import styled from 'styled-components'

export const S_Text = styled.div`
  color: ${(props) => props.textColor};
  display: ${(props) => props.display};
  ${(props) => (props.alignItems ? `align-items: ${props.alignItems}` : '')};
  ${(props) => (props.minWidth ? `min-width: ${props.minWidth}` : '')};
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}` : '')};
  word-wrap: ${(props) => props.wrap};

  margin: ${(props) => `${props.mt} ${props.mr} ${props.mb} ${props.ml}`};
  padding: ${(props) => `${props.pt} ${props.pr} ${props.pb} ${props.pl}`};
  font-weight: ${(props) => props.fw};
  font-size: ${(props) => props.fs};
  text-align: ${(props) => props.align};
  letter-spacing: 0.3px;
  overflow: ${(props) => props.overflow};
`
