import styled from 'styled-components'

import colors from '../../_constants/colors'

const paginationItemSpacing = '5px'

export const S_PaginationControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;

    .prev,
    .next {
        margin: ${paginationItemSpacing} ${paginationItemSpacing};
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .pagination-label {
        min-width: 100px; /* Prevent offset changes */
        text-align: center;
        color: #999;
        font-size: 14px;
        margin-right: 5px;
        &.for-single {
            min-width: 60px;
        }
        padding-top: 5px;
    }
    .spacer {
        padding-left: ${paginationItemSpacing};
        padding-right: ${paginationItemSpacing};
    }
    .select-container {
        display: flex;
    }
    .page-select {
        display: block;
        font-size: 14px;
        font-family: sans-serif;
        color: #999;
        padding: 2px 2px 2px 12px;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        border: none;
        appearance: none;
        max-height: 30px;
        min-height: 30px;
        background-color: white;
        cursor: pointer;
        position: relative;
        .page-select-option {
            position: absolute;
            height: 30px;
            top: 0px;
            left: 0px;
            width: 100%;
            opacity: 0;
            cursor: pointer;
        }
        .current-page-label {
            margin-top: 5px;
        }
    }
    .page-size-select {
        font-size: 14px;
        border: none;
        appearance: none;
        min-height: 30px;
        min-width: 20px;
        max-height: 30px;
        max-width: 20px;
        text-align-last: center;
        color: #999;
        background-size: 30px 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: white;
        cursor: pointer;
        position: relative;
        &:disabled {
            color: #ccc;
        }
        .size-select-label {
            font-size: 22px;
            font-weight: bolder;
            .caret-container {
            }
        }
        .size-select-label:hover {
            color: black;
        }
        .page-size-select-option {
            position: absolute;
            height: 30px;
            top: 0px;
            left: 0px;
            width: 100%;
            opacity: 0;
            font-size: 14px;
            cursor: pointer;
        }
    }
    .page-size-select:hover {
        color: black;
    }
    .page-select:hover {
    }
    .loader {
        margin-right: 10px;
        margin-top: 5px;
    }

    &.disabled {
        .size-select-label,
        .current-page-label {
            color: #ccc;
        }
    }
    .nav-controls {
        margin-left: auto;
        margin-right: auto;
        display: flex;
    }
    .download {
        margin-left: auto;
        color: ${colors.DEFAULT_SECONDARY};
        display: flex;
        position: absolute;
        right: 0;
    }

    .download-icon {
        cursor: pointer;
        margin-left: 10px;
        svg {
            fill: ${colors.DEFAULT_SECONDARY};
            transition: fill 0.2s ease-in-out;
        }

        &:hover {
            svg {
                fill: ${colors.DEFAULT_PRIMARY};
        }
    }
`
