import React, { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import ReactDropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone-newest'

// Constants
import colors from '../../../../_constants/colors'

// Components
import { FaTimes } from 'react-icons/fa'
import { S_Button } from '../../../Button/Button.styles'

export const S_UploadDocField = styled.div`
  .options-section {
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;

    .radio-btn {
      flex-grow: 1;
      flex-basis: 0;
      margin: 5px;

      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }

      input[type='radio'] {
        display: none;

        &:checked {
          & + label {
            border-color: ${colors.MATERIAL_GREEN};
          }
          & + div label {
            border-color: ${colors.MATERIAL_GREEN};
          }
        }
      }
    }
  }

  .upload-section {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`

export const radioLabelStyle = css`
  padding: 9px 16px;
  border: 1px solid ${colors.OUTLINE_GREY_BOX};
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 3px;
  transition: border 0.2s ease-in-out;
  white-space: nowrap;

  &:hover {
    border-color: ${colors.TEXT_DARK};
  }
`

const RadioLabel = styled.label`
  ${radioLabelStyle}
`

export const UploadFile = styled(ReactDropzone)`
  ${radioLabelStyle}
`

export const UploadFileButton = styled(ReactDropzone)`
  ${S_Button};
  overflow: hidden;
  display: flex;
  align-items: center;
`

export const DueDiligenceUploadFileButton = styled(ReactDropzone)`
  svg {
    cursor: pointer;
    height: 1em;
    width: 1em;
    vertical-align: middle;

    &:hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
`

export const S_FileLabel = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border: 1px solid ${colors.ICON_GREY};
  color: ${colors.TEXT_DARK};
  width: 100%;
  padding: 9px 12px;
  border-radius: 3px;

  .delete-icon {
    margin-left: 3px;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${colors.MATERIAL_RED};
    }
  }
`

const updateFromProps = (value, defaultValue) => {
  // Generally these will be uncontrolled
  const isControlled = value !== undefined
  let valueToUse = isControlled ? value : defaultValue
  return valueToUse
}

const FileLabel = ({ onDelete, name }) => {
  return (
    <S_FileLabel>
      <span>{name}</span>
      <FaTimes className={'delete-icon'} onClick={onDelete} />
    </S_FileLabel>
  )
}

const UploadDocField = (props) => {
  const [fileName, setFileName] = useState(null)

  const configureValues = (newValue, id) => {
    setDisplayValue(newValue)
    onChange(newValue, id)
  }

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      configureValues({ option: 'upload', file: acceptedFiles[0] }, id)
      setFileName(acceptedFiles[0].name)
    }
  }, [])

  const { open, getInputProps, getRootProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
  })

  const {
    options = [],
    id = null,
    value,
    name,
    defaultValue = {},
    onChange = () => {},
    inputRef,
    label,
    setError,
    ...rest
  } = props
  const [displayValue, setDisplayValue] = useState(updateFromProps(value, defaultValue))

  useEffect(() => {
    setDisplayValue(updateFromProps(value, defaultValue))
  }, [id, value])

  const handleChangeLocal = (newValue, id) => {
    configureValues(newValue, id)
  }

  const openUploadDialog = (option) => {
    if (option === 'upload') {
      open()
    }
  }

  const deleteFile = (e) => {
    e.preventDefault()
    handleChangeLocal({ option: 'delete', file: null }, id)
    setFileName(null)
  }

  return (
    <S_UploadDocField {...rest} className={`radio-group`} id={id}>
      <input {...getInputProps()} />
      <div {...getRootProps({ className: 'dropzone options-section' })}>
        {fileName === null ? (
          options.map((opt) => (
            <div className={`radio-btn`} key={opt.value}>
              <input
                defaultChecked={String(opt.value) === displayValue.option}
                id={`${label}-${name}-${opt.value}`}
                name={`${label}-${name}`}
                onChange={(e) => {
                  handleChangeLocal({ option: e.target.value, file: null }, id)
                }}
                ref={inputRef}
                type="radio"
                value={opt.value}
              />

              <RadioLabel htmlFor={`${label}-${name}-${opt.value}`} onClick={() => openUploadDialog(opt.value)}>
                {opt.label}
              </RadioLabel>
            </div>
          ))
        ) : (
          <FileLabel name={fileName} onDelete={(e) => deleteFile(e)} />
        )}
      </div>
    </S_UploadDocField>
  )
}

function mapStateToProps(state) {
  const { env } = state
  return {
    tenant: env.tenant,
  }
}

const connectedUploadDocField = connect(mapStateToProps)(UploadDocField)
export { connectedUploadDocField as UploadDocField }
