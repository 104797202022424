import startCase from 'lodash/startCase'

export const getApprovalMessage = (code, isFunded = false) => {
  const approvalMessages = {
    100: 'Pending',
    200: 'Rejected',
    300: isFunded ? 'Funded' : 'Approved',
  }

  return approvalMessages[code]
}

export const getApprovalStatus = (fundingRequest) => {
  const { last_approval, status, funded } = fundingRequest

  //ABL funding request approval messages
  if (status) {
    return startCase(status)
  }

  // Factoring funding request approval messages
  if (!last_approval || !last_approval.approval_code) {
    return getApprovalMessage('100')
  }

  return getApprovalMessage(last_approval.approval_code, funded)
}

export const isPendingApproval = (approvalObj = null) => {
  return getApprovalStatus(approvalObj) === getApprovalMessage('100')
}

export const getPendingdMessage = () => {
  return getApprovalMessage('200')
}

export const getRejectedMessage = () => {
  return getApprovalMessage('200')
}

export const getApprovedMessage = () => {
  return getApprovalMessage('200')
}
