function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

const validationFormRules = {
  percentage: {
    valid: (value) => {
      if (+value > 1.0) return 'Should be less than 100%'
      return true
    },
  },
  currency: {
    length: (value) => {
      if (value >= 10 ** 10) return 'Should be less than $10,000,000,000.00'
      return true
    },
  },
  tel: {
    length: (value) => {
      if (typeof value !== 'string') return true
      let valueSize = value.replace(/\D/g, '').length
      if (valueSize < 10 && valueSize > 0) return 'Should have at least 10 digits'
      if (valueSize > 13) return 'Should have less than 13 digits'
      return true
    },
  },
  email: {
    valid: (value) => {
      if (value.length > 0 && !emailIsValid(value)) return 'Invalid email'
      return true
    },
  },
  ssn: {
    length: (value) => {
      if (typeof value !== 'string') return true
      let valueSize = value.replace(/\D/g, '').length
      if (valueSize !== 9 && valueSize > 0) return 'Should have 9 digits'
      return true
    },
  },
  business_ein: {
    length: (value) => {
      if (typeof value !== 'string') return true
      let valueSize = value.replace(/\D/g, '').length
      if (valueSize !== 9 && valueSize > 0) return 'Should have 9 digits'
      return true
    },
  },
}

export const getValidationRuleByFieldType = (type) => {
  return validationFormRules[type] || {}
}
