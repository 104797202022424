import { S_DeleteNotificationGroupModal } from './DeleteNotificationGroupModal.styles'
import { Button } from '../../../_components/Button'
import React, { useState } from 'react'
import { LoaderButton } from '../../../_components/LoaderButton'
import { logError } from '../../../_helpers/errors'

import { notificationActionHooks } from '../../../_actionHooks'

import { deleteNotificationGroup } from '../../../_services/lender.service'

export const DeleteNotificationGroupModal = ({ notificationGroup, closeModal }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const showNotification = notificationActionHooks.useShowNotification()

  return (
    <S_DeleteNotificationGroupModal>
      <h3>Confirm</h3>
      <div className={`message-body`}>Are you sure you want to delete this notification group?</div>
      <div className={`btn-container`}>
        <Button disabled={isDeleting} onClick={closeModal}>
          Cancel
        </Button>
        <LoaderButton
          className={`destroy`}
          isLoading={isDeleting}
          onClick={async () => {
            try {
              deleteNotificationGroup(notificationGroup.id).then(() => {
                closeModal()
                showNotification({
                  message: 'The notification group was deleted successfully!',
                  type: 'success',
                })
              })
            } catch (error) {
              logError(error)
              showNotification({
                type: 'error',
                message: 'This notification group could not be deleted. Please contact the administrator.',
              })
            }
            setIsDeleting(false)
          }}
        >
          Confirm
        </LoaderButton>
      </div>
    </S_DeleteNotificationGroupModal>
  )
}
