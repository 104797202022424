import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useAuthentication } from 'xen/hooks';
const Notifications = lazy(() => import('../pages/notifications'));
export const NOTIFICATIONS_PATHS = {
    root: '/notifications',
};
export const NotificationsRoutes = () => {
    const { root } = NOTIFICATIONS_PATHS;
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    return [
        _jsx(Route, { exact: true, path: root, children: _jsx(Notifications, {}) }, root),
    ];
};
