import { getBusinesses } from '../../../../_services/businesses.service'
import { getABLBusinesses } from '../../../../_services/abl/abl-business.service'

export const factoringBusinessFilter = {
  fieldName: 'business_name',
  displayName: 'Client',
  type: 'autoComplete',
  autoComplete: {
    service: getBusinesses,
    requestMethod: 'POST',
    searchParam: 'business_name',
    responseAttr: 'business_name',
    suggestion: 'Start typing to search a business',
    extra: { constraints: { is_client: true } },
  },
}

export const factoringProspectsFilter = {
  fieldName: 'business_name',
  displayName: 'Client',
  type: 'autoComplete',
  autoComplete: {
    service: getBusinesses,
    requestMethod: 'POST',
    searchParam: 'business_name',
    responseAttr: 'business_name',
    suggestion: 'Start typing to search a business',
    extra: { constraints: { is_client: false } },
  },
}

export const ablBusinessFilter = {
  fieldName: 'name',
  displayName: 'Client',
  type: 'autoComplete',
  autoComplete: {
    service: getABLBusinesses,
    requestMethod: 'GET',
    searchParam: 'name',
    responseAttr: 'name',
    suggestion: 'Start typing to search a business',
    extra: { constraints: { status: 'in_prospect' } },
  },
}
