import { esigningConstants } from '../_constants'

export function esigning(state = {}, action) {
  switch (action.type) {
    case esigningConstants.GET_ESIGNING_REQUESTS_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
        },
      }
    case esigningConstants.GET_ESIGNING_REQUESTS_SUCCESS:
      return {
        requestsCount: action.requestsCount,
        requests: action.requests,
      }
    case esigningConstants.GET_ESIGNING_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case esigningConstants.OPEN_ESIGNING_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case esigningConstants.OPEN_ESIGNING_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        signature_request: action.signatureRequest,
      }
    case esigningConstants.OPEN_ESIGNING_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}
