import React from 'react'
import styled, { css } from 'styled-components'
import { math } from 'polished'

// Components
import { FormInput, SelectInput, RadioGroupInput, TextAreaInput, MultiSelectInput } from '../FormInput'
import { S_FormInput } from '../FormInput/FormInput.styles'
import { CurrencyField } from './CustomFields/CurrencyField'
import { PercentField, PercentField2 } from './CustomFields/PercentField'
import { RegionField } from './CustomFields/RegionField'
import { SecureField } from './CustomFields/SecureField'
import { ACInput } from './CustomFields/ACInput'
import { UploadDocField } from './CustomFields/UploadDocField'
import { StdTermsSaleField } from './CustomFields/StdTermsSaleField'
import { InternationalTelField } from './CustomFields/InternationalTelField'
import { CountryField } from './CustomFields/CountryField'
import { S_AutoCompleteInput } from '../../AutoCompleteInput'
import { textInputStyles, textInputStylesConfig } from '../../../_constants/styles'
import { IntegerField } from './CustomFields/IntegerField'
import { ConfirmNumberField } from './CustomFields/ConfirmNumberField'
import colors from '../../../_constants/colors'
import { Checkbox } from './CustomFields/Checkbox/Checkbox'
import { CheckboxGroup } from './CustomFields/CheckboxGroup/CheckboxGroup'

export const S_FormFieldWrapper = styled.div`
  display: inline-flex;
  position: relative;
  width: 100%;

  &.percentage {
    ${S_FormInput} {
      padding-right: ${math(`${textInputStylesConfig.paddingH}*2`)};
    }
    &:after {
      content: '%';
      display: flex;
      color: ${colors.DEFAULT_TEXT};
      width: 16px;
      height: 100%;
      font-size: 16px;
      position: absolute;
      right: 8px;
      align-items: center;
      justify-content: center;
    }
  }
  ${S_AutoCompleteInput} {
    width: 100%;
    .formFieldInput {
      ${textInputStyles}
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #f5f5f5;
    `}
`

export const FormField = (props) => {
  // need option to override error state
  // need built-in validation (required, validation regex)
  const newProps = { ...props }
  if (!props.type) {
    newProps['type'] = 'text'
  }
  if (newProps.autoCompleteProps) {
    delete newProps.autoCompleteProps
  }
  if (newProps.type == 'checkbox' && newProps.defaultValue) {
    newProps.defaultChecked = newProps.defaultValue
  }
  const { id = null, onChange = () => {}, name } = props
  switch (newProps.type) {
    case 'currency':
      return <CurrencyField {...newProps} />
    case 'textarea':
      return <TextAreaInput {...newProps} onChange={(e) => onChange(e.target.value, id)} />
    case 'percentage':
      return <PercentField {...newProps} />
    case 'percent':
      return <PercentField {...newProps} />
    case 'percent-2':
      return <PercentField2 {...newProps} />
    case 'select':
      return <SelectInput {...newProps} onChange={(e) => onChange(e.target.value, id)} />
    case 'region':
      return <RegionField {...newProps} onChange={(e) => onChange(e.target.value, id)} />
    case 'country':
      return <CountryField {...newProps} />
    case 'std_terms_sale':
      return <StdTermsSaleField {...newProps} />
    case 'integer':
      return <IntegerField {...newProps} />
    case 'ssn':
      return <SecureField {...newProps} />
    case 'tel':
      return <InternationalTelField {...newProps} />
    case 'radio':
      return <RadioGroupInput {...newProps} />
    case 'multiselect':
      return <MultiSelectInput {...newProps} />
    case 'upload_document':
      return <UploadDocField {...newProps} />
    case 'business_ein':
    case 'aba_routing_ach':
    case 'aba_routing_wire':
    case 'account_number':
      return <SecureField {...newProps} />
    case 'autocomplete':
      return <ACInput {...newProps} {...props.autoCompleteProps} value={newProps.defaultValue} />
    case 'confirm-number':
      return <ConfirmNumberField {...newProps} />
    case 'checkbox':
      return <Checkbox {...newProps} onChange={(e) => onChange(e.target.checked, id, e)} />
    case 'checkbox-group':
      return <CheckboxGroup {...newProps} />
    default:
      // We want to normalize how onChange param values are handled
      // Return the value and the ID, instead of the event itself
      return (
        <FormInput
          {...newProps}
          onChange={(e) => onChange(newProps.type === 'checkbox' ? e.target.checked : e.target.value, id)}
        />
      )
  }
}
