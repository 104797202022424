import { PropertyProxy, ConfigProxy } from '../_helpers/util'

const DEFAULT_STATS = [
  {
    type: 'currency',
    label: 'Outstanding Invoices',
    name: 'outstanding_credit',
  },
  {
    type: 'currency',
    name: 'client_escrow_reserve',
    label: 'Escrow Reserves',
  },
  {
    type: 'currency',
    name: 'client_reserve',
    label: 'Client Reserves',
  },
  {
    type: 'currency',
    name: 'client_balance',
    label: 'Current Net Funds Employed',
  },
  {
    type: 'percent',
    label: 'Advance Rate',
    name: 'advance_rate',
  },
  {
    label: '# Open Invoices',
    name: 'number_open_invoices',
    type: 'number',
  },
]
const DEFAULT_OVERVIEW = {
  title: 'Overview',
  stats: DEFAULT_STATS,
}
const DEFAULT_DETAILS = {
  submit_btn_label: 'Submit Invoices',
}
const DEFAULT_CLIENT_HOME_CONFIG = {
  overview: DEFAULT_OVERVIEW,
  details: DEFAULT_DETAILS,
}
export const clientHomeConfig = ConfigProxy(
  {
    /*Enter unique tenant properties here, example below*/
    boldbusinesscapital: PropertyProxy(
      {
        details: PropertyProxy(
          {
            submit_btn_label: 'Submit Invoices',
            legalese:
              'The above signed, for value received, hereby sells, assigns, transfers and sets over to BOLD Business Capital, LLC. the above invoices.in accordance with the terms and conditions recited in a certain Factoring Agreement executed by the Abovesigned. Further, that all materials have been delivered and all services performed as represented on the above listed invoices, and that there are no existing offsets.',
          },
          DEFAULT_DETAILS
        ),
      },
      DEFAULT_CLIENT_HOME_CONFIG
    ),
    marcocapital: PropertyProxy(
      {
        details: PropertyProxy(
          {
            submit_btn_label: 'Submit Invoices',
            legalese:
              'By submitting this Schedule of Accounts, Seller represents and warrants that the foregoing Accounts offered for sale to Purchaser by this Schedule of Accounts are each being offered pursuant to and in accordance with the terms and conditions of the Master Purchase and Sale Agreement in effect as of the date hereof(the "Purchase Agreement"), including but not limited to the representations, warranties and covenants therein, which Purchase Agreement Seller agrees in full force and effect and is hereby ratified, approved, and confirmed in all respects. The representative of Seller is identified below certifies that such representative has the authority to submit this Schedule of Accounts on behalf of Seller and that all information contained in this Schedule of Accounts and the documents submitted herewith is true, accurate and complete.',
          },
          DEFAULT_DETAILS
        ),
      },
      DEFAULT_CLIENT_HOME_CONFIG
    ),
    marcofi: PropertyProxy(
      {
        details: PropertyProxy(
          {
            submit_btn_label: 'Submit Invoices',
            legalese:
              'By submitting this Schedule of Accounts, Seller represents and warrants that the foregoing Accounts offered for sale to Purchaser by this Schedule of Accounts are each being offered pursuant to and in accordance with the terms and conditions of the Master Purchase and Sale Agreement in effect as of the date hereof(the "Purchase Agreement"), including but not limited to the representations, warranties and covenants therein, which Purchase Agreement Seller agrees in full force and effect and is hereby ratified, approved, and confirmed in all respects. The representative of Seller is identified below certifies that such representative has the authority to submit this Schedule of Accounts on behalf of Seller and that all information contained in this Schedule of Accounts and the documents submitted herewith is true, accurate and complete.',
          },
          DEFAULT_DETAILS
        ),
      },
      DEFAULT_CLIENT_HOME_CONFIG
    ),
    factoringdemo: PropertyProxy(
      {
        details: PropertyProxy(
          {
            submit_btn_label: 'Submit Invoices',
            legalese:
              'The above signed, for value received, hereby sells, assigns, transfers and sets over to Xen, Inc. the above invoices.in accordance with the terms and conditions recited in a certain Factoring Agreement executed by the Abovesigned. Further, that all materials have been delivered and all services performed as represented on the above listed invoices, and that there are no existing offsets.',
          },
          DEFAULT_DETAILS
        ),
      },
      DEFAULT_CLIENT_HOME_CONFIG
    ),
    dat: PropertyProxy(
      {
        details: PropertyProxy(
          {
            submit_btn_label: 'Submit Invoices',
            legalese:
              'The above signed, for value received, hereby sells, assigns, transfers and sets over to Xen, Inc. the above invoices.in accordance with the terms and conditions recited in a certain Factoring Agreement executed by the Abovesigned. Further, that all materials have been delivered and all services performed as represented on the above listed invoices, and that there are no existing offsets.',
          },
          DEFAULT_DETAILS
        ),
      },
      DEFAULT_CLIENT_HOME_CONFIG
    ),
    acsfactors: PropertyProxy(DEFAULT_STATS, DEFAULT_CLIENT_HOME_CONFIG),
    ctrlpay: PropertyProxy(
      {
        details: PropertyProxy(
          {
            submit_btn_label: 'Submit Invoices',
            legalese:
              'The above signed, for value received, hereby sells, assigns, transfers and sets over to CtrlPay the above invoices.in accordance with the terms and conditions recited in a certain Factoring Agreement executed by the Abovesigned. Further, that all materials have been delivered and all services performed as represented on the above listed invoices, and that there are no existing offsets.',
          },
          DEFAULT_DETAILS
        ),
      },
      DEFAULT_CLIENT_HOME_CONFIG
    ),
    breakoutfinance: PropertyProxy(
      {
        details: PropertyProxy(
          {
            submit_btn_label: 'Submit Invoices',
            legalese:
              'The above signed, for value received, hereby sells, assigns, transfers and sets over to Breakout Capital the above invoices.in accordance with the terms and conditions recited in a certain Factoring Agreement executed by the Abovesigned. Further, that all materials have been delivered and all services performed as represented on the above listed invoices, and that there are no existing offsets.',
          },
          DEFAULT_DETAILS
        ),
      },
      DEFAULT_CLIENT_HOME_CONFIG
    ),
    primaryfundingdemo: PropertyProxy(
      {
        details: PropertyProxy(
          {
            submit_btn_label: 'Submit Invoices',
            legalese:
              'The above signed, for value received, hereby sells, assigns, transfers and sets over to Primary Funding the above invoices.in accordance with the terms and conditions recited in a certain Factoring Agreement executed by the Abovesigned. Further, that all materials have been delivered and all services performed as represented on the above listed invoices, and that there are no existing offsets.',
          },
          DEFAULT_DETAILS
        ),
      },
      DEFAULT_CLIENT_HOME_CONFIG
    ),
  },
  DEFAULT_CLIENT_HOME_CONFIG
)
