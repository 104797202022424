import React from 'react'
import styled, { css } from 'styled-components'

// Constants
import { cssConstants, cssBreakpoints } from '../../../_constants/styles'
import colors from '../../../_constants/colors'

const columnStyles = {
  marginBot: '15px',
}

const subTitlesStyles = css`
  color: ${colors.TEXT_LIGHT};
  margin-top: 5px;
`
export const S_ContentColumn = styled.div`
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => (props.contentWidth ? props.contentWidth : cssConstants.contentWidth)};
  padding-left: ${cssConstants.contentPadding};
  padding-right: ${cssConstants.contentPadding};

  h1:not(.typography) {
    margin-bottom: ${columnStyles.marginBot};
    font-size: 38px;
    font-weight: normal;
    color: ${colors.TEXT_DARK};
  }

  .page-header {
    margin-bottom: ${columnStyles.marginBot};

    h1 {
      margin-bottom: 0;
    }

    @media print {
      margin-bottom: 0;
    }
  }

  .page-header-row {
    display: flex;
    align-items: center;
  }

  .page-subtitle {
    ${subTitlesStyles}
    text-transform: uppercase;
    font-weight: 500;
  }

  .subtitle {
    ${subTitlesStyles}
    font-size: 14px;
    font-weight: 500;
  }

  @media (min-width: ${cssBreakpoints.desktopLg}) {
    overflow: unset;
  }
`

// A layout component to align content to the center of the page and apply responsive max-widths
// TODO: ability to set tag type (like <main>) for specific content columns
export const ContentColumn = ({ children, ...rest }) => <S_ContentColumn {...rest}>{children}</S_ContentColumn>
