import { timezoneConstants } from '../_constants'

export function timezone(state = {}, action) {
  switch (action.type) {
    case timezoneConstants.SET_TIMEZONE:
      return {
        ...state,
        ...action.timezone,
      }

    default:
      return state
  }
}
