import { jsx as _jsx } from "react/jsx-runtime";
import { z } from 'zod';
import { useGetBusinesses } from 'xen/api/use-business';
import { FormSelect } from '../select';
export const FormClientSelect = ({ allowDeselect = false, label = 'Client', nothingFoundMessage = 'No client found...', required = false, ...props }) => {
    const { data: businessesList = [] } = useGetBusinesses({
        constraints: {
            // Only return clients, not prospects
            is_client: true,
        },
        pagination: {
            // Force API to return all clients (max unique clients is currently ~100)
            ...{ page: 1, per_page: 1000 },
        },
        // Sort alphabetically by business name
        ...{ sort_by: 'business_name', sort_asc: true },
    });
    const selectBusinessList = businessesList.map((business) => {
        return {
            label: business.businessName,
            value: String(business.id),
        };
    });
    return (_jsx(FormSelect, { allowDeselect: allowDeselect ?? !required, clearable: false, data: selectBusinessList, label: label, nothingFoundMessage: nothingFoundMessage, required: required, searchable: true, ...props }));
};
const clientSelectSchema = z.string().min(1, { message: 'Client is required' });
FormClientSelect.schema = clientSelectSchema;
