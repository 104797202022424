import styled from 'styled-components'

// Constants
import { colors } from '../../../_constants/colors'

import { textInputStylesConfig } from '../../../_constants/styles'
import { S_QuestionRoundedIcon } from '../../ListSearchableView'

export const S_FormRow = styled.div`
  width: 100%;
  display: ${(props) => (props.shouldShow ? 'block' : 'none')};

  .form-row-label {
    margin-bottom: 4px;
    font-size: 16px;
    color: ${colors.TEXT_DARK};
    padding-left: 5px;
  }

  &.inline {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .form-row-label {
      min-height: ${textInputStylesConfig.minHeight};
      display: flex;
      align-items: center;
      padding-right: 20px;
      padding-left: 0;
      width: 150px; /* TODO: we need to overwrite this on a case by case basis */
    }
    .field-and-error-wrapper {
      flex: 1 1 auto;
    }

    .form-row-error {
      text-align: right;
    }
  }

  &.inline-group {
    display: inline-flex;
    width: auto;
    align-items: flex-start;
    min-width: 120px;

    .form-row-label {
      min-height: ${textInputStylesConfig.minHeight};
      display: flex;
      align-items: center;
      padding-right: 20px;
      padding-left: 0;
      width: 150px; /* TODO: we need to overwrite this on a case by case basis */
    }

    &.label-center {
      .form-row-label {
        width: auto;
        padding: 0 10px;
        justify-content: center;
      }
    }
  }

  .label-text {
    display: inline-block;
    margin-right: 3px;
  }

  .required-indicator {
    display: inline;
    padding-left: 2px;
    color: ${colors.MATERIAL_RED};
  }

  .form-row-error {
    margin-top: 4px;
    font-size: 14px;
    color: ${colors.MATERIAL_RED};
  }

  .form-row-info {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 14px;
    color: #666;
  }

  .tooltip-question {
    display: inline;
  }

  ${S_QuestionRoundedIcon} {
    margin-left: 6px;
  }
`
