import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const ToolTip = ({ children, content, interactive, position, wait }) =>
  content ? (
    <Tippy arrow={true} content={content} delay={[wait, 0]} interactive={interactive} placement={position}>
      {children}
    </Tippy>
  ) : (
    <div>{children}</div>
  )

export default ToolTip

ToolTip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  interactive: PropTypes.bool,
  position: PropTypes.string,
  visible: PropTypes.bool, //NOTE: visible is for debug purposes
  wait: PropTypes.number,
}

ToolTip.defaultProps = {
  interactive: true,
  position: `top`,
  wait: 0,
}
