import styled from 'styled-components'
import { colors } from '../../_constants/colors'

export const S_TileTitle = styled.h3`
  margin-right: auto;
  margin-bottom: 16px;
  color: ${colors.TEXT_DARK};
  font-weight: 400;
  text-align: left;
`
