import { ErrorGroup } from '../ErrorGroup'
import React from 'react'

export const InvalidPasswordCognito = ({ style }) => {
  return (
    <div style={style}>
      <ErrorGroup
        description={'Please provide a password following all these rules:'}
        errors={[
          'At least 8 characters (total)',
          'One character must be a number',
          'One character must be a special character: ^$*.[]{}()?!@#%&,><:;|_~',
          'One character must be lowercase',
          'One character must be uppercase',
          'example: Hiolzq5$',
        ]}
        title={'Password Invalid'}
      />
    </div>
  )
}
