import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { FaPhone } from 'react-icons/fa'
import get from 'lodash/get'

// CONSTANTS
import urls from '../../_constants/urls'
import { tenantColors } from '../../_constants/colors'
import { headerConfig, metadataConfig } from '../../_configs'
import ROUTES from '../../_constants/routes'

// Utilities
import { isFeatureEnabled } from '../../_helpers/flags'

// Components
import { MenuToggle } from './MenuToggle'
import ImgLink from '../ImgLink'

import { S_HeaderBar } from './Header.styles'
import { PRODUCT_FLAGS } from '../../_helpers/featureFlags/flags.constants'

// Hooks
import { useFlags } from '../../_reduxHooks/flags.hook'

// This component is only used on auth pages, until xen_auth_pages is released
export const Header = () => {
  let env = useSelector((state) => state.env)
  const { featureFlags } = useFlags()

  const environment = env.env
  const tenant = env.tenant

  const customColors = tenantColors[tenant]
  const config = headerConfig[tenant]
  const metadata = metadataConfig[tenant]
  const headerRef = useRef()

  const isABLEnabled = isFeatureEnabled(PRODUCT_FLAGS.ABL, featureFlags)
  const productSuffix = isABLEnabled ? '_abl' : ''

  const scrollToTop = get(location, 'state.scrollToHeader', false)
  useEffect(() => {
    if (scrollToTop) {
      headerRef.current.scrollIntoView()
    }
  }, [scrollToTop])

  const CustomHeaderLogo = () => {
    return (
      <img
        alt={`${metadata.tenant_name}`}
        className="header-logo"
        src={`${urls.IMAGES}/${environment}/${tenant}/header_logo${productSuffix}.png`}
      />
    )
  }

  return (
    <div>
      <S_HeaderBar customColors={customColors} ref={headerRef}>
        <div className="header-wrapper">
          <div className="header-left">
            <a className="brand-link" data-testid="brand-link" href={metadata.link_landing_page}>
              <CustomHeaderLogo />
            </a>
          </div>

          <div className="header-right">
            <NavLink activeClassName={'active'} className="link sign-up-link" key={0} to={ROUTES.sign_up}>
              Sign Up
            </NavLink>

            <NavLink activeClassName={'active'} className="link sign-in-link" key={1} to={ROUTES.sign_in}>
              Sign In
            </NavLink>
            {config
              .filter((item) => item.type === 'imglink')
              .map((item, i) => (
                <ImgLink
                  alt={item.label}
                  className={'link img-link'}
                  href={item.link}
                  key={i}
                  src={item.image}
                  target={`_blank`}
                  title={item.label}
                  width={`75px`}
                />
              ))}
            {config
              .filter((item) => item.type !== 'imglink')
              .map((item, i) => (
                <a className={`link tel-link`} href={item.link} key={i}>
                  {item.type === `phone` && <FaPhone className={`icon`} />}
                  {item.label}
                </a>
              ))}
            <div className="menu-wrapper">
              <MenuToggle />
            </div>
          </div>
        </div>
      </S_HeaderBar>
    </div>
  )
}
