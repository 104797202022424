import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { S_Tabs } from './Tabs.styles'
import { formatCase, searchParam } from 'xen/helpers'

export const Tab = ({
  _label, // defines the title of the tab when used in Tabs.jsx
  children,
}) => <div>children</div>

const getActiveLabel = (tab) => formatCase.kebab(tab)

const Tabs = (props) => {
  const { children: propsChildren, smooth = false, bordered = false } = props
  const children = propsChildren.filter ? propsChildren.filter(Boolean) : propsChildren

  const [activeTab, _setActiveTab] = useState('')
  const setActiveTab = (tab) => {
    // Always format active tab to kebab-case
    const activeTab = getActiveLabel(tab)

    _setActiveTab(activeTab)

    // If setTabStateInUrl is true, also set the tab in the url
    if (props.setTabStateInUrl) {
      searchParam.set('tab', activeTab)
    }
  }

  useEffect(() => {
    let childrenRef
    if (Array.isArray(children)) {
      childrenRef = children
    } else {
      childrenRef = [children]
    }
    if (childrenRef.length > 0) {
      const activeTabLabels = childrenRef.map((child) => getActiveLabel(child.props.label))
      const firstTab = activeTabLabels[0]
      const tabParam = searchParam.get('tab')

      // If setTabStateInUrl is true and is a valid tab, get the tab from the url, or default to the first tab
      const activeTab = props.setTabStateInUrl && activeTabLabels.includes(tabParam) ? tabParam : firstTab

      setActiveTab(activeTab)
    }
    //Stringify the labels to use as a key to avoid object id related re-renders
  }, [JSON.stringify(Array.isArray(children) ? children.map((c) => c.props.label) : [children.props.label])])

  const onClickTabItem = (tab) => {
    setActiveTab(tab)
  }

  const tabs = Array.isArray(children) ? children : [children]

  return (
    <S_Tabs bordered={bordered} className="tabs" smooth={smooth}>
      <ul className="tab-list">
        {tabs.map((child) => {
          const { label } = child.props
          return (
            <li
              className={`tab-list-item ${activeTab === getActiveLabel(label) ? `_active` : ''}`}
              key={label}
              onClick={() => onClickTabItem(label)}
            >
              {label}
            </li>
          )
        })}
      </ul>
      <div className="tab-content">
        {tabs.map((child) => {
          const { children, label } = child.props
          if (activeTab === getActiveLabel(label)) return children
        })}
      </div>
    </S_Tabs>
  )
}

Tabs.defaultProps = {
  children: false,
  className: '',
  borderedContent: false,
  bordered: false,
  fs: '18px',
}

Tabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.bool]),
  className: PropTypes.string,
  borderedContent: PropTypes.bool,
  bordered: PropTypes.bool,
  fs: PropTypes.string,
}

export default Tabs
