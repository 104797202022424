import styled, { css } from 'styled-components'
import colors from '../../_constants/colors'
import { cssBreakpoints } from '../../_constants/styles'

export const S_Tabs = styled.div`
  padding-left: 0;

  .tab-list {
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    list-style-type: none;
    display: flex;
  }

  .tab-list-item {
    cursor: pointer;
    font-size: ${(props) => props.fs};
    font-weight: 500;
    padding: 10px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    margin-right: 5px;

    background: transparent;
    color: ${colors.TEXT_LIGHT};
    transition:
      color 0.2s ease-in-out,
      background 0.2s ease-in-out,
      opacity 0.2s ease-in-out;

    &:hover {
      background: ${(props) => props.theme.colors.secondary};
      color: ${colors.WHITE};
      opacity: 0.75;
    }

    &._active {
      color: ${colors.WHITE};
      background: ${(props) => props.theme.colors.secondary};
      opacity: 1;
    }
  }

  .tab-content {
    padding: 0;
    @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
      overflow: scroll;
    }
  }

  ${({ bordered }) =>
    bordered &&
    css`
      .tab-list {
        margin-bottom: 0;
      }

      .tab-content {
        ${(props) =>
          props.borderedContent
            ? `border: 1px solid ${colors.OUTLINE_GREY_BOX}`
            : `border-top: 1px solid ${colors.OUTLINE_GREY_BOX}`};
        border-radius: 0 0 3px 3px;
      }

      .tab-list-item {
        position: unset;
        border: 1px solid transparent;
        border-radius: 3px 3px 0 0;
        border-bottom: 0;
        font-size: ${(props) => props.fs};
        transition: border-color 0.2s ease-in-out;
        &:hover {
          background: unset;
          color: black;
        }
        &:last-child {
          border-right-width: 1px;
        }
        &._active {
          background-color: unset;
          border-bottom: 2px ${colors.DEFAULT_SECONDARY} solid;
          color: black;
          &:after {
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            bottom: -1px;
            background: #fff;
            width: 100%;
          }
        }
      }
    `}

  ${({ smooth }) =>
    smooth &&
    css`
      color: ${colors.TEXT_LIGHT};
      .tab-list {
        border-bottom: none;
      }
      .tab-list-item {
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        list-style: none;
        margin-bottom: -1px;
        opacity: 0.5;
        padding: 15px;
        position: relative;
        &:hover {
          opacity: 0.75;
          background: unset;
          color: ${(props) => props.theme.colors.secondary};
        }
        &:after {
          bottom: 0;
          left: 0;
          position: absolute;
          width: 100%;
          content: '';
          height: 3px;
          transition: all 0.3s ease;
          max-width: 0;
        }

        &._active {
          background-color: unset;
          color: ${(props) => props.theme.colors.secondary};
          &:after {
            content: '';
            background: ${(props) => props.theme.colors.secondary};
            max-width: 100%;
          }
        }
      }
    `}
`
