import { jsx as _jsx } from "react/jsx-runtime";
import { tenantColors } from 'legacy/_constants/colors';
import { createXenColorsTuple, createXenTheme, useXenTheme, xenTheme, XenThemeProvider } from 'xen-ui';
import { getTenantFromUrl } from 'xen/helpers';
import { useFeatureFlags } from 'xen/hooks';
import { useConfigurationContext } from 'xen/providers';
import './theme-provider.css';
export const ThemeProvider = ({ children }) => {
    const { newConfigurationsAndSettingsUi: settingsUIEnabled } = useFeatureFlags();
    const { primary, secondary, background } = useConfigurationContext().tenant.branding.colors;
    // NOTE: The legacy app uses "secondary" as their main CTA (button, link) color. So we're manually
    // switching the primary and secondary colors here. When we get these values from the API this
    // won't be necessary.
    const { background: legacyBackground, primary: legacySecondary, secondary: legacyPrimary, } = tenantColors[getTenantFromUrl()];
    /**
     * App and tenant-specific theme overrides.
     * Global xen-ui values should be added where xenTheme is defined.
     */
    const theme = createXenTheme({
        ...xenTheme,
        primaryColor: 'primary',
        colors: {
            ...xenTheme.colors,
            primary: createXenColorsTuple(settingsUIEnabled ? primary : legacyPrimary),
            secondary: createXenColorsTuple(settingsUIEnabled ? secondary : legacySecondary),
            background: createXenColorsTuple(settingsUIEnabled ? background : legacyBackground),
        },
    });
    return _jsx(XenThemeProvider, { theme: theme, children: children });
};
export { useXenTheme };
