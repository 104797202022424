import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FaCheck, FaTimes } from 'react-icons/fa'

// Components
import { ApprovalStatus } from './ApprovalStatus'
import ToolTip from './Tooltip/Tooltip'

// Helpers
import { convertToLocalTZ, formatCurrency, formatIntPercentage, formatPercentage } from '../_helpers'
import { getApprovalStatus } from '../_helpers/approval'
import { formatValueByType } from '../_helpers'
import { IconContext } from 'react-icons'
import colors from '../_constants/colors'
import { VERIFICATION_LABELS } from '../_views/common/lender/InvoicesOverview/constants'

export const S_Link = styled.div`
  font-weight: bold;
  width: 100%;
  :hover {
    text-decoration: underline;
  }
  a {
    display: inline-block;
    width: 100%;
    :hover {
      text-decoration: underline;
    }
  }
`
const S_DateDifference = styled.div`
  .negative {
    font-weight: bold;
    color: red;
  }
`

export const CellWrapper = (props) => {
  const tooltip = props.column.toolTipContent
  const addExpander = props.addExpander
  const isChild = props.row?.depth
  const toolTipContent = typeof tooltip === 'function' ? tooltip(props.row.original) : tooltip
  const renderEmptyChildCell = isChild && addExpander
  if (renderEmptyChildCell) {
    return null
  }
  return (
    <ToolTip content={toolTipContent}>
      {props.row.canExpand && addExpander ? (
        <span
          className={`cell-expander ${props.row.isExpanded ? 'expanded' : ''}`}
          {...props.row.getToggleRowExpandedProps()}
        >
          {props.children ? props.children : props.value}
        </span>
      ) : (
        <div>{props.children ? props.children : props.value}</div>
      )}
    </ToolTip>
  )
}

export const createColumn = ({
  type,
  id,
  headingText,
  overflow = false,
  getRoute,
  mode = null,
  addExpander = false,
  allowEmpty = false,
  alternativeId,
  ...rest
}) => {
  const defaultAlignment = {
    currency: 'right',
    percentage: 'right',
    'precise-percentage': 'right',
    count: 'right',
  }
  const textAlignment = rest?.textAlignment ?? defaultAlignment[type] ?? 'left'

  // TODO: This is very bloated. These should be separate classes
  switch (type) {
    case 'date':
      return {
        id: id,
        headingText: headingText,
        accessor: (d) => {
          return d[id]
        },
        Cell: (props) => {
          // TODO: this won't work for tenant with positive offsets
          const cellContent = (
            <div className={`table-cell-value align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              {props.value && formatValueByType(props.value, 'truncatedDate')}
            </div>
          )
          return (
            <CellWrapper addExpander={addExpander} {...props}>
              {cellContent}
            </CellWrapper>
          )
        },
        type,
        formatter: (value) => formatValueByType(value, 'truncatedDate'),
        ...rest,
      }
    case 'dateTime':
      return {
        id: id,
        headingText: headingText,
        accessor: (d) => {
          return d[id]
        },
        Cell: (props) => {
          const cellContent = (
            <div className={`table-cell-value align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              {props.value &&
                formatValueByType(convertToLocalTZ(props.value), 'dateTime', {
                  date: 'MM-DD-YYYY',
                  time: 'h:mm:ss A',
                  separator: '/',
                })}
            </div>
          )
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        type,
        formatter: (value) =>
          formatValueByType(value, 'dateTime', {
            date: 'MM-DD-YYYY',
            time: 'h:mm:ss A',
            separator: '/',
          }),
        ...rest,
      }
    case 'date-difference':
      return {
        id: id,
        headingText: headingText,
        accessor: (d) => {
          return d[id]
        },
        Cell: (props) => {
          const cellContent = (
            <S_DateDifference className={`align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              <div className={props.value < 0 ? 'negative' : ''}>{props.value}</div>
            </S_DateDifference>
          )
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        type,
        ...rest,
      }
    case 'approval':
      return {
        accessor: id,
        headingText: 'Status',
        maxWidth: 120,
        Cell: (props) => {
          const approvalObject = props.value
          const statusText = getApprovalStatus(props.row.original)
          const cellContent = (
            <div className={`table-cell-value align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              <ApprovalStatus status={statusText} />
            </div>
          )
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        sortMethod: (a, b) => {
          const approvalCode1 = a ? a.approval_code : 100
          const approvalCode2 = b ? b.approval_code : 100
          return approvalCode1 - approvalCode2
        },
        type,
        ...rest,
      }
    case 'currency':
      return {
        id: id,
        headingText: headingText,
        textAlignment,
        accessor: (d) => {
          return d[id] ? Number(d[id]) : Number(d[alternativeId])
        },
        Cell: (props) => {
          const isEmptyValue = allowEmpty && !props.value
          const cellContent = (
            <div className={`table-cell-value align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              {isEmptyValue ? '' : formatCurrency(props.value || 0)}
            </div>
          )
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        type,
        formatter: (value) => formatCurrency(value || 0),
        pdfFormat: { textAlign: 'right' },
        allowEmpty,
        ...rest,
      }
    case 'percentage':
      return {
        id: id,
        headingText: headingText,
        textAlignment,
        accessor: (d) => {
          return d[id]
        },
        Cell: (props) => {
          const cellContent = (
            <div className={`table-cell-value align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              {formatIntPercentage(props.value)}
            </div>
          )
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        type,
        formatter: (value) => formatIntPercentage(value),
        ...rest,
      }
    case 'precise-percentage':
      return {
        id: id,
        headingText: headingText,
        textAlignment,
        accessor: (d) => {
          return d[id]
        },
        Cell: (props) => {
          const cellContent = (
            <div className={`table-cell-value align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              {formatPercentage(props.value, rest.precision)}
            </div>
          )
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        type,
        ...rest,
      }
    case 'link':
      return {
        id: id,
        headingText: headingText,
        accessor: (d) => {
          return d[id]
        },
        Cell: (props) => {
          const route = getRoute(props.row.original)
          const cellContent = (
            <div className={`table-cell-value align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              {route === undefined ? (
                props.value
              ) : (
                <S_Link>
                  <Link to={route}>{props.value}</Link>
                </S_Link>
              )}
            </div>
          )
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        type,
        ...rest,
      }
    case 'datelink':
      return {
        id: id,
        headingText: headingText,
        accessor: (d) => {
          return d[id]
        },
        Cell: (props) => {
          const route = getRoute(props.row.original)
          const cellContent = (
            <div className={`table-cell-value align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              {route === undefined ? (
                props.value
              ) : (
                <S_Link>
                  <Link to={route}>{new Date(props.value).toLocaleDateString()}</Link>
                </S_Link>
              )}
            </div>
          )
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        type,
        ...rest,
      }
    case 'boolean':
      return {
        id: id,
        headingText: headingText,
        accessor: (d) => {
          return d[id]
        },
        maxWidth: 100,
        Cell: (props) => {
          const cellContent = (
            <div className={`table-cell-value align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              {props.value ? <FaCheck /> : null}
            </div>
          )
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        type,
        ...rest,
      }
    case 'boolean-indicator':
      return {
        id: id,
        headingText: headingText,
        accessor: (d) => {
          return d[id]
        },
        maxWidth: 100,
        mode: mode,
        Cell: (props) => {
          const value = mode === 'reverse' ? !props.value : props.value
          const cellContent = (
            <IconContext.Provider value={{ color: value ? colors.MATERIAL_GREEN : colors.MATERIAL_RED }}>
              {value ? <FaCheck /> : <FaTimes />}
            </IconContext.Provider>
          )
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        type,
        ...rest,
      }
    case 'index':
      return {
        id: id,
        headingText: headingText,
        accessor: (d) => {
          return d[id]
        },
        maxWidth: 100,
        Cell: (props) => {
          const cellContent = props.row.index + 1
          return <CellWrapper {...props}>{cellContent}</CellWrapper>
        },
        type,
        ...rest,
      }
    case 'count':
      return {
        id: id,
        headingText: headingText,
        textAlignment,
        accessor: (d) => {
          return Number(d[id].length)
        },
        type,
        ...rest,
      }
    case 'sentiment':
      return {
        accessor: id,
        headingText: headingText,
        type,
        Cell: (props) => {
          const label = VERIFICATION_LABELS?.[props.value] || null
          const cellContent = (
            <div className={`rt-custom-cell align-${textAlignment} verification ${props.value}`}>
              {props.value !== 'non_verified' ? label : null}
            </div>
          )
          return (
            <CellWrapper show {...props}>
              {cellContent}
            </CellWrapper>
          )
        },
        ...rest,
      }
    case 'expand':
      return {
        id: 'expand',
        headingText: '',
        disableSortBy: true,
        className: 'expand-cell',
        Cell: ({ row }) =>
          row.canExpand && (
            <span
              className={`table-expand-arrow ${row.isExpanded ? 'expanded' : ''}`}
              {...row.getToggleRowExpandedProps()}
            ></span>
          ),
      }
    default:
      return {
        accessor: id,
        headingText: headingText,
        Cell: (props) => {
          const cellContent = (
            <div className={`table-cell-value align-${textAlignment} ${overflow ? '_overflow' : ''}`}>
              {props.value}
            </div>
          )
          return (
            <CellWrapper addExpander={addExpander} {...props}>
              {cellContent}
            </CellWrapper>
          )
        },
        type,
        ...rest,
      }
  }
}
