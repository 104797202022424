import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { queryClient } from 'xen/providers/query-provider';
import { transformInvoiceSettlementResponse } from './transform';
async function createInvoiceSettlementAndPaymentAssociation(batchNumber, checkNumber) {
    try {
        const response = await request({
            method: 'POST',
            url: `/operate/payment_batches/${batchNumber}/collections_checks/${checkNumber}`,
        });
        const data = transformInvoiceSettlementResponse(response);
        return data;
    }
    catch (_error) {
        throw Error('Error creating payment assosiation to invoice settlement');
    }
}
export const useCreatePaymentAssociation = () => {
    return useMutation({
        mutationFn: ({ batchNumber, checkNumber }) => createInvoiceSettlementAndPaymentAssociation(batchNumber, checkNumber),
        onSuccess: (invoiceSettlement) => {
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements', invoiceSettlement.id] });
            queryClient.invalidateQueries({ queryKey: ['payments'] });
        },
    });
};
