import request from './axios_service'
import { formHeader } from '../_helpers'

export const borrowingBaseCertificateService = {
  uploadDocument,
}

export function uploadDocument(file, name, bbcId) {
  const data = new FormData()
  data.append(`filedata`, file)
  data.append(`filename`, file.name)
  data.append(`document_type`, name)

  return request({
    url: `/documents/upload_bbc_document/${bbcId}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// WB Demo - Upload / Get Invoices for BBC
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// First Upload the invoices, then associate them with the specificed bbc
export const uploadInvoicesForBBC = (file, bbcId) => {
  const data = new FormData()
  data.append('file', file)
  data.append('filename', file.name)
  data.append('financing_type', 'abl')

  return request({
    url: `/invoices/bulk_upload/`,
    method: 'POST',
    headers: [formHeader()],
    data,
  }).then((resp) => {
    request({
      url: `/ui_configs/entities/bbc/${bbcId}`,
      method: 'PUT',
      data: resp,
    })
    return resp
  })
}

export const getInvoicesForBBC = (bbcId) => {
  return request({
    url: `/ui_configs/entities/bbc/${bbcId}`,
    method: 'GET',
  })
}
