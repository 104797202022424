import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Group, Stack } from 'xen-ui';
import { useCreateInvoiceSchedule } from 'xen/api/use-invoice-schedule';
import { ErrorText } from 'xen/components';
import { FormClientSelect } from 'xen/components/form';
import { useXenHistory } from 'xen/hooks';
const invoiceScheduleFormSchema = z.object({
    businessId: FormClientSelect.schema,
});
export const InvoiceScheduleCreateForm = () => {
    const defaultValues = {
        businessId: '',
    };
    const { control, handleSubmit } = useForm({
        resolver: zodResolver(invoiceScheduleFormSchema),
        mode: 'onBlur',
        values: defaultValues,
    });
    const { push } = useXenHistory();
    const { mutateAsync: createInvoiceSchedule, isError } = useCreateInvoiceSchedule();
    const onSubmit = async (data) => {
        const payload = {
            businessId: Number(data.businessId),
        };
        const invoiceSchedule = await createInvoiceSchedule(payload);
        push({ to: '/invoice-schedules/:scheduleId/edit', urlParams: [invoiceSchedule.id] });
    };
    const handleFormSubmit = () => {
        handleSubmit(onSubmit)();
    };
    return (_jsx(Form, { control: control, onSubmit: handleFormSubmit, children: _jsx(Group, { children: _jsxs(Stack, { gap: "lg", children: [isError && _jsx(ErrorText, { message: "Error creating invoice schedule" }), _jsx(FormClientSelect, { control: control, description: "Select the associated client", name: "businessId", onChange: handleFormSubmit, required: true })] }) }) }));
};
