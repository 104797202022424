export const RenderIf = ({ condition, children }) => {
  if (!condition) {
    return null
  }
  if (children) {
    return children
  }
  return null
}

RenderIf.defaultProps = {
  condition: false,
  children: null,
}
