import { useQuery } from '@tanstack/react-query';
import { generateCancelToken } from 'legacy/_services/axios_service';
import { request } from 'xen/api';
import { getTenantFromUrl } from 'xen/helpers/url';
import { transformTenantResponse } from './transform';
async function getTenant({ tenantToken }) {
    try {
        // We will eventually replace this with a new axios instance.
        const response = await request({
            url: `/tenants/${tenantToken}`,
            method: 'GET',
            cancelToken: generateCancelToken().token,
        });
        return transformTenantResponse(response);
    }
    catch (error) {
        throw Error('Error getting tenant');
    }
}
export const useGetTenant = () => {
    const tenantToken = getTenantFromUrl();
    return useQuery({
        queryKey: ['tenants', tenantToken],
        queryFn: () => getTenant({ tenantToken }),
        enabled: tenantToken !== undefined,
    });
};
