import React, { useState, useEffect } from 'react'
import { unstable_batchedUpdates } from 'react-dom'

// Components
import { FormInput } from '../../FormInput'

const formatSSNField = (value) => {
  const n = value.length
  if (n < 4) return value
  else if (n < 6) return [value.slice(0, 3), '-', value.slice(3)].join('')
  else return [value.slice(0, 3), '-', value.slice(3, 5), '-', value.slice(5)].join('')
}

const formatEINField = (value) => {
  const n = value.length
  if (n < 3) return value
  else return [value.slice(0, 2), '-', value.slice(2)].join('')
}

const updateFromProps = (type, value, defaultValue) => {
  // Generally these will be uncontrolled
  const isControlled = value !== undefined
  let valueToUse = isControlled ? value : defaultValue
  valueToUse = valueToUse.replace(/\D/g, '')
  let newDisplayValue = valueToUse

  if (type === 'business_ein') {
    newDisplayValue = formatEINField(valueToUse)
  }

  if (type === 'ssn') {
    newDisplayValue = formatSSNField(valueToUse)
  }
  return newDisplayValue
}

export const SecureField = (props) => {
  const { id = null, value, defaultValue, onChange = () => {}, type, shouldShow = true, ...rest } = props
  const [displayValue, setDisplayValue] = useState(updateFromProps(type, value, defaultValue))

  // Allow us to reset the field when ID changes
  useEffect(() => {
    if (shouldShow) {
      setDisplayValue(updateFromProps(type, value, defaultValue))
    } else {
      setDisplayValue('')
      onChange('', id)
    }
  }, [id, value, shouldShow])

  const handleChangeLocal = (e) => {
    let target = e.target
    let newValue = target.value
    let newCursor = target.selectionStart
    let replacedVal = newValue.replace(/\D/g, '').slice(0, 9)
    let newDisplayValue = newValue

    if (type === 'business_ein') {
      newDisplayValue = formatEINField(replacedVal)
    }

    if (type === 'ssn') {
      newDisplayValue = formatSSNField(replacedVal)
    }

    unstable_batchedUpdates(() => {
      setDisplayValue(newDisplayValue)
    })

    setTimeout(() => {
      target.selectionStart = target.selectionEnd = newCursor + (newDisplayValue.length - newValue.length)
    }, 0)

    // Callback
    onChange(newDisplayValue, id)
  }

  // Class Name
  const className = `secure ${props.className ? props.className : ''}`

  return (
    <FormInput
      {...rest}
      autoComplete="off"
      className={className}
      id={id}
      onChange={handleChangeLocal}
      type="text"
      value={displayValue}
    />
  )
}
