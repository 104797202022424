import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from 'xen-ui';
import { TextLink } from '../link';
const featureNames = {
    bankingIntegration: 'Banking integration',
    customBranding: 'Custom branding',
    dataValidation: 'Data validation',
    documentAnalysis: 'Document analysis',
    eSignature: 'E-signature',
};
export const PremiumFeatureAlert = ({ feature, children, ...props }) => {
    const featureName = featureNames[feature];
    const defaultChildren = (_jsxs(_Fragment, { children: [_jsx(TextLink, { c: "blue", to: `mailto:hello@xenplatforms.com?subject=XEN premium feature - ${featureName}`, children: "Contact us" }), ' ', "to learn more about ", _jsx("b", { children: featureName }), " features."] }));
    return (_jsx(Alert, { color: "blue", icon: "Lock", title: "Premium feature", variant: "light", ...props, children: children || defaultChildren }));
};
