import { envConstants } from '../_constants'

export function env(state = {}, action) {
  switch (action.type) {
    case envConstants.SET_ENV:
      return {
        tenant: action.tenant,
        env: action.env,
      }
    default:
      return state
  }
}
