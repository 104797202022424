import { plaidConstants } from '../../_constants'

const initialState = {
  [plaidConstants.STATUS_ATTR]: plaidConstants.STATUS_NOT_CONNECT,
}

export function plaid(state = initialState, action) {
  switch (action.type) {
    case plaidConstants.CONNECT_PLAID_SUCCESS:
      return {
        [plaidConstants.STATUS_ATTR]: plaidConstants.STATUS_CONNECT,
      }
    case plaidConstants.CONNECT_PLAID_FAILURE:
      return {
        [plaidConstants.STATUS_ATTR]: plaidConstants.STATUS_NOT_CONNECT,
      }
    case plaidConstants.DISCONNECT_PLAID:
      return {
        [plaidConstants.STATUS_ATTR]: plaidConstants.STATUS_NOT_CONNECT,
      }
    default:
      return state
  }
}
