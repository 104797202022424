// Styled Components
import styled, { css } from 'styled-components'
import { S_FormRow } from '../FormRow'
import colors from '../../../_constants/colors'
import { ButtonComponent } from '../../Button'

// Styled Components
export const S_ValidationForm = styled.div`
  .section-title {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  ${S_FormRow} {
    margin-bottom: 10px;

    &.hide {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(p) =>
    !p.requiredIndicator &&
    css`
      .required-indicator {
        display: none;
      }
    `}

  .validation-form-label {
    margin-bottom: 10px;
    font-size: 16px;
    color: ${colors.TEXT_DARK};
    display: flex;
    align-items: center;
  }

  .validation-form-row {
    display: flex;
  }
`
export const S_ExpandButton = styled.div`
  ${ButtonComponent} {
    padding: 0;
    min-width: 21px;
    background-color: ${colors.DARK_GREY};
    border-color: ${colors.DARK_GREY};
    margin-left: 5px;
  }
`
