import React from 'react'
import { useSelector } from 'react-redux'
import { FaPhone, FaEnvelope } from 'react-icons/fa'

// CONSTANTS
import urls from '../../_constants/urls'
import { footerConfig } from '../../_configs'

// Components
import { SocialIcons } from '../SocialIcons'
import { Copyright } from '../Copyright'
import { FooterBar, FooterLogo } from './Footer.styles'

export const Footer = () => {
  const { env, authentication, user } = useSelector((state) => state)
  const environment = env.env
  const tenant = env.tenant

  const config = footerConfig[tenant]

  const logo = `${urls.IMAGES}/${environment}/${tenant}/footer_logo.png`

  return (
    <FooterBar>
      <div className={`footer-main`}>
        <FooterLogo alt={`${tenant} logo`} src={logo} />
        <div className={`rows`}>
          {config.map((row, i) => (
            <div className={`row`} key={i}>
              {row.map((item, i) => (
                <a className={`link ${item.nohover ? '' : 'hover-effect'}`} href={item.link} key={i}>
                  {item.type === `phone` && <FaPhone className={`icon`} />}
                  {item.type === `email` && <FaEnvelope className={`icon`} />}
                  {item.label}
                </a>
              ))}
            </div>
          ))}
        </div>
        <div className={`si-container`}>
          <SocialIcons />
        </div>
      </div>
      <Copyright />
    </FooterBar>
  )
}
