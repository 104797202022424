import request from './axios_service'
import { tenantHeader } from '../_helpers'
import config from 'config'

function signUp(credentials, reCaptchaToken = null) {
  let data = {
    user: {
      email_address: credentials.email,
      password: credentials.password,
      first_name: credentials.first_name,
      last_name: credentials.last_name,
      full_name:
        credentials.first_name && credentials.last_name ? `${credentials.first_name} ${credentials.last_name}` : null,
      traffic_ip: ``,
      traffic_user_agent: navigator.userAgent,
      landing_page_referrer: `direct`,
    },
    business: {
      business_phone_number: credentials.business_phone_number,
      business_name: credentials.business_name,
      business_website: credentials.business_website,
    },
    //add business additional
  }
  if (credentials.salesPerson) {
    data.business.sales_person_id = credentials.salesPerson
  }
  if (credentials.tell_us_more) {
    data.business_additional = {
      ...data.business_additional,
      tell_us_more: credentials.tell_us_more,
    }
  }

  if (credentials.how_hear_about_us) {
    data.business_additional = {
      ...data.business_additional,
      how_hear_about_us: credentials.how_hear_about_us,
    }
  }

  // For ACS
  if (typeof credentials.is_carrier !== undefined) {
    data.business_additional = {
      ...data.business_additional,
      is_carrier: credentials.is_carrier,
      is_new_carrier: credentials.is_new_carrier || false,
    }
  }

  //Add recaptcha token

  if (reCaptchaToken) {
    data.recaptcha_token = reCaptchaToken
  }

  let queryParams = new URLSearchParams(window.location.search)
  if (queryParams.has('ref')) {
    data.user.landing_page_referrer = queryParams.get('ref')
  }
  return request({
    url: `/facade/registrations?login_user=true`,
    method: 'POST',
    data: data,
  })
}

function signIn(username, password, noToken = false, raw = false, financing_type = 'factoring') {
  return request(
    {
      url: `/login/login`,
      method: 'PUT',
      data: {
        email_address: username,
        password: password,
        financing_type: financing_type,
      },
    },
    //The endpoint returns a session if a session token header is sent regardless of whether or not the username and password are correct.
    noToken ? { ...tenantHeader() } : null,
    raw,
    !raw,
    true
  )
}

function validateSession(session_id) {
  return request({
    url: `/login/current_user/${session_id}`,
    method: 'GET',
    headers: [{ 'session-token': session_id }],
    uncancelable: true,
  })
}

function signOut(session_token) {
  return request({
    url: '/login/invalidate',
    method: 'PUT',
    data: { session_token },
    uncancelable: true,
  })
}

// Sends forgot Password Email
function forgotPassword(email_address) {
  return request({
    url: '/emails/forgot_password',
    method: 'POST',
    data: { email_address },
  })
}

function resetPassword(email, tempPassword, newPassword, userid) {
  if (tempPassword) {
    return request({
      url: '/users/reset_password',
      method: 'PUT',
      data: {
        email_address: email,
        rand_password: tempPassword,
        new_password: newPassword,
      },
    })
  } else {
    return request({
      url: '/users/' + userid,
      method: 'PUT',
      data: {
        user: {
          id: userid,
          password: newPassword,
          reset_password_required: false,
        },
      },
    })
  }
}

function loginLink(email, dealId = null) {
  const urlParams = new URLSearchParams()
  urlParams.append('email_address', email)
  urlParams.append('underwrite_deal_id', dealId)
  return request({
    url: `login/link?${urlParams.toString()}`,
    method: 'GET',
  })
}

const getSignUpFormAndMarketing = (tenantToken) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/forms/signup`,
    method: 'GET',
    headers: [{ 'tenant-token': tenantToken }],
  })
}

const signUpWithDealInfo = (customPayload) => {
  return request(
    {
      url: `/underwrite/api/v1/signup`,
      method: 'POST',
      data: customPayload,
      headers: [tenantHeader()],
    },
    { ...tenantHeader() }
  )
}

export const authenticationService = {
  signUp,
  signIn,
  validateSession,
  signOut,
  forgotPassword,
  resetPassword,
  loginLink,
  getSignUpFormAndMarketing,
  signUpWithDealInfo,
}
