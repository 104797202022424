import { reportsUpdateConstants } from '../_constants'

export const reportsUpdateActions = {
  setReportsLastUpdate,
  setReportsShouldNotify,
}

function setReportsLastUpdate(newTimestamp) {
  return { type: reportsUpdateConstants.SET_REPORTS_LAST_UPDATE, payload: newTimestamp }
}

function setReportsShouldNotify(shouldNotify = true) {
  return { type: reportsUpdateConstants.SET_REPORTS_SHOULD_NOTIFY, payload: shouldNotify }
}
