import styled, { css } from 'styled-components'
import { S_Chip } from './Chip.styles'
import { cssBreakpoints, textInputStyles } from '../../_constants/styles'
import colors from '../../_constants/colors'

export const suggestBoxMobileHeight = 150

export const S_AutoCompleteInput = styled.div`
    position: relative;
    font-size: 16px;

    .autocomplete-field {
        margin: 0;
        padding: 0;
        outline: none;
        border: none;
        min-height: 20px;
        height: 100%;
        width: 100%;
    }

    &.showInputStyles,
    &.inputMode {
        ${css`
          ${textInputStyles}
          background: ${colors.WHITE};
        `}}
    }

    &.inputMode {
        padding: 8.5px 12px;
    }

    &.showInputStyles {
        padding: 6px 12px;
    }

    .suggestion-box-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0;
        min-width: 100%;
        z-index: 31;
    }
    .suggestion-box {
        top: 0;
        min-height: 30px;
        overflow: hidden;
        white-space: nowrap;

        ${(props) =>
          props.limitMobileHeight &&
          css`
            @media screen and (max-width: ${cssBreakpoints.mobileMd}) {
              max-height: ${suggestBoxMobileHeight}px;
              overflow: auto;
            }
          `}
    }
    .open {
        border: 1px solid #e8e8e8;
        border-width: 1px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: white;
    }

    .suggestion {
        background-color: white;
        cursor: pointer;
        padding: 8px;
    }

    .default-suggestion {
        background-color: #e8e8e8;
        padding: 8px;
        border: 1px solid #e8e8e8;
        border-width: 1px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .suggestion:hover {
        background-color: #eee;
    }

    .warning-icon {
        margin-left: 4px;
        margin-top: 3px;
    }

    .add-button {
        background-color: #aeaeae;
        border: 1px solid #aeaeae;
        cursor: pointer;
        padding: 8px;
    }

    .close {
        display: none;
    }

    .active {
        background-color: #e8e8e8;
    }

    .chip-position {
        max-width: 98%;
        max-height: ${(props) => (props.multiline ? 'none' : '28px')};
        width: fit-content;
    }

    ${S_Chip} {
        .chip-text {
            text-overflow: ${(props) => (props.multiline ? 'initial' : 'ellipse')};
            white-space: ${(props) => (props.multiline ? 'initial' : 'nowrap')};
        }
    }

    .loading {
        display: flex;
        justify-content: center;
    }
`
