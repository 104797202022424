import styled, { css } from 'styled-components'

const propHasValue = (values) => values.some((value) => value !== false)

const getPadding = (props) => {
  const { pt, pr, pl, pb, p } = props

  if (!propHasValue([pt, pr, pl, pb, p])) {
    return ''
  }

  const defaultPadding = p === false ? 0 : p

  return css`
    padding: ${pt || defaultPadding} ${pr || defaultPadding} ${pb || defaultPadding} ${pl || defaultPadding};
  `
}

const getMargin = (props) => {
  const { mt, mr, ml, mb, m } = props

  if (!propHasValue([mt, mr, ml, mb, m])) {
    return ''
  }

  const defaultMargin = m === false ? 0 : m

  return css`
    margin: ${mt || defaultMargin} ${mr || defaultMargin} ${mb || defaultMargin} ${ml || defaultMargin};
  `
}

const getBorderWidth = (props) => {
  const { borderWidth, bwt, bwr, bwb, bwl } = props

  if (!propHasValue([borderWidth, bwt, bwr, bwb, bwl])) {
    return ''
  }

  const defaultValue = borderWidth === false ? 0 : borderWidth

  return css`
    border-width: ${bwt || defaultValue} ${bwr || defaultValue} ${bwb || defaultValue} ${bwl || defaultValue};
  `
}

export const S_Box = styled.div`
  ${(props) => getBorderWidth(props)}
  ${(props) => (props.boxHeight ? `height: ${props.boxHeight};` : '')}
    ${(props) => (props.boxMinHeight ? `min-height: ${props.boxMinHeight};` : '')}
    ${(props) => (props.boxWidth ? `width: ${props.boxWidth};` : '')}
    ${(props) => (props.boxMaxWidth ? `max-width: ${props.boxMaxWidth};` : '')}
    ${(props) => (props.boxMinWidth ? `min-width: ${props.boxMinWidth};` : '')}
    ${(props) => (props.boxBackgroundColor ? `background-color: ${props.boxBackgroundColor};` : '')}
    ${(props) => (props.position ? `position: ${props.position};` : '')}
    ${(props) =>
    getBorderWidth(props) && props.borderColor && props.borderStyle ? `border-style: ${props.borderStyle};` : ''}
    ${(props) => (props.borderColor ? `border-color: ${props.borderColor};` : '')}
    ${(props) => (props.borderRadius ? `border-radius: ${props.borderRadius};` : '')}
    ${(props) => getPadding(props)}
    ${(props) => getMargin(props)}
  
     ${(props) => (props.d ? `display: ${props.d};` : '')}
     ${(props) => (props.fd ? `flex-direction: ${props.fd};` : '')}
     ${(props) => (props.alignItems ? `align-items: ${props.alignItems};` : '')}
     ${(props) => (props.justifyContent ? `justify-content: ${props.justifyContent};` : '')}
`
