import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformInvoiceSettlementResponse } from './transform';
async function getInvoiceSettlement(id) {
    try {
        const response = await request({
            method: 'GET',
            url: `/operate/payment_batches/${id}`,
        });
        const data = transformInvoiceSettlementResponse(response);
        return data;
    }
    catch (_error) {
        throw Error('Error fetching invoice settlement');
    }
}
export const useGetInvoiceSettlement = (id) => {
    return useQuery({
        queryKey: ['invoice-settlements', id],
        queryFn: () => getInvoiceSettlement(id),
        enabled: id !== undefined,
    });
};
