import React from 'react'
import { Field } from 'formik'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const numberMask = createNumberMask({
  allowDecimal: true,
  prefix: ``,
  suffix: `%`,
})

const removeCharacters = (value) => {
  if (value && typeof value === `string`) {
    return value.replace(`%`, ``)
  } else if (value) {
    return value
  } else {
    return null
  }
}

const PercentageField = ({ field, formikName, handleBlur, invalid }) => (
  <Field
    className={invalid ? 'invalid-input' : ''}
    name={formikName}
    render={({ field: formikField }) => {
      return (
        <MaskedInput
          mask={numberMask}
          {...formikField}
          inputMode="numeric"
          onBlur={(e) => {
            handleBlur(formikName, field.name, removeCharacters(e.target.value))
          }}
          style={{
            width: `100%`,
            height: `50px`,
            borderRadius: `5px`,
            border: `1px solid ${invalid ? `#d93025` : `#ccc`}`,
            padding: `1em`,
            fontSize: `1rem`,
            outline: `none`,
          }}
        />
      )
    }}
    validate={(value) => {
      let errorMessage
      if (field.required && !value) {
        errorMessage = `Field is required.`
      } else if (value && Number(removeCharacters(value)) > 100) {
        errorMessage = `Percentage cannot be > 100.`
      }

      return errorMessage
    }}
  />
)

export default PercentageField
