import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformSerializedInvoiceVerification } from './transform';
const transformInvoiceVerificationPayload = (payload) => {
    return {
        body: payload.body,
        contact_id: payload.contactId ? String(payload.contactId) : null,
        verification_type_id: payload.verificationTypeId,
    };
};
async function createInvoiceVerification({ urlParams: { invoiceId }, payload, }) {
    try {
        const response = await request({
            method: 'POST',
            url: `/invoices/${invoiceId}/verifications`,
            data: transformInvoiceVerificationPayload(payload),
        });
        const data = transformSerializedInvoiceVerification(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not create invoice verification');
    }
}
export const useCreateInvoiceVerification = ({ urlParams: { invoiceId }, }) => {
    return useMutation({
        mutationKey: ['invoices', invoiceId, 'verifications'],
        mutationFn: (params) => createInvoiceVerification({ urlParams: { invoiceId }, payload: params.payload }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (invoiceVerification) => {
            notifications.success(`Added invoice verification ${invoiceVerification.id}`);
            queryClient.invalidateQueries({ queryKey: ['invoices'] });
        },
    });
};
