import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { notificationsActions } from '../_actions'

export const notificationActionHooks = {
  useShowNotification,
}

function useShowNotification(content) {
  const dispatch = useDispatch()
  return useCallback(
    (content) => {
      const { type, message, id } = content
      return dispatch(
        notificationsActions.showNotification({
          type,
          message,
          id,
        })
      )
    },
    [dispatch, JSON.stringify(content)]
  )
}

useShowNotification.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  id: PropTypes.string,
}
useShowNotification.defaultProps = {
  type: 'success',
  message: 'success',
  id: null,
}
