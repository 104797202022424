export const businessOfficersConstants = {
  GET_BUSINESS_OFFICERS_REQUEST: 'GET_BUSINESS_OFFICERS_REQUEST',
  GET_BUSINESS_OFFICERS_SUCCESS: 'GET_BUSINESS_OFFICERS_SUCCESS',
  GET_BUSINESS_OFFICERS_FAILURE: 'GET_BUSINESS_OFFICERS_FAILURE',

  UPDATE_BUSINESS_OFFICER_REQUEST: 'UPDATE_BUSINESS_OFFICER_REQUEST',
  UPDATE_BUSINESS_OFFICER_SUCCESS: 'UPDATE_BUSINESS_OFFICER_SUCCESS',
  UPDATE_BUSINESS_OFFICER_FAILURE: 'UPDATE_BUSINESS_OFFICER_FAILURE',

  CREATE_BUSINESS_OFFICER_REQUEST: 'CREATE_BUSINESS_OFFICER_REQUEST',
  CREATE_BUSINESS_OFFICER_SUCCESS: 'CREATE_BUSINESS_OFFICER_SUCCESS',
  CREATE_BUSINESS_OFFICER_FAILURE: 'CREATE_BUSINESS_OFFICER_FAILURE',

  DELETE_BUSINESS_OFFICER_REQUEST: 'DELETE_BUSINESS_OFFICER_REQUEST',
  DELETE_BUSINESS_OFFICER_SUCCESS: 'DELETE_BUSINESS_OFFICER_SUCCESS',
  DELETE_BUSINESS_OFFICER_FAILURE: 'DELETE_BUSINESS_OFFICER_FAILURE',
}
