import React, { useState, useEffect } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import styled from 'styled-components'
import { ClipLoader } from 'react-spinners'

// Components
import { EmptyMessage } from '../EmptyMessage'
import { PaginationControls } from '../PaginationControls'
import ToolTip from '../Tooltip/Tooltip'

// Utils
import { logError } from '../../_helpers/errors'
import { createSearchParams } from '../../_helpers/search'
import { useCancelRequest } from '../../_services/axios_service'
import { UPLOAD_ERRORS } from '../../_views/ClientViews/UploadInvoices/constants'
import { exportTableData } from '../../_helpers/export-table/exportTable.helper'
import { S_QuestionRoundedIcon } from '../ListSearchableView'
import { H2 } from '../Typography/Components/Headings/H2'
import { FilterIndicators } from './FilterIndicators/FilterIndicators'

// Build a string to show for the result count and filter term
export const getResultsString = (numResults, searchTerm, exactMatch = false) => {
  // If we're matching exactly, not searching, indicate that in label
  const resultsWord =
    exactMatch && searchTerm !== ''
      ? numResults === 1
        ? 'exact match'
        : 'exact matches'
      : numResults === 1
        ? 'result'
        : 'results'
  return searchTerm !== '' ? `${numResults} ${resultsWord} for '${searchTerm}'` : `${numResults} ${resultsWord}`
}

/**
 * Styled Component
 */
export const S_SearchableView = styled.div`
  .tool-tip-icon {
    margin-left: 8px;
    color: #999;
  }
  .tool-tip-icon:hover {
    color: #777;
  }
`

// Defines strategies to handle the pagination when adding a new Entities
export const NEW_ENTITY_PAGE_STRATEGIES = {
  DEFAULT: 'DEFAULT', // A new Entity included after the pagination limit is saved before calling 'endpointCall' function
  TRANSIENT: 'TRANSIENT', //  A new Entity included after the pagination limit is added to 'results' state
}

/**
 * Creates a single filterable, sortable view
 */
export const SearchableView = ({
  viewConfig,
  searchTerm = '',
  change,
  searchParam,
  showLoading = false,
  searchFilters,
  allowDownload,
  removeFilter,
  newEntityPageStrategy = NEW_ENTITY_PAGE_STRATEGIES['DEFAULT'],
}) => {
  const {
    endpointCall,
    title = '',
    emptyMessage = 'No Results',
    Component,
    requestMethod = 'GET',
    initialTotal = 0,
    pageSize = 10,
    beforeTable = null,
    exactMatch = false,
    pageSizes = [10, 25, 50, 100],
    pageSizePostfix = 'results/page',
    defaultSort = [],
    removeMethod = () => {},
    addMethod = () => {},
    updateMethod = () => {},
    onResultsUpdate = () => {},
    onPageUpdate = () => {},
    updateResults = () => {},
    columns = [],
    showPagination = true,
    explainer = '',
    fetchReload = () => {},
    lastPageDefault = false,
    numRecords = null,
    ActionComponent = null,
    editableMode = false,
    handleExport = null,
    initialPage = 1,
    onPaginate = () => {},
  } = viewConfig
  const [isLoading, setIsLoading] = useState(true)
  const [results, setResults] = useState([])
  const [totalResults, setTotalResults] = useState(initialTotal)
  const [pageNum, setPageNum] = useState(initialPage)
  const [pendingSort, setPendingSort] = useState(defaultSort)
  const [sort, setSort] = useState([])
  const [resultsString, setResultsString] = useState('')
  const [actualSearchTerm, setActualSearchTerm] = useState(searchTerm)
  const [actualSearchFilters, setActualSearchFilters] = useState(searchFilters)
  const [recordsPerPage, setRecordsPerPage] = useState(pageSize)
  const [updateLoading, setupdateLoading] = useState(false)
  const [cancelTokenRef, cancelRequest] = useCancelRequest()
  const [loadedNumRecords, setLoadedNumRecords] = useState(false)
  const [totals, setTotals] = useState(null)
  const [entityForNewPage, setEntityForNewPage] = useState(null)

  const showNotification = () => {}

  const addNewPageEntity = (newResults, newTotalResults) => {
    addMethod(newResults, entityForNewPage)
    newResults = [...newResults, entityForNewPage]
    newTotalResults += 1
    setEntityForNewPage(null)
    return { newResults, newTotalResults }
  }

  const loadFn = () => {
    // cancelRequest()

    if (lastPageDefault && typeof numRecords === 'function' && !loadedNumRecords) {
      numRecords().then((numberOfRecords) => {
        setTotalResults(numberOfRecords)
        setLoadedNumRecords(true)
      })
      return
    }

    const searchParams = createSearchParams({
      searchTerm: actualSearchTerm,
      pageSize: recordsPerPage,
      pageNum,
      sort: pendingSort,
      requestMethod,
      searchParam,
      searchFilters,
    })

    setupdateLoading(true)
    endpointCall(searchParams, cancelTokenRef.current).then((response) => {
      unstable_batchedUpdates(() => {
        let newResults = results
        let newTotalResults = totalResults
        let newTotals = totals

        // Handle both object and array responses
        if (Array.isArray(response)) {
          newResults = response
        } else {
          newResults = response.results
          newTotalResults = response.total_results
          if (response.totals) {
            newTotals = response.totals
          }
        }
        if (newEntityPageStrategy == NEW_ENTITY_PAGE_STRATEGIES['TRANSIENT'] && entityForNewPage)
          ({ newResults, newTotalResults } = addNewPageEntity(newResults, newTotalResults))

        setSort(pendingSort)
        setupdateLoading(false)
        setIsLoading(false)

        setResults(newResults)
        setTotalResults(newTotalResults)
        setTotals(newTotals)
        setResultsString(getResultsString(newTotalResults, actualSearchTerm, exactMatch))

        onResultsUpdate(newResults)
      })
    }, logError)
  }

  // Call endpoint
  useEffect(loadFn, [actualSearchTerm, actualSearchFilters, pageNum, pendingSort, change, recordsPerPage])

  // Call this function once to set up a pipeline to force override the current results
  // Calling this will also call back to the parent to let it set the current state the same way
  useEffect(() => {
    fetchReload(loadFn)
    updateResults((newResults) => {
      setResults(newResults)
      onResultsUpdate(newResults)
    })
  }, [])

  // Reset search term and page num based on prop change
  useEffect(() => {
    unstable_batchedUpdates(() => {
      setActualSearchTerm(searchTerm)
      setActualSearchFilters(searchFilters)
      setPageNum(initialPage)
    })
  }, [searchTerm, searchFilters])

  useEffect(() => {
    if (lastPageDefault) {
      unstable_batchedUpdates(() => {
        setPageNum(Math.ceil(totalResults / recordsPerPage))
      })
    }
  }, [totalResults, recordsPerPage])

  useEffect(() => {
    onPageUpdate(pageNum)
  }, [pageNum])

  // Reset sort and page num
  const handleSortChange = (newSorted) => {
    unstable_batchedUpdates(() => {
      setPendingSort(newSorted)
      !lastPageDefault && setPageNum(initialPage)
    })
  }

  const handleRemove = (id) => {
    let newResults = removeMethod(results, id)
    let newTotalResults = totalResults

    setTotalResults((prev) => {
      newTotalResults = prev - 1
      return newTotalResults
    })

    const lastPageIndex = Math.ceil(newTotalResults / recordsPerPage)

    // Show prev page if removed entity was the last in the batch
    if (!newResults.length && pageNum > 1) {
      setPageNum((prev) => prev - 1)
      return
    }

    // Update current page entities if it's not last page
    if (newResults.length < recordsPerPage && pageNum !== lastPageIndex) {
      loadFn()
      return
    }

    setResults(newResults)
  }

  const handleAdd = (newEntity) => {
    let newResults = addMethod(results, newEntity)
    let newTotalResults = totalResults
    setTotalResults((prev) => {
      newTotalResults = prev + 1
      return newTotalResults
    })

    // Show next page if new entity will show on new page
    if (newResults.length > recordsPerPage) {
      const lastPageIndex = Math.ceil(newTotalResults / recordsPerPage)
      setPageNum(lastPageIndex)
      if (newEntityPageStrategy == NEW_ENTITY_PAGE_STRATEGIES['TRANSIENT']) setEntityForNewPage(newEntity)
      return
    }

    setResults(newResults)
  }

  const handleUpdate = (updatedEntity) => {
    let newResults = updateMethod(results, updatedEntity)
    setResults(newResults)
    onResultsUpdate(newResults)
  }

  // This can be called from the Component (usually Table) that renders
  // the SearchableView when updating values inline to trigger re-rendering.
  const handleUpdateResults = (newResults) => {
    setResults(newResults)
    onResultsUpdate(newResults)
  }

  // Determine content
  const hasResults = results && results.length > 0
  const showEditMode = !totalResults && editableMode
  const overviewContent =
    !hasResults && !showEditMode ? (
      <EmptyMessage message={emptyMessage} />
    ) : (
      <>
        <Component
          columns={columns}
          defaultSort={defaultSort}
          handleAdd={(entity) => handleAdd(entity)}
          handleRemove={(id) => handleRemove(id)}
          handleSortChange={handleSortChange}
          handleUpdateEntity={(entity) => handleUpdate(entity)}
          handleUpdateResults={handleUpdateResults}
          pageSize={recordsPerPage}
          refresh={loadFn}
          results={results}
          sort={sort}
          totalResults={totalResults}
          totals={totals}
        />
      </>
    )

  return (
    <S_SearchableView>
      {/* Header */}
      {title !== '' && (
        <header className="overview-header">
          <div className="left">
            <H2 data-testid={'overview-title'} fw={'400'} mr={'4px'}>
              {title}
            </H2>
            {explainer ? (
              <ToolTip className={'overview-tooltip'} content={explainer}>
                <S_QuestionRoundedIcon />
              </ToolTip>
            ) : null}
            {(isLoading || updateLoading) && (
              <div className={`loader`}>
                <ClipLoader
                  color={'#000'}
                  css={{
                    display: `block`,
                    margin: `5px`,
                    animationDuration: `1.5s !important`,
                  }}
                  size={20}
                />
              </div>
            )}
            {!isLoading && <div className="total-indicator">{resultsString}</div>}
            <FilterIndicators removeFilter={removeFilter} searchFilters={searchFilters} />
          </div>
          {ActionComponent && <ActionComponent />}
        </header>
      )}

      {/* Content */}
      {beforeTable}
      {overviewContent}
      {hasResults && showPagination && (
        <PaginationControls
          isLoadingDownload={isLoading}
          lastPageDefault={lastPageDefault}
          loading={updateLoading || showLoading}
          onDownloadClick={async (type) => {
            setIsLoading(true)
            const searchParams = createSearchParams({
              searchTerm: actualSearchTerm,
              pageSize: 100000,
              pageNum: 1,
              sort: pendingSort,
              requestMethod,
              searchParam,
              searchFilters,
            })
            try {
              const results = await endpointCall(searchParams)
              const data = [...results.results]
              if (results.totals) {
                data.push(results.totals)
              }
              if (handleExport) {
                handleExport(type, data, columns)
              } else {
                exportTableData(type, data, columns)
              }
            } catch (err) {
              console.error('TCL: ', err)
              showNotification({
                type: `error`,
                message: UPLOAD_ERRORS.SCHEDULE_UPLOAD__UNEXPECTED_ERROR,
              })
            }
            setIsLoading(false)
          }}
          onNextPage={() => {
            const nextPage = pageNum + 1
            setPageNum(nextPage)
            onPaginate(nextPage)
          }}
          onPageSelected={(page) => {
            setPageNum(page)
          }}
          onPageSizeChange={(newPageSize) => {
            setRecordsPerPage(newPageSize)
            !lastPageDefault && setPageNum(1)
          }}
          onPrevPage={() => {
            const previousPage = pageNum - 1
            setPageNum(previousPage)
            onPaginate(previousPage)
          }}
          pageNum={pageNum}
          pageSize={recordsPerPage}
          pageSizePostfix={pageSizePostfix}
          pageSizes={pageSizes}
          showDownload={allowDownload}
          totalResults={totalResults}
        />
      )}
    </S_SearchableView>
  )
}
