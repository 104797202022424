import { ConfigProxy } from '../_helpers/util'
export const socialMediaConfig = ConfigProxy(
  {
    finvoice: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/finvoiceco',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/finvoice',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/finvoice',
      },
    ],
    capitalnow: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/capitalnowinc',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/capital-now-inc/',
      },
      {
        type: 'youtube',
        link: 'https://www.youtube.com/channel/UCcsoHafAupjbXqc0rpF8ILA',
      },
    ],
    sevenoaks: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/SevenOaksCapital/',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/6326543/',
      },
    ],
    boldbusinesscapital: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/BoldBusinessCapital',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/boldbusinesscapital',
      },
    ],
    velocityfi: [
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/velocity-financial-accounts-receivable-factoring',
      },
    ],
    capitalplus: [
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/capitalplusconstruction/mycompany/',
      },
    ],
    capitalplusfinancial: [
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/capitalplusconstruction/mycompany/',
      },
    ],
    changecapital: [
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/change-capital/',
      },
    ],
    twelvefive: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/12fiveCapital',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/12five',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/12fiveCapital',
      },
    ],
    sallyportcf: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/sallyportcf',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/sallyportcf',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/sallyport-commercial-finance-llc',
      },
    ],
    portercapital: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/portercapital/',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/Porter_Cap',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/322882/',
      },
    ],
    marcocapital: [
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/marco-capital/?originalSubdomain=hk',
      },
    ],
    marcofi: [
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/marcofi',
      },
    ],
    newcenturyfinancial: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/newcenturyfinancialinc/',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/NCFinancial',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/new-century-financial',
      },
    ],
    scfactoringsolution: [
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/in/ernesto-a-hidalgo-93113769/',
      },
    ],
    chartercapitalusa: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/invoice.factoring.companies',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/ar_factoring',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/charter-capital_2',
      },
    ],
    acsfactors: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/ACSFactors/',
      },
      {
        type: 'twitter',
        link: 'https://www.twitter.com/AcsFactors',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/advance-capital-solutions-llc-dba-acs-factors',
      },
    ],
    ccbg: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/capitalcitybank/',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/CapitalCityBank',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/capital-city-bank-group-inc-',
      },
    ],
    hedayacapital: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/TheHedayaCapitalGroup/',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/hedaya-capital-group/',
      },
    ],
    republicbc: [
      {
        type: 'twitter',
        link: 'https://twitter.com/republicbc',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/republic-business-credit-llc/',
      },
    ],
    newbridgeglobal: [
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/newbridge-global-sourcing/',
      },
    ],
    axosbank: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/AxosBank/',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/AxosBank/',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/showcase/axos-bank-for-business/',
      },
    ],
    factoringdemo: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/finvoiceco',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/finvoice',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/finvoice',
      },
    ],
    abldemo: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/finvoiceco',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/finvoice',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/finvoice',
      },
    ],
    prodemo: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/finvoiceco',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/finvoice',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/finvoice',
      },
    ],
    ctrlpay: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/ctrlpay',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/ctrlpay',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/ctrlpay',
      },
    ],
    breakoutfinance: [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/breakoutcapital',
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/BreakoutCap',
      },
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/breakout-capital-finance',
      },
    ],
    interportcapital: [
      {
        type: 'linkedin',
        link: 'https://www.linkedin.com/company/interportcapital',
      },
    ],
  },
  []
)
