import { adminConstants } from '../_constants'

const intialState = {
  admin_tenant: 1,
}

export function admin(state = intialState, action) {
  switch (action.type) {
    case adminConstants.SET_ADMIN_TENANT:
      return {
        ...state,
        admin_tenant: action.tenant_id,
      }
    default:
      return state
  }
}
