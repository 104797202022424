import React, { useState } from 'react'
import { LoaderButton } from '../../LoaderButton'
import { disconnectPlaidAccess } from '../../../_services/integration/plaid/plaid.service'
import { plaidConstants } from '../../../_constants'
import { colors } from '../../../_constants/colors'
import { PLAID_BTN } from './constants'

export const PlaidDisconnect = ({
  entityId,
  entityType,
  disconnectCb = () => {},
  isConnected = true,
  customCss = {},
  disconnectText = PLAID_BTN.DISCONNECT,
}) => {
  const [loading, setLoading] = useState(false)
  const disconnectPlaid = () => {
    setLoading(true)
    disconnectPlaidAccess(entityId, entityType).then(() => {
      setLoading(false)
      disconnectCb(plaidConstants.STATUS_NOT_CONNECT)
    })
  }

  const buttonText = isConnected ? disconnectText : PLAID_BTN.DISCONNECTED

  return (
    <LoaderButton
      customCss={customCss}
      customcolor={colors.MATERIAL_RED}
      disabled={!isConnected}
      isLoading={loading}
      onClick={disconnectPlaid}
      text={buttonText}
    />
  )
}
