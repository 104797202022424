import axios from 'axios'

const URL = 'https://api.timezonedb.com/'
const KEY = 'WXLN0AF01SHR'

const defaultTimeZone = {
  status: 'OK',
  message: '',
  countryCode: 'US',
  countryName: 'United States',
  regionName: '',
  cityName: '',
  zoneName: 'America Los_Angeles',
  abbreviation: 'PST',
  gmtOffset: -28800,
  dst: '0',
  zoneStart: 1636275600,
  zoneEnd: 1647165600,
  nextAbbreviation: 'PDT',
  timestamp: 1641553182,
  formatted: '2022-01-07 10:59:42',
}

export function getTimezone(tzName) {
  const params = new URLSearchParams()
  params.set('key', KEY)
  params.set('format', 'json')
  params.set('by', 'zone')
  params.set('zone', tzName)
  return axios({
    method: 'get',
    url: `${URL}/v2.1/get-time-zone?${params.toString()}`,
  }).catch(() => {
    return { data: defaultTimeZone }
  })
}
