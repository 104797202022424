import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Title } from '@mantine/core';
import { Group } from '../group';
import { XenIcon } from '../icons';
import { VisuallyHidden } from '../visually-hidden';
export const Heading = ({ children, icon, level, styleVariant, visuallyHidden = false, ...props }) => {
    let content = children;
    if (visuallyHidden) {
        content = _jsx(VisuallyHidden, { "data-testid": "heading-hidden", children: children });
    }
    const headingProps = {
        children: content,
        order: level,
        ...(styleVariant && { size: `h${styleVariant}` }),
        ...props,
    };
    if (icon) {
        const Icon = XenIcon[icon];
        return (_jsx(Title, { "data-testid": "heading", ...headingProps, children: _jsxs(Group, { component: "span", gap: "xxs", children: [_jsx(Icon, { "aria-hidden": true }), children] }) }));
    }
    return _jsx(Title, { "data-testid": "heading", ...headingProps });
};
