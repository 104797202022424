import { topBusinessDebtorsConstants } from '../_constants'
import { topBusinessDebtorsService } from '../_services'

export const topBusinessDebtorsActions = {
  getTopBusinessDebtors,
  createBusinessDebtor,
  updateDebtor,
  addTopBusinessDebtor,
  updateBusinessDebtor,
  removeTopBusinessDebtor,
  deleteTopBusinessDebtor,
}

function getTopBusinessDebtors(businessId) {
  return (dispatch) => {
    dispatch(request())
    return topBusinessDebtorsService
      .getTopBusinessDebtors(businessId)
      .then((topBusinessDebtors) => dispatch(success(topBusinessDebtors)))
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: topBusinessDebtorsConstants.GET_TOP_BUSINESS_DEBTORS_REQUEST }
  }
  function success(topBusinessDebtors) {
    return {
      type: topBusinessDebtorsConstants.GET_TOP_BUSINESS_DEBTORS_SUCCESS,
      topBusinessDebtors,
    }
  }
  function failure(error) {
    return { type: topBusinessDebtorsConstants.GET_TOP_BUSINESS_DEBTORS_FAILURE, error }
  }
}

function createBusinessDebtor(debtorName, businessId, index) {
  return (dispatch) => {
    dispatch(request())
    return topBusinessDebtorsService
      .createBusinessDebtor(debtorName, businessId, index)
      .then((businessDebtor) => dispatch(success(businessDebtor, index)))
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: topBusinessDebtorsConstants.CREATE_BUSINESS_DEBTOR_REQUEST }
  }
  function success(businessDebtor, index) {
    return {
      type: topBusinessDebtorsConstants.CREATE_BUSINESS_DEBTOR_SUCCESS,
      businessDebtor,
      index,
    }
  }
  function failure(error) {
    return { type: topBusinessDebtorsConstants.CREATE_BUSINESS_DEBTOR_FAILURE, error }
  }
}

function updateDebtor(attr, val, debtorId, businessDebtorId = null) {
  return (dispatch) => {
    dispatch(request())
    return topBusinessDebtorsService
      .updateDebtor(attr, val, debtorId)
      .then(async (debtor) => {
        dispatch(success(attr, val, debtorId))
        if (businessDebtorId) {
          await dispatch(updateBusinessDebtor(`debtor_id`, debtor.id, businessDebtorId))
        }
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: topBusinessDebtorsConstants.UPDATE_DEBTOR_REQUEST }
  }
  function success(attr, val, debtorId) {
    return { type: topBusinessDebtorsConstants.UPDATE_DEBTOR_SUCCESS, attr, val, debtorId }
  }
  function failure(error) {
    return { type: topBusinessDebtorsConstants.UPDATE_DEBTOR_FAILURE, error }
  }
}

function updateBusinessDebtor(attr, val, businessDebtorId) {
  return (dispatch) => {
    dispatch(request())
    return topBusinessDebtorsService
      .updateBusinessDebtor(attr, val, businessDebtorId)
      .then((_) => dispatch(success(attr, val, businessDebtorId)))
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: topBusinessDebtorsConstants.UPDATE_BUSINESS_DEBTOR_REQUEST }
  }
  function success(attr, val, businessDebtorId) {
    return {
      type: topBusinessDebtorsConstants.UPDATE_BUSINESS_DEBTOR_SUCCESS,
      attr,
      val,
      businessDebtorId,
    }
  }
  function failure(error) {
    return { type: topBusinessDebtorsConstants.UPDATE_BUSINESS_DEBTOR_FAILURE, error }
  }
}

function addTopBusinessDebtor() {
  return {
    type: topBusinessDebtorsConstants.ADD_TOP_BUSINESS_DEBTOR,
  }
}

function removeTopBusinessDebtor(ordinal, handleRemoveCallback = () => {}) {
  handleRemoveCallback()
  return {
    type: topBusinessDebtorsConstants.REMOVE_TOP_BUSINESS_DEBTOR,
    ordinal,
  }
}

function deleteTopBusinessDebtor(businessId, businessDebtorId, handleRemoveCallback = () => {}) {
  return (dispatch) => {
    dispatch(request())
    return topBusinessDebtorsService
      .removeBusinessDebtor(businessId, businessDebtorId)
      .then(() => {
        dispatch(success(businessDebtorId))
        handleRemoveCallback()
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: topBusinessDebtorsConstants.DELETE_BUSINESS_DEBTOR_REQUEST }
  }
  function success(businessDebtorId) {
    return {
      type: topBusinessDebtorsConstants.DELETE_BUSINESS_DEBTOR_SUCCESS,
      businessDebtorId: businessDebtorId,
    }
  }
  function failure(error) {
    return { type: topBusinessDebtorsConstants.DELETE_BUSINESS_DEBTOR_FAILURE, error }
  }
}
