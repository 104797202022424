import { UTCDateToSystemString, dateToUTCString } from './dates'

export const officersDateFields = ['dob', 'bankruptcy_history_date']

export const officerDatesToUTC = (officer) => {
  officersDateFields.forEach((dateType) => {
    if (officer[dateType]) {
      officer[dateType] = dateToUTCString(officer[dateType])
    }
  })
  return officer
}

export const officerDatesToSystemTime = (officer) => {
  officersDateFields.forEach((dateType) => {
    if (officer[dateType]) {
      officer[dateType] = UTCDateToSystemString(officer[dateType]).split('T')[0]
    }
  })
  return officer
}
