import { menuOpenConstants } from '../_constants'

export const menuOpenActions = {
  toggleMenu,
}

function toggleMenu() {
  return {
    type: menuOpenConstants.TOGGLE,
  }
}
