import { getBusinesses } from '../../../../_services/businesses.service'
import { businessService } from '../../../../_services/business.service'
import { getDebtors, searchDebtors } from '../../../../_services/lender.service'
import startCase from 'lodash/startCase'
import { factoringBusinessFilter } from '../../../../_components/modals/filter/filterFields'

export const VERIFICATION_LABELS = {
  non_verified: 'Non Verified',
  negative: 'Negative',
  neutral: 'Neutral',
  positive: 'Verified',
}

export const VERIFICATION_FILTER = {
  fieldName: 'sentiment',
  displayName: 'Verification',
  type: 'select',
  formatter: (val) => VERIFICATION_LABELS[val] || val,
  options: [
    {
      value: 'positive',
      display: VERIFICATION_LABELS['positive'],
    },
    {
      value: 'negative',
      display: VERIFICATION_LABELS['negative'],
    },
    {
      value: 'non_verified',
      display: VERIFICATION_LABELS['non_verified'],
    },
  ],
}

export const INVOICES_FILTER_COLUMNS = [
  {
    fieldName: 'invoice_number',
    displayName: 'Invoice No.',
  },
  {
    fieldName: 'business_id',
    displayName: 'Client',
    type: 'autoComplete',
    autoComplete: {
      service: getBusinesses,
      fetchRecord: businessService.getBusiness,
      requestMethod: 'POST',
      searchParam: 'business_name',
      responseAttr: 'id',
      valueLabelAttr: 'business_name',
      suggestion: 'Start typing to search a business',
    },
  },
  {
    fieldName: 'debtor_id',
    displayName: 'Debtor',
    type: 'autoComplete',
    autoComplete: {
      service: searchDebtors,
      fetchRecord: searchDebtors,
      requestMethod: 'GET',
      searchParam: 'debtor_name',
      responseAttr: 'id',
      suggestion: 'Start typing to search a debtor name',
    },
  },
  {
    fieldName: 'non_funded',
    displayName: 'Non Funded',
    type: 'customTruthy',
    fieldNameAsLabel: true,
    customTruthy: [
      {
        paramName: 'non_funded[]',
        paramValue: '1',
      },
    ],
  },
  {
    fieldName: 'state',
    displayName: 'Status',
    type: 'select',
    formatter: (val) => startCase(val),
    options: [
      {
        value: 'pending',
        display: 'Pending',
      },
      {
        value: 'verified',
        display: 'Verified',
      },
      {
        value: 'purchased',
        display: 'Purchased',
      },
      {
        value: 'closed',
        display: 'Closed',
      },
      {
        value: 'incomplete',
        display: 'Incomplete',
      },
    ],
  },
  VERIFICATION_FILTER,
  {
    fieldName: 'start_date',
    displayName: 'Start Date',
    type: 'date',
  },
  {
    fieldName: 'end_date',
    displayName: 'End Date',
    type: 'date',
  },
  {
    fieldName: 'start_age',
    displayName: 'Start Age',
    type: 'number',
  },
  {
    fieldName: 'end_age',
    displayName: 'End Age',
    type: 'number',
  },
  {
    fieldName: 'ineligible',
    displayName: 'Ineligible',
    type: 'boolean',
  },
]

export const BUSINESS_INVOICES_FILTER_COLUMNS = [
  {
    fieldName: 'invoice_number',
    displayName: 'Invoice No.',
  },
  {
    fieldName: 'start_age',
    displayName: 'Start Age',
    type: 'number',
  },
  {
    fieldName: 'end_age',
    displayName: 'End Age',
    type: 'number',
  },
  factoringBusinessFilter,
  {
    fieldName: 'debtor_name',
    displayName: 'Debtor',
    type: 'autoComplete',
    autoComplete: {
      service: getDebtors,
      requestMethod: 'GET',
      searchParam: 'debtor_name',
      responseAttr: 'debtor_name',
      suggestion: 'Start typing to search a debtor name',
    },
  },
  {
    fieldName: 'state',
    displayName: 'Status',
    type: 'select',
    options: [
      {
        value: 'incomplete,pending',
        display: 'Pending',
      },
      {
        value: 'purchased',
        display: 'Purchased',
      },
      {
        value: 'non_funded',
        display: 'Non Funded',
      },
      {
        value: 'closed',
        display: 'Closed',
      },
    ],
  },
  VERIFICATION_FILTER,
  {
    fieldName: 'debtor_id',
    displayName: 'Debtor Id',
  },
  {
    fieldName: 'start_date',
    displayName: 'Start Date',
    type: 'date',
  },
  {
    fieldName: 'end_date',
    displayName: 'End Date',
    type: 'date',
  },
  {
    fieldName: 'ineligible',
    displayName: 'Ineligible',
    type: 'boolean',
  },
]
