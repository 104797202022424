import React from 'react'
import { ClipLoader } from 'react-spinners'
import { connect } from 'react-redux'
import queryString from 'query-string'
import styled from 'styled-components'

// Actions
import { authenticationActions } from '../_actions'

// Constants
import ROUTES from '../_constants/routes'
import { tenantColors } from '../_constants/colors'

// Helpers
import { history } from '../_helpers'

const StyledLoadingComponent = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

class ProspectPage extends React.Component {
  constructor(props) {
    super(props)
  }

  shouldComponentUpdate() {
    return false
  }

  componentDidMount() {
    const { identifier } = queryString.parse(location.search)
    const { validateSession, swapSession, authentication, setViaProspectLink } = this.props
    if (!identifier) {
      history.push(ROUTES.sign_in)
      return
    }

    if (authentication.loggedIn) {
      if (authentication.sessionToken === identifier) {
        // Even if it's the same session, we still want to validate it to hook into current auth and esign behavior
        validateSession(identifier).then(() => history.push(ROUTES.confirm_form))
      } else {
        // If it's a different session / account, we want to sign them out and start a new session with the current identifier
        swapSession(authentication.sessionToken, identifier).then((_) => setViaProspectLink())
      }
    } else {
      // Validate logged out users
      validateSession(identifier)
        .then((_) => setViaProspectLink())
        .catch((e) => console.error(e))
    }
  }

  render() {
    const { tenant } = this.props

    return (
      <StyledLoadingComponent>
        <ClipLoader
          color={tenantColors[tenant].secondary}
          css={{
            display: `block`,
            margin: `auto`,
            animationDuration: `1.5s !important`,
          }}
          loading={true}
          size={100}
        />
      </StyledLoadingComponent>
    )
  }
}

function mapStateToProps(state) {
  const { authentication, env } = state
  return {
    authentication,
    tenant: env.tenant,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    validateSession: (sessionToken) => {
      return dispatch(authenticationActions.validateSession(sessionToken))
    },
    swapSession: (initToken, newToken) => {
      return dispatch(authenticationActions.swapSession(initToken, newToken))
    },
    setViaProspectLink: () => {
      dispatch(authenticationActions.setViaProspectLink())
    },
  }
}

const connected = connect(mapStateToProps, mapDispatchToProps)(ProspectPage)
export { connected as ProspectPage }
