import styled, { keyframes } from 'styled-components'
import colors from '../../_constants/colors'

const skeletonFade = keyframes`
  0% {
    opacity: 1;
  }
  
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

const alignment = {
  center: '0 auto',
  right: '0 0 0 auto',
  left: '0 auto 0 0',
}

export const S_Skeleton = styled.div`
  margin: ${(props) => alignment[props.align]};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background: ${colors.INNER_GREY_BOX};
  animation: ${skeletonFade} 1.5s ease-in-out 0.5s infinite;
  ${(props) => (props.variant === 'circle' ? `border-radius: 100%;` : '')}
`
