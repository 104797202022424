import { useIsMutating, useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { queryClient } from 'xen/providers';
async function deletePaymentAssignment({ id }) {
    try {
        await request({
            method: 'DELETE',
            url: `/operate/payment_assignments/${id}`,
        });
        return id;
    }
    catch (_error) {
        throw Error('Error deleting invoice assignment');
    }
}
export const useDeletePaymentAssignment = (paymentId) => {
    return useMutation({
        mutationKey: ['delete-payment-assignment', paymentId],
        mutationFn: ({ id }) => deletePaymentAssignment({ id }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['payment-assignments'] });
            queryClient.invalidateQueries({ queryKey: ['payments'] });
            queryClient.invalidateQueries({ queryKey: ['invoices'] });
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements'] });
        },
    });
};
export const useIsPaymentAssignmentDeleting = (paymentId) => {
    return useIsMutating({ mutationKey: ['delete-payment-assignment', paymentId] });
};
