import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../../_constants/colors'

export const S_AlertMessage = styled.div`
  text-align: center;
  padding: 10px 20px;
  border-radius: 4px;

  ${({ type }) => {
    if (type == 'error') {
      return css`
        background: #fff4f4;
        border: 1px solid #ff9999;
        color: #aa1111;
      `
    }

    if (type == 'success') {
      return css`
        background: ${colors.MATERIAL_GREEN};
        border: 1px solid ${colors.MATERIAL_DARK_GREEN};
        color: ${colors.MATERIAL_DARK_GREEN};
      `
    }

    return css`
      background: #fffef6;
      border: 1px solid #f6e200;
    `
  }}
`

export const AlertMessage = ({ type = 'warning', children }) => {
  return <S_AlertMessage type={type}>{children}</S_AlertMessage>
}
