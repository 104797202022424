import { notificationsConstants } from '../_constants'

import Noty from 'noty'
import 'noty/lib/noty.css'
import 'noty/lib/themes/mint.css'

export const notificationsActions = {
  showNotification,
  hideNotification,
}

function showNotification(notification) {
  return (dispatch) => {
    notification.id = notification.id || Date.now()

    return new Promise((resolve, reject) => {
      const props = {
        layout: `topRight`,
        type: notification.type,
        text: notification.message,
      }

      if (notification.closeOnClick) {
        props['closeWith'] = ['click', 'button']
      } else {
        props['timeout'] = notification.timeout ?? 4000
      }

      new Noty(props)
        .on(`onShow`, function () {
          dispatch(success(notification))
        })
        .on(`afterClose`, function () {
          dispatch(hideNotification(notification.id))
          resolve()
        })
        .show()
    })
  }
  function success(notification) {
    return { type: notificationsConstants.SHOW_NOTIFICATION, notification }
  }
}

function hideNotification(id) {
  return {
    type: notificationsConstants.HIDE_NOTIFICATION,
    id,
  }
}
