import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import IdleTimer from 'react-idle-timer'
import config from 'config'

// Constants
import { authenticationConstants, MAX_IDLE_TIME } from '../_constants'

// Redux Hooks
import { useSignOut } from '../_reduxHooks/authentication.hook'
const timeout = 1000 * 60 * MAX_IDLE_TIME

export const IdleTimerWrapper = () => {
  const { authentication, env } = useSelector((state) => state)
  const { signOut } = useSignOut()
  const { loggedIn, sessionToken } = authentication
  let idleTimerRef = useRef(null)

  const handleOnAction = () => {
    if (!config.noTimeout) {
      idleTimerRef?.reset()
    }
  }

  const handleOnIdle = () => {
    if (!config.noTimeout) {
      const host = window.location.host
      if (env === 'prod' && !host.includes('localhost')) {
        signOut(sessionToken, false, true, authenticationConstants.IDLE_SIGNOUT_MSG)
      }
    }
  }

  return (
    <>
      {loggedIn && !config.noTimeout && (
        <IdleTimer
          debounce={250}
          onAction={handleOnAction}
          onIdle={handleOnIdle}
          ref={(ref) => (idleTimerRef = ref)}
          timeout={timeout}
        />
      )}
    </>
  )
}
