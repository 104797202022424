import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
export const useUpdateDealSalesPerson = ({ dealId }) => {
    return useMutation({
        mutationKey: ['update-deal-sales-person', dealId],
        mutationFn: async ({ salesPersonId }) => {
            try {
                await request({
                    method: 'PUT',
                    url: `underwrite/api/v1/deals/${dealId}/assign_sales_person`,
                    data: { sales_person_id: salesPersonId },
                });
            }
            catch (_error) {
                throw Error('Error assigning Sales Person to Deal');
            }
        },
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['deals', dealId] });
            queryClient.invalidateQueries({ queryKey: ['sales-people'] });
            notifications.success('Assigned sales person to deal');
        },
    });
};
