import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
async function deleteInvoice({ id }) {
    try {
        await request({
            method: 'DELETE',
            url: `/invoices/${id}`,
        });
        return id;
    }
    catch (error) {
        throw new Error('Could not delete invoice');
    }
}
export const useDeleteInvoice = () => {
    return useMutation({
        mutationKey: ['delete-invoice'],
        mutationFn: ({ id }) => deleteInvoice({ id }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: () => {
            notifications.success('Invoice successfully deleted');
            queryClient.invalidateQueries({ queryKey: ['invoices'] });
            queryClient.invalidateQueries({ queryKey: ['invoice-schedules'] });
        },
    });
};
