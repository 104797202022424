export const getStatusDisplay = (status) => {
  switch (status) {
    case 'rejected':
      return 'Not Funded'
    case 'funded':
      return 'Purchased'
    default:
      return status[0].toUpperCase() + status.slice(1)
  }
}
