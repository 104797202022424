import { jsx as _jsx } from "react/jsx-runtime";
import { colorsTuple, createTheme as createXenTheme, MantineProvider, rem, useMantineTheme as useXenTheme, } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import './xen-theme-provider.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
/**
 * Add theme values here that should apply to all xen-ui components.
 * App or tenant-specific theme values should be added in the xen/providers/theme-provider.
 */
const xenTheme = {
    fontFamily: 'Lato, sans-serif',
    other: {
        maxContentWidth: rem(1440),
    },
};
export const XenThemeProvider = ({ children, theme = {} }) => {
    return (_jsx(MantineProvider, { theme: theme, children: _jsx(ModalsProvider, { children: children }) }));
};
const createXenColorsTuple = (color) => {
    // TODO: Create actual 10-shade color palette from single color
    return colorsTuple(color);
};
export { createXenColorsTuple, createXenTheme, rem, xenTheme, useXenTheme };
