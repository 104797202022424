import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useFeatureFlags } from 'xen/hooks';
const SignUp = lazy(() => import('../pages/sign-up'));
const SignIn = lazy(() => import('../pages/sign-in'));
const ForgotPassword = lazy(() => import('../pages/forgot-password'));
const ResetPassword = lazy(() => import('../pages/reset-password'));
export const AUTH_PATHS = {
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    signIn: '/sign-in',
    signUp: '/sign-up',
};
export const AuthRoutes = () => {
    const { forgotPassword, resetPassword, signIn, signUp } = AUTH_PATHS;
    const { tenantEnrollment } = useFeatureFlags();
    const signInRoutes = [
        _jsx(Route, { exact: true, path: forgotPassword, children: _jsx(ForgotPassword, {}) }, forgotPassword),
        _jsx(Route, { exact: true, path: resetPassword, children: _jsx(ResetPassword, {}) }, resetPassword),
        _jsx(Route, { exact: true, path: signIn, children: _jsx(SignIn, {}) }, signIn),
    ];
    const signUpRoute = (_jsx(Route, { exact: true, path: signUp, children: _jsx(SignUp, {}) }, signUp));
    // Hide user sign-up if tenant enrollment is enabled
    if (tenantEnrollment)
        return signInRoutes;
    return [...signInRoutes, signUpRoute];
};
