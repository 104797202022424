import request from './axios_service'

export const createProspectBrokerCommission = ({ broker_id, deal_id, commission_percentage }) => {
  return request({
    url: `/underwrite/api/v1/broker_commissions`,
    method: 'POST',
    data: { broker_id, deal_id, commission_percentage },
  })
}

export const getProspectBrokerCommission = (id) => {
  return request({
    url: `/underwrite/api/v1/broker_commissions/${id}`,
    method: 'GET',
  })
}

export const deleteProspectBrokerCommission = (id) => {
  return request({
    url: `/underwrite/api/v1/broker_commissions/${id}`,
    method: 'DELETE',
  })
}

export const searchProspectBrokerCommissions = ({ dealId, brokerId }) => {
  const urlParams = new URLSearchParams({})
  if (dealId) urlParams.set('deal_id', dealId)
  if (brokerId) urlParams.set('broker_id', brokerId)

  return request({
    url: `/underwrite/api/v1/broker_commissions?${urlParams.toString()}`,
    method: 'GET',
  })
}

export const updateProspectBrokerCommission = (id, { commission_percentage, broker_id }) => {
  const data = {}
  if (commission_percentage) data.commission_percentage = commission_percentage
  if (broker_id) data.broker_id = broker_id

  return request({
    url: `/underwrite/api/v1/broker_commissions/${id}`,
    method: 'PUT',
    data,
  })
}
