import React from 'react'
import { Text } from '../Text/Text'

export const H2 = ({ children, fs, ...props }) => {
  return (
    <Text fs={fs} variant={'h2'} {...props}>
      {children}
    </Text>
  )
}

H2.defaultProps = {
  fs: '24px',
  fw: '700',
}
