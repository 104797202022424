import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from 'xen-ui';
import { useCreateInvoiceScheduleInvoice } from 'xen/api/use-invoice-schedule/use-create-invoice-schedule-invoice';
export const AddInvoiceButton = ({ children, invoiceSchedule, ...props }) => {
    const { mutate: createInvoice, isPending: isInvoiceBeingCreated } = useCreateInvoiceScheduleInvoice();
    const handleCreateInvoice = () => {
        createInvoice({
            invoiceScheduleId: invoiceSchedule.id,
            invoice: {
                businessId: invoiceSchedule.businessId,
                faceValue: 0,
                invoiceDate: new Date(),
                paymentTerms: 90,
            },
        });
    };
    return (_jsx(Button, { disabled: invoiceSchedule.state !== 'incomplete', loading: isInvoiceBeingCreated, onClick: handleCreateInvoice, ...props, children: children }));
};
