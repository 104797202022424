import { featureFlagConstants } from '../_constants/featureFlags.constants'

const defaultState = []

export function featureFlags(state = defaultState, action) {
  switch (action.type) {
    case featureFlagConstants.GET_FEATURE_FLAGS_SUCCESS:
      return action.featureFlags
    default:
      return state
  }
}
