import styled from 'styled-components'
import { textInputStyles } from '../../_constants/styles'
import { S_Chip } from './Chip.styles'

export const S_MultipleAutoCompleteInput = styled.div`
  position: relative;
  width: 100%;
  font-size: 16px;

  .multiple-auto-complete {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    ${textInputStyles}
    padding: 5px 12px;
    min-height: 41px;
    cursor: text;

    ${S_Chip} {
      margin: 2px;
    }

    &.focused {
    }
  }

  .formFieldInput,
  input {
    border: none;
    outline: none;
  }

  .suggestion-box-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    min-width: 100%;
    z-index: 31;
  }
  .suggestion-box {
    top: 0;
    min-height: 30px;
    overflow: hidden;
    white-space: nowrap;
  }
  .open {
    border: 1px solid #e8e8e8;
    border-width: 1px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: white;
  }

  .suggestion {
    background-color: white;
    cursor: pointer;
    padding: 8px;

    &.added {
      background-color: #e8e8e8;
      opacity: 0.5;
      cursor: default;
    }
  }

  .default-suggestion {
    background-color: #e8e8e8;
    padding: 8px;
    border: 1px solid #e8e8e8;
    border-width: 1px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .suggestion:hover {
    background-color: #eee;
  }

  .warning-icon {
    margin-left: 4px;
    margin-top: 3px;
  }

  .add-button {
    background-color: #aeaeae;
    border: 1px solid #aeaeae;
    cursor: pointer;
    padding: 8px;
  }

  .close {
    display: none;
  }

  .active {
    background-color: #e8e8e8;
  }

  .chip-position {
    max-width: 98%;
    max-height: 28px;
    width: fit-content;
  }

  .loading {
    display: flex;
    justify-content: center;
  }
`
