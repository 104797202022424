import styled from 'styled-components'

// Components
const searchBarStyles = {
  padding: '11px',
  iconWidth: '15px',
}

export const S_SearchBar = styled.div`
  flex-grow: 1;

  .search-field {
    min-height: 41px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: ${searchBarStyles.padding} 8px;
    height: 100%;
    width: 100%;
    outline: none;
    background: transparent;
    flex-grow: 1;

    &:focus {
      border-color: #007bff;
    }
  }
`
