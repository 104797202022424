import { timezoneConstants } from '../_constants/timezone.constants'
import { getTimezone } from '../_services/timezonedb.service'

export function setTimezone(timezoneName) {
  return (dispatch) =>
    getTimezone(timezoneName).then((resp) =>
      dispatch({
        type: timezoneConstants.SET_TIMEZONE,
        timezone: resp.data,
      })
    )
}
