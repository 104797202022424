import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled, RestrictedAccess } from 'xen/components';
import { useAuthentication, useFeatureFlags } from 'xen/hooks';
const Prospects = lazy(() => import('../pages/prospects'));
const ProspectDetails = lazy(() => import('../pages/prospect-details'));
const ProspectPreQual = lazy(() => import('../pages/prospect-pre-qualification'));
const ProspectDashboard = lazy(() => import('../pages/prospect-dashboard'));
const ProspectCreate = lazy(() => import('../pages/prospect-create'));
export const PROSPECTS_PATHS = {
    root: '/prospects',
    dashboard: '/prospects/dashboard',
    preQualification: '/prospects/pre-qualification',
    create: '/prospects/new',
    details: '/prospects/:id',
};
export const ProspectsRoutes = () => {
    const { root, dashboard, preQualification, create, details } = PROSPECTS_PATHS;
    const { stagedApplication } = useFeatureFlags();
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    return [
        _jsx(Route, { exact: true, path: root, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(Prospects, {}) }) }, root),
        _jsx(Route, { exact: true, path: dashboard, children: _jsx(RestrictedAccess, { userTypes: ['client', 'prospect'], children: _jsx(ProspectDashboard, {}) }) }, dashboard),
        _jsx(Route, { exact: true, path: preQualification, children: () => {
                if (!stagedApplication)
                    return _jsx(FeatureNotEnabled, { feature: "Staged application" });
                return (_jsx(RestrictedAccess, { userTypes: ['prospect'], children: _jsx(ProspectPreQual, {}) }));
            } }, preQualification),
        _jsx(Route, { exact: true, path: create, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(ProspectCreate, {}) }) }, create),
        _jsx(Route, { exact: true, path: details, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(ProspectDetails, {}) }) }, details),
    ];
};
