import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
async function deleteDocument(params) {
    try {
        await request({
            method: 'DELETE',
            url: `/documents/${params.id}`,
        });
        return params;
    }
    catch (_error) {
        throw Error('Error deleting document');
    }
}
export const useDeleteDocument = () => {
    return useMutation({
        mutationFn: (params) => deleteDocument(params),
        onError: () => {
            console.error('Error deleting document');
            notifications.error('Error deleting document');
        },
        onSuccess: (params) => {
            notifications.success(`${params.name ?? 'The document'} has been deleted`);
            queryClient.invalidateQueries({ queryKey: ['payments'] });
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements'] });
            queryClient.invalidateQueries({ queryKey: ['invoice-schedules'] });
            queryClient.invalidateQueries({ queryKey: ['invoices'] });
        },
    });
};
