import { useStore } from 'react-redux';
/**
 * Get authentication data for current user.
 * In the future this won't come from redux: https://finvoice-inc.atlassian.net/browse/FP-6950
 */
export const useAuthentication = () => {
    const store = useStore().getState().authentication;
    const authentication = transformAuthentication(store);
    return authentication;
};
const transformAuthentication = (store) => {
    const authentication = {
        accessToken: store.access_token ?? '',
        error: store.error ?? '',
        idleTimeoutMessage: store.idleTimeoutMessage ?? '',
        isIdleTimeout: !!store.isIdleTimeout,
        isLoading: !!store.loading,
        isLoggedIn: !!store.loggedIn,
        sessionToken: store.sessionToken ?? '',
    };
    return authentication;
};
