import { bankService } from '../_services'
import { bankConstants } from '../_constants/bank.constants'

export const bankActions = {
  connectBank,
  setBankConnection,
}

function connectBank(value, userId, dealId) {
  return (dispatch) => {
    if (value === bankConstants.VALUE_CONNECT) {
      dispatch(request())
      return bankService
        .connectBank(userId, dealId)
        .then((_) => dispatch(success()))
        .catch((error) => dispatch(failure(error)))
    } else {
      dispatch(upload())
    }
  }

  function upload() {
    return { type: bankConstants.DO_NOT_CONNECT_BANK }
  }
  function request() {
    return { type: bankConstants.CONNECT_BANK_REQUEST }
  }
  function success() {
    return { type: bankConstants.CONNECT_BANK_SUCCESS }
  }
  function failure(error) {
    return { type: bankConstants.CONNECT_BANK_FAILURE, error }
  }
}

function setBankConnection(isConnected) {
  const value = isConnected ? bankConstants.VALUE_CONNECT : bankConstants.VALUE_UPLOAD
  return { type: bankConstants.SET_BANK_CONNECTION_STATUS, value }
}
