import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Button } from 'xen-ui';
import { createInternalLinkUrl } from 'xen/helpers';
import { isExternalLink } from 'xen/types';
/**
 * A wrapper around the `Button` component that handles internal and external
 * text links. If the `to` prop is an external link (https://...), the component
 * will render an anchor tag that looks like a button that opens in a new
 * window. If the `to` prop is an internal link, the component will render a
 * `Link` component from `react-router-dom` and only allow valid internal link
 * paths based on the `XenPath` type and require the correct number of dynamic
 * URL params (0-3).
 *
 * NOTE: `createInternalLinkUrl` only supports a maximum of 3 dynamic URL
 * params. If you need more, update the `LinkProps` type in `xen/types/link.ts`
 * or manually construct your own link component and linkUrl.
 */
export const ButtonLink = ({ children, keepSearchParams, searchParams = {}, to, urlParams, ...props }) => {
    if (isExternalLink(to)) {
        return (_jsx(Button, { ...props, component: "a", href: to, rel: "noopener noreferrer", target: "_blank", children: children }));
    }
    // @ts-expect-error TODO: fix type errors
    const linkUrl = createInternalLinkUrl({ keepSearchParams, to, urlParams, searchParams });
    return (_jsx(Button, { ...props, component: Link, to: linkUrl, children: children }));
};
