import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

// Helpers
import { tenantColors } from '../../_constants/colors'

// Components
import { S_Button } from './Button.styles'

export const LinkComponent = styled(({ dispatch, ...props }) => <Link {...props} />)`
  ${S_Button}
`

export const ButtonComponent = styled.button`
  ${S_Button}
`

export const Button = ({
  type,
  buttonType,
  text,
  to,
  onClick,
  disabled = false,
  children,
  customcolor,
  className,
  ...rest
}) => {
  const env = useSelector((state) => state.env)
  const tenant = env.tenant
  const colors = tenantColors[tenant]

  if (buttonType === `link`) {
    const linkClassName = `${className} ${disabled ? 'disabled' : ''}`
    return (
      <LinkComponent
        colors={colors}
        customcolor={customcolor}
        disabled={disabled}
        to={to}
        type={type}
        {...rest}
        className={linkClassName}
      >
        {text}
        {children}
      </LinkComponent>
    )
  } else {
    return (
      <ButtonComponent
        className={className}
        colors={colors}
        customcolor={customcolor}
        disabled={disabled}
        onClick={(e) => onClick && onClick(e)}
        type={type}
        {...rest}
      >
        {text}
        {children}
      </ButtonComponent>
    )
  }
}
