import React from 'react'
import { CountryRegionData } from 'react-country-region-selector'
import SelectField from './SelectField'

let COUNTRIES = CountryRegionData.map((country) => {
  return {
    label: country[0],
    value: country[0],
  }
})

const Country = ({ field, ...rest }) => {
  if (field.countries) {
    COUNTRIES = COUNTRIES.reduce((nameArr, country) => {
      if (field.countries.includes(country.value)) {
        nameArr.push(country)
        return nameArr
      } else {
        return nameArr
      }
    }, [])
  }
  return (
    <SelectField
      field={{
        ...field,
        options: COUNTRIES,
      }}
      {...rest}
    />
  )
}

export default Country
