import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { Collapse } from 'xen-ui';
import { Page } from 'xen/components';
import { UnappliedCashEntries, UnappliedCashPaymentCard } from '../components';
const UnappliedCash = () => {
    const { paymentId } = useParams();
    return (_jsxs(Page, { "data-testid": "unapplied-cash", children: [_jsx(Collapse, { in: !!paymentId, children: paymentId && _jsx(UnappliedCashPaymentCard, { paymentId: paymentId }) }), _jsx(UnappliedCashEntries, {})] }));
};
export default UnappliedCash;
