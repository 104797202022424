import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformDocumentsResponse } from 'xen/api/use-document/transforms';
async function getInvoiceDocuments({ queryParams = {}, urlParams: { invoiceId }, }) {
    try {
        const response = await request({
            method: 'get',
            url: `/invoices/${invoiceId}/get_supporting_documents`,
            params: queryParams,
        });
        return transformDocumentsResponse(response);
    }
    catch (error) {
        throw new Error('Could not fetch invoice documents');
    }
}
export const useGetInvoiceDocuments = ({ queryParams = {}, urlParams }) => {
    const { invoiceId } = urlParams;
    return useQuery({
        queryKey: ['invoices', invoiceId, 'documents', { queryParams }],
        queryFn: () => getInvoiceDocuments({ queryParams, urlParams }),
    });
};
