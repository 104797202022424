import styled from 'styled-components'

export const S_DeleteNotificationGroupModal = styled.div`
  max-width: 350px;
  h3 {
    margin-bottom: 32px;
  }
  .message-body {
    margin-bottom: 32px;
  }
  .btn-container {
    display: flex;
    justify-content: space-between;
  }
`
