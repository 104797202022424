import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformInvoiceResponse } from './transform';
async function getInvoices({ queryParams = {} }) {
    try {
        const response = await request({
            method: 'GET',
            url: `/portfolio/invoices`,
            params: queryParams,
        });
        const data = response.map(transformInvoiceResponse);
        return data;
    }
    catch (_error) {
        throw Error('Error fetching portfolio invoices');
    }
}
/**
 * Note: If you are replacing /invoices calls, please verify if you filter is already supported
 * by the new endpoint. Also check if the response has everything you need.
 */
export const useGetInvoices = (params = {}) => {
    return useQuery({
        queryKey: ['invoices', { params: params.queryParams }],
        queryFn: () => getInvoices(params),
    });
};
