import styled from 'styled-components'
import colors from '../../_constants/colors'
import { math } from 'polished'

import { cssBreakpoints, cssConstants } from '../../_constants/styles'
const { siteHeaderHeight } = cssConstants

export const menuSizes = {
  mobileWidth: 70,
  headerPadding: 15,
  headerPaddingMobile: 10,
}

export const S_HeaderBar = styled.header.attrs((props) => ({
  primary: props.customColors.primary || colors.DEFAULT_PRIMARY,
  borderBottomColor: props.customColors.line || props.customColors.primary_button || colors.DEFAULT_PRIMARY,
  navLinkColor: props.customColors.footer_header || colors.DEFAULT_FOOTER_HEADER,
  linkColor: props.customColors.footer_header || colors.LIGHT_GREY,
  buttonBg: props.customColors.background || colors.DEFAULT_BACKGROUND,
  buttonBorder: !props.customColors.auth_button_border ? false : props.customColors.auth_button_border,

  menuToggleColor: props.customColors.primary === props.customColors.secondary ? '#fff' : props.customColors.secondary,
}))`
  background-color: ${(props) => props.primary};
  padding: 0;
  height: ${siteHeaderHeight};
  min-height: ${siteHeaderHeight}; /* fix for iOS */
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  border-bottom-color: ${(props) => props.borderBottomColor};
  border-bottom-style: solid;
  border-bottom-width: 1px;

  .brand-link {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    max-height: ${siteHeaderHeight};
    max-width: 160px;
    padding: 0;
    width: 0;

    img {
      display: block;
      max-height: ${math(`${siteHeaderHeight} - 24px`)};
      max-height: 100%;
      max-width: 100%;
    }
  }

  .logged-in-email,
  .link {
    color: ${(props) => props.navLinkColor};
    font-weight: bold;
    letter-spacing: 0.05em;
    font-size: 0.9em;
  }

  .header-logo {
    height: auto;
    width: auto;
  }

  .header-wrapper {
    display: flex;
    margin: 0 auto;
    max-width: ${cssConstants.contentWidth};
    position: relative;
    width: 100%;
  }

  .header-nav {
    align-self: stretch;
    height: ${siteHeaderHeight};
    overflow: scroll;
    width: 100%;
    @media screen and (min-width: ${cssBreakpoints.mobileLg}) {
      overflow: auto;
    }
  }

  .header-left,
  .header-right {
    display: flex;
    align-items: center;
  }

  .header-right {
    margin-left: auto;
    height: ${siteHeaderHeight};
    min-height: ${siteHeaderHeight}; /* fix for iOS */
    padding: 0 ${menuSizes.headerPaddingMobile}px;
    position: relative;
    min-width: ${menuSizes.mobileWidth}px;

    @media screen and (min-width: ${cssBreakpoints.mobileLg}) {
      min-width: auto;
      padding: 0 ${menuSizes.headerPadding}px;
    }

    .esigning-notifictions,
    .logged-in-email,
    .link {
      background-color: ${(props) => props.primary};
    }

    .link {
      display: flex;
      align-items: center;
      margin-right: 5px;
      padding: 10px 15px;
      border-radius: 3px;
      color: ${(props) => props.linkColor};
      font-weight: 400;
      letter-spacing: 0.5px;
      font-size: 16px;
      transition: background-color 0.2s ease-in-out;

      svg {
        display: inline-block;
        margin-right: 4px;
        width: 12px;
        height: 12px;
      }
    }

    .sign-up-link,
    .sign-in-link {
      font-size: 13px;
      min-width: 60px;
      padding: 10px 5px;
      text-align: center;
      justify-content: center;
      border: 1px solid transparent;
      &:hover {
        background: ${(props) => props.buttonBg};
        text-decoration: none;
      }
      &.active {
        border: ${(props) => `1px solid ${props.buttonBorder ? props.buttonBorder : 'transparent'}`};
        background: ${(props) => (props.buttonBorder ? 'transparent' : props.buttonBg)};
      }
    }

    .img-link,
    .tel-link,
    .logged-in-email {
      display: none;
    }

    .tel-link {
      white-space: nowrap;
    }

    .logged-in-email {
      border-radius: 3px;
    }

    .icon {
      margin-right: 4px;
    }

    .menu-toggle {
      color: ${(props) => props.menuToggleColor};
      cursor: pointer;
      user-select: none;
    }
  }

  .menu-container {
    top: ${siteHeaderHeight};
    right: 0;
    position: absolute;
    z-index: 2;
  }

  .notification-wrapper {
    padding: 0 5px 0 0;
  }

  @media screen and (min-width: ${cssBreakpoints.mobileLg}) {
    .header-right {
      .sign-up-link,
      .sign-in-link {
        font-size: 16px;
        min-width: 0;
        padding: 10px 15px;
      }

      .logged-in-email {
        display: inline-flex;
      }
    }
  }

  @media screen and (min-width: 800px) {
    .header-right {
      .img-link,
      .tel-link {
        display: flex;
      }
    }
  }
  @media screen and (min-width: ${cssBreakpoints.desktopSm}) {
    .header-wrapper {
      overflow: unset;
    }

    .brand-link {
      padding: 0 15px;
      width: 100%;
    }
  }

  @media print {
    display: none;
  }
`
