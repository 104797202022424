import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Components
import Table from '../../../_components/Table'
import { Button } from '../../../_components/Button'
import { EditQuoteTemplate } from './EditQuoteTemplate'
import { CreateQuoteTemplate } from './CreateQuoteTemplate'

// Selectors
import { useModal } from '../../../_reduxHooks/modal.hook'

// Services
import { getQuoteTemplates } from '../../../_services/quotes.service'

//Helpers
import { createColumn } from '../../../_components/TableColumn'

const S_QuoteTemplates = styled.div`
  .create {
    margin-top: 20px;
    float: right;
  }
`

export const QuoteTemplates = ({ admin_tenant }) => {
  const [loading, setLoading] = useState(false)
  const [templates, setTemplates] = useState([])
  const { openModal, modal } = useModal()

  useEffect(() => {
    if (modal.is_open) return

    const fetchQuoteTemplates = async () => {
      setLoading(true)

      const quoteTemplates = await getQuoteTemplates()
      setTemplates(quoteTemplates)
    }

    fetchQuoteTemplates()
  }, [modal.is_open, admin_tenant])

  return (
    <S_QuoteTemplates>
      <h3>Quote Templates</h3>

      <Table
        columns={[
          createColumn({
            type: 'expand',
          }),
          {
            accessor: 'name',
            headingText: 'Name',
            id: 'name',
            disableSortBy: true,
          },
          {
            accessor: (data, index, info) => {
              return info?.isChild ? data.original.children[index].id : data.id
            },
            id: 'id',
            headingText: '',
            disableSortBy: true,
            maxWidth: 120,
            Cell: (props) => (
              <div className={`align-right`}>
                <Button
                  className="compact"
                  onClick={() => {
                    openModal(() => {
                      return <EditQuoteTemplate templateId={props.row.original.id} />
                    }, {})
                  }}
                  text="Edit"
                />
              </div>
            ),
          },
        ]}
        data={templates}
      />

      <Button
        className={`compact create`}
        onClick={() => {
          openModal(() => <CreateQuoteTemplate admin_tenant={admin_tenant} />, {})
        }}
        text="Create Quote Template"
      />
    </S_QuoteTemplates>
  )
}
