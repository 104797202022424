import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { CheckboxGroup as XenCheckboxGroup } from 'xen-ui';
import { ErrorText } from '../../error-text';
/**
 * Wraps xen-ui <Checkbox> components in a group when we need to submit multiple values for one form key.
 * - Set `defaultValues` on the group to populate each group's with initial values.
 * - Set `name` and `control` on the group instead of the child checkboxes to share the values with react-hook-form.
 * - Set `value` on each child checkbox to determine if it is checked.
 */
export const FormCheckboxGroup = ({ name, control, defaultValue, error, rules, shouldUnregister, onChange, ...props }) => {
    const { field: { value, onChange: fieldOnChange, ...field }, fieldState, } = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const fieldError = error ?? fieldState.error?.message;
    return (_jsx(XenCheckboxGroup, { error: fieldError ? _jsx(ErrorText, { component: "span", message: fieldError }) : undefined, inputWrapperOrder: ['label', 'description', 'error', 'input'], onChange: (e) => {
            fieldOnChange(e);
            onChange?.(e);
        }, styles: { root: { display: 'flex', flexDirection: 'column', gap: '.25rem' }, ...props.styles }, value: value, ...field, ...props }));
};
