import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import ROUTES from '../../_constants/routes'

const PrivateAblClientRoute = ({
  component: Component,
  loggedIn,
  isClient,
  resetPasswordRequired,
  financingType,
  user,
  ...rest
}) => {
  let redirectPath
  if (!loggedIn) {
    redirectPath = ROUTES.sign_in
  } else if (resetPasswordRequired) {
    redirectPath = ROUTES.reset_password
  } else if (user.user_type === 'lender') {
    redirectPath = ROUTES.lender_home
  } else if (!isClient) {
    redirectPath = ROUTES.upload_invoices
  } else if (financingType !== `abl`) {
    redirectPath = ROUTES.client_home
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        redirectPath ? (
          <Redirect to={{ pathname: redirectPath, state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

function mapStateToProps(state) {
  const { authentication, business, user } = state
  return {
    loggedIn: authentication.loggedIn,
    isClient: business.is_client,
    user: user,
    resetPasswordRequired: !!(user && user.reset_password_required),
    financingType: business.financing_type,
  }
}

const connectedPrivateAblClientRoute = connect(mapStateToProps)(PrivateAblClientRoute)
export { connectedPrivateAblClientRoute as PrivateAblClientRoute }
