/**
 * Transform this response object
 * ```
 *  {
 *    features: {
 *      feature_one: {
 *        enabled: false
 *      },
 *      feature_two: {
 *        enabled: true
 *      }
 *    }
 *    fliper_id: 'Tenant;tenant-token'
 *  }
 * ```
 * into this simplified object
 * ```
 *  {
 *    feature_one: false,
 *    feature_two: true,
 *  }
 * ```
 */
export const transformFlipperFeatures = (response) => {
    const { features } = response;
    const featureObject = Object.keys(features).reduce((acc, key) => {
        acc[key] = features[key]?.enabled || false;
        return acc;
    }, {});
    return featureObject;
};
