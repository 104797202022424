import { tenantConstants } from '../_constants/tenant.constants'

export const tenantActions = {
  setTenant,
}

function setTenant(tenant) {
  return (dispatch) => {
    dispatch(success(tenant))
  }

  function success(tenant) {
    return { type: tenantConstants.SET_TENANT_SUCCESS, tenant }
  }
}
