import { formConstants } from '../_constants'

export const formActions = {
  nextSection,
  prevSection,
  submitForm,
  goToSection,
  completeSection,
  completedSections,
}

function nextSection() {
  return {
    type: formConstants.NEXT_SECTION,
  }
}

function prevSection() {
  return {
    type: formConstants.PREV_SECTION,
  }
}

function submitForm() {
  return {
    type: formConstants.SUBMIT_FORM,
  }
}

function goToSection(prevSection, nextSection) {
  return {
    type: formConstants.GO_TO_SECTION,
    prevSection,
    nextSection,
  }
}

function completeSection(index) {
  return {
    type: formConstants.COMPLETE_SECTION,
    index,
  }
}
function completedSections(completedSections) {
  return {
    type: formConstants.COMPLETED_SECTIONS,
    completedSections,
  }
}
