import React, { useState, useEffect } from 'react'
import { unstable_batchedUpdates } from 'react-dom'

// Components
import { FormInput } from '../../FormInput'

const formatTelField = (val) => {
  let numsOnly = val.replace(/\D/g, '')
  let toReturn = ''
  if (numsOnly.length > 10) {
    if (numsOnly.length > 13) {
      numsOnly = numsOnly.substr(0, 13)
    }
    let chars = []
    for (let i = numsOnly.length - 1; i >= 0; i--) {
      chars.push(numsOnly[i])
      if (chars.length == 4 || chars.length == 8 || chars.length == 12) {
        chars.push(' ')
      }
    }
    return '+' + chars.reverse().join('')
  } else {
    toReturn = '(' + toReturn
    for (let i = 0; i < numsOnly.length; i++) {
      toReturn += numsOnly[i]
      if (i == 2) {
        toReturn += ') '
      }
      if (i == 5) {
        toReturn += '-'
      }
    }
    if (toReturn[toReturn.length - 1] == '-' || toReturn[toReturn.length - 1] == '(') {
      toReturn = toReturn.substr(0, toReturn.length - 1)
    }
    if (toReturn[toReturn.length - 1] == ' ') {
      toReturn = toReturn.substr(0, toReturn.length - 2)
    }
    return toReturn
  }
}

const updateFromProps = (value, defaultValue) => {
  // Generally these will be uncontrolled
  const isControlled = value !== undefined
  let valueToUse = isControlled ? value : defaultValue
  if (typeof valueToUse !== 'string') {
    valueToUse = ''
  }
  valueToUse = valueToUse.replace(/\D/g, '')
  const newDisplayValue = formatTelField(valueToUse)
  return newDisplayValue
}

export const InternationalTelField = (props) => {
  const { id = null, value, defaultValue, onChange = () => {}, type, ...rest } = props
  const [displayValue, setDisplayValue] = useState(updateFromProps(value, defaultValue))

  // Allow us to reset the field when ID changes
  useEffect(() => {
    setDisplayValue(updateFromProps(value, defaultValue))
  }, [id, value])

  const handleChangeLocal = (e) => {
    let target = e.target
    let newValue = target.value
    let newCursor = target.selectionStart
    let newDisplayValue = formatTelField(newValue)

    unstable_batchedUpdates(() => {
      setDisplayValue(newDisplayValue)
    })

    setTimeout(() => {
      target.selectionStart = target.selectionEnd = newCursor + (newDisplayValue.length - newValue.length)
    }, 0)

    // Callback
    onChange(newDisplayValue, id)
  }

  // Class Name
  const className = `tel ${props.className ? props.className : ''}`

  return (
    <FormInput
      {...rest}
      autoComplete="off"
      className={className}
      id={id}
      onChange={handleChangeLocal}
      type="text"
      value={displayValue}
    />
  )
}
