import React, { useState } from 'react'

// Components
import { ValidationForm } from '../../../_components/Forms/ValidationForm'
import { S_ChangePassword } from './ChangePassword.style'
import { LoaderButton } from '../../../_components/LoaderButton'

// Services
import { authenticationService } from '../../../_services/authentication.service'

// Hooks
import { useModal } from '../../../_reduxHooks/modal.hook'
import { notificationActionHooks } from '../../../_actionHooks/notification.actionHooks'
import { InvalidPasswordCognito } from '../../FormValidation/InvalidPasswordCognito'
import { cognitoPasswordValid } from '../../../_helpers/security/passwordValidation'

const { signIn, resetPassword } = authenticationService

export const ChangePassword = ({ user_id, email }) => {
  const [passwordUpdate, setPasswordUpdate] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [errorsCopy, setErrorsCopy] = useState({})
  const [errSet, setErrSet] = useState()
  const [submitFn, setSubmitFn] = useState()
  const [clearErr, setClearErr] = useState()
  const [submissionError, setSubmissionError] = useState('')
  const { closeModal } = useModal()
  const showNotification = notificationActionHooks.useShowNotification()
  const [passwordInvalid, setPasswordInvalid] = useState(false)

  const passwordUpdateFields = [
    {
      id: 'current_password',
      label: 'Current Password',
      type: 'password',
      validate: (value) => {
        return null
      },
      required: true,
    },
    {
      id: 'new_password',
      label: 'New Password',
      type: 'password',
      validate: (value) => {
        return null
      },
      required: true,
    },
    {
      id: 'repeat_new_password',
      label: 'Repeat new Password',
      type: 'password',
      validate: (value) => {
        return null
      },
      required: true,
    },
  ]

  return (
    <S_ChangePassword>
      <header>
        <h4>Change Password</h4>
        <h5 className={'err-msg'}>{submissionError}</h5>
      </header>

      <ValidationForm
        entity={passwordUpdate}
        fields={passwordUpdateFields}
        getFormFunctions={({ setError, submitForm, clearError }) => {
          setErrSet(() => setError)
          setSubmitFn(submitForm)
          setClearErr(() => clearError)
        }}
        onBlur={(id, setError, value) => {}}
        // Pass back the new object?
        onChange={(id, newValue) => {
          const newState = { ...passwordUpdate }
          newState[id] = newValue.target ? newValue.target.value : newValue
          setPasswordUpdate(newState)
        }}
        onErrorsUpdate={(newErrors) => {
          setErrorsCopy(newErrors)
        }}
        submitHandler={async (entity) => {
          const { current_password, new_password, repeat_new_password } = entity
          setIsLoading(true)
          setPasswordInvalid(false)

          if (!cognitoPasswordValid(new_password)) {
            setPasswordInvalid(true)
            setSubmissionError('Password invalid')
            setIsLoading(false)
            return
          }

          //Just need to know whether the promise is fulfilled or rejected
          let { status } = (await Promise.allSettled([signIn(email, current_password, true, true)]))[0]
          if (status === 'rejected') {
            setSubmissionError('Current Password is Incorrect')
            setIsLoading(false)
            return
          }
          if (new_password !== repeat_new_password) {
            setSubmissionError('Passwords Must Match')
            setIsLoading(false)
            return
          }
          await resetPassword(email, '', new_password, user_id)
          setIsLoading(false)
          closeModal()
          showNotification({ type: 'success', message: 'Password Updated', id: 'password_updated' })
        }}
      />

      {passwordInvalid && <InvalidPasswordCognito style={{ marginTop: '10px' }} />}

      <div className={'save-container'}>
        <LoaderButton
          isLoading={isLoading}
          onClick={() => {
            submitFn()
          }}
        >
          Save
        </LoaderButton>
      </div>
    </S_ChangePassword>
  )
}
