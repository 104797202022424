import { envConstants } from '../_constants'

export const envActions = {
  setEnv,
}

function setEnv(tenant, env) {
  return {
    type: envConstants.SET_ENV,
    tenant,
    env,
  }
}
