import styled from 'styled-components'
import { PieChart } from 'recharts'
import colors from '../../../_constants/colors'

export const S_PieChartLegend = styled.ol`
  list-style-position: inside;
  margin: 0;
  padding: 0;

  li {
    color: ${colors.TEXT_LIGHT};
    font-weight: 400;
    padding: 5px 0;
    transition: color 0.2s ease-in-out;
    &.active {
      color: ${colors.DEFAULT_SECONDARY};
    }
  }
`

export const S_PieChart = styled(PieChart)`
  .recharts-pie-sector {
    path {
      ${({ animationDisabled }) =>
        !animationDisabled &&
        `
            transition: all 0.2s ease-in-out;
          `}
    }
  }

  .recharts-sector {
    opacity: ${(props) => (props && props.activeShapeMode ? '0.7' : '1')};
    &.active-recharts-sector {
      opacity: 1;
    }
  }
`
