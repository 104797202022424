import React from 'react'
import PropTypes from 'prop-types'

// Components
import { FormField } from '../FormField'
import ToolTip from '../../Tooltip/Tooltip'
import { S_FormRow } from './FormRow.styles'
import { S_QuestionRoundedIcon } from '../../ListSearchableView'

//TODO: break out form label, so we can easily set up situations with one label and multiple inputs
// do the same for form row error

export const FormRow = (props) => {
  const {
    error = null,
    errors = [],
    explainer = '',
    tooltip = '',
    info = null,
    label = null,
    layout = '',
    required = false,
    shouldShow = true,
    children = null,
  } = props
  // Can't pass 'error' and 'errors' to the same component
  const hasSingleError = error !== null
  const hasErrorArray = Array.isArray(errors) && errors.length > 0
  if (hasSingleError && hasErrorArray) {
    return null
  }
  const isInvalid = hasSingleError || hasErrorArray
  let rowClassName = layout
  return (
    <S_FormRow className={`${rowClassName} ${shouldShow ? 'show' : 'hide'}`} shouldShow={shouldShow}>
      {/* Label */}
      {label && (
        <div className="form-row-label">
          <label className="label-text" htmlFor={props.id}>
            {label}
            {required && <span className="required-indicator">*</span>}

            {explainer ||
              (tooltip && (
                <ToolTip content={explainer || tooltip}>
                  <span className={`tooltip-question`}>
                    <S_QuestionRoundedIcon size={'sm'} />
                  </span>
                </ToolTip>
              ))}
          </label>
        </div>
      )}

      <div className={`field-and-error-wrapper ${isInvalid ? 'invalid-input' : ''}`}>
        {/* Input(s) */}
        <FormField {...props} invalid={isInvalid} />

        {/* Error(s) */}
        {hasSingleError && <div className="form-row-error">{error}</div>}
        {hasErrorArray &&
          errors.map((error, i) => (
            <div className="form-row-error" key={i}>
              {error}
            </div>
          ))}
      </div>

      {/* Extra Info*/}
      {info && <div className="form-row-info">{info}</div>}
    </S_FormRow>
  )
}

FormRow.propTypes = {
  error: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  errors: PropTypes.arrayOf(PropTypes.string),
  explainer: PropTypes.string,
  info: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  layout: PropTypes.string,
  required: PropTypes.bool,
  shouldShow: PropTypes.bool,
}
