export const authenticationConstants = {
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  SIGN_UP_COMPLETE: 'SIGN_UP_COMPLETE',

  SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_IN_COMPLETE: 'SIGN_IN_COMPLETE',

  SIGN_OUT_REQUEST: 'SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  CLEAR_ERROR: 'CLEAR_ERROR',
  STOP_LOADING: 'STOP_LOADING',

  VIA_PROSPECT_LINK: 'VIA_PROSPECT_LINK',

  IDLE_SIGNOUT_MSG: 'You have been signed out due to inactivity. Please sign in again.',

  GET_SIGN_UP_FORM_AND_MARKETING_REQUEST: 'GET_SIGN_UP_FORM_AND_MARKETING_REQUEST',
  GET_SIGN_UP_FORM_AND_MARKETING_SUCCESS: 'GET_SIGN_UP_FORM_AND_MARKETING_SUCCESS',
  GET_SIGN_UP_FORM_AND_MARKETING_FAILURE: 'GET_SIGN_UP_FORM_AND_MARKETING_FAILURE',
}
