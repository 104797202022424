import { UTCDateToSystemString, dateToUTCString } from '../dates'

export const fundingRequestDatesToUTC = (fundingRequest) => {
  if (fundingRequest.date_posted) {
    fundingRequest.date_posted = dateToUTCString(fundingRequest.date_posted)
  }
  if (fundingRequest.date) {
    fundingRequest.date = dateToUTCString(fundingRequest.date)
  }
  return fundingRequest
}
export const fundingRequestDatesToSystemTime = (fundingRequest) => {
  if (fundingRequest.date) {
    fundingRequest.date = UTCDateToSystemString(fundingRequest.date)
  }
  if (fundingRequest.date_posted) {
    fundingRequest.date_posted = UTCDateToSystemString(fundingRequest.date_posted)
  }
  if (fundingRequest.created_at) {
    fundingRequest.created_at = UTCDateToSystemString(fundingRequest.created_at)
  }
  if (fundingRequest.updated_at) {
    fundingRequest.updated_at = UTCDateToSystemString(fundingRequest.updated_at)
  }
  return fundingRequest
}
