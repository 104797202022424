export const fundingRequestConstants = [
  //Default Value
  {
    display: 'Funding request',
    value: 'funding_request',
  },
  {
    display: 'Fee charge',
    value: 'fee_charge',
  },
  {
    display: 'Reserve release',
    value: 'reserve_release',
  },
]
