import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { RestrictedAccess } from 'xen/components';
import { useAuthentication } from 'xen/hooks';
const Clients = lazy(() => import('../pages/clients'));
const ClientDetails = lazy(() => import('../pages/client-details'));
export const CLIENTS_PATHS = {
    root: `/clients`,
    details: `/clients/:id`,
};
export const ClientsRoutes = () => {
    const { details, root } = CLIENTS_PATHS;
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    return [
        _jsx(Route, { exact: true, path: root, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(Clients, {}) }) }, root),
        _jsx(Route, { exact: true, path: details, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(ClientDetails, {}) }) }, details),
    ];
};
