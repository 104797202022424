import { jsx as _jsx } from "react/jsx-runtime";
import { XenThemeProvider } from 'xen-ui';
import { useGetTenant } from 'xen/api/use-tenant';
import { useGetCurrentUser } from 'xen/api/use-user';
import { AppLoader, Error } from 'xen/components';
import { TenantProvider, UserProvider } from 'xen/providers';
const GlobalDataProvider = ({ children }) => {
    const { data: userData, isLoading: isUserLoading, isError: isUserError } = useGetCurrentUser();
    const { data: tenantData, isLoading: isTenantLoading, isError: isTenantError } = useGetTenant();
    if (isUserLoading || isTenantLoading) {
        return (_jsx(XenThemeProvider, { children: _jsx(AppLoader, {}) }));
    }
    if (!tenantData || isTenantError || isUserError) {
        return (_jsx(XenThemeProvider, { children: _jsx(Error, { message: "Error Initializing Application" }) }));
    }
    if (!userData) {
        return _jsx(TenantProvider, { tenant: tenantData, children: children });
    }
    return (_jsx(TenantProvider, { tenant: tenantData, children: _jsx(UserProvider, { user: userData, children: children }) }));
};
export { GlobalDataProvider };
