// Importing this fn from 'xen/helpers' causes jest tests to break, so we can
// define it here all the legacy config code is deleted.
const getTenantFromUrl = () => {
  const url = window.location.origin
  const regex = /^https?:\/\/([^/.]+)/
  const match = url.match(regex) ?? []
  const tenant = match[1]

  if (tenant === undefined || tenant.startsWith('localhost')) return ''

  return tenant
}
const currentTenant = getTenantFromUrl()

// Force the list of tenants to only include the current one, instead of every possible tenant.
const TENANTS = [currentTenant]

export default TENANTS
