import { transformDocumentResponse } from 'xen/api/use-document';
import { transformUnserializedInvoice } from 'xen/api/use-invoice';
import { parseDate } from 'xen/helpers';
export const transformInvoiceSettlementErrorResponse = (errors) => {
    const errorObj = {};
    if (errors.amount_posted) {
        errorObj.amountPosted = errors.amount_posted;
    }
    if (errors.collections_checks) {
        errorObj.collectionsChecks = errors.collections_checks;
    }
    return errorObj;
};
export const transformInvoiceSettlementResponse = (data) => {
    return {
        amountPosted: parseFloat(data.amount_posted),
        batchNumber: data.batch_number,
        clients: data.clients,
        createdAt: new Date(data.created_at),
        dateCreated: new Date(data.date_created),
        datePosted: data.date_posted ? new Date(data.date_posted) : null,
        debtorCount: data.debtor_count,
        deletable: data.deletable,
        documents: data.documents.map(transformDocumentResponse),
        errors: transformInvoiceSettlementErrorResponse(data.errors),
        expectedTotalValue: parseFloat(data.expected_total_value),
        id: data.id,
        paymentCount: data.payment_count,
        state: data.state,
        totalValue: parseFloat(data.total_value),
        updatedAt: new Date(data.updated_at),
    };
};
export function transformPaymentAssignmentParams(params) {
    const requestParams = {};
    if ('amount' in params)
        requestParams.amount = params.amount;
    if ('businessId' in params)
        requestParams.business_id = params.businessId;
    if (params.collectionsCheckId)
        requestParams.collections_check_id = params.collectionsCheckId;
    if ('invoiceId' in params)
        requestParams.invoice_id = params.invoiceId;
    if (params.type)
        requestParams.type = params.type;
    return requestParams;
}
export const transformPaymentAssignmentResponse = (paymentAssignmentResponse) => {
    const payment = {
        id: paymentAssignmentResponse.id,
        tenantId: paymentAssignmentResponse.tenant_id,
        businessId: paymentAssignmentResponse.business_id,
        collectionsCheckId: paymentAssignmentResponse.collections_check_id,
        invoiceId: paymentAssignmentResponse.invoice_id,
        amount: parseFloat(paymentAssignmentResponse.amount),
        createdAt: new Date(paymentAssignmentResponse.created_at),
        updatedAt: new Date(paymentAssignmentResponse.updated_at),
        paymentBatchId: paymentAssignmentResponse.payment_batch_id,
        postingDate: paymentAssignmentResponse.posting_date
            ? parseDate.fromIsoDateString(paymentAssignmentResponse.posting_date)
            : null,
        type: paymentAssignmentResponse.type,
        state: paymentAssignmentResponse.state,
        invoice: transformUnserializedInvoice(paymentAssignmentResponse.invoice),
        business: {
            id: paymentAssignmentResponse.business.id,
            name: paymentAssignmentResponse.business.business_name,
        },
        errors: {
            ...('type' in paymentAssignmentResponse.errors ? { type: paymentAssignmentResponse.errors.type } : {}),
        },
    };
    return payment;
};
export const transformPaymentAssignmentsResponse = (data) => {
    return data.map(transformPaymentAssignmentResponse);
};
