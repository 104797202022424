import React from 'react'
import { Text } from '../Text/Text'

export const H1 = ({ children, fs, ...props }) => {
  return (
    <Text fs={fs} variant={'h1'} {...props}>
      {children}
    </Text>
  )
}

H1.defaultProps = {
  fs: '32px',
  fw: '700',
}
