import React from 'react'
import { S_Box } from './Box.styles'
import colors from '../../_constants/colors'
import PropTypes from 'prop-types'

export const Box = ({ children, ...props }) => {
  return <S_Box {...props}>{children}</S_Box>
}

Box.propTypes = {
  /**
   * Border width
   */
  borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Border radius
   */
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Border color
   */
  borderColor: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Border color
   */
  borderStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Padding
   */
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Padding top
   */
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Padding Bottom
   */
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Padding Right
   */
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Padding Left
   */
  pl: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Margin
   */
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Margin Top
   */
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Margin Bottom
   */
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Margin Right
   */
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Margin Left
   */
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Absolute height for box
   */
  boxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Absolute width for box
   */
  boxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Absolute max-width for box
   */
  boxMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Absolute min-width for box
   */
  boxMinWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Background color property
   */
  boxBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  alignItems: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  justifyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  borderLeftWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  borderTopWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  borderRightWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  borderBottomWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
}

Box.defaultProps = {
  borderRadius: false,
  borderColor: colors.BORDER_GREY,
  borderStyle: 'solid',
  p: false,
  pt: false,
  pb: false,
  pr: false,
  pl: false,
  m: false,
  mt: false,
  mb: false,
  mr: false,
  ml: false,
  boxHeight: false,
  boxWidth: false,
  boxMaxWidth: false,
  borderWidth: false,
  bwt: false,
  bwr: false,
  bwb: false,
  bwl: false,
  d: '',
  fd: false,
  alignItems: false,
  justifyContent: false,
  boxBackgroundColor: false,
  position: false,
}
