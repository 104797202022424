import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

// Components
import { Alert } from '../_components/Alert'
import { Modal } from '../_components/Modal'

// Styled Component
const S_AppOverlay = styled.div``

/*
    Container for all connected componetns that overlay the regular UI and prevent unnecessary rerenders.
*/
export const AppOverlay = () => {
  const alert = useSelector((state) => state.alert)
  const modal = useSelector((state) => state.modal)
  return (
    <S_AppOverlay>
      {alert.is_open && <Alert alert={alert} />}
      {modal.is_open && <Modal {...modal.props} />}
    </S_AppOverlay>
  )
}
