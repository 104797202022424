import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BasicTable, Loader, Stack, Text } from 'xen-ui';
import { useGetInvoiceScheduleInvoices } from 'xen/api/use-invoice-schedule';
import { ErrorText } from 'xen/components';
import { formatCurrency, formatPercent } from 'xen/helpers';
const Total = ({ label, value }) => {
    return (_jsxs(BasicTable.Tr, { children: [_jsx(BasicTable.Th, { fw: "normal", lts: "0.05rem", pl: 0, pt: 0, scope: "row", tt: "uppercase", children: label }), _jsx(BasicTable.Td, { fw: "bold", pt: 0, children: value })] }));
};
export const InvoiceScheduleTotals = ({ invoiceSchedule }) => {
    const { data: invoices = [], isLoading: isInvoicesLoading, isError: isInvoicesError, } = useGetInvoiceScheduleInvoices({
        queryParams: { pagination: { count: 1000 } },
        urlParams: { scheduleId: invoiceSchedule.id },
    });
    if (isInvoicesLoading)
        return _jsx(Loader, {});
    if (isInvoicesError)
        return _jsx(ErrorText, { message: "Could not fetch invoices" });
    const { totalValue, totalFunded, advanceRate } = invoiceSchedule;
    // NOTE: A `invoiceSchedule.totalAdvance` column exists but in practice always returns 0 or null.
    // Unless/until the backend populates this value, we can derive it by summing the advanced amount
    // of all the *funded* schedule invoices.
    const totalAdvanced = (invoices ?? [])
        .filter((invoice) => !invoice.nonFunded)
        .reduce((acc, { advancedAmount }) => acc + advancedAmount, 0);
    const EMPTY_VALUE = '-';
    const formattedTotalValue = formatCurrency(totalValue);
    const formattedTotalFunded = formatCurrency(totalFunded);
    const formattedAdvanceRate = advanceRate ? formatPercent(advanceRate) : EMPTY_VALUE;
    const formattedTotalAdvanced = formatCurrency(totalAdvanced);
    return (_jsxs(Stack, { gap: "xxxs", children: [_jsx(Text, { fw: "bold", size: "sm", children: "Summary" }), _jsxs(BasicTable, { captionSide: "top", maw: "20rem", withRowBorders: false, children: [_jsx(BasicTable.Caption, { ta: "left", children: _jsx(Text, { size: "xs", children: "Invoice schedule totals at a glance" }) }), _jsxs(BasicTable.Tbody, { children: [_jsx(Total, { label: "Total Value", value: formattedTotalValue }), _jsx(Total, { label: "Total Funded", value: formattedTotalFunded }), _jsx(Total, { label: "Advance Rate", value: formattedAdvanceRate }), _jsx(Total, { label: "Total Advanced", value: formattedTotalAdvanced })] })] })] }));
};
