import config from 'config'
import axios from 'axios'
import request from './axios_service'

export const accountingPlatformsService = {
  connectToSageOne,
  connectToXero,
  getLatestInvoices,
  getAllPendingInvoices,
  getAllSubmittedInvoices,
  checkPreapproval,
  importInvoice,
  linkInvoiceToDeal,
  uploadAccountingInvoicesAndReports,
  getInvoiceCount,
}

function connectToSageOne(businessId) {
  return axios.get(`${config.apiUrl}/sage_one/authenticate/${businessId}`)
}

function connectToXero(businessId) {
  return axios.get(`${config.apiUrl}/xero/authenticate/${businessId}`)
}

function getLatestInvoices(businessId, accountingPlatform) {
  if (accountingPlatform == 'none' || !accountingPlatform) {
    return request({
      url: `/businesses/${businessId}`,
      method: 'GET',
    }).then((resp) => {
      if (resp.data_source) {
        return request({
          url: `/integration/${resp.data_source}/invoices/invoice_summary/${businessId}`,
          method: 'GET',
        })
      } else {
        return request({
          url: `/integration/${accountingPlatform}/invoices/invoice_summary/${businessId}`,
          method: 'GET',
        })
      }
    })
  } else {
    return request({
      url: `/integration/${accountingPlatform}/invoices/invoice_summary/${businessId}`,
      method: 'GET',
    })
  }
}

function getAllSubmittedInvoices(businessId) {
  return request({
    url: `/invoices/get_all_submitted_invoices/${businessId}`,
    method: 'GET',
  })
}

function getAllPendingInvoices(businessId) {
  return request({
    url: `/invoices/get_all_pending_invoices/${businessId}`,
    method: 'GET',
  })
}

function checkPreapproval(dataObj) {
  return request({
    url: `/debtors/check_preapproval`,
    method: `POST`,
    data: dataObj,
  })
}

function importInvoice(businessId, externalInvoiceId, preapproved, accountingPlatform) {
  return request({
    url: `/integration/${accountingPlatform}/invoices/invoice_details_import/${businessId}/${externalInvoiceId}/${preapproved}`,
    method: `GET`,
  })
}

function linkInvoiceToDeal(invoiceId, dealId) {
  return request({
    url: `/deals/link_invoice/${dealId}/${invoiceId}`,
    method: `PUT`,
  })
}

function uploadAccountingInvoicesAndReports(userId, dealId, dataObj, accountingPlatform) {
  return request({
    url: `/integration/${accountingPlatform}/upload/upload_all_documents/${userId}/${dealId}`,
    method: `POST`,
    data: dataObj,
  })
}

function getInvoiceCount(userId, accountingPlatform) {
  return request({
    url: `/integration/${accountingPlatform}/invoices/invoice_count/${userId}`,
    method: `GET`,
  })
}
