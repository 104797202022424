import React from 'react'
import { CountryRegionData } from 'react-country-region-selector'
import SelectField from './SelectField'

const getRegions = (country) => {
  if (!country) {
    return []
  }
  let officialCountry = CountryRegionData.find((array) => {
    return array[0] === country
  })
  if (!officialCountry) {
    country = 'United States'
    officialCountry = CountryRegionData.find((array) => {
      return array[0] === country
    })
  }
  return officialCountry[2].split('|').map((regionPair) => {
    let [regionName, regionShortCode = null] = regionPair.split('~')
    return {
      label: regionName,
      value: regionName,
    }
  })
}

const Region = ({ field, state, invalid, ...rest }) => {
  let incCountry
  if (state && state.business && state.business.inc_country) {
    incCountry = state.business.inc_country
  }
  const regions = getRegions(incCountry || field.country || `United States`)
  return (
    <SelectField
      className={invalid ? 'invalid-input' : ''}
      field={{
        ...field,
        options: regions,
      }}
      {...rest}
    />
  )
}

export default Region
