import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { RestrictedAccess } from 'xen/components';
import { useAuthentication, useFeatureFlags } from 'xen/hooks';
const LenderHome = lazy(() => import('../pages/lender-home'));
const ClientHome = lazy(() => import('../pages/client-home'));
const ProspectHome = lazy(() => import('../pages/prospect-home'));
const RootLoggedOut = lazy(() => import('../pages/root-logged-out'));
const RootLoggedIn = lazy(() => import('../pages/root-logged-in'));
export const HOME_PATHS = {
    root: '/',
    lender: '/lender-home',
    client: '/client-home',
    prospect: '/prospect-home',
};
export const HomeRoutes = () => {
    const { client, lender, prospect, root } = HOME_PATHS;
    const { isLoggedIn } = useAuthentication();
    const { xenAuthPages } = useFeatureFlags();
    // If xen_auth_pages is disabled, exclude both xen root routes. They are
    // duplicated by legacy routes and we want those routes to be matched instead.
    if (!xenAuthPages) {
        if (!isLoggedIn)
            return [];
        return [
            _jsx(Route, { exact: true, path: lender, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(LenderHome, {}) }) }, lender),
            _jsx(Route, { exact: true, path: client, children: _jsx(RestrictedAccess, { userTypes: ['client'], children: _jsx(ClientHome, {}) }) }, client),
            _jsx(Route, { exact: true, path: prospect, children: _jsx(RestrictedAccess, { userTypes: ['client', 'prospect'], children: _jsx(ProspectHome, {}) }) }, prospect),
        ];
    }
    // If not logged in, only include logged-out root path
    if (!isLoggedIn)
        return [
            _jsx(Route, { exact: true, path: root, children: _jsx(RootLoggedOut, {}) }, root),
        ];
    // If logged in, include all paths + logged-in root path
    return [
        _jsx(Route, { exact: true, path: root, children: _jsx(RootLoggedIn, {}) }, root),
        _jsx(Route, { exact: true, path: lender, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(LenderHome, {}) }) }, lender),
        _jsx(Route, { exact: true, path: client, children: _jsx(RestrictedAccess, { userTypes: ['client'], children: _jsx(ClientHome, {}) }) }, client),
        _jsx(Route, { exact: true, path: prospect, children: _jsx(RestrictedAccess, { userTypes: ['client', 'prospect'], children: _jsx(ProspectHome, {}) }) }, prospect),
    ];
};
