import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled, RestrictedAccess } from 'xen/components';
import { useAuthentication } from 'xen/hooks';
import { useConfigurationContext } from 'xen/providers';
const FactoringClientDashboard = lazy(() => import('../pages/factoring-client-dashboard'));
const FactoringLenderDashboard = lazy(() => import('../pages/factoring-lender-dashboard'));
export const FACTORING_PATHS = {
    root: '/factoring',
    clientDashboard: '/factoring/client-dashboard',
    lenderDashboard: '/factoring/lender-dashboard',
};
export const FactoringRoutes = () => {
    const { clientDashboard, lenderDashboard, root } = FACTORING_PATHS;
    const configuration = useConfigurationContext();
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    if (!configuration.tenant.factoring.enabled)
        return [
            _jsx(Route, { path: root, children: _jsx(FeatureNotEnabled, { feature: "factoring" }) }, root),
        ];
    return [
        _jsx(Route, { exact: true, path: clientDashboard, children: _jsx(RestrictedAccess, { userTypes: ['client'], children: _jsx(FactoringClientDashboard, {}) }) }, clientDashboard),
        _jsx(Route, { exact: true, path: lenderDashboard, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(FactoringLenderDashboard, {}) }) }, lenderDashboard),
    ];
};
