import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaLock, FaLockOpen } from 'react-icons/fa'

// Components
import Table from '../../../_components/Table'
import { Button } from '../../../_components/Button'
import { EditGLAccountModal } from './EditGLAccountModal'
import { CreateGLAccountModal } from './CreateGLAccountModal'
import { SensitiveChangeModal } from '../../../_components/modals/sensitive_change'

// Selectors
import { useModal } from '../../../_reduxHooks/modal.hook'

// Services
import { getMainGLAccounts } from '../../../_services/fvadmin_service'

//Helpers
import { createColumn } from '../../../_components/TableColumn'

const S_MainGLAccounts = styled.div`
  .create {
    margin-top: 20px;
    float: right;
  }
  .lock-icon {
    float: right;
    cursor: pointer;
  }
  .locked {
    display: none;
  }
`

export const MainGLAccounts = ({ admin_tenant }) => {
  const [loading, setLoading] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [subAccounts, setSubAccounts] = useState([])
  const { openModal, closeModal, modal } = useModal()
  const [locked, setLocked] = useState(true)
  useEffect(() => {
    if (modal.is_open) return

    getMainGLAccounts(admin_tenant).then((resp) => {
      setAccounts(
        resp.map((account) => {
          return { ...account, ...account.parent, subRows: account.children }
        })
      )

      let childrenAccounts = []
      resp.forEach((parentAccount) => {
        const parent = parentAccount.parent
        childrenAccounts = childrenAccounts.concat(
          parentAccount.children.map((child) => {
            return {
              ...child,
              parentName: parent.account_name,
            }
          })
        )
      })
      setSubAccounts(childrenAccounts)
    })
  }, [modal.is_open, admin_tenant])

  const unlock = () => {
    openModal(SensitiveChangeModal, {
      onVerification: () => {
        closeModal()
        setLocked(false)
      },
    })
  }

  return (
    <S_MainGLAccounts>
      <h3>GL Accounts</h3>
      <Table
        columns={[
          createColumn({
            type: 'expand',
          }),
          {
            accessor: (data, index, info) => {
              return info?.isChild ? data.original.children[index].account_name : data.account_name
            },
            headingText: 'GL Account Name',
            id: 'account_name',
          },
          {
            accessor: (data, index, info) => {
              return info?.isChild ? data.original.children[index].account_number : data.account_number
            },
            id: 'account_number',
            headingText: `Account Number`,
          },
          {
            accessor: (data, index, info) => {
              return info?.isChild ? data.original.children[index].id : data.id
            },
            id: 'id',
            headingText: '',
            disableSortBy: true,
            maxWidth: 120,
            Cell: (props) => (
              <div className={`align-right ${locked ? 'locked' : ''}`}>
                <Button
                  className="compact"
                  onClick={() => {
                    openModal(() => {
                      return <EditGLAccountModal account={props.row.original} admin_tenant={admin_tenant} />
                    }, {})
                  }}
                  text="Edit"
                />
              </div>
            ),
            Header: (props) => (
              <div>
                {locked ? (
                  <FaLock className={'lock-icon'} onClick={unlock} size={30} />
                ) : (
                  <FaLockOpen className={'lock-icon'} onClick={() => setLocked(true)} size={30} />
                )}
              </div>
            ),
          },
        ]}
        data={accounts}
      />

      <Button
        className={`compact create ${locked ? 'locked' : ''}`}
        onClick={() => {
          openModal(() => <CreateGLAccountModal admin_tenant={admin_tenant} mainAccounts={accounts} />, {})
        }}
        text="Create Sub Account"
      />
    </S_MainGLAccounts>
  )
}
