import { createStore, applyMiddleware, compose } from 'redux'
import { thunk } from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import rootReducer from '../_reducers'

const persistConfig = {
  key: 'finvoiceapp',
  storage,
  blacklist: ['menuOpen', 'notifications', 'subscription', 'locks'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const reduxMiddleware = applyMiddleware(thunk)

export const store = createStore(persistedReducer, compose(reduxMiddleware))

export const persistor = persistStore(store)
