import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

// Components
import { FormRow } from '../FormRow'

// Utils
import { buildFormFields } from '../../../_helpers/forms'
import { S_ValidationForm } from './ValidationForm.styles'

import ValidationFormFields from './ValidationFormFields'

export const ValidationForm = ({
  entity = {}, // used only for initial/default values, there is no binding
  fields,
  fieldDefaults = {},
  isDisabled = false,
  isSubmitting = false,
  FieldComponent = FormRow,
  WrapperComponent = S_ValidationForm,
  submitHandler = () => {},
  onChange = () => {},
  onBlur = () => {},
  onErrorsUpdate = () => {},
  getFormFunctions = () => {},
  resolver = null,
  resetOnSubmit = false,
  requiredIndicator = true,
  getErrors = () => {}, //parameter passed to this will be a function that syncronously returns errors from the form
  comparisonEntity = null,
  formId = '',
  expandByDefault = [],
}) => {
  const [formFields, setFormFields] = useState([])
  const [pipelineCreated, setPipelineCreated] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    trigger: triggerValidation,
    reset,
    clearErrors: clearError,
    unregister,
    getValues,
    watch,
  } = useForm({
    defaultValues: entity,
    ...(resolver !== null && { resolver }),
    shouldUnregister: true,
  })

  // When we submit and no errors are returned, call the submit handlers
  const onSubmit = async (values) => {
    await submitHandler(values, setError)
    if (resetOnSubmit) {
      reset()
      let formFieldsAfresh = [...formFields]
      setFormFields([])
      setTimeout(() => setFormFields(formFieldsAfresh)) //TODO: Investigate resetting the form for real
    }
  }

  // Set up pipeline to get form functions to parent component (call once)
  // - Make sure form fields are set (non-empty) before calling
  useEffect(() => {
    if (!pipelineCreated && formFields && formFields.length) {
      getFormFunctions({
        submitForm: () => {
          return handleSubmit(onSubmit)
        },
        triggerValidation,
        setValue,
        setError,
        clearError,
        getValues,
      })
      setPipelineCreated(true)
    }
  }, [formFields])

  // Pass errors back to parent handler when they change.
  // Needs special compare because object doesn't update consistently.
  useEffect(() => {
    onErrorsUpdate({ ...errors })
  }, [JSON.stringify(errors)])

  // Builds the initial form fields for the form
  useEffect(() => {
    setFormFields(buildFormFields(entity, fields, fieldDefaults))
    getErrors(() => errors)
  }, [entity, JSON.stringify(fields.map((f) => [f.id, f.shouldShow, f.key, f.options]))])

  useEffect(() => {
    setPipelineCreated(false)
    getFormFunctions({
      submitForm: () => {
        return handleSubmit(onSubmit)
      },
      triggerValidation,
      setValue,
      setError,
      clearError,
      getValues,
    })
    setPipelineCreated(true)
  }, [JSON.stringify(fields.map((f) => [f.id, f.shouldShow]))])

  // Don't render if we don't have fields
  if (!formFields || !formFields.length) {
    return null
  }

  return (
    <WrapperComponent requiredIndicator={requiredIndicator}>
      <ValidationFormFields
        FieldComponent={FieldComponent}
        addKey={''}
        clearError={clearError}
        comparisonEntity={comparisonEntity}
        errors={errors}
        expandByDefault={expandByDefault}
        fields={formFields}
        formId={formId}
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        onBlur={onBlur}
        onChange={onChange}
        register={register}
        setError={setError}
        setValue={setValue}
        triggerValidation={triggerValidation}
        unregister={unregister}
        watch={watch}
      />
    </WrapperComponent>
  )
}

export default ValidationForm
