import React from 'react'
import { CountryRegionData } from 'react-country-region-selector'

// Components
import { SelectInput } from '../../FormInput'

const getRegions = (country = 'United States') => {
  let officialCountry = CountryRegionData.find((array) => {
    return array[0] === country
  })
  if (!officialCountry) {
    country = 'United States'
    officialCountry = CountryRegionData.find((array) => {
      return array[0] === country
    })
  }
  return officialCountry[2].split('|').map((regionPair) => {
    let [regionName, regionShortCode = null] = regionPair.split('~')
    return {
      display: regionName,
      value: regionName,
    }
  })
}

export const RegionField = (props) => {
  const { country, ...rest } = props
  const regions = [{ display: 'Select State', value: '' }].concat(getRegions(country))

  return <SelectInput {...rest} options={regions} />
}
