import React, { useState } from 'react'

// Components
import { ValidationForm } from '../../../_components/Forms/ValidationForm'
import { LoaderButton } from '../../../_components/LoaderButton'

// Constants
import { createGLAccountFormConfig } from '../../../_configs/mainGLAccount.config'

// Services
import { createGLAccount } from '../../../_services/fvadmin_service'

// Utils
import { S_GLAccountModal } from './styles'

// Hooks
import { modalActionHooks } from '../../../_actionHooks'
import { notificationActionHooks } from '../../../_actionHooks/notification.actionHooks'

export const CreateGLAccountModal = ({ admin_tenant, mainAccounts }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [onSubmit, setOnSubmit] = useState()
  const [currentAccount, setCurrentAccount] = useState({})
  const closeModal = modalActionHooks.useCloseModal()
  const showNotification = notificationActionHooks.useShowNotification()

  if (!mainAccounts.length) {
    return null
  }

  const formConfig = {
    entity: {
      ...createGLAccountFormConfig.entity,
      parent_account_id: mainAccounts[0].id,
    },
    fields: [
      ...createGLAccountFormConfig.fields,
      {
        type: 'select',
        id: 'parent_account_id',
        required: false,
        layout: '',
        label: 'Parent Account',
        options: mainAccounts.map((mainAccount) => {
          return {
            value: mainAccount.id,
            display: mainAccount.account_name,
          }
        }),
      },
    ],
  }

  return (
    <S_GLAccountModal>
      <h2>Create GL Account</h2>
      <ValidationForm
        entity={Object.assign(formConfig.entity, currentAccount)}
        fields={formConfig.fields}
        getFormFunctions={({ submitForm, triggerValidation, setValue, setError }) => {
          setOnSubmit(submitForm)
        }}
        submitHandler={async (values, setError) => {
          setIsSubmitting(true)
          createGLAccount(admin_tenant, values).then(
            (resp) => {
              setCurrentAccount(resp)
              closeModal()
              setIsSubmitting(false)
            },
            (e) => {
              showNotification({
                type: 'error',
                message: e,
              })
              setIsSubmitting(false)
            }
          )
        }}
      />
      <div className="submit">
        <LoaderButton isLoading={isSubmitting} onClick={onSubmit} text="Save" />
      </div>
    </S_GLAccountModal>
  )
}
