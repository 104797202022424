export const FINANCING_TYPE_ABL = 'Asset Based Lending'
export const FINANCING_TYPE_FACTORING = 'Factoring'
export const FINANCING_TYPE_EMPTY = ''

export const getFinancingTypeLabel = (type) => {
  switch (type) {
    case FINANCING_TYPE_ABL:
      return 'ABL'
    case FINANCING_TYPE_FACTORING:
      return 'Factoring'
    default:
      return ''
  }
}
