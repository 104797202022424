import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import throttle from 'lodash/throttle'

// Components
import { S_SearchBar } from './SearchBar.styles'
import { FormInput } from '../Forms/FormInput'

// Only triggers the callback every 500ms
// TODO: formalize this delay ms, make it standard
const throttleCallback = throttle((val, callback) => {
  callback(val)
}, 500)

export const SearchBar = ({
  initialValue = '',
  placeholder = 'Search',
  onChange = null,
  isControlled = false,
  value = null,
  className = '',
  iconClassName = '',
  inputClassName = '',
}) => {
  const [inputVal, setInputVal] = useState(initialValue)

  const handleChange = (newVal) => {
    if (!isControlled) {
      setInputVal(newVal)
    }
    if (onChange !== null) {
      throttleCallback(newVal, onChange)
    }
  }

  return (
    <S_SearchBar className={className ? className : ''}>
      <FormInput
        className={`search-field ${inputClassName}`}
        icon={FaSearch}
        iconClassName={`icon-right ${iconClassName}`}
        onChange={(e) => handleChange(e.target.value, handleChange)}
        placeholder={placeholder}
        type="text"
        value={isControlled ? value : inputVal}
      />
    </S_SearchBar>
  )
}
