import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import startCase from 'lodash/startCase'
import { FaSpinner } from 'react-icons/fa'
import { Formik, Form } from 'formik'

import { addRecaptchaScript } from '../HeadScripts/reCaptcha/reCaptcha.helpers'

import Field from '../_components/FieldNew'
import { Button } from '../_components/Button'
import { MarketingColumn } from './MarketingColumn'

// Constants
import urls from '../_constants/urls'
import { tenantColors } from '../_constants/colors'
import { AuthConfig } from '../_configs'
import ROUTES from '../_constants/routes'

import { authenticationActions } from '../_actions'

// Components
import { AlertMessage } from '../_components/AlertMessage'
import { Auth, AlertWrapper, AlertInnerWrapper } from './styles'
import { MarketingColumnOldUnderwrite } from './MarketingColumnOldUnderwrite'

// Helpers
import { redirectByAuthenticationState } from '../_helpers/auth'
import { isLocalhost } from '../_helpers/url'
import { isProductEnabled, isFeatureEnabled } from '../_helpers/flags'
import { FINANCING_TYPE_ABL, FINANCING_TYPE_FACTORING } from '../_constants/financingTypes'
import { cognitoPasswordValid } from '../_helpers/security/passwordValidation'
import { InvalidPasswordCognito } from '../_components/FormValidation/InvalidPasswordCognito'

const RESET_PASSWORD = 'reset_password'

export const ResetPassword = () => {
  const [salesPerson, setSalesPerson] = useState(null)
  const [customError, setCustomError] = useState('')
  const [passwordInvalid, setPasswordInvalid] = useState(false)
  const dispatch = useDispatch()

  const { authentication, env, idleTimeoutMessage, tenant, featureFlags, user } = useSelector((state) => ({
    authentication: state.authentication,
    env: state.env.env,
    idleTimeoutMessage: state.authentication.idleTimeoutMessage,
    tenant: state.env.tenant,
    featureFlags: state.featureFlags,
    user: state.user,
  }))
  const newSignUpFlow = isFeatureEnabled('new_underwriting_sign_up_flow', featureFlags)

  const resetPassword = (email, tempPassword, newPassword, userid, sessionToken) => {
    dispatch(authenticationActions.resetPassword(email, tempPassword, newPassword, userid, sessionToken))
  }
  const clearError = () => {
    dispatch(authenticationActions.clearError())
  }
  const stopLoading = () => {
    dispatch(authenticationActions.stopLoading())
  }

  useEffect(() => {
    if (newSignUpFlow) {
      dispatch(authenticationActions.getSignUpFormAndMarketing(tenant))
    }
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('sp')) {
      setSalesPerson(queryParams.get('sp'))
    }
    clearError()
    stopLoading()
    // Recaptcha disabled on localhost and for demo tenant in staging
    const isLocalHost = isLocalhost()
    const isStaging = env === 'staging'
    const isDemoTenant = ['demo', 'acsfactors'].includes(tenant)
    const disableRecaptchaEvaluation = isLocalHost || (isStaging && isDemoTenant)
    if (!disableRecaptchaEvaluation && disableRecaptchaEvaluation !== undefined) {
      addRecaptchaScript()
    }
  }, [])

  useEffect(() => {
    clearError()
  }, [RESET_PASSWORD])

  redirectByAuthenticationState(RESET_PASSWORD)

  const handleBlur = (setFieldTouched, name) => {
    setFieldTouched(name, true)
  }

  const handleChange = (setFieldValue, attr, val) => {
    setFieldValue(attr, val)
  }

  const config = AuthConfig[tenant]
  const email = user && user.email_address ? user.email_address : ''
  let fields = config[RESET_PASSWORD].fields
  let financing_type = null

  const duplicate_email = 'Duplicate Email'
  const duplicate_phone = 'Duplicate Phone'
  const bgImage = `${urls.IMAGES}/${env}/${tenant}/sign_bg.jpg`
  if (authentication.via_prospect_link) {
    fields = fields.filter((field) => field.prospect_link_applicable)
  }

  return (
    <Auth
      bg={config.common.show_auth_bg ? bgImage : false}
      bg_size={config.common['bg_size']}
      customColors={tenantColors[tenant]}
    >
      <Helmet>
        <title>{startCase(RESET_PASSWORD)}</title>
      </Helmet>
      {authentication.isIdleTimeout && (
        <AlertWrapper>
          <AlertInnerWrapper>
            <AlertMessage type="error">{idleTimeoutMessage}</AlertMessage>
          </AlertInnerWrapper>
        </AlertWrapper>
      )}
      <div className={`auth-panel`}>
        <div className={`section primary-section`}>
          <div className={RESET_PASSWORD}>
            <h1 className={`title`}>{config[RESET_PASSWORD].label_title}</h1>
            <Formik
              initialValues={{
                email: email,
                password: '',
                confirm_password: '',
                temp_password: '',
              }}
              onSubmit={async (values) => {
                if (salesPerson) {
                  values.salesPerson = salesPerson
                }
                if (!financing_type) {
                  financing_type = isProductEnabled(FINANCING_TYPE_ABL, featureFlags)
                    ? FINANCING_TYPE_ABL
                    : FINANCING_TYPE_FACTORING
                }
                const { email, temp_password, password, confirm_password } = values
                setPasswordInvalid(false)
                if (!cognitoPasswordValid(password)) {
                  setCustomError('Password Invalid')
                  setPasswordInvalid(true)
                  return false
                }

                if (password === confirm_password) {
                  resetPassword(email, temp_password, password, user.id, authentication.sessionToken)
                } else {
                  setCustomError('New Password and Confirm New Password fields do not match')
                }
              }}
            >
              {({ setFieldTouched, setFieldValue, values, errors, touched, key = location.key }) => (
                <Form autoComplete="off" className={`${RESET_PASSWORD}-form`} key={key}>
                  <input autoComplete={`off`} name={`hidden`} style={{ display: `none` }} type={`text`} />
                  {authentication.error && <span className={`error-msg`}>{authentication.error}</span>}
                  {customError && <span className={`error-msg`}>{customError}</span>}
                  {(authentication.error === duplicate_email || authentication.error === duplicate_phone) && (
                    <Link className={`duplicate`} to={ROUTES.sign_in}>
                      Sign in instead.
                    </Link>
                  )}
                  {fields.map((field, i) => (
                    <Fragment key={i + field.name}>
                      <Field
                        errors={errors}
                        field={field}
                        handleBlur={(name) => handleBlur(setFieldTouched, name)}
                        handleChange={(attr, val) => handleChange(setFieldValue, attr, val)}
                        touched={touched}
                        values={values}
                      />
                      {field.following_question && values[field.name] === field.following_question.condition && (
                        <Field
                          errors={errors}
                          field={field.following_question}
                          handleBlur={(name) => handleBlur(setFieldTouched, name)}
                          handleChange={(attr, val) => handleChange(setFieldValue, attr, val)}
                          touched={touched}
                          values={values}
                        />
                      )}
                    </Fragment>
                  ))}

                  {passwordInvalid && <InvalidPasswordCognito style={{ marginTop: '20px' }} />}

                  <div className={`form-group`}>
                    <Button
                      buttonType={`button`}
                      className={`submit-btn`}
                      disabled={authentication.loading}
                      text={authentication.loading ? <FaSpinner /> : config[RESET_PASSWORD].label_cta}
                      type={`submit`}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {newSignUpFlow ? <MarketingColumn /> : <MarketingColumnOldUnderwrite />}
      </div>
    </Auth>
  )
}
