import { jsx as _jsx } from "react/jsx-runtime";
import { Redirect as ReactRouterRedirect } from 'react-router-dom';
import { createInternalLinkUrl } from 'xen/helpers';
/**
 * A wrapper around the `Redirect` component that handles redirects to internal
 * links. The component renders a `Redirect` component from `react-router-dom`
 * and only allow valid internal link paths based on the `XenPath` type and
 * requires the correct number of dynamic URL params (0-3).
 *
 * NOTE: `createInternalLinkUrl` only supports a maximum of 3 dynamic URL
 * params. If you need more, update the `InternalLinkProps` type in `xen/types/link.ts`
 * or manually construct your own link component and linkUrl.
 */
export const Redirect = ({ keepSearchParams, searchParams = {}, to, urlParams, ...props }) => {
    // @ts-expect-error TODO: fix type errors
    const linkUrl = createInternalLinkUrl({ keepSearchParams, to, searchParams, urlParams });
    return _jsx(ReactRouterRedirect, { ...props, to: linkUrl });
};
