import { useSelector, shallowEqual } from 'react-redux'

// Constants
import { FINANCING_TYPE_FACTORING, FINANCING_TYPE_ABL, FINANCING_TYPE_EMPTY } from '../_constants/financingTypes'

//Helpers
import { isProductEnabled } from '../_helpers/flags'

export function useFlags() {
  return {
    featureFlags: useSelector((state) => {
      return state.featureFlags
    }, shallowEqual),
    productFlags: useSelector((state) => {
      return state.featureFlags
    }, shallowEqual),
    activeFinancingType: useSelector((state) => {
      const featureFlags = state.featureFlags
      const isAblEnabled = isProductEnabled(FINANCING_TYPE_ABL, featureFlags)
      const isFactoringEnabled = isProductEnabled(FINANCING_TYPE_FACTORING, featureFlags)
      if (isAblEnabled) {
        return FINANCING_TYPE_ABL
      }
      if (isFactoringEnabled) {
        return FINANCING_TYPE_FACTORING
      }
      return FINANCING_TYPE_EMPTY
    }),
  }
}
