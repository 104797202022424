import { additionalBusinessDataConstants } from '../_constants'

export function additionalBusinessData(state = {}, action) {
  switch (action.type) {
    case additionalBusinessDataConstants.GET_ADDITIONAL_BUSINESS_DATA_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
        },
      }
    case additionalBusinessDataConstants.GET_ADDITIONAL_BUSINESS_DATA_SUCCESS:
      return action.additionalData
    case additionalBusinessDataConstants.GET_ADDITIONAL_BUSINESS_DATA_FAILURE:
      return {
        ...state,
        action: {
          loading: false,
          error: action.error,
        },
      }
    case additionalBusinessDataConstants.UPDATE_ADDITIONAL_BUSINESS_DATA_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
        },
      }
    case additionalBusinessDataConstants.UPDATE_ADDITIONAL_BUSINESS_DATA_SUCCESS:
      return {
        ...state,
        ...action.additionalData,
      }
    case additionalBusinessDataConstants.UPDATE_ADDITIONAL_BUSINESS_DATA_FAILURE:
      return {
        ...state,
        action: {
          loading: false,
          error: action.error,
        },
      }
    default:
      return state
  }
}
