import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import {} from 'xen/types';
const defaultUser = {
    activeTill: null,
    archived: false,
    businessAblId: '',
    businessId: '',
    createdAt: new Date(),
    deleted: false,
    disabled: false,
    emailAddress: '',
    firstName: '',
    flowStep: '',
    hasPassword: true,
    id: 0,
    intercomHash: '',
    isAdmin: false,
    isOperations: false,
    isSalesPerson: false,
    isUnderwriter: false,
    landingPageReferrer: '',
    lastName: '',
    middleName: '',
    name: '',
    permissions: [],
    phoneNumber: '',
    resetPasswordRequired: false,
    title: '',
    trafficIp: '',
    trafficUserAgent: '',
    underwriteDealId: 0,
    updatedAt: new Date(),
    userType: 'prospect',
    uuid: '',
};
const UserContext = createContext(defaultUser);
export const UserProvider = ({ children, user }) => {
    return _jsx(UserContext.Provider, { value: user ?? defaultUser, children: children });
};
export const useUserContext = () => {
    const context = useContext(UserContext);
    return context;
};
