import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppShell, ScrollArea } from 'xen-ui';
import { useUserContext } from 'xen/providers';
import { AppLayoutDivider } from '../divider';
import { ClientNavBarBody, LenderNavBarBody, ProspectNavBarBody } from './nav-bar-body';
import { ClientNavBarFooter, LenderNavBarFooter, ProspectNavBarFooter } from './nav-bar-footer';
export const NavBar = () => {
    const { userType } = useUserContext();
    return (_jsxs(_Fragment, { children: [_jsxs(AppShell.Section, { "aria-label": "Navigation links", component: ScrollArea, grow: true, children: [userType === 'lender' && _jsx(LenderNavBarBody, {}), userType === 'client' && _jsx(ClientNavBarBody, {}), userType === 'prospect' && _jsx(ProspectNavBarBody, {})] }), _jsxs(AppShell.Section, { children: [_jsx(AppLayoutDivider, {}), userType === 'lender' && _jsx(LenderNavBarFooter, {}), userType === 'client' && _jsx(ClientNavBarFooter, {}), userType === 'prospect' && _jsx(ProspectNavBarFooter, {})] })] }));
};
