import { jsx as _jsx } from "react/jsx-runtime";
// We want to replace this when we handle login work for Xen 1.0
import { useSignOut } from 'legacy/_reduxHooks/authentication.hook';
import { useAuthentication } from 'xen/hooks';
import { NavBarButton } from './nav-bar-button';
export const NavBarSignOutButton = ({ ...props }) => {
    const { signOut } = useSignOut();
    const { sessionToken } = useAuthentication();
    const handleSignOut = () => {
        signOut(sessionToken);
    };
    return _jsx(NavBarButton, { ...props, onClick: handleSignOut });
};
