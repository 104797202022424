import { UTCDateToSystemString } from './dates'

export const checksDatesToSystemTime = (checks) => {
  checks = checks.map((check) => {
    if (check.created_at) {
      check.created_at = UTCDateToSystemString(check.created_at)
    }
    if (check.updated_at) {
      check.updated_at = UTCDateToSystemString(check.updated_at)
    }
    if (check.date_created) {
      check.date_created = UTCDateToSystemString(check.date_created)
    }
    if (check.date_posted) {
      check.date_posted = UTCDateToSystemString(check.date_posted)
    }
    if (check.documents) {
      check.documents = check.documents.map((doc) => {
        doc.created_at = UTCDateToSystemString(doc.created_at)
        doc.updated_at = UTCDateToSystemString(doc.updated_at)
        return doc
      })
    }
    return check
  })
  return checks
}
