import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

// Constants
import { LENDER_ROUTES } from '../_constants/routes'

// Helpers
import { isLender } from '../_helpers/user-utils'
import { isProductEnabled } from '../_helpers/flags'

export const EsigningLenderRoutes = ({ loggedIn, user, featureFlags }) => {
  // Only for factoring lenders
  if (!isLender(loggedIn, user.user_type) || !isProductEnabled('E-Signing', featureFlags)) {
    return []
  }

  let loadedModules = {}
  const pageComponents = {
    SigningDocuments: import('../_views/LenderViews/SigningDocuments/index.js').then((m) => {
      loadedModules['SigningDocuments'] = m
      return m
    }),
  }
  const EsigningLenderRoutes = [
    // Signing Documents
    {
      path: LENDER_ROUTES.signing_documents,
      Component: lazy(() => pageComponents['SigningDocuments']),
      module: 'SigningDocuments',
    },
  ]

  return EsigningLenderRoutes.map(({ path, Component, exact = false, module }) => (
    // Butt ugly, but this prevents the suspense fallback component from displaying unnecessicarily
    <Route
      exact={exact}
      key={path}
      path={path}
      render={(props) => {
        if (module && loadedModules[module]) {
          //Set status to resolved and result to default export of downloaded module to prevent briefly loading suspense component. See https://github.com/facebook/react/blob/master/packages/react/src/ReactLazy.js
          Component._status = 1
          Component._result = loadedModules[module].default
        }
        return <Component {...props} />
      }}
    />
  ))
}
