import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from 'xen-ui';
/** Based on https://mantine.dev/theming/colors/#default-colors */
export const invoiceStateColorMap = {
    Incomplete: 'gray',
    Submitted: 'yellow.3',
    Verified: 'green.4',
    Purchased: 'blue',
    Closed: 'red',
};
export const InvoiceStateBadge = ({ invoice }) => {
    const { state } = invoice;
    const badgeColor = invoiceStateColorMap[state];
    return (_jsx(Badge, { autoContrast: true, color: badgeColor, "data-testid": "invoice-state-badge", children: state }));
};
