import styled from 'styled-components'
import { S_Chip } from '../../../_components/AutoCompleteInput/Chip.styles'
import colors from '../../../_constants/colors'

export const S_UserAlert = styled.div``

export const S_TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  padding: 25px 20px;
  border: 1.5px solid ${colors.GREY};
  border-radius: 2px 2px 0 0;
  background-color: ${colors.GREY_LIGHT_FILLS};
  margin: 10px 0;
  cursor: pointer;

  .subject {
    color: ${colors.TEXT_DARK};
    padding-bottom: 5px;
  }

  .summary {
    color: ${colors.TEXT_LIGHT};
    font-size: 14px;
  }

  .whodunnit {
    padding-top: 5px;
  }

  ${S_Chip} {
    margin-left: 6px;
    text-transform: capitalize;
    color: white;
    font-size: 12px;
    display: inline-block;
    &.new {
      background-color: ${colors.FINVOICE_BLUE};
    }
  }
`
