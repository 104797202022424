export const topBusinessDebtorsConstants = {
  GET_TOP_BUSINESS_DEBTORS_REQUEST: 'GET_TOP_BUSINESS_DEBTORS_REQUEST',
  GET_TOP_BUSINESS_DEBTORS_SUCCESS: 'GET_TOP_BUSINESS_DEBTORS_SUCCESS',
  GET_TOP_BUSINESS_DEBTORS_FAILURE: 'GET_TOP_BUSINESS_DEBTORS_FAILURE',

  CREATE_BUSINESS_DEBTOR_REQUEST: 'CREATE_BUSINESS_DEBTOR_REQUEST',
  CREATE_BUSINESS_DEBTOR_SUCCESS: 'CREATE_BUSINESS_DEBTOR_SUCCESS',
  CREATE_BUSINESS_DEBTOR_FAILURE: 'CREATE_BUSINESS_DEBTOR_FAILURE',

  UPDATE_DEBTOR_REQUEST: 'UPDATE_DEBTOR_REQUEST',
  UPDATE_DEBTOR_SUCCESS: 'UPDATE_DEBTOR_SUCCESS',
  UPDATE_DEBTOR_FAILURE: 'UPDATE_DEBTOR_FAILURE',

  UPDATE_BUSINESS_DEBTOR_REQUEST: 'UPDATE_BUSINESS_DEBTOR_REQUEST',
  UPDATE_BUSINESS_DEBTOR_SUCCESS: 'UPDATE_BUSINESS_DEBTOR_SUCCESS',
  UPDATE_BUSINESS_DEBTOR_FAILURE: 'UPDATE_BUSINESS_DEBTOR_FAILURE',

  ADD_TOP_BUSINESS_DEBTOR: 'ADD_TOP_BUSINESS_DEBTOR',

  REMOVE_TOP_BUSINESS_DEBTOR: 'REMOVE_TOP_BUSINESS_DEBTOR',

  DELETE_BUSINESS_DEBTOR_REQUEST: 'DELETE_BUSINESS_DEBTOR_REQUEST',
  DELETE_BUSINESS_DEBTOR_SUCCESS: 'DELETE_BUSINESS_DEBTOR_SUCCESS',
  DELETE_BUSINESS_DEBTOR_FAILURE: 'DELETE_BUSINESS_DEBTOR_FAILURE',
}
