import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformSerializedInvoice } from './transform';
async function getInvoice({ urlParams: { id } }) {
    try {
        const response = await request({
            method: 'GET',
            url: `/invoices/${id}`,
        });
        const data = transformSerializedInvoice(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not fetch invoice');
    }
}
export const useGetInvoice = (id) => {
    return useQuery({
        queryKey: ['invoices', id],
        queryFn: () => getInvoice({ urlParams: { id } }),
    });
};
