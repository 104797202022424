import { darken } from 'polished'
import { PropertyProxy, ConfigProxy } from '../_helpers/util'

export const styleGuideColors = {
  LIGHT_GREY: '#DADFE5',
  DEFAULT_TEXT: '#58595B',
}

export const colors = {
  BLACK: '#000',
  BORDER_GREY: '#D0D2D3',
  DARK_GREY: '#DBE0E6',
  DEFAULT_AUTH_BACKGROUND: '#51575b',
  DEFAULT_BACKGROUND: '#F1F1F133',
  DEFAULT_FOOTER_HEADER: '#FFFFFF',
  DEFAULT_PRIMARY: '#043754',
  DEFAULT_SECONDARY: '#00AAE0',
  DEFAULT_SECONDARY_DARK: darken(0.1, '#00AAE0'),
  FINVOICE_BLUE: '#00aee1',
  GREY: '#E6EAEE',
  GREY_LIGHT_FILLS: '#EDF1F3',
  ICON_GREY: '#CFD1D2',
  INNER_GREY_BOX: '#E9EEF0',
  LIGHT_GREY: '#8F9EA1',
  MATERIAL_GREEN: '#2fda99',
  MATERIAL_DARK_GREEN: '#0a730a',
  MATERIAL_RED: '#F44336',
  MATERIAL_YELLOW: '#FFEB3B',
  MATERIAL_RED_LIGHT_50: '#FF8787',
  NEGATIVE_VERIFICATION: '#FF9999',
  NEUTRAL_VERIFICATION: '#AAAAAA',
  NO_VERIFICATION: '#CCCCCC',
  OUTLINE_GREY_BOX: '#CFD1D2',
  POSITIVE_VERIFICATION: '#00B5E3',
  RED_ERROR: '#D50000',
  SUBTITLE_GREY: '#9EAEB5',
  TEXT_DARK: '#393F44',
  TEXT_LIGHT: '#9EAEB5',
  TITLE_GREY: '#2B3035',
  WHITE: '#FFF',
  DEFAULT_TEXT: '#58595B',
  CHART_DARK_GREY_LINE: '#3a3f44',
  CHART_LIGHT_GREEN_LINE: '#54b9b5',
  MATERIAL_ORANGE: '#f26f11',
  SAPPHIRE_BLUE: '#0059B3',
  SKY_BLUE: '#66B2FF',
}

// authBackground: background colour on apply now, sign in, sign up etc. pages
// primary: used for the Header, Footer, loading pages and selected button elements
// secondary: used for selected button elements, banners, selected text and text hovers

export const DEFAULT_COLORS = {
  authBackground: colors.DEFAULT_AUTH_BACKGROUND,
  primary: colors.DEFAULT_PRIMARY,
  secondary: colors.DEFAULT_SECONDARY,
  footer_header: colors.DEFAULT_FOOTER_HEADER,
  background: colors.DEFAULT_BACKGROUND,
  primary_button: colors.DEFAULT_PRIMARY,
  auth_button_border: false,

  positiveVerification: colors.POSITIVE_VERIFICATION,
  neutralVerification: colors.NEUTRAL_VERIFICATION,
  negativeVerification: colors.NEGATIVE_VERIFICATION,
  noVerification: colors.NO_VERIFICATION,
  errorColor: colors.RED_ERROR,
  activeHeaderLink: colors.DEFAULT_SECONDARY,
  header_text: colors.WHITE,
  header_pill_text: colors.FINVOICE_BLUE,
}

export const tenantColors = ConfigProxy(
  {
    newcenturyfinancial: PropertyProxy(
      {
        primary: '#032a55',
        secondary: '#58a309',
        primary_button: '#032a55',
        activeHeaderLink: '#58a309',
      },
      DEFAULT_COLORS
    ),
    sallyportcf: PropertyProxy(
      {
        authBackground: '#f8f8f8',
        primary: '#2a5e8d',
        secondary: '#2a5e8d',
        primary_button: '#f8f8f8',
        activeHeaderLink: '#fff',
      },
      DEFAULT_COLORS
    ),
    capitalnow: PropertyProxy(
      {
        primary: '#4e606e',
        secondary: '#fe4e00',
        primary_button: '#4e606e',
        activeHeaderLink: '#fe4e00',
      },
      DEFAULT_COLORS
    ),
    republicbc: PropertyProxy(
      {
        authBackground: '#d3d3d3',
        primary: '#075383',
        secondary: '#075383',
        primary_button: '#075383',
        activeHeaderLink: '#fff',
        header_text: '#fff',
        header_pill_text: '#000',
      },
      DEFAULT_COLORS
    ),
    sevenoaks: PropertyProxy(
      {
        authBackground: '#f8f8f8',
        primary: '#2f5f2f',
        secondary: '#003b7f',
        primary_button: '#2f5f2f',
        activeHeaderLink: '#fff',
        header_text: '#fff',
        header_pill_text: '#000',
      },
      DEFAULT_COLORS
    ),
    cfgroup: PropertyProxy(
      {
        primary: '#2d2d2d',
        secondary: '#41a0bf',
        primary_button: '#2d2d2d',
        activeHeaderLink: '#41a0bf',
      },
      DEFAULT_COLORS
    ),
    tradegate: PropertyProxy(
      {
        authBackground: '#f8f8f8',
        primary: '#792728',
        secondary: '#d3b100',
        primary_button: '#792728',
        activeHeaderLink: '#d3b100',
      },
      DEFAULT_COLORS
    ),
    summitfr: PropertyProxy(
      {
        authBackground: '#e1ecf8',
        primary: '#ffffff',
        secondary: '#0046b4',
        primary_button: '#ffffff',
        trackerform: '#0046b4',
        activeHeaderLink: '#0046b4',
      },
      DEFAULT_COLORS
    ),
    hedayacapital: PropertyProxy(
      {
        authBackground: '#f8f8f8',
        primary: '#817c77',
        secondary: '#009af0',
        primary_button: '#817c77',
        activeHeaderLink: '#009af0',
      },
      DEFAULT_COLORS
    ),
    zenithinsured: PropertyProxy(
      {
        primary: '#0a4d7f',
        secondary: '#8bd8e7',
        primary_button: '#0a4d7f',
        activeHeaderLink: '#8bd8e7',
      },
      DEFAULT_COLORS
    ),
    tbscapitalfunding: PropertyProxy(
      {
        primary: '#0a0909',
        secondary: '#7500f0',
        primary_button: '#0a0909',
        activeHeaderLink: '#7500f0',
      },
      DEFAULT_COLORS
    ),
    axosbank: PropertyProxy(
      {
        primary: '#1d3769',
        secondary: '#ffa42a',
        primary_button: '#1d3769',
        authBackground: '#216888',
        activeHeaderLink: '#ffa42a',
      },
      DEFAULT_COLORS
    ),
    twelvefive: PropertyProxy(
      {
        authBackground: '#f8f8f8',
        primary: '#081e54', //'#071e54',
        secondary: '#2c82bf', //'#d42d2a',
        primary_button: '#081e54',
        activeHeaderLink: '#2c82bf',
      },
      DEFAULT_COLORS
    ),
    scfactoringsolution: PropertyProxy(
      {
        authBackground: '#ebebeb',
        primary: '#208b7d',
        secondary: '#208b7d',
        primary_button: '#208b7d',
        activeHeaderLink: '#fff',
      },
      DEFAULT_COLORS
    ),
    invoicefactoringsolutions: PropertyProxy(
      {
        authBackground: '#ffffff',
        primary: '#041f2d',
        secondary: '#041f2d',
        primary_button: '#041f2d',
        activeHeaderLink: '#fff',
      },
      DEFAULT_COLORS
    ),
    boldbusinesscapital: PropertyProxy(
      {
        primary: '#0071d1',
        secondary: '#0071d1',
        primary_button: '#0071d1',
        activeHeaderLink: '#fff',
      },
      DEFAULT_COLORS
    ),
    portercapital: PropertyProxy(
      {
        primary: '#00aa45',
        secondary: '#00aa45',
        primary_button: '#00aa45',
        activeHeaderLink: '#fff',
      },
      DEFAULT_COLORS
    ),
    marcocapital: PropertyProxy(
      {
        primary: '#0b0707',
        secondary: '#150d0d',
        primary_button: '#0b0707',
        activeHeaderLink: '#fff',
      },
      DEFAULT_COLORS
    ),
    marcofi: PropertyProxy(
      {
        primary: '#c2f3ff',
        secondary: '#005bf0',
        primary_button: '#c2f3ff',
        authBackground: '#e0fffd',
        listColor: '#000',
        defaultTextColor: '#000',
        activeHeaderLink: '#005bf0',
      },
      DEFAULT_COLORS
    ),
    chartercapitalusa: PropertyProxy(
      {
        primary: '#4a70a7',
        secondary: '#4a70a7',
        authBackground: '#ccc',
        primary_button: '#4a70a7',
        activeHeaderLink: '#fff',
      },
      DEFAULT_COLORS
    ),
    acsfactors: PropertyProxy(
      {
        primary: '#ffffff',
        secondary: '#03ade5',
        authBackground: '#edf1f3',
        footer_header: '#03ade5',
        background: '#d9d9d9',
        primary_button: '#7f7f7f',
        trackerform: '#03ade5',
        activeHeaderLink: '#03ade5',
        header_text: '#fff',
        header_pill_text: '#000',
      },
      DEFAULT_COLORS
    ),
    ccbg: PropertyProxy(
      {
        authBackground: '#f8f8f8',
        primary: '#ffffff',
        secondary: '#ce0e41',
        footer_header: '#676767',
        primary_button: '#ce0e41',
        line: '#7f7f7f',
        trackerform: '#ce0e41',
        activeHeaderLink: '#ce0e41',
      },
      DEFAULT_COLORS
    ),
    newbridgeglobal: PropertyProxy(
      {
        primary: '#78c1d3',
        secondary: '#2798fa',
        primary_button: '#78c1d3',
        authBackground: '#fff',
        activeHeaderLink: '#2798fa',
      },
      DEFAULT_COLORS
    ),
    dat: PropertyProxy(
      {
        primary: '#ffffff',
        secondary: '#0047e0',
        authBackground: '#edf1f3',
        footer_header: '#0047e0',
        background: '#d9d9d9',
        primary_button: '#7f7f7f',
        trackerform: '#0047e0',
        activeHeaderLink: '#0047e0',
      },
      DEFAULT_COLORS
    ),
    ctrlpay: PropertyProxy(
      {
        primary: '#121C4A',
        secondary: '#80F2ED',
        activeHeaderLink: '#80F2ED',
      },
      DEFAULT_COLORS
    ),
    breakoutfinance: PropertyProxy(
      {
        primary: '#FFF',
        secondary: '#1f6a96',
        authBackground: '#edf1f3',
        footer_header: '#1f6a96',
        background: '#d9d9d9',
        primary_button: '#1f6a96',
        trackerform: '#e8663e',
        activeHeaderLink: '#1f6a96',
      },
      DEFAULT_COLORS
    ),
    fastarfunding: PropertyProxy(
      {
        primary: '#fffdfd',
        secondary: '#1f6a96',
        authBackground: '#969494',
        footer_header: '#1f6a96',
        background: '#d9d9d9',
        primary_button: '#1f6a96',
        trackerform: '#e8663e',
        activeHeaderLink: '#1f6a96',
        header_text: '#1f6a96',
        header_pill_text: '#FFF',
      },
      DEFAULT_COLORS
    ),
    capitalplus: PropertyProxy(
      {
        primary: '#1c3978',
        navLinkColor: '#fff',
        secondary: '#1c3978',
        authBackground: '#edf1f3',
        footer_header: '#fff',
        background: '#f1f1f133',
        line: '#7f7f7f',
        primary_button: '#519f20',
        trackerform: '#0a3d91',
        auth_button_border: '#fff',
        activeHeaderLink: '#fff',
        header_text: '#fff',
        header_pill_text: '#000',
      },
      DEFAULT_COLORS
    ),
    capitalplusfinancial: PropertyProxy(
      {
        primary: '#1c3978',
        navLinkColor: '#fff',
        secondary: '#1c3978',
        authBackground: '#edf1f3',
        footer_header: '#fff',
        background: '#f1f1f133',
        line: '#7f7f7f',
        primary_button: '#519f20',
        trackerform: '#0a3d91',
        auth_button_border: '#fff',
        activeHeaderLink: '#fff',
        header_text: '#fff',
        header_pill_text: '#000',
      },
      DEFAULT_COLORS
    ),
    changecapital: PropertyProxy(
      {
        primary: '#15537A',
        navLinkColor: '#fff',
        secondary: '#15537A',
        authBackground: '#edf1f3',
        footer_header: '#fff',
        background: '#f1f1f133',
        line: '#7f7f7f',
        primary_button: '#519f20',
        trackerform: '#196493',
        auth_button_border: '#fff',
        activeHeaderLink: '#fff',
        header_text: '#fff',
        header_pill_text: '#000',
      },
      DEFAULT_COLORS
    ),
    cfpartners: PropertyProxy(
      {
        primary: '#f58520',
        navLinkColor: '#fff',
        secondary: '#15537A',
        authBackground: '#edf1f3',
        footer_header: '#FAD300',
        background: '#FAD300',
        line: '#7f7f7f',
        primary_button: '#f58520',
        trackerform: '#196493',
        auth_button_border: '#fff',
        activeHeaderLink: '#fff',
        header_text: '#fff',
        header_pill_text: '#000',
      },
      DEFAULT_COLORS
    ),
    commercialfinancepartners: PropertyProxy(
      {
        primary: '#f58520',
        navLinkColor: '#fff',
        secondary: '#15537A',
        authBackground: '#edf1f3',
        footer_header: '#FAD300',
        background: '#FAD300',
        line: '#7f7f7f',
        primary_button: '#f58520',
        trackerform: '#196493',
        auth_button_border: '#fff',
        activeHeaderLink: '#fff',
        header_text: '#fff',
        header_pill_text: '#000',
      },
      DEFAULT_COLORS
    ),
    rmcapital: PropertyProxy(
      {
        primary: '#7f7f7f',
        navLinkColor: '#fff',
        secondary: '#826f6f',
        authBackground: '#797878',
        footer_header: '#fff3f3',
        background: '#797878',
        line: '#7f7f7f',
        primary_button: '#fff3f3',
      },
      DEFAULT_COLORS
    ),
    unicorncapital: PropertyProxy(
      {
        primary: '#3e6952',
        navLinkColor: '#fff',
        secondary: '#75a99f',
        authBackground: '#cbdae3',
        footer_header: '#fff3f3',
        background: '#cbdae3',
        line: '#7f7f7f',
        primary_button: '#fff3f3',
      },
      DEFAULT_COLORS
    ),
    canntella: PropertyProxy(
      {
        primary: '#000115',
        navLinkColor: '#fff',
        secondary: '#00821e',
        authBackground: '#cbdae3',
        footer_header: '#fff3f3',
        background: '#cbdae3',
        line: '#7f7f7f',
        primary_button: '#fff3f3',
      },
      DEFAULT_COLORS
    ),
    interportcapital: PropertyProxy(
      {
        primary: '#fff',
        navLinkColor: '#fff',
        secondary: '#fe5e55',
        authBackground: '#edf1f3',
        footer_header: '#0009dd',
        background: '#f1f1f133',
        line: '#7f7f7f',
        primary_button: '#519f20',
        trackerform: '#0a3d91',
        auth_button_border: '#fff',
        activeHeaderLink: '#fff',
        header_text: '#0009dd',
        header_pill_text: '#FFF',
      },
      DEFAULT_COLORS
    ),
    velocityfi: PropertyProxy(
      {
        primary: '#002e79',
        navLinkColor: '#fff',
        secondary: '#0069be',
        authBackground: '#dcd9d9',
        footer_header: '#fff',
        background: '#f1f1f133',
        line: '#7f7f7f',
        primary_button: '#519f20',
        trackerform: '#0a3d91',
        auth_button_border: '#fff',
        activeHeaderLink: '#dcd9d9',
        header_text: '#fff',
        header_pill_text: '#000',
      },
      DEFAULT_COLORS
    ),
    fundient: PropertyProxy(
      {
        primary: '#123d64',
        navLinkColor: '#fff',
        secondary: '#123d64',
        authBackground: '#edf1f3',
        footer_header: '#fff',
        background: '#f1f1f133',
        line: '#7f7f7f',
        primary_button: '#123d64',
        trackerform: '#123d64',
        auth_button_border: '#fff',
        activeHeaderLink: '#fff',
        header_text: '#fff',
        header_pill_text: '#000',
      },
      DEFAULT_COLORS
    ),
  },
  DEFAULT_COLORS
)

export default colors
