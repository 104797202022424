import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { formatCase, notifications } from 'xen/helpers';
export const useCreateLinkToken = () => {
    return useMutation({
        mutationFn: async (payload) => {
            const response = await request({
                url: '/integration/plaid/application_accesses/create_link_token',
                method: 'POST',
                data: formatCase.objectKeys('snake', payload),
            });
            return formatCase.objectKeys('camel', response);
        },
        onError: () => {
            notifications.error('Could not open Plaid Link modal');
        },
    });
};
