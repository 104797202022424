import React from 'react'
import { FormInput } from '../../../FormInput'
import { S_Checkbox, S_Checkmark, S_CheckboxLabelText } from './Checkbox.styles'

export const Checkbox = (props) => {
  const { checkboxLabel = null, key = '' } = props

  return (
    <S_Checkbox key={key}>
      <FormInput {...props} />
      <S_Checkmark />
      {checkboxLabel ? <S_CheckboxLabelText>{checkboxLabel}</S_CheckboxLabelText> : ''}
    </S_Checkbox>
  )
}
