const defaultState = {
  isOpen: false,
}

export default function sideNav(state = defaultState, action) {
  switch (action.type) {
    case 'ISOPENCHANGE':
      return {
        isOpen: action.isOpen,
      }
    default:
      return state
  }
}
