import { UTCDateToSystemString } from '../dates'

export const lastApprovalToSystemtime = (lastApproval) => {
  if (lastApproval.created_at) {
    lastApproval.created_at = UTCDateToSystemString(lastApproval.created_at)
  }
  if (lastApproval.updated_at) {
    lastApproval.updated_at = UTCDateToSystemString(lastApproval.updated_at)
  }

  return lastApproval
}
