export const formatDecimal = (value, { places = 2 } = {}) => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: places,
        maximumFractionDigits: places,
    }).format(value);
};
export const formatDecimalAsNumber = (value, { places = 2 } = {}) => {
    return Number(Number(value.replaceAll(',', '')).toFixed(places));
};
