import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformBusinessTerm } from './transform';
async function getBusinessTerms(businessId) {
    try {
        const response = await request({
            method: 'GET',
            url: `/businesses/${businessId}/terms_entries`,
        });
        const data = response.map(transformBusinessTerm);
        return data;
    }
    catch (_error) {
        throw Error('Could not fetch business terms');
    }
}
export const useGetBusinessTerms = (businessId) => {
    return useQuery({
        queryKey: ['businesses', businessId, 'terms'],
        queryFn: () => getBusinessTerms(businessId),
        enabled: !!businessId,
    });
};
