import { jsx as _jsx } from "react/jsx-runtime";
import { ActionIcon, XenIcon } from 'xen-ui';
import { useGetDocumentDownloadLink } from 'xen/api/use-document';
export const DocumentListDownload = ({ document: doc }) => {
    const { data: { url: downloadLink } = {}, isLoading: isDownloadLinkLoading, isError: isPaymentsError, } = useGetDocumentDownloadLink(doc.id);
    if (isPaymentsError) {
        return (_jsx(ActionIcon, { "aria-label": 'Download Document Disabled', disabled: true, loading: isDownloadLinkLoading, role: "anchor", size: "sm", variant: "transparent", children: _jsx(XenIcon.DownloadOff, { size: "2em" }) }));
    }
    return (_jsx(ActionIcon, { "aria-label": `Download ${doc.documentName}`, color: "black", component: "a", href: downloadLink, loading: isDownloadLinkLoading, size: "sm", variant: "transparent", children: _jsx(XenIcon.Download, { size: "2em" }) }));
};
