import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from 'xen-ui';
export const PaymentStateBadge = ({ payment }) => {
    const { state } = payment;
    const badgeColorMap = {
        incomplete: 'gray',
        complete: 'green',
        returned: 'red',
    };
    const badgeColor = badgeColorMap[state];
    return _jsx(Badge, { bg: badgeColor, children: state });
};
