import { useCallback } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import { tenantActions } from '../_actions'

export function useTenant() {
  const dispatch = useDispatch()

  return {
    setTenant: useCallback((tenant) => dispatch(tenantActions.setTenant(tenant)), [dispatch]),
    tenant: useSelector((state) => {
      return state.env.tenant
    }, shallowEqual),
    tenantObj: useSelector((state) => {
      return state.tenant
    }, shallowEqual),
  }
}
