import { dragConstants } from '../_constants'

export function drag(state = {}, action) {
  switch (action.type) {
    case dragConstants.SET_DRAG_OVER_TARGET:
      return {
        dragOverTarget: action.target,
      }
    default:
      return state
  }
}
