import { ConfigProxy } from '../_helpers/util'
import { BBCTypes } from '../_views/LenderViews/ClientDetails/ClientBBCSections/BBC.constants'

const DEFAULT_OVERVIEW = {
  title: 'Overview',
  stats: [
    {
      type: 'currency',
      label: 'Total Credit Outstanding',
      name: 'total_credit_outstanding',
    },
    {
      type: 'number',
      label: 'Risk Rating',
      name: 'risk_rating',
    },
    {
      type: 'currency',
      label: 'Pending Funding',
      name: 'transaction_pending',
    },
    {
      type: 'currency',
      label: 'Borrowing Base',
      name: 'borrowing_base',
    },
    {
      type: 'currency',
      label: 'Pending Borrowing Base',
      name: 'pending_borrowing_base',
    },
    {
      type: 'currency',
      label: 'Unused Availability',
      name: 'unused_availability',
    },
    {
      type: 'currency',
      label: 'Pending Availability',
      name: 'pending_availability',
      negativeWarning: 'Future funding requests will be done as overadvances.',
    },
    {
      type: 'currency',
      label: 'Accrued Interest + Fees',
      name: 'accrued_interest_plus_fees',
    },
  ],
}

export const ablClientHomeConfig = ConfigProxy(
  {
    finvoice: {
      overview: DEFAULT_OVERVIEW,
    },
    boldbusinesscapital: {
      overview: DEFAULT_OVERVIEW,
    },
    capitalnow: {
      overview: DEFAULT_OVERVIEW,
    },
    portercapital: {
      overview: DEFAULT_OVERVIEW,
    },
    marcocapital: {
      overview: DEFAULT_OVERVIEW,
    },
    marcofi: {
      overview: DEFAULT_OVERVIEW,
    },
    twelvefive: {
      overview: DEFAULT_OVERVIEW,
    },
    acsfactors: {
      overview: DEFAULT_OVERVIEW,
    },
    abldemo: {
      overview: DEFAULT_OVERVIEW,
    },
  },
  {
    overview: DEFAULT_OVERVIEW,
  }
)

export const borrowingBaseCertificateConfig = {
  title: 'Borrowing Base Certificate',
  rateTitle: 'Advance Rate',
  marginedAmount: 'Total Margined Amount',
  lastSubTitle: 'Last Certificate',
  newSubTitle: 'New Certificate',
  availableBorrowingBaseTitle: 'Available Borrowing Base',
  twelvefive: {
    sections: [
      {
        title: 'Accounts Receivable',
        totalCaption: 'Total Eligible Receivable:',
        section_type: BBCTypes.ACCOUNTS_RECEIVABLE.value,
        fields: [
          {
            type: 'date',
            name: 'report_date',
            label: 'Report Date',
          },
          {
            type: 'currency',
            name: 'total_accounts_receivable',
            label: 'Total Accounts Receivable',
          },
        ],
      },
      {
        title: 'Equipment',
        totalCaption: 'Total:',
        section_type: BBCTypes.EQUIPMENT.value,
        fields: [
          {
            type: 'date',
            name: 'equipment_date',
            label: 'Date',
          },
          {
            type: 'currency',
            name: 'total_equipment_amount',
            label: 'Total Amount',
          },
        ],
      },
      {
        title: 'Inventory Summary',
        totalCaption: 'Eligible Inventory Amount:',
        section_type: BBCTypes.INVENTORY.value,
        fields: [
          {
            type: 'date',
            name: 'valuation_balance_sheet_date',
            label: 'Date of Valuation or Balance Sheet',
          },
          {
            type: 'currency',
            name: 'total_inventory_amount',
            label: 'Total Inventory Amount',
          },
        ],
      },
    ],
  },
  finvoice: {
    sections: [
      {
        title: 'Accounts Receivable',
        totalCaption: 'Total Eligible Receivable:',
        section_type: BBCTypes.ACCOUNTS_RECEIVABLE.value,
        fields: [
          {
            type: 'date',
            name: 'report_date',
            label: 'Report Date',
          },
          {
            type: 'currency',
            name: 'total_accounts_receivable',
            label: 'Total Accounts Receivable',
          },
        ],
      },
      {
        title: 'Equipment',
        totalCaption: 'Total:',
        section_type: BBCTypes.EQUIPMENT.value,
        fields: [
          {
            type: 'date',
            name: 'equipment_date',
            label: 'Date',
          },
          {
            type: 'currency',
            name: 'total_equipment_amount',
            label: 'Total Amount',
          },
        ],
      },
      {
        title: 'Inventory Summary',
        totalCaption: 'Eligible Inventory Amount:',
        section_type: BBCTypes.INVENTORY.value,
        fields: [
          {
            type: 'date',
            name: 'valuation_balance_sheet_date',
            label: 'Date of Valuation or Balance Sheet',
          },
          {
            type: 'currency',
            name: 'total_inventory_amount',
            label: 'Total Inventory Amount',
          },
        ],
      },
    ],
  },
  abldemo: {
    sections: [
      {
        title: 'Accounts Receivable',
        totalCaption: 'Total Eligible Receivable:',
        section_type: BBCTypes.ACCOUNTS_RECEIVABLE.value,
        fields: [
          {
            type: 'date',
            name: 'report_date',
            label: 'Report Date',
          },
          {
            type: 'currency',
            name: 'total_accounts_receivable',
            label: 'Total Accounts Receivable',
          },
        ],
      },
      {
        title: 'Equipment',
        totalCaption: 'Total:',
        section_type: BBCTypes.EQUIPMENT.value,
        fields: [
          {
            type: 'date',
            name: 'equipment_date',
            label: 'Date',
          },
          {
            type: 'currency',
            name: 'total_equipment_amount',
            label: 'Total Amount',
          },
        ],
      },
      {
        title: 'Inventory Summary',
        totalCaption: 'Eligible Inventory Amount:',
        section_type: BBCTypes.INVENTORY.value,
        fields: [
          {
            type: 'date',
            name: 'valuation_balance_sheet_date',
            label: 'Date of Valuation or Balance Sheet',
          },
          {
            type: 'currency',
            name: 'total_inventory_amount',
            label: 'Total Inventory Amount',
          },
        ],
      },
    ],
  },
}

export const bbcDocUploadConfig = {
  title: 'Upload Documents',
  subtitle: 'Type',
  files: [
    {
      title: 'Aged Receivables Summary',
      name: 'aged_receivables_summary',
    },
    {
      title: 'Inventory Valuation',
      name: 'inventory_valuation',
    },
    {
      title: 'Equipment Summary',
      name: 'equipment_summary',
    },
    {
      title: 'Term Loan Depreciation Schedule',
      name: 'term_loan_depreciation_schedule',
    },
  ],
}
