/**
 * Returns a form state based on an entity and a model for that entity type's form fields.
 * @param {*} entity
 * @param {*} fields
 * @param {*} fieldDefaults
 */
export const buildFormFields = (entity, fields, fieldDefaults = {}) => {
  return fields.map((field) => {
    const isNested = field.id && field.id.split('.').length > 1
    let currentField = entity[field.id]

    if (isNested) {
      const fieldNameSpace = field.id.split('.')
      const groupName = fieldNameSpace[0]
      const fieldName = fieldNameSpace[1]
      currentField = entity?.[groupName]?.[fieldName]
    }

    const isGroup = field?.isGroup
    if (isGroup) {
      // Recursively call buildFormFields to add DefaultValues for fields in array
      return { ...field, groupFields: buildFormFields(entity, field.groupFields, fieldDefaults) }
    }

    const defaultValue = currentField === undefined || currentField === null ? field.defaultValue : currentField
    const formInputObj = { ...fieldDefaults, ...field, defaultValue }
    return formInputObj
  })
}

/**
 * Returns a unique identifier for a form field
 * @param {*} field
 * @param {*} index
 */
export const getFormikName = (field, index = null) => {
  const { table, name, type } = field
  if (table === `business_officers`) {
    return `business_officers[${index}].${name}`
  } else if (table === `top_business_debtors`) {
    return `top_business_debtors[${index}].${name}`
  } else if (table === `uploadedInvoices`) {
    return `uploadedInvoices[${index}].${name}`
  } else if (type === `upload_document`) {
    return `${table}.have_${name}`
  } else if (table) {
    return `${table}.${name}`
  } else {
    return `${name}`
  }
}

/**
 * Checks if the given email is valid
 * @param {String} email
 * @returns {Boolean} True if the email is valid, false if not
 */
export const isValidEmail = (email) => {
  const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegExp.test(email)
}

/**
 * Get the error field from the react-hook-form errors object
 * using the field object path in the form of a string, e.g.
 * 'deal.0.business_contacts.0.email'
 *
 * @param {String} path
 * @param {Object} errors
 * @returns {Object} The error field object
 * @example
 * const errors = {
 *   deal: [{
 *     business_contacts: [{ email: { type: "required", message: "", ref: {} }},]
 *   },]
 * }
 * const path = 'deal[0].business_contacts[0].email'
 * const errorField = getErrorField(path, errors)
 * console.log(errorField)
 * // { type: "required", message: "", ref: {} }
 *
 */
export const getErrorField = (path, errors) => {
  if (!path || path.length < 1 || !errors || Object.keys(errors).length < 1) {
    return undefined
  }

  // Split the path into an array of keys
  const pathArray = path.split(/[\.\[\]]/).filter((element) => element !== '')

  if (pathArray.length < 1) {
    return undefined
  }

  return pathArray.reduce((object, key) => {
    if (object && object[key] !== 'undefined') {
      return object[key]
    }

    return undefined
  }, errors)
}
