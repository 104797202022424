import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { FiSliders } from 'react-icons/fi'
import { IconContext } from 'react-icons'
import { math } from 'polished'

// Components
import { Button } from '../Button'
import { unstable_batchedUpdates } from 'react-dom'
import { useEffect } from 'react'
import { Filter, formatFilter } from '../../_components/modals/filter'

// Hooks
import { useModal } from '../../_reduxHooks/modal.hook'
import { cssBreakpoints } from '../../_constants/styles'

const searchFilterStyles = {
  padding: '11px',
  iconWidth: '15px',
}

export const S_SearchFilter = styled.div`
  .icon-wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${math(`2 * ${searchFilterStyles.padding} + ${searchFilterStyles.iconWidth}`)};
    min-width: ${math(`2 * ${searchFilterStyles.padding} + ${searchFilterStyles.iconWidth}`)};
  }

  &.align-with-table {
    margin-left: auto;
    margin-bottom: -40px;
  }

  .clear-icon {
    width: ${searchFilterStyles.iconWidth};
    height: ${searchFilterStyles.iconWidth};
    cursor: pointer;
  }

  .filter-btn {
    max-height: 41px;
    max-width: 41px;
    min-width: 41px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 13px;
    padding-right: 13px;
    margin-left: 8px;
  }

  @media screen and (min-width: ${cssBreakpoints.mobileLg}) {
    &.desktop-lg {
      .filter-btn {
        max-height: 60px;
        max-width: 60px;
        min-width: 60px;
        height: 60px;
      }
    }
  }
`

/**
 * Component for doing filtered search based on custom
 *
 * @component
 * @example
 * const onChange = (e) => {console.log(e)}
 * const columns = [{
 *      fieldName: 'invoice_number',
 *      displayName: 'Invoice No.',
 *  }]
 * return (<SearchFilter onChange={onChange} columns={columns} />)
 */

export const setFormattedFilters = (filters) => {
  return filters.map((newFilter) => {
    return formatFilter(newFilter)
  })
}

export const SearchFilter = (props) => {
  const { onChange, columns, defaultFilters = [], getSetSearchFilter } = props
  const [availableColumns, setAvailableColumns] = useState(columns)
  const [filters, setFilters] = useState([])
  const { openModal } = useModal()
  const { colors } = useTheme()

  const handleChange = (newFilters) => {
    var formattedFilters = setFormattedFilters(newFilters)
    onChange(formattedFilters)
  }

  useEffect(() => {
    getSetSearchFilter((newFilters) => {
      if (newFilters.length) {
        setFilters(newFilters)
        handleChange(newFilters)
      } else {
        setFilters(newFilters)
        handleChange(newFilters)
      }
    })
  }, [])

  useEffect(() => {
    if (!defaultFilters || !defaultFilters.length) return
    unstable_batchedUpdates(() => {
      setFilters(defaultFilters)

      const newColumns = availableColumns.filter((column) => {
        let found = false
        defaultFilters.forEach((defaultFilter) => {
          found = defaultFilter.column.fieldName === column.fieldName
        })

        return !found
      })
      setAvailableColumns(newColumns)
      handleChange(defaultFilters)
    })
  }, [])

  const handleClickEnable = () => {
    const customModalStyles = `
            .swal2-close{
                display: none;
            }
            .swal2-popup{
                border-top-width: 10px;
                border-top-style: solid;
                border-top-color: ${colors.secondary}
            }
        `
    openModal(Filter, {
      filterFields: columns,
      onChange: (newFilters) => {
        setFilters(newFilters)
        return onChange(newFilters)
      },
      currentFilters: filters,
      modal_additionalStyles: customModalStyles,
    })
  }

  return (
    <S_SearchFilter className={props.className ? props.className : ''}>
      <Button className={'filter-btn'} onClick={handleClickEnable}>
        <IconContext.Provider value={{ className: 'filter-icon' }}>
          <FiSliders />
        </IconContext.Provider>
      </Button>
    </S_SearchFilter>
  )
}
