import React from 'react'
import styled from 'styled-components'
import { MdErrorOutline } from 'react-icons/md'

export const S_ErrorGroup = styled.div`
  text-align: center;
  padding: 10px 20px;
  border-radius: 4px;

  .error-list-title {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 10px;
      width: 25px;
      height: 25px;
    }
  }

  .error-list-description {
    margin-bottom: 0;
    font-size: 15px;
  }

  .list-wrapper {
    display: flex;
    justify-content: center;
  }

  ul {
    text-align: left;
    padding-left: 20px;
    margin: 10px;
  }

  li {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.error {
    background: #fff4f4;
    border: 2px solid #ff9999;
  }
  &.warning {
    background: #fffef6;
    border: 2px solid #f6e200;
  }
`

export const ErrorGroup = ({
  title = 'Error',
  description = 'Please resolve the following errors and try again',
  errors = [],
  maxErrorsToShow = 20,
  className = 'error',
}) => {
  if (!errors || errors.length === 0) {
    return null
  }

  const amountOverMax = errors.length - maxErrorsToShow
  const showOverage = amountOverMax > 0
  const errorsToShow = errors
    .reduce((prev, current) => {
      if (Array.isArray(current)) {
        return prev.concat(current)
      } else {
        prev.push(current)
        return prev
      }
    }, [])
    .slice(0, maxErrorsToShow)

  return (
    <S_ErrorGroup className={className}>
      <h2 className="error-list-title">
        <MdErrorOutline />
        {title}
      </h2>
      <p className="error-list-description">{description}</p>
      <div className="list-wrapper">
        <ul>
          {errorsToShow.map((error, i) => (
            <li key={String(i) + error}>{error}</li>
          ))}
          {showOverage && <li>{amountOverMax} more errors...</li>}
        </ul>
      </div>
    </S_ErrorGroup>
  )
}
