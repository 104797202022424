import { tableToXlsx } from './xlsx.helper'
import { tableToPdf } from './pdfTable.helper'

export const EXPORT_TABLE_FORMATS = {
  PDF: 'pdf',
  XLSX: 'xlsx',
}

export const exportTableData = (type, data, columns, filename = undefined) => {
  switch (type) {
    case EXPORT_TABLE_FORMATS.PDF:
      tableToPdf(data, columns, filename)
      return
    case EXPORT_TABLE_FORMATS.XLSX:
    default:
      tableToXlsx(data, columns, filename)
  }
}
