import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import MaskedInput from 'react-text-mask'
import { IoMdCloseCircleOutline } from 'react-icons/io'

// Components
import ToolTip from '../Tooltip/Tooltip'

const S_SecureFieldWrapper = styled.div`
  position: relative;
`

const S_UnlockFieldIcon = styled.span`
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`
const abaRoutingNumberMask = {
  locked: ['X', 'X', 'X', '-', 'X', 'X', 'X', '-', /\d/, /\d/, /\d/],
  unlocked: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
}

const MASK = {
  ssn: {
    locked: ['X', 'X', 'X', '-', 'X', 'X', '-', /\d/, /\d/, /\d/, /\d/],
    unlocked: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  },
  business_ein: {
    locked: ['X', 'X', '-', 'X', 'X', 'X', /\d/, /\d/, /\d/, /\d/],
    unlocked: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  },
  account_number: {
    locked: ['X', 'X', 'X', 'X', 'X', 'X', /\d/, /\d/, /\d/, /\d/],
    unlocked: false,
  },
  aba_routing_wire: abaRoutingNumberMask,
  aba_routing_ach: abaRoutingNumberMask,
}

class SecureField extends React.Component {
  constructor(props) {
    super(props)
    const { value } = this.props
    this.state = {
      locked: value ? true : false,
    }
  }

  removeCharacters = (value) => {
    if (value) {
      return value.replace(/[^0-9]+/g, '')
    } else {
      return null
    }
  }

  unlockField = (fieldMask) => {
    const { handleChange, formikName } = this.props
    handleChange(formikName, ``)

    if (!fieldMask.unlocked) {
      this.input.inputElement.value = ''
    }

    this.input.inputElement.focus()
    this.setState({
      locked: false,
    })
  }

  render() {
    const { field, formikName, handleBlur, invalid } = this.props
    const fieldMask = MASK[field.type]
    return (
      <Field
        key={`${formikName} SecureField`}
        name={formikName}
        render={({ field: formikField }) => {
          return (
            <S_SecureFieldWrapper className={invalid ? 'invalid-input' : ''}>
              <MaskedInput
                mask={this.state.locked ? fieldMask.locked : fieldMask.unlocked}
                {...formikField}
                inputMode="numeric"
                onBlur={(e) => {
                  handleBlur(formikName, field.type, this.removeCharacters(e.target.value))
                }}
                ref={(el) => (this.input = el)}
                style={{
                  width: `100%`,
                  height: `50px`,
                  borderRadius: `5px`,
                  border: `1px solid ${invalid ? `#d93025` : `#ccc`}`,
                  padding: `1em`,
                  fontSize: `1rem`,
                  outline: `none`,
                  opacity: this.state.locked && `0.5`,
                  pointerEvents: this.state.locked && `none`,
                }}
              />
              {this.state.locked && (
                <ToolTip content={`Unlock & Clear Field`} key={`${formikName} SecureField ToolTip`}>
                  <S_UnlockFieldIcon>
                    <IoMdCloseCircleOutline onClick={() => this.unlockField(fieldMask)} />
                  </S_UnlockFieldIcon>
                </ToolTip>
              )}
            </S_SecureFieldWrapper>
          )
        }}
        validate={(value) => {
          let errorMessage
          if (value && !this.state.locked && value.includes(`_`)) {
            errorMessage = `Invalid Value.`
          }
          if (field.required && !value) {
            errorMessage = `Field is required.`
          }
          return errorMessage
        }}
      />
    )
  }
}

export default SecureField
