import { dealConstants } from '../_constants'
import { dealService } from '../_services'

export const dealActions = {
  createDeal,
  getDeal,
  setDeal,
  updateDeal,
  uploadDealDocument,
}

function getDeal(businessId) {
  return (dispatch) => {
    dispatch(request())
    return dealService
      .getDeal(businessId)
      .then(async (deal) => {
        dispatch(success(deal))
        // sessionStorage.setItem('dealData', JSON.stringify(deal));
        if (!deal.deal) {
          await dispatch(createDeal(businessId))
        }
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: dealConstants.GET_DEAL_REQUEST }
  }
  function success(deal) {
    return { type: dealConstants.GET_DEAL_SUCCESS, deal }
  }
  function failure(error) {
    return { type: dealConstants.GET_DEAL_FAILURE, error }
  }
}

function setDeal(deal) {
  return {
    type: dealConstants.GET_DEAL_SUCCESS,
    deal,
  }
}

function updateDeal(attr, val, dealId) {
  return (dispatch) => {
    dispatch(request(attr, val))
    return dealService
      .updateDeal(attr, val, dealId)
      .then((deal) => dispatch(success(deal)))
      .catch((error) => dispatch(failure(error)))
  }

  function request(attr, val) {
    return { type: dealConstants.UPDATE_DEAL_REQUEST, attr, val }
  }
  function success(deal) {
    return { type: dealConstants.UPDATE_DEAL_SUCCESS, deal }
  }
  function failure(error) {
    return { type: dealConstants.UPDATE_DEAL_FAILURE, error }
  }
}

function uploadDealDocument(file, userId, dealId) {
  return (dispatch) => {
    dispatch(request(file))
    return dealService
      .uploadDealDocument(file, userId, dealId)
      .then((_) => dispatch(success(file)))
      .catch((error) => dispatch(failure(error)))
  }

  function request(file) {
    return { type: dealConstants.UPLOAD_DEAL_DOCUMENT_REQUEST, file }
  }
  function success(file) {
    return { type: dealConstants.UPLOAD_DEAL_DOCUMENT_SUCCESS, file }
  }
  function failure(error) {
    return { type: dealConstants.UPLOAD_DEAL_DOCUMENT_FAILURE, error }
  }
}

function createDeal(businessId) {
  return (dispatch) => {
    dispatch(request())
    return dealService
      .createDeal(businessId)
      .then((deal) => dispatch(success(deal)))
      .catch((error) => dispatch(failure(error)))
  }
  function request() {
    return { type: dealConstants.CREATE_DEAL_REQUEST }
  }
  function success(deal) {
    return { type: dealConstants.CREATE_DEAL_SUCCESS, deal }
  }
  function failure(error) {
    return { type: dealConstants.CREATE_DEAL_FAILURE, error }
  }
}
