import { useQuery } from '@tanstack/react-query';
import { generateCancelToken } from 'legacy/_services/axios_service';
import { request } from 'xen/api';
import { formatCase } from 'xen/helpers';
import { getTenantFromUrl } from 'xen/helpers/url';
const currentTenant = getTenantFromUrl();
export const useGetConfiguration = (tenantToken = currentTenant) => {
    return useQuery({
        queryKey: ['tenants', tenantToken, 'configuration'],
        queryFn: async () => {
            const response = await request({
                url: `/v2/configuration`,
                method: 'GET',
                cancelToken: generateCancelToken().token,
                headers: { 'tenant-token': tenantToken },
            });
            return formatCase.objectKeys('camel', response);
        },
    });
};
