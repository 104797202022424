import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformDocumentResponse, transformUploadDocumentToFormData } from 'xen/api/use-document';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
async function uploadPaymentDocument(params) {
    try {
        const formData = transformUploadDocumentToFormData(params.file);
        const response = await request({
            method: 'POST',
            url: `/documents/upload_collections_check_document/${params.id}`,
            data: formData,
        });
        const data = transformDocumentResponse(response);
        return data;
    }
    catch (_error) {
        throw Error('Error uploading document');
    }
}
export const useUploadPaymentDocument = () => {
    return useMutation({
        mutationFn: (params) => uploadPaymentDocument(params),
        onError: () => {
            console.error('Error uploading document');
            notifications.error('Error uploading documents');
        },
        onSuccess: (data) => {
            notifications.success(`${data.documentName} has been uploaded`);
            queryClient.invalidateQueries({ queryKey: ['payments'] });
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements'] });
        },
    });
};
