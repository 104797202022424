import isNil from 'lodash/isNil'

import { store } from '../_helpers/store'

const HOURS_IN_A_DAY = 24
const SECONDS_IN_AN_HOUR = 3600
const SECONDS_IN_A_DAY = HOURS_IN_A_DAY * SECONDS_IN_AN_HOUR
const MILLISECONDS_IN_A_DAY = SECONDS_IN_A_DAY * 1000

// TODO: Remove mock/testing data from implementation - should live only in tests or test harnesses
const mockDefault = { testingEnv: false, mockStore: {} }

// Returns a date string in the format 'YYYY-MM-DD',
// The same format type used by HTML5 date inputs
export const getDateInputFormat = (date) => {
  // Check type
  const d = typeof date === 'object' ? date : new Date(date)
  if (typeof date !== 'object') {
    //Add a day. new Date("2021-05-21") results in date 5/20/2021
    d.setDate(d.getDate() + 1)
  }
  // Parse values
  const year = d.getFullYear()
  let month = d.getMonth() + 1
  let day = d.getDate()
  month = (month < 10 && '0') + month
  day = (day < 10 && '0') + day
  return `${year}-${month}-${day}`
}
export const dateFromInputFormat = (dateString) => {
  let dateParts = dateString.split('-')
  let year = Number(dateParts[0])
  let month = Number(dateParts[1] - 1)
  let day = Number(dateParts[2])
  return new Date(year, month, day)
}

export const currentDateTimeInUTC = () => {
  const localDate = new Date()
  return new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate(),
    localDate.getUTCHours(),
    localDate.getUTCMinutes(),
    localDate.getUTCSeconds(),
    localDate.getUTCMilliseconds()
  )
}

export const truncatedUTCNow = () => {
  const currentDateInTenantTime = UTCDateToSystemString(currentDateTimeInUTC())
  const currentDateAtZero = currentDateInTenantTime.split('T')[0]
  return dateToUTCString(currentDateAtZero)
}

export const dateRangeFilterFormatter = {
  startDate: (dateString) => {
    let dateTime = new Date(dateString)
    let { timezone } = store.getState()
    dateTime.setUTCSeconds(-timezone.gmtOffset)
    return dateTime.toISOString()
  },
  endDate: (dateString) => {
    let dateTime = new Date(dateString)
    let { timezone } = store.getState()
    dateTime.setUTCSeconds(-timezone.gmtOffset + SECONDS_IN_A_DAY - 1)
    return dateTime.toISOString()
  },
}

export const absoluteCurrentDateEndOfTheDayinUTC = (dateInUTC) => {
  if (isNil(dateInUTC)) {
    return null
  }

  let dateToFormat = dateInUTC
  if (dateInUTC instanceof Date) {
    dateToFormat = dateInUTC.toISOString().split('T')[0] + 'T23:59:59.999Z'
  } else if (typeof dateInUTC === 'string') {
    dateToFormat = dateInUTC.split('T')[0] + 'T23:59:59.999Z'
  }

  return dateToFormat
}

export const absoluteCurrentDateinUTC = (dateInUTC) => {
  if (isNil(dateInUTC)) {
    return null
  }

  let dateToFormat = dateInUTC
  if (dateInUTC instanceof Date) {
    dateToFormat = dateInUTC.toISOString().split('T')[0] + 'T00:00:00.000Z'
  } else if (typeof dateInUTC === 'string') {
    dateToFormat = dateInUTC.split('T')[0] + 'T00:00:00.000Z'
  }

  return dateToFormat
}

// From Server to Browser
export const dateToUTCString = (date, mockData = mockDefault) => {
  if (isNil(date)) {
    return null
  }
  let timezone = getTimeZone(mockData)

  let dateToFormat = date
  if (dateToFormat instanceof Date) {
    date = dateToFormat.toISOString()
  }

  let [dateParts, timeParts] = date.split('T')
  let [year, month, day] = dateParts.split('-').map(strToNum)

  dateToFormat = new Date(year, month - 1, day)

  if (timeParts) {
    timeParts = timeParts.replace('Z', '')
    let [hour, minute, second] = timeParts.split(':')
    let [hours, minutes] = [hour, minute].map(strToNum)
    let [seconds, milliseconds = 0] = second.split('.').map(strToNum)
    dateToFormat.setHours(hours, minutes, seconds, milliseconds)
  }
  dateToFormat.setSeconds(dateToFormat.getSeconds() + timezone.gmtOffset * -1)
  return (
    `${lessThanTen(dateToFormat.getFullYear())}-` +
    `${lessThanTen(dateToFormat.getMonth() + 1)}-` +
    `${lessThanTen(dateToFormat.getDate())}T` +
    `${lessThanTen(dateToFormat.getHours())}:` +
    `${lessThanTen(dateToFormat.getMinutes())}:` +
    `${lessThanTen(dateToFormat.getSeconds())}.${dateToFormat.getMilliseconds()}Z`
  )
}

// From Browser to Server
export const UTCDateToSystemString = (date, mockData = mockDefault, returnDateObj = false, withoutTimeZone = false) => {
  if (!date) {
    return null
  }
  let timezone = getTimeZone(mockData)

  let dateToFormat = date
  if (dateToFormat instanceof Date) {
    //Compensate for toISOString converting to UTC timezone
    dateToFormat.setMinutes(dateToFormat.getMinutes() - dateToFormat.getTimezoneOffset())
    date = dateToFormat.toISOString()
  }

  let [dateParts, timeParts] = date.split('T')
  let [year, month, day] = dateParts.split('-').map(strToNum)

  dateToFormat = new Date(year, month - 1, day)
  if (timeParts) {
    timeParts = timeParts.replace('Z', '')
    timeParts = timeParts.replace('+00:00', '')
    let [hour, minute, second] = timeParts.split(':')
    let [hours, minutes] = [hour, minute].map(strToNum)
    let [seconds, milliseconds] = second.split('.').map(strToNum)
    dateToFormat.setHours(hours, minutes, seconds, milliseconds)
  }
  const seconds = withoutTimeZone ? dateToFormat.getSeconds() : dateToFormat.getSeconds() + timezone.gmtOffset
  dateToFormat.setSeconds(seconds)
  if (returnDateObj) {
    return dateToFormat
  }
  return (
    `${lessThanTen(dateToFormat.getFullYear())}-` +
    `${lessThanTen(dateToFormat.getMonth() + 1)}-` +
    `${lessThanTen(dateToFormat.getDate())}T` +
    `${lessThanTen(dateToFormat.getHours())}:` +
    `${lessThanTen(dateToFormat.getMinutes())}:` +
    `${lessThanTen(dateToFormat.getSeconds())}.${dateToFormat.getMilliseconds()}Z`
  )
}

export function convertDatesFromDateTime(object, dateFields) {
  dateFields.forEach((dateType) => {
    if (object[dateType]) {
      object[dateType] = getDateFromDatetime('' + object[dateType])
    }
  })
  return object
}

export function getDateFromDatetime(datetime) {
  if (!datetime || !datetime?.split('T')) {
    return datetime
  }
  return datetime.split('T')[0]
}

export function getDate(UTCStringDate) {
  if (isNil(UTCStringDate)) {
    return null
  }
  return UTCStringDate
}

function lessThanTen(value) {
  if (value < 10) {
    return `0${value}`
  }
  return value
}

function strToNum(str) {
  return Number(str)
}

function getTimeZone({ testingEnv, mockStore }) {
  let timezone = store.getState().timezone
  if (testingEnv) {
    timezone = mockStore.getState().timezone
  }

  return timezone
}

export const systemNow = () => {
  let utc = currentDateTimeInUTC()
  let systemDate = UTCDateToSystemString(utc, mockDefault, true)
  return systemDate
}

// Returns a current date minus *minAge* years. Used to set the max/min date
export const getAllowedDob = (minAge) => {
  const now = new Date()
  const year = now.getFullYear() - minAge
  let month = now.getMonth() + 1
  let day = now.getDate()

  month = (month < 10 && '0') + month
  day = (day < 10 && '0') + day

  return `${year}-${month}-${day}`
}

/**
 * Converts a string to a date object.
 * If the string includes a timestamp, the time is set to midnight
 * of the given date in order to parse it as if it's in the local timezone.
 * @param {String} dateString
 * @returns
 */
const stringToDate = (dateString) => {
  const matchRegex = /^(\d\d\d\d)-(\d\d)-(\d\d)/
  const match = dateString.split('T')[0].match(matchRegex)

  if (match === null) return new Date(dateString)

  // Set the time to midnight of the given date, so that it's parsed as if it's in the local timezone.
  const [_originalString, year, month, day] = match
  return new Date(`${year}-${month}-${day}T00:00:00`)
}

/**
 * Returns a date in the format MM/DD/YYYY
 * @param {Date|String} dateOrString
 * @returns {string}
 */
export const dateToUTCStringSlashes = (dateOrString) => {
  if (!dateOrString) {
    return ''
  }

  let date = typeof dateOrString === 'string' ? stringToDate(dateOrString) : dateOrString

  const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
  return date.toLocaleDateString(undefined, options)
}

/**
 * Returns a date in the format MM/DD/YYYY
 * @param {Date} date
 * @returns {string}
 */
export const dateToStringSlashes = (dateString) => {
  if (!dateString) {
    return ''
  }
  const dateStr = dateString.split('T')[0]
  const dateArray = dateStr.split('-')
  return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`
}

/**
 * Returns a time in the format AM/PM
 * @param {Date} date
 * @returns {string}
 */
export const timeUTCAmPm = (date) => {
  let hours = date.getUTCHours()
  let minutes = date.getUTCMinutes()
  let ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12 // change '0' hour to 12
  minutes = minutes < 10 ? `0${minutes}` : minutes
  return hours + ':' + minutes + ampm
}

/**
 * Returns a date in the format MM/DD/YYYY + AM/PM
 * @param {Date} date
 * @returns {string}
 */
export const dateUTCAmPm = (date) => {
  if (!date) {
    return ''
  }
  const dateObject = typeof date === 'object' ? date : new Date(String(date))
  const UTCDate = dateToUTCStringSlashes(dateObject)

  let hours = dateObject.getUTCHours()
  let minutes = dateObject.getUTCMinutes()
  let ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12 // change '0' hour to 12
  minutes = minutes < 10 ? `0${minutes}` : minutes
  return UTCDate + ' ' + hours + ':' + minutes + ampm
}

/**
 * Returns difference in days between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @param {Boolean} isAbs
 * @returns {number}
 */
export const getDiffDays = (date1, date2, isAbs = true) => {
  const diffTime = date1.getTime() - date2.getTime()
  const diffDays = Math.ceil(diffTime / MILLISECONDS_IN_A_DAY)

  return isAbs ? Math.abs(diffDays) : diffDays
}

export const shortMonth = (monthNum) => {
  let monthNumber = 1
  if (typeof monthNum == 'number' || !isNaN(Number(monthNum))) {
    monthNumber = monthNum
  } else {
    monthNumber = monthNum?.monthNum || null
  }

  const months = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  }
  return monthNumber ? months[monthNumber] : ''
}

/**
 * Returns date formatted like "Oct 2022"
 * @param {Date} date
 * @returns {string}
 */
export const getShortMonthAndYearFromDate = (date, showDay = false) => {
  if (!date || (!(date instanceof Date) && typeof date !== 'string')) {
    return ''
  }
  const dateObject = typeof date === 'object' ? date : new Date(String(date))
  if (isNaN(dateObject)) {
    return ''
  }
  const year = dateObject.getFullYear()
  const monthIndex = dateObject.getMonth() + 1
  const month = shortMonth(monthIndex)
  const day = dateObject.getDate()

  return `${month} ${showDay ? `${day}, ` : ''}${year}`
}

/**
 * Returns date formatted like {:day, :month, :year}
 * @param {Date} date
 * @returns {object}
 */

export const getDateObject = (date) => {
  if (!date || (typeof date !== 'object' && typeof date !== 'string')) {
    return {}
  }
  const dateObject = typeof date === 'object' ? date : new Date(String(date))
  if (isNaN(dateObject)) {
    return {}
  }
  const year = dateObject.getFullYear()
  const monthIndex = dateObject.getMonth() + 1
  const day = dateObject.getDate()

  return {
    year: year,
    month: monthIndex,
    day: day,
  }
}

export const longMonth = ({ monthNum, shortMonth }) => {
  const numberMonths = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  }
  const shortMonths = {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
  }
  if (monthNum) {
    return numberMonths[monthNum]
  } else if (shortMonth) {
    return shortMonths[shortMonth]
  }
}

/**
 * Converts an ISO date string to a given timezone.
 * @param {string} datetimeString - ISO date string
 *
 * @return {string} - ISO date string in the local timezone.
 **/
export const convertToLocalTZ = (datetimeString) => {
  if (!datetimeString) {
    console.error('Invalid date')
    return datetimeString
  }

  // If the datetime string doesn't have a timezone, add UTC offset
  const dtStringWithTz = datetimeString.length < 24 ? datetimeString + '+00:00' : datetimeString

  const datetime = new Date(dtStringWithTz)

  if (datetime == 'Invalid Date') {
    console.error('Invalid date')
    return datetimeString
  }

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }

  let formatter = null

  try {
    formatter = new Intl.DateTimeFormat('en-US', options)
  } catch (e) {
    console.error('Unknown error converting timezone')
    return datetimeString
  }

  if (!formatter) {
    console.error('Unknown error converting timezone')
    return datetimeString
  }

  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minute },
    ,
    { value: second },
  ] = formatter.formatToParts(datetime)

  const milliseconds = datetime.getUTCMilliseconds().toString().padStart(3, '0')
  const offsetString = getOffsetString(datetime)

  return `${year}-${month}-${day}T${hour}:${minute}:${second}.${milliseconds}${offsetString}`
}

const getOffsetString = (datetime) => {
  const offset = datetime.getTimezoneOffset()
  const sign = offset > 0 ? '-' : '+'
  const hours = Math.floor(Math.abs(offset) / 60)
    .toString()
    .padStart(2, '0')
  const minutes = (Math.abs(offset) % 60).toString().padStart(2, '0')

  return `${sign}${hours}:${minutes}`
}
