import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import { rem, useDisclosure, useMediaQuery, useXenTheme } from 'xen-ui';
const HEIGHT = rem(52);
const WIDTH = {
    default: rem(200),
    iconsOnly: rem(42),
};
const AppShellNavBarContext = createContext({
    isMobile: false,
    isNavCollapsed: false,
    isNavHidden: false,
    navHeight: HEIGHT,
    navWidth: WIDTH.default,
    toggleNavVisibility: () => { },
    toggleNavWidth: () => { },
});
export const useAppShellNavBarContext = () => {
    const context = useContext(AppShellNavBarContext);
    return context;
};
export const AppShellProvider = ({ children }) => {
    const theme = useXenTheme();
    const isMobileXS = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const [isNavVisible, { open: showNavBar, toggle: toggleNavVisibility }] = useDisclosure(false);
    const [navWidth, setNavWidth] = useState(WIDTH.default);
    const navHeight = HEIGHT;
    const toggleNavWidth = () => {
        setNavWidth((navWidth) => (navWidth === WIDTH.default ? WIDTH.iconsOnly : WIDTH.default));
    };
    const isNavCollapsed = navWidth === WIDTH.iconsOnly;
    const isNavHidden = !isNavVisible;
    const isMobile = !!isMobileXS;
    useEffect(() => {
        // Closed by default on mobile, open everywhere else
        isMobileXS !== undefined && !isMobileXS && showNavBar();
    }, [isMobileXS]);
    return (_jsx(AppShellNavBarContext.Provider, { value: {
            isMobile,
            isNavCollapsed,
            isNavHidden,
            navHeight,
            navWidth,
            toggleNavVisibility,
            toggleNavWidth,
        }, children: _jsx(_Fragment, { children: children }) }));
};
