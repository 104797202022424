import { authenticationConstants } from '../_constants'

export function authentication(state = {}, action) {
  switch (action.type) {
    case authenticationConstants.SIGN_UP_REQUEST:
      return {
        ...state,
        loading: true,
        credentials: action.credentials,
      }
    case authenticationConstants.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: true,
        sessionToken: action.sessionToken,
      }
    case authenticationConstants.SIGN_UP_COMPLETE:
      return {
        ...state,
        loading: false,
        loggedIn: true,
      }
    case authenticationConstants.SIGN_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case authenticationConstants.SIGN_IN_REQUEST:
      return {
        loading: true,
      }
    case authenticationConstants.SIGN_IN_SUCCESS:
      return {
        loading: true,
        sessionToken: action.sessionToken,
        accessToken: action.accessToken,
      }
    case authenticationConstants.SIGN_IN_COMPLETE:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        isIdleTimeout: false,
        idleTimeoutMessage: '',
      }
    case authenticationConstants.SIGN_IN_FAILURE:
      return {
        loading: false,
        error: action.error,
      }

    case authenticationConstants.SIGN_OUT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case authenticationConstants.SIGN_OUT_FAILURE:
      return {
        loading: false,
        error: action.error,
      }
    case authenticationConstants.SIGN_OUT_SUCCESS:
      return {
        loading: false,
        isIdleTimeout: action.isIdleTimeout,
        idleTimeoutMessage: action.idleTimeoutMessage,
      }

    case authenticationConstants.FORGOT_PASSWORD_REQUEST:
      return {
        loading: true,
        credentials: action.credentials,
      }
    case authenticationConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        loading: false,
      }
    case authenticationConstants.FORGOT_PASSWORD_FAILURE:
      return {
        loading: false,
        error: action.error,
      }

    case authenticationConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        credentials: action.credentials,
      }
    case authenticationConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case authenticationConstants.RESET_PASSWORD_FAILURE:
      return {
        loading: false,
        error: action.error,
      }

    case authenticationConstants.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      }
    case authenticationConstants.STOP_LOADING:
      return {
        ...state,
        loading: false,
      }
    case authenticationConstants.VIA_PROSPECT_LINK:
      return {
        ...state,
        via_prospect_link: true,
      }
    case authenticationConstants.GET_SIGN_UP_FORM_AND_MARKETING_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case authenticationConstants.GET_SIGN_UP_FORM_AND_MARKETING_SUCCESS:
      return {
        ...state,
        loading: false,
        signUpForm: { title: action.signUpAndMarketingForm.title, fields: action.signUpAndMarketingForm.fields },
        marketing: action.signUpAndMarketingForm.marketing_components,
      }
    case authenticationConstants.GET_SIGN_UP_FORM_AND_MARKETING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}
