import apiKeys from 'apiKeys'

const PUBLIC_KEY = apiKeys.recaptcha

export const getRecaptchaToken = (disableRecaptcha) => {
  if (!PUBLIC_KEY || !window.grecaptcha || disableRecaptcha) {
    return false
  }

  return window.grecaptcha.execute(PUBLIC_KEY, { action: 'submit' }).then((token) => token)
}

export const loadScriptByURL = ({ id, url, callback = false }) => {
  const isScriptExist = document.getElementById(id)

  if (!isScriptExist) {
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    script.id = id
    script.onload = function () {
      if (callback) callback()
    }
    document.body.appendChild(script)
  }

  if (isScriptExist && callback) callback()
}

export const addRecaptchaScript = (callback = () => {}) => {
  loadScriptByURL({
    id: 'recaptcha-client',
    url: `https://www.google.com/recaptcha/api.js?render=${PUBLIC_KEY}`,
    callback: () => callback(),
  })
}
