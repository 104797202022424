import request from './axios_service'
import { formHeader, formatDebtor, debtorSummaryToSystemTime } from '../_helpers'
import { noaDatesToSystemTime, noaDatesToUTC } from '../_helpers/noa'
import { documentDatesToSystemTime } from '../_helpers/documents'
import { contactDatesToSystemTime } from '../_helpers/contacts'

//Type definitions for JSDoc
/**
 *
 * @typedef {Object} Document
 * @property {Number} id
 * @property {Date} created_at
 * @property {String} data_source
 * @property {Boolean} deleted
 * @property {String} document_description
 * @property {Date} document_issue_date
 * @property {String} document_name
 * @property {String} document_number
 * @property {String} document_type
 * @property {Date} updated_at
 *
 */

/**
 *
 * @typedef {Object} Notification
 * @property {Number} id
 * @property {Number} business_id
 * @property {Number} tenant_id
 * @property {Date} created_at
 * @property {Date} updated_at
 * @property {Number} debtor_id
 * @property {Date} received_date
 * @property {Date} sent_date
 * @property {Date} stopped_date
 * @property {Date} waived_date
 *
 */

/**
 *
 * @typedef {Object} Note
 * @property {String} body
 * @property {String} category
 * @property {Date} created_at
 * @property {Object} created_by
 * @property {Number} created_by_id
 * @property {Number} id
 * @property {Object} modified_by
 * @property {Number} modified_by_id
 * @property {Object} owner
 * @property {Number} owner_id
 * @property {Date} updated_at
 * @typedef {Object} Contact
 * @typedef {Object} Contacts
 *
 */

/**
 *
 * @typedef {Object} DebtorSummary
 * @property {Array} transactions_by_type
 * @property {Date} last_payment_date
 * @property {Object} top_business
 *
 */

export const searchDebtor = (searchTerm, addTypeTenant) => {
  return request({
    url: `/debtors/?debtor_name=${searchTerm}${addTypeTenant ? '&type=tenant' : ''}`,
    method: 'GET',
  })
}

export const getDebtor = (id, business_id) => {
  return request({
    url: `/debtors/${business_id ? `${id}?business_id=${business_id}` : id}`,
    method: 'GET',
  })
}

// It seems that the backend needs the debtor params and object improve if necesary
export const createDebtor = (debtor) => {
  const formattedDebtor = formatDebtor(debtor)
  return request({
    url: `/debtors`,
    method: 'POST',
    data: { ...formattedDebtor, debtor: formattedDebtor },
  })
}

export const createChildDebtor = (id, debtor) => {
  const formattedDebtor = formatDebtor(debtor)
  return request({
    url: `/debtors/${id}/child_debtors`,
    method: 'POST',
    data: { ...formattedDebtor, child_debtor: formattedDebtor },
  })
}

export const updateDebtor = (id, debtor) => {
  const formattedDebtor = formatDebtor(debtor)
  return request({
    url: `/debtors/${id}`,
    method: 'PUT',
    data: { ...formattedDebtor, debtor: formattedDebtor },
  })
}

/** Get all notifications
 * @param {String} searchTerm
 * @return {Notification[]}
 */
export const getAllNotices = (searchTerm) => {
  return request({
    url: `/notifications/?${searchTerm}`,
    method: 'GET',
  }).then((noas) => noas.map(noaDatesToSystemTime))
}

/** Get all notifications count
 * @param {String} searchTerm
 * @return {{count:Number}}
 */
export const getNoticesCount = (searchTerm) => {
  return request({
    url: `/notifications/count/?${searchTerm}`,
    method: 'GET',
  })
}

/**
 * Get a single notification
 * @param {Number} notificationId
 * @returns {Notification}
 */
export const getNotice = (notificationId) => {
  return request({
    url: `/notifications/${notificationId}`,
    method: 'GET',
  }).then(noaDatesToSystemTime)
}

/**
 * Get documents of a notification
 * @param {Number} notificationId
 * @param {String} searchTerm
 * @return {{count:Number, documents:Documents[]}}
 */
export const getNoticeDocuments = (notificationId, searchParams) => {
  return request({
    url: `/notifications/${notificationId}/get_documents/?${searchParams}`,
    method: 'GET',
  }).then((docs) => {
    docs.documents = docs.documents.map(documentDatesToSystemTime)
    return docs
  })
}

/**
 * Get notes of a notification
 * @param {Number} notificationId
 * @return {{count:Number, notes:Notes[]}}
 */
export const getNoticeNotes = (notificationId) => {
  return request({
    url: `/notifications/${notificationId}/get_notes`,
    method: 'GET',
  }).then((notes) => {
    notes.notes = notes.notes.map((note) => noaDatesToSystemTime(note))
    return notes
  })
}

/**
 * Add a note to a notification
 * @param {Number} notificationId
 * @param {Object} data
 * @returns {Note}
 */
export const addNoticeNote = (notificationId, data) => {
  return request({
    url: `/notifications/${notificationId}/add_note`,
    method: 'POST',
    data: data,
  })
}

/**
 * Update a notification
 * @param {Number} notificationId
 * @param {Object} data
 * @returns {Notification}
 */
export const updateNotice = (notificationId, data) => {
  return request({
    url: `/notifications/${notificationId}`,
    method: 'PUT',
    data: {
      notification: noaDatesToUTC(data),
    },
  }).then(noaDatesToSystemTime)
}

/**
 * Add a document to a notification
 * @param {Number} notificationId
 * @param {File} file file to upload
 * @param {String} name
 * @return {Document}
 */
export function uploadNoticeDocument(file, name, notificationId) {
  const data = new FormData()
  data.append(`filedata`, file)
  data.append(`filename`, file.name)
  data.append(`document_type`, name)

  return request({
    url: `/documents/upload_notification_document/${notificationId}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

/**
 * Get all contacts for debtor
 * @param {Number} debtorId
 * @returns {Contacts}
 */
export const getContacts = (debtorId) => {
  return request({
    url: `/debtors/${debtorId}/contacts`,
    method: 'GET',
  }).then((contacts) => contacts.map(contactDatesToSystemTime))
}

/**
 * Get a contact
 * @param {Number} debtorId
 * @param {Number} clientId
 * @returns {Contact}
 */
export const getContact = (debtorId, clientId = null, params = null) => {
  return request({
    url: `/debtors/${debtorId}/contacts/${clientId ? clientId : ''}${params ? params : ''}`,
    method: 'GET',
  })
}

/**
 * Update a contact
 * @param {Number} debtorId
 * @param {Number} clientId
 * @param {Object} data
 * @returns {Contacts}
 */
export const updateContact = (debtorId, clientId, data) => {
  return request({
    url: `/debtors/${debtorId}/contacts/${clientId}`,
    method: 'PATCH',
    data,
  }).then(contactDatesToSystemTime)
}

/**
 * Create a contact
 * @param {Number} debtorId
 * @param {Object} data
 * @returns {Contacts}
 */
export const createContact = (debtorId, data) => {
  return request({
    url: `/debtors/${debtorId}/contacts`,
    method: 'POST',
    data,
  }).then(contactDatesToSystemTime)
}

/**
 * Get debtor summary
 * @param {Number} debtorId
 * @param {Object} data
 * @returns {DebtorSummary}
 */
export const getDebtorSummary = (debtorId, timeframe = 30) => {
  return request({
    url: `/debtors/${debtorId}/summary${timeframe ? `?timeframe=${timeframe}` : ''}`,
    method: 'GET',
  }).then(debtorSummaryToSystemTime)
}

export const getDebtorBusinesses = ({ debtor_id, params }) => {
  return request({
    url: `/factoring/debtors/${debtor_id}/businesses?${params}`,
    method: 'GET',
  })
}

export const notifyBusinessDebtors = (business_id) => {
  return request({
    url: `/factoring/businesses/${business_id}/debtors/notify`,
    method: 'POST',
  })
}
