import { jsx as _jsx } from "react/jsx-runtime";
import { MIME_TYPES as MANTINE_MIME_TYPES, Dropzone as MantineDropzone } from '@mantine/dropzone';
// Mantine's MIME_TYPES do not include .ico
// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
export const MIME_TYPES = { ...MANTINE_MIME_TYPES, ico: 'image/x-icon' };
export const Dropzone = ({ ...props }) => {
    return _jsx(MantineDropzone, { ...props });
};
const Accept = (props) => {
    return _jsx(MantineDropzone.Accept, { ...props });
};
Dropzone.Accept = Accept;
const Reject = (props) => {
    return _jsx(MantineDropzone.Reject, { ...props });
};
Dropzone.Reject = Reject;
const Idle = (props) => {
    return _jsx(MantineDropzone.Idle, { ...props });
};
Dropzone.Idle = Idle;
