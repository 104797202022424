import React, { useEffect, useState } from 'react'

// Components
import { FormField } from '../../../_components/Forms/FormField'
import { LoaderButton } from '../../../_components/LoaderButton'
import Tabs from '../../../_components/Tabs'
import { SearchableOverview } from '../../../_components/SearchableOverview'
import { useModal } from '../../../_reduxHooks/modal.hook'
import { NotificationGroupModal } from './NotificationGroupModal'
import { DeleteNotificationGroupModal } from './DeleteNotificationGroupModal'
import Table from '../../../_components/Table'
import { Button } from '../../../_components/Button'
import { S_UserNotificationSettings } from './UserNotificationSettings.styles'

// Services
import { userService } from '../../../_services'
import { getNotificationGroups } from '../../../_services/lender.service'
import { isAdminUser } from '../../../_helpers/user-utils'
import { useUser } from '../../../_reduxHooks/user.hook'

import { isFeatureEnabled } from '../../../_helpers/flags'
import { useFlags } from '../../../_reduxHooks/flags.hook'

export const UserNotificationSettings = ({ user_id }) => {
  const [options, setOptions] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const { user } = useUser()
  const isAdmin = isAdminUser(user)
  const { featureFlags } = useFlags()
  const isUserNotificationsEnabled = isFeatureEnabled('user-notifications', featureFlags)

  const onSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    const data = {}
    options.forEach((option) => {
      data[option.id] = { alert: option.alert, email: option.email }
    })

    userService.updateUserNotificationSettings(user_id, data).then((res) => {
      setSubmitting(false)
    })
  }

  const onChange = (value, id) => {
    setOptions(
      options.map((option) => {
        if (option.id === id.split('-')[0]) {
          return { ...option, [id.split('-')[1]]: value }
        }
        return option
      })
    )
  }

  const { openModal, closeModal } = useModal()

  const columns = [
    {
      accessor: 'name',
      headingText: 'Name',
      disableSortBy: false,
    },
    {
      accessor: 'description',
      headingText: 'Description',
      disableSortBy: true,
    },
    {
      accessor: 'notifications',
      headingText: 'Notifications',
      disableSortBy: true,
      Cell: (props) => {
        const notifications = props.row.original.notifications
        return (
          <ul style={{ padding: '0 0 0 1em' }}>
            {notifications.map((notification, index) => {
              return <li key={index}>{notification.name}</li>
            })}
          </ul>
        )
      },
    },
    {
      accessor: 'users',
      headingText: 'Users',
      disableSortBy: true,
      Cell: (props) => {
        const users = props.row.original.users
        return (
          <ul style={{ padding: '0 0 0 1em' }}>
            {users.map((user, index) => {
              return <li key={index}>{user.name}</li>
            })}
          </ul>
        )
      },
    },
    {
      accessor: `id`,
      headingText: '',
      disableSortBy: true,
      maxWidth: 120,
      Cell: (props) => {
        const notificationGroup = props.row.original

        return (
          <div className={`align-right`}>
            <Button
              className="compact"
              onClick={() => {
                openModal(NotificationGroupModal, {
                  notificationGroup: notificationGroup,
                  closeModal: closeModal,
                })
              }}
              style={{ marginRight: '5px' }}
              text="Edit"
            />
            <Button
              className={`compact destroy`}
              onClick={() => {
                openModal(DeleteNotificationGroupModal, {
                  notificationGroup: notificationGroup,
                  closeModal: closeModal,
                })
              }}
            >
              Delete
            </Button>
          </div>
        )
      },
    },
  ]

  const notificationGroups = (searchParams) => {
    return getNotificationGroups(searchParams).then((response) => {
      return {
        results: response.data,
        total_results: response.headers['total-count'],
      }
    })
  }

  useEffect(() => {
    userService.getUserNotificationSettings(user_id).then((res) => {
      const tempOptions = []
      Object.keys(res).forEach((key) => {
        // convert key into  label
        // funding_request_funded in Funding Request Funded
        const label = key
          .split('_')
          .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1)
          })
          .join(' ')

        tempOptions.push({ label: label, alert: res[key].alert, email: res[key].email, id: key })
      })

      setOptions(tempOptions)
    })
  }, [])

  if (!options.length) return null

  return (
    <S_UserNotificationSettings>
      <Tabs bordered={true} smooth={true}>
        {isAdmin && isUserNotificationsEnabled && (
          <div label="Groups">
            <div className="groups_container_tab">
              <div className="add_groups_container">
                <Button
                  onClick={() => openModal(NotificationGroupModal, { closeModal: closeModal })}
                  text="Add Group"
                />
              </div>
              <SearchableOverview
                searchPlaceholder="Search Funding Requests"
                showSearchBar={true}
                showSearchFilter={true}
                views={[
                  {
                    title: 'Notification Groups',
                    endpointCall: async (searchParams) => notificationGroups(searchParams),
                    requestMethod: 'GET',
                    columns: columns,
                    pageSizes: [25, 50, 100],
                    pageSize: 25,
                    defaultSort: [{ id: 'name', desc: true }],
                    Component: ({ results, sort, handleSortChange, pageSize, columns }) => {
                      return (
                        <Table
                          className="with-border"
                          columns={columns}
                          data={results}
                          onSortedChange={handleSortChange}
                          pageSize={pageSize}
                          sorted={sort}
                        />
                      )
                    },
                  },
                ]}
              />
            </div>
          </div>
        )}

        <div label="My Notifications">
          <div style={{ marginTop: '20px' }}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>email</th>
                  <th>alert</th>
                </tr>
              </thead>
              <tbody>
                {options.map((option) => {
                  return (
                    <tr className="border-bottom" key={option.id}>
                      <td>{option.label}</td>
                      <td>
                        <FormField
                          defaultValue={option.email}
                          entity={option.email}
                          id={`${option.id}-email`}
                          onChange={onChange}
                          type="checkbox"
                        />
                      </td>
                      <td>
                        <FormField
                          defaultValue={option.alert}
                          entity={option.alert}
                          id={`${option.id}-alert`}
                          onChange={onChange}
                          type="checkbox"
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <div className="submit">
              <LoaderButton disabled={submitting} isLoading={submitting} onClick={onSubmit} text="Save" />
            </div>
          </div>
        </div>
      </Tabs>
    </S_UserNotificationSettings>
  )
}
