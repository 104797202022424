import { jsx as _jsx } from "react/jsx-runtime";
import { Button, modals } from 'xen-ui';
import { useDeleteInvoice } from 'xen/api/use-invoice/use-delete-invoice';
export const DeleteInvoiceButton = ({ children = 'Delete Invoice', invoice, ...props }) => {
    const { mutate: deleteInvoice, isPending: isInvoiceDeletePending, isError: isInvoiceDeleteError } = useDeleteInvoice();
    const handleDeleteInvoice = () => {
        deleteInvoice({ id: invoice.id });
        if (isInvoiceDeleteError)
            console.error('Error deleting invoice');
    };
    const confirmDeleteInvoice = () => {
        modals.openConfirmModal({
            title: 'Confirm',
            children: 'Are you sure you want to delete this invoice and its verifications? This action cannot be undone.',
            confirmProps: { color: 'red' },
            labels: { cancel: 'Cancel', confirm: 'Delete Invoice' },
            onConfirm: handleDeleteInvoice,
        });
    };
    return (_jsx(Button, { disabled: invoice.state !== 'Incomplete', loading: isInvoiceDeletePending, onClick: confirmDeleteInvoice, ...props, children: children }));
};
