import { alertConstants } from '../_constants'

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SHOW_ALERT:
      action.alert.is_open = true
      return action.alert
    case alertConstants.HIDE_ALERT:
      return {}
    default:
      return state
  }
}
