import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
async function createUnprocessState({ id, date, batchNumber }) {
    try {
        await request({
            method: 'POST',
            url: `/reset/payment_batches`,
            data: {
                date,
                ids: id,
            },
        });
        // request returns null so we return id to use to invalidate the cache
        return id;
    }
    catch (_error) {
        throw Error(`Could not unprocess invoice settlement ${batchNumber}`);
    }
}
export const useCreateUnprocessState = () => {
    return useMutation({
        mutationFn: ({ id, date, batchNumber }) => createUnprocessState({ id, date, batchNumber }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (id, { batchNumber }) => {
            notifications.success(`Unprocessed invoice settlement ${batchNumber}`);
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements', String(id)] });
            queryClient.invalidateQueries({ queryKey: ['payment-assignments'] });
        },
    });
};
