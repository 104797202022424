import React, { useState } from 'react'
import { S_SensitiveChangeModal } from './S_SensitiveChangeModal'
import { useUser } from '../../../_reduxHooks/user.hook'
import { ValidationForm } from '../../Forms/ValidationForm'
import { LoaderButton } from '../../../_components/LoaderButton'
import { authenticationService } from '../../../_services/authentication.service'

export const SensitiveChangeModal = ({ onVerification = () => {} }) => {
  const { user } = useUser()
  const [passwordEntity, setPasswordEntity] = useState({})
  const [submitForm, setSubmitForm] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const checkPwd = (values, setError) => {
    setIsLoading(true)
    return authenticationService
      .signIn(user.email_address, values.password, true, true)
      .then((res) => {
        setIsLoading(false)
        onVerification()
      })
      .catch((res) => {
        if (res.status === 401) {
          setError('password', '', 'Password is incorrect.')
        }
        setIsLoading(false)
      })
  }
  return (
    <S_SensitiveChangeModal>
      <h3>Authentication</h3>
      <div className={'explanation'}>
        You are attempting to change sensitive system configuration options. Please enter your password to proceed.
      </div>
      <ValidationForm
        entity={passwordEntity}
        fields={[
          {
            id: 'password',
            label: 'Password',
            type: 'password',
            required: true,
          },
        ]}
        getFormFunctions={({ submitForm }) => {
          setSubmitForm(submitForm)
        }}
        onChange={(id, newValue) => {
          setPasswordEntity((prev) => ({ ...prev, [id]: newValue }))
        }}
        submitHandler={checkPwd}
      />
      <div className={'submit-container'}>
        {submitForm && (
          <LoaderButton
            className={'submit-btn'}
            isLoading={isLoading}
            onClick={() => {
              submitForm()
            }}
            text={`Submit`}
          />
        )}
      </div>
    </S_SensitiveChangeModal>
  )
}
