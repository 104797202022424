import request from './axios_service'

export const getOperationTypes = () => {
  return request({
    url: `/v2/operation_types`,
    method: 'get',
  })
}

export const getAccountTypes = () => {
  return request({
    url: `/v2/account_types?account_type=cash`,
    method: 'get',
  })
}

export const getAccountTypeOperations = () => {
  return request({
    url: `/v2/account_type_operations`,
    method: 'get',
  })
}

export const putOperationAccountMapping = (operationTypes, data) => {
  const keys = Object.keys(data)
  const result = keys
    .map((key) => {
      const keyId = operationTypes.find((operationType) => operationType.name === key).id
      if (data[key] == null || data[key] === '') return
      return {
        operation_type_id: keyId,
        account_type_id: data[key],
      }
    })
    .filter((item) => item != null)

  return request({
    url: `/v2/account_type_operations`,
    method: 'put',
    data: {
      account_type_operations: result,
    },
  })
}
