export const esigningConstants = {
  GET_ESIGNING_REQUESTS_REQUEST: 'GET_ESIGNING_REQUESTS_REQUEST',
  GET_ESIGNING_REQUESTS_SUCCESS: 'GET_ESIGNING_REQUESTS_SUCCESS',
  GET_ESIGNING_REQUESTS_FAILURE: 'GET_ESIGNING_REQUESTS_FAILURE',

  OPEN_ESIGNING_DOCUMENT_REQUEST: 'OPEN_ESIGNING_DOCUMENT_REQUEST',
  OPEN_ESIGNING_DOCUMENT_SUCCESS: 'OPEN_ESIGNING_DOCUMENT_SUCCESS',
  OPEN_ESIGNING_DOCUMENT_FAILURE: 'OPEN_ESIGNING_DOCUMENT_FAILURE',

  SIGN_ESIGNING_DOCUMENT: 'SIGN_ESIGNING_DOCUMENT',
}
