import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'
import { textInputStyles } from '../../_constants/styles'

const StyledField = styled(({ invalid, ...props }) => <Field {...props} />)`
  ${textInputStyles}

  .react-phone-number-input__country {
    &:focus-within {
      outline: auto;
    }
  }

  input {
    border-bottom: none;
  }
`
const format = (val) => {
  let numsOnly = val.replace(/\D/g, '')
  let toReturn = ''
  if (numsOnly.length > 10) {
    if (numsOnly.length > 13) {
      numsOnly = numsOnly.substr(0, 13)
    }
    let chars = []
    for (let i = numsOnly.length - 1; i >= 0; i--) {
      chars.push(numsOnly[i])
      if (chars.length == 4 || chars.length == 8 || chars.length == 12) {
        chars.push(' ')
      }
    }
    return '+' + chars.reverse().join('')
  } else {
    toReturn = '(' + toReturn
    for (let i = 0; i < numsOnly.length; i++) {
      toReturn += numsOnly[i]
      if (i == 2) {
        toReturn += ') '
      }
      if (i == 5) {
        toReturn += '-'
      }
    }
    if (toReturn[toReturn.length - 1] == '-' || toReturn[toReturn.length - 1] == '(') {
      toReturn = toReturn.substr(0, toReturn.length - 1)
    }
    if (toReturn[toReturn.length - 1] == ' ') {
      toReturn = toReturn.substr(0, toReturn.length - 2)
    }
    return toReturn
  }
}
const InternationalTel = (props) => {
  const { field, formikName, handleBlur, handleChange, invalid } = props
  return (
    <StyledField
      className={invalid ? 'invalid-input' : ''}
      component={'input'}
      defaultValue={field.value}
      id={field.label}
      invalid={invalid}
      name={formikName}
      onBlur={(e) => {
        let formatted = format(e.target.value)
        handleBlur(formikName, field.name, formatted)
      }}
      placeholder={field.placeholder}
      type={`text`}
      validate={(value) => {
        let errorMessage
        if (field.required && !value) {
          errorMessage = `${field.label || `Field`} is required.`
        }
        return errorMessage
      }}
    />
  )
}

export default InternationalTel
