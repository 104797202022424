/**
 * Always require a label. Set it via aria-label if hideLabel is true.
 * Use in components that render an `input`, which needs a label for proper a11y.
 * Extend component props type with the `LabelProps` type.
 */
export const createLabelText = (label, hideLabel, type) => {
    const useAriaLabel = (hideLabel || type === 'hidden') && typeof label === 'string';
    const labelText = useAriaLabel ? { 'aria-label': label, label: undefined } : { label };
    return labelText;
};
