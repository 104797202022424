import { UTCDateToSystemString, dateToUTCString, getDateFromDatetime } from './dates'
import { verificationDatesToSystemTime } from './verification'
import { businessDatesToSystemTime } from './business'
import { getDebtor, searchDebtor } from '../_services/debtor.service'

export const getInvoicesTotal = (invoices, valueField = 'invoice_amount') => {
  if (!invoices || invoices.length === 0) {
    return 0
  }
  let total = 0
  invoices.forEach((invoice) => {
    total += invoice[valueField]
  })
  return total
}
// For Use in xlsx invoice import
export const correctDebtor = async (invoice) => {
  if (!invoice.warnings) {
    invoice.warnings = []
  }
  if (invoice.debtor_id) {
    let debtor = await getDebtor(invoice.debtor_id)
    if (debtor === null) {
      invoice.warnings.push(`Debtor with ID ${invoice.debtor_id} not found, searching by debtor name if provided.`)
      invoice.debtor_id = null
    }
  }
  if (!invoice.debtor_id && invoice.debtor_id_label) {
    let possibleDebtors = await searchDebtor(invoice.debtor_id_label)
    possibleDebtors = possibleDebtors.map((possible_debtor) => {
      possible_debtor.similarity = levenshtein(
        possible_debtor.debtor_name.toLowerCase(),
        invoice.debtor_id_label.toLowerCase()
      )
      return possible_debtor
    })
    possibleDebtors = possibleDebtors.sort((a, b) => {
      a.similarity - b.similarity
    })
    if (possibleDebtors.length) {
      invoice.debtor_id = possibleDebtors[0].id
      if (invoice.debtor_id_label.toLowerCase() != possibleDebtors[0].debtor_name.toLowerCase()) {
        invoice.warnings.push(`Exact match for debtor ${invoice.debtor_id_label} was not found.`)
      }
    }
  }
  return invoice
}

export const invoiceDatesToSystemTime = (invoice) => {
  const dateFields = ['created_at', 'updated_at', 'closed_date', 'debtor_due_date', 'invoice_date', 'purchase_date']

  dateFields.forEach((fieldName) => {
    if (invoice[fieldName]) {
      invoice[fieldName] = UTCDateToSystemString(invoice[fieldName])
    }
  })
  if (invoice.verifications) {
    invoice.verifications = invoice.verifications.map(verificationDatesToSystemTime)
  }
  return invoice
}

export const addBackwardCompatibilityNames = (invoice) => {
  if (invoice.fees_earned) {
    invoice.earned_fees = invoice.fees_earned
  }
  if (invoice.balance) {
    invoice.current_balance = invoice.balance
  }
  if (invoice.business) {
    invoice.business = businessDatesToSystemTime(invoice.business)
    invoice.business_name = invoice.business.business_name
  }
  if (invoice.purchase_batch_number) {
    invoice.batch_number = invoice.purchase_batch_number
  }
  if (!invoice?.business_name) {
    invoice.business_name = invoice.business.business_name
  }
  return invoice
}

export const invoiceDatesToUTC = (invoice) => {
  if (invoice.closed_date) {
    invoice.closed_date = dateToUTCString(invoice.closed_date)
  }
  if (invoice.debtor_due_date) {
    invoice.debtor_due_date = dateToUTCString(invoice.debtor_due_date)
  }
  if (invoice.invoice_date) {
    invoice.invoice_date = dateToUTCString(invoice.invoice_date)
  }
  if (invoice.purchase_date) {
    invoice.purchase_date = dateToUTCString(invoice.purchase_date)
  }
  return invoice
}

export const tableFormat = (invoice) => {
  if (invoice.invoice_date) {
    invoice.invoice_date = getDateFromDatetime(invoice.invoice_date)
  }
  if (invoice.debtor_due_date) {
    invoice.debtor_due_date = getDateFromDatetime(invoice.debtor_due_date)
  }
  return invoice
}

// Returns Object
// { invoice_id: {errors_list} }
export const addInvoiceErrorObject = (invoice) => {
  if (invoice && invoice.id && Object.keys(invoice.errors).length !== 0) {
    return { [invoice.id]: invoice.errors }
  }
}

// Returns Object of invoices errors
export const getInvoicesErrors = (invoices) => {
  let errors = {}
  const invoicesWithErrors = invoices.filter((invoice) => invoice?.errors && Object.keys(invoice.errors) !== 0)
  if (!invoicesWithErrors) {
    return errors
  }
  invoicesWithErrors.forEach((invoice) => {
    if (invoice.errors) {
      errors = { ...errors, ...addInvoiceErrorObject(invoice) }
    }
  })
  return errors
}
