import styled, { css } from 'styled-components'
import colors, { tenantColors } from '../../../_constants/colors'
import { cssBreakpoints, selectInputStyles, textInputStyles } from '../../../_constants/styles'
import { darken } from 'polished'

export const S_Icon = styled.div`
  position: relative;
  .icon {
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    fill: ${colors.GREY_LIGHT_FILLS};

    @media screen and (min-width: ${cssBreakpoints.mobileLg}) {
      &.icon-desktop-lg {
        width: auto;
        font-size: 20px;
      }
    }

    &.icon-right {
      left: auto;
      right: 10px;
    }
  }

  .icon + input.currency,
  .icon + input.secure,
  .icon + input.percentage {
    text-align: right;
  }
`

// This can also be textarea, select
export const S_FormInput = styled.input`
  width: 100%;

  &[type='text'],
  &[type='email'],
  &[type='password'],
  &[type='date'],
  &[type='number'],
  &[type='datetime-local'],
  &[type='time'],
  &[type='url'],
  &[type='tel'] {
    ${textInputStyles}
    width: 100%; /* TODO: does this style get added to the constant definition */
    max-width: 100%; /* TODO: does this style get added to the constant definition */
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  /* Currency, Percent styles */
  &.currency,
  &.secure,
  &.percentage {
    text-align: left;
    &:focus {
      text-align: left;
    }
  }
`

export const S_FormSelect = styled.div`
  ${selectInputStyles}
  ${(props) =>
    props.invalid &&
    css`
      border-color: ${colors.RED_ERROR};
    `}
    select {
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }
`

export const S_RadioGroup = styled.div`
  .buttons {
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
    margin: -5px;

    .radio-btn {
      margin: 5px;
      position: relative;

      label {
        padding: 9px 12px;
        font-size: 16px;
        border: 1px solid ${colors.OUTLINE_GREY_BOX};
        outline: none;
        cursor: ${(props) => (props.staticButtons ? 'default' : 'pointer')};
        display: flex;
        flex-direction: column;
        text-align: center;
        border-radius: 3px;
        transition:
          border 0.2s ease-in-out,
          color 0.2s ease-in-out,
          background-color 0.2s ease-in-out;
        white-space: nowrap;
        color: ${colors.LIGHT_GREY};
        &:hover {
          border-color: ${(props) => (props.staticButtons ? colors.OUTLINE_GREY_BOX : colors.SUBTITLE_GREY)};
          color: ${colors.TEXT_DARK};
        }

        ${(props) =>
          props.invalid &&
          css`
            border-color: ${colors.RED_ERROR};
          `}

        ${(props) =>
          props.disabled &&
          !props.staticButtons &&
          css`
            cursor: not-allowed;
            background-color: ${colors.DARK_GREY};
          `}
      }

      &.checked {
        label {
          border-color: ${(props) =>
            props.customcolor || tenantColors[props.tenant].secondary || colors.DEFAULT_SECONDARY};
          background-color: ${(props) =>
            props.customcolor || tenantColors[props.tenant].secondary || colors.DEFAULT_SECONDARY};
          color: ${colors.WHITE};
        }
      }

      input[type='radio'] {
        opacity: 0;
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  &.selected-accent {
    .buttons {
      .radio-btn {
        label {
          border-color: ${colors.TEXT_LIGHT};
          color: ${colors.TEXT_LIGHT};
          &:hover {
            border-color: ${colors.DEFAULT_SECONDARY};
            color: ${colors.DEFAULT_SECONDARY};
          }
        }

        &.checked {
          label {
            background: ${colors.DEFAULT_SECONDARY};
            border-color: ${colors.DEFAULT_SECONDARY};
            color: #fff;
          }
        }
      }
    }
  }

  &.split-buttons {
    .buttons {
      margin: 0;
      .radio-btn {
        margin: 0;
        label {
          border-radius: 0;
          transition: background 0.2s ease-in-out;
          color: ${(props) => props.customcolor || tenantColors[props.tenant].secondary || colors.DEFAULT_SECONDARY};
        }

        &.checked {
          label {
            background: ${(props) =>
              props.customcolor || tenantColors[props.tenant].secondary || colors.DEFAULT_SECONDARY};
            color: #fff;
          }
        }

        &:first-child {
          label {
            border-radius: 3px 0 0 3px;
          }
        }
        &:last-child {
          label {
            border-radius: 0 3px 3px 0;
          }
        }
      }
    }
  }
`
export const S_MultiSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;

  .checkbox-btn {
    flex-grow: 1;
    flex-basis: 0;
    margin: 5px;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }

    label {
      padding: 13px 16px;
      font-size: 16px;
      border: 2px solid ${colors.DARK_GREY};
      outline: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 5px;
      transition: border 0.2s ease-in-out;
      white-space: nowrap;
      &:hover {
        border: 2px solid ${darken(0.1, colors.DARK_GREY)};
      }
    }

    input[type='checkbox'] {
      display: none;

      &:checked {
        & + label {
          border-color: ${(props) => props.customcolor || tenantColors[props.tenant].primary || colors.DEFAULT_PRIMARY};
        }
      }
    }
  }
`

export const S_TextAreaInput = styled.textarea`
  border-radius: 3px;
  font-family: 'Lato', sans-serif;
  border: 1px solid ${colors.OUTLINE_GREY_BOX};
  color: ${colors.TEXT_DARK};
  font-size: 14px;
  min-height: 120px;
  outline: none;
  padding: 9px 12px;
  width: 100%;
  &:hover,
  &:focus {
    border-color: ${colors.SUBTITLE_GREY};
  }
`
