import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Card, Group, Heading, Stack } from 'xen-ui';
import { Page } from 'xen/components';
import { PlaidAccounts, PlaidLink } from '../components';
const LinkedExternalAccounts = () => {
    const [selectedAccount, setSelectedAccount] = useState(null);
    return (_jsx(Page, { "data-testid": "linked-external-accounts", children: _jsxs(Card, { p: "0", radius: "md", shadow: "sm", withBorder: true, children: [_jsxs(Group, { bd: "2px solid gray.1", justify: "space-between", px: "lg", py: "md", children: [_jsx(Group, { gap: "xl", children: _jsx(Heading, { icon: "BuildingBank", level: 2, children: "Linked Bank Accounts" }) }), _jsx(PlaidLink, {})] }), _jsx(Stack, { p: "lg", children: _jsx(PlaidAccounts, { entityType: "tenant", onAccountSelected: setSelectedAccount, selectedAccount: selectedAccount }) })] }) }));
};
export default LinkedExternalAccounts;
