import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { modalActions } from '../_actions'

export const modalActionHooks = {
  useOpenModal,
  useCloseModal,
  useSwitchModal,
  useSetPrevModal,
}

function useOpenModal() {
  const dispatch = useDispatch()
  return useCallback(
    (content, props, modalProps) => dispatch(modalActions.openModal(content, props, modalProps)),
    [dispatch]
  )
}

function useCloseModal() {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(modalActions.closeModal()), [dispatch])
}

function useSwitchModal() {
  const dispatch = useDispatch()
  return useCallback((content, props) => dispatch(modalActions.switchModal(content, props)), [dispatch])
}

function useSetPrevModal() {
  const dispatch = useDispatch()
  return useCallback((content, props) => dispatch(modalActions.setPrevModal(content, props)), [dispatch])
}
