import React, { useEffect, useState } from 'react'

import { S_NotificationGroupModal } from './NotificationGroupModal.styles'
import { notificationActionHooks } from '../../../_actionHooks'
import { getNotificationTypes, getUsers } from '../../../_services/lender.service'
import { Button } from '../../../_components/Button'

import { getTenantUsers } from '../../../_services/fvadmin_service'
import { createSearchParams } from '../../../_helpers/search'

import { updateNotificationGroup, createNotificationGroup } from '../../../_services/lender.service'
import { ValidationForm } from '../../../_components/Forms/ValidationForm'
import { logError } from '../../../_helpers/errors'

export const NotificationGroupModal = ({ notificationGroup, closeModal }) => {
  const [group, setGroup] = useState(
    notificationGroup !== undefined
      ? { ...notificationGroup }
      : {
          name: '',
          description: '',
          notifications: [],
          users: [],
        }
  )
  const isEditing = notificationGroup !== undefined
  const [notificationTypesOptions, setNotificationTypesOptions] = useState([])
  const [submitFunction, setSubmitFunction] = useState()
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)

  const showNotification = notificationActionHooks.useShowNotification()

  useEffect(() => {
    getNotificationTypes().then((response) => {
      const options = response.data.map((notificationType) => {
        return {
          id: notificationType.id,
          name: notificationType.name,
          label: notificationType.name,
          checked: group.notifications.some((n) => n.name === notificationType.name),
        }
      })
      setNotificationTypesOptions(options)
    })
  }, [])

  const searchLenderUsers = async (searchText, setLoading = () => {}) => {
    let searchParams = createSearchParams({
      searchTerm: searchText,
      searchParam: 'search_term',
    })

    setLoading(true)
    const response = getTenantUsers(searchParams)
    setLoading(false)

    return response
  }

  const createGroup = () => {
    createNotificationGroup(group)
      .then(() => {
        closeModal()
        showNotification({ message: 'The notification group was created successfully!', type: 'success' })
      })
      .catch((error) => {
        logError(error)
        showNotification({
          type: 'error',
          message: 'The notification group could not be created. Please contact the administrator.',
        })
      })
  }

  const editGroup = () => {
    updateNotificationGroup(group.id, group)
      .then(() => {
        closeModal()
        showNotification({ message: 'The notification group was updated successfully!', type: 'success' })
      })
      .catch((error) => {
        logError(error)
        showNotification({
          type: 'error',
          message: 'The notification group could not be updated. Please contact the administrator.',
        })
      })
  }

  const fields = [
    {
      type: 'text',
      id: 'name',
      layout: '',
      label: 'Name',
      required: 'Required',
    },
    {
      type: 'textarea',
      id: 'description',
      layout: '',
      label: 'Description',
      required: 'Required',
    },
    {
      type: 'checkbox-group',
      id: 'notifications',
      layout: 'label-over',
      label: 'Notification Types',
      required: true,
      options: notificationTypesOptions,
      onChange: (value, id) => {
        const notificationType = notificationTypesOptions.find((notificationType) => notificationType.id === id)

        if (value) {
          group.notifications.push({
            id: notificationType.id,
            name: notificationType.name,
          })
        } else {
          const index = group.notifications.indexOf((n) => n.id === id)
          group.notifications.splice(index, 1)
        }
      },
    },
    {
      id: 'users',
      fieldName: 'first_name',
      label: 'Users',
      type: 'autocomplete',
      layout: 'label-over',
      multiple: true,
      required: true,
      autoCompleteProps: {
        checkInputValue: false,
        valueIdAttr: 'id',
        valueLabelAttr: 'first_name',
        fetchRecord: getUsers,
        fetchSuggestions: searchLenderUsers,
        defaultSuggestion: 'Start typing to find an user...',
        addButton: false,
      },
    },
  ]

  return (
    <S_NotificationGroupModal>
      <h2>Create New Notification Group</h2>
      <br />
      <ValidationForm
        entity={group}
        fields={fields}
        getFormFunctions={({ submitForm }) => {
          setSubmitFunction(submitForm)
        }}
        onBlur={(id, setError, value) => {
          if (id === 'users') {
            group[id] = value.items
          }
        }}
        onChange={(id, newValue) => {
          group[id] = newValue
        }}
        submitHandler={() => {
          setSubmitButtonDisabled(true)
          if (isEditing) {
            editGroup()
          } else {
            createGroup()
          }
          setSubmitButtonDisabled(false)
        }}
      />
      <div className="submit">
        <Button
          buttonType={`button`}
          disabled={submitButtonDisabled}
          onClick={submitFunction}
          text={isEditing ? 'Save Changes' : 'Save'}
        />
      </div>
    </S_NotificationGroupModal>
  )
}
