import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useConfigurationContext } from 'xen/providers';
import { NavBarLink } from '../nav-bar-link';
import { NavBarLinkExternal } from '../nav-bar-link-external';
import { NavBarSignOutButton } from '../nav-bar-sign-out-button';
export const ClientNavBarFooter = () => {
    const { tenant } = useConfigurationContext();
    const contactUsUrl = tenant.branding.urls.contactUs;
    return (_jsxs(_Fragment, { children: [_jsx(NavBarLink, { icon: "Bell", label: "Notifications", to: "/notifications" }), _jsx(NavBarLink, { icon: "UserCircle", label: "Profile", to: "/users/profile" }), contactUsUrl && _jsx(NavBarLinkExternal, { href: contactUsUrl, icon: "ExternalLink", label: "Contact us" }), _jsx(NavBarSignOutButton, { icon: "Logout", label: "Sign out" })] }));
};
