import styled from 'styled-components'
import colors from '../../../_constants/colors'

export const S_GLAccountModal = styled.div`
  height: 300px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const S_QuoteTemplateModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input {
    margin-bottom: 10px;
  }

  h2,
  h3,
  h4 {
    margin: 10px 10px 10px 0;
  }

  .variables-header {
    display: flex;
    padding: 10px 0;

    button {
      min-width: 50px;
      padding-left: 20px;
    }
  }

  .submit {
    padding: 10px 0;
  }

  .actions {
    cursor: pointer;
    width: 10%;
    flex-basis: 40px;
    align-self: center;
  }

  .variable-row {
    display: flex;

    svg {
      margin-left: 10px;
    }
  }

  .variable-column {
    flex-basis: 160px;
    padding: 0 10px;
  }

  .subtitle {
    color: ${colors.LIGHT_GREY};
    display: block;
    overflow-wrap: normal;
    margin: 0px;
    padding: 10px 0;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.3px;
  }
  .placeholders,
  .default-placeholders-column,
  .default-placeholders-row {
    display: flex;
  }
  .default-placeholders {
    display: flex;
    flex-basis: 60%;
    flex-direction: column;
  }
  .current-placeholders {
    display: flex;
    flex-basis: 40%;
    flex-direction: column;
  }

  .default-placeholders-row {
    line-height: 30px;
  }

  .label {
    align-items: center;
    flex-basis: 240px;
  }
`
