import styled from 'styled-components'
import colors from '../_constants/colors'

export const AlertWrapper = styled.aside`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  width: 100%;
`

export const AlertInnerWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`

export const Auth = styled.div`
  background-color: ${(props) => props.customColors.authBackground || colors.DEFAULT_AUTH_BACKGROUND};
  background-image: ${(props) => (props.bg ? `url(${props.bg})` : 'none')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 10px;
  height: 100%;
  .auth-panel {
    background-color: white;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    text-align: center;
    display: flex;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    .sign_in,
    .sign_up,
    .forgot_password,
    .reset_password {
      display: flex;
      flex-direction: column;
    }

    .title {
      font-weight: 500;

      @media screen and (min-width: 700px) {
        text-align: left;
      }
    }

    .section {
      padding: 50px 25px;
      max-width: 50%;
      height: 100%;
      @media screen and (min-width: 700px) {
        padding: 50px;
      }
      @media screen and (max-width: 700px) {
        max-width: 100%;
      }

      form {
        display: flex;
        flex-direction: column;
        .error-msg {
          text-align: left;
          font-size: 0.9rem;
          font-weight: 900;
          color: ${colors.MATERIAL_RED};
        }

        .duplicate {
          text-align: left;
          margin-bottom: -5px;
          font-size: 0.9rem;
          font-weight: 900;
          color: #9eaeb6;
          text-decoration: underline;
        }

        .submit-btn {
          margin-top: 30px;
          width: 100%;
        }
      }
    }

    .primary-section {
      border-bottom: 1.5px solid #e7eaee;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      @media screen and (min-width: 700px) {
        border-right: 1.5px solid #e7eaee;
        border-bottom: none;
      }
    }

    .secondary-section {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-items: center;
      align-content: center;
      height: 100%;
      .auth-logo {
        margin-top: 5em;
        margin-bottom: 1em;
        max-width: 160px;
      }

      hr {
        border: 1.5px solid #e7eaee;
        width: 50px;
        margin-top: 1em;
      }

      ul,
      ol {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: ${(props) => props.customColors.listColor || '#9eaeb6'};
        font-size: 0.95em;

        li {
          width: fit-content;

          .icon {
            margin-right: 15px;
          }
        }
      }

      ol {
        li {
          margin-bottom: 10px;
        }
      }

      ul {
        list-style: none;
      }

      h4 {
        white-space: pre-line;
        font-size: 22px;
        text-transform: capitalize;
      }

      .pdf-application {
        color: #9eaeb6;
        /* font-size: 0.9em; */
        margin-top: 50px;

        a {
          color: #900;
        }
      }
    }

    .further-options {
      display: flex;
      flex-direction: column;
      grid-gap: 20px;
      letter-spacing: 0.025em;

      p,
      a {
        margin-top: 1em;
        color: ${(props) => props.customColors.defaultTextColor || `#9eaeb6`};
      }

      p.small {
        font-size: 12px;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .certifications {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      .certlink {
        margin: 0px 4px 0px 4px;
      }
      img {
        height: 40px;
      }

      @media screen and (min-width: 700px) {
        grid-auto-flow: column;
        margin-top: 100px;
      }

      @media screen and (max-width: 700px) {
        margin-top: 32px;
        justify-content: space-evenly;
      }
    }
  }
`
