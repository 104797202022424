import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Button } from '../../Button'

// Actions
import { modalActions } from '../../../_actions'

const StyledFullDisclaimer = styled.div`
  max-width: 500px;
  .apply-btn {
    margin-top: 16px;
    display: flex;
    justify-content: space-evenly;
  }
  .title {
    color: #383f44;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.56px;
  }
  .body {
    margin-top: 8px;
    color: #9eaeb6;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.76px;
  }
`

const InfoModal = ({ title = '', content = '', buttonText = 'Accept', closeModal }) => {
  return [
    <StyledFullDisclaimer>
      <div className={`title`}>{title}</div>
      <div className={`body`}>{content}</div>
      <div className={'apply-btn'}>
        <Button onClick={closeModal} text={buttonText} type={`button`} />
      </div>
    </StyledFullDisclaimer>,
  ]
}

function mapStateToProps(state) {
  const { business, env } = state
  return {
    business,
    tenant: env.tenant,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(modalActions.closeModal())
    },
  }
}

const connectedDisclaimer = connect(mapStateToProps, mapDispatchToProps)(InfoModal)
export { connectedDisclaimer as InfoModal }
