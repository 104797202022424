/** Parse string like '2024-01-31' as Date in local timezone at midnight */
const parseISODateStringAsDate = (dateString) => {
    const matchRegex = /^(\d\d\d\d)-(\d\d)-(\d\d)/;
    const match = dateString.match(matchRegex);
    if (match === null)
        return new Date();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_originalString, year, month, day] = match;
    return new Date(`${year}-${month}-${day}T00:00:00`);
};
export const parseDate = {
    fromIsoDateString: parseISODateStringAsDate,
};
