import { bankConstants } from '../_constants'

const initialState = {
  [bankConstants.ATTR_NAME]: bankConstants.VALUE_UPLOAD,
}

export function bank(state = initialState, action) {
  switch (action.type) {
    case bankConstants.SET_BANK_CONNECTION_STATUS:
      return {
        loading: false,
        [bankConstants.ATTR_NAME]: action.value,
      }
    case bankConstants.DO_NOT_CONNECT_BANK:
      return {
        loading: false,
        [bankConstants.ATTR_NAME]: bankConstants.VALUE_UPLOAD,
      }
    case bankConstants.CONNECT_BANK_REQUEST:
      return {
        loading: true,
        [bankConstants.ATTR_NAME]: bankConstants.VALUE_CONNECT,
      }
    case bankConstants.CONNECT_BANK_SUCCESS:
      return {
        loading: false,
        [bankConstants.ATTR_NAME]: bankConstants.VALUE_CONNECT,
      }
    case bankConstants.CONNECT_BANK_FAILURE:
      return {
        loading: false,
        error: action.error,
        [bankConstants.ATTR_NAME]: bankConstants.VALUE_UPLOAD,
      }

    default:
      return state
  }
}
