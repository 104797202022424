import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useAuthentication } from 'xen/hooks';
import { useConfigurationContext } from 'xen/providers';
const UnappliedCash = lazy(() => import('../pages/unapplied-cash'));
export const UNAPPLIED_CASH_PATHS = {
    root: '/unapplied-cash',
    payment: '/unapplied-cash/:paymentId',
};
export const UnappliedCashRoutes = () => {
    const { payment, root } = UNAPPLIED_CASH_PATHS;
    const { tenant: { factoring }, } = useConfigurationContext();
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    return [
        _jsx(Route, { exact: true, path: root, children: factoring.enabled ? _jsx(UnappliedCash, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, root),
        _jsx(Route, { path: payment, children: factoring.enabled ? _jsx(UnappliedCash, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, payment),
    ];
};
