import { notificationsConstants } from '../_constants'

export function notifications(state = [], action) {
  switch (action.type) {
    case notificationsConstants.SHOW_NOTIFICATION:
      return state.concat(action.notification)
    case notificationsConstants.HIDE_NOTIFICATION:
      return state.filter((notification) => notification.id != action.id)
    default:
      return state
  }
}
