import Noty from 'noty'

export const showNotification = (message, type, options = {}) => {
  new Noty({
    layout: 'topRight',
    type: type,
    text: message,
    timeout: 4000,
    ...options,
  }).show()
}
