import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from 'xen-ui';
import { useCreateInvoiceScheduleDocument } from 'xen/api/use-invoice-schedule';
import { DocumentList, Dropzone } from 'xen/components';
export const InvoiceScheduleFileManager = ({ invoiceSchedule }) => {
    const { documents = [] } = invoiceSchedule;
    const { mutate: uploadInvoiceScheduleDocument, isError, isPending, } = useCreateInvoiceScheduleDocument({ urlParams: { scheduleId: invoiceSchedule.id } });
    const uploadDocument = ({ file }) => {
        uploadInvoiceScheduleDocument({ payload: { file } });
    };
    return (_jsxs(Stack, { children: [_jsx(Dropzone, { isError: isError, isLoading: isPending, uploadFile: uploadDocument }), documents.length > 0 && _jsx(DocumentList, { documents: documents })] }));
};
