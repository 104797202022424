import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { formatCase } from 'xen/helpers';
export const useGetSalesPeople = () => {
    return useQuery({
        queryKey: ['sales-people'],
        queryFn: async () => {
            try {
                const response = await request({
                    method: 'GET',
                    url: '/users/sales_people',
                });
                return response.map((salesPerson) => formatCase.objectKeys('camel', salesPerson));
            }
            catch (_error) {
                throw Error('Error fetching Sales People');
            }
        },
    });
};
