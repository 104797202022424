import { jsx as _jsx } from "react/jsx-runtime";
import { ActionIcon, XenIcon } from 'xen-ui';
import { useDeleteDocument } from 'xen/api/use-document';
export const DocumentListDelete = ({ document }) => {
    const { mutate: deletePaymentDocument, isPending } = useDeleteDocument();
    const onDelete = () => {
        deletePaymentDocument({ id: document.id, name: document.documentName });
    };
    return (_jsx(ActionIcon, { "aria-label": 'Delete Document', color: "red", loading: isPending, onClick: () => onDelete(), size: "sm", variant: "transparent", children: _jsx(XenIcon.Trash, { size: "2em" }) }));
};
