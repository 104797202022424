import { UTCDateToSystemString, dateToUTCString } from './dates'
export const noaDatesToUTC = (noa) => {
  if (noa.received_date) {
    noa.received_date = dateToUTCString(noa.received_date)
  }
  if (noa.sent_date) {
    noa.sent_date = dateToUTCString(noa.sent_date)
  }
  if (noa.stopped_date) {
    noa.stopped_date = dateToUTCString(noa.stopped_date)
  }
  if (noa.waived_date) {
    noa.waived_date = dateToUTCString(noa.waived_date)
  }
  return noa
}
export const noaDatesToSystemTime = (noa) => {
  noa.created_at = UTCDateToSystemString(noa.created_at)
  noa.updated_at = UTCDateToSystemString(noa.updated_at)
  if (noa.received_date) {
    noa.received_date = UTCDateToSystemString(noa.received_date).split('T')[0]
  }
  if (noa.sent_date) {
    noa.sent_date = UTCDateToSystemString(noa.sent_date).split('T')[0]
  }
  if (noa.stopped_date) {
    noa.stopped_date = UTCDateToSystemString(noa.stopped_date).split('T')[0]
  }
  if (noa.waived_date) {
    noa.waived_date = UTCDateToSystemString(noa.waived_date).split('T')[0]
  }
  return noa
}
