import { InvoiceScheduleApproveButton } from './_invoice-schedule-approve-button';
import { InvoiceScheduleDeleteButton } from './_invoice-schedule-delete-button';
import { InvoiceScheduleNotifyDebtorsButton } from './_invoice-schedule-notify-debtors-button';
import { InvoiceScheduleSubmitButton } from './_invoice-schedule-submit-button';
import { InvoiceScheduleUnapproveButton } from './_invoice-schedule-unapprove-button';
import { InvoiceScheduleUnsubmitButton } from './_invoice-schedule-unsubmit-button';
export const InvoiceScheduleButton = {
    Approve: InvoiceScheduleApproveButton,
    Delete: InvoiceScheduleDeleteButton,
    NotifyDebtors: InvoiceScheduleNotifyDebtorsButton,
    Submit: InvoiceScheduleSubmitButton,
    Unapprove: InvoiceScheduleUnapproveButton,
    Unsubmit: InvoiceScheduleUnsubmitButton,
};
