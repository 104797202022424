import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { DateInput as MantineDateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { createLabelText } from '../_internal/';
// It is required to extend dayjs with customParseFormat plugin
// in order to parse dates with custom format, e.g. valueFormat="DD/MM/YYYY HH:mm:ss"
dayjs.extend(customParseFormat);
const SUNDAY = 0;
export const DateInput = forwardRef(({ firstDayOfWeek = SUNDAY, hideLabel = false, highlightToday = true, label, ...props }, ref) => {
    const labelText = createLabelText(label, hideLabel, props.type);
    return (_jsx(MantineDateInput, { firstDayOfWeek: firstDayOfWeek, highlightToday: highlightToday, ...props, ref: ref, ...labelText }));
});
DateInput.displayName = 'DateInput';
