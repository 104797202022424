import { store } from '../../_helpers/store'
import { esigningActions } from '../../_actions/esigning.actions'
const onMessage = (message) => {
  if (message?.message?.message_type === 'signature_request') {
    store.dispatch(esigningActions.getEsigningRequests(store.getState().user.id, true))
  }
}
const respondsTo = ['signature_request']
const channel = 'BusinessSignatureChannel'

export const helloSignService = { onMessage, respondsTo, channel }
