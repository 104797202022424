import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { z } from 'zod';
import { Box, Popover, Progress, rem, Text, XenIcon, PasswordInput as XenPasswordInput } from 'xen-ui';
import { ErrorText } from '../../error-text';
const CHARACTER_IS = {
    NUMBER: /[0-9]/,
    LOWERCASE: /[a-z]/,
    UPPERCASE: /[A-Z]/,
    SPECIAL: /[\^$*.[\]{}()?!@#%&,><:;|_~]/,
};
const MIN_CHARACTERS = 8;
export function FormPasswordInput({ name, control, defaultValue, error, iconLeft, iconRight, rules, shouldUnregister, onChange, hideStrengthPopover = false, ...props }) {
    const { field: { value, onChange: fieldOnChange, ...field }, fieldState, } = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const [popoverOpened, setPopoverOpened] = useState(false);
    const fieldError = error ?? fieldState.error?.message;
    const LeftIcon = iconLeft ? XenIcon[iconLeft] : undefined;
    const RightIcon = iconRight ? XenIcon[iconRight] : undefined;
    const input = (_jsx(XenPasswordInput, { error: fieldError ? _jsx(ErrorText, { component: "span", message: fieldError }) : undefined, inputWrapperOrder: ['label', 'description', 'error', 'input'], onChange: (e) => {
            fieldOnChange(e);
            onChange?.(e);
        }, styles: { root: { display: 'flex', flexDirection: 'column', gap: '.25rem' }, ...props.styles }, value: value, ...(LeftIcon && { leftSection: _jsx(LeftIcon, { fontSize: "inherit" }) }), ...(RightIcon && { rightSection: _jsx(RightIcon, { fontSize: "inherit" }) }), ...field, ...props }));
    if (hideStrengthPopover)
        return input;
    const strength = getStrength(value);
    const progressColor = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';
    return (_jsxs(Popover, { opened: popoverOpened, position: "bottom", transitionProps: { transition: 'pop' }, width: "target", children: [_jsx(Popover.Target, { children: _jsx("div", { onBlurCapture: () => setPopoverOpened(false), onFocusCapture: () => setPopoverOpened(true), children: input }) }), _jsxs(Popover.Dropdown, { children: [_jsx(Progress, { color: progressColor, mb: "xs", size: 5, value: strength }), _jsx(PasswordRequirement, { label: `Includes at least ${MIN_CHARACTERS} characters`, meets: value.length >= MIN_CHARACTERS }), requirements.map((requirement, index) => (_jsx(PasswordRequirement, { label: requirement.label, meets: requirement.regex.test(value) }, index)))] })] }));
}
const inputSchema = () => z
    .string()
    .min(MIN_CHARACTERS, { message: `Must include at least ${MIN_CHARACTERS} characters` })
    .refine((value) => CHARACTER_IS.NUMBER.test(value), { message: 'Must include at least one number' })
    .refine((value) => CHARACTER_IS.LOWERCASE.test(value), {
    message: 'Must include at least one lowercase letter',
})
    .refine((value) => CHARACTER_IS.UPPERCASE.test(value), {
    message: 'Must include at least one uppercase letter',
})
    .refine((value) => CHARACTER_IS.SPECIAL.test(value), {
    message: 'Must include at least one special character: ^$*.[]{}()?!@#%&,><:;|_~',
});
FormPasswordInput.schema = inputSchema;
// Private --------------------------------------------------------------------
const PasswordRequirement = ({ meets, label }) => {
    const Icon = meets ? XenIcon.CheckMark : XenIcon.Close;
    const textColor = meets ? 'teal' : 'red';
    return (_jsxs(Text, { c: textColor, mt: 7, size: "sm", style: { display: 'flex', alignItems: 'center' }, children: [_jsx(Icon, { style: { width: rem(14), height: rem(14) } }), ' ', _jsx(Box, { component: "span", ml: 10, children: label })] }));
};
const requirements = [
    { regex: CHARACTER_IS.NUMBER, label: 'Includes number' },
    { regex: CHARACTER_IS.LOWERCASE, label: 'Includes lowercase letter' },
    { regex: CHARACTER_IS.UPPERCASE, label: 'Includes uppercase letter' },
    { regex: CHARACTER_IS.SPECIAL, label: 'Includes special character: ^$*.[]{}()?!@#%&,><:;|_~' },
];
const getStrength = (password) => {
    let multiplier = password.length >= MIN_CHARACTERS ? 0 : 1;
    requirements.forEach((requirement) => {
        if (!requirement.regex.test(password)) {
            multiplier += 1;
        }
    });
    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
};
