export const uploadInvoiceConfig = [
  {
    type: 'radio',
    name: 'document_type',
    table: 'uploadedInvoices',
    label: 'Is this document an invoice, purchase order, or other receivable?',
    options: [
      {
        label: 'Invoice',
        value: 'invoice',
      },
      {
        label: 'Purchase Order',
        value: 'purchase_order',
      },
      {
        label: 'Other',
        value: 'other',
      },
    ],
    required: true,
  },
  {
    type: 'text',
    name: 'debtor_name',
    table: 'uploadedInvoices',
    label: 'What is your customers name?',
    required: true,
  },
  {
    type: 'currency',
    name: 'face_value',
    table: 'uploadedInvoices',
    label: 'What is the amount listed on the document?',
    required: true,
  },
  {
    type: 'text',
    name: 'invoice_number',
    table: 'uploadedInvoices',
    label: 'Invoice No.',
    required: true,
  },
  {
    type: 'select',
    name: 'payment_terms',
    table: 'uploadedInvoices',
    label: 'What are the payment terms?',
    options: [
      { label: 'Net 15', value: 'Net 15' },
      { label: 'Net 30', value: 'Net 30' },
      { label: 'Net 60', value: 'Net 60' },
      { label: 'Net 90', value: 'Net 90' },
      { label: 'Other', value: 'Other' },
    ],
    required: true,
    following_question: {
      condition: 'Other',
      type: 'text',
      name: 'other_payment_terms',
      table: 'uploadedInvoices',
      label: 'Payment Terms',
      required: true,
    },
  },
  {
    type: 'date',
    name: 'document_issue_date',
    table: 'uploadedInvoices',
    label: 'What is the issue date?',
    required: true,
  },
]
