import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from 'xen-ui';
/** Based on https://mantine.dev/theming/colors/#default-colors */
export const invoiceScheduleStateColorMap = {
    incomplete: 'gray',
    pending: 'yellow.3',
    approved: 'green.4',
    purchased: 'blue',
    funded: 'violet',
    closed: 'orange.6',
    rejected: 'red',
};
export const InvoiceScheduleStateBadge = ({ invoiceSchedule }) => {
    const { state } = invoiceSchedule;
    const badgeColor = invoiceScheduleStateColorMap[state];
    return (_jsx(Badge, { autoContrast: true, color: badgeColor, "data-testid": "invoice-schedule-state-badge", size: "lg", children: state }));
};
