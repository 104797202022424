import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './query-client';
/**
 * Tanstack query provider + devtools.
 * https://tanstack.com/query/latest/docs/framework/react/overview
 *
 * Open the query dev tools by adding `?queryDevtools` to the URL.
 * https://tanstack.com/query/latest/docs/framework/react/devtools
 */
export const QueryProvider = ({ children }) => {
    const searchParams = new URLSearchParams(window.location.search);
    const showDevtools = searchParams.has('queryDevtools');
    return (_jsx(_Fragment, { children: _jsxs(QueryClientProvider, { client: queryClient, children: [children, showDevtools && _jsx(ReactQueryDevtools, { initialIsOpen: false })] }) }));
};
