import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { Button, Flex, Group, Heading, Loader, Stack, Text } from 'xen-ui';
import { useGetInvoiceSchedule, useIsInvoiceScheduleUpdating } from 'xen/api/use-invoice-schedule';
import { ErrorText, Page, PageTitle } from 'xen/components';
import { AddInvoiceButton } from 'xen/_modules/invoices/components';
import { InvoiceScheduleActions, InvoiceScheduleFileManager, InvoiceScheduleInvoices, InvoiceScheduleStateBadge, InvoiceScheduleTotals, InvoiceScheduleUpdateForm, } from '../components';
const InvoiceScheduleEdit = () => {
    const { scheduleId: id } = useParams();
    const scheduleId = Number(id);
    const { data: invoiceSchedule, isLoading: isScheduleLoading, isError: isScheduleError, } = useGetInvoiceSchedule(scheduleId);
    const isUpdating = useIsInvoiceScheduleUpdating(invoiceSchedule?.id);
    if (isScheduleLoading)
        return (_jsx(Page, { children: _jsx(Loader, {}) }));
    if (isScheduleError || !invoiceSchedule)
        return (_jsx(Page, { children: _jsx(ErrorText, { message: "Could not fetch invoice schedule" }) }));
    return (_jsxs(Page, { children: [_jsxs(Group, { align: "center", justify: "space-between", children: [_jsxs(Group, { align: "center", children: [_jsx(PageTitle, { title: `Invoice Schedule ${invoiceSchedule.batchNumber}` }), _jsx(InvoiceScheduleStateBadge, { invoiceSchedule: invoiceSchedule }), isUpdating && _jsx(Loader, { size: "sm" })] }), _jsx(InvoiceScheduleActions, { invoiceSchedule: invoiceSchedule })] }), _jsxs(Stack, { gap: "xl", children: [_jsxs(Stack, { children: [_jsx(Heading, { level: 2, children: "Overview" }), _jsxs(Flex, { align: "flex-start", direction: { base: 'column', sm: 'row' }, gap: "6rem", children: [_jsx(InvoiceScheduleUpdateForm, { invoiceSchedule: invoiceSchedule }), _jsx(InvoiceScheduleTotals, { invoiceSchedule: invoiceSchedule }), _jsxs(Stack, { flex: 1, gap: "xxxs", children: [_jsx(Text, { fw: "bold", size: "sm", children: "Documents" }), _jsx(Text, { c: "gray", size: "xs", children: "Supporting documents for this invoice schedule" }), _jsx(InvoiceScheduleFileManager, { invoiceSchedule: invoiceSchedule })] })] })] }), _jsxs(Stack, { children: [_jsxs(Group, { align: "center", justify: "space-between", children: [_jsx(Heading, { level: 2, children: "Invoices" }), _jsxs(Group, { align: "center", children: [_jsx(Button, { disabled: invoiceSchedule.state !== 'incomplete', size: "sm", variant: "outline", children: "Bulk Upload" }), _jsx(AddInvoiceButton, { invoiceSchedule: invoiceSchedule, size: "sm", children: "Add Invoice" })] })] }), _jsx(InvoiceScheduleInvoices, { invoiceSchedule: invoiceSchedule })] })] })] }));
};
export default InvoiceScheduleEdit;
