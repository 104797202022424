import React from 'react'
import styled from 'styled-components'
import { FaPhone } from 'react-icons/fa'

const S_MenuHelpLinks = styled.div`
  display: flex;
  margin-top: 1.5em;
  font-size: 16px;
  font-weight: 400;

  .need-help-title {
    white-space: nowrap;
  }

  .icon {
    &._mail-icon {
      margin-top: 0.1em;
    }
  }

  .need-help-link {
    line-height: 1;
    margin-top: 0;
    font-weight: 400;

    & + .need-help-link {
      margin-top: 12px;
    }
  }
  .need-help-label {
    margin-left: 5px;
  }
`

export const MenuHelpLinks = ({ links }) => {
  return (
    <S_MenuHelpLinks className={`need-help`}>
      <div className="need-help-links">
        {links.map((linkItem, i) => (
          <a className={`need-help-link`} href={linkItem.link} key={i}>
            {linkItem.icon}
            {!linkItem.icon && linkItem.type === 'phone' && <FaPhone className={`icon`} />}
            <span className={`need-help-link-label`}>{linkItem.label}</span>
          </a>
        ))}
      </div>
    </S_MenuHelpLinks>
  )
}
