import { datadogRum } from '@datadog/browser-rum'

export function startMonitoring({ applicationId, env, version }) {
  console.log('[Datadog] Datadog RUM client initialized')

  datadogRum.init({
    applicationId,
    clientToken: 'pub34bc849dc31b94d5844f6145d9af7623',
    site: 'us5.datadoghq.com',
    service: 'platform_react',
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

export function addTenantToMonitoringContext(tenant) {
  datadogRum.setGlobalContext({
    tenant: {
      id: tenant.id,
      name: tenant.organization_name,
      token: tenant.token,
    },
  })
}

export function addUserToMonitoringContext(user) {
  datadogRum.setUser({
    email: user.email_address,
    id: user.id,
    is_admin: user.is_admin,
    is_operations: user.is_operations,
    is_sales_person: user.is_sales_person,
    is_underwriter: user.is_underwriter,
    name: [user.first_name?.trim(), user.last_name?.trim()].join(' ').trim(),
    phone_number: user.phone_number,
    title: user.title,
    user_type: user.user_type,
  })
}

export function removeUserFromMonitoringContext() {
  datadogRum.clearUser()
}
