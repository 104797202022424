import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Checkbox, Text } from 'xen-ui';
import { TextLink } from 'xen/components';
import { PRIVACY_NOTICE_URL, TERMS_AND_CONDITIONS_URL } from 'xen/constants';
export const useAcceptTerms = () => {
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
    const toggleAcceptance = (checked) => {
        setHasAcceptedTerms(checked);
    };
    const AcceptTermsCheckbox = () => (_jsx(Checkbox, { checked: hasAcceptedTerms, label: _jsxs(Text, { size: "sm", children: ["I agree to the ", _jsx(TextLink, { to: TERMS_AND_CONDITIONS_URL, children: "Terms & Conditions" }), " and acknowledge that I have read and understood the ", _jsx(TextLink, { to: PRIVACY_NOTICE_URL, children: "Privacy Notice" })] }), name: "terms", onChange: (event) => toggleAcceptance(event.target.checked), required: true, size: "xs", styles: { body: { alignItems: 'center' } } }));
    return { hasAcceptedTerms, AcceptTermsCheckbox };
};
