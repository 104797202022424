/*********************************************
 * DO NOT USE THIS CONFIG
 * The Capital Plus tenatn has migrated to Capital Plus Financial
 * use ./capitalplusfinancial_config instead.
 ********************************************/
import ROUTES from '../../../_constants/routes'
import { BUSINESS_ENTITY_OPTIONS, todayIsoDate } from '../../form.constants'
import { getAllowedDob } from '../../../_helpers'
import React from 'react'

export const capitalplus_config = {
  crumbs: [
    {
      label: 'Business Details',
      link: ROUTES.confirm_form,
    },
    {
      label: 'Submitted',
      link: ROUTES.submitted,
    },
  ],
  sections: [
    {
      title: 'Contact Details',
      type: 'contact_details',
      newProspectTitle: 'Required Prospect Details',
      newProspectSubtitle: 'Please enter the following information to create a new prospect.',
      tooltip: 'Please tell us about your business.',
      fields: [
        {
          type: 'text',
          name: 'first_name',
          table: 'user',
          label: 'First Name',
          placeholder: 'First Name',
          lenderRequired: true,
          required: true,
        },
        {
          type: 'text',
          name: 'last_name',
          table: 'user',
          label: 'Last Name',
          placeholder: 'Last Name',
          lenderRequired: true,
          required: true,
        },
        {
          type: 'email',
          name: 'email_address',
          table: 'user',
          label: 'Email',
          placeholder: 'Email',
          lenderRequired: true,
          required: true,
        },
        {
          type: 'text',
          name: 'title',
          table: 'user',
          label: 'Title',
          placeholder: 'Title',
          lenderRequired: true,
          required: true,
        },
        {
          type: 'tel',
          name: 'phone_number',
          table: 'user',
          label: 'Cell Phone',
          placeholder: 'Cell Phone',
          lenderRequired: true,
          required: true,
          hideForPrefillForm: false,
        },
        {
          type: 'text',
          name: 'business_name',
          table: 'business',
          placeholder: 'Legal Company Name',
          label: 'Legal Company Name',
          required: true,
          lenderRequired: true,
        },
        {
          type: 'text',
          name: 'dba',
          table: 'business',
          placeholder: 'Other Business Names (DBA)',
          label: 'Other Business Names (DBA)',
          required: false,
        },
        {
          type: 'text',
          name: 'business_website',
          table: 'business',
          placeholder: 'Website',
          label: 'Website',
          required: false,
        },
        {
          type: 'tel',
          name: 'business_phone_number',
          table: 'business',
          placeholder: 'Business Phone',
          label: 'Business Phone',
          lenderRequired: true,
          required: true,
        },
        [
          {
            type: 'text',
            name: 'address_street',
            table: 'business',
            placeholder: 'Street',
            label: 'Business Address',
            required: true,
          },
          {
            type: 'text',
            name: 'address_city',
            table: 'business',
            placeholder: 'City',
            label: 'City',
            required: true,
          },
          {
            type: 'text',
            name: 'address_state',
            table: 'business',
            placeholder: 'State',
            label: 'State',
            required: true,
          },
          {
            type: 'text',
            name: 'address_zip_code',
            table: 'business',
            placeholder: 'Zip Code',
            label: 'Zip Code',
            required: true,
          },
        ],
        {
          type: 'select',
          name: 'business_entity',
          table: 'business',
          placeholder: 'Legal Form of Business',
          label: 'Legal Form of Business',
          options: BUSINESS_ENTITY_OPTIONS,
          required: false,
        },
        {
          type: 'number',
          name: 'years_in_business',
          table: 'business',
          label: 'Years in Business',
          placeholder: 'Years in Business',
          min: 0,
          lenderRequired: false,
          required: false,
        },
        {
          type: 'region',
          name: 'state_of_incorporation',
          table: 'business',
          label: 'State of Incorporation',
          placeholder: 'State of Incorporation',
          required: false,
        },
        {
          type: 'date',
          name: 'date_business_started',
          table: 'business',
          label: 'Date Founded/Incorporated',
          max: todayIsoDate,
          required: false,
        },
        {
          type: 'text',
          name: 'state_organization_number',
          table: 'additional_business_data',
          label: 'State Organization No.',
          placeholder: 'State Organization No.',
        },
        {
          type: 'business_ein',
          name: 'business_ein_last4',
          table: 'business',
          label: 'Federal ID #',
          placeholder: 'Federal ID #',
          tooltip:
            'Your Business Tax ID. If forgotten/unknown call IRS Business & Specialty Tax Line at 800-829-4933, https://www.sba.gov/blogs/how-do-i-find-ein-0',
          required: false,
        },
        {
          type: 'radio',
          name: 'has_associated_entities',
          table: 'business',
          label: 'Does the business have a parent company?',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          required: false,
          following_question: {
            condition: true,
            type: 'text',
            name: 'associated_entities_explain',
            table: 'business',
            label: 'Parent companies',
            placeholder: 'Parent companies',
            required: false,
          },
          tooltip: 'List any parent companies, subsidiaries and other affiliated entities.',
        },
        {
          type: 'currency',
          name: 'amount_interest_monthly',
          table: 'additional_business_data',
          label: 'How much capital are you looking for?',
          placeholder: 'How much capital are you looking for?',
          required: false,
        },
        {
          name: 'how_hear_about_us',
          type: 'select',
          options: [
            { value: 'linkedin-facebook', label: 'Linkedin or Facebook' },
            { value: 'colleague-friend', label: 'Colleague or Friend' },
            { value: 'article-press', label: 'Article/Press Release' },
            { value: 'advertisement', label: 'Advertisement' },
            { value: 'google-search', label: 'Google/Search Engine' },
            { value: 'email', label: 'Email' },
            { value: 'conference-tradeshow', label: 'Conference or Tradeshow' },
            { value: 'other', label: 'Other' },
          ],
          label: 'How Did You Hear About Us?',
          table: 'additional_business_data',
          required: false,
        },
        {
          name: 'tell_us_more',
          type: 'textarea',
          table: 'additional_business_data',
          label: 'Your Message',
          required: false,
        },
      ],
    },
    {
      title: 'Principals',
      type: 'business_officers',
      label: 'Business Officer',
      fields: [
        {
          type: 'text',
          name: 'first_name',
          table: 'business_officers',
          label: 'First Name',
          placeholder: 'First Name',
          required: false,
        },
        {
          type: 'text',
          name: 'middle_name',
          table: 'business_officers',
          label: 'Middle Name',
          placeholder: 'Middle Name',
          required: false,
        },
        {
          type: 'text',
          name: 'last_name',
          table: 'business_officers',
          label: 'Last Name',
          placeholder: 'Last Name',
          required: false,
        },
        {
          type: 'email',
          name: 'email_address',
          table: 'business_officers',
          label: 'Email',
          placeholder: 'Email',
          required: false,
        },
        {
          type: 'percentage',
          name: 'pct_ownership',
          table: 'business_officers',
          label: 'Percent Ownership',
          placeholder: 'Percent Ownership',
          required: false,
        },
        {
          type: 'tel',
          name: 'phone_number',
          table: 'business_officers',
          label: 'Cell Phone',
          placeholder: 'Cell Phone',
          required: false,
        },
        {
          type: 'text',
          name: 'title',
          table: 'business_officers',
          label: 'Title',
          placeholder: 'Title',
          required: false,
        },
        {
          type: 'date',
          name: 'dob',
          max: getAllowedDob(18),
          table: 'business_officers',
          label: 'Date of Birth (MM-DD-YYYY)',
          placeholder: 'Date of Birth (MM-DD-YYYY)',
          required: false,
        },
        {
          type: 'text',
          name: 'address',
          table: 'business_officers',
          label: 'Address',
          placeholder: 'Home Address',
          required: false,
        },
        {
          type: 'text',
          name: 'city',
          table: 'business_officers',
          label: 'City',
          placeholder: 'City',
          required: false,
        },
        {
          type: 'text',
          name: 'state',
          table: 'business_officers',
          label: 'State',
          placeholder: 'State',
          required: false,
        },
        {
          type: 'text',
          name: 'zip',
          table: 'business_officers',
          label: 'Zip Code',
          placeholder: 'Zip code',
          required: false,
        },
        {
          type: 'ssn',
          name: 'ssn_last4',
          table: 'business_officers',
          label: 'Social Security #',
          placeholder: 'Social Security #',
          required: false,
        },
        {
          type: 'radio',
          name: 'bankruptcy_history',
          table: 'business_officers',
          label: 'Ever filled for bankruptcy?',
          required: false,
          options: [
            {
              label: 'Yes',
              value: '1',
            },
            {
              label: 'No',
              value: '0',
            },
          ],
          following_question: {
            condition: '1',
            type: 'date',
            max: todayIsoDate,
            required: false,
            name: 'bankruptcy_history_date',
            table: 'business_officers',
            label: 'When?',
          },
        },
        {
          type: 'upload_document',
          name: 'driver_license',
          table: 'deal',
          label: `Copy of Driver's License`,
          required: false,
          options: [
            {
              label: 'Upload file',
              value: 'upload',
            },
            {
              label: 'I will do it later',
              value: 'later',
            },
          ],
        },
        {
          type: 'upload_document',
          name: 'personal_tax_return',
          table: 'deal',
          label: `Latest Filed Personal Tax Return (including Schedule C, if related to business)`,
          required: false,
          options: [
            {
              label: 'Upload file',
              value: 'upload',
            },
            {
              label: 'I will do it later',
              value: 'later',
            },
          ],
        },
      ],
    },
    {
      title: 'Debtors',
      section_title: 'Debtors',
      type: 'largest_customers',
      maxLength: 5,
      addButtonInitialLabel: 'Add Largest 5 Customers +',
      addButtonLabel: 'Add Next Customer +',
      label: 'Debtor',
      required: false,
      fields: [
        {
          type: 'text',
          name: 'debtor_name',
          label: 'Debtor Name',
          table: 'top_business_debtors',
          required: false,
        },
        {
          type: 'text',
          name: 'debtor_city',
          label: 'Debtor City',
          table: 'top_business_debtors',
          required: false,
        },
        {
          type: 'text',
          name: 'debtor_state',
          label: 'Debtor State',
          table: 'top_business_debtors',
          required: false,
        },
      ],
    },
    {
      type: 'upload_documents',
      title: 'Document Details',
      tooltip:
        'Upload the following documents to expedite your application. Document uploads with "I will do it later" are optional.',
      fields: [
        [
          {
            type: 'upload_document',
            name: 'aged_receivables',
            table: 'deal',
            label: 'Aged Receivables',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'aged_payables',
            table: 'deal',
            label: 'Aged Payables',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'profit_and_loss',
            table: 'deal',
            label: 'Profit and Loss',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'balance_sheet',
            table: 'deal',
            label: 'Balance Sheet',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'cash_flow',
            table: 'deal',
            label: 'Cash Flow',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'sales_summary',
            table: 'deal',
            label: 'Sales Summary',
            tooltip: 'Please provide for each of your customers (for multiple files, upload one Zip file)',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'bank_statement',
            table: 'deal',
            label: 'Bank Statement from last month',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'bank_statement_2',
            table: 'deal',
            label: 'Bank Statement from 2 months ago',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'bank_statement_3',
            table: 'deal',
            label: 'Bank Statement from 3 months ago',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'workman_compensation_dec_page',
            table: 'deal',
            label: `Workman's Compensation Dec Page`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'general_liability_dec_page',
            table: 'deal',
            label: `General Liability Dec Page`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'personal_tax_returns',
            table: 'deal',
            label: `Personal tax returns`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'business_tax_returns',
            table: 'deal',
            label: `Business tax returns (including K-1 if applicable)`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'projected_pro_form_statements',
            table: 'deal',
            label: `Projected Pro form Statements over next 3 months`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'personal_financial_statements_for_owners',
            table: 'deal',
            label: `Personal Financial Statements for Owners`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'business_tax_returns_or_ein',
            table: 'deal',
            label: `Business Tax Returns or EIN Verification Letter`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'driver_license',
            table: 'deal',
            label: `Copy of Driver's License`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            // Adding this temporarily
            // Followup task: https://finvoice-inc.atlassian.net/browse/FP-5735
            type: 'upload_document',
            name: 'credit_authorization',
            table: 'deal',
            label: 'Credit Authorization Signature',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
        ],
      ],
    },
  ],
  disclaimer_more: {
    btn_txt: 'Click to read Application Disclosure',
    title: '',
    taggedBody: () => {
      return (
        <>
          <p>
            <b>Instructions</b>
          </p>
          <p>Borrowers Application and Underwriting – Please Read Before Proceeding</p>
          <p>
            First and foremost, we want to thank you for your interest in CapitalPlus Construction Services
            (CapitalPlus). CapitalPlus provides working capital to the construction industry through the purchase of
            eligible invoices thereby getting your money to you quicker while we wait for payment from your client. We
            can provide funding for single invoices on an as need, case-by-case basis (Spot Receivables Financing),
            provide funding for all invoices on a contract (Contract Funding) or ultimately provide you with a
            receivables financing facility (revolver) suited for your cash flow needs for preferred pricing (Volume
            Funding). It is our goal to make this process seamless as possible by clearly communicating with you through
            the entire process. At any point you have questions, please reach out to your Account Executive.
          </p>
          <p>
            <b>THE APPLICATION</b>
          </p>
          <p>
            The application involves the collection of information regarding your company, its owners, general financial
            information and other relevant business and tax documents. The level and complexity of information required
            is largely based on the volume and size of your funding request. You will be asked to designate the size of
            your request early in the process. CapitalPlus will begin underwriting your firm with the ultimate goal of
            entering into a Master Accounts Receivable Purchase and Security Agreement ("Master Agreement"). This Master
            Agreement will signify your approval as a client and will become the governing document regarding the
            lender/borrower relationship. Unless terminated by one of the two parties, this agreement never terminates
            allowing the borrower to bring clients and invoices for purchase at any time in the future. You will never
            be charged additional underwriting fees for the life of the relationship.
          </p>
          <p>
            <b>PROJECT INFORMATION REQUEST</b>
          </p>
          <p>
            You will be provided access to the second phase of the process called the <b>PROJECT INFORMATION REQUEST</b>
            . At this time, you will be required to provide information on the projects you wish to request funding
            including: customer information; project owner information; signed contracts and affiliated change orders or
            modifications; a list of subcontractors and material suppliers for each project; and, ultimately the
            invoices/pay applications to be considered for purchasing. As part of this step, CapitalPlus will underwrite
            each of your clients associated with these projects and will establish a credit limit for each customer.
          </p>
          <p>Please NOTE:</p>
          <p>
            1) The speed of the underwriting process is greatly impacted by the flow of documents. We encourage you to
            read the application and prepare all of your documentation prior to submitting the application itself.
          </p>
          <p>
            2) We do not make contact with your clients at any time during the underwriting unless we have received
            permission from you, our customer. Our only contact is associated with a one time notice of assignment and
            verification of the invoices you wish to purchase.
          </p>
          <p>
            3) Be transparent with your clients. We highly encourage you to contact your clients regarding the desire to
            factor your invoices. It is our experience that discussing this up front with your clients and sharing the
            value of our services greatly improves their cooperation. We will provide you with talking points regarding
            the value if so desired.
          </p>
        </>
      )
    },
  },
  disclaimer:
    'By clicking the below, I acknowledge that I have read the instructions and desire to seek a receivables financing relationship with CapitalPlus Construction Services.',
  agree_btn: true,
}
