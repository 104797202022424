import { Sector } from 'recharts'
import React from 'react'

export const PieChartActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, midAngle, fill }) => {
  const RADIAN = Math.PI / 180
  const offset = 5
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const radius = outerRadius - outerRadius
  const sx = cx + radius * cos
  const sy = cy + radius * sin

  return (
    <Sector
      className={'active-recharts-sector'}
      cx={sx}
      cy={sy}
      endAngle={endAngle}
      fill={fill} // Use the original fill color
      innerRadius={innerRadius}
      outerRadius={outerRadius + offset}
      startAngle={startAngle}
    />
  )
}
