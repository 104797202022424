import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformInvoiceSchedule } from './transform';
async function createInvoiceSchedule({ businessId }) {
    try {
        const response = await request({
            method: 'POST',
            url: `/businesses/${businessId}/invoice_schedules`,
        });
        const data = transformInvoiceSchedule(response);
        return data;
    }
    catch (error) {
        throw new Error('Could not create invoice schedule');
    }
}
export const useCreateInvoiceSchedule = () => {
    return useMutation({
        mutationKey: ['create-invoice-schedule'],
        mutationFn: ({ businessId }) => createInvoiceSchedule({ businessId }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (invoiceSchedule) => {
            notifications.success(`Created invoice schedule ${invoiceSchedule.batchNumber}`);
            queryClient.invalidateQueries({ queryKey: ['invoice-schedules'] });
        },
    });
};
export const useIsInvoiceScheduleCreating = () => {
    const { isPending, isSuccess } = useCreateInvoiceSchedule();
    return isPending || isSuccess;
};
