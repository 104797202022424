import React, { useState, useEffect } from 'react'

// Components
import { FormInput } from '../../FormInput'

// Utils
import { formatCurrencyField, currencyToNum } from '../../../../_helpers'

const updateFromProps = (value, defaultValue) => {
  // Generally these will be uncontrolled
  const isControlled = value !== undefined
  const valueToUse = isControlled ? value : defaultValue
  const newDisplayValue = !String(valueToUse).includes('-')
    ? formatCurrencyField(valueToUse)
    : formatCurrencyField(valueToUse)
  return newDisplayValue
}

export const CurrencyField = (props) => {
  const {
    id = null,
    allowNegative = false,
    allowNull = false,
    defaultValue,
    isIneligible,
    onChange = () => {},
    value,
    ...rest
  } = props
  const [displayValue, setDisplayValue] = useState(updateFromProps(value, defaultValue))

  // Allow us to reset the field when ID changes
  useEffect(() => {
    setDisplayValue(updateFromProps(value, defaultValue))
  }, [id, value, defaultValue])

  // We're generating two values, because some input changes (like typing '0' vs '0.' aren't reflected in numerical value)
  const handleChangeLocal = (e) => {
    let target = e.target
    const newValue = target.value
    let newCursor = target.selectionStart
    let multiplier = 1
    if (allowNegative) {
      if (newValue.includes('-')) {
        multiplier = -1
      }
    }

    // Need to know if we're editing the decimal part because it has
    // special rules.
    const inDecimals = newValue.indexOf('.') !== -1 && newValue.indexOf('.') < newCursor
    // Another special case: delete everything but the $ char
    const onlyDollarSign = newValue === '$'
    // Might need to trim this value so we do pre processing
    const replacedValRaw = newValue
      .replace(/[^\d.]/g, '')
      .replace(/\.\./g, '.')
      .replace(/(\..+)(\.)/g, '$1')

    // If empty space leave it that way
    if (replacedValRaw === '') {
      if (allowNull) {
        setDisplayValue('')
        onChange(null, id)
      } else {
        setDisplayValue('$0')
        onChange(0, id)
      }
      return
    }

    // If editing decimals, we may have a 3 decimal point new number...
    const decimalLength = inDecimals ? replacedValRaw.split('.')[1].length : 0
    // ...if that's the case we trim it down to 2.
    const replacedVal = decimalLength === 3 ? replacedValRaw.slice(0, replacedValRaw.length - 1) : replacedValRaw

    // Now we get the new value as a number and re-format it as currency
    const newNumValue = currencyToNum(replacedVal) * multiplier
    const newDisplayValue = formatCurrencyField(replacedVal)
    if (newDisplayValue === '$0.00' && !inDecimals) {
      // If the initial value is $0.00 it means the field is not null but actually 0.
      // For UX reasons we want any number to overwrite this, so we do this.
      const nonZeroValue = currencyToNum(newValue) * 1000
      setDisplayValue(formatCurrencyField(nonZeroValue))
      onChange(nonZeroValue, id)
      return
    }
    setDisplayValue(newDisplayValue)

    // Lastly, we need to set the cursor where it's supposed to be after typing.
    // This must take into account new chars added by the formatting
    // and also behaves differently if editing the decimals part.
    const charPosition = newDisplayValue.length - newValue.length
    const addedChar = charPosition >= 0 ? charPosition : 1

    setTimeout(() => {
      if (!inDecimals) {
        if (onlyDollarSign) {
          target.selectionStart = target.selectionEnd = newCursor + 1
        } else if (!newValue) {
          // Empty value
          target.selectionStart = target.selectionEnd = newCursor + 2
        } else {
          target.selectionStart = target.selectionEnd = newCursor + addedChar
        }
      } else {
        target.selectionStart = target.selectionEnd = newCursor
      }
    }, 0)

    // Callback
    onChange(newNumValue, id)
  }

  // Class Name
  const className = `currency ${isIneligible ? 'ineligible' : ''} ${props.className ? props.className : ''}`

  return (
    <FormInput
      {...rest}
      autoComplete="off"
      className={className}
      id={id}
      onChange={handleChangeLocal}
      type="text"
      value={displayValue}
    />
  )
}
