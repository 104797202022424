import request from './axios_service'

export const tenantService = {
  getTenant,
}

function getTenant(tenantId) {
  return request({
    url: `/tenants/${tenantId}`,
    method: 'GET',
  })
}
