import styled, { css } from 'styled-components'
import colors from '../../_constants/colors'

const buttonColor = '#e7eaee'
const titleColor = '#383f44'

export const S_ListSearchableView = styled.div`
  margin-top: 30px;
`

export const S_OverViewHead = styled.div`
  display: flex;
  align-items: center;
`

export const S_QuestionRoundedIcon = styled.i`
  width: ${(props) => (props.size === 'sm' ? '15px' : '20px')};
  height: ${(props) => (props.size === 'sm' ? '15px' : '20px')};
  border-radius: 10px;
  letter-spacing: 0;
  font-size: ${(props) => (props.size === 'sm' ? '12px' : '14px')};
  cursor: pointer;
  border: 1px solid ${colors.SUBTITLE_GREY};
  color: ${colors.SUBTITLE_GREY};
  font-weight: 700;
  font-style: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition:
    color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  position: relative;
  z-index: 10;
  margin-left: ${(props) => (props.ml ? props.ml : 0)};
  &:hover {
    color: ${colors.TEXT_DARK};
    border-color: ${colors.TEXT_DARK};
  }
  &:before {
    content: '?';
  }
`

export const S_LoadMoreButton = styled.button`
  border-radius: 3px;
  border: solid 1.5px ${buttonColor};
  color: ${colors.TEXT_PRIMARY};
  cursor: not-allowed;
  display: none;
  font-size: 16px;
  letter-spacing: 1.2px;
  padding: 20px 0;
  text-align: center;
  transition: 0.2s ease-in-out;
  background: transparent;
  width: 100%;

  ${(props) =>
    !props.disabled &&
    css`
      display: block;
      cursor: pointer;
      &:hover {
        background: ${buttonColor};
        color: ${titleColor};
      }
    `}
`

export const S_OverViewWrapper = styled.div`
  transition:
    height 0.2s ease-in-out,
    opacity 0.3s ease-in-out;
  padding-top: 30px;
  height: 0;
  opacity: 0;

  ${(props) =>
    props.showSection &&
    css`
      height: 100%;
      opacity: 1;
    `}
`
