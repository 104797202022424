import { useEffect } from 'react';
import apiKeys from 'apiKeys';
const INTERCOM_APP_ID = apiKeys.intercom;
import Intercom from '@intercom/messenger-js-sdk';
import { useFeatureFlags } from 'xen/hooks';
import { useConfigurationContext, useTenantContext, useUserContext } from 'xen/providers';
export const LiveChat = () => {
    const { intercom: intercomEnabled } = useFeatureFlags();
    const user = useUserContext();
    const tenant = useTenantContext();
    const config = useConfigurationContext();
    useEffect(() => {
        if (!intercomEnabled)
            return;
        if (!INTERCOM_APP_ID)
            return;
        if (!user.emailAddress)
            return;
        if (!user.intercomHash)
            return;
        Intercom({
            app_id: INTERCOM_APP_ID,
            user_id: String(user.id),
            name: user.name,
            email: user.emailAddress,
            created_at: getUnixTimeInSeconds(user.createdAt),
            user_hash: user.intercomHash,
            user_type: user.userType,
            is_admin: user.isAdmin,
            is_sales_person: user.isSalesPerson,
            company: {
                id: tenant.id,
                created_at: getUnixTimeInSeconds(tenant.createdAt),
                name: tenant.token,
                phone: tenant.contactPhone,
                website: config.tenant.branding.urls.homepage,
            },
        });
    }, [user.emailAddress, user.intercomHash, intercomEnabled, INTERCOM_APP_ID]);
    return null;
};
// Intercom requires the date to be in seconds, not milliseconds
// https://www.intercom.com/help/en/articles/3605703-how-dates-work-in-intercom
const getUnixTimeInSeconds = (date) => Math.round(date.getTime() / 1000);
