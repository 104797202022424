import { useIsMutating, useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformInvoiceSchedule, transformInvoiceScheduleParams } from './transform';
async function updateInvoiceSchedule({ urlParams: { scheduleId }, payload, }) {
    try {
        const response = await request({
            method: 'PUT',
            url: `/invoice_schedules/${scheduleId}`,
            data: transformInvoiceScheduleParams(payload),
        });
        const data = transformInvoiceSchedule(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not update invoice schedule');
    }
}
export const useUpdateInvoiceSchedule = ({ urlParams: { scheduleId }, }) => {
    return useMutation({
        mutationKey: ['update-invoice-schedule', scheduleId],
        mutationFn: ({ payload }) => updateInvoiceSchedule({ urlParams: { scheduleId }, payload }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (invoiceSchedule) => {
            notifications.success(`Updated invoice schedule ${invoiceSchedule.batchNumber}`);
            queryClient.invalidateQueries({ queryKey: ['invoice-schedules', invoiceSchedule.id] });
        },
    });
};
export const useIsInvoiceScheduleUpdating = (scheduleId) => {
    return useIsMutating({ mutationKey: ['update-invoice-schedule', scheduleId] });
};
