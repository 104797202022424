import { topBusinessDebtorsConstants } from '../_constants'

export function topBusinessDebtors(state = {}, action) {
  switch (action.type) {
    case topBusinessDebtorsConstants.GET_TOP_BUSINESS_DEBTORS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case topBusinessDebtorsConstants.GET_TOP_BUSINESS_DEBTORS_SUCCESS:
      return {
        data: action.topBusinessDebtors,
      }
    case topBusinessDebtorsConstants.GET_TOP_BUSINESS_DEBTORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case topBusinessDebtorsConstants.ADD_TOP_BUSINESS_DEBTOR:
      return {
        data: state.data.concat({}),
      }

    case topBusinessDebtorsConstants.REMOVE_TOP_BUSINESS_DEBTOR:
      return {
        data:
          action.ordinal == 0
            ? state.data.slice(1)
            : state.data.slice(0, action.ordinal - 1).concat(state.data.slice(action.ordinal)),
      }

    case topBusinessDebtorsConstants.CREATE_BUSINESS_DEBTOR_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case topBusinessDebtorsConstants.CREATE_BUSINESS_DEBTOR_SUCCESS:
      let newData1 = state.data
      newData1[action.index] = action.businessDebtor
      return {
        data: newData1,
      }
    case topBusinessDebtorsConstants.CREATE_BUSINESS_DEBTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case topBusinessDebtorsConstants.UPDATE_DEBTOR_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case topBusinessDebtorsConstants.UPDATE_DEBTOR_SUCCESS:
      let newData2 = state.data
      newData2.find((businessDebtor) => businessDebtor.debtor_id === action.debtorId)[action.attr] = action.val
      return {
        data: newData2,
      }
    case topBusinessDebtorsConstants.UPDATE_DEBTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case topBusinessDebtorsConstants.UPDATE_BUSINESS_DEBTOR_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case topBusinessDebtorsConstants.UPDATE_BUSINESS_DEBTOR_SUCCESS:
      let newData3 = state.data
      newData3.find((businessDebtor) => businessDebtor.id === action.businessDebtorId)[action.attr] = action.val
      return {
        data: newData3,
      }
    case topBusinessDebtorsConstants.UPDATE_BUSINESS_DEBTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case topBusinessDebtorsConstants.DELETE_BUSINESS_DEBTOR_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case topBusinessDebtorsConstants.DELETE_BUSINESS_DEBTOR_SUCCESS:
      return {
        data: state.data.filter((debtor) => debtor.id !== action.businessDebtorId),
        loading: false,
      }
    case topBusinessDebtorsConstants.DELETE_BUSINESS_DEBTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}
