import { searchTermConstants } from '../_constants'

export function searchTerm(state = {}, action) {
  switch (action.type) {
    case searchTermConstants.SET_SEARCH_TERM:
      return {
        searchTerm: action.searchTerm,
      }
    default:
      return state
  }
}
