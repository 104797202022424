import styled from 'styled-components'
import colors from '../../../../../_constants/colors'

export const S_Checkmark = styled.span`
  min-width: 20px;
  min-height: 20px;
  display: inline-block;
  position: relative;

  &:before {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.OUTLINE_GREY_BOX};
    left: 0;
    border-radius: 3px;
    content: '';
    display: inline-block;
    transition: border-color 0.2s ease-in-out;
  }

  &:after {
    box-sizing: border-box;
    content: '';
    position: absolute;
    top: 4px;
    left: 8px;
    width: 6px;
    height: 10px;
    opacity: 0;
    border: solid ${colors.DEFAULT_SECONDARY};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: opacity 0.2s ease-in-out;
  }
`

export const S_CheckboxLabelText = styled.span`
  font-size: 14px;
  color: ${colors.TEXT_DARK};
  margin-left: 10px;
  margin-top: 1px;
`

export const S_Checkbox = styled.label`
  position: relative;
  cursor: pointer;
  display: inline-flex;

  &:hover {
    ${S_Checkmark} {
      &:before {
        border-color: ${colors.TEXT_LIGHT};
      }
    }
  }

  input {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    z-index: -1;

    &:disabled + ${S_Checkmark} {
      opacity: 0.7;
      cursor: default;
      &:before {
        border-color: ${colors.OUTLINE_GREY_BOX};
      }
      & + ${S_CheckboxLabelText} {
        opacity: 0.7;
        cursor: default;
      }
    }

    &:checked + ${S_Checkmark} {
      &:after {
        opacity: 1;
      }
      &:before {
        border-color: ${colors.DEFAULT_SECONDARY};
      }
    }
  }
`
