import React from 'react'
import styled from 'styled-components'

// Constants
import { cssConstants } from '../../_constants/styles'

// Nested Styles
import { S_ContentColumn } from '../../_components/layout/ContentColumn'

const { siteHeaderHeight, pageContentsPadTop } = cssConstants

export const S_PageContents = styled.div`
  display: flex;
  align-items: flex-start; /* necessary for position sticky */
  min-height: calc(100vh - ${siteHeaderHeight});
  position: relative;

  ${S_ContentColumn} {
    flex-grow: 1;
    padding-top: ${pageContentsPadTop};
    padding-bottom: 100px;
  }
`

export const PageContents = ({ children }) => <S_PageContents>{children}</S_PageContents>
