import React, { useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { getPlaidLinkToken, savePlaidPublicToken } from '../../../_services/integration/plaid/plaid.service'
import { useBusiness } from '../../../_reduxHooks/business.hook'
import { LoaderButton } from '../../LoaderButton'
import { PLAID_BTN } from './constants'

export const PlaidLink = ({ isConnected, successCb }) => {
  const { business } = useBusiness()

  const [loading, setLoading] = useState(false)
  const [linkToken, setLinkToken] = useState(null)
  const [showPlaid, setShowPlaid] = useState(false)

  const initPlaid = async () => {
    setLoading(true)
    if (!linkToken) {
      const plaidResponse = await getPlaidLinkToken(business.id)
      setLinkToken(plaidResponse.link_token)
    }
    setShowPlaid(true)
  }
  const buttonText = isConnected ? PLAID_BTN.LINK_CONNECTED : PLAID_BTN.LINK_DISCONNECTED

  return (
    <>
      <LoaderButton disabled={isConnected} isLoading={loading} onClick={() => initPlaid()} text={buttonText} />

      {linkToken ? (
        <PlaidIframe
          business={business}
          linkToken={linkToken}
          setLinkToken={setLinkToken}
          setLoading={setLoading}
          setShowPlaid={setShowPlaid}
          showPlaid={showPlaid}
          successCb={successCb}
        />
      ) : null}
    </>
  )
}

const PlaidIframe = ({ successCb, showPlaid, setLoading, setShowPlaid, setLinkToken, business, linkToken }) => {
  const onSuccess = (public_token) => {
    savePlaidPublicToken(business.id, public_token).then(() => {
      setLoading(false)
      successCb()
    })
  }

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: onSuccess,
    onExit: () => {
      setShowPlaid(false)
      setLoading(false)
      setLinkToken(null)
    },
  })

  useEffect(() => {
    if (ready && showPlaid) {
      open()
    }
  }, [ready, open, showPlaid])

  return null
}
