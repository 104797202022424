import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FaCheck } from 'react-icons/fa'

// Constants
import urls from '../_constants/urls'
import { AuthConfig } from '../_configs'

const MarketingColumnOldUnderwrite = ({ env, tenant }) => {
  const config = AuthConfig[tenant]

  return (
    <div className={`section secondary-section`}>
      {config.common.show_auth_pg_logo && (
        <img
          alt={`${tenant} Auth Logo`}
          className={`auth-logo`}
          src={`${urls.IMAGES}/${env}/${tenant}/auth_pg_logo.png`}
          style={config.common.sign_in_logo_style || {}}
        />
      )}
      <h4 style={config.common.label_invoices_to_cash_style || {}}>{config.common.label_invoices_to_cash}</h4>
      {(config.common.upper_list || config.common.lower_list) && <hr />}
      {config.common.upper_list && (
        <ol className={`upper-list`}>
          {config.common.upper_list.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ol>
      )}
      {config.common.lower_list && (
        <ul className={`lower-list`}>
          {config.common.lower_list.map((item, i) => (
            <li key={i}>
              <FaCheck className={`icon`} />
              {item}
            </li>
          ))}
        </ul>
      )}
      {config.common.contact_info}
      {config.common.link_pdf_application && (
        <p className={`pdf-application`}>
          Please click{' '}
          <a href={config.common.link_pdf_application} target={`_blank`}>
            here
          </a>{' '}
          to download a PDF version of the application
        </p>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  const { env } = state
  return {
    env: env.env,
    tenant: env.tenant,
  }
}

const connectedMarketingColumnOldUnderwrite = withRouter(connect(mapStateToProps)(MarketingColumnOldUnderwrite))

export { connectedMarketingColumnOldUnderwrite as MarketingColumnOldUnderwrite }
