export const transformDebtorResponse = (data) => {
    return {
        arBalance: parseFloat(data.ar_balance),
        concentration: parseFloat(data.concentration),
        createdAt: new Date(data.created_at),
        dataSource: data.data_source,
        dataSourceRank: data.data_source_rank ? parseFloat(data.data_source_rank) : null,
        debtorAddressStreet: data.debtor_address_street,
        debtorAddressUnit: data.debtor_address_unit,
        debtorCity: data.debtor_city,
        debtorCountry: data.debtor_country,
        debtorDescription: data.debtor_description,
        debtorDisplayName: data.debtor_display_name,
        debtorFaxNumber: data.debtor_fax_number,
        debtorIndustry: data.debtor_industry,
        debtorName: data.debtor_name,
        debtorNameLookupString: data.debtor_name_lookup_string,
        debtorPhoneNumber: data.debtor_phone_number,
        debtorRevenue: data.debtor_revenue ? parseFloat(data.debtor_revenue) : null,
        debtorState: data.debtor_state,
        debtorWebsite: data.debtor_website,
        debtorZip: data.debtor_zip,
        displayName: data.display_name,
        id: data.id,
        ineligible: parseFloat(data.ineligible),
        limitAvailability: parseFloat(data.limit_availability),
        netDilution: parseFloat(data.net_dilution),
        overallConcentrationLimit: parseFloat(data.overall_concentration_limit),
        overallCreditLimit: parseFloat(data.overall_credit_limit),
        parentDebtorId: data.parent_debtor_id,
        tenantId: data.tenant_id,
        unknown: !!data.unknown,
        updatedAt: new Date(data.updated_at),
    };
};
export const transformDebtorSearchResponse = (data) => {
    const response = data
        .map((item) => ({
        id: item.id,
        debtorName: item.debtor_name,
    }))
        .sort((a, b) => a.debtorName.localeCompare(b.debtorName));
    return response;
};
export const transformDebtorContactResponse = (data) => {
    return {
        clients: data.clients,
        createdAt: new Date(data.created_at),
        debtorId: data.debtor_id,
        email: data.email,
        firstName: data.first_name,
        lastName: data.last_name,
        id: data.id,
        tenantId: data.tenant_id,
        title: data.title,
        updatedAt: new Date(data.updated_at),
    };
};
