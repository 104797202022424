import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformDocumentResponse, transformUploadDocumentToFormData } from 'xen/api/use-document/transforms';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
async function createInvoiceDocument({ urlParams, payload }) {
    const { invoiceId } = urlParams;
    const { file } = payload;
    try {
        const response = await request({
            method: 'POST',
            url: `/documents/upload_invoice_supporting_document/${invoiceId}`,
            data: transformUploadDocumentToFormData(file),
        });
        const data = transformDocumentResponse(response);
        return data;
    }
    catch (_error) {
        throw Error(`Could not upload ${file.name} to invoice`);
    }
}
export const useCreateInvoiceDocument = ({ urlParams }) => {
    const { invoiceId } = urlParams;
    return useMutation({
        mutationKey: ['create-invoice-document', invoiceId],
        mutationFn: ({ payload }) => createInvoiceDocument({ payload, urlParams: { invoiceId } }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (document) => {
            notifications.success(`Uploaded ${document.documentName} to invoice`);
            queryClient.invalidateQueries({ queryKey: ['invoices', invoiceId] });
        },
    });
};
