import moment from 'moment'
import isNil from 'lodash/isNil'

class DateFormatter {
  constructor(date_time) {
    const dateTime = new moment.parseZone(date_time).utc(true)
    this.time = dateTime.format('hh:mm:ss A')
    this.date = dateTime.format('M/DD/YYYY')
    this.dateTime = dateTime
  }

  getTime(format) {
    if (isNil(format)) {
      return this.time
    }
    return this.dateTime.format(format)
  }

  getDate(format) {
    if (isNil(format)) {
      return this.date
    }
    return this.dateTime.format(format)
  }
}

export default DateFormatter
