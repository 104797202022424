import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled, RestrictedAccess } from 'xen/components';
import { useAuthentication, useFeatureFlags } from 'xen/hooks';
const EnrollmentSignUp = lazy(() => import('../pages/enrollment-sign-up'));
const EnrollmentVerify = lazy(() => import('../pages/enrollment-verify'));
const EnrollmentWelcome = lazy(() => import('../pages/enrollment-welcome'));
export const ENROLLMENTS_PATHS = {
    signUp: '/enrollments/sign-up',
    verify: '/enrollments/verify',
    welcome: '/enrollments/welcome',
};
export const EnrollmentsRoutes = () => {
    const { signUp, verify, welcome } = ENROLLMENTS_PATHS;
    const { tenantEnrollment } = useFeatureFlags();
    const { isLoggedIn } = useAuthentication();
    const SignUpRoute = (_jsx(Route, { exact: true, path: signUp, children: () => {
            if (!tenantEnrollment)
                return _jsx(FeatureNotEnabled, { feature: "Tenant Enrollment" });
            return _jsx(EnrollmentSignUp, {});
        } }, signUp));
    const VerifyRoute = (_jsx(Route, { exact: true, path: verify, children: () => {
            if (!tenantEnrollment)
                return _jsx(FeatureNotEnabled, { feature: "Tenant Enrollment" });
            return _jsx(EnrollmentVerify, {});
        } }, verify));
    const WelcomeRoute = (_jsx(Route, { exact: true, path: welcome, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(EnrollmentWelcome, {}) }) }, welcome));
    // If not logged in, only show the sign-up and verify routes
    if (!isLoggedIn)
        return [SignUpRoute, VerifyRoute];
    return [SignUpRoute, VerifyRoute, WelcomeRoute];
};
