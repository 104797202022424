import styled from 'styled-components'

// CONSTANTS

// Components
import { SocialIconRow } from '../SocialIcons'

export const FooterBar = styled.footer`
  background-color: ${(props) => props.theme.colors.primary};
  text-align: center;

  .footer-main {
    display: flex;
    margin-top: auto;
    padding: 1.25rem;
    justify-items: center;
    align-items: center;
    flex-wrap: nowrap;
    border-top-color: ${(props) => props.theme?.colors?.line || props.theme.colors.primary_button}
    border-top-style: solid;
    border-top-width: 1px;

    .si-container {
      margin-left: auto;
      @media screen and (max-width: 700px) {
        margin-left: 0em;
      }
    }

    @media screen and (min-width: 700px) {
      grid-template-columns: max-content auto max-content;
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  .rows {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .row {
      display: flex;
      align-self: stretch;
      @media screen and (min-width: 700px) {
        align-self: stretch;
      }
    }
    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  ${SocialIconRow} {
    margin-top: 0;
    @media screen and (max-width: 700px) {
      margin-top: 1em;
    }
  }

  a,
  .link {
    color: ${(props) => props.theme.colors.footer_header};
    display: flex;
    margin-left: 1rem;
    align-self: stretch;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: all 0.3s ease-in-out;
    @media screen and (max-width: 700px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .hover-effect:hover {
    opacity: 0.5;
  }

  @media print {
    display: none;
  }
`

export const FooterLogo = styled.img`
  max-height: 1.875rem;
  width: auto;
  margin-right: 1.25rem;
`
