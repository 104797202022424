import React from 'react'
import { FaEnvelope } from 'react-icons/fa'

import { ConfigProxy } from '../_helpers/util'
import TrustPilotImg from '../assets/img/trustpilot.png'

export const headerConfig = ConfigProxy(
  {
    finvoice: [
      {
        type: 'phone',
        label: '310-951-0596',
        link: 'tel:3109510596',
      },
      {
        type: 'email',
        label: 'support@xenplatforms.com',
        link: 'mailto:support@xenplatforms.com',
        icon: <FaEnvelope className={`icon _mail-icon`} />,
      },
    ],
    demo: [
      {
        type: 'phone',
        label: '310-951-0596',
        link: 'tel:3109510596',
      },
      {
        type: 'email',
        label: 'support@xenplatforms.com',
        link: 'mailto:support@xenplatforms.com',
        icon: <FaEnvelope className={`icon _mail-icon`} />,
      },
    ],
    capitalnow: [
      {
        type: 'phone',
        label: '403-617-2075 🇨🇦',
        link: 'tel:4036172075',
      },
      {
        type: 'phone',
        label: '512-858-8806 🇺🇸',
        link: 'tel:5128588806',
      },
    ],
    sevenoaks: [
      {
        type: 'phone',
        label: '800-511-4588',
        link: 'tel:8005114588',
      },
    ],
    boldbusinesscapital: [
      {
        type: 'phone',
        label: '844-770-7775',
        link: 'tel:8447707775',
      },
    ],
    twelvefive: [
      {
        type: 'phone',
        label: '630-270-3072',
        link: 'tel:6302703072',
      },
    ],
    sallyportcf: [
      {
        type: 'phone',
        label: '832-939-9500',
        link: 'tel:8329399500',
      },
    ],
    marcocapital: [
      {
        type: 'phone',
        label: '(917) 525-3695',
        link: 'tel:9175253695',
      },
    ],
    marcofi: [
      {
        type: 'phone',
        label: '(917) 525-3695',
        link: 'tel:9175253695',
      },
    ],
    portercapital: [
      {
        type: 'imglink',
        image: TrustPilotImg,
        label: 'Review us on Trust Pilot',
        link: 'https://www.trustpilot.com/review/www.portercap.net',
      },
      {
        type: 'phone',
        label: '800-737-7344',
        link: 'tel:8007377344',
      },
    ],
    newcenturyfinancial: [
      {
        type: 'phone',
        label: '713-840-1600',
        link: 'tel:7138401600',
      },
    ],
    scfactoringsolution: [
      {
        type: 'phone',
        label: '754-301-8813',
        link: 'tel:7543018813',
      },
    ],
    chartercapitalusa: [],
    acsfactors: [
      {
        type: 'phone',
        label: '909-946-5599',
        link: 'tel:9099465599',
      },
    ],
    ccbg: [
      {
        type: 'phone',
        label: '850-402-7537',
        link: 'tel:8504027537',
      },
    ],
    hedayacapital: [
      {
        type: 'phone',
        label: '(212) 233-0044',
        link: 'tel:2122330044',
      },
    ],
    republicbc: [
      {
        type: 'phone',
        label: '866-722-4987',
        link: 'tel:18667224987',
      },
    ],
    newbridgeglobal: [
      {
        type: 'phone',
        label: '646-595-1170',
        link: 'tel:6465951170',
      },
    ],
    axosbank: [
      {
        type: 'phone',
        label: '(877) 247-7990',
        link: 'tel:8772477990',
      },
    ],
    factoringdemo: [
      {
        type: 'phone',
        label: '310-951-0596',
        link: 'tel:3109510596',
      },
    ],
    abldemo: [
      {
        type: 'phone',
        label: '310-951-0596',
        link: 'tel:3109510596',
      },
    ],
    prodemo: [
      {
        type: 'phone',
        label: '310-951-0596',
        link: 'tel:3109510596',
      },
    ],
    ctrlpay: [
      {
        type: 'phone',
        label: '1-800-375-5056',
        link: 'tel:+18003755056',
      },
    ],
    breakoutfinance: [
      {
        label: '888-318-3534',
        link: 'tel:8883183534',
        type: 'phone',
      },
    ],
    fastarfunding: [
      {
        label: '888-833-2286',
        link: 'tel:8888332286',
        type: 'phone',
      },
    ],
    capitalplus: [
      {
        label: '+1 (865) 670-2345',
        link: 'tel:8656702345',
        type: 'phone',
      },
    ],
    capitalplusfinancial: [
      {
        label: '+1 (865) 670-2345',
        link: 'tel:8656702345',
        type: 'phone',
      },
    ],
    changecapital: [
      {
        label: '+1 (646) 434-0900',
        link: 'tel:6464340900',
        type: 'phone',
      },
      {
        type: 'email',
        label: 'info@change.capital',
        link: 'mailto:info@change.capital',
        icon: <FaEnvelope className={`icon _mail-icon`} />,
      },
    ],
    fundient: [
      {
        label: '(202) 421-5974',
        link: 'tel:(202)4215974',
        type: 'phone',
      },
    ],
    velocityfi: [],
    interportcapital: [
      {
        label: '+1 (516) 405-0375',
        link: 'tel:(516) 405-0375',
        type: 'phone',
      },
    ],
  },
  []
)
