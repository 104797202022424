import React, { lazy } from 'react'
import { Route, Redirect } from 'react-router-dom'

// Constants
import ROUTES, { LENDER_ROUTES } from '../_constants/routes'

// Helpers
import { isLender } from '../_helpers/user-utils'
import { isProductEnabled } from '../_helpers/flags'

export const LenderRoutes = ({ loggedIn, user, featureFlags }) => {
  // Only for lenders
  if (!isLender(loggedIn, user.user_type)) {
    return []
  }
  if (!user) {
    return []
  }

  let loadedModules = {}
  const pageComponents = {
    LenderHomePage: import('../_views/LenderViews/LenderHome/index.js').then((m) => {
      loadedModules['LenderHomePage'] = m
      return m
    }),
    ClientsOverview: import('../_views/LenderViews/ClientsOverview/index.js').then((m) => {
      loadedModules['ClientsOverview'] = m
      return m
    }),
    ClientDetails: import('../_views/LenderViews/ClientDetails/index.js').then((m) => {
      loadedModules['ClientDetails'] = m
      return m
    }),
    UserAlerts: import('../_views/LenderViews/UserAlerts/index.js').then((m) => {
      loadedModules['UserAlerts'] = m
      return m
    }),
  }

  if (loggedIn && user.is_admin) {
    pageComponents.Admin = import('../_views/LenderViews/TenantAdmin/index.js').then((m) => {
      loadedModules['Admin'] = m
      return m
    })
  }

  const lenderRoutes = [
    // Generic Lender Dashboard
    {
      path: ROUTES.lender_home,
      Component: isProductEnabled('Operate', featureFlags)
        ? lazy(() => pageComponents['LenderHomePage'])
        : () => <Redirect to={LENDER_ROUTES.prospects_overview} />,
      module: 'LenderHomePage',
    },
    // Clients
    {
      path: LENDER_ROUTES.clients_overview,
      Component: lazy(() => pageComponents['ClientsOverview']),
      exact: true,
      module: 'ClientsOverview',
    },
    {
      path: LENDER_ROUTES.clients_overview + '/:businessId',
      Component: lazy(() => pageComponents['ClientDetails']),
      module: 'ClientDetails',
    },
  ]

  if (loggedIn && user.is_admin) {
    lenderRoutes.push({
      path: LENDER_ROUTES.admin,
      Component: lazy(() => pageComponents['Admin']),
      module: 'Admin',
    })
  }
  return lenderRoutes.map(({ path, Component, exact = false, module }) => (
    // Butt ugly, but this prevents the suspense fallback component from displaying unnecessicarily
    <Route
      exact={exact}
      key={path}
      path={path}
      render={(props) => {
        if (module && loadedModules[module]) {
          //Set status to resolved and result to default export of downloaded module to prevent briefly loading suspense component. See https://github.com/facebook/react/blob/master/packages/react/src/ReactLazy.js
          Component._status = 1
          Component._result = loadedModules[module].default
        }
        return <Component {...props} />
      }}
    />
  ))
}
