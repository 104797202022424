import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformDocumentResponse, transformUploadDocumentToFormData } from 'xen/api/use-document/transforms';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
async function createInvoiceScheduleDocument({ urlParams, payload, }) {
    const { scheduleId } = urlParams;
    const { file } = payload;
    try {
        const response = await request({
            method: 'POST',
            url: `/documents/upload_invoice_schedule_document/${scheduleId}`,
            data: transformUploadDocumentToFormData(file),
        });
        const data = transformDocumentResponse(response);
        return data;
    }
    catch (_error) {
        throw Error(`Could not upload ${file.name} to invoice schedule`);
    }
}
export const useCreateInvoiceScheduleDocument = ({ urlParams, }) => {
    const { scheduleId } = urlParams;
    return useMutation({
        mutationKey: ['create-invoice-schedule-document', scheduleId],
        mutationFn: ({ payload }) => createInvoiceScheduleDocument({ payload, urlParams: { scheduleId } }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (document) => {
            notifications.success(`Uploaded ${document.documentName} to invoice schedule`);
            queryClient.invalidateQueries({ queryKey: ['invoice-schedules', scheduleId] });
        },
    });
};
