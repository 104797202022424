import { businessConstants } from '../_constants'
import { businessService } from '../_services'
import {
  additionalBusinessDataActions,
  topBusinessDebtorsActions,
  dealActions,
  businessOfficersActions,
  bankActions,
  plaidActions,
} from './'
import { bankConstants } from '../_constants/bank.constants'
import { businessBankAccountActions } from './businessBankAccount.actions'
import { FINANCING_TYPE_FACTORING } from '../_constants/financingTypes'

export const businessActions = {
  getBusiness,
  setBusiness,
  updateBusiness,
}

function getBusiness(businessId, operate_enabled) {
  return (dispatch) => {
    dispatch(request())
    return businessService
      .getBusiness(businessId)
      .then((business) => {
        dispatch(success(business))
        if (!business.is_client || !operate_enabled) {
          return Promise.all([
            dispatch(additionalBusinessDataActions.getAdditionalBusinessData(business.id)),
            dispatch(topBusinessDebtorsActions.getTopBusinessDebtors(business.id)),
            dispatch(dealActions.getDeal(business.id)),
            dispatch(businessOfficersActions.getBusinessOfficers(business.id)),
            dispatch(businessBankAccountActions.getBusinessBankAccount(business.id)),
            dispatch(
              bankActions.setBankConnection(
                business[bankConstants.BUSINESS_FLAG_NAME] === bankConstants.BANK_CONNECTED_TO_BUSINESS_FLAG
              )
            ),
          ])
        }
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: businessConstants.GET_BUSINESS_REQUEST }
  }
  function success(business) {
    return { type: businessConstants.GET_BUSINESS_SUCCESS, business }
  }
  function failure(error) {
    return { type: businessConstants.GET_BUSINESS_FAILURE, error }
  }
}

function setBusiness(business, operate_enabled, financing_type = FINANCING_TYPE_FACTORING) {
  return (dispatch) => {
    dispatch(success(business))
    const isFactoringBusiness = financing_type === FINANCING_TYPE_FACTORING

    if ((!business.is_client || !operate_enabled) && isFactoringBusiness) {
      return Promise.all([
        dispatch(additionalBusinessDataActions.getAdditionalBusinessData(business.id)),
        dispatch(topBusinessDebtorsActions.getTopBusinessDebtors(business.id)),
        dispatch(dealActions.getDeal(business.id)),
        dispatch(businessOfficersActions.getBusinessOfficers(business.id)),
        dispatch(businessBankAccountActions.getBusinessBankAccount(business.id)),
        dispatch(plaidActions.checkPlaidStatus(business.id)),
        dispatch(
          bankActions.setBankConnection(
            business[bankConstants.BUSINESS_FLAG_NAME] === bankConstants.BANK_CONNECTED_TO_BUSINESS_FLAG
          )
        ),
      ])
    } else if (business.is_client) {
      return dispatch(plaidActions.checkPlaidStatus(business.id))
    }
  }

  function success(business) {
    return { type: businessConstants.GET_BUSINESS_SUCCESS, business }
  }
}

function updateBusiness(attr, val, businessId) {
  return (dispatch) => {
    dispatch(request(attr, val))
    return businessService
      .updateBusiness(attr, val, businessId)
      .then((business) => dispatch(success(business)))
      .catch((error) => dispatch(failure(error)))
  }

  function request(attr, val) {
    return { type: businessConstants.UPDATE_BUSINESS_REQUEST, attr, val }
  }
  function success(business) {
    return { type: businessConstants.UPDATE_BUSINESS_SUCCESS, business }
  }
  function failure(error) {
    return { type: businessConstants.UPDATE_BUSINESS_FAILURE, error }
  }
}
