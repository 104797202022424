import { userConstants } from '../_constants'

export function user(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return { loading: true }
    case userConstants.GET_USER_SUCCESS:
      return action.user
    case userConstants.GET_USER_FAILURE:
      return { error: action.error }

    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
          attr: action.attr,
          val: action.val,
        },
      }
    case userConstants.UPDATE_USER_SUCCESS:
      return action.user
    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        action: {
          loading: false,
          error: action.error,
        },
      }
    default:
      return state
  }
}
