import request from './axios_service'

import { formatDebtor, debtorToSystemTime } from '../_helpers'

export const topBusinessDebtorsService = {
  getTopBusinessDebtors,
  createBusinessDebtor,
  updateDebtor,
  updateBusinessDebtor,
  updateBusinessDebtorBatch,
  updateDebtorBatch,
  removeBusinessDebtor,
  getAllBusinessDebtors,
  getBusinessDebtorsCount,
  createUnderwriteBusinessDebtor,
  getUnderwriteBusinessDebtors,
}

function getTopBusinessDebtors(businessId) {
  return request({
    url: `/business_debtors/get_top_three/${businessId}`,
    method: 'GET',
  })
}

function getAllBusinessDebtors(searchParam) {
  return request(
    {
      url: `/business_debtors?${searchParam}`,
      method: 'GET',
    },
    null,
    true
  ).then((debtors) => {
    const formattedDebtors = debtors.data.map(debtorToSystemTime)
    return {
      data: formattedDebtors,
      count: debtors.headers['total-count'],
    }
  })
}

function getBusinessDebtorsCount(searchParam = '') {
  return request({
    url: `/business_debtors/count?${searchParam}`,
    method: 'GET',
  })
}

function createBusinessDebtor(debtorName, businessId, index) {
  const { debtor_name } = formatDebtor({ debtor_name: debtorName })
  return request({
    url: `/business_debtors`,
    method: 'POST',
    data: {
      debtor_name: debtor_name,
      business_id: businessId,
      data_source: `user_input`,
      top_three: index + 1,
    },
  })
}

function updateDebtor(attr, val, debtorId) {
  const formatDebtorVal = formatDebtor({ [attr]: val })[attr]
  if (typeof attr === 'string') {
    return request({
      url: `/debtors/${debtorId}`,
      method: 'PUT',
      data: {
        debtor: {
          [attr]: formatDebtorVal,
        },
      },
    })
  } else {
    return request({
      url: `/debtors/${debtorId}`,
      method: 'PUT',
      data: {
        debtor: attr,
      },
    })
  }
}

function updateDebtorBatch(data, debtorId) {
  let { debtor_name, ...filteredData } = data

  return request({
    url: `/debtors/${debtorId}`,
    method: 'PUT',
    data: { debtor: filteredData },
  })
}

function updateBusinessDebtor(attr, val, businessDebtorId) {
  return request({
    url: `/business_debtors/${businessDebtorId}`,
    method: 'PUT',
    data: {
      [attr]: val,
    },
  })
}

function updateBusinessDebtorBatch(data, businessDebtorId) {
  return request({
    url: `/business_debtors/${businessDebtorId}`,
    method: 'PUT',
    data: { business_debtor: data },
  })
}

function removeBusinessDebtor(businessId, businessDebtorId) {
  return request({
    url: `/businesses/${businessId}/debtors/${businessDebtorId}`,
    method: 'DELETE',
  })
}

function createUnderwriteBusinessDebtor(businessId, data, debtor_name) {
  const { debtor_name: formatDebtorName } = formatDebtor({ debtor_name })

  return request({
    url: `/businesses/${businessId}/debtors`,
    method: 'POST',
    data: {
      business_debtor: data,
      debtor_name: formatDebtorName,
    },
  })
}

function getUnderwriteBusinessDebtors(businessId) {
  return request({
    url: `/businesses/${businessId}/debtors`,
    method: 'GET',
  })
}
