import { plaidConstants } from '../../_constants/integration/plaid.constants'
import { getPlaidApplicationAccess } from '../../_services/integration/plaid/plaid.service'
export const plaidActions = {
  connectPlaidBank,
  checkPlaidStatus,
  disconnectPlaidBank,
}

function checkPlaidStatus(businessId) {
  return (dispatch) => {
    return getPlaidApplicationAccess(businessId)
      .then((response) => {
        const plaidConnected = response?.length
        plaidConnected ? dispatch(connectionSuccess()) : dispatch(connectionFailure())
      })
      .catch(() => dispatch(connectionFailure()))
  }
}

function connectPlaidBank(value) {
  return (dispatch) => {
    if (value === plaidConstants.STATUS_CONNECT) {
      dispatch(connectionSuccess())
    } else {
      dispatch(connectionFailure())
    }
  }
}

function disconnectPlaidBank(value) {
  return (dispatch) => {
    if (value === plaidConstants.STATUS_NOT_CONNECT) {
      dispatch(disconnectSuccess())
    }
  }
}

function connectionFailure() {
  return { type: plaidConstants.CONNECT_PLAID_FAILURE }
}
function connectionSuccess() {
  return { type: plaidConstants.CONNECT_PLAID_SUCCESS }
}

function disconnectSuccess() {
  return { type: plaidConstants.DISCONNECT_PLAID }
}
