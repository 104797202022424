import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from 'xen-ui';
import { useUpdateInvoiceScheduleSubmission } from 'xen/api/use-invoice-schedule';
export const InvoiceScheduleSubmitButton = ({ children = 'Submit Invoice Schedule', invoiceSchedule: { id: scheduleId }, ...props }) => {
    const { mutateAsync: updateScheduleSubmission, isPending } = useUpdateInvoiceScheduleSubmission({
        urlParams: { scheduleId },
    });
    const handleSubmitSchedule = async () => {
        updateScheduleSubmission();
    };
    return (_jsx(Button, { loading: isPending, onClick: handleSubmitSchedule, ...props, children: children }));
};
