import React from 'react'
import { S_Skeleton } from './Skeleton.styles'
import PropTypes from 'prop-types'

export const Skeleton = ({ isLoaded, height, variant, width, align }) => {
  if (isLoaded) {
    return null
  }
  return <S_Skeleton align={align} height={height} variant={variant} width={width} />
}

Skeleton.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  isLoaded: PropTypes.bool,
  variant: PropTypes.string,
  align: PropTypes.string,
}

Skeleton.defaultProps = {
  height: '10px',
  width: '100%',
  isLoaded: false,
  variant: '',
  align: 'center',
}
