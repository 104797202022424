import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Heading, Stack, Text } from 'xen-ui';
import { formatCurrency, formatDate } from 'xen/helpers';
import { useGetInvoiceSettlement, useGetPaymentAssignments } from '../../api';
import { PaymentAssignmentCreateForm, PaymentAssignmentUpdateForm, SettlementProcessForm } from '../../components';
export const PaymentAssignments = ({ payment }) => {
    const { data: assignments = [], isLoading: isAssignmentsLoading, isError: isAssignmentsError, } = useGetPaymentAssignments({ collectionsCheckId: String(payment.id) });
    const { data: settlement, isLoading: isSettlementLoading, isError: isSettlementError, } = useGetInvoiceSettlement(String(payment.paymentBatchId));
    if (isAssignmentsLoading || isSettlementLoading)
        return _jsx(Text, { size: "sm", children: "Loading..." });
    if (isAssignmentsError)
        return _jsx(Text, { size: "sm", children: "Error loading payment assignments." });
    if (isSettlementError || !settlement)
        return _jsx(Text, { size: "sm", children: "Error loading invoice settlement." });
    const processedAssignments = assignments.filter((assignment) => assignment.state === 'processed');
    const pendingAssignments = assignments.filter((assignment) => assignment.state === 'pending');
    /**
     * Create array of processed assignments grouped by posting date.
     * ```
     * [
     *  ['1/31/2024', [assignment1, assignment2, ...]
     *  ['2/1/2024', [assignment3, assignment4, ...]
     * ]
     * ```
     */
    const processedAssignmentsByDate = Object.entries(
    // Create object of processed assignments grouped by posting date.
    // {
    //   '1/31/2024': [assignment1, assignment2, ...],
    //   '2/1/2024': [assignment3, assignment4, ...]
    // }
    processedAssignments.reduce((acc, assignment) => {
        // Format Date as string e.g. '1/31/2024'
        // settlement.datePosted should always be present when there are processed assignments
        const settlementDate = settlement.datePosted;
        const assignmentDate = assignment.postingDate;
        const dateString = formatDate.localeDateString(assignmentDate ?? settlementDate);
        // If no key exists for date
        if (acc[dateString] === undefined) {
            // create an empty array assigned to date
            acc[dateString] = [];
        }
        // Push assignment to array assigned to date
        ;
        acc[dateString].push(assignment);
        return acc;
    }, {})).sort(([dateStringA], [dateStringB]) => {
        // Sort chronologically, with earliest date first
        return new Date(dateStringA).getTime() - new Date(dateStringB).getTime();
    });
    const unappliedCash = payment.amount ? payment.amount - payment.amountPosted : 0;
    const settlementHasNoErrors = Object.keys(settlement.errors).length === 0;
    return (_jsxs(Stack, { gap: "xl", children: [_jsx(PaymentAssignmentCreateForm, { payment: payment }), assignments.length === 0 ? (_jsx(Text, { size: "sm", children: "No invoices are assigned to this payment." })) : (processedAssignmentsByDate.map(([date, processedAssignments]) => {
                return (_jsxs(Stack, { gap: "xs", children: [_jsxs(Heading, { level: 4, size: "h6", children: ["Invoices processed on ", date] }), _jsx(Divider, {}), processedAssignments.map((assignment, index) => {
                            return _jsx(PaymentAssignmentUpdateForm, { assignment: assignment, index: index }, assignment.id);
                        })] }, date));
            })), pendingAssignments.length > 0 && (_jsxs(Stack, { gap: "xs", children: [_jsx(Heading, { level: 4, size: "h6", children: "Pending Invoices" }), _jsx(Divider, {}), pendingAssignments.map((assignment, index) => {
                        return _jsx(PaymentAssignmentUpdateForm, { assignment: assignment, index: index }, assignment.id);
                    }), _jsxs(Stack, { align: "flex-end", gap: "xs", children: [settlement.state === 'partially_processed' && (_jsx(SettlementProcessForm, { formButtons: ['process'], settlement: settlement })), settlementHasNoErrors && unappliedCash > 0 && (_jsxs(Text, { size: "xs", children: ["Payment will be processed with ", _jsx("b", { children: formatCurrency(unappliedCash) }), " in unapplied cash."] }))] })] }))] }));
};
