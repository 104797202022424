import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { queryKeyFactory } from './query-keys-factory';
import { transformPaymentResponseToPayment } from './transform';
async function getPayments(params = {}) {
    try {
        const response = await request({
            url: '/operate/checks',
            params,
            method: 'GET',
        });
        return response.map(transformPaymentResponseToPayment);
    }
    catch (error) {
        throw Error('Error getting payments');
    }
}
export const useGetPayments = (params = {}) => {
    return useQuery({
        queryKey: queryKeyFactory.payments(new URLSearchParams(params).toString()),
        queryFn: () => getPayments(params),
    });
};
