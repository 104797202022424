import { useIsMutating, useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformInvoiceSchedule } from './transform';
async function updateInvoiceScheduleSubmission({ urlParams: { scheduleId }, }) {
    try {
        const response = await request({
            method: 'PUT',
            url: `/invoice_schedules/${scheduleId}/submit`,
        });
        const data = transformInvoiceSchedule(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not submit invoice schedule');
    }
}
export const useUpdateInvoiceScheduleSubmission = ({ urlParams: { scheduleId }, }) => {
    return useMutation({
        mutationKey: ['update-invoice-schedule-submission', scheduleId],
        mutationFn: () => updateInvoiceScheduleSubmission({ urlParams: { scheduleId } }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (invoiceSchedule) => {
            notifications.success(`Submitted invoice schedule ${invoiceSchedule.batchNumber}`);
            queryClient.invalidateQueries({ queryKey: ['invoice-schedules', scheduleId] });
        },
    });
};
export const useIsInvoiceScheduleSubmissionUpdating = (scheduleId) => {
    return useIsMutating({ mutationKey: ['update-invoice-schedule-submission', scheduleId] });
};
