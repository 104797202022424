import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformBusiness } from 'xen/api/use-business/transform';
async function getBusinesses(params) {
    const businessesPayload = params;
    try {
        const response = await request({
            method: 'POST',
            url: `/businesses/search`,
            data: businessesPayload,
        });
        const { results = [] } = response;
        const data = results.map(transformBusiness);
        return data;
    }
    catch (_error) {
        throw Error('Error fetching businesses');
    }
}
export const useGetBusinesses = (params = {}) => {
    return useQuery({
        queryKey: ['businesses', { params }],
        queryFn: () => getBusinesses(params),
    });
};
