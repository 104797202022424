import styled, { css } from 'styled-components'
import { colors } from '../../_constants/colors'

const tableStyles = {
  outerBorder: 'none',
  cellPadding: '10px',
  smCellPadding: '3px',
  footerColor: '#666',
  expandOffset: 15,
}

export const S_TR = styled.div`
  ${(props) => {
    if (props.clickable) {
      return `&:hover{
                background-color: #eee;
                cursor: pointer;
            }`
    } else {
      return ''
    }
  }}
  ${(props) => (props.addtional_styles ? props.addtional_styles : '')}
`
export const S_Table = styled.div`
  ${(props) =>
    props.mobileScroll &&
    css`
      overflow: auto;
    `}

  &.has-expand-columns {
    padding-left: ${tableStyles.expandOffset}px;
  }

  .table {
    overflow-x: auto;
    border-top: 0;
    width: 100%;
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    display: ${(props) => (props.isTableLayout ? 'table' : 'block')};
  }

  .th {
    position: relative;

    &:hover {
      .resizer {
        opacity: 1;
      }
    }

    .resizer {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      display: inline-block;
      width: 10px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;
      touch-action: none;

      &:before,
      &:after {
        content: '';
        top: 20%;
        width: 1px;
        height: 60%;
        position: absolute;
        transition: all linear 100ms;
        background-color: ${colors.BORDER_GREY};
      }

      &:before {
        left: 3px;
      }

      &:after {
        left: 6px;
      }

      &:hover {
        cursor: col-resize;
        &:before,
        &:after {
          height: 80%;
          top: 10%;
        }
      }

      &._active {
        &:before,
        &:after {
          height: 95%;
          top: 2.5%;
          background: ${colors.DEFAULT_SECONDARY};
        }

        &:before {
          left: 4px;
        }
        &:after {
          left: 4px;
        }
      }
    }
  }

  .th,
  .td {
    display: ${(props) => (props.isTableLayout ? 'table-cell' : 'inline-flex')};
    border-bottom: 1px solid ${colors.BORDER_GREY};
  }

  border: ${tableStyles.outerBorder};
  border-radius: 4px;
  box-shadow: none;

  .align-center,
  .align-left,
  .align-right {
    display: flex;
    align-items: center;
  }
  .align-left {
    justify-content: flex-start;
  }

  ._overflow {
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      width: 10px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: linear-gradient(90deg, transparent, #fff);
    }
  }

  .align-right {
    justify-content: flex-end;
  }

  .align-center {
    justify-content: center;
  }
  .hovercolor {
    cursor: pointer;
  }
  .hovercolor:hover {
    background-color: #eee;
  }

  .rowgroup {
    display: ${(props) => (props.isTableLayout ? 'table-row-group' : 'block')};
  }

  .headergroup {
    display: ${(props) => (props.isTableLayout ? 'table-header-group' : 'block')};
  }

  .tr {
    display: ${(props) => (props.isTableLayout ? 'table-row' : 'flex')};
    position: relative;
  }

  .tr {
    &.parent-row-expanded {
      .td {
        border-bottom-width: 0;
      }
    }
    &.child-row {
      .td {
        border-bottom-width: 0;
      }
      & + .tr:not(.child-row) {
        .td {
          border-top: 1px solid ${colors.BORDER_GREY};
        }
      }
    }
  }

  .cell-expander {
    display: inline-block;
    &:hover {
      .table-cell-value {
        &:after {
          border-right-color: ${colors.TEXT_DARK};
          border-top-color: ${colors.TEXT_DARK};
        }
      }
    }
    .table-cell-value {
      padding-right: 15px;
      position: relative;
      &:after {
        top: calc(50% - 3px);
        box-sizing: border-box;
        right: 0;
        position: absolute;
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border-right: 1.5px solid ${colors.TEXT_LIGHT};
        border-top: 1.5px solid ${colors.TEXT_LIGHT};
        transform: rotate(-45deg);
        font-weight: 300;
        transition: all 0.4s ease-in-out;
      }
    }

    &.expanded {
      .table-cell-value {
        &:after {
          top: calc(50% - 5px);
          transform: rotate(135deg);
        }
      }
    }
  }

  .tr:last-child {
    .td {
      border: none;
    }
  }

  ${(props) => {
    if (props.hasTotals) {
      return css`
        .tr:nth-last-child(2) {
          .td {
            border-bottom: 1px solid black;
          }
        }
        .tr:last-child {
          .td:first-child {
            font-weight: bold;
          }
        }
      `
    } else {
      return ''
    }
  }}

  .td,
    .th {
    border-right: none;
    color: ${colors.TEXT_DARK};
    padding: ${tableStyles.cellPadding};
    ${(props) => (props.borderLess ? 'border-bottom:none;' : '')};

    &.expand-cell {
      padding: 0;
      max-width: ${tableStyles.expandOffset}px;
      left: -${tableStyles.expandOffset}px;
      height: 100%;
      position: relative;
      border: none;
    }
  }

  .th {
    font-size: 14px;
    font-weight: 500;
    color: ${colors.TEXT_LIGHT};
  }

  .arrow-container {
    display: inline-block;
    position: relative;
    width: 12px;
    height: 12px;
    margin: 0 4px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      content: ' ';
      border: 5px solid transparent;
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      transition: opacity 0.2s ease-in-out;
    }

    &:after {
      border-bottom-color: ${colors.TEXT_LIGHT};
      border-width: 6px 4px 6px 4px;
      top: -8px;
    }

    &:before {
      border-top-color: ${colors.TEXT_LIGHT};
      border-width: 6px 4px 2px 4px;
      bottom: -2px;
    }

    &.-sort-asc {
      &:after {
        border-bottom-color: ${colors.DEFAULT_TEXT};
      }

      &:before {
        opacity: 0;
      }
    }

    &.-sort-desc {
      &:before {
        border-top-color: ${colors.DEFAULT_TEXT};
      }

      &:after {
        opacity: 0;
      }
    }
  }

  .align-right {
    .arrow-container {
      left: 0;
      right: auto;
    }
  }

  .-pagination {
    box-shadow: none;
    border-top: ${tableStyles.outerBorder};
    padding: ${tableStyles.cellPadding};
    align-items: center;

    .-previous {
      text-align: left;
    }

    .-next {
      text-align: right;
    }

    .-btn {
      display: inline-block;
      background: ${colors.DEFAULT_SECONDARY} !important;
      color: white !important;
      font-size: 0.9em !important;
      transition: all 0.2s ease-in-out !important;
      min-width: 80px;
      width: auto;

      &:not(:disabled) {
        &:hover {
          background: ${colors.DEFAULT_SECONDARY_DARK} !important;
        }
      }
    }

    .-center {
      font-size: 14px;
      text-transform: uppercase;
      color: ${tableStyles.footerColor};
    }

    .-pageJump {
      margin: 0 2px;
      input {
        color: ${tableStyles.footerColor};
      }
    }

    .-pageSizeOptions {
      select {
        height: 29px; /* Match page select */
        color: ${tableStyles.footerColor};
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }

  .table-expand-arrow {
    display: inline-block;
    position: absolute;
    width: 12px;
    height: 12px;
    cursor: pointer;
    top: calc(50% - 6px);
    left: 0;

    &:before {
      content: '';
      position: absolute;
      transform: rotate(-90deg);
      top: 2px;
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      transition: transform 0.2s ease-in-out;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid ${colors.DEFAULT_SECONDARY};
    }

    &.expanded {
      &:before {
        transform: rotate(0);
      }
    }
  }

  &.hide-header {
    .headergroup {
      display: none;
    }
  }

  a.secondary {
    color: ${(props) => props.theme.colors.secondary};
  }

  a.icon {
    color: inherit;
    display: flex;

    &:hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  .td.no-wrap {
    white-space: nowrap;
  }

  .td.full-width {
    width: 100%;
  }
`

// Wrapper under Table component
export const S_ColorTable = styled.div`
  .tr {
    align-items: center;
  }
  .td {
    border: none;
    padding: 5px 12px;
    color: ${colors.TEXT_LIGHT};
    vertical-align: middle;
  }

  .rowgroup {
    ${S_TR}:nth-child(even) {
      background: ${colors.INNER_GREY_BOX};
    }
  }
`
