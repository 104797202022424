import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import ROUTES from '../../_constants/routes'

const PrivateProspectRoute = ({
  component: Component,
  loggedIn,
  isClient,
  user,
  resetPasswordRequired,
  operate_enabled,
  ...rest
}) => {
  let redirectPath
  if (!loggedIn) {
    redirectPath = ROUTES.sign_in
  } else if (resetPasswordRequired) {
    redirectPath = ROUTES.reset_password
  } else if (user.user_type === 'lender') {
    redirectPath = ROUTES.lender_home
  } else if (isClient && operate_enabled) {
    redirectPath = ROUTES.client_home
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        redirectPath ? (
          <Redirect to={{ pathname: redirectPath, state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

function mapStateToProps(state) {
  const { authentication, business, user, tenant } = state
  return {
    loggedIn: authentication.loggedIn,
    isClient: business.is_client,
    operate_enabled: !!tenant.operate_enabled,
    user: user,
    resetPasswordRequired: !!(user && user.reset_password_required),
  }
}

const connectedPrivateProspectRoute = connect(mapStateToProps)(PrivateProspectRoute)
export { connectedPrivateProspectRoute as PrivateProspectRoute }
