import React from 'react'
import { FormField } from '../../FormField'
import { S_CheckboxGroup } from './CheckboxGroup.styles'

export const CheckboxGroup = ({ options, onChange }) => {
  const checkboxes = options.map((option) => {
    const newProps = {
      id: option.id,
      type: 'checkbox',
      key: option.id,
      defaultChecked: option.checked,
      onChange: onChange,
      checkboxLabel: option.label,
    }
    return <FormField {...newProps} key={option.id} />
  })

  return <S_CheckboxGroup>{checkboxes}</S_CheckboxGroup>
}
