import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from 'xen-ui';
import { useDeleteInvoiceScheduleApproval } from 'xen/api/use-invoice-schedule';
export const InvoiceScheduleUnapproveButton = ({ children = 'Unapprove Invoice Schedule', invoiceSchedule: { id: scheduleId }, ...props }) => {
    const { mutateAsync: unapproveInvoiceSchedule, isPending } = useDeleteInvoiceScheduleApproval({
        urlParams: { scheduleId },
    });
    const handleInvoiceScheduleUnapprove = async () => {
        unapproveInvoiceSchedule();
    };
    return (_jsx(Button, { loading: isPending, onClick: handleInvoiceScheduleUnapprove, ...props, children: children }));
};
