import React from 'react'
import styled from 'styled-components'
import colors from '../_constants/colors'

const S_SigningStatusIndicator = styled.div`
  display: flex;
  align-items: center;

  .name {
    color: #aaa;
  }

  .indicator {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 0;
    margin-bottom: 0;
    background-color: ${colors.MATERIAL_YELLOW};

    &.sent {
      background-color: ${colors.MATERIAL_YELLOW};
    }

    &.viewed {
      background-color: ${colors.MATERIAL_YELLOW};
    }

    &.signed {
      background-color: ${colors.MATERIAL_GREEN};
    }
  }
`

const getStatusText = (status) => {
  return status.replace(/^\w/, (c) => c.toUpperCase())
}

export const SigningStatus = ({ status, name }) => {
  return (
    <S_SigningStatusIndicator>
      {status && <div className={`indicator ${status}`} />}
      <div className="text">
        {getStatusText(status)} <div className="name">{name}</div>
      </div>
    </S_SigningStatusIndicator>
  )
}
