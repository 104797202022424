import { businessOfficersConstants } from '../_constants'
import { businessOfficersService } from '../_services'
import { notificationsActions } from './notifications.actions'

export const businessOfficersActions = {
  getBusinessOfficers,
  updateBusinessOfficer,
  createBusinessOfficer,
  deleteBusinessOfficer,
}

function getBusinessOfficers(businessId) {
  return (dispatch) => {
    dispatch(request(businessId))
    return businessOfficersService
      .getBusinessOfficers(businessId)
      .then(async (businessOfficers) => {
        dispatch(success(businessOfficers))

        // If no business officers exits, create one
        if (businessOfficers.length === 0) {
          await dispatch(createBusinessOfficer(businessId))
        }
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request(businessId) {
    return { type: businessOfficersConstants.GET_BUSINESS_OFFICERS_REQUEST, businessId }
  }
  function success(businessOfficers) {
    return { type: businessOfficersConstants.GET_BUSINESS_OFFICERS_SUCCESS, businessOfficers }
  }
  function failure(error) {
    return { type: businessOfficersConstants.GET_BUSINESS_OFFICERS_FAILURE, error }
  }
}

function updateBusinessOfficer(attr, val, businessId, businessOfficerOrdinal) {
  return (dispatch) => {
    dispatch(request(attr, val, businessId, businessOfficerOrdinal))
    return businessOfficersService
      .updateBusinessOfficer(attr, val, businessId, businessOfficerOrdinal)
      .then((businessOfficer) => dispatch(success(businessOfficer)))
      .catch((error) => {
        dispatch(failure(error))
        if (error.length) {
          dispatch(
            notificationsActions.showNotification({
              type: `error`,
              message: error,
            })
          )
        }
      })
  }

  function request(attr, val, businessId, businessOfficerOrdinal) {
    return {
      type: businessOfficersConstants.UPDATE_BUSINESS_OFFICER_REQUEST,
      attr,
      val,
      businessId,
      businessOfficerOrdinal,
    }
  }
  function success(businessOfficer) {
    return { type: businessOfficersConstants.UPDATE_BUSINESS_OFFICER_SUCCESS, businessOfficer }
  }
  function failure(error) {
    return { type: businessOfficersConstants.UPDATE_BUSINESS_OFFICER_FAILURE, error }
  }
}

function createBusinessOfficer(businessId) {
  return (dispatch) => {
    dispatch(request(businessId))
    return businessOfficersService
      .createBusinessOfficer(businessId)
      .then((businessOfficer) => dispatch(success(businessOfficer)))
      .catch((error) => dispatch(failure(error)))
  }

  function request(businessId) {
    return { type: businessOfficersConstants.CREATE_BUSINESS_OFFICER_REQUEST, businessId }
  }
  function success(businessOfficer) {
    return { type: businessOfficersConstants.CREATE_BUSINESS_OFFICER_SUCCESS, businessOfficer }
  }
  function failure(error) {
    return { type: businessOfficersConstants.CREATE_BUSINESS_OFFICER_FAILURE, error }
  }
}

function deleteBusinessOfficer(businessOfficerId) {
  return (dispatch) => {
    dispatch(request(businessOfficerId))
    return businessOfficersService
      .deleteBusinessOfficer(businessOfficerId)
      .then(() => dispatch(success(businessOfficerId)))
      .catch((error) => dispatch(failure(error)))
  }

  function request(businessOfficerId) {
    return { type: businessOfficersConstants.DELETE_BUSINESS_OFFICER_REQUEST, businessOfficerId }
  }

  function success(businessOfficerId) {
    return { type: businessOfficersConstants.DELETE_BUSINESS_OFFICER_SUCCESS, businessOfficerId }
  }
  function failure(error) {
    return { type: businessOfficersConstants.DELETE_BUSINESS_OFFICER_FAILURE, error }
  }
}
