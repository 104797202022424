import { formHeader } from '../_helpers'
import request from './axios_service'
// import axios from 'axios';

export const dealService = {
  getDeal,
  updateDeal,
  updateDealBatch,
  uploadDealDocument,
  createDeal,
  getDealsToDate,
  getLatestDeal,
  createInvoice,
  getDealById,
}

function getDeal(businessId) {
  return request({
    url: `/deals/get_latest`,
    method: 'PUT',
    uncancelable: true,
    data: { business_id: businessId.toString() },
  })
}

function getDealById(dealId) {
  return request({
    url: `/deals/${dealId}`,
    method: 'GET',
  })
}

function updateDeal(attr, val, dealId) {
  return request({
    url: `/deals/${dealId}`,
    method: 'PUT',
    uncancelable: true,
    data: {
      deal: {
        [attr]: val,
      },
    },
  })
}

function updateDealBatch(data, dealId) {
  return request({
    url: `/deals/${dealId}`,
    method: 'PUT',
    data: {
      deal: data,
    },
  })
}

function uploadDealDocument(file, userId, dealId) {
  const data = new FormData()
  data.append(`filedata`, file.filedata)
  data.append(`filename`, file.filename)
  data.append(`document_type`, file.document_type)
  data.append(`user_id`, userId)
  data.append(`deal_id`, dealId)
  data.append(`send_email`, false)

  return request({
    url: `/documents/upload_deal_document/${dealId}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

function createDeal(businessId) {
  return request({
    url: '/deals',
    method: 'POST',
    data: { business_id: businessId, deal_status: 'created' },
  })
}

function getDealsToDate(businessId, numDaysToDate = 30) {
  return request({
    url: `/deals/get_deals_to_date/${businessId}`,
    method: 'PUT',
    data: { days_from_today: numDaysToDate },
  })
}

function getLatestDeal(businessId) {
  return request({
    url: `/deals/get_latest`,
    method: 'PUT',
    data: { business_id: businessId },
  })
}

function createInvoice(businessId, dealId) {
  return request({
    url: `/invoices/add_invoice_and_document`,
    method: `POST`,
    data: {
      business_id: businessId.toString(),
      deal_id: dealId,
    },
  })
}
