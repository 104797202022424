import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// Components
import { LenderOverviewTile } from './LenderOverviewTile'
import { S_LenderOverviewTile } from './LenderOverviewTile.style'
import { cssBreakpoints } from '../_constants/styles'

// Styled Componenets
const tileSpacing = 15
const tileSpacingMobile = 5

export const S_LenderOverviewTiles = styled.div`
  .tiles-section {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -${tileSpacing}px 0; /* TODO: make this a setting on the lender homepage level, keep things flexible as we factor out common components */

    @media (max-width: ${cssBreakpoints.mobileLg}) {
      margin: 0 -${tileSpacingMobile}px 0;
    }

    ${S_LenderOverviewTile} {
      margin: ${tileSpacing}px;
      max-width: calc(100% - ${tileSpacing * 2}px);
      flex-grow: 1;

      @media (max-width: ${cssBreakpoints.mobileLg}) {
        margin: ${tileSpacingMobile}px;
        max-width: calc(100% - ${tileSpacingMobile * 2}px);
      }
    }
  }

  .pending-sub-text {
    display: flex;
    justify-content: flex-end;
    color: #666;
  }
`

export const LenderOverviewTiles = ({ overviewTiles, largeTiles = false }) => {
  const somePending = overviewTiles.some((tile) => tile.pending)

  return (
    <S_LenderOverviewTiles>
      <div className="tiles-section">
        {overviewTiles.map(
          (
            {
              label,
              value,
              content,
              type = 'single',
              href,
              linkText,
              pending = false,
              tooltip = '',
              component,
              layout = '',
              noClick = false,
              disableHover = false,
              tileClass = '',
              boxClass = '',
            },
            i
          ) => (
            <LenderOverviewTile
              boxClass={boxClass}
              component={component}
              content={content}
              disableHover={disableHover}
              href={href}
              key={i}
              label={label}
              layout={largeTiles ? `large ${layout}` : layout}
              linkText={linkText}
              noClick={noClick}
              pending={pending}
              tileClass={tileClass}
              tooltip={tooltip}
              type={type}
              value={value}
            />
          )
        )}
      </div>

      {somePending && <div className="pending-sub-text">Pending</div>}
    </S_LenderOverviewTiles>
  )
}

LenderOverviewTiles.propTypes = {
  largeTiles: PropTypes.bool,
  overviewTiles: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
      linkText: PropTypes.string,
      tooltip: PropTypes.array,
      type: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
}

LenderOverviewTiles.defaultProps = {
  largeTiles: false,
}
