import React from 'react'
import { AutoCompleteInput, MultipleAutoCompleteInput } from '../../../AutoCompleteInput'
import { S_FormFieldWrapper } from '../FormField'

export const ACInput = (props) => {
  const { disabled } = props
  return (
    <S_FormFieldWrapper disabled={disabled}>
      {props.multiple ? (
        <MultipleAutoCompleteInput {...props} className={`formFieldInput`} />
      ) : (
        <AutoCompleteInput {...props} className={`formFieldInput`} />
      )}
    </S_FormFieldWrapper>
  )
}
