import { transformDocumentResponse } from 'xen/api/use-document';
import { transformVerificationNoteResponse } from 'xen/api/use-invoice';
export const transformInvoiceSchedule = (invoiceSchedule) => {
    return {
        advanceRate: invoiceSchedule.advance_rate ? parseFloat(invoiceSchedule.advance_rate) : null,
        batchNumber: invoiceSchedule.batch_number,
        businessId: invoiceSchedule.business_id,
        businessName: invoiceSchedule.business_name,
        createdAt: new Date(invoiceSchedule.created_at),
        documents: invoiceSchedule.documents.map(transformDocumentResponse),
        expectedTotalValue: parseFloat(invoiceSchedule.expected_total_value),
        feeScheduleId: invoiceSchedule.fee_schedule_id,
        hasPendingSignatureRequest: invoiceSchedule.has_pending_signature_request,
        id: invoiceSchedule.id,
        invoicesCount: invoiceSchedule.invoices_count,
        maxInvoiceDate: new Date(invoiceSchedule.max_invoice_date),
        name: invoiceSchedule.name,
        notes: invoiceSchedule.notes.map(transformVerificationNoteResponse),
        purchaseDate: invoiceSchedule.purchase_date ? new Date(invoiceSchedule.purchase_date) : null,
        requiresAction: invoiceSchedule.requires_action,
        state: invoiceSchedule.state,
        totalAdvance: invoiceSchedule.total_advance ? parseFloat(invoiceSchedule.total_advance) : null,
        totalFunded: parseFloat(invoiceSchedule.total_funded),
        totalValue: parseFloat(invoiceSchedule.total_value),
        updatedAt: new Date(invoiceSchedule.updated_at),
        terms: invoiceSchedule.terms,
    };
};
export const transformInvoiceScheduleParams = (params) => {
    const payload = {};
    if ('businessId' in params) {
        payload.business_id = params.businessId;
    }
    if ('expectedTotalValue' in params) {
        payload.expected_total_value = params.expectedTotalValue;
    }
    if ('state' in params) {
        payload.state = params.state;
    }
    if ('terms' in params) {
        payload.terms = params.terms;
    }
    return payload;
};
export const transformInvoiceSchedulePayload = ({ invoiceScheduleId, invoice, }) => {
    const transformedInvoice = {};
    if ('approved' in invoice) {
        transformedInvoice.approved = invoice.approved;
    }
    if ('archived' in invoice) {
        transformedInvoice.archived = invoice.archived;
    }
    if ('businessId' in invoice) {
        transformedInvoice.business_id = invoice.businessId;
    }
    if ('currency' in invoice) {
        transformedInvoice.currency = invoice.currency;
    }
    if ('dataSource' in invoice) {
        transformedInvoice.data_source = invoice.dataSource;
    }
    if ('dealId' in invoice) {
        transformedInvoice.deal_id = invoice.dealId;
    }
    if ('debtorDueDate' in invoice) {
        transformedInvoice.debtor_due_date = invoice.debtorDueDate?.toISOString();
    }
    if ('debtorId' in invoice) {
        transformedInvoice.debtor_id = invoice.debtorId;
    }
    if ('discountRate' in invoice) {
        transformedInvoice.discount_rate = invoice.discountRate;
    }
    if ('documentId' in invoice) {
        transformedInvoice.document_id = invoice.documentId;
    }
    if ('externalReferenceNumber' in invoice) {
        transformedInvoice.external_reference_number = invoice.externalReferenceNumber;
    }
    if ('faceValue' in invoice) {
        transformedInvoice.face_value = invoice.faceValue;
    }
    if ('financed' in invoice) {
        transformedInvoice.financed = invoice.financed;
    }
    if ('ineligible' in invoice) {
        transformedInvoice.ineligible = invoice.ineligible;
    }
    if ('invoiceDate' in invoice) {
        transformedInvoice.invoice_date = invoice.invoiceDate?.toISOString();
    }
    if ('invoiceNumber' in invoice) {
        transformedInvoice.invoice_number = invoice.invoiceNumber;
    }
    if ('nonFactored' in invoice) {
        transformedInvoice.non_factored = invoice.nonFactored;
    }
    if ('nonFunded' in invoice) {
        transformedInvoice.non_funded = invoice.nonFunded;
    }
    if ('paymentTerms' in invoice) {
        transformedInvoice.payment_terms = invoice.paymentTerms;
    }
    if ('pctAdvanced' in invoice) {
        transformedInvoice.pct_advanced = String(invoice.pctAdvanced);
    }
    if ('pending' in invoice) {
        transformedInvoice.pending = invoice.pending;
    }
    if ('poNumber' in invoice) {
        transformedInvoice.po_number = String(invoice.poNumber);
    }
    if ('preapproved' in invoice) {
        transformedInvoice.preapproved = invoice.preapproved;
    }
    if ('removed' in invoice) {
        transformedInvoice.removed = invoice.removed;
    }
    if ('sentToInvestors' in invoice) {
        transformedInvoice.sent_to_investors = invoice.sentToInvestors;
    }
    if ('status' in invoice) {
        transformedInvoice.status = invoice.status;
    }
    if ('submitted' in invoice) {
        transformedInvoice.submitted = invoice.submitted;
    }
    return {
        invoice_schedule_id: invoiceScheduleId,
        invoice: transformedInvoice,
    };
};
export const transformInvoiceScheduleUpload = (response) => {
    return {
        errors: response.errors.map((error) => ({
            columns: error.columns.map((column) => ({
                errorCode: column.error_code,
                name: column.name,
            })),
            row: error.row,
        })),
        invoices: response.invoices.map((invoice) => ({
            businessId: invoice.business_id,
            createdAt: new Date(invoice.created_at),
            currentBalance: invoice.current_balance,
            debtorDueDate: new Date(invoice.debtor_due_date),
            debtorId: invoice.debtor_id,
            faceValue: invoice.face_value,
            invoiceDate: new Date(invoice.invoice_date),
            invoiceNumber: invoice.invoice_number,
            invoiceScheduleId: invoice.invoice_schedule_id,
            paymentTerms: invoice.payment_terms,
            poNumber: invoice.po_number,
            // Bulk uploaded invoices are hard-coded to the Invoice::INV_INCOMPLETE state
            state: 'Incomplete',
            tenantId: invoice.tenant_id,
            updatedAt: new Date(invoice.updated_at),
        })),
        successCount: response.success_count,
        warnings: response.warnings,
    };
};
