import { UTCDateToSystemString } from '../dates'
import { getStatusDisplay } from './status-display'

export const formatInvoiceSchedules = (invoice) => {
  if (invoice.created_at) {
    invoice.created_at = UTCDateToSystemString(invoice.created_at)
  }

  if (invoice.updated_at) {
    invoice.updated_at = UTCDateToSystemString(invoice.updated_at)
  }

  if (invoice.state) {
    invoice.state = getStatusDisplay(invoice.state)
  }

  return invoice
}
