export const businessConstants = {
  GET_BUSINESS_REQUEST: 'GET_BUSINESS_REQUEST',
  GET_BUSINESS_SUCCESS: 'GET_BUSINESS_SUCCESS',
  GET_BUSINESS_FAILURE: 'GET_BUSINESS_FAILURE',

  UPDATE_BUSINESS_REQUEST: 'UPDATE_BUSINESS_REQUEST',
  UPDATE_BUSINESS_SUCCESS: 'UPDATE_BUSINESS_SUCCESS',
  UPDATE_BUSINESS_FAILURE: 'UPDATE_BUSINESS_FAILURE',

  GET_CLIENT_OVERVIEW_DETAILS_REQUEST: 'GET_CLIENT_OVERVIEW_DETAILS_REQUEST',
  GET_CLIENT_OVERVIEW_DETAILS_SUCCESS: 'GET_CLIENT_OVERVIEW_DETAILS_SUCCESS',
  GET_CLIENT_OVERVIEW_DETAILS_FAILURE: 'GET_CLIENT_OVERVIEW_DETAILS_FAILURE',

  GET_CLIENT_TRANSACTIONS_REQUEST: 'GET_CLIENT_TRANSACTIONS_REQUEST',
  GET_CLIENT_TRANSACTIONS_SUCCESS: 'GET_CLIENT_TRANSACTIONS_SUCCESS',
  GET_CLIENT_TRANSACTIONS_FAILURE: 'GET_CLIENT_TRANSACTIONS_FAILURE',
}
