import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformDebtorContactResponse } from './transform';
const getDebtorContacts = async ({ urlParams: { debtorId } }) => {
    try {
        const response = await request({
            method: 'get',
            url: `/debtors/${debtorId}/contacts`,
        });
        const data = response.map(transformDebtorContactResponse);
        return data;
    }
    catch (_error) {
        throw Error('Error fetching debtor contacts');
    }
};
export const useGetDebtorContacts = ({ urlParams: { debtorId } }) => {
    return useQuery({
        queryKey: ['debtors', debtorId, 'contacts'],
        queryFn: () => getDebtorContacts({ urlParams: { debtorId } }),
        enabled: debtorId !== undefined,
    });
};
