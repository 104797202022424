import request from '../axios_service'

export const getABLBusinessesOverview = (params = '') => {
  return request(
    {
      url: `/abl/client/businesses/overview?${params.toString()}`,
      method: 'GET',
    },
    null,
    true
  )
}

export const getABLBusinesses = (params = '', status = false, raw = false) => {
  if (status && params) {
    params.set('status', status)
  }
  return request(
    {
      url: `/abl/businesses?${params.toString()}`,
      method: 'GET',
    },
    null,
    raw
  )
}

export function getABLBusiness(businessId) {
  return request({
    url: `abl/businesses/${businessId}`,
    method: 'GET',
  })
}

export function getABLBusinessByDeal(dealId) {
  return request({
    url: `abl/client/businesses/${dealId}/show_by_deal_id`,
    method: 'GET',
  })
}

export const getABLBusinessOverview = (businessId) => {
  return request({
    url: `/abl/client/businesses/${businessId}/overview`,
    method: 'GET',
  })
}

export const updateABLBusiness = (businessId, data) => {
  return request({
    url: `/abl/businesses/${businessId}`,
    method: 'PUT',
    data: data,
  })
}

export const createABLBusiness = (data) => {
  return request({
    url: `/abl/client/businesses`,
    method: 'POST',
    data: data,
  })
}

export const getUsersForABLBusiness = (businessId) => {
  // get up to 100 users for a business
  return request({
    url: `/users?business_abl_id=${businessId}&count=100&start_index=0`,
    method: 'GET',
  })
}

export const getABLUnderwriteBusinessesOverview = (params = '', status = null) => {
  params.set('risk_status', status)
  return request(
    {
      url: `/abl/client/businesses/underwrite_search?${params.toString()}`,
      method: 'GET',
    },
    null
  )
}

export const archiveABLBusiness = (business_id) => {
  return request({
    url: `/abl/client/businesses/${business_id}/archive/`,
    data: null,
    method: 'PUT',
  })
}
