import { css } from 'styled-components'

const breakpoints = {
  xs: '0px', // Extra-small
  sm: '576px', // Small screens
  md: '768px', // Medium screens, mobile starts here
  lg: '992px', // Large, tablets
  xl: '1200px', // Extra-large, Laptops
}

export const addMediaStyles = (breakpoint, styles) => {
  return css`
    @media (min-width: ${breakpoints[breakpoint]}) {
      ${styles}
    }
  `
}

export const addMediaQuery = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})
