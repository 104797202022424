import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Checkbox as MantineCheckbox } from '@mantine/core';
import { createLabelText } from '../_internal';
import { useId } from '../hooks/use-id';
export const Checkbox = forwardRef(({ description, hideLabel = false, label, ...props }, ref) => {
    const labelText = createLabelText(label, hideLabel, props.type);
    // Associate checkbox to description if it exists
    let descriptionProps = {};
    if (description) {
        const descriptionId = useId();
        descriptionProps = {
            'aria-describedby': descriptionId,
            description: _jsx("span", { id: descriptionId, children: description }),
        };
    }
    return _jsx(MantineCheckbox, { ...props, ref: ref, ...labelText, ...descriptionProps });
});
Checkbox.displayName = 'Checkbox';
