import { alertConstants } from '../_constants'

export const alertActions = {
  showAlert,
  hideAlert,
}

function showAlert(alert) {
  return {
    type: alertConstants.SHOW_ALERT,
    alert,
  }
}

function hideAlert() {
  return {
    type: alertConstants.HIDE_ALERT,
  }
}
