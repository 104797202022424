import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
async function createInvoiceScheduleNotification({ urlParams, payload, }) {
    const { debtorIds } = payload;
    const { scheduleId } = urlParams;
    try {
        await request({
            method: 'POST',
            url: `/emails/notify_schedule_debtors/${scheduleId}`,
            data: { debtor_ids: debtorIds },
        });
        return { debtorIds };
    }
    catch (error) {
        throw new Error('Could not notify debtors');
    }
}
export const useCreateInvoiceScheduleNotification = ({ urlParams: { scheduleId }, }) => {
    return useMutation({
        mutationKey: ['create-invoice-schedule-notificaiton', scheduleId],
        mutationFn: ({ payload }) => createInvoiceScheduleNotification({ urlParams: { scheduleId }, payload }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: ({ debtorIds }) => {
            notifications.success(`Sent email notifications to ${debtorIds.length} debtors`);
        },
    });
};
