import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformBlankInvoiceResponse } from 'xen/api/use-invoice/transform';
async function getInvoiceScheduleInvoices({ urlParams: { scheduleId }, queryParams = {}, }) {
    try {
        const response = await request({
            method: 'GET',
            url: `/invoice_schedules/${scheduleId}/invoices`,
            // Include invoices with no debtor ID by default, so "blank invoices" are included in the response
            params: { includeInvoicesWithNoDebtorId: true, ...queryParams },
        });
        const data = response.map(transformBlankInvoiceResponse);
        return data;
    }
    catch (_error) {
        throw Error('Could not fetch invoice schedule invoices');
    }
}
export const useGetInvoiceScheduleInvoices = ({ urlParams, queryParams }) => {
    return useQuery({
        queryKey: ['invoice-schedules', urlParams.scheduleId, 'invoices', { queryParams }],
        queryFn: () => getInvoiceScheduleInvoices({ urlParams, queryParams }),
        enabled: urlParams.scheduleId !== undefined,
    });
};
