import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { queryClient } from 'xen/providers';
async function deletePayment(id) {
    try {
        await request({
            method: 'DELETE',
            url: `/operate/checks/${id}`,
        });
        return id;
    }
    catch (_error) {
        throw Error('Error deleting payment');
    }
}
export const useDeletePayment = () => {
    return useMutation({
        mutationFn: (id) => deletePayment(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['payments'] });
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements'] });
        },
    });
};
