import { transformDocumentResponse } from 'xen/api/use-document';
import { transformVerificationNoteResponse } from 'xen/api/use-invoice';
import { formatDate } from 'xen/helpers/format';
export const transformPaymentResponseToPayment = (response) => {
    return {
        id: response.id,
        tenantId: response.tenant_id,
        businessId: response.business_id,
        tenantDebtorId: response.tenant_debtor_id,
        amount: response.amount ? Number(response.amount) : null,
        date: new Date(response.date),
        checkNumber: response.check_number,
        bankRouting: response.bank_routing,
        bankAccount: response.bank_account,
        reference: response.reference,
        invoiceNumber: response.invoice_number,
        closed: response.closed,
        createdAt: new Date(response.created_at),
        updatedAt: new Date(response.updated_at),
        remaining: response.remaining,
        depositDate: response.deposit_date ? new Date(response.deposit_date) : null,
        source: response.source,
        nptLender: response.npt_lender,
        nptLockbox: response.npt_lockbox,
        paymentBatchId: response.payment_batch_id,
        debtorId: response.debtor_id,
        state: response.state,
        amountPosted: Number(response.amount_posted),
        note: response.note,
        complete: response.complete,
        businessName: response.business_name,
        debtor: response.debtor,
        debtorName: response.debtor_name,
        notes: response.notes.map(transformVerificationNoteResponse),
        documents: response.documents.map(transformDocumentResponse),
        errors: {
            tenantDebtor: response.errors.tenant_debtor,
            amount: response.errors.amount,
            checkNumber: response.errors.check_number,
        },
        postedDate: response.posted_date ? new Date(response.posted_date) : null,
        batchNumber: response.batch_number,
        operations: response.operations,
    };
};
export const transformPaymentParamsToPaymentParamsPayload = (paymentPayload) => {
    const paymentAPIPayload = {};
    if ('amount' in paymentPayload)
        paymentAPIPayload.amount = paymentPayload.amount;
    if ('bankAccount' in paymentPayload)
        paymentAPIPayload.bank_account = paymentPayload.bankAccount;
    if ('bankRouting' in paymentPayload)
        paymentAPIPayload.bank_routing = paymentPayload.bankRouting;
    if ('businessId' in paymentPayload)
        paymentAPIPayload.business_id = paymentPayload.businessId;
    if ('checkNumber' in paymentPayload)
        paymentAPIPayload.check_number = paymentPayload.checkNumber;
    if ('date' in paymentPayload) {
        paymentAPIPayload.date = paymentPayload.date ? formatDate.isoDateString(paymentPayload.date) : undefined;
    }
    paymentAPIPayload.date = formatDate.isoDateString(paymentPayload.date ?? new Date());
    if ('debtorId' in paymentPayload)
        paymentAPIPayload.debtor_id = paymentPayload.debtorId;
    if ('depositDate' in paymentPayload) {
        paymentAPIPayload.deposit_date = paymentPayload.depositDate
            ? formatDate.isoDateString(paymentPayload.depositDate)
            : undefined;
    }
    if ('documentId' in paymentPayload)
        paymentAPIPayload.document_id = paymentPayload.documentId;
    if ('invoiceNumber' in paymentPayload)
        paymentAPIPayload.invoice_number = paymentPayload.invoiceNumber;
    if ('nptLender' in paymentPayload)
        paymentAPIPayload.npt_lender = paymentPayload.nptLender;
    if ('nptLockbox' in paymentPayload)
        paymentAPIPayload.npt_lockbox = paymentPayload.nptLockbox;
    if ('reference' in paymentPayload)
        paymentAPIPayload.reference = paymentPayload.reference;
    if ('source' in paymentPayload)
        paymentAPIPayload.source = paymentPayload.source;
    if ('tenantDebtorId' in paymentPayload)
        paymentAPIPayload.tenant_debtor_id = paymentPayload.tenantDebtorId;
    return paymentAPIPayload;
};
