import styled, { css } from 'styled-components'
import { S_EmptyMessage } from '../EmptyMessage'
import { S_SearchBar } from '../SearchBar'
import { S_PaginationControls } from '../PaginationControls'
import { S_SearchableView } from '../SearchableView'

// Constants
import colors from '../../_constants/colors'
import { cssBreakpoints } from '../../_constants/styles'

export const totalIndicatorStyles = css`
  background: ${colors.TEXT_LIGHT};
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 400;
  white-space: nowrap;
  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    padding: 5px;
  }
`

// Styled Component
export const S_SearchableOverview = styled.div`
  ${S_SearchBar} {
    margin-bottom: 10px;
  }

  ${S_SearchableView} {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${S_PaginationControls} {
    margin: 0 5px;
  }

  .overview-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 10px;
    justify-content: space-between;
    .left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .total-indicator {
    margin: 4px;
  }

  .overview-title {
    font-weight: 400;
    color: ${colors.TEXT_DARK};
  }

  .total-indicator {
    ${totalIndicatorStyles}
  }

  ${S_EmptyMessage} {
    background: #f9f9f9;
    border-radius: 2px;
  }

  .search-filter {
    display: flex;
    max-width: 950px;
    &.align-with-table {
      max-width: none;
    }
  }

  .search-filter-items {
    display: flex;
    flex-wrap: wrap;
  }
`
