import request from './axios_service'

export const bankService = {
  connectBank,
}

function connectBank(userId, dealId) {
  const windowName = 'yodlee_fastlink'
  const yodleeWindow = window.open('', windowName)
  yodleeWindow.document.write('loading...')

  return connectToYodlee(userId, dealId).then(function (resp) {
    const form = document.createElement('form')
    form.setAttribute('target', windowName)
    form.setAttribute('method', 'post')
    form.setAttribute('action', resp.fastlink_node_url)

    var params = {
      app: '10003600',
      rsession: resp.user_session_token,
      token: resp.fastlink_token,
      redirectReq: true,
      extraParams: resp.extra_params,
    }

    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        let hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('name', key)
        hiddenField.setAttribute('value', params[key])
        form.appendChild(hiddenField)
      }
    }

    document.body.appendChild(form)
    form.submit()
  })
}

function connectToYodlee(userId, dealId) {
  return request({
    url: '/yodlee/authenticate/' + userId + '/' + dealId,
    method: 'GET',
  })
}
