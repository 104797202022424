import React, { useState, useEffect } from 'react'

// Components
import { FormInput } from '../../FormInput'

const updateFromProps = (value, defaultValue) => {
  const isControlled = value !== undefined
  const valueToUse = isControlled ? value : defaultValue
  return valueToUse
}

export const ConfirmNumberField = (props) => {
  const { id = null, value, defaultValue, onChange = () => {}, ...rest } = props
  const [displayValue, setDisplayValue] = useState(updateFromProps(value, defaultValue))

  useEffect(() => {
    setDisplayValue(updateFromProps(value, defaultValue))
  }, [id, value])

  const handleChangeLocal = (e) => {
    let value = e.target.value
    const isValid = /^[A-Za-z0-9 _ -]*[A-Za-z0-9][A-Za-z0-9 _ -]*$/.test(value)

    if (!isValid && value.length) {
      return null
    }

    setDisplayValue(value)
    onChange(value, e)
  }

  return <FormInput {...rest} id={id} onChange={handleChangeLocal} type="text" value={displayValue} />
}
