import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

// Components
import { PageNotReady } from '../PageNotReady'
import { PrivateAblClientRoute } from '../_components/routes/PrivateAblClientRoute'

// Constants
import ROUTES from '../_constants/routes'

// Helpers
import { isLender } from '../_helpers/user-utils'
import { isProductEnabled } from '../_helpers/flags'

export const ABLClientRoutes = ({ loggedIn, user, featureFlags }) => {
  // Only for asset based lenders
  const isClient = !isLender(loggedIn, user.user_type)
  if (!isClient || !isProductEnabled('Asset Based Lending', featureFlags)) {
    return null
  } else if (
    isClient &&
    !isProductEnabled('Operate', featureFlags) &&
    isProductEnabled('Asset Based Lending', featureFlags)
  ) {
    return <Route component={PageNotReady} />
  }

  //dynamically load pages and dependencies for abl client routes
  const pageComponents = {
    ABLClientHomePage: import('../ABLClientHomePage'),
    BBSubmission: import('../BorrowingBaseCertificate/BorrowingBaseSubmission/index.js'),
    BBSummary: import('../BorrowingBaseCertificate/BBCSummary/index.js'),
    BBCInvoices: import('../BorrowingBaseCertificate/BBCInvoices/index.js'),
    ABLCashReceiptDetailsPage: import('../_views/ABLClientViews/ABLCashReceiptDetailsPage'),
    ABLFundingRequestEditPage: import('../_views/ABLClientViews/ABLFundingRequestEditPage'),
    DailyStatements: import('../_views/abl/DailyStatements/index.js'),
  }

  const ABLClientHomePage = lazy(() => pageComponents['ABLClientHomePage'])
  const BBSummary = lazy(() => pageComponents['BBSummary'])
  const BBSubmission = lazy(() => pageComponents['BBSubmission'])
  const BBCInvoices = lazy(() => pageComponents['BBCInvoices'])
  const ABLCashReceiptDetailsPage = lazy(() => pageComponents['ABLCashReceiptDetailsPage'])
  const ABLFundingRequestEditPage = lazy(() => pageComponents['ABLFundingRequestEditPage'])
  const DailyStatements = lazy(() => pageComponents['DailyStatements'])

  return (
    <>
      <PrivateAblClientRoute component={ABLClientHomePage} path={ROUTES.abl_client_home} />
      <PrivateAblClientRoute
        component={BBCInvoices}
        exact={true}
        path={ROUTES.borrowing_base_submission + '/:bbcId/invoices'}
      />
      <PrivateAblClientRoute
        component={BBSubmission}
        exact={true}
        path={ROUTES.borrowing_base_submission + '/:bbcId'}
      />
      <PrivateAblClientRoute component={BBSummary} path={ROUTES.borrowing_base_summary + '/:bbcId'} />
      <PrivateAblClientRoute
        component={ABLCashReceiptDetailsPage}
        path={ROUTES.cash_receipt_details + '/:cashReceiptId'}
      />
      <PrivateAblClientRoute
        component={ABLFundingRequestEditPage}
        path={ROUTES.funding_request_edit + '/:fundingRequestId'}
      />
      <PrivateAblClientRoute component={DailyStatements} path={ROUTES.statements_daily + '/:date'} />
    </>
  )
}
