import { businessConstants } from '../_constants'

export function business(state = {}, action) {
  switch (action.type) {
    case businessConstants.GET_BUSINESS_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
        },
      }
    case businessConstants.UPDATE_BUSINESS_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
          attr: action.attr,
          val: action.val,
        },
      }
    case businessConstants.GET_BUSINESS_SUCCESS:
      return action.business
    case businessConstants.UPDATE_BUSINESS_SUCCESS:
      return action.business
    case businessConstants.GET_BUSINESS_FAILURE:
      return {
        ...state,
        action: {
          loading: false,
          error: action.error,
        },
      }
    case businessConstants.UPDATE_BUSINESS_FAILURE:
      return {
        ...state,
        action: {
          loading: false,
          error: action.error,
        },
      }

    case businessConstants.GET_CLIENT_OVERVIEW_DETAILS_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
        },
      }
    case businessConstants.GET_CLIENT_OVERVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.details,
        action: {},
      }
    case businessConstants.GET_BUSINESS_FAILURE:
      return {
        ...state,
        action: {
          loading: false,
          error: action.error,
        },
      }

    case businessConstants.GET_CLIENT_TRANSACTIONS_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
        },
      }
    case businessConstants.GET_CLIENT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.transactions,
        action: {
          loading: false,
        },
      }
    case businessConstants.GET_CLIENT_TRANSACTIONS_FAILURE:
      return {
        ...state,
        action: {
          loading: false,
          error: action.error,
        },
      }
    default:
      return state
  }
}
