import { jsx as _jsx } from "react/jsx-runtime";
import { Button, modals } from 'xen-ui';
import { useDeleteInvoiceSchedule } from 'xen/api/use-invoice-schedule';
import { useXenHistory } from 'xen/hooks';
export const InvoiceScheduleDeleteButton = ({ children = 'Delete Invoice Schedule', invoiceSchedule: { id: scheduleId }, ...props }) => {
    const { push } = useXenHistory();
    const { mutateAsync: deleteSchedule, isPending } = useDeleteInvoiceSchedule({ urlParams: { scheduleId } });
    const handleDeleteSchedule = async () => {
        await deleteSchedule();
        push({ to: '/invoice-schedules' });
    };
    const confirmDeleteSchedule = () => {
        modals.openConfirmModal({
            title: 'Confirm',
            children: 'Are you sure you want to delete this invoice schedule and all of its invoices? This action cannot be undone.',
            confirmProps: { color: 'red' },
            labels: { cancel: 'Cancel', confirm: 'Delete Invoice Schedule' },
            onConfirm: handleDeleteSchedule,
        });
    };
    return (_jsx(Button, { loading: isPending, onClick: confirmDeleteSchedule, ...props, children: children }));
};
