import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ActionIcon, BasicTable, Button, Center, Checkbox, Group, Modal, Stack, useDisclosure, XenIcon } from 'xen-ui';
import { useCreateInvoiceScheduleNotification } from 'xen/api/use-invoice-schedule';
import { useGetInvoiceScheduleNotifiableDebtors } from 'xen/api/use-invoice-schedule';
import { Error } from 'xen/components';
import { NotifyDebtorPreview } from './notify-debtor-preview';
export const NotifyDebtors = ({ invoiceSchedule, notifyDebtorsModalClose }) => {
    const { id: scheduleId } = invoiceSchedule;
    const { data: notifiableDebtors } = useGetInvoiceScheduleNotifiableDebtors({
        urlParams: { scheduleId: scheduleId },
    });
    const { mutate: notifyDebtors, isPending: isDebtorNotificationLoading } = useCreateInvoiceScheduleNotification({
        urlParams: { scheduleId: scheduleId },
    });
    const [notifyDebtorPreviewModalOpened, { open: notifyDebtorPreviewModalOpen, close: notifyDebtorPreviewModalClose }] = useDisclosure(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isIndeterminate, setIsIndeterminate] = useState(false);
    const [selectedDebtorPreviewId, setSelectedDebtorPreviewId] = useState(undefined);
    useEffect(() => {
        const totalDebtors = notifiableDebtors?.length || 0;
        const totalSelected = selectedIds.length;
        setSelectAll(totalSelected === totalDebtors && totalSelected > 0);
        setIsIndeterminate(totalSelected > 0 && totalSelected < totalDebtors);
    }, [selectedIds, notifiableDebtors]);
    const handleSelectAllChange = () => {
        if (selectAll || isIndeterminate) {
            setSelectedIds([]);
        }
        else {
            const allIds = notifiableDebtors?.map((debtor) => debtor.id) || [];
            // Convert to numbers as we send numbers to the backend API
            setSelectedIds(allIds.map(Number));
        }
    };
    const handleDebtorSelectionChange = (id) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter((existingId) => existingId !== id));
        }
        else {
            setSelectedIds([...selectedIds, id]);
        }
    };
    const handleNotifyDebtors = () => {
        notifyDebtors({ payload: { debtorIds: selectedIds } }, {
            onSuccess: () => {
                notifyDebtorsModalClose();
            },
        });
    };
    const onPreviewClick = (debtorId) => {
        setSelectedDebtorPreviewId(debtorId);
        notifyDebtorPreviewModalOpen();
    };
    const rows = notifiableDebtors?.map(({ id: debtorId, debtorName }) => (_jsxs(BasicTable.Tr, { children: [_jsx(BasicTable.Td, { children: debtorName }), _jsx(BasicTable.Td, { children: _jsx(Center, { children: _jsx(Checkbox, { checked: selectedIds.includes(debtorId), hideLabel: true, label: `notify-selection-${debtorId}`, onChange: () => handleDebtorSelectionChange(Number(debtorId)) }) }) }), _jsx(BasicTable.Td, { children: _jsx(Center, { children: _jsx(ActionIcon, { "aria-label": `Debtor ${debtorId} notification preview`, 
                        // color="black"
                        component: "button", onClick: () => onPreviewClick(debtorId), size: "sm", variant: "transparent", children: _jsx(XenIcon.Eye, {}) }) }) })] }, debtorId)));
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { children: [_jsxs(BasicTable, { withColumnBorders: true, withTableBorder: true, children: [_jsx(BasicTable.Thead, { children: _jsxs(BasicTable.Tr, { children: [_jsx(BasicTable.Th, { children: "Debtor" }), _jsx(BasicTable.Th, { children: _jsx(Center, { children: _jsxs(Group, { children: ["Notify?", _jsx(Checkbox, { checked: selectAll, hideLabel: true, indeterminate: isIndeterminate, label: "select-all", onChange: handleSelectAllChange })] }) }) }), _jsx(BasicTable.Th, { children: _jsx(Center, { children: "Preview" }) })] }) }), _jsx(BasicTable.Tbody, { children: rows })] }), _jsxs(Group, { justify: "right", children: [_jsx(Button, { color: "red", onClick: notifyDebtorsModalClose, size: "sm", children: "Cancel" }), _jsx(Button, { disabled: selectedIds.length === 0, loading: isDebtorNotificationLoading, onClick: handleNotifyDebtors, size: "sm", children: "Notify Debtors" })] })] }), _jsx(Modal, { centered: true, onClose: notifyDebtorPreviewModalClose, opened: notifyDebtorPreviewModalOpened, size: "xl", title: "", children: selectedDebtorPreviewId ? (_jsx(NotifyDebtorPreview, { debtorId: selectedDebtorPreviewId, scheduleId: scheduleId })) : (_jsx(Error, { message: "Could not resolve Debtor Id", title: "Error: No Debtor ID Found" })) })] }));
};
