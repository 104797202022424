import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { getTenantFromUrl, notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
export const useDeleteConfiguration = () => {
    return useMutation({
        mutationKey: ['delete-configuration'],
        mutationFn: async ({ urlParams: { configPath } }) => {
            try {
                await request({
                    url: `/v2/configuration/${configPath}`,
                    method: 'DELETE',
                });
            }
            catch (error) {
                throw Error('Could not reset configuration');
            }
        },
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: () => {
            notifications.success(`Reset configuration`);
            queryClient.invalidateQueries({ queryKey: ['tenants', getTenantFromUrl(), 'configuration'] });
        },
    });
};
