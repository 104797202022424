import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { formatCase, notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
export const useCreateApplicationAccess = () => {
    return useMutation({
        mutationKey: ['create-integration-plaid-application-access'],
        mutationFn: async (payload) => {
            const response = await request({
                url: '/integration/plaid/application_accesses',
                method: 'POST',
                data: formatCase.objectKeys('snake', payload),
            });
            return formatCase.objectKeys('camel', response);
        },
        onError: () => {
            notifications.error('Could not link external bank account');
        },
        onSuccess: () => {
            notifications.success('Successfully linked bank account');
            queryClient.invalidateQueries({ queryKey: ['integration-plaid-accounts'] });
        },
    });
};
