import React, { useState } from 'react'

// Components
import { ValidationForm } from '../../../_components/Forms/ValidationForm'
import { LoaderButton } from '../../../_components/LoaderButton'

// Constants
import { initialMainGLAccountsConfig } from '../../../_configs/mainGLAccount.config'

// Services
import { updateMainGLAccount } from '../../../_services/fvadmin_service'

import { S_GLAccountModal } from './styles'

// Hooks
import { modalActionHooks } from '../../../_actionHooks'
import { notificationActionHooks } from '../../../_actionHooks/notification.actionHooks'

export const EditGLAccountModal = ({ admin_tenant, account }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [onSubmit, setOnSubmit] = useState()
  const [currentAccount, setCurrentAccount] = useState(account)
  const closeModal = modalActionHooks.useCloseModal()
  const showNotification = notificationActionHooks.useShowNotification()

  return (
    <S_GLAccountModal>
      <h2>Edit GL Account</h2>
      <ValidationForm
        entity={Object.assign(initialMainGLAccountsConfig.entity, currentAccount)}
        fields={initialMainGLAccountsConfig.fields}
        getFormFunctions={({ submitForm, triggerValidation, setValue, setError }) => {
          setOnSubmit(submitForm)
        }}
        submitHandler={async (values, setError) => {
          setIsSubmitting(true)
          updateMainGLAccount(admin_tenant, currentAccount.id, values).then(
            (resp) => {
              setCurrentAccount(resp)
              closeModal()
              setIsSubmitting(false)
            },
            (e) => {
              showNotification({
                type: 'error',
                message: e,
              })
              setIsSubmitting(false)
            }
          )
        }}
      />
      <div className="submit">
        <LoaderButton isLoading={isSubmitting} onClick={onSubmit} text="Save" />
      </div>
    </S_GLAccountModal>
  )
}
