import styled from 'styled-components'
import { colors } from '../../../_constants/colors'
import { LineChart } from 'recharts'

export const S_LineChart = styled(LineChart)`
  .recharts-default-legend {
    color: ${colors.TEXT_LIGHT};
    font-size: 12px;

    .recharts-legend-icon {
      stroke-width: 2px;
      margin-right: 0;
    }

    .recharts-legend-item {
      display: inline-flex !important;
      flex-direction: row-reverse;
      align-items: center;
      &:last-child {
        margin-right: 0 !important;
      }
      .recharts-legend-item-text {
        margin-right: 3px;
      }
    }
  }

  .prev-current {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 225px;
    top: 10px;
    right: 0;
    z-index: 100;

    div {
      font-size: 12px;
      color: ${colors.TEXT_LIGHT};
      transition: color 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        color: ${colors.TEXT_DARK};
      }

      &.active {
        color: ${colors.DEFAULT_SECONDARY};
      }
    }
  }
`

export const S_NoData = styled.div`
  position: relative;
  left: 0;
  top: 0;
  height: ${(props) => `${props.height}px`};
  width: 100%;
  text-align: center;
  border-radius: ${(props) => (props.rounded ? '100%' : 0)};
  z-index: 10;
  background-color: ${colors.GREY_LIGHT_FILLS};
  vertical-align: middle;
  font-size: 16px;
  &:before {
    color: ${colors.DEFAULT_TEXT};
    content: 'No Data';
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
