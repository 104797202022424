import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { z } from 'zod';
import { XenIcon, TextInput as XenTextInput } from 'xen-ui';
import { ErrorText } from '../../error-text';
export function FormEmailInput({ name, control, defaultValue, error, iconLeft, iconRight, rules, shouldUnregister, onChange, ...props }) {
    const { field: { value, onChange: fieldOnChange, ...field }, fieldState, } = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const fieldError = error ?? fieldState.error?.message;
    const LeftIcon = iconLeft ? XenIcon[iconLeft] : undefined;
    const RightIcon = iconRight ? XenIcon[iconRight] : undefined;
    return (_jsx(XenTextInput, { error: fieldError ? _jsx(ErrorText, { component: "span", message: fieldError }) : undefined, inputWrapperOrder: ['label', 'description', 'error', 'input'], onChange: (e) => {
            fieldOnChange(e);
            onChange?.(e);
        }, styles: { root: { display: 'flex', flexDirection: 'column', gap: '.25rem' }, ...props.styles }, type: "email", value: value, ...(LeftIcon && { leftSection: _jsx(LeftIcon, { fontSize: "inherit" }) }), ...(RightIcon && { rightSection: _jsx(RightIcon, { fontSize: "inherit" }) }), ...field, ...props }));
}
const inputSchema = () => z.string().email({ message: 'Must be valid email address' });
FormEmailInput.schema = inputSchema;
