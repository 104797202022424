import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
async function getDocumentDownloadLink(id) {
    try {
        const response = await request({
            method: 'get',
            url: `/documents/download/${id}`,
        });
        return response;
    }
    catch (_error) {
        console.error(`Error getting document link for document ${id}`);
        throw Error('Error getting document link');
    }
}
export const useGetDocumentDownloadLink = (id) => {
    return useQuery({
        queryKey: ['documents', id.toString(), 'download-link'],
        queryFn: () => getDocumentDownloadLink(id),
    });
};
