import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
export const useGetUnderwriteDocumentUrl = ({ documentId, isDueDiligenceDocument, enabled = true, }) => {
    return useQuery({
        enabled,
        queryFn: async () => {
            const { url } = await request({
                url: `/underwrite/api/v1/documents/${documentId}`,
                params: { dueDiligenceDocument: isDueDiligenceDocument },
                method: 'GET',
            });
            return url;
        },
        queryKey: ['underwrite-documents', documentId, isDueDiligenceDocument, 'download'],
    });
};
