import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { authenticationActions } from '../_actions'

export function useSignOut() {
  const dispatch = useDispatch()
  return {
    signOut: useCallback(
      (sessionToken, noRedirect = false, isIdleTimeout = false, idleTimeoutMessage = '') => {
        dispatch(authenticationActions.signOut(sessionToken, noRedirect, isIdleTimeout, idleTimeoutMessage))
      },
      [dispatch]
    ),
  }
}
