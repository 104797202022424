import React, { useEffect, useState, useRef } from 'react'
import {
  getOperationTypes,
  getAccountTypes,
  getAccountTypeOperations,
  putOperationAccountMapping,
} from '../../../_services/operationAccountConnection.service'
import { notificationsActions } from '../../../_actions'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { FormRow } from '../../../_components/Forms/FormRow'
import styles from './OperationAccounts.module.css'
import { LoaderButton } from '../../../_components/LoaderButton'
import { ClipLoader } from 'react-spinners'
import { colors } from '../../../_constants/colors'

const flattenAccounts = (accounts) => {
  return accounts.reduce((flat, account) => {
    return flat.concat(account, flattenAccounts(account.subaccounts || []))
  }, [])
}

const getDefaultValues = (accountTypeOperations, operationTypes) => {
  return accountTypeOperations.reduce((values, mapping) => {
    const existingOperation = operationTypes.find((operation) => operation.id === mapping.operation_type_id)
    if (existingOperation) {
      values[existingOperation.name] = mapping.account_type_id
    }
    return values
  }, {})
}

export const OperationAccounts = () => {
  const [operations, setOperations] = useState([])
  const [accountTypes, setAccountTypes] = useState([])
  const [existingValues, setExistingValues] = useState([{}])
  const mounted = useRef(false)
  const saving = useRef(false)
  const formLoad = useRef(false)
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    reset,
  } = useForm({
    defaultValues: {},
  })
  const dispatch = useDispatch()

  useEffect(() => {
    mounted.current = true
    formLoad.current = true
    const getOperationMappingData = async () => {
      const operationTypesResponse = await getOperationTypes()
      const accountTypesResponse = await getAccountTypes()
      const accountTypeOperationsResponse = await getAccountTypeOperations()
      if (mounted.current) {
        const flattenedAccounts = flattenAccounts(accountTypesResponse)
        const transformedAccounts = flattenedAccounts.map((account) => ({
          display: `${account.account_number} - ${account.account_name}`,
          value: account.id,
        }))
        setOperations(operationTypesResponse)
        setAccountTypes(transformedAccounts)
        setExistingValues(getDefaultValues(accountTypeOperationsResponse, operationTypesResponse))
      }
      formLoad.current = false
    }
    getOperationMappingData()

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    reset(existingValues)
  }, [existingValues])

  const onSubmit = async (values) => {
    saving.current = true
    await putOperationAccountMapping(operations, values)
      .then(() => {
        dispatch(
          notificationsActions.showNotification({
            type: `success`,
            message: `Successfully saved operation account mapping`,
            id: `operation-map-success`,
          })
        )
        saving.current = false
      })
      .catch((error) => {
        dispatch(
          notificationsActions.showNotification({
            type: `error`,
            message: `Could not save operation account mapping`,
            id: `operation-map-success`,
          })
        )
        console.error(error)
        saving.current = false
      })
    saving.current = false
  }

  return (
    <div>
      <div>
        <h2>Operation Accounts</h2>
        <p className={styles['description-text']}>
          {
            'Here we can and associate available accounts to different operations used within \
          the application. This allows you to have different account usage depending on the \
          action taken by your organization.'
          }
        </p>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formLoad.current && (
            <div className={styles['loader']}>
              <ClipLoader color={colors.DEFAULT_SECONDARY} size={75} />
            </div>
          )}
          {!formLoad.current &&
            operations.map((operation) => {
              return (
                <div className={styles['form-row']} key={`${operation.name}-container`}>
                  <FormRow
                    defaultValue={existingValues[operation.name] || undefined}
                    disabled={false}
                    error={errors.operation?.message || null}
                    id={operation.id}
                    inputRef={() => {
                      return register(operation.name, {
                        required: false,
                      })
                    }}
                    key={operation.name}
                    label={operation.name}
                    layout={'inline'}
                    name={operation.name}
                    onChange={(value) => setValue(operation.name, value)}
                    options={accountTypes}
                    placeholder={'Select Account'}
                    tooltip={operation.description || ''}
                    type={'select'}
                  />
                </div>
              )
            })}
          <div className={styles['save-account-mapping-conatiner']}>
            <LoaderButton isLoading={saving.current} type="submit">
              Save Account Mappings
            </LoaderButton>
          </div>
        </form>
      </div>
    </div>
  )
}
