import styled from 'styled-components'

export const S_UserNotificationSettings = styled.div`
  table {
    border-spacing: 0;
  }
  th {
    font-size: 14px;
    font-weight: 500;
    color: #9eaeb5;
  }

  tr.border-bottom td {
    padding: 10px 10px;
    border-bottom: 1pt solid #d0d2d3;
  }

  tbody > tr:last-child > td {
    border-bottom: 0;
  }

  .submit {
    margin-top: 20px;
  }

  .groups_container_tab {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .add_groups_container {
    display: flex;
    flex-direction: row-reverse;
  }
`
