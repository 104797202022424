import { bankingAccountConstants } from '../_constants'

export function bankingAccount(state = {}, action) {
  switch (action.type) {
    case bankingAccountConstants.SET_BUSINESS_ACCOUNTS:
      return {
        ...state,
        businessId: action.businessId,
        accounts: action.accounts,
      }
    case bankingAccountConstants.CLEAR_BANKING_ACCOUNTS:
      return {}
    default:
      return state
  }
}
