import { UTCDateToSystemString } from '../dates'
import { lastApprovalToSystemtime } from './last-approval'

export const fundingRequestsToSystemTime = (funding) => {
  if (funding.created_at) {
    funding.created_at = UTCDateToSystemString(funding.created_at)
  }
  if (funding.updated_at) {
    funding.updated_at = UTCDateToSystemString(funding.updated_at)
  }

  if (funding.date) {
    funding.date = UTCDateToSystemString(funding.date)
  }

  if (funding.last_approval) {
    funding.last_approval = lastApprovalToSystemtime(funding.last_approval)
  }
  return funding
}
