import { UTCDateToSystemString } from './dates'
export const transactionDatesToSystemTime = (transactionResponse) => {
  transactionResponse.data = transactionResponse.data.map((transaction) => {
    transaction.date = transaction.date.split('T')[0]
    transaction.created_at = UTCDateToSystemString(transaction.created_at)
    transaction.updated_at = UTCDateToSystemString(transaction.updated_at)
    return transaction
  })
  return transactionResponse
}
