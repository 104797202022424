import { getDateInputFormat } from '../_helpers'

export const todayIsoDate = getDateInputFormat(new Date())

export const BUSINESS_ENTITY_OPTIONS = [
  { value: 'Corporation', label: 'Corporation' },
  { value: 'LLC', label: 'LLC' },
  { value: 'Partnership', label: 'Partnership' },
  { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
]

export const STD_TERMS_OF_SALE_OPTIONS = [
  { label: 'Net 30', value: 'Net 30' },
  { label: 'Net 60', value: 'Net 60' },
  { label: 'Net 90', value: 'Net 90' },
]

export const FINANCIAL_RADIO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const FURTHER_DETAILS_FIELD = {
  condition: true,
  type: 'textarea',
  table: 'business',
  label: 'Further Details',
  required: true,
}
export const INDUSTRY_OPTIONS = [
  { label: 'Apparel', value: 'Apparel' },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Biotechnology', value: 'Biotechnology' },
  { label: 'Building Maintenance', value: 'Building Maintenance' },
  { label: 'Chemicals', value: 'Chemicals' },
  { label: 'Communications', value: 'Communications' },
  { label: 'Consulting', value: 'Consulting' },
  { label: 'Distribution', value: 'Distribution' },
  { label: 'Education', value: 'Education' },
  { label: 'Electronics', value: 'Electronics' },
  { label: 'Energy', value: 'Energy' },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Food', value: 'Food' },
  { label: 'Government', value: 'Government' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Janitorial', value: 'Janitorial' },
  { label: 'Lumber', value: 'Lumber' },
  { label: 'Machinery', value: 'Machinery' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Media', value: 'Media' },
  { label: 'Printing', value: 'Printing' },
  { label: 'Security', value: 'Security' },
  { label: 'Shipping', value: 'Shipping' },
  { label: 'Staffing', value: 'Staffing' },
  { label: 'Technology', value: 'Technology' },
  { label: 'Telecommunications', value: 'Telecommunications' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Other', value: 'Other' },
]
