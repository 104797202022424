import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

// Components
import { FormInput } from '../../FormInput'
import { S_RadioGroup } from '../../FormInput/FormInput.styles'

const updateFromProps = (value, defaultValue) => {
  // Generally these will be uncontrolled
  const isControlled = value !== undefined
  let valueToUse = isControlled ? value : defaultValue
  return valueToUse
}

const StdTermsSaleField = (props) => {
  const { options = [], id = null, value, name, defaultValue, onChange = () => {}, inputRef, label, ...rest } = props
  const [displayValue, setDisplayValue] = useState(updateFromProps(value, defaultValue))

  useEffect(() => {
    setDisplayValue(updateFromProps(value, defaultValue))
  }, [id, value])

  const handleChangeLocal = (newValue, id) => {
    setDisplayValue(newValue)
    onChange(newValue, id)
  }

  return (
    <S_RadioGroup {...rest} className={`radio-group`} id={id}>
      <div className="buttons">
        {options.concat([{ label: 'Other', value: 'other' }]).map((opt) => (
          <div className={`radio-btn ${String(opt.value) === displayValue.option ? 'checked' : ''}`} key={opt.value}>
            <input
              defaultChecked={String(opt.value) === displayValue.option}
              id={`${id}-${opt.value}`}
              name={`${name}`}
              onChange={(e) => {
                handleChangeLocal({ option: e.target.value, value: '' }, id)
              }}
              ref={inputRef}
              type="radio"
              value={opt.value}
            />

            <label htmlFor={`${id}-${opt.value}`}>{opt.label}</label>
          </div>
        ))}
      </div>

      <div className="form-input-section">
        {displayValue.option === 'other' && (
          <FormInput
            defaultValue={displayValue.value}
            id={`${id}-input`}
            name={`${name}-input`}
            onChange={(e) => handleChangeLocal({ option: 'other', value: e.target.value }, id)}
          />
        )}
      </div>
    </S_RadioGroup>
  )
}

function mapStateToProps(state) {
  const { env } = state
  return {
    tenant: env.tenant,
  }
}

const connectedStdTermsSaleField = connect(mapStateToProps)(StdTermsSaleField)
export { connectedStdTermsSaleField as StdTermsSaleField }
