import { jsx as _jsx } from "react/jsx-runtime";
import { z } from 'zod';
import { useGetDebtorContacts } from 'xen/api/use-debtor/use-get-debtor-contacts';
import { FormSelect } from '../select';
export const FormDebtorContactSelect = ({ allowDeselect = false, label = 'Debtor Contact', nothingFoundMessage = 'No debtor contact found...', required = false, debtorId, ...props }) => {
    const { data: debtorContacts = [] } = useGetDebtorContacts({
        urlParams: { debtorId },
    });
    const selectDebtorContactsList = debtorContacts.map((debtorContact) => {
        return {
            label: `${debtorContact.firstName} (${debtorContact.email})`,
            value: String(debtorContact.id),
        };
    });
    return (_jsx(FormSelect, { allowDeselect: allowDeselect ?? !required, clearable: false, data: selectDebtorContactsList, label: label, nothingFoundMessage: nothingFoundMessage, required: required, searchable: true, ...props }));
};
const formDebtorContactSelectSchema = z.string().min(1, { message: 'Debtor contact is required' });
FormDebtorContactSelect.schema = formDebtorContactSelectSchema;
