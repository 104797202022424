import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

// Constants
import { LENDER_ROUTES } from '../_constants/routes'

// Helpers
import { isLender } from '../_helpers/user-utils'
import { isProductEnabled } from '../_helpers/flags'

export const UnderwriteLenderRoutes = ({ loggedIn, user, featureFlags }) => {
  // Only for lenders with underwrite enabled
  if (!isLender(loggedIn, user.user_type) || !isProductEnabled('Underwrite', featureFlags)) {
    return []
  }

  const underwriteClientsOverview = import(
    `../_views/LenderViews/UnderwriteClientsOverview/NewUnderwriteClientsOverview.jsx`
  )

  const prospectsOverview = import(`../_views/factoring/lender/NewProspectsOverview/index.js`)

  const prospectDetails = import('../_views/LenderViews/NewProspectDetails/index.js')

  let loadedModules = {}
  const pageComponents = {
    ProspectsOverview: prospectsOverview.then((m) => {
      loadedModules['UnderwriteClientsOverview'] = m
      return m
    }),
    UnderwriteClientsOverview: underwriteClientsOverview.then((m) => {
      loadedModules['UnderwriteClientsOverview'] = m
      return m
    }),
    ProspectDetails: prospectDetails.then((m) => {
      loadedModules['ProspectDetails'] = m
      return m
    }),
    NewProspect: import('../_views/LenderViews/NewProspect/index.js').then((m) => {
      loadedModules['NewProspect'] = m
      return m
    }),
    NewProspectUpdated: import('../_views/common/NewProspect/index').then((m) => {
      loadedModules['NewProspectUpdated'] = m
      return m
    }),
    NewClientApplication: import('../_views/common/NewClient/index.js').then((m) => {
      loadedModules['NewClient'] = m
      return m
    }),
    EditBusiness: import('../_views/LenderViews/EditBusiness/index.js').then((m) => {
      loadedModules['EditBusiness'] = m
      return m
    }),
  }

  const lenderRoutes = [
    // Prospects
    {
      path: LENDER_ROUTES.prospects_overview,
      Component: lazy(() => pageComponents['ProspectsOverview']),
      exact: true,
      module: 'ProspectsOverview',
    },
    {
      path: LENDER_ROUTES.clients_overview,
      Component: lazy(() => pageComponents['UnderwriteClientsOverview']),
      exact: true,
      module: 'UnderwriteClientsOverview',
    },
    {
      path: LENDER_ROUTES.new_prospect,
      Component: lazy(() => pageComponents['NewProspectUpdated']),
      module: 'NewProspectUpdated',
    },
    {
      path: LENDER_ROUTES.client_application,
      Component: lazy(() => pageComponents['NewClientApplication']),
      module: 'NewClientApplication',
    },
    {
      path: LENDER_ROUTES.underwrite_borrower_details + '/:businessId',
      Component: lazy(() => pageComponents['ProspectDetails']),
      module: 'ProspectDetails',
    },
  ]

  return lenderRoutes.map(({ path, Component, exact = false, module }) => (
    // Butt ugly, but this prevents the suspense fallback component from displaying unnecessicarily
    <Route
      exact={exact}
      key={path}
      path={path}
      render={(props) => {
        if (module && loadedModules[module]) {
          //Set status to resolved and result to default export of downloaded module to prevent briefly loading suspense component. See https://github.com/facebook/react/blob/master/packages/react/src/ReactLazy.js
          Component._status = 1
          Component._result = loadedModules[module].default
        }
        return <Component {...props} />
      }}
    />
  ))
}
