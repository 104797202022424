import { scheduleInvoicesConstants } from '../_constants'

export const scheduleInvoicesActions = {
  setPurchaseShouldNotify,
  setUploadShouldNotify,
}

function setPurchaseShouldNotify(shouldNotify = true) {
  return { type: scheduleInvoicesConstants.SET_PURCHASE_SHOULD_NOTIFY, payload: shouldNotify }
}

function setUploadShouldNotify(shouldNotify = true) {
  return { type: scheduleInvoicesConstants.SET_UPLOAD_SHOULD_NOTIFY, payload: shouldNotify }
}
