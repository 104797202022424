// Constants
import { featureFlagConstants } from '../_constants/featureFlags.constants'

// Services
export const getFeatureFlags = (flags) => {
  return (dispatch) => {
    dispatch(success(flags))
  }

  function success(featureFlags) {
    return { type: featureFlagConstants.GET_FEATURE_FLAGS_SUCCESS, featureFlags }
  }
}
