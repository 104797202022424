import React, { useEffect, useState } from 'react'
import { useUser } from '../../../_reduxHooks/user.hook'
import parse from 'html-react-parser'

// Components
import { StandardPage } from '../../../_components/layout/StandardPage'
import { formatValueByType, history } from '../../../_helpers'

import { S_UserAlert, S_TableHeader } from './UserAlert.styles'
import { Chip } from '../../../_components/AutoCompleteInput/Chip'
import { Text } from '../../../_components/Typography/Components/Text/Text'
import { PaginationControls } from '../../../_components/PaginationControls/PaginationControls'

import { userService } from '../../../_services/user.service'
import { LENDER_ROUTES } from '../../../_constants/routes'

import { EmptyMessage } from '../../../_components/EmptyMessage/EmptyMessage'
export const UserAlerts = () => {
  const { user } = useUser()

  const [userAlerts, setUserAlerts] = useState([])
  const [totalResults, setTotalResults] = useState(0)
  const [pageNum, setPageNum] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [pageSizes] = useState([10, 25, 50, 100])

  useEffect(() => {
    const fetchUserAlerts = async () => {
      const response = await userService.getUserNotifications(user.id, pageNum)
      setUserAlerts(response.results)
      setTotalResults(response.total_results)
    }
    fetchUserAlerts()
  }, [pageNum])

  const markAsRead = async (id) => {
    userService.markNotificationAsRead(id)
  }

  const redirectToEntity = (notification) => async () => {
    const { entity_type, entity_id } = notification.params

    await markAsRead(notification.id)
    if (!entity_type || !entity_id) return

    if (entity_type === 'funding_request') {
      history.push(LENDER_ROUTES.funding_requests + `/${entity_id}`)
    }
  }

  // alerts where title is null are not displayed
  // const notificationsSize = userAlerts.filter(alert => alert.title).length
  return (
    <StandardPage title="Notifications">
      <header className="page-header">
        <h1>{`Notifications (${totalResults})`}</h1>
      </header>
      <S_UserAlert>
        {userAlerts.map((alert) => {
          if (!alert.title) return null

          return (
            <S_TableHeader key={alert.id} onClick={redirectToEntity(alert)}>
              <div className="alert">
                <div className="subject">
                  <Text fs="16px" fw={700}>
                    {alert.title} {alert.id}
                    {!alert.read_at && <Chip className="new" showDelete={false} text={'New'} />}
                  </Text>
                </div>
                <div className="summary">
                  <p>{parse(alert.summary)}</p>
                </div>
                <div className="whodunnit">
                  <Text>{formatValueByType(alert.created_at, 'dateTime', { separator: ' ' })}</Text>
                </div>
              </div>
            </S_TableHeader>
          )
        })}
        {userAlerts.length === 0 && <EmptyMessage message={'No notifications'} />}

        {userAlerts.length > 0 && (
          <PaginationControls
            onNextPage={() => {
              setPageNum(pageNum + 1)
            }}
            onPageSelected={(page) => {
              setPageNum(page)
            }}
            onPageSizeChange={(newPageSize) => {
              setRecordsPerPage(+newPageSize)
              setPageNum(1)
            }}
            onPrevPage={() => {
              setPageNum(pageNum - 1)
            }}
            pageNum={pageNum}
            pageSize={recordsPerPage}
            pageSizes={pageSizes}
            totalResults={totalResults}
          />
        )}
      </S_UserAlert>
    </StandardPage>
  )
}
