import request from './axios_service'
import { createSearchParams } from '../_helpers/search'
// import { contentJsonHeader, tenantHeader, sessionHeader } from '../_helpers'

/** Get All Tenant Brokers
 * @param {URLSearchParams} params
 */
export const getTenantBrokers = (params) => {
  return request(
    {
      url: `/brokers?${params}`,
      method: 'GET',
    },
    null,
    true
  )
}

export const fetchBrokersSuggestions = async (searchText, setLoading = () => {}) => {
  let searchParams = createSearchParams({
    searchTerm: searchText,
    searchParam: 'search_term',
  })
  setLoading(true)
  let brokers = await getTenantBrokers(searchParams)
  setLoading(false)

  return brokers.data
}

export const getBroker = (brokerId) => {
  return request(
    {
      url: `/brokers/${brokerId}`,
      method: 'GET',
    },
    null,
    true
  ).then(({ data }) => {
    return data
  })
}

export const updateBroker = (brokerId, data) => {
  return request({
    url: `/brokers/${brokerId}`,
    method: 'PUT',
    data: data,
  })
}

/** Create Broker
 * @param {Object} data
 */
export const createTenantBroker = (data) => {
  return request({
    url: `/brokers/`,
    method: 'POST',
    data: data,
  })
}

/** Create Business Broker
 * @param {Object} data
 */
export const createBusinessBroker = (data) => {
  return request({
    url: `/business_commissions/`,
    method: 'POST',
    data: data,
  })
}

/** Get Business Brokers
 * @param {number} business_id
 */
export const getBusinessBrokers = (business_id) => {
  return request({
    url: `/broker_businesses?business_id=${business_id}`,
    method: 'GET',
  })
}

/** Delete Business Broker
 * @param {number} id
 */
export const deleteBusinessBroker = (id) => {
  return request({
    url: `/broker_businesses/${id}`,
    method: 'DELETE',
  })
}

/** Update Business Broker
 * @param {number} id
 * @param {Object} data
 */
export const updateBusinessBroker = (id, data) => {
  return request({
    url: `/broker_businesses/${id}`,
    method: 'PUT',
    data: data,
  })
}

/** Get Business Brokers
 * @param {number} business_id
 */
export const getBusinessBroker = (businessBrokerId) => {
  return request({
    url: `/broker_businesses/${businessBrokerId}`,
    method: 'GET',
  })
}
