import request from './axios_service'
import { officerDatesToSystemTime, officerDatesToUTC } from '../_helpers/business_officers'

export const businessOfficersService = {
  getBusinessOfficers,
  updateBusinessOfficer,
  updateBusinessOfficerBatch,
  createBusinessOfficer,
  removeBusinessOfficer,
  deleteBusinessOfficer,
  getBusinessOfficer,
}

function getBusinessOfficers(businessId) {
  return request({
    url: `/businesses/${businessId}/officers`,
    method: 'GET',
  }).then((officers) => officers.map(officerDatesToUTC))
}

function getBusinessOfficer(businessId, officerId) {
  return request({
    url: `/businesses/${businessId}/officers/${officerId}`,
    method: 'GET',
  }).then((officer) => officerDatesToSystemTime(officer))
}

function updateBusinessOfficer(attr, val, businessId, businessOfficerOrdinal) {
  let tempOfficer = { [attr]: val }
  tempOfficer = officerDatesToUTC(tempOfficer)
  return request({
    url: `/businesses/${businessId}/officers/${businessOfficerOrdinal}`,
    method: 'PUT',
    data: {
      business_officer: tempOfficer,
    },
  }).then(officerDatesToSystemTime)
}

function updateBusinessOfficerBatch(data, businessId, businessOfficerOrdinal) {
  return request({
    url: `/businesses/${businessId}/officers/${businessOfficerOrdinal}`,
    method: 'PUT',
    data: {
      business_officer: officerDatesToUTC(data),
    },
  }).then(officerDatesToSystemTime)
}

function createBusinessOfficer(businessId) {
  return request({
    url: `/businesses/${businessId}/officers`,
    method: 'POST',
  })
}

function removeBusinessOfficer(businessId, businessOfficerOrdinal) {
  return request({
    url: `/businesses/${businessId}/officers/${businessOfficerOrdinal}`,
    method: 'DELETE',
  })
}

function deleteBusinessOfficer(businessOfficerId) {
  return request({
    url: `/officers/${businessOfficerId}`,
    method: 'DELETE',
  })
}
