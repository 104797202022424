import { useQuery } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import { request } from 'xen/api';
async function getInvoiceScheduleNotificationPreview({ urlParams, }) {
    try {
        const { debtorId, scheduleId } = urlParams;
        // Returns an HTML string
        const response = await request({
            method: 'GET',
            url: `/emails/notify_schedule_debtors_preview/${scheduleId}/debtor/${debtorId}`,
        });
        // Sanitize the HTML string so we don't render any malicious code
        const data = DOMPurify.sanitize(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not fetch invoice schedule notification preview');
    }
}
export const useGetInvoiceScheduleNotificationPreview = (params) => {
    const { scheduleId, debtorId } = params.urlParams;
    return useQuery({
        queryKey: ['invoice-schedules', scheduleId, 'notifiable-debtors', debtorId, 'preview'],
        queryFn: () => getInvoiceScheduleNotificationPreview(params),
    });
};
