import styled from 'styled-components'
import { cssBreakpoints } from '../../_constants/styles'

const StyledMenuToggleConstants = {
  width: 40,
  height: 40,
  mobileWidth: 30,
  mobileHeight: 30,
}

export const StyledMenuToggle = styled.span`
  display: flex;
  font-size: 26px;
  width: ${StyledMenuToggleConstants.width}px;
  height: ${StyledMenuToggleConstants.width}px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  position: relative;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  &:hover {
    background: #f1f1f155;
  }

  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    width: ${StyledMenuToggleConstants.mobileWidth}px;
    height: ${StyledMenuToggleConstants.mobileHeight}px;
  }
`
