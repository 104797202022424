import { UTCDateToSystemString, dateToUTCString } from './dates'
import { invoiceDatesToSystemTime } from './invoices'
import { createSearchParams } from './search'
import { getABLBusinessesOverview } from '../_services/abl/abl-business.service'
import { getClients } from '../_services/businesses.service'
export const businessDateFields = [
  'created_at',
  'updated_at',
  'financing_required_date',
  'date_business_started',
  'became_client_at',
]

export const businessDatesToUTCString = (client) => {
  businessDateFields.forEach((dateType) => {
    if (client[dateType]) {
      client[dateType] = dateToUTCString(client[dateType]).split('T')[0]
    }
  })
  return client
}

export const businessDatesToSystemTime = (client) => {
  businessDateFields.forEach((dateType) => {
    if (client[dateType]) {
      client[dateType] = UTCDateToSystemString(client[dateType]).split('T')[0]
    }
  })
  return client
}

export const prospectsDatesToSystemTime = (prospect) => {
  prospect.created_at = UTCDateToSystemString(prospect.created_at)
  prospect.updated_at = UTCDateToSystemString(prospect.updated_at)
  return prospect
}

export const businessSummaryDatesToSystemTime = (summary) => {
  const deals = summary.deals || []
  deals.forEach((deal) => {
    const invoices = deal.invoices || []
    invoices.forEach((invoice) => invoiceDatesToSystemTime(invoice))
  })

  const business = summary.business || {}
  businessDatesToSystemTime(business)

  return summary
}

export const ABLBusinessOverviewObject = (business) => {
  return {
    address: {
      street: business.address_street,
      city: business.address_city,
      zip_code: business.address_zip_code,
      state: business.address_state,
      unit: business.address_unit,
    },
    phone: business.phone_number,
    fax: business.fax_number,
    creditLimit: business.limit,
  }
}

export const fetchAblBusinessSuggestions = async (searchText, setLoading = () => {}) => {
  let searchParams = createSearchParams({
    searchTerm: searchText,
    requestMethod: 'GET',
    searchParam: 'search_term',
  })
  setLoading(true)
  let businesses = await getABLBusinessesOverview(searchParams)
  setLoading(false)
  return businesses.data
}

export const fetchFactoringBusinessSuggestions = async (searchText, setLoading = () => {}) => {
  let searchParams = createSearchParams({
    searchTerm: searchText,
    requestMethod: 'POST',
  })
  setLoading(true)
  let businesses = await getClients(searchParams)
  setLoading(false)
  return businesses.results
}

export const getBusinessFullAddress = (business) => {
  const { address_street, address_unit, address_city, address_state, address_zip_code } = business
  return `${address_street}${address_unit ? ` ${address_unit}` : ''}${address_city ? `. ${address_city}` : '. '}${
    address_state ? `, ${address_state}` : ' '
  }${address_zip_code ? ` ${address_zip_code}` : ''}`
}
