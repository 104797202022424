import { isFeatureEnabled } from 'legacy/_helpers/flags'
import { createInternalLinkUrl } from 'xen/helpers'

// Returns if the current environment is localhost
export const isLocalhost = () => {
  const hostname = window.location.hostname
  const pieces = hostname.split('.')
  if (pieces && pieces.length) {
    return pieces[pieces.length - 1].indexOf('localhost') !== -1
  }
  return false
}

export const invoiceSchedulePath = (scheduleId) => {
  return isFeatureEnabled('xen_invoice_schedule')
    ? createInternalLinkUrl({ to: '/invoice-schedules/:scheduleId/edit', urlParams: [scheduleId] })
    : `/schedules/${scheduleId}`
}

export const paymentBatchPath = (settlementId) => {
  return createInternalLinkUrl({ to: '/invoice-settlements/:settlementId/edit', urlParams: [settlementId] })
}
