export const UPLOAD_ERRORS = {
  SCHEDULE_UPLOAD__BAD_TEMPLATE: 'The template is not formatted correctly. Please check the column names are correct.',
  SCHEDULE_UPLOAD__DUPLICATED_INVOICE_ERROR: 'Duplicate invoice ID.',
  SCHEDULE_UPLOAD__AMOUNT_NOT_POSITIVE: 'Amount for invoice is not a positive number.',
  SCHEDULE_UPLOAD__UNEXPECTED_ERROR: 'An unexpected error has occurred. Please contact tech support',
  SCHEDULE_UPLOAD__ID_PARSE_ERROR: 'Invalid ID.',
  SCHEDULE_UPLOAD__EMPTY_COLUMN_ERROR: 'Column is empty.',
  SCHEDULE_UPLOAD__EMPTY_FILEPATH_ERROR: 'No file supplied.',
  SCHEDULE_UPLOAD__DEBTOR_NOT_FOUND: 'Debtor does not exist.',
  SCHEDULE_UPLOAD__DEBTOR_INFERENCE_ERROR: 'Could not find matching debtor.',
  SCHEDULE_UPLOAD__DATE_PARSE_ERROR: 'Invalid date format.',
  SCHEDULE_UPLOAD__FUTURE_DATE_ERROR: 'Date is in the future.',
  SCHEDULE_UPLOAD__TERM_NOT_POSITIVE: 'Terms can not be a negative number.',
  SCHEDULE_UPLOAD__TERM_PARSE_ERROR: 'Invalid format for terms (needs to be an positive integer).',
  SCHEDULE_UPLOAD__AMOUNT_NOT_POSITIVE: 'Invoice amount is not a positive number.',
  SCHEDULE_UPLOAD__MAXIMUM_AMOUNT_EXCEEDED: 'Maximum amount exceeded.',
  SCHEDULE_UPLOAD__AMOUNT_PARSE_ERROR: 'Invalid format for amount (needs to be a positive number).',
  SCHEDULE_UPLOAD__DEBTOR_BUSINESS_MISSING: 'This Debtor is not currently associated with this Client.',
}

export const ABL_ERRORS = {
  UNEXPECTED_ERROR: 'An unexpected error has occurred. Please contact tech support',
}
