import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from 'xen-ui';
import { useAppShellNavBarContext } from 'xen/providers';
import { NavItem } from '../../link';
export const NavBarLink = ({ ...props }) => {
    const { isMobile } = useAppShellNavBarContext();
    const LinkComponent = isMobile ? MobileNavBarLink : DesktopNavBarLink;
    return _jsx(LinkComponent, { ...props });
};
// Private --------------------------------------------------------------------
const DesktopNavBarLink = ({ ...props }) => {
    const { isNavCollapsed, navWidth } = useAppShellNavBarContext();
    // When desktop nav is collapsed, only show icon inside link that still navigates on click
    if (isNavCollapsed) {
        return (_jsx(Tooltip, { label: props.label, position: "right", children: _jsx(NavItem.Link, { ...props, label: "", w: navWidth }) }));
    }
    return _jsx(NavItem.Link, { ...props });
};
const MobileNavBarLink = ({ ...props }) => {
    const { toggleNavVisibility } = useAppShellNavBarContext();
    // On mobile, close the nav when a link is clicked
    return _jsx(NavItem.Link, { ...props, onClick: toggleNavVisibility });
};
