import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'
import InputField from './InputField'

// Constants

// Components
import { S_RadioGroup } from '../form-fields/RadioGroup'

const StyledStdTermsSale = styled.div`
  display: block;

  .other-field-wrapper {
    margin-top: 10px;
  }
`

class StdTermsSale extends React.Component {
  constructor(props) {
    super(props)
    const { value } = props
    this.state = {
      is_other: false,
    }
  }

  render() {
    const { field, formikName, handleChange, handleBlur, invalid } = this.props
    return (
      <StyledStdTermsSale>
        <S_RadioGroup>
          {field.options.map((option, i) => (
            <Field
              key={i}
              name={formikName}
              render={({ field: formikField }) => {
                return (
                  <div className={`radio-btn`}>
                    <input
                      checked={option.value === formikField.value}
                      id={`${formikName}-${option.value}`}
                      name={formikField.name}
                      onChange={(e) => {
                        let fieldValue = e.target.value
                        if (fieldValue === `true`) {
                          fieldValue = true
                        } else if (fieldValue === `false`) {
                          fieldValue = false
                        } else if (fieldValue === `Net 30` || fieldValue === `Net 60` || fieldValue === `Net 90`) {
                          this.setState({
                            is_other: false,
                          })
                        }

                        handleChange(formikName, fieldValue)
                        handleBlur(formikName, field.name, fieldValue)
                      }}
                      type={`radio`}
                      value={option.value}
                    />
                    <label htmlFor={`${formikName}-${option.value}`}>{option.label}</label>
                  </div>
                )
              }}
              validate={(value) => {
                let errorMessage
                if ((field.required && value === '') || value === null) {
                  errorMessage = `Field is required.`
                }
                return errorMessage
              }}
            />
          ))}
          <div className={`radio-btn`}>
            <input
              checked={this.state.is_other}
              id={`${formikName}-other`}
              name={`${formikName}-other`}
              onChange={(e) => {
                let fieldValue = e.target.value
                this.setState({
                  is_other: fieldValue,
                })

                handleChange(formikName)
                handleBlur(formikName, field.name)
              }}
              type={`radio`}
              value={true}
            />
            <label htmlFor={`${formikName}-other`}>Other</label>
          </div>
        </S_RadioGroup>
        {this.state.is_other && (
          <div className="other-field-wrapper">
            <InputField field={field} formikName={formikName} handleBlur={handleBlur} invalid={invalid} />
          </div>
        )}
      </StyledStdTermsSale>
    )
  }
}

export default StdTermsSale
