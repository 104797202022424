import React, { useState, useRef, useEffect } from 'react'

// Components
import { H4 } from '../../Typography/Components/Headings/H4'

// Utils
import { S_ExpandButton } from './ValidationForm.styles'
import { getValidationRuleByFieldType } from './validationFormRules'
import { Button } from '../../Button'

const ValidationFormFields = (props) => {
  const {
    comparisonEntity, // This is only used once, by BBCCollateralIneligibles
    triggerValidation,
    onBlur,
    setError,
    register,
    errors,
    fields,
    setValue,
    onChange,
    isDisabled,
    isSubmitting,
    FieldComponent,
    formId,
    expandByDefault,
    watch,
    clearError,
    unregister,
  } = props
  const prevFieldsRef = useRef()

  useEffect(() => {
    prevFieldsRef.current = fields
  }, [])

  useEffect(() => {
    const prevFields = prevFieldsRef.current
    if (prevFields && prevFields.length) {
      prevFields.forEach((field) => {
        if (!fields.find((f) => f.id === field.id)) {
          unregister(field.id)
          clearError(field.id)
        }
      })
    }
    prevFieldsRef.current = fields
  }, [fields])
  const [expandedFields, setExpandedFields] = useState(expandByDefault)
  return fields.map(
    (
      {
        id,
        type,
        label,
        defaultValue,
        disabled = false,
        validate = {},
        layout = 'inline',
        after = null,
        required = true,
        isSectionTitle = false,
        isGroup = false, // This and groupFields are only used by ABLFeesModal
        groupFields = [],
        addKey,
        isLabel = false, // This doesn't appear to be used by anything
        Component = FieldComponent,
        watchFieldName = undefined,
        watchFieldValue = undefined,
        ...rest
      },
      index
    ) => {
      const isRequired = (typeof required === 'string' && required.toLowerCase() === 'required') || required
      const validationRules =
        Object.keys(validate).length || typeof validate === 'function' ? validate : getValidationRuleByFieldType(type)
      let watchField
      if (watchFieldName && watchFieldValue) {
        watchField = watch(watchFieldName)
      }
      if (isGroup && groupFields.length) {
        const groupLabel = rest?.groupLabel
        // Recursively call ValidationFormFields to wrap grouped fields :(
        return (
          <div className={'validation-form-row'} key={`addForm-${index}`}>
            {groupLabel ? <div className="validation-form-label">{groupLabel}</div> : null}
            <div className={'validation-form-row-fields'}>
              {!rest?.expands || expandedFields.includes(rest?.groupId) ? (
                <ValidationFormFields
                  FieldComponent={FieldComponent}
                  addKey={index}
                  comparisonEntity={comparisonEntity}
                  errors={errors}
                  fields={groupFields}
                  formId={formId}
                  isDisabled={isDisabled}
                  isSubmitting={isSubmitting}
                  onBlur={onBlur}
                  onChange={onChange}
                  register={register}
                  setError={setError}
                  setValue={setValue}
                  triggerValidation={triggerValidation}
                />
              ) : (
                <S_ExpandButton>
                  <Button
                    onClick={() => {
                      setExpandedFields((prev) => [...prev, rest?.groupId])
                    }}
                    text={'+'}
                  />
                </S_ExpandButton>
              )}
            </div>
          </div>
        )
      }
      return (
        <React.Fragment key={`${id}-validation-form-${index}${formId}${addKey}`}>
          {!isSectionTitle && !isLabel ? (
            <Component
              comparisonValue={comparisonEntity ? comparisonEntity[id] : null}
              defaultValue={defaultValue}
              disabled={disabled || isDisabled || isSubmitting}
              error={errors[id] ? errors[id].message : null}
              id={`${formId}${id}`}
              inputRef={() => {
                return register(id, {
                  required:
                    watchField !== undefined && watchFieldValue !== undefined
                      ? watchField === watchFieldValue
                      : isRequired,
                  validate: validationRules,
                })
              }}
              key={`${formId}${id}${addKey}`}
              label={label}
              layout={layout}
              name={id}
              // TODO: Need to make sure formrow is returning the value and id, then add setError as a function
              onBlur={async (v) => {
                let val = v.target.value
                let isValid = await triggerValidation(id)
                onBlur(id, setError, val, isValid)
              }}
              onChange={(newValue) => {
                const trimmedValue = typeof newValue === 'string' ? newValue.trim() : newValue
                setValue(id, trimmedValue, true)
                onChange(id, trimmedValue)
              }}
              required={isRequired}
              setError={setError}
              type={type}
              {...rest}
            />
          ) : null}

          {isSectionTitle ? (
            <H4 className={'form-section-title'} mb={'10px'}>
              {label}
            </H4>
          ) : null}
          {isLabel ? <div className="validation-form-label">{label}</div> : null}

          {after}
        </React.Fragment>
      )
    }
  )
}

export default ValidationFormFields
