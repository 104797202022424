import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useAuthentication } from 'xen/hooks';
const Profile = lazy(() => import('../pages/profile'));
export const USERS_PATHS = {
    profile: '/users/profile',
};
export const UsersRoutes = () => {
    const { profile } = USERS_PATHS;
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    return [
        _jsx(Route, { exact: true, path: profile, children: _jsx(Profile, {}) }, profile),
    ];
};
