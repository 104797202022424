import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const defaultFormValues = [];
const FormValuesContext = createContext(defaultFormValues);
export const FormValuesProvider = ({ children, formValues }) => {
    return _jsx(FormValuesContext.Provider, { value: formValues ?? defaultFormValues, children: children });
};
export const useFormValuesContext = () => {
    const context = useContext(FormValuesContext);
    return context;
};
