import { jsx as _jsx } from "react/jsx-runtime";
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { XenThemeProvider } from 'xen-ui';
import { Error } from 'xen/components/fallbacks';
export const ErrorBoundary = ({ children, fallbackRender = DefaultFallback }) => {
    return _jsx(ReactErrorBoundary, { fallbackRender: fallbackRender, children: children });
};
// Private --------------------------------------------------------------------
const DefaultFallback = ({ error }) => {
    return (_jsx(XenThemeProvider, { children: _jsx(Error, { message: error.message, title: "Unexpected application error" }) }));
};
