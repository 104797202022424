import { cancelTokenConstants } from '../_constants'

export const cancelTokenActions = {
  setCancelToken,
}

function setCancelToken(source) {
  return (dispatch) => {
    dispatch(success(source))
  }

  function success(source) {
    return { type: cancelTokenConstants.SET_CANCEL_TOKEN_SUCCESS, source }
  }
}
