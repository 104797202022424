import { tableWidthsConstants } from '../_constants'

export const tableWidthsActions = {
  getColumnsWidths,
  setColumnsWidths,
}

function getColumnsWidths(columns, uniqueTableId) {
  if (!columns) {
    return {}
  }
  return {
    type: tableWidthsConstants.GET_COLUMNS_WIDTH,
    columns: appendWidthsToColumns(columns, uniqueTableId),
  }
}

function setColumnsWidths({ isResizingColumn, columnWidths }, uniqueTableId) {
  if (isResizingColumn) {
    return {
      type: tableWidthsConstants.GET_COLUMNS_WIDTH,
    }
  }

  const localData = getLocalData(uniqueTableId)

  const updatedData = {
    ...localData,
    [uniqueTableId]: { ...localData[uniqueTableId], ...columnWidths },
  }

  setLocalData(updatedData)

  return {
    type: tableWidthsConstants.SET_COLUMNS_WIDTH,
    columns: updatedData,
  }
}

function getLocalData() {
  const localStorageData = localStorage.getItem(tableWidthsConstants.LOCALSTORAGE_NAME) || '{}'
  return JSON.parse(localStorageData)
}

function setLocalData(updatedData) {
  localStorage.setItem(tableWidthsConstants.LOCALSTORAGE_NAME, JSON.stringify(updatedData))
}

function appendWidthsToColumns(columns, uniqueTableId) {
  let updatedColumns = []
  const localData = getLocalData()[uniqueTableId]
  if (!localData) {
    return columns
  }

  updatedColumns = columns.map((item) => {
    let key = item.id || item.accessor

    if (localData[key]) {
      item.width = localData[key]
    }

    return item
  })

  return updatedColumns
}
