import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Components
import { Button } from '../../../_components/Button'
import { FormField } from '../../../_components/Forms/FormField'

// Services
import { getTenantVerificationTypes, createTenantVerificationType } from '../../../_services/fvadmin_service'

const S_VerificationNotes = styled.div`
  display: flex;
  .section {
    flex-basis: 33%;
    margin-top: 16px;
    background-color: #eee;
    padding: 8px;
  }
  .add-controls {
    display: flex;
  }
  .add-btn {
    margin-left: 16px;
  }
  .neutral {
    margin-left: 16px;
    margin-right: 16px;
  }
  .type-display {
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #ddd;
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: white;
  }
`

export const VerificationNotes = ({ admin_tenant }) => {
  const [loading, setLoading] = useState(false)
  const [noteTypes, setNoteTypes] = useState(null)
  const [negative, setNegative] = useState('')
  const [neutral, setNeutral] = useState('')
  const [positive, setPositive] = useState('')
  useEffect(() => {
    setNoteTypes(null)
  }, [admin_tenant])
  useEffect(() => {
    if (!loading && !noteTypes) {
      setLoading(true)
      getTenantVerificationTypes(admin_tenant).then((r) => {
        setNoteTypes(r)
        setLoading(false)
      })
    }
  })
  return (
    <S_VerificationNotes>
      {noteTypes ? (
        <>
          <div className={`section negative`}>
            <h4>Negative</h4>
            {noteTypes
              .filter((nt) => nt.sentiment === 'negative')
              .map((nt) => {
                return (
                  <div className={`type-display`} key={nt.id}>
                    {nt.name}
                  </div>
                )
              })}
            <div className={`add-controls`}>
              <FormField onChange={(val) => setNegative(val)} placeholder="Type Name" type="text" value={negative} />
              <Button
                className={`add-btn`}
                onClick={() => {
                  setNegative('')
                  createTenantVerificationType(admin_tenant, 'negative', negative).then(async () => {
                    let types = await getTenantVerificationTypes(admin_tenant)
                    setNoteTypes(types)
                  })
                }}
                text={`Add`}
              />
            </div>
          </div>
          <div className={`section neutral`}>
            <h4>Neutral</h4>
            {noteTypes
              .filter((nt) => nt.sentiment === 'neutral')
              .map((nt) => {
                return (
                  <div className={`type-display`} key={nt.id}>
                    {nt.name}
                  </div>
                )
              })}
            <div className={`add-controls`}>
              <FormField onChange={(val) => setNeutral(val)} placeholder="Type Name" type="text" value={neutral} />
              <Button
                className={`add-btn`}
                onClick={() => {
                  setNeutral('')
                  createTenantVerificationType(admin_tenant, 'neutral', neutral).then(async () => {
                    let types = await getTenantVerificationTypes(admin_tenant)
                    setNoteTypes(types)
                  })
                }}
                text={`Add`}
              />
            </div>
          </div>
          <div className={`section positive`}>
            <h4>Positive</h4>
            {noteTypes
              .filter((nt) => nt.sentiment === 'positive')
              .map((nt) => {
                return (
                  <div className={`type-display`} key={nt.id}>
                    {nt.name}
                  </div>
                )
              })}
            <div className={`add-controls`}>
              <FormField onChange={(val) => setPositive(val)} placeholder="Type Name" type="text" value={positive} />
              <Button
                className={`add-btn`}
                onClick={() => {
                  setPositive('')
                  createTenantVerificationType(admin_tenant, 'positive', positive).then(async () => {
                    let types = await getTenantVerificationTypes(admin_tenant)
                    setNoteTypes(types)
                  })
                }}
                text={`Add`}
              />
            </div>
          </div>
        </>
      ) : null}
    </S_VerificationNotes>
  )
}
