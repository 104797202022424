import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useAuthentication } from 'xen/hooks';
import { useConfigurationContext } from 'xen/providers';
const Debtors = lazy(() => import('../pages/debtors'));
export const DEBTORS_PATHS = {
    root: '/debtors',
    /** This is an old Xen route that should be updated when we address debtors in Xen 1.0
     * we are using it here to use legacy logic for contacts page. This will also help
     * keep track of changing this where it is used through typing when it's updated
     */
    contacts: '/debtors/:debtorId/contacts',
};
export const DebtorsRoutes = () => {
    const { contacts, root } = DEBTORS_PATHS;
    const { tenant: { factoring }, } = useConfigurationContext();
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    return [
        _jsx(Route, { exact: true, path: root, children: factoring.enabled ? _jsx(Debtors, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, root),
        _jsx(Route, { exact: true, path: contacts, render: ({ match }) => {
                const path = `/debtor_details/${match.params.debtorId}/contacts`;
                return _jsx(Redirect, { to: path });
            } }, contacts),
    ];
};
