import { UTCDateToSystemString } from './dates'
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Lender
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const isLender = (loggedIn, userType) => {
  return loggedIn && userType === 'lender'
}

export const isSalesPersonPermission = (user = {}) => {
  return isSalesPerson(user) && !isAdminUser(user) && !isOperationsUser(user)
}

export const isSalesPerson = (user = {}) => {
  return user?.is_sales_person
}

export const isAdminUser = (user = {}) => {
  return user?.is_admin
}

export const isOperationsUser = (user = {}) => {
  return user?.is_operations
}

export const isUnderwriterUser = (user = {}) => {
  return user?.is_underwriter
}

// export const isABLLender = (loggedIn, isClient, userType) => {
//     return loggedIn && !isClient && userType === 'lender'
// }

// export const isFactoringLender = (loggedIn, isClient, userType) => {
//     return loggedIn && !isClient && userType === 'lender'
// }

export const userDatesToSystemTime = (user) => {
  if (user) {
    user.created_at = UTCDateToSystemString(user.created_at)
    user.updated_at = UTCDateToSystemString(user.updated_at)
  }
  return user
}
