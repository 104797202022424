import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

// Constants

// Helpers
import { isLender } from '../_helpers/user-utils'

import { Build } from '../FVAdminPanel/Build'

export const AdminRoutes = ({ loggedIn, user }) => {
  // Only for lenders
  if (!isLender(loggedIn, user.user_type) && user.tenant_id == 1) {
    return []
  }

  const FVAdminPanel = import('../FVAdminPanel/FVAdminPanel')
  const TenantDetails = import('../FVAdminPanel/TenantDetails')
  const TenantUsers = import('../FVAdminPanel/TenantUsers/index')
  const TenantOriginateFormConfig = import('../FVAdminPanel/TenantOrginateFormConfig/index')
  const DefaultFeatureFlags = import('../FVAdminPanel/DefaultFeatureFlags/index')
  const DefaultProductFlags = import('../FVAdminPanel/DefaultProductFlags/index')
  const FeatureFlags = import('../FVAdminPanel/FeatureFlags/index')
  const ProductFlags = import('../FVAdminPanel/ProductFlags/index')
  const TenantConfig = import('../FVAdminPanel/TenantConfig/index')

  const lenderRoutes = [
    // Generic Lender Dashboard
    {
      path: '/fv_admin_panel',
      Component: lazy(() => FVAdminPanel),
    },
    {
      path: '/tenant_details',
      Component: lazy(() => TenantDetails),
    },
    {
      path: '/tenant_users',
      Component: lazy(() => TenantUsers),
    },
    {
      path: '/email_field_config',
      Component: lazy(() => TenantOriginateFormConfig),
    },
    {
      path: '/default_feature_flags',
      Component: lazy(() => DefaultFeatureFlags),
    },
    {
      path: '/default_product_flags',
      Component: lazy(() => DefaultProductFlags),
    },
    {
      path: '/build',
      Component: Build,
    },
    {
      path: '/feature_flags',
      Component: lazy(() => FeatureFlags),
    },
    {
      path: '/product_flags',
      Component: lazy(() => ProductFlags),
    },
    {
      path: '/tenant_config',
      Component: lazy(() => TenantConfig),
    },
  ]

  return lenderRoutes.map(({ path, Component, exact = false }) => (
    <Route component={Component} exact={exact} key={path} path={path} />
  ))
}
