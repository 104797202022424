import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { z, ZodError } from 'zod';
import { TextInput as XenTextInput } from 'xen-ui';
import { ErrorText } from '../../error-text';
export function FormNumberInput({ name, control, defaultValue, error, rules, shouldUnregister, onChange, ...props }) {
    const { field: { value, onChange: fieldOnChange, ...field }, fieldState, } = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const fieldError = error ?? fieldState.error?.message;
    return (_jsx(XenTextInput, { error: fieldError ? _jsx(ErrorText, { component: "span", message: fieldError }) : undefined, inputWrapperOrder: ['label', 'description', 'error', 'input'], onChange: (e) => {
            fieldOnChange(e);
            onChange?.(e);
        }, styles: { root: { display: 'flex', flexDirection: 'column', gap: '.25rem' }, ...props.styles }, value: value, ...field, ...props }));
}
const numberInputSchema = ({ integerOnly = false, label, maxVal, minVal, name, required = false, }) => z.string().refine((val) => {
    if (required && val === '') {
        throw new ZodError([{ path: [name], message: `${label} is required`, code: 'custom' }]);
    }
    const numVal = Number(val);
    if (isNaN(numVal)) {
        throw new ZodError([{ path: [name], message: `${label} must be a valid number`, code: 'custom' }]);
    }
    if (integerOnly && !Number.isInteger(numVal)) {
        throw new ZodError([{ path: [name], message: `${label} must be a whole number`, code: 'custom' }]);
    }
    if (minVal && !Number.isNaN(minVal) && numVal < minVal) {
        throw new ZodError([
            {
                path: [name],
                message: `${label} must be at least ${minVal}`,
                code: 'too_small',
                minimum: minVal,
                inclusive: false,
                type: 'number',
            },
        ]);
    }
    if (maxVal && !Number.isNaN(maxVal) && numVal > maxVal) {
        throw new ZodError([
            {
                path: [name],
                message: `${label} must be ${maxVal} or less`,
                code: 'too_big',
                maximum: maxVal,
                inclusive: false,
                type: 'number',
            },
        ]);
    }
    return true;
}, {
    message: `${label} must be valid number`,
});
FormNumberInput.schema = numberInputSchema;
