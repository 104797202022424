import styled, { css } from 'styled-components'
import { addMediaStyles } from '../../_constants/stylesMediaBreakpoints'

export const S_ModalComponent = styled.div`
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4) !important;
  transition: background-color 0.1s;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  .swal2-popup {
    border-radius: 0;
  }

  .swal2-close {
    position: static;
    margin-left: auto;
  }

  .icon.icon-compact {
    &.modal-back-btn,
    &.modal-close-btn {
      padding: 0;
      font-size: 25px;
    }
  }

  .modal-top-controls {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding: 10px 10px 0px 10px;
  }

  .modal-content {
    ${addMediaStyles(
      'md',
      css`
        padding: 30px 40px;
      `
    )}
    padding: 35px 15px 15px 15px;
    width: auto;
    display: flex;
    margin: auto;
    -webkit-tap-highlight-color: transparent;
  }

  .body {
    margin-top: 8px;
    color: #9eaeb6;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.76px;
  }

  &.mobile-full-screen-modal {
    ${addMediaStyles(
      'xs',
      css`
        padding: 0;
        .modal-content {
          height: 100%;
          width: 100%;
        }
      `
    )}
    ${addMediaStyles(
      'md',
      css`
        padding: 0;
        .modal-content {
          width: auto;
          height: auto;
        }
      `
    )}
  }

  ${(props) => (props && props.modal_additionalStyles ? props.modal_additionalStyles : '')}
`
