import { store } from './store'

export function sessionHeader() {
  const state = store.getState()
  const authentication = state.authentication

  let sessionTokenHeader = {}
  let accessTokenHeaders = {}

  if (authentication.sessionToken) {
    sessionTokenHeader = { 'session-token': authentication.sessionToken }
  }

  if (authentication.accessToken) {
    accessTokenHeaders = { Authorization: 'Bearer ' + authentication.accessToken }
  }

  if (sessionTokenHeader || accessTokenHeaders) {
    return Object.assign(sessionTokenHeader, accessTokenHeaders)
  }
}
