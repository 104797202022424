import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from 'xen-ui';
import { TextLink } from '../link';
const featureNames = {
    documentAnalysis: 'Document analysis',
};
export const DisabledFeatureAlert = ({ feature, children, ...props }) => {
    const featureName = featureNames[feature];
    const defaultChildren = (_jsxs(_Fragment, { children: ["The ", _jsx("b", { children: featureName }), " feature is disabled for your company.", ' ', _jsx(TextLink, { c: "blue", to: `mailto:hello@xenplatforms.com?subject=XEN disabled feature - ${featureName}`, children: "Contact XEN" }), ' ', "to enable it."] }));
    return (_jsx(Alert, { color: "blue", icon: "Lock", title: "Feature disabled", variant: "light", ...props, children: children || defaultChildren }));
};
