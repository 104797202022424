import { store, history } from '../../_helpers'
import { reportsUpdateActions, scheduleInvoicesActions } from '../../_actions'
import Noty from 'noty'
import { isLender } from '../../_helpers/user-utils'
import ROUTES from '../../_constants/routes'

const showNotification = (message, forwardLink) => {
  new Noty({
    layout: `topRight`,
    type: `success`,
    text: message,
    closeWith: ['click', 'button'],
  })
    .on('onClick', function () {
      history.push(forwardLink)
    })
    .show()
}

const onMessage = (message) => {
  if (message?.message?.message_type === 'reports_update') {
    store.dispatch(reportsUpdateActions.setReportsLastUpdate(JSON.stringify(new Date())))
    const { reportsUpdate } = store.getState()
    if (reportsUpdate?.reportsShouldNotify) {
      store.dispatch(reportsUpdateActions.setReportsShouldNotify(false))
      showNotification('Report is ready to download.', '/generated-reports')
    }
  }

  if (message?.message?.message_type === 'purchase_update_finished') {
    const { invoiceSchedules } = store.getState()
    if (invoiceSchedules?.purchaseInvoicesShouldNotify) {
      const scheduleId = parseInvoiceScheduleMessage(message.message.value)
      store.dispatch(scheduleInvoicesActions.setPurchaseShouldNotify(false))
      showNotification('Invoice schedule has been purchased.', `/schedules/${scheduleId}`)
    }
  }

  if (message?.message?.message_type === 'upload_invoices_finished') {
    const { invoiceSchedules, user, authentication } = store.getState()
    const isLenderUser = isLender(authentication?.loggedIn, user.user_type)

    if (invoiceSchedules?.uploadInvoicesShouldNotify) {
      const scheduleId = parseInvoiceScheduleMessage(message.message.value)
      store.dispatch(scheduleInvoicesActions.setUploadShouldNotify(false))
      const forwardLink = isLenderUser
        ? `/schedules/${scheduleId}`
        : `${ROUTES.client_invoice_submission}/${scheduleId}`
      showNotification('The invoices have been uploaded. Click here', forwardLink)
    }
  }
}

const parseInvoiceScheduleMessage = (messageValue) => {
  let scheduleId = ''
  if (messageValue) {
    const scheduleReturn = messageValue.split(':')
    if (scheduleReturn && scheduleReturn[1]) {
      scheduleId = scheduleReturn[1]
    }
  }
  return scheduleId
}

const respondsTo = ['reports_update']
const channel = 'ReportsChannel'

export const reportsWebsocketService = { onMessage, respondsTo, channel }
