import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Container, Divider, Flex, Image, Stack, XenIcon } from 'xen-ui';
import { useConfigurationContext } from 'xen/providers';
import { TextLink } from '../link';
import { Logo, PoweredByXen } from './burger-and-logo';
export const AuthLayout = ({ children }) => {
    const config = useConfigurationContext();
    const contactUsUrl = config.tenant.branding.urls.contactUs;
    const homePageUrl = config.tenant.branding.urls.homepage;
    return (_jsx(Container, { h: "100%", p: "xl", children: _jsxs(Stack, { gap: "xl", h: "100%", children: [_jsx(LogoLink, { homePageUrl: homePageUrl }), _jsxs(Flex, { align: { base: undefined, sm: 'center' }, direction: { base: 'column', sm: 'row' }, gap: { base: 'md', sm: 'xl' }, h: "100%", p: { base: 0, sm: 'xl' }, w: "100%", children: [_jsx(Box, { px: { base: 0, sm: 'xl' }, w: "100%", children: _jsx(Stack, { gap: "xl", children: children }) }), _jsx(Divider, { orientation: "vertical", visibleFrom: "xs" }), _jsx(Box, { px: { base: 0, sm: 'xl' }, w: "100%", children: _jsxs(Stack, { gap: "xl", children: [_jsx(Image, { alt: "", fallbackSrc: "https://placehold.co/480x480", fit: "contain", flex: 1, h: "auto", src: config.tenant.branding.images.marketingColumn, w: "100%" }), contactUsUrl && (_jsxs(TextLink, { style: { display: 'flex', alignItems: 'center', gap: '0.25rem' }, to: contactUsUrl, children: [_jsx(XenIcon.ExternalLink, {}), "Contact us"] }))] }) })] })] }) }));
};
// Private --------------------------------------------------------------------
const LogoLink = ({ homePageUrl }) => {
    const content = (_jsxs(Flex, { align: "center", component: "span", style: { position: 'relative' }, children: [_jsx(Logo, {}), _jsx(Flex, { align: "center", component: "span", fz: "0.5625em", gap: "0.125rem", style: {
                    bottom: '-0.125rem',
                    lineHeight: '0.75rem',
                    position: 'absolute',
                    right: '0',
                    transform: 'translateY(100%)',
                }, children: _jsx(PoweredByXen, {}) })] }));
    const styles = {
        alignItems: 'center',
        color: 'inherit',
        display: 'flex',
        maxHeight: '9rem',
        maxWidth: '16rem',
        position: 'relative',
    };
    if (homePageUrl) {
        return (_jsx(TextLink, { style: styles, to: homePageUrl, underline: "never", children: content }));
    }
    return _jsx(Box, { style: styles, children: content });
};
