import React from 'react'
const ImgLink = ({
  src,
  href,
  alt,
  width = '100%',
  height = '100%',
  target = '',
  title = '',
  className,
  cssSetsHeight = false,
}) => {
  if (cssSetsHeight) {
    return (
      <a href={href} target={target} title={title}>
        <img alt={alt} className={className} src={src} />
      </a>
    )
  } else {
    return (
      <a className={className} href={href} target={target} title={title}>
        <img alt={alt} height={height} src={src} width={width} />
      </a>
    )
  }
}
export default ImgLink
