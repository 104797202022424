import styled from 'styled-components'
import { colors } from '../../../_constants/colors'
export const S_ChangePassword = styled.div`
  min-width: 450px;
  .save-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 16px;
  }
  header {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .err-msg {
      color: ${colors.MATERIAL_RED};
    }
  }
`
