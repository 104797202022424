import { tenantConstants } from '../_constants'

export function tenant(state = {}, action) {
  switch (action.type) {
    case tenantConstants.SET_TENANT_SUCCESS:
      return {
        ...state,
        ...action.tenant,
      }

    default:
      return state
  }
}
