import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useAuthentication, useFeatureFlags } from 'xen/hooks';
const LinkedExternalAccounts = lazy(() => import('../pages/linked-external-accounts'));
export const LINKED_EXTERNAL_ACCOUNTS_PATHS = {
    root: '/linked-external-accounts',
};
export const LinkedExternalAccountsRoutes = () => {
    const { root } = LINKED_EXTERNAL_ACCOUNTS_PATHS;
    const { bankingIntegration } = useFeatureFlags();
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    return [
        _jsx(Route, { path: root, children: bankingIntegration ? _jsx(LinkedExternalAccounts, {}) : _jsx(FeatureNotEnabled, { feature: "Banking Integration" }) }, root),
    ];
};
