import { dealConstants } from '../_constants'

const initialState = {
  deal: {},
  deal_invoices: [],
  deal_invoice_documents: [],
  deal_debtors: [],
  deal_business_debtors: [],
  deal_supporting_documents: [],
}

export function deal(state = initialState, action) {
  switch (action.type) {
    case dealConstants.GET_DEAL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case dealConstants.GET_DEAL_SUCCESS:
      return {
        ...state,
        ...action.deal,
        loading: false,
      }
    case dealConstants.GET_DEAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case dealConstants.UPDATE_DEAL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case dealConstants.UPDATE_DEAL_SUCCESS:
      return {
        ...state,
        deal: action.deal,
        loading: false,
      }
    case dealConstants.UPDATE_DEAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case dealConstants.UPLOAD_DEAL_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case dealConstants.UPLOAD_DEAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        deal_supporting_documents: state.deal_supporting_documents.concat({
          document_type: action.file.document_type,
          document_name: action.file.filename,
          updated_at: new Date(),
        }),
        loading: false,
      }
    case dealConstants.UPLOAD_DEAL_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case dealConstants.CREATE_DEAL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case dealConstants.CREATE_DEAL_SUCCESS:
      return {
        ...state,
        deal: action.deal,
        loading: false,
      }
    case dealConstants.CREATE_DEAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}
