import request from './axios_service'

import {
  businessDatesToSystemTime,
  businessSummaryDatesToSystemTime,
  businessDatesToUTCString,
} from '../_helpers/business'

export const businessService = {
  getBusiness,
  getBusinessSummary,
  updateBusiness,
  updateBusinessBatch,
  getClientBbcConfig,
  getPortfolioSummary,
  getLastSubmittedBbc,
  getUnsubmittedBbc,
  createNewBbc,
  saveAssetData,
  saveIneligibleData,
  submitBbc,
  createBusinessDebtor,
  getBusinessActivity,
  createNewProspect,
  getBusinessesByName,
  getLedgerSummary,
}

function getBusiness(businessId, includeArchived = false) {
  const params = includeArchived ? `?include_archived=true` : ''
  return request({
    url: `/businesses/${businessId}${params}`,
    method: 'GET',
  }).then(businessDatesToUTCString)
}

function getPortfolioSummary(businessId, params = '') {
  return request({
    url: `/businesses/${businessId}/portfolio_summary?${params.toString()}`,
    method: 'GET',
  })
}

function getBusinessSummary(businessId) {
  return request({
    url: `/businesses/get_business_summary/${businessId}`,
    method: 'GET',
  }).then(businessSummaryDatesToSystemTime)
}

function getLedgerSummary(clientId) {
  return request({
    url: `/businesses/${clientId}/ledger_summary`,
    method: 'GET',
  })
}

function updateBusiness(attr, val, businessId) {
  return request({
    url: `/businesses/${businessId}`,
    method: 'PUT',
    uncancelable: true,
    data: { [attr]: val },
  })
}

function updateBusinessBatch(data, businessId) {
  return request({
    url: `/businesses/${businessId}`,
    method: 'PUT',
    data: data,
  })
}

// /operate/businesses/:business_id/bbc_form
function getClientBbcConfig(businessId) {
  return request({
    url: `/operate/businesses/${businessId}/bbc_form`,
    method: 'GET',
  })
}

function getLastSubmittedBbc(businessId) {
  return request({
    url: `/operate/borrowing_base_certificates?business_id=${businessId}&submitted=true`,
    method: 'GET',
  })
}

function getUnsubmittedBbc(businessId) {
  return request({
    url: `/operate/borrowing_base_certificates?business_id=${businessId}&submitted=false`,
    method: 'GET',
  })
}

function createNewBbc(businessId = null) {
  return request({
    url: `/operate/borrowing_base_certificates${businessId ? '?business_id=' + businessId : ''}`,
    method: 'POST',
    data: {},
  })
}

function saveAssetData(assetId, data) {
  return request({
    url: `/operate/assets/${assetId}`,
    method: 'PUT',
    data: data,
  })
}

function saveIneligibleData(ineligibleId, data) {
  return request({
    url: `/operate/ineligibles/${ineligibleId}`,
    method: 'PUT',
    data: data,
  })
}

// Sets the Bbc identified by bbcId as submitted, making it the
function submitBbc(bbcId) {
  return request({
    url: `/operate/borrowing_base_certificates/${bbcId}`,
    method: 'PUT',
    data: { submitted: true },
  })
}

function createBusinessDebtor(businessId, dataSource, debtorName) {
  const dataObj = {
    business_id: businessId.toString(),
    data_source: dataSource,
    debtor_name: debtorName,
  }

  return request({
    url: `/business_debtors`,
    method: `POST`,
    data: dataObj,
  })
}

function getBusinessActivity(businessId, params = '') {
  return request({
    url: `/events/by_business/${businessId}?${params}`,
    method: 'GET',
  }).then((activities) => {
    activities.events = activities.events.map((client) => businessDatesToSystemTime(client))
    return activities
  })
}

async function getBusinessesByName(business_name) {
  return await request({
    url: `/businesses/by_name?business_name=${business_name}`,
    method: 'GET',
  })
}

// Prospects

function createNewProspect(data) {
  return request(
    {
      url: `/facade/registrations/sales_create`,
      method: 'POST',
      data: data,
    },
    null,
    false,
    false
  )
}
