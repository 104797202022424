import styled from 'styled-components'
import { ButtonComponent } from '../../Button'
import { S_Text } from '../../Typography/Components/Text/Text.styles'

export const S_FilterInput = styled.div`
  ${ButtonComponent} {
    display: flex;
  }
  .radio-btn {
    min-width: calc(33.3% - 10px);
  }
`

export const S_FilterField = styled.div`
  max-width: calc(50% - 10px);
  margin: 5px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px 15px;
`

export const S_FilterFieldHead = styled.div`
  position: relative;
  cursor: pointer;
  ${S_Text} {
    transition: color 0.2s ease-in-out;
  }
  &:hover {
    ${S_Text} {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
  &:after {
    border-right: 1.5px solid ${(props) => props.theme.colors.secondary};
    border-top: 1.5px solid ${(props) => props.theme.colors.secondary};
    content: '';
    display: inline-block;
    height: 8px;
    margin-right: 0.5em;
    position: absolute;
    right: 0;
    top: 0;
    transition:
      transform 0.3s ease-in-out,
      top 0.3s ease-in-out;
    transform: rotate(135deg);
    width: 8px;
  }
  &.opened {
    &:after {
      top: 6px;
      transform: rotate(-45deg);
    }
  }
`

export const S_FilterFieldBody = styled.div`
  padding-top: 0;
  max-height: 0px;
  overflow: hidden;
  transform-origin: top;
  transition:
    max-height 0.2s ease-in-out,
    padding-top 0.2s ease-in-out;

  &.opened {
    max-height: 250px;
    padding-top: 15px;
    overflow: visible;
  }
`
