import { borrowingBaseCertificateConstants } from '../_constants'

const initialState = {
  report_date: '',
  total_accounts_receivable: '',
  equipment_date: '',
  valuation_balance_sheet_date: '',
  total_inventory_amount: '',
  total_equipment_amount: '',
}

export function borrowingBaseCertificate(state, action) {
  switch (action.type) {
    case borrowingBaseCertificateConstants.UPDATE_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.data,
        },
      }
    case borrowingBaseCertificateConstants.CLEAN_FORM_DATA:
      return { ...state, formData: initialState }
    case borrowingBaseCertificateConstants.CLEAN_UPLOADED_DOCUMENT:
      return { ...state, docUpload: {} }
    case borrowingBaseCertificateConstants.SET_ID:
      return { ...state, bbcId: action.id }
    case borrowingBaseCertificateConstants.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        docUpload: {
          ...state.docUpload,
          [action.name]: {
            loading: true,
          },
        },
      }
    case borrowingBaseCertificateConstants.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        docUpload: {
          ...state.docUpload,
          [action.name]: {
            loading: false,
          },
        },
      }
    case borrowingBaseCertificateConstants.UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        docUpload: {
          ...state.docUpload,
          [action.name]: {
            loading: false,
            error: action.error,
          },
        },
      }
    default:
      if (state) {
        return state
      } else {
        return {
          formData: initialState,
          docUpload: {},
        }
      }
  }
}
