import { jsx as _jsx } from "react/jsx-runtime";
import { useDeleteApplicationAccess } from 'xen/api/use-integration-plaid';
import { PlaidButton } from 'xen/_modules/linked-external-accounts/components';
export const PlaidDisconnect = ({ institutionId, entityType, }) => {
    const { isPending: loading, isError, mutate: deleteApplicationAccess } = useDeleteApplicationAccess();
    if (isError)
        return _jsx("div", { "data-testid": "error-message", children: "Error disconnecting accounts. Please try again." });
    const handleDisconnect = () => {
        deleteApplicationAccess({ institutionId, entityType });
    };
    return _jsx(PlaidButton, { color: "red", loading: loading, onClick: handleDisconnect, text: "Disconnect" });
};
