import React from 'react'
import { Field } from 'formik'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const numberMask = createNumberMask({
  allowDecimal: true,
})

const removeCharacters = (value) => {
  if (value) {
    return Number(value.replace(/[^0-9.-]+/g, ''))
  } else {
    return null
  }
}

const CurrencyField = ({ field, formikName, handleBlur, invalid, onChange = null, inputTestID = null }) => {
  const onChangeObj = onChange ? { onChange: onChange } : {}
  return (
    <Field
      className={invalid ? 'invalid-input' : ''}
      name={formikName}
      render={({ field: formikField }) => {
        return (
          <MaskedInput
            {...formikField}
            mask={numberMask}
            onBlur={(e) => {
              handleBlur(formikName, field.name, removeCharacters(e.target.value))
            }}
            {...onChangeObj}
            data-testid={inputTestID}
            defaultValue={field.value}
            inputMode="numeric"
            placeholder={field.placeholder}
            style={{
              width: `100%`,
              height: `50px`,
              borderRadius: `5px`,
              border: `1px solid ${invalid ? `#d93025` : `#ccc`}`,
              padding: `1em`,
              fontSize: `1rem`,
              outline: `none`,
            }}
          />
        )
      }}
      validate={(value) => {
        let errorMessage
        if (field.required && !value) {
          errorMessage = `Field is required.`
        }
        return errorMessage
      }}
    />
  )
}

export default CurrencyField
