import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forEach } from 'lodash';
import { Group, MIME_TYPES, Stack, Text, Dropzone as XenDropzone, XenIcon } from 'xen-ui';
import { ErrorText } from '../error-text';
const acceptTypesDefault = ['jpeg', 'png', 'gif', 'pdf', 'csv', 'docx', 'xlsx'];
/** 1024 kilobytes ** 2 = 1 megabyte (MB) * 10 = 10MB */
const maxSizeDefault = 10 * 1024 ** 2;
const ChildrenDefault = ({ accept, isError, multiple, }) => {
    const uploadTerm = multiple ? 'files' : 'file';
    return (_jsx(Group, { align: "center", justify: "center", children: _jsxs(Stack, { align: "center", gap: "xs", justify: "center", px: "xs", py: "xs", children: [_jsx(XenIcon.CloudUpload, { size: "md" }), _jsxs(Text, { inline: true, size: "sm", children: ["Drag ", uploadTerm, " here or click to select ", uploadTerm] }), _jsx(Text, { c: "dimmed", size: "xs", children: accept.join(', ') }), isError && _jsx(ErrorText, { message: `Error uploading ${uploadTerm}` })] }) }));
};
export const Dropzone = ({ uploadFile, accept = acceptTypesDefault, maxSize = maxSizeDefault, isError = false, isLoading = false, multiple = true, children = _jsx(ChildrenDefault, { accept: accept, isError: isError, multiple: multiple }), }) => {
    return (_jsx(XenDropzone, { accept: accept.map((type) => MIME_TYPES[type]), "aria-label": "Upload files", "data-testid": "xen-dropzone", loading: isLoading, maxSize: maxSize, onDrop: (files) => {
            forEach(files, (file) => {
                uploadFile({ file: file });
            });
        }, onReject: (files) => console.error('rejected files', files), p: "sm", role: "button", children: children }));
};
