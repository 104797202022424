import { transactionsConstants } from '../_constants'

export function transactions(state = {}, action) {
  switch (action.type) {
    case transactionsConstants.SET_TRANSACTIONS_LAST_UPDATE:
      return {
        ...state,
        transactionsLastUpdate: action.payload,
      }
    default:
      return state
  }
}
