import request from './axios_service'
import { downloadPdfFile } from '../_helpers/download.js'

export const createQuoteTemplate = (data) => {
  return request({
    url: `/v2/quote_template`,
    method: 'POST',
    data: data,
  })
}

export const getQuoteTemplates = () => {
  return request({
    url: `/v2/quote_template`,
    method: 'GET',
  })
}

export const getQuoteTemplate = (id) => {
  return request({
    url: `/v2/quote_template/${id}`,
    method: 'GET',
  })
}

export const updateQuoteTemplate = (id, data) => {
  return request({
    url: `/v2/quote_template/${id}`,
    method: 'PUT',
    data: data,
  })
}

export const createQuote = (business_id, data) => {
  return request({
    url: `/v2/businesses/${business_id}/quotes`,
    method: 'POST',
    data: data,
  })
}

export const previewQuote = (business_id, data) => {
  return request({
    url: `/v2/businesses/${business_id}/quotes/preview`,
    method: 'POST',
    data: data,
    responseType: 'arraybuffer',
  }).then(
    (resp) => resp,
    (error) => {
      var decodedString = String.fromCharCode.apply(null, new Uint8Array(error))
      var obj = JSON.parse(decodedString)
      var message = obj['message']
      console.error(message)
    }
  )
}

export const quotesApi = {
  createQuoteTemplate,
  getQuoteTemplates,
  getQuoteTemplate,
  updateQuoteTemplate,
  createQuote,
  previewQuote,
}
