import { underwriteDealConstants } from '../_constants'

export function underwriteDeal(state = {}, action) {
  switch (action.type) {
    case underwriteDealConstants.SET_PROSPECT_URL:
      return {
        applicationURL: action.loggedDeal.applicationURL,
      }
    default:
      return state
  }
}
