import React, { useEffect } from 'react'
import get from 'lodash/get'
import { history } from '../../_helpers'

// Styles
import { S_ModalComponent } from './Modal.styles'
import { S_Subtitle } from '../Typography/Titles.styles'

// Hooks
import { modalActionHooks } from '../../_actionHooks'
import { useSelector } from 'react-redux'
import { Button } from '../Button'
import { IoArrowBackSharp, IoCloseSharp } from 'react-icons/io5'
import { RenderIf } from '../../components/helpers/RenderIf/RenderIf'
import { Box } from '../../components/Box/Box'

const ModalHooks = () => {
  const modal = useSelector((state) => state.modal)
  const closeModal = modalActionHooks.useCloseModal()
  const switchModal = modalActionHooks.useSwitchModal()
  const { content, prev_modal, props, modalProps } = modal
  const modalTitle = modalProps?.modalTitle
  const preventCloseOnRouting = modalProps?.preventCloseOnRouting

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      closeModal()
      // Pass true to onclose to indicate that user was explicitly attempting to close the modal
      modalProps?.onClose?.(true)
    }
  }
  const handleModalClick = (event) => {
    event.stopPropagation()
  }

  if (!preventCloseOnRouting) {
    useEffect(() => {
      let unlistenHistory = history.listen(() => {
        closeModal()
      })

      return () => {
        unlistenHistory()
      }
    }, [])
  }

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
      modalProps?.onClose?.(false)
    }
  }, [])
  return (
    <S_ModalComponent
      className={`modal-container swal2-container ${props?.className ? props.className : ''}`}
      modal_additionalStyles={props && props.modal_additionalStyles}
    >
      <div className={`modal-content swal2-popup`} onClick={handleModalClick}>
        <Box alignItems={'center'} className={'modal-top-controls'} d={'flex'} justifyContent={'space-between'}>
          <RenderIf condition={prev_modal}>
            <Button
              aria-label="Back"
              className="icon icon-compact modal-back-btn"
              onClick={() => {
                const prevModalProps = get(modal, 'prev_modal_props', {})
                switchModal(prev_modal, prevModalProps)
              }}
            >
              <IoArrowBackSharp />
            </Button>
          </RenderIf>

          <Button
            aria-label="Close this dialog"
            className="icon icon-compact modal-close-btn swal2-close"
            onClick={() => {
              closeModal()
              // Pass true to onclose to indicate that user was explicitly attempting to close the modal
              modalProps?.onClose?.(true)
            }}
          >
            <IoCloseSharp />
          </Button>
        </Box>

        {modalTitle && <S_Subtitle align={modalProps?.titleAlign}>{modalTitle}</S_Subtitle>}
        {content ? React.createElement(content, props) : closeModal()}
      </div>
    </S_ModalComponent>
  )
}

export { ModalHooks as Modal }
