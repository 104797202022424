import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from 'xen-ui';
import { useCreateInvoiceDocument, useGetInvoiceDocuments } from 'xen/api/use-invoice';
import { DocumentList, Dropzone } from 'xen/components';
export const InvoiceFileManager = ({ invoice }) => {
    const { mutate: uploadInvoiceDocument, isError, isPending, } = useCreateInvoiceDocument({ urlParams: { invoiceId: invoice.id } });
    const { data: invoiceDocuments } = useGetInvoiceDocuments({ urlParams: { invoiceId: invoice.id } });
    const uploadDocument = ({ file }) => {
        uploadInvoiceDocument({ payload: { file } });
    };
    return (_jsxs(Stack, { children: [_jsx(Dropzone, { isError: isError, isLoading: isPending, uploadFile: uploadDocument }), invoiceDocuments && invoiceDocuments.count > 0 && _jsx(DocumentList, { documents: invoiceDocuments.documents })] }));
};
