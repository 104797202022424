import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Components
import { SearchBar } from '../SearchBar'
import { S_SearchableOverview } from './SearchableOverview.styles'
import { SearchableView, NEW_ENTITY_PAGE_STRATEGIES } from '../SearchableView'
import { SearchFilter, setFormattedFilters } from '../SearchFilter'

/**
 * A list of searchable views and an optional search bar
 */
export const SearchableOverview = ({
  allowDownload = true,
  change,
  defaultFilters = [],
  filterColumns = [],
  getSetSearchFilter = () => {},
  getSetSearchTerm = () => {},
  searchFilterCallback = () => {},
  searchParam = 'search_term',
  searchParamCallback = () => {},
  searchPlaceholder = 'Search',
  showLoading = false,
  showSearchBar = true,
  showSearchFilter = false,
  views = [],
  editableMode = false,
  newEntityPageStrategy = NEW_ENTITY_PAGE_STRATEGIES['DEFAULT'],
  CustomSearchFilter = null,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  const [setInnerSearchFilter, setSetInnerSearchFilter] = useState(null)

  const handleSearchTermChange = (newTerm) => {
    setSearchTerm(newTerm)
    searchParamCallback(newTerm)
  }

  const handleSearchFiltersChange = (newFilters) => {
    setSearchFilters(newFilters)
    searchFilterCallback(newFilters)
  }

  useEffect(() => {
    getSetSearchTerm(setSearchTerm)
  }, [])

  const removeFilter = (filter) => {
    let newFilters = [...searchFilters]
    let toRemove = newFilters.findIndex((f) => f.column.fieldName === filter.column.fieldName)
    newFilters.splice(toRemove, 1)
    const formattedFilters = setFormattedFilters(newFilters)
    setInnerSearchFilter(formattedFilters)
  }

  const filterWithoutInput = !showSearchBar && showSearchFilter
  return (
    <S_SearchableOverview>
      {!CustomSearchFilter ? (
        <div className={`search-filter ${filterWithoutInput ? 'align-with-table' : ''}`}>
          {/* Search Term Filter */}
          {showSearchBar && <SearchBar onChange={handleSearchTermChange} placeholder={searchPlaceholder} />}

          {/* Advanced Search Filter */}
          {showSearchFilter && (
            <SearchFilter
              className={filterWithoutInput ? 'align-with-table' : ''}
              columns={filterColumns}
              defaultFilters={defaultFilters}
              getSetSearchFilter={(fn) => {
                setSetInnerSearchFilter(() => fn)
                getSetSearchFilter(fn)
              }}
              onChange={handleSearchFiltersChange}
            />
          )}
        </div>
      ) : (
        <CustomSearchFilter
          getSetSearchFilter={(fn) => {
            setSetInnerSearchFilter(() => fn)
            getSetSearchFilter(fn)
          }}
          handleSearchFiltersChange={handleSearchFiltersChange}
          handleSearchTermChange={handleSearchTermChange}
          removeFilter={removeFilter}
          searchFilters={searchFilters}
        />
      )}
      {/* Sub-views */}
      {views.map((viewConfig, i) => (
        <SearchableView
          allowDownload={allowDownload}
          change={change}
          editableMode={editableMode}
          key={i}
          newEntityPageStrategy={newEntityPageStrategy}
          removeFilter={removeFilter}
          searchFilters={searchFilters}
          searchParam={searchParam}
          searchTerm={searchTerm}
          showLoading={showLoading}
          viewConfig={viewConfig}
        />
      ))}
    </S_SearchableOverview>
  )
}

SearchableOverview.propTypes = {
  allowDownload: PropTypes.bool,
  change: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  defaultFilters: PropTypes.array,
  filterColumns: PropTypes.array,
  getSetSearchFilter: PropTypes.func,
  getSetSearchTerm: PropTypes.func,
  searchFilterCallback: PropTypes.func,
  searchParam: PropTypes.string,
  searchParamCallback: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  showLoading: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  showSearchFilter: PropTypes.bool,
  views: PropTypes.array,
}
