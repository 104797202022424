import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'
import colors from '../../_constants/colors'

export const StyledField = styled(({ invalid, ...props }) => <Field {...props} />)`
  border-radius: 5px;
  border: 1px solid ${(props) => (props.invalid ? `${colors.RED_ERROR}` : `${colors.NO_VERIFICATION}`)};
  color: ${colors.TEXT_DARK};
  font-size: 1rem;
  min-height: 150px;
  outline: none;
  padding: 1em;
  width: 100%;
`

const TextArea = ({ field, formikName, handleBlur, invalid }) => {
  return (
    <>
      <StyledField
        className={invalid ? 'invalid-input' : ''}
        component={`textarea`}
        invalid={invalid}
        name={formikName}
        onBlur={(e) => {
          handleBlur(formikName, field.name, e.target.value)
        }}
        placeholder={field.placeholder}
        type={field.type}
        validate={(value) => {
          let errorMessage

          if (field.required && !value) {
            errorMessage = `${field.label || `Field`} is required.`
          }

          return errorMessage
        }}
      />
    </>
  )
}

export default TextArea
