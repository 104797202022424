import React from 'react'
import { Link } from 'react-router-dom'

// Helpers
import { UTCDateToSystemString } from '../_helpers/dates'
import DateFormatter from '../_helpers/date-formatter'

// Components
import { ApprovalStatus } from '../_components/ApprovalStatus'
import get from 'lodash/get'

// Performance saving list for multiple currency values
const formatList = {}

/**
 * Returns a formatted currency value for the specific currency type and locale.
 * @param {number} amount
 * @param {string} currency
 * @param {string} locale
 */
export const formatCurrency = (amount = 0, currency = 'USD', locale = 'en-US') => {
  if (!amount || isNaN(Number(amount))) {
    amount = 0
  }
  const formatKey = currency + locale
  if (!formatList[formatKey]) {
    formatList[formatKey] = new Intl.NumberFormat(locale, { style: 'currency', currency: currency })
  }
  const currencyFormat = formatList[formatKey]
  let formattedAmount = currencyFormat.format(amount)
  if (formattedAmount === '-$0.00') formattedAmount = '$0.00'

  return formattedAmount
}

/**
 * Converts number to compact currency value via Intl.NumberFormat
 * FE: 100 -> $100, 1000 -> $1K, 10000 -> $1M
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @param {number} value
 */
export const getCompactCurrencyValue = (value) => {
  let compactFormatter = Intl.NumberFormat('en', { notation: 'compact', style: 'currency', currency: 'USD' })
  return compactFormatter.format(value)
}

/**
 * Like formatCurrency, except formats more nicely for inputs
 * This is a hard problem to solve
 * TODO: This only works for US dollars. Use this eventually for complete solution:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat/formatToParts
 * @param {number} amount
 * @param {string} currency
 * @param {string} locale
 */
export const formatCurrencyField = (amount = '', currency = 'USD', locale = 'en-US') => {
  if (amount === '' || amount === null || amount === undefined) {
    return ''
  }
  const initialStringAmount = amount + ''
  // Fix display values with a single decimal point
  const initialDecimalPart = initialStringAmount.split('.')
  const onlyOneDecimal = initialDecimalPart.length > 1 && initialDecimalPart[1].length === 1
  const stringAmount = onlyOneDecimal ? initialStringAmount + '0' : initialStringAmount

  const formatted = formatCurrency(amount, currency, locale)

  if (stringAmount.indexOf('.') !== -1) {
    if (stringAmount.length === 1) {
      return '$0.'
    }
    const decimalLength = stringAmount.split('.')[1].length
    if (decimalLength === 0) {
      return formatted.split('.')[0] + '.'
    }
    if (decimalLength === 1) {
      return formatted.substring(0, formatted.length - 1)
    }
    return formatted
  }
  // This split is intentional, since formatting will always return a fractional
  return formatted.split('.')[0]
}

/**
 * Returns a formatted ineligible currency value for the specific currency type and locale.
 * @param {number} amount
 * @param {string} currency
 * @param {string} locale
 */
export const formatIneligible = (amount = 0, currency = 'USD', locale = 'en-US') => {
  return `(${formatCurrency(amount, currency, locale)})`
}

/**
 * Returns a formatted percentage value, unrounded
 * @param {number} decimalVal
 * @param {number} decimals
 */
export const formatPercentage = (decimalVal, decimals = 2) => {
  if (!isFinite(decimalVal)) {
    return `-`
  }

  let displayVal = decimalVal * 100
  if (decimalVal % 1 !== 0) {
    displayVal = displayVal.toFixed(decimals)
  }
  return `${displayVal}%`
}

/**
 * Returns a formatted percentage value rounded to the nearest integer
 * @param {number} decimalVal
 */
export const formatIntPercentage = (decimalVal) => {
  return `${Math.floor(decimalVal * 100)}%`
}

/**
 * Returns a formatted decimal
 * @param {number} decimalVal
 */
export const formatDecimal = (decimalVal, decimals = 4) => {
  if (!isFinite(decimalVal)) return '-'
  let displayVal = Number(decimalVal).toFixed(decimals)
  return displayVal
}

/**
 * Returns a formatted phone number given a phone number
 * Note: Only formats US numbers properly for now
 * @param {number, string} phoneNumber
 */
export const formatPhoneNumber = (phoneNumber) => {
  const phoneNum = phoneNumber + ''
  // Check if US number (assumes 10-digit nums are US)
  if (phoneNum.length === 10 || (phoneNum.indexOf('+1') !== -1 && phoneNum.length === 12)) {
    const cleanedNum = phoneNum.replace('+1', '')
    const matches = cleanedNum.match(/^(\d{3})(\d{3})(\d{4})$/)
    return `(${matches[1]}) ${matches[2]} ${matches[3]}`
  }
  return phoneNum
}

/**
 * Returns a formatted value based on the valueType.
 * @param {number, string} value
 * @param {string} valueType
 * @param {object} options
 */
export const formatValueByType = (value, valueType, options = null) => {
  switch (valueType) {
    case 'currency':
      return formatCurrency(value || 0)
    case 'inneligible':
      return formatIneligible(value)
    case 'approvalStatus':
      return <ApprovalStatus status={value} />
    case 'percentage':
      return formatPercentage(value)
    case 'offsetDate':
      return UTCDateToSystemString(value).split('T')[0]
    case 'truncatedDate':
      return value ? value.split('T')[0] : '-'
    case 'date':
      return value ? new Date(value).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '-'
    case 'decimal':
      return value ? formatDecimal(value) : '-'
    case 'dateTime':
      const timeFormatter = new DateFormatter(value)
      const oDate = get(options, 'date', null)
      const oTime = get(options, 'time', null)
      const date = timeFormatter.getDate(oDate)
      const time = timeFormatter.getTime(oTime)
      const separator = get(options, 'separator', '•')
      return `${date} ${separator} ${time}`
    case 'link':
      return (
        <Link to={options.to}>
          <strong>{value}</strong>
        </Link>
      )
    default:
      return value
  }
}

/**
 * Returns a number based on a formatted string currency value
 * @param {string} value
 * @returns {number}
 */
export const currencyToNum = (value) => {
  if (value === '.') {
    return 0
  }
  return Number(value.replace(/[^\d.]/g, ''))
}

/**
 * Capitalizes the first letter of a string and lowercases the rest
 * @param {string} value
 * @returns {string}
 */
export const capitalizeString = (string) => {
  const str = string || ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
