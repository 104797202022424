import { jsx as _jsx } from "react/jsx-runtime";
import { z } from 'zod';
import { useGetDebtorsSearch } from 'xen/api/use-debtor';
import { FormSelect } from '../select';
import { debtorSelectTransform } from './debtors-select-transform';
export const FormDebtorSelect = ({ label = 'Debtor', nothingFoundMessage = 'No Debtor Found...', error, allowDeselect, required, ...props }) => {
    const { data: debtorsList = [], isError, isSuccess } = useGetDebtorsSearch({ debtorName: '' });
    let dataError = '';
    if (isError)
        dataError = 'Could not fetch debtors';
    if (isSuccess && debtorsList.length === 0)
        dataError = 'No debtors exist';
    const selectDebtorList = debtorSelectTransform(debtorsList);
    return (_jsx(FormSelect, { allowDeselect: allowDeselect ?? !required, data: selectDebtorList, error: dataError || error, label: label, nothingFoundMessage: nothingFoundMessage, required: required, searchable: true, ...props }));
};
const debtorSelectSchema = z.string().min(1, { message: 'Debtor is required' });
FormDebtorSelect.schema = debtorSelectSchema;
