import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChangePassword } from 'legacy/_components/modals/changePassword';
import { Alert, Button, CopyButton, Group, Heading, Stack, Text } from 'xen-ui';
import { useGetSalesPeople } from 'xen/api/use-sales-person';
import { Page, PageTitle } from 'xen/components';
import { createInternalLinkUrl } from 'xen/helpers';
import { useUserContext } from 'xen/providers';
const Profile = () => {
    const user = useUserContext();
    let mySignUpLink = '';
    const { data: salesPeople = [] } = useGetSalesPeople();
    const salesPerson = salesPeople.find((person) => person.user.id === user.id);
    if (salesPerson) {
        const signUpPath = createInternalLinkUrl({
            to: '/sign-up',
            searchParams: { sp: String(salesPerson.id) },
        });
        mySignUpLink = new URL(signUpPath, window.location.origin).toString();
    }
    return (_jsxs(Page, { children: [_jsx(PageTitle, { title: "User profile" }), _jsxs(Stack, { gap: "sm", children: [_jsx(Heading, { level: 2, children: "Info" }), _jsxs(Stack, { gap: "xs", children: [_jsxs(Text, { children: [_jsx("b", { children: "Name" }), ": ", user.name] }), _jsxs(Text, { children: [_jsx("b", { children: "Email" }), ": ", user.emailAddress] })] })] }), _jsxs(Stack, { gap: "sm", children: [_jsx(Heading, { level: 2, children: "Security" }), _jsx(ChangePassword, { email: user.emailAddress, user_id: user.id })] }), mySignUpLink && (_jsxs(Stack, { gap: "sm", children: [_jsx(Heading, { level: 2, children: "My sign up link" }), _jsx(Text, { children: "As a sales person, new users will be assigned to you when they use this link." }), _jsxs(Group, { children: [_jsx(Alert, { py: "xs", variant: "light", children: mySignUpLink }), _jsx(CopyButton, { value: mySignUpLink, children: ({ copied, copy }) => (_jsx(Button, { icon: copied ? 'CheckMark' : 'Copy', onClick: copy, variant: "outline", children: copied ? 'Copied' : 'Copy URL' })) })] })] }))] }));
};
export default Profile;
