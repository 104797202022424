import moment from 'moment'

import TENANTS from '../_constants/tenants'
import { FaMinus, FaPlus, FaSearch } from 'react-icons/fa'

function PropertyProxy(CONFIG_OBJ, DEFAULT) {
  for (let key in DEFAULT) {
    Object.defineProperty(CONFIG_OBJ, key, {
      writable: false,
      enumerable: true,
      value: key in CONFIG_OBJ ? CONFIG_OBJ[key] : DEFAULT[key],
    })
  }
  return CONFIG_OBJ
}

function ConfigProxy(CONFIG_OBJ, DEFAULT) {
  for (let tenant of TENANTS) {
    if (!CONFIG_OBJ.hasOwnProperty(tenant)) {
      CONFIG_OBJ[tenant] = DEFAULT
    }
  }
  //Two loops to make sure any bold configs that need to be set to default are set first
  //TODO: Remove this code once Bold is entirely on new system
  for (let tenant of TENANTS) {
    if (tenant === 'boldbusinesscapitalold' || tenant === 'boldbusinesscapitalnew') {
      CONFIG_OBJ[tenant] = CONFIG_OBJ['boldbusinesscapital']
    }
  }
  return CONFIG_OBJ
}
/**
 * Returns a date string formatted as yyyy-mm-dd
 * @param {Date} date
 * @returns {String}
 */
const FormatDateForDB = (date) => {
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
    '-' +
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
  )
}

const getUTCDateHourZero = (date) => {
  const zeroDate = date ? moment(date) : moment()
  zeroDate.second(0).minute(0).hour(0)

  const utcDate = zeroDate.utc()
  return utcDate.format()
}

const AppendParams = (searchParams, toAppend) => {
  let appendParams = new URLSearchParams(toAppend)
  if (!searchParams) {
    searchParams = new URLSearchParams()
  }
  if (appendParams) {
    for (let [key, val] of appendParams) {
      // Check to not allow inclusive operators if an exclusive one already exists.
      const isInclusive = key.endsWith('[]')
      if (isInclusive) {
        const exclusiveKey = key.slice(0, key.length - 2)
        const exclusiveExists = !!searchParams.get(exclusiveKey)

        if (exclusiveExists) {
          continue
        }
      }
      searchParams.append(key, val)
    }
  }
  return searchParams
}

// Sleep function to mock delay responses
export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function getIcon(icon = '') {
  switch (icon) {
    case '+':
      return FaPlus

    case '-':
      return FaMinus
    case '-':
      return FaMinus
    case 'search':
      return FaSearch
    default:
      return null
  }
}
export { PropertyProxy, ConfigProxy, FormatDateForDB, AppendParams, getUTCDateHourZero }
