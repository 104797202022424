import * as XLSX from 'xlsx'
import { FormatDateForDB } from '../util'

/**
 *
 * @param {Blob} data
 */
//should be able to pass in mosty spreadsheet types, csv, xlsx, xml etc. . .
export const getFirstSheet = (data) => {
  const workbook = XLSX.read(data, { type: 'binary', cellDates: true })
  const sheet = workbook.Sheets[workbook.SheetNames[0]]

  let sheetData = XLSX.utils.sheet_to_json(sheet)

  sheetData = sheetData.map((row) => {
    Object.keys(row).map((colName) => {
      if (row[colName] instanceof Date) {
        row[colName] = FormatDateForDB(row[colName])
      }
      return row[colName]
    })
    return row
  })
  return sheetData
}

export const binary_string_to_workbook = (data) => {
  const workbook = XLSX.read(data, { type: 'binary', cellDates: true })

  return workbook
}

export const xlsxDataFromTableJson = (data, tableColumns) => {
  if (!data || data.length < 1 || !tableColumns || tableColumns < 1) return []

  return data.map((field) => {
    return tableColumns.reduce((previous, current) => {
      const { accessor, headingText, type } = current
      const fieldData = field[accessor]

      if (Array.isArray(fieldData)) {
        previous[headingText] = fieldData.reduce((prev, curr, idx) => {
          if (idx === 0) {
            return `${prev}${curr}`
          }

          return `${prev}\n${curr}`
        }, '')
      } else if (typeof accessor === 'function') {
        previous[headingText] = accessor(field)
      } else if (type === 'date' && typeof previous[headingText] === 'string') {
        const parts = previous[headingText].split('T')[0].split('-')

        if (parts.length > 0) {
          const year = Number(parts[0])
          const month = Number(parts[1]) - 1
          const day = Number(parts[2])

          previous[headingText] = new Date(year, month, day)
        }
      } else if (typeof accessor === 'string' && accessor.split('.').length > 1) {
        // This looks like a weird use of reduce - it would be great if there
        // was a test with an example of the expected input and output
        previous[headingText] = accessor.split('.').reduce((_prev, curr) => {
          return field[curr]
        }, field)
      } else {
        previous[headingText] = fieldData
      }

      return previous
    }, {})
  })
}

export const tableToXlsx = (data, columns, fileName = 'records.xlsx') => {
  const columnPrefixes = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const typeConversion = { currency: 'c' }
  const tableColumns = columns.filter((c) => c.headingText)

  const xlsxData = xlsxDataFromTableJson(data, tableColumns)

  let colSet = new Set()
  const columnTypes = {}

  // Set the heading text and conversion details for each column
  for (const [index, column] of tableColumns.entries()) {
    const { headingText, type } = column

    colSet.add(headingText)

    if (type) {
      columnTypes[columnPrefixes[index]] = typeConversion[type]
    }
  }

  // Create and save xlsx file
  const workbook = XLSX.utils.book_new()
  const workSheet = XLSX.utils.json_to_sheet(xlsxData)

  workSheet['!cols'] = Array.from(colSet).map((heading) => ({ wch: heading.length }))

  // Set types, excluding header cols
  const keysToFormat = Object.keys(workSheet).filter((key) => {
    const exclude = ['!cols', '!ref', ...columnPrefixes.split('').map((char) => char + '1')]

    return !exclude.includes(key) && Object.keys(columnTypes).includes(key.replace(/\d/g, ''))
  })

  //Only formatting currency fields for now, will probably need to change in the future
  for (const key of keysToFormat) {
    workSheet[key].z = '$#,##0.00'
  }

  XLSX.utils.book_append_sheet(workbook, workSheet, 'Records')

  return new Promise((success, reject) => {
    try {
      XLSX.writeFile(workbook, fileName)
      return success()
    } catch (error) {
      return reject()
    }
  })
}
