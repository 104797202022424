import React from 'react'
import styled from 'styled-components'

/**
 * Displays an empty message with some padding and centered text
 * TODO: - optionally configured with a button to allow user action.
 */
export const S_EmptyMessage = styled.div`
  text-align: center;
  padding: 30px 15px;
  color: #666;

  &.with-bg {
    background: #f1f1f1;
  }
`

export const EmptyMessage = ({ message, ...rest }) => <S_EmptyMessage {...rest}>{message}</S_EmptyMessage>
