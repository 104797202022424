import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { notificationsActions } from '../_actions'

export function useNotifications() {
  const dispatch = useDispatch()
  return {
    showNotification: useCallback(
      (notification) => dispatch(notificationsActions.showNotification(notification)),
      [dispatch]
    ),
  }
}
