import request from '../../axios_service'

const PLAID_API_BASE_URL = '/integration/plaid'
const PLAID_ENTITY_TYPE = 'business'

export const getPlaidApplicationAccess = (entity_id) => {
  const entityParams = `entity_type=${PLAID_ENTITY_TYPE}&entity_id=${entity_id}`
  return request({
    url: `${PLAID_API_BASE_URL}/application_accesses?${entityParams}`,
    method: 'GET',
  })
}

export const getPlaidLinkToken = (entity_id) => {
  return request({
    url: `${PLAID_API_BASE_URL}/application_accesses/create_link_token`,
    method: 'POST',
    data: {
      entity_id,
      entity_type: PLAID_ENTITY_TYPE,
    },
  })
}

export const savePlaidPublicToken = (entity_id, public_token) => {
  return request({
    url: `${PLAID_API_BASE_URL}/application_accesses`,
    method: 'POST',
    data: {
      entity_id,
      entity_type: PLAID_ENTITY_TYPE,
      public_token,
      name: `account_for_${PLAID_ENTITY_TYPE}_${entity_id}`,
    },
  })
}

export const getPlaidAccounts = (entity_id, entity_type = PLAID_ENTITY_TYPE) => {
  const entityParams = `entity_type=${entity_type}&entity_id=${entity_id}`
  return request({
    url: `${PLAID_API_BASE_URL}/accounts?${entityParams}`,
    method: 'GET',
  })
}

export const getPlaidAccountTransactions = (account_id, searchParams) => {
  return request(
    {
      url: `${PLAID_API_BASE_URL}/accounts/${account_id}/transactions?${searchParams}`,
      method: 'GET',
    },
    null,
    true
  ).then((response) => {
    const transactions = response.data.map((transaction) => {
      transaction.type = transaction.amount < 0 ? 'DEBIT' : 'CREDIT'
      return transaction
    })
    const total_transactions = response['headers']['total-count']
    return { transactions, total_transactions }
  })
}

export const disconnectPlaidAccess = (entity_id, entity_type = PLAID_ENTITY_TYPE) => {
  return request({
    url: `${PLAID_API_BASE_URL}/application_accesses/disconnect`,
    method: 'DELETE',
    data: {
      entity_id,
      entity_type,
    },
  })
}
