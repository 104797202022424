export const transformDocumentResponse = (document) => {
    return {
        approved: !!document.approved,
        createdAt: new Date(document.created_at),
        dataSource: document.data_source,
        deleted: !!document.deleted,
        documentDescription: document.document_description,
        documentIssueDate: document.document_issue_date ? new Date(document.document_issue_date) : null,
        documentName: document.document_name,
        documentNumber: document.document_number,
        documentType: document.document_type,
        documentableId: document.documentable_id,
        id: document.id,
        updatedAt: new Date(document.updated_at),
    };
};
export const transformDocumentsResponse = (documentsResponse) => {
    return {
        count: documentsResponse.count,
        documents: documentsResponse.supporting_documents.map(transformDocumentResponse),
    };
};
export const transformUploadDocumentToFormData = (file) => {
    const formData = new FormData();
    formData.append('filedata', file, file.name);
    formData.append('filename', file.name);
    return formData;
};
