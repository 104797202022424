import styled from 'styled-components'

export const S_Filter = styled.div`
  width: 800px;
  .filter-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 -5px;
  }
  .btn-container {
    width: 100%;
    margin-top: 32px;
    display: flex;
    justify-content: space-around;
  }
  .set-filter-btn {
    cursor: pointer;
  }
  .header-with-close {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .sliders {
      margin-right: 5px;
    }
    .header-right {
      display: flex;
      align-items: center;
    }
    .close-btn {
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .container {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-top: 8px;
  }
`
