export const bankConstants = {
  DO_NOT_CONNECT_BANK: 'DO_NOT_CONNECT_BANK',
  CONNECT_BANK_REQUEST: 'CONNECT_BANK_REQUEST',
  CONNECT_BANK_SUCCESS: 'CONNECT_BANK_SUCCESS',
  CONNECT_BANK_FAILURE: 'CONNECT_BANK_FAILURE',
  SET_BANK_CONNECTION_STATUS: 'SET_BANK_CONNECTION_STATUS',

  VALUE_CONNECT: 'connect',
  VALUE_UPLOAD: 'upload',
  ATTR_NAME: 'connect-to-bank',
  BUSINESS_FLAG_NAME: 'bank_connection_status',
  BANK_CONNECTED_TO_BUSINESS_FLAG: 'authenticated',
  CONNECT_BUTTON_TITLE: 'Connect your bank',
  CONNECTED_TITLE: 'Connected',
  CONNECTED_HOME_TITLE: 'Bank Connected',
  UPLOAD_BUTTON_TITLE: 'I will upload manually',
}
