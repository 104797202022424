import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaExclamationCircle } from 'react-icons/fa'

// Components
import ToolTip from './Tooltip/Tooltip'
import CurrencyField from '../_components/form-fields/CurrencyField'
import RadioGroup from '../_components/form-fields/RadioGroup'
import TenantFormRadioGroup from '../_components/form-fields/TenantFormRadioGroup'
import Checkbox from '../_components/form-fields/Checkbox'
import InputField from '../_components/form-fields/InputField'
import SelectField from '../_components/form-fields/SelectField'
import InternationalTel from './form-fields/InternationalTel'
import Country from './form-fields/Country'
import Region from './form-fields/Region'
import { UploadDoc } from '../_components/form-fields/UploadDoc'
import PercentageField from './form-fields/PercentageField'
import SecureField from '../_components/form-fields/SecureField'
import TextArea from '../_components/form-fields/TextArea'
import StdTermsSale from '../_components/form-fields/StdTermsSale'
import ListField from '../_components/form-fields/List'
import { PlaidFormikField } from './form-fields/Plaid/PlaidFormikField'

// Utilities
import { getFormikName } from '../_helpers/forms'
import colors from '../_constants/colors'
import { S_QuestionRoundedIcon } from './ListSearchableView'

const StyledFieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  position: relative;

  .field-header {
    display: flex;
    margin-bottom: 4px;

    .field-label {
      display: flex;
      align-items: center;

      label {
        color: ${colors.DEFAULT_TEXT};

        .required-asterix {
          color: red;
        }
      }

      ${S_QuestionRoundedIcon} {
        margin-left: 3px;
      }
    }
  }

  .field-footer {
    margin-top: 4px;
    .error-msg {
      color: #d93025;
      font-weight: 600;
      font-size: 0.8em;
      display: flex;
      align-items: center;
      svg {
        margin-right: 3px;
      }
    }
  }

  .react-tel-input,
  .react-tel-input,
  .react-select {
    border: ${(props) => props.invalid && `1px solid #d93025`};
    border-radius: 5px;
  }

  .field {
    margin-top: 10px;
  }
`

const FIELD_COMPONENT_LOOKUP = {
  text: InputField,
  number: InputField,
  date: InputField,
  url: InputField,
  password: InputField,
  select: SelectField,
  dropdown: SelectField,
  radio: RadioGroup,
  tenant_form_radio: TenantFormRadioGroup,
  currency: CurrencyField,
  email: InputField,
  tel: InternationalTel,
  country: Country,
  region: Region,
  upload_document: UploadDoc,
  connect_your_bank: PlaidFormikField,
  percentage: PercentageField,
  ssn: SecureField,
  business_ein: SecureField,
  aba_routing_ach: SecureField,
  aba_routing_wire: SecureField,
  account_number: SecureField,
  textarea: TextArea,
  std_terms_sale: StdTermsSale,
  multiselect: Checkbox,
  list: ListField,
}

const getFormikTouched = (touched, field, index = null) => {
  const { table, name, type } = field
  if (!touched) {
    return false
  }
  const tableTouched = touched[table]
  if (tableTouched) {
    if (table === `business_officers` || table === `top_business_debtors` || table === `uploadedInvoices`) {
      return tableTouched[index] && tableTouched[index][name]
    } else if (type === `upload_document`) {
      return tableTouched[`have_${name}`]
    } else {
      return tableTouched[name]
    }
  } else if (touched[name]) {
    return touched[name]
  } else {
    return false
  }
}

const getFormikErrors = (errors, field, index = null) => {
  const { table, name, type } = field
  if (!errors) {
    return ''
  }
  const tableErrors = errors[table]
  if (tableErrors) {
    if (table === `business_officers` || table === `top_business_debtors` || table === `uploadedInvoices`) {
      return tableErrors[index] && tableErrors[index][name]
    } else if (type === `upload_document`) {
      return tableErrors[`have_${name}`]
    } else {
      return tableErrors[name]
    }
  } else if (errors[name]) {
    return errors[name]
  } else {
    return ``
  }
}

const getFormikValue = (values, field, index = null) => {
  const { table, name, type } = field
  if (!values || !table) {
    return ''
  }
  const tableValues = values[table]
  if (tableValues) {
    if (table === `business_officers` || table === `top_business_debtors` || table === `uploadedInvoices`) {
      return tableValues[index] && tableValues[index][name]
    } else if (type === `upload_document`) {
      return tableValues[`have_${name}`]
    } else {
      return tableValues[name]
    }
  } else {
    return values[name]
  }
}

const DynamicComponent = (props) => {
  const TagName = FIELD_COMPONENT_LOOKUP[props.field.type]
  return <TagName {...props} />
}

const FieldHeader = ({ field }) => {
  return (
    <div className={`field-header`}>
      <div className={`field-label`}>
        <label htmlFor={field?.label}>
          {field.label} {field.required && <span className={`required-asterix`}>*</span>}
        </label>
        {field.tooltip && (
          <ToolTip content={field.tooltip}>
            <S_QuestionRoundedIcon size={'sm'} />
          </ToolTip>
        )}
      </div>
    </div>
  )
}

const Field = (props) => {
  const {
    field,
    className = '',
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    index,
    state,
    fieldStateUpdate,
    parentFieldTable,
    parentFieldName,
    condition,
    overrideShowError = false,
    onFocus = () => {},
  } = props
  const fieldTouched = getFormikTouched(touched, field, index)
  const fieldError = getFormikErrors(errors, field, index)
  const invalid = Boolean((fieldTouched && fieldError) || (overrideShowError && fieldError))
  const Component = FIELD_COMPONENT_LOOKUP[field.type]
  const [blur, setBlur] = useState(false)

  useEffect(() => {
    if (typeof fieldStateUpdate === 'function' && blur) {
      fieldStateUpdate(
        field.table,
        field.name,
        fieldError,
        fieldTouched,
        getFormikValue(values, field, index),
        index,
        parentFieldTable,
        parentFieldName,
        condition,
        true
      )
    }
    setBlur(false)
  }, [values])

  return (
    <StyledFieldGroup className={`field`} invalid={invalid}>
      {field.label && <FieldHeader field={field} />}
      <div className="field-component">
        <Component
          autocomplete="off"
          className={className}
          field={field}
          formikName={getFormikName(field, index)}
          handleBlur={function () {
            //Not an arrow function so we can have access to arguments object
            handleBlur(...arguments)
            setBlur(true)
          }}
          handleChange={function () {
            handleChange(...arguments)
          }}
          handleFocus={onFocus}
          id={field?.label}
          invalid={invalid}
          state={state}
          value={getFormikValue(values, field, index)}
          values={values}
        />
        {invalid && (
          <div className={`field-footer`}>
            <span className={`error-msg`}>
              <FaExclamationCircle /> {fieldError}
            </span>
          </div>
        )}
        {field.following_question &&
          !field.following_question.condition_field_name &&
          getFormikValue(values, field, index) === field.following_question.condition && (
            <div className={'field'}>
              {field.following_question.label && <FieldHeader field={field.following_question} />}
              <DynamicComponent
                className={className ? className : ''}
                errors={errors}
                field={field.following_question}
                formikName={getFormikName(field.following_question, index)}
                handleBlur={(name, attr, val) => {
                  if (field.following_question.table === 'business_officers') {
                    handleBlur(name, attr, val)
                  } else {
                    handleBlur(name, attr, val, field.following_question.table)
                  }
                }}
                handleChange={handleChange}
                index={index}
                touched={touched}
                value={''}
              />
            </div>
          )}
        {field.following_question &&
          field.following_question.condition_field_name &&
          state[field.table][field.following_question.condition_field_name] === field.following_question.condition && (
            <Field
              autocomplete="off"
              condition={field.following_question.condition}
              errors={errors}
              field={field.following_question}
              fieldStateUpdate={fieldStateUpdate}
              handleBlur={(name, attr, val) => {
                handleBlur(name, attr, val, field.following_question.table)
              }}
              handleChange={handleChange}
              index={index}
              parentFieldName={field.name}
              parentFieldTable={field.table}
              touched={touched}
              values={values}
            />
          )}
        {field.following_questions &&
          getFormikValue(values, field, index) === field.following_questions.condition &&
          field.following_questions.fields.map((f) => {
            return (
              <Field
                autocomplete="off"
                condition={f.condition}
                errors={errors}
                field={f}
                fieldStateUpdate={fieldStateUpdate}
                handleBlur={(name, attr, val) => {
                  handleBlur(name, attr, val, f.table)
                }}
                handleChange={handleChange}
                index={index}
                parentFieldName={field.name}
                parentFieldTable={field.table}
                touched={touched}
                values={values}
              />
            )
          })}
      </div>
    </StyledFieldGroup>
  )
}

export default Field
