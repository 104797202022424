import request from './axios_service'
import { tenantHeader, sessionHeader } from '../_helpers'

export const getReport = (reportType, business, isLedger = false) => {
  let headerObj = Object.assign({ Accept: 'text/iif' }, sessionHeader(), tenantHeader())
  let business_filter = business ? `?business_id=${business.id}` : ``
  return request(
    {
      url: isLedger ? `/ledger/reports/${reportType}` : `/operate/reports/${reportType}${business_filter}`,
      method: 'GET',
      responseType: 'blob',
    },
    headerObj,
    true
  )
}

/** Get a report from the server
 * @param {String} path
 * @param {URLSearchParams} params
 */
export const getReportByPath = (path, params = null, mime_type = 'text/csv') => {
  let headerObj = Object.assign({ Accept: mime_type }, sessionHeader(), tenantHeader())
  return request(
    {
      url: path + `${params ? '?' + params.toString() : ''}`,
      method: 'GET',
      responseType: 'blob',
    },
    headerObj,
    true
  )
}

export const getReports = (uncancelable = false) => {
  return request({
    url: `/ledger/reports`,
    method: 'GET',
    uncancelable: uncancelable,
  })
}

export const getABLReports = (uncancelable = false) => {
  return request({
    url: `/abl/reports`,
    method: 'GET',
    uncancelable: uncancelable,
  })
}

/** Get list of generated reports
 * @param {URLSearchParams} params
 */
export const getGeneratedReports = (params) => {
  return request(
    {
      url: `/ledger/generated_reports?${params}`,
      method: 'GET',
    },
    null,
    true
  )
}

/** Get list of generated reports
 * @param {URLSearchParams} params
 */
export const downloadReportById = (id) => {
  return request({
    url: `/ledger/generated_reports/${id}/download`,
    method: 'GET',
  })
}
