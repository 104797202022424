import { transformDebtorContactResponse } from 'xen/api/use-debtor';
import { transformDocumentResponse } from 'xen/api/use-document';
import { transformUserResponse } from 'xen/api/use-user';
import { formatCurrency, formatDate } from 'xen/helpers';
export const transformInvoiceResponse = (invoice) => {
    return {
        accruedFees: Number(invoice.accrued_fees),
        advancedAmount: Number(invoice.advance_amount),
        age: invoice.age,
        batchNumber: invoice.batch_number,
        businessId: invoice.business_id,
        businessName: invoice.business_name,
        currentBalance: Number(invoice.current_balance),
        debtorDueDate: invoice.debtor_due_date ? new Date(invoice.debtor_due_date) : null,
        debtorId: invoice.debtor_id,
        debtorName: invoice.debtor_name,
        earnedFees: Number(invoice.earned_fees),
        faceValue: Number(invoice.face_value),
        id: invoice.id,
        invoiceDate: new Date(invoice.invoice_date),
        invoiceNumber: invoice.invoice_number,
        nonFunded: invoice.non_funded,
        paymentTerms: invoice.payment_terms,
        poNumber: invoice.po_number,
        purchaseDate: new Date(invoice.purchase_date),
        sentiment: invoice.sentiment,
        state: invoice.state,
    };
};
export const transformBlankInvoiceResponse = (invoice) => {
    return {
        accruedFees: Number(invoice.accrued_fees),
        advancedAmount: Number(invoice.advance_amount),
        age: invoice.age,
        batchNumber: invoice.batch_number,
        businessId: invoice.business_id,
        businessName: invoice.business_name,
        currentBalance: Number(invoice.current_balance),
        debtorDueDate: invoice.debtor_due_date ? new Date(invoice.debtor_due_date) : null,
        debtorId: invoice.debtor_id,
        debtorName: invoice.debtor_name,
        earnedFees: Number(invoice.earned_fees),
        faceValue: invoice.face_value ? Number(invoice.face_value) : null,
        id: invoice.id,
        invoiceDate: new Date(invoice.invoice_date),
        invoiceNumber: invoice.invoice_number,
        nonFunded: invoice.non_funded,
        paymentTerms: invoice.payment_terms,
        poNumber: invoice.po_number,
        purchaseDate: new Date(invoice.purchase_date),
        sentiment: invoice.sentiment,
        state: invoice.state,
    };
};
export const transformInvoicesToSelectOptionsGroupedByDebtor = (invoices) => {
    return invoices
        .reduce((acc, invoice) => {
        const debtorName = invoice.debtorName;
        const existingGroup = acc.find((group) => group.group === debtorName);
        const newInvoice = {
            label: `# ${invoice.invoiceNumber} | Client: ${invoice.businessName} | Amount: ${formatCurrency(invoice.faceValue)} | Balance: ${formatCurrency(invoice.currentBalance)} | Purchased: ${formatDate.localeDateString(invoice.purchaseDate)}`,
            value: String(invoice.id),
        };
        if (existingGroup) {
            existingGroup.items.push(newInvoice);
            existingGroup.items.sort((a, b) => a.label.localeCompare(b.label));
        }
        else {
            acc.push({ group: debtorName, items: [newInvoice] });
        }
        return acc;
    }, [])
        .sort((a, b) => a.group.localeCompare(b.group));
};
export const transformNonFactoredInvoiceParams = (params) => {
    const requestParams = {
        business_id: params.businessId,
        debtor_id: params.debtorId,
        invoice_number: params.invoiceNumber,
    };
    if ('invoiceDate' in params)
        requestParams.invoice_date = params.invoiceDate;
    if ('paymentTerms' in params)
        requestParams.payment_terms = params.paymentTerms;
    return requestParams;
};
export const transformSerializedInvoiceBusiness = (business) => {
    return {
        businessName: business.business_name,
        financingType: business.financing_type,
        fullLegalName: business.full_legal_name,
    };
};
export const transformVerificationNoteResponse = (noteResponse) => {
    return {
        body: noteResponse.body,
        category: noteResponse.category,
        createdAt: new Date(noteResponse.created_at),
        createdBy: noteResponse.created_by ? transformUserResponse(noteResponse.created_by) : null,
        createdById: noteResponse.created_by_id,
        id: noteResponse.id,
        modifiedBy: noteResponse.modified_by ? transformUserResponse(noteResponse.modified_by) : null,
        modifiedById: noteResponse.modified_by_id,
        owner: noteResponse.owner ? transformUserResponse(noteResponse.owner) : null,
        ownerId: noteResponse.owner_id,
        updatedAt: new Date(noteResponse.updated_at),
    };
};
export const transformSerializedInvoiceVerification = (verification) => {
    return {
        contact: verification.contact ? transformDebtorContactResponse(verification.contact) : null,
        currentSentiment: verification.current_sentiment,
        documents: verification.documents.map(transformDocumentResponse),
        id: verification.id,
        note: transformVerificationNoteResponse(verification.note),
        ordinal: verification.ordinal,
        sentiment: verification.sentiment,
        tenantId: verification.tenant_id,
        verificationTypeId: verification.verification_type_id,
        verificationTypeName: verification.verification_type_name,
    };
};
export const transformSerializedInvoice = (invoice) => {
    const transformedInvoice = {
        accruedFees: invoice.accrued_fees ? parseFloat(invoice.accrued_fees) : 0,
        advanceAmount: parseFloat(invoice.advance_amount),
        advancedAmount: parseFloat(invoice.advanced_amount),
        advancePctSource: invoice.advance_pct_source,
        age: invoice.age,
        approved: invoice.approved,
        archived: invoice.archived,
        balance: parseFloat(invoice.balance),
        batchNumber: invoice.batch_number,
        batchType: invoice.batch_type,
        businessDebtorId: invoice.business_debtor_id,
        businessId: invoice.business_id,
        cashPostedAmount: parseFloat(invoice.cash_posted_amount),
        closed: invoice.closed,
        closedDate: invoice.closed_date ? new Date(invoice.closed_date) : null,
        createdAt: new Date(invoice.created_at),
        currency: invoice.currency,
        currentBalance: parseFloat(invoice.current_balance),
        dataSource: invoice.data_source,
        dealId: invoice.deal_id,
        debtorDueDate: invoice.debtor_due_date ? new Date(invoice.debtor_due_date) : null,
        debtorId: invoice.debtor_id,
        debtorName: invoice.debtor_name,
        deleted: invoice.deleted,
        dilutionAmount: parseFloat(invoice.dilution_amount),
        discountRate: invoice.discount_rate ? parseFloat(invoice.discount_rate) : null,
        documentId: invoice.document_id,
        earnedFees: invoice.earned_fees ? parseFloat(invoice.earned_fees) : 0,
        externalReferenceNumber: invoice.external_reference_number,
        faceValue: invoice.face_value ? parseFloat(invoice.face_value) : 0,
        feesAccrued: invoice.fees_accrued ? parseFloat(invoice.fees_accrued) : 0,
        feesCutoffDate: invoice.fees_cutoff_date ? new Date(invoice.fees_cutoff_date) : null,
        feesEarned: invoice.fees_earned ? parseFloat(invoice.fees_earned) : 0,
        financed: invoice.financed,
        fund: invoice.fund,
        fundedAmount: parseFloat(invoice.funded_amount),
        fundingRemaining: parseFloat(invoice.funding_remaining),
        id: invoice.id,
        ineligible: invoice.ineligible,
        invoiceDate: new Date(invoice.invoice_date),
        invoiceNumber: invoice.invoice_number,
        invoiceScheduleId: invoice.invoice_schedule_id,
        lastEventType: invoice.last_event_type,
        locked: invoice.locked,
        nonFactored: invoice.non_factored,
        nonFunded: invoice.non_funded,
        paymentTerms: invoice.payment_terms,
        pctAdvanced: invoice.pct_advanced ? parseFloat(invoice.pct_advanced) : null,
        pending: invoice.pending,
        poNumber: invoice.po_number,
        preapproved: invoice.preapproved,
        preclosed: invoice.preclosed,
        purchaseBatchNumber: invoice.purchase_batch_number,
        purchaseDate: new Date(invoice.purchase_date),
        removed: invoice.removed,
        sentiment: invoice.sentiment,
        state: invoice.state,
        status: invoice.status,
        submitted: invoice.submitted,
        tenantId: invoice.tenant_id,
        updatedAt: new Date(invoice.updated_at),
    };
    if (invoice.business) {
        transformedInvoice.business = transformSerializedInvoiceBusiness(invoice.business);
    }
    if (invoice.debtor_name) {
        transformedInvoice.debtorName = invoice.debtor_name;
    }
    if (invoice.errors) {
        transformedInvoice.errors = invoice.errors;
    }
    if (invoice.sales_person) {
        transformedInvoice.salesPerson = transformUserResponse(invoice.sales_person);
    }
    if (invoice.sentiment) {
        transformedInvoice.sentiment = invoice.sentiment;
    }
    if (invoice.supporting_documents_count) {
        transformedInvoice.supportingDocumentsCount = invoice.supporting_documents_count;
    }
    if (invoice.verifications) {
        transformedInvoice.verifications = invoice.verifications.map(transformSerializedInvoiceVerification);
    }
    return transformedInvoice;
};
export const transformUnserializedInvoice = (invoice) => {
    return {
        accruedFees: invoice.accrued_fees ? parseFloat(invoice.accrued_fees) : 0,
        advanceAmount: parseFloat(invoice.advance_amount),
        advancedAmount: parseFloat(invoice.advanced_amount),
        advancePctSource: invoice.advance_pct_source,
        alreadyPaidAmount: invoice.already_paid_amount ? parseFloat(invoice.already_paid_amount) : null,
        amountDue: parseFloat(invoice.amount_due),
        approved: invoice.approved,
        archived: invoice.archived,
        balance: parseFloat(invoice.balance),
        batchNumber: invoice.batch_number,
        batchType: invoice.batch_type,
        businessDebtorId: invoice.business_debtor_id,
        businessId: invoice.business_id,
        cashPostedAmount: parseFloat(invoice.cash_posted_amount),
        closed: invoice.closed,
        closedDate: invoice.closed_date ? new Date(invoice.closed_date) : null,
        createdAt: new Date(invoice.created_at),
        currency: invoice.currency,
        currentBalance: parseFloat(invoice.current_balance),
        dataSource: invoice.data_source,
        dealId: invoice.deal_id,
        debtorDueDate: invoice.debtor_due_date ? new Date(invoice.debtor_due_date) : null,
        debtorId: invoice.debtor_id,
        debtorName: invoice.debtor_name,
        deleted: invoice.deleted,
        dilutionAmount: parseFloat(invoice.dilution_amount),
        discountRate: invoice.discount_rate ? parseFloat(invoice.discount_rate) : null,
        documentId: invoice.document_id,
        earnedFees: invoice.earned_fees ? parseFloat(invoice.earned_fees) : 0,
        externalReferenceNumber: invoice.external_reference_number,
        faceValue: invoice.face_value ? parseFloat(invoice.face_value) : 0,
        feesAccrued: invoice.fees_accrued ? parseFloat(invoice.fees_accrued) : 0,
        feeScheduleId: invoice.fee_schedule_id,
        feesCutoffDate: invoice.fees_cutoff_date ? new Date(invoice.fees_cutoff_date) : null,
        feesEarned: invoice.fees_earned ? parseFloat(invoice.fees_earned) : 0,
        financed: invoice.financed,
        fundedAmount: parseFloat(invoice.funded_amount),
        fundingRemaining: parseFloat(invoice.funding_remaining),
        id: invoice.id,
        ineligible: invoice.ineligible,
        invoiceDate: new Date(invoice.invoice_date),
        invoiceNumber: invoice.invoice_number,
        invoiceScheduleId: invoice.invoice_schedule_id,
        lastEventType: invoice.last_event_type,
        locked: invoice.locked,
        nonFactored: invoice.non_factored,
        nonFunded: invoice.non_funded,
        paymentTerms: invoice.payment_terms,
        pctAdvanced: invoice.pct_advanced ? parseFloat(invoice.pct_advanced) : null,
        pending: invoice.pending,
        poNumber: invoice.po_number,
        preapproved: invoice.preapproved,
        preclosed: invoice.preclosed,
        purchaseBatchNumber: invoice.purchase_batch_number,
        purchaseDate: new Date(invoice.purchase_date),
        removed: invoice.removed,
        sentiment: invoice.sentiment,
        state: invoice.state,
        status: invoice.status,
        submitted: invoice.submitted,
        tenantDebtorId: invoice.tenant_debtor_id,
        tenantId: invoice.tenant_id,
        updatedAt: new Date(invoice.updated_at),
    };
};
export const transformInvoiceUpdatePayload = (payload) => {
    const updatePayload = {};
    if ('businessId' in payload)
        updatePayload.business_id = String(payload.businessId);
    if ('debtorDueDate' in payload)
        updatePayload.debtor_due_date = payload.debtorDueDate?.toISOString();
    if ('debtorId' in payload)
        updatePayload.debtor_id = String(payload.debtorId);
    if ('faceValue' in payload)
        updatePayload.face_value = payload.faceValue;
    if ('ineligible' in payload)
        updatePayload.ineligible = payload.ineligible;
    if ('invoiceDate' in payload)
        updatePayload.invoice_date = payload.invoiceDate?.toISOString();
    if ('invoiceNumber' in payload)
        updatePayload.invoice_number = payload.invoiceNumber;
    if ('nonFunded' in payload)
        updatePayload.non_funded = payload.nonFunded;
    if ('paymentTerms' in payload)
        updatePayload.payment_terms = payload.paymentTerms;
    if ('poNumber' in payload)
        updatePayload.po_number = payload.poNumber;
    return updatePayload;
};
export const transformVerificationType = (response) => {
    return {
        createdAt: new Date(response.created_at),
        id: response.id,
        name: response.name,
        sentiment: response.sentiment,
        tenantId: response.tenant_id,
        updatedAt: new Date(response.updated_at),
    };
};
