import React, { useState, useEffect } from 'react'

// Components
import { FormInput } from '../../FormInput'

const updateFromProps = (value, defaultValue) => {
  const valueToUse = parseInt(value || defaultValue)
  if (valueToUse === NaN) return ''
  return valueToUse
}

export const IntegerField = (props) => {
  const { id = null, value, defaultValue, onChange = () => {}, ...rest } = props
  const [displayValue, setDisplayValue] = useState(updateFromProps(value, defaultValue))

  useEffect(() => {
    setDisplayValue(updateFromProps(value, defaultValue))
  }, [id, value])

  const handleChangeLocal = (e) => {
    let value = parseInt(e.target.value)

    if (value === NaN) {
      setDisplayValue('')
      onChange(null)
      return
    }
    if (rest.max && value > rest.max) {
      value = Number(value.toString().slice(0, rest.max.toString().length))
      e.target.value = value
    }
    setDisplayValue(value)
    onChange(value, e)
  }

  return (
    <FormInput
      {...rest}
      id={id}
      onChange={handleChangeLocal}
      onKeyDown={(event) => {
        if (event.key === '.') {
          event.preventDefault()
        }
      }}
      type="number"
      value={displayValue}
    />
  )
}
