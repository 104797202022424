import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { unstable_batchedUpdates } from 'react-dom'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { S_FormFieldWrapper } from '../FormField'

const numberMask = createNumberMask({
  allowDecimal: true,
  prefix: ``,
  suffix: `%`,
})

// Components
import { FormInput } from '../../FormInput'

// Constants
import { textInputStyles } from '../../../../_constants/styles'

const S_MaskedInput = styled(MaskedInput)`
  ${textInputStyles}
  width: auto; /* TODO: does this style get added to the constant definition */
  max-width: 100%; /* TODO: does this style get added to the constant definition */
`
export const PercentField2 = (props) => {
  const { value, onChange, disabled, className } = props
  return (
    <S_MaskedInput
      className={className}
      disabled={disabled}
      inputMode="numeric"
      mask={numberMask}
      onChange={onChange}
      value={value}
    />
  )
}

export const PercentField = (props) => {
  const {
    value,
    defaultValue,
    onChange = () => {},
    onBlur = () => {},
    displayAfterBlur = true,
    className,
    fixedLength = 2,
    min = 0,
    max = 100,
    ...rest
  } = props
  // Generally these will be uncontrolled

  const maxMinFormattedValue = (value) => {
    return Math.max(Number(min), Math.min(Number(max), Number(value)))
  }

  const isControlled = value !== undefined
  const valueToUse = isControlled ? value : defaultValue
  const formattedVal = valueToUse ? valueToUse * 100 : ''
  const [displayValue, setDisplayValue] = useState(
    formattedVal ? maxMinFormattedValue(formattedVal).toFixed(fixedLength) : ''
  )

  const handleChangeLocal = (e) => {
    const { value } = e.target

    if (value === '') {
      setDisplayValue(null)
      onChange(null)
      return
    }

    const newDisplayValue = maxMinFormattedValue(value)

    unstable_batchedUpdates(() => {
      setDisplayValue(newDisplayValue)
    })

    const revertedNumber = Number((Number(newDisplayValue) / 100).toFixed(fixedLength + 2))
    onChange(revertedNumber)
  }

  const handleBlur = (e) => {
    onBlur(e)
    if (displayAfterBlur) {
      setDisplayValue(maxMinFormattedValue(displayValue).toFixed(fixedLength))
    }
  }

  useEffect(() => {
    const updateValue = formattedVal ? formattedVal.toFixed(fixedLength) : ''
    setDisplayValue(updateValue)
  }, [value])

  return (
    <S_FormFieldWrapper className={`percentage ${className}`}>
      <FormInput
        {...rest}
        className={`percentage ${className}`}
        max={max}
        min={min}
        onBlur={handleBlur}
        onChange={handleChangeLocal}
        onKeyDown={(e) => {
          if (e.key === '.' && fixedLength == 0) {
            e.preventDefault()
          }
        }}
        type="number"
        value={displayValue}
      />
    </S_FormFieldWrapper>
  )
}
