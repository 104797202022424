import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useAuthentication } from 'xen/hooks';
const Brokers = lazy(() => import('../pages/brokers'));
export const BROKERS_PATHS = {
    root: '/brokers',
};
export const BrokersRoutes = () => {
    const { root } = BROKERS_PATHS;
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    return [
        _jsx(Route, { exact: true, path: root, children: _jsx(Brokers, {}) }, root),
    ];
};
