import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button, Stack } from 'xen-ui';
import { useCreateNonFactoredInvoice } from 'xen/api/use-invoice';
import { ErrorText } from 'xen/components';
import { FormClientSelect, FormCurrencyInput, FormDateInput, FormDebtorSelect, FormTextInput, } from 'xen/components/form';
import { formatDate } from 'xen/helpers';
import { useCreatePaymentAssignment } from '../../api';
const nonFactoredInvoiceFormSchema = z.object({
    amount: FormCurrencyInput.schema({ name: 'amount', label: 'Amount', required: true }),
    businessId: FormClientSelect.schema,
    debtorId: FormDebtorSelect.schema,
    invoiceNumber: z.string().min(1, { message: 'Invoice number is required' }),
    invoiceDate: z.date().optional(),
});
export const NonFactoredInvoiceCreateForm = ({ payment, onSuccess }) => {
    const defaultValues = {
        amount: '',
        businessId: '',
        debtorId: String(payment.debtorId ?? ''),
        invoiceNumber: '',
        invoiceDate: undefined,
    };
    const { control, handleSubmit } = useForm({
        resolver: zodResolver(nonFactoredInvoiceFormSchema),
        mode: 'onBlur',
        values: defaultValues,
    });
    const { mutateAsync: createNonFactoredInvoice, isError: isInvoiceError, isPending: isInvoicePending, error: invoiceError, } = useCreateNonFactoredInvoice();
    const { mutateAsync: createPaymentAssignment, isError: isAssignmentError, isPending: isAssignmentPending, } = useCreatePaymentAssignment();
    const onSubmit = async (data) => {
        const invoicePayload = {
            debtorId: Number(data.debtorId),
            businessId: Number(data.businessId),
            invoiceNumber: data.invoiceNumber,
            ...(data.invoiceDate && { invoiceDate: formatDate.isoDateString(data.invoiceDate) }),
        };
        const invoice = await createNonFactoredInvoice(invoicePayload);
        const assignmentPayload = {
            collectionsCheckId: String(payment.id),
            invoiceId: invoice.id,
            amount: FormCurrencyInput.formatValue(data.amount),
            type: 'NonFactoredPaymentAction',
            businessId: Number(data.businessId),
        };
        await createPaymentAssignment(assignmentPayload);
        onSuccess();
    };
    const handleFormSubmit = () => {
        handleSubmit(onSubmit)();
    };
    const handleError = (e) => {
        console.error('Form error:', e);
    };
    return (_jsx(Form, { control: control, onError: handleError, onSubmit: handleFormSubmit, children: _jsxs(Stack, { gap: "lg", children: [_jsxs(Stack, { gap: "sm", children: [(isInvoiceError || isAssignmentError) && _jsx(ErrorText, { message: "Error creating non-factored invoice" }), _jsx(FormCurrencyInput, { control: control, label: "Amount", name: "amount", required: true }), _jsx(FormTextInput, { control: control, error: invoiceError?.invoiceNumberError, label: "Invoice Number", name: "invoiceNumber", required: true }), _jsx(FormClientSelect, { control: control, name: "businessId", required: true }), _jsx(FormDebtorSelect, { clearable: false, control: control, disabled: true, name: "debtorId", readOnly: true, required: true }), _jsx(FormDateInput, { control: control, label: "Date Posted (optional)", maxDate: new Date(), name: "invoiceDate" })] }), _jsx(Button, { loading: isAssignmentPending || isInvoicePending, type: "submit", children: "Submit" })] }) }));
};
