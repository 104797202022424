import request from './axios_service'

export const additionalBusinessDataService = {
  getAdditionalBusinessData,
  updateAdditionalBusinessData,
  updateAdditionalBusinessDataBatch,
}

function getAdditionalBusinessData(businessId) {
  return request({
    url: `businesses/${businessId}/additional_data`,
    method: 'GET',
  })
}

function updateAdditionalBusinessData(attr, val, businessId) {
  return request({
    url: `/businesses/${businessId}/additional_data`,
    method: 'PUT',
    uncancelable: true,
    data: {
      new_data: {
        [attr]: val,
      },
    },
  })
}

function updateAdditionalBusinessDataBatch(new_data, businessId) {
  return request({
    url: `/businesses/${businessId}/additional_data`,
    method: 'PUT',
    data: {
      new_data,
    },
  })
}
