export const businessBankAccountConstants = {
  GET_BANK_ACCOUNT_REQUEST: 'GET_BANK_ACCOUNT_REQUEST',
  GET_BANK_ACCOUNT_SUCCESS: 'GET_BANK_ACCOUNT_SUCCESS',
  GET_BANK_ACCOUNT_FAILURE: 'GET_BANK_ACCOUNT_FAILURE',

  UPDATE_BANK_ACCOUNT_REQUEST: 'UPDATE_BANK_ACCOUNT_REQUEST',
  UPDATE_BANK_ACCOUNT_SUCCESS: 'UPDATE_BANK_ACCOUNT_SUCCESS',
  UPDATE_BANK_ACCOUNT_FAILURE: 'UPDATE_BANK_ACCOUNT_FAILURE',

  CREATE_BANK_ACCOUNT_REQUEST: 'CREATE_BANK_ACCOUNT_REQUEST',
  CREATE_BANK_ACCOUNT_SUCCESS: 'CREATE_BANK_ACCOUNT_SUCCESS',
  CREATE_BANK_ACCOUNT_FAILURE: 'CREATE_BANK_ACCOUNT_FAILURE',
}
