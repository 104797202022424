import { useIsMutating, useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformInvoiceUpdatePayload, transformUnserializedInvoice } from './transform';
async function updateInvoice({ urlParams: { id }, payload }) {
    try {
        const payloadData = transformInvoiceUpdatePayload(payload);
        const response = await request({
            method: 'PUT',
            url: `/invoices/${id}`,
            data: payloadData,
        });
        const data = transformUnserializedInvoice(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not update invoice');
    }
}
export const useUpdateInvoice = (id) => {
    return useMutation({
        mutationKey: ['update-invoice', id],
        mutationFn: (params) => updateInvoice({ ...params, urlParams: { id } }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (invoice) => {
            notifications.success(`Updated invoice ${invoice.invoiceNumber}`);
            queryClient.invalidateQueries({ queryKey: ['invoices', invoice.id] });
        },
    });
};
export const useIsInvoiceUpdating = (id) => {
    return useIsMutating({ mutationKey: ['update-invoice', id] });
};
