import PropTypes from 'prop-types'
import { UTCDateToSystemString } from './dates'

export const contactDatesToSystemTime = (contact) => {
  contact.created_at = UTCDateToSystemString(contact.created_at)
  contact.updated_at = UTCDateToSystemString(contact.updated_at)
  return contact
}

export const getContactsFromClientsByDebtor = (debtorContactList, businessName) => {
  const contactsForInvoice = []
  for (const contact of debtorContactList) {
    const { clients, first_name, last_name, id } = contact

    const isThereNoClientsToContact = clients.length === 0
    const isBusinessInClientsList = clients.some((client) => client.name === businessName)

    if (isThereNoClientsToContact || isBusinessInClientsList) {
      contactsForInvoice.push({ name: `${first_name}${last_name ? ` ${last_name}` : ''}`, id })
    }
  }
  return contactsForInvoice
}

const debtorContactProp = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  clients: {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  },
  first_name: PropTypes.string.isRequired, // Doubt here. Is this field "required"?
  last_name: PropTypes.string,
}

getContactsFromClientsByDebtor.propTypes = {
  businessName: PropTypes.string.isRequired,
  debtorContactList: PropTypes.arrayOf(PropTypes.shape(debtorContactProp)).isRequired,
}
