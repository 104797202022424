import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { formatCase } from 'xen/helpers';
export const useGetUsersSearch = (params = {}) => {
    return useQuery({
        queryFn: async () => {
            try {
                const response = await request({
                    method: 'GET',
                    url: '/users/search_v2',
                    params,
                });
                return formatCase.objectKeys('camel', response);
            }
            catch (error) {
                throw new Error('Could not get users search data');
            }
        },
        queryKey: ['users-search', params],
    });
};
