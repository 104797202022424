import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformVerificationType } from './transform';
async function getVerificationTypes({ queryParams = {} }) {
    try {
        const response = await request({
            method: 'GET',
            url: `verification_types`,
            params: queryParams,
        });
        const data = response.map(transformVerificationType);
        return data;
    }
    catch (_error) {
        throw Error('Error fetching verification types');
    }
}
export const useGetVerificationTypes = ({ queryParams = {} } = {}) => {
    return useQuery({
        queryKey: ['verification-types', queryParams.tenantId ? queryParams.tenantId : {}],
        queryFn: () => getVerificationTypes({ queryParams }),
    });
};
