import { IconContext } from 'react-icons'
import { IoCloseCircleSharp } from 'react-icons/io5'
import { S_FilterIndicator } from './FilterIndicator.styles'
import React from 'react'

export const FilterIndicator = ({ filter, removeFilter }) => {
  if (!filter) {
    return
  }

  let displayValue = `${filter.column.displayName}: ${filter.formattedValue}`
  if (filter.column && filter.column.fieldNameAsLabel) {
    displayValue = filter.column.displayName
  }
  return (
    <S_FilterIndicator className="filter-indicator">
      {displayValue}
      <IconContext.Provider value={{ size: '22px', className: 'x-btn' }}>
        <IoCloseCircleSharp data-testid="remove-filter" onClick={() => removeFilter(filter)} />
      </IconContext.Provider>
    </S_FilterIndicator>
  )
}

FilterIndicator.defaultProps = {
  filter: null,
  removeFilter: () => {},
}
