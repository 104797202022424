import { transformPaginationKeys, transformSortingKeys } from 'xen/api/api';
import { transformTenantResponse } from 'xen/api/use-tenant';
export const transformBusiness = (business) => {
    const data = {
        acctsPayableInfo: business.accts_payable_info,
        acctsReceivableInfo: business.accts_receivable_info,
        addressCity: business.address_city,
        addressCountry: business.address_country,
        addressState: business.address_state,
        addressStreet: business.address_street,
        addressUnit: business.address_unit,
        addressZipCode: business.address_zip_code,
        aliasTenant: transformTenantResponse(business.alias_tenant),
        allowInvoiceNumberDuplicates: business.allow_invoice_number_duplicates,
        allowInvoiceNumberDuplicatesBetweenDebtors: business.allow_invoice_number_duplicates_between_debtors,
        archived: business.archived,
        associatedEntitiesExplain: business.associated_entities_explain,
        avgDaysPayableOutstanding: business.avg_days_payable_outstanding,
        avgInvoiceValue: business.avg_invoice_value,
        avgMonthlySales: business.avg_monthly_sales,
        avgNumCustomersMonthly: business.avg_num_customers_monthly,
        avgNumInvoices: business.avg_num_invoices,
        balance: business.balance,
        bankConnectionStatus: business.bank_connection_status,
        becameClientAt: business.became_client_at ? new Date(business.became_client_at) : null,
        brokerExplain: business.broker_explain,
        businessDescription: business.business_description,
        businessEinLast4: business.business_ein_last4,
        businessEntity: business.business_entity,
        businessFaxNumber: business.business_fax_number,
        businessIndustry: business.business_industry,
        businessName: business.business_name,
        businessPhoneNumber: business.business_phone_number,
        businessSize: business.business_size,
        businessTaxId: business.business_tax_id,
        businessType: business.business_type,
        businessTypeText: business.business_type_text,
        businessWebsite: business.business_website,
        buyoutExplain: business.buyout_explain,
        checkInLink: business.check_in_link,
        clientDebtorRelationRequired: business.client_debtor_relation_required,
        clientReferenceCode: business.client_reference_code,
        completedProfile: business.completed_profile,
        craBusinessNumber: business.cra_business_number,
        createdAt: business.created_at ? new Date(business.created_at) : null,
        currentlyBeingFinanced: business.currently_being_financed,
        currentlyBeingFinancedExplain: business.currently_being_financed_explain,
        currentYtdRev: business.current_ytd_rev,
        customer90daysAr: business.customer_90days_ar,
        customerTotalAr: business.customer_total_ar,
        dataSource: business.data_source,
        dateBusinessStarted: business.date_business_started ? new Date(business.date_business_started) : null,
        dba: business.dba,
        deleted: business.deleted,
        delinquentTaxesExplain: business.delinquent_taxes_explain,
        desiredLineOfCredit: business.desired_line_of_credit,
        differentCompanyNameExplain: business.different_company_name_explain,
        duns: business.duns,
        escrow: typeof business.escrow == 'string' ? parseFloat(business.escrow) : business.escrow,
        excessReserve: typeof business.excess_reserve == 'string' ? parseFloat(business.excess_reserve) : business.excess_reserve,
        financingRequiredAmount: business.financing_required_amount,
        financingRequiredDate: business.financing_required_date ? new Date(business.financing_required_date) : null,
        financingType: business.financing_type,
        fullLegalName: business.full_legal_name,
        fundsEmployed: business.funds_employed,
        hasAblTransactions: business.has_abl_transactions,
        hasAssociatedEntities: business.has_associated_entities,
        hasDeductionArrears: business.has_deduction_arrears,
        hasDelinquentTaxes: business.has_delinquent_taxes,
        hasDifferentCompanyName: business.has_different_company_name,
        hasOfficerBankruptcies: business.has_officer_bankruptcies,
        hasOfficerFelonies: business.has_officer_felonies,
        hasOutstandingCommercialLoans: business.has_outstanding_commercial_loans,
        hasOutstandingDebt: business.has_outstanding_debt,
        hasPreviouslyFactored: business.has_previously_factored,
        hasTransactions: business.has_transactions,
        hideEscrowReserves: business.hide_escrow_reserves,
        id: business.id,
        incCountry: business.inc_country,
        incState: business.inc_state,
        ineligibleAge: business.ineligible_age,
        ineligibleAr: typeof business.ineligible_ar == 'string' ? parseFloat(business.ineligible_ar) : business.ineligible_ar,
        invoicesIndividuals: business.invoices_individuals,
        invoicesLargeCompanies: business.invoices_large_companies,
        invoicesSmallCompanies: business.invoices_small_companies,
        isClient: business.is_client,
        lastAccountingUpdateTime: business.last_accounting_update_time
            ? new Date(business.last_accounting_update_time)
            : null,
        lastBankUpdateTime: business.last_bank_update_time ? new Date(business.last_bank_update_time) : null,
        lateFeeIncrementDays: business.late_fee_increment_days,
        lateFeePct: typeof business.late_fee_pct == 'string' ? parseFloat(business.late_fee_pct) : business.late_fee_pct,
        leadDealId: business.lead_deal_id,
        lenderDueDiligence: business.lender_due_diligence,
        lenderFundingLimit: business.lender_funding_limit,
        markIneligibleOnPartialPay: business.mark_ineligible_on_partial_pay,
        maxInvoiceAgeForPurchase: business.max_invoice_age_for_purchase,
        maxInvoiceTerm: business.max_invoice_term,
        mc: business.mc,
        monthlyFeePct: typeof business.monthly_fee_pct == 'string' ? parseFloat(business.monthly_fee_pct) : business.monthly_fee_pct,
        monthlySalesRange: business.monthly_sales_range,
        negativePurchaseProhibited: business.negative_purchase_prohibited,
        next12mRev: business.next_12m_rev,
        ninetyDaysAp: business.ninety_days_ap,
        ninetyDaysAr: business.ninety_days_ar,
        ninetyOnePlusAp: business.ninety_one_plus_ap,
        ninetyOnePlusAr: business.ninety_one_plus_ar,
        notificationEmail: business.notification_email,
        officerBankruptciesExplain: business.officer_bankruptcies_explain,
        officerFeloniesExplain: business.officer_felonies_explain,
        outstandingCommercialLoansExplain: business.outstanding_commercial_loans_explain,
        outstandingDebtDetail: business.outstanding_debt_detail,
        outstandingJudgementsLiens: business.outstanding_judgements_liens,
        overallCreditLimit: business.overall_credit_limit ? parseFloat(business.overall_credit_limit) : null,
        paid941Taxes: business.paid_941_taxes,
        paymentTermDays: business.payment_term_days,
        payrollTaxesCurrent: business.payroll_taxes_current,
        payrollTaxesExplain: business.payroll_taxes_explain,
        pledgedAcctReceivables: business.pledged_acct_receivables,
        precedingPrevYrRev: business.preceding_prev_yr_rev,
        presentAcctReceivables: business.present_acct_receivables,
        previouslyFactoredExplain: business.previously_factored_explain,
        prevYrRev: business.prev_yr_rev,
        priorCashflowFunding: business.prior_cashflow_funding,
        promoCode: business.promo_code,
        referralSource: business.referral_source,
        referralSource2: business.referral_source2,
        reserve: typeof business.reserve == 'string' ? parseFloat(business.reserve) : business.reserve,
        reservePct: typeof business.reserve_pct == 'string' ? parseFloat(business.reserve_pct) : business.reserve_pct,
        riskStatus: business.risk_status,
        salesGeneratedThru: business.sales_generated_thru,
        salesGeneratedThruContract: business.sales_generated_thru_contract,
        salesGeneratedThruOther: business.sales_generated_thru_other,
        salesGeneratedThruOtherExplain: business.sales_generated_thru_other_explain,
        salesGeneratedThruPurchaseOrder: business.sales_generated_thru_purchase_order,
        salesPersonId: business.sales_person_id,
        sellToOtherBusinesses: business.sell_to_other_businesses,
        sixtyDaysAp: business.sixty_days_ap,
        sixtyDaysAr: business.sixty_days_ar,
        stateOfIncorporation: business.state_of_incorporation,
        stdTermsOfSale: business.std_terms_of_sale,
        termExpiryDate: business.term_expiry_date ? new Date(business.term_expiry_date) : null,
        thirtyDaysAp: business.thirty_days_ap,
        thirtyDaysAr: business.thirty_days_ar,
        totalAr: typeof business.total_ar == 'string' ? parseFloat(business.total_ar) : business.total_ar,
        totalNumCustomers: business.total_num_customers,
        totalOpenAp: business.total_open_ap,
        totalOpenAr: business.total_open_ar,
        truckingEntityType: business.trucking_entity_type,
        typeOfBusiness: business.type_of_business,
        typeOfBusinessOther: business.type_of_business_other,
        underwriteDealId: business.underwrite_deal_id,
        unverifiedPurchaseProhibited: business.unverified_purchase_prohibited,
        updatedAt: business.updated_at ? new Date(business.updated_at) : null,
        verificationRequirement: business.verification_requirement ? parseFloat(business.verification_requirement) : null,
        whenRaiseInvoice: business.when_raise_invoice,
        whenRaiseInvoiceDelivered: business.when_raise_invoice_delivered,
        whenRaiseInvoiceProgress: business.when_raise_invoice_progress,
        whenRaiseInvoiceShipped: business.when_raise_invoice_shipped,
        yearsInBusiness: business.years_in_business,
        yrsInOperation: business.yrs_in_operation,
    };
    if ('officer1_address' in business)
        data.officer1Address = business.officer1_address;
    if ('officer1_dl' in business)
        data.officer1Dl = business.officer1_dl;
    if ('officer1_dob' in business)
        data.officer1Dob = business.officer1_dob;
    if ('officer1_email_address' in business)
        data.officer1EmailAddress = business.officer1_email_address;
    if ('officer1_first_name' in business)
        data.officer1FirstName = business.officer1_first_name;
    if ('officer1_id_number' in business)
        data.officer1IdNumber = business.officer1_id_number;
    if ('officer1_last_name' in business)
        data.officer1LastName = business.officer1_last_name;
    if ('officer1_pct_ownership' in business)
        data.officer1PctOwnership = business.officer1_pct_ownership;
    if ('officer1_phone' in business)
        data.officer1Phone = business.officer1_phone;
    if ('officer1_ssn_last4' in business)
        data.officer1SsnLast4 = business.officer1_ssn_last4;
    if ('officer1_title' in business)
        data.officer1Title = business.officer1_title;
    if ('officer1_user_id' in business)
        data.officer1UserId = business.officer1_user_id;
    if ('officer2_address' in business)
        data.officer2Address = business.officer2_address;
    if ('officer2_dl' in business)
        data.officer2Dl = business.officer2_dl;
    if ('officer2_dob' in business)
        data.officer2Dob = business.officer2_dob;
    if ('officer2_email_address' in business)
        data.officer2EmailAddress = business.officer2_email_address;
    if ('officer2_first_name' in business)
        data.officer2FirstName = business.officer2_first_name;
    if ('officer2_id_number' in business)
        data.officer2IdNumber = business.officer2_id_number;
    if ('officer2_last_name' in business)
        data.officer2LastName = business.officer2_last_name;
    if ('officer2_pct_ownership' in business)
        data.officer2PctOwnership = business.officer2_pct_ownership;
    if ('officer2_phone' in business)
        data.officer2Phone = business.officer2_phone;
    if ('officer2_ssn_last4' in business)
        data.officer2SsnLast4 = business.officer2_ssn_last4;
    if ('officer2_title' in business)
        data.officer2Title = business.officer2_title;
    if ('officer2_user_id' in business)
        data.officer2UserId = business.officer2_user_id;
    if ('officer3_address' in business)
        data.officer3Address = business.officer3_address;
    if ('officer3_dl' in business)
        data.officer3Dl = business.officer3_dl;
    if ('officer3_dob' in business)
        data.officer3Dob = business.officer3_dob;
    if ('officer3_email_address' in business)
        data.officer3EmailAddress = business.officer3_email_address;
    if ('officer3_first_name' in business)
        data.officer3FirstName = business.officer3_first_name;
    if ('officer3_id_number' in business)
        data.officer3IdNumber = business.officer3_id_number;
    if ('officer3_last_name' in business)
        data.officer3LastName = business.officer3_last_name;
    if ('officer3_pct_ownership' in business)
        data.officer3PctOwnership = business.officer3_pct_ownership;
    if ('officer3_phone' in business)
        data.officer3Phone = business.officer3_phone;
    if ('officer3_ssn_last4' in business)
        data.officer3SsnLast4 = business.officer3_ssn_last4;
    if ('officer3_title' in business)
        data.officer3Title = business.officer3_title;
    if ('officer3_user_id' in business)
        data.officer3UserId = business.officer3_user_id;
    return data;
};
export const transformGetBusinessPayload = (payload) => {
    let data = {};
    if (payload.constraints) {
        if ('isClient' in payload.constraints)
            data.constraints = { is_client: payload.constraints.isClient };
    }
    if (payload.pagination) {
        data.pagination = transformPaginationKeys(payload.pagination);
    }
    if (payload.sorting) {
        data = { ...data, ...transformSortingKeys(payload.sorting) };
    }
    return data;
};
export const transformBusinessTerm = (businessTerm) => {
    const data = {
        accrueEvent: businessTerm.accrue_event,
        businessId: businessTerm.business_id,
        createdAt: new Date(businessTerm.created_at),
        daysInYear: businessTerm.days_in_year,
        earnEvent: businessTerm.earn_event,
        entity: businessTerm.entity,
        errors: businessTerm.errors,
        externalTermsId: businessTerm.external_terms_id,
        floatDays: businessTerm.float_days,
        floatType: businessTerm.float_type,
        id: businessTerm.id,
        name: businessTerm.name,
        rangeBasedOn: businessTerm.range_based_on,
        rangeBegin: businessTerm.range_begin ? Number(businessTerm.range_begin) : null,
        rangeEnd: businessTerm.range_end ? Number(businessTerm.range_end) : null,
        rangeIncrement: businessTerm.range_increment ? Number(businessTerm.range_increment) : null,
        submitted: businessTerm.submitted,
        type: businessTerm.type,
        uiType: businessTerm.ui_type,
        updatedAt: new Date(businessTerm.updated_at),
        value: Number(businessTerm.value),
    };
    return data;
};
