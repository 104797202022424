import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { formatCase } from 'xen/helpers';
export const useGetAccounts = ({ queryParams, refetchInterval }) => {
    return useQuery({
        queryKey: ['integration-plaid-accounts', { queryParams }],
        queryFn: async () => {
            const response = await request({
                url: `/integration/plaid/accounts`,
                method: 'GET',
                params: queryParams,
            });
            return response.data.map((account) => formatCase.objectKeys('camel', account));
        },
        refetchInterval,
    });
};
