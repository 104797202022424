import React, { useState } from 'react'
import { S_QuoteTemplateModal } from './styles'
import { FaTrashAlt } from 'react-icons/fa'

// Components
import { LoaderButton } from '../../../_components/LoaderButton'
import { Button } from '../../../_components/Button'
import { FormInput } from '../../../_components/Forms/FormInput'
import { Editor } from '../../../_views/LenderViews/Quotes/Editor'
import { H2, H4 } from '../../../_components/Typography/Components/Headings/index.js'

// Services
import { createQuoteTemplate } from '../../../_services/quotes.service'

// Hooks
import { modalActionHooks } from '../../../_actionHooks'
import { notificationActionHooks } from '../../../_actionHooks/notification.actionHooks'

export const CreateQuoteTemplate = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [template, setTemplate] = useState('')
  const [name, setName] = useState('')
  const [placeholders, setPlaceholders] = useState([{ name: '', default_value: '' }])
  const closeModal = modalActionHooks.useCloseModal()
  const showNotification = notificationActionHooks.useShowNotification()

  const addPlaceholder = () => {
    setPlaceholders((placeholders) => [...placeholders, { name: '', default_value: '' }])
  }

  const removePlaceholder = (index) => {
    const newPlaceholders = [...placeholders]
    newPlaceholders.splice(index, 1)
    setPlaceholders(newPlaceholders)
  }

  const onSubmit = async () => {
    setIsSubmitting(true)

    const data = { name: name, template: template, placeholders: placeholders }
    try {
      await createQuoteTemplate(data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsSubmitting(false)
      showNotification({
        type: `success`,
        message: `Quote template updated successfully!`,
        id: `quote-template-success`,
      })
      closeModal()
    }
  }

  return (
    <S_QuoteTemplateModal>
      <H2>Create Quote template</H2>

      <H4>Name</H4>
      <FormInput onChange={(e) => setName(e.target.value)} value={name} />

      <H4>Template</H4>

      <Editor setValue={setTemplate} value={template} />

      <div className={'subtitle'}>
        <p>Text between ||placeholder|| will be replaced before being sent to a prospect</p>
      </div>

      <h3>Placeholders</h3>

      <div className="placeholders">
        <div className="current-placeholders">
          <h4>User Placeholders</h4>
          {placeholders.map((placeholder, index) => {
            return (
              <div className="variable-row" key={index}>
                <div className="variable-column">
                  <FormInput
                    id={`placeholder_${index}`}
                    onChange={(e) => {
                      const newPlaceholders = [...placeholders]
                      newPlaceholders[index].name = e.target.value
                      setPlaceholders(newPlaceholders)
                    }}
                    placeholder="Name"
                    value={placeholder.name}
                  />
                </div>
                <div className="variable-column">
                  <FormInput
                    id={`placeholder_${index}`}
                    onChange={(e) => {
                      const newPlaceholders = [...placeholders]
                      newPlaceholders[index].default_value = e.target.value
                      setPlaceholders(newPlaceholders)
                    }}
                    placeholder="Default value"
                    value={placeholder.default_value}
                  />
                </div>
                <div className="actions">
                  <FaTrashAlt onClick={() => removePlaceholder(index)} />
                </div>
              </div>
            )
          })}
        </div>

        <div className="default-placeholders">
          <h4>System Placeholders</h4>
          <div className="default-placeholders-row">
            <div className="default-placeholders-column label">
              <label>||today_date||</label>
            </div>
            <div className="default-placeholders-column">
              <label>replaced with current date</label>
            </div>
          </div>
          <div className="default-placeholders-row">
            <div className="default-placeholders-column label">
              <label>||business_name||</label>
            </div>
            <div className="default-placeholders-column">
              <label htmlFor="">replaced with business name</label>
            </div>
          </div>
          <div className="default-placeholders-row">
            <div className="default-placeholders-column label">
              <label>||business_address||</label>
            </div>
            <div className="default-placeholders-column">
              <label htmlFor="">replaced with business city</label>
            </div>
          </div>
          <div className="default-placeholders-row">
            <div className="default-placeholders-column label">
              <label>||business_city||</label>
            </div>
            <div className="default-placeholders-column">
              <label htmlFor="">replaced with business state</label>
            </div>
          </div>
          <div className="default-placeholders-row">
            <div className="default-placeholders-column label">
              <label>||sender_fullname||</label>
            </div>
            <div className="default-placeholders-column">
              <label htmlFor="">replaced with the sender&apos;s name</label>
            </div>
          </div>
          <div className="default-placeholders-row">
            <div className="default-placeholders-column label">
              <label>||sender_title||</label>
            </div>
            <div className="default-placeholders-column">
              <label htmlFor="">replaced with the sender&apos;s title</label>
            </div>
          </div>
          <div className="default-placeholders-row">
            <div className="default-placeholders-column label">
              <label>||sender_phone||</label>
            </div>
            <div className="default-placeholders-column">
              <label htmlFor="">replaced with the sender&apos;s phone</label>
            </div>
          </div>
          <div className="default-placeholders-row">
            <div className="default-placeholders-column label">
              <label>||sender_email||</label>
            </div>
            <div className="default-placeholders-column">
              <label htmlFor="">replaced with the sender&apos;s email</label>
            </div>
          </div>
          <div className="default-placeholders-row">
            <div className="default-placeholders-column label">
              <label>||business_officer_first_name||</label>
            </div>
            <div className="default-placeholders-column">
              <label htmlFor="">replaced with the business officer&apos;s first name</label>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Button onClick={addPlaceholder} text={'Add Placeholder'} />
      </div>
      <div className="submit">
        <LoaderButton disabled={!name} isLoading={isSubmitting} onClick={onSubmit} text="Create Template" />
      </div>
    </S_QuoteTemplateModal>
  )
}
