import React from 'react'
import { Field } from 'formik'
import { isValidEmail } from '../../_helpers/forms'
import styled from 'styled-components'
import { textInputStyles } from '../../_constants/styles'

const StyledField = styled(({ invalid, ...props }) => <Field {...props} />)`
  ${textInputStyles}
`
const isValidUrl = (string) => {
  try {
    new URL(string)
    return true
  } catch (_) {
    return false
  }
}

const validateMinMaxDate = (value, min = false, max = false) => {
  const date = new Date(value)

  if (min) {
    const minDate = new Date(min)
    if (date < minDate) {
      return `Date should not be earlier than ${min}.`
    }
  }

  if (max) {
    const maxDate = new Date(max)
    if (date > maxDate) {
      return `Date should not be greater than ${max}.`
    }
  }

  return null
}

const validateMinMaxNumber = (value, min = false, max = false) => {
  if (max !== false && +value > max) {
    return `The value should not be more than ${max}.`
  }
  if (min !== false && +value < min) {
    return `The value should not be less than ${min}.`
  }
  return null
}

const InputField = ({ field, formikName, handleBlur, invalid, handleFocus }) => {
  const validateField = (field, value, type = 'message') => {
    const textError = type === 'message'
    let error = textError ? undefined : false

    if (field.required && !value) {
      error = `${field.label || `Field`} is required.`
    }

    if (field.type == 'url' && value) {
      let isValid = isValidUrl(value)
      if (!isValid) {
        error = 'Invalid URL'
      }
    }

    if (field.type == 'email' && value) {
      let isValid = isValidEmail(value)
      if (!isValid) {
        error = 'Invalid email address'
      }
    }

    if (field.type === 'date' && !error) {
      error = validateMinMaxDate(value, field.min, field.max)
    }

    if (field.type === 'number' && !error) {
      error = validateMinMaxNumber(value, field.min, field.max)
    }

    if (textError) {
      return error
    } else {
      return !!error
    }
  }
  return (
    <StyledField
      autoComplete="off"
      className={invalid ? 'invalid-input' : ''}
      component={'input'}
      defaultValue={field.value}
      id={field.label}
      invalid={invalid}
      max={field.max}
      min={field.min}
      name={formikName}
      onBlur={(e) => {
        const invalid = validateField(field, e.target.value, 'boolean')
        handleBlur(formikName, field.name, e.target.value, field.table, invalid)
      }}
      onFocus={handleFocus}
      placeholder={field.placeholder}
      type={field.type}
      validate={(value) => {
        let errorMessage = validateField(field, value, 'message')
        return errorMessage
      }}
    />
  )
}

export default InputField
