import React, { useState, useEffect } from 'react'
import { unstable_batchedUpdates } from 'react-dom'

// Components
import { EmptyMessage } from '../EmptyMessage'
import {
  S_QuestionRoundedIcon,
  S_ListSearchableView,
  S_LoadMoreButton,
  S_OverViewWrapper,
  S_OverViewHead,
} from './ListSearchableView.styles'
import { SectionLoader } from '../SectionLoader'

// PropTypes
import { listViewDefaultProps, listViewPropsTypes } from './ListSearchableView.propTypes'

// Helpers
import { createSearchParams } from '../../_helpers/search'
import { logError } from '../../_helpers/errors'
import { getResultsString } from '../SearchableView'
import ToolTip from '../Tooltip/Tooltip'
import { S_Title } from '../Typography/Titles.styles'

const INITIAL_PAGE_NUM = 1

const LoadMoreSection = ({ isLoadButtonMoreDisable, isLoadingMore, onClick, updateLoading }) => {
  if (updateLoading) {
    return null
  }

  if (isLoadingMore) {
    return <SectionLoader />
  }

  return (
    <S_LoadMoreButton disabled={isLoadButtonMoreDisable} onClick={onClick}>
      See More
    </S_LoadMoreButton>
  )
}

export const ListSearchableView = ({ viewConfig, searchTerm, searchParam, searchFilters, fetchReload }) => {
  const {
    Component,
    asideData,
    emptyMessage,
    endpointCall,
    exactMatch,
    explainer,
    initialTotal,
    pageSize,
    pageSizePostfix,
    pageSizes,
    removeMethod,
    requestMethod,
    showPagination,
    title,
    updateResults,
  } = viewConfig

  const [actualSearchFilters, setActualSearchFilters] = useState(searchFilters)
  const [actualSearchTerm, setActualSearchTerm] = useState(searchTerm)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [pageNum, setPageNum] = useState(INITIAL_PAGE_NUM)
  const [results, setResults] = useState([])
  const [resultsString, setResultsString] = useState('')
  const [showSection, setShowSection] = useState(true)
  const [totalResults, setTotalResults] = useState(initialTotal)
  const [updateLoading, setupdateLoading] = useState(false)

  const reloadResults = (searchParams) => {
    // Handle both object and array responses
    setupdateLoading(true)
    endpointCall(searchParams).then((response) => {
      unstable_batchedUpdates(() => {
        if (Array.isArray(response)) {
          setResults(response)
          setResultsString(totalResults || response.length)
          setTotalResults(initialTotal || response.length)
        } else {
          setResults(response.results)
          setTotalResults(Number(response.total_results))
          setResultsString(Number(response.total_results))
        }
        setupdateLoading(false)
      }, logError)
    })
  }

  const addMoreResponses = (searchParams) => {
    // Handle both object and array responses
    setIsLoadingMore(true)
    endpointCall(searchParams).then((response) => {
      unstable_batchedUpdates(() => {
        if (Array.isArray(response)) {
          const concatResults = results.concat(response)
          setResults(concatResults)
          const stringResult = getResultsString(totalResults, actualSearchTerm, exactMatch)
          setResultsString(stringResult)
        } else {
          const concatResults = results.concat(response.results)
          setResults(concatResults)
          setTotalResults(response.total_results)
          const stringResult = getResultsString(response.total_results, actualSearchTerm, exactMatch)
          setResultsString(stringResult)
        }
        setIsLoadingMore(false)
      }, logError)
    })
  }

  const loadFn = (callback) => {
    const searchParams = createSearchParams({
      searchTerm: actualSearchTerm,
      pageSize: pageSize,
      pageNum,
      requestMethod,
      searchParam,
      searchFilters,
    })
    callback(searchParams)
  }

  // Call endpoint
  useEffect(() => {
    if (pageNum !== INITIAL_PAGE_NUM) {
      loadFn(addMoreResponses)
    }
  }, [pageNum])
  useEffect(() => loadFn(reloadResults), [actualSearchFilters, actualSearchTerm])

  // Reset search term and page num based on prop change
  useEffect(() => {
    unstable_batchedUpdates(() => {
      setActualSearchTerm(searchTerm)
      setActualSearchFilters(searchFilters)
      setPageNum(1)
    })
  }, [searchTerm, searchFilters])

  // Call this function once to set up a pipeline to force override the current results
  // Calling this will also call back to the parent to let it set the current state the same way
  useEffect(() => {
    fetchReload(() => loadFn(reloadResults))
  }, [])

  const handleShowSection = () => {
    setShowSection((prev) => !prev)
  }

  const handleLoadMore = () => {
    setPageNum((prev) => (prev += 1))
  }

  const isLoadButtonMoreDisable = totalResults <= pageNum * pageSize

  const hasResults = results && results.length > 0
  const overviewContent = updateLoading ? (
    <SectionLoader />
  ) : !hasResults ? (
    <EmptyMessage message={emptyMessage} />
  ) : (
    <>
      <Component asideData={asideData} isSectionEnable={showSection} refresh={loadFn} results={results} />
    </>
  )
  return (
    <S_ListSearchableView>
      <S_OverViewHead>
        <S_Title
          expandable={true}
          expanded={showSection}
          marginBottom={'0'}
          onClick={handleShowSection}
          size={'sm'}
          weight={700}
        >
          {title} {!updateLoading && `(${resultsString})`}
        </S_Title>

        {explainer ? (
          <ToolTip content={explainer}>
            <S_QuestionRoundedIcon />
          </ToolTip>
        ) : null}
      </S_OverViewHead>

      <S_OverViewWrapper showSection={showSection}>
        {overviewContent}
        <LoadMoreSection
          isLoadButtonMoreDisable={isLoadButtonMoreDisable}
          isLoadingMore={isLoadingMore}
          onClick={handleLoadMore}
          updateLoading={updateLoading}
        />
      </S_OverViewWrapper>
    </S_ListSearchableView>
  )
}

ListSearchableView.propTypes = listViewPropsTypes
ListSearchableView.defaultProps = listViewDefaultProps
