import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import { PageContents } from '../PageContents'
import { ContentColumn } from '../ContentColumn'
import { SectionLoader } from '../../SectionLoader'

export const StandardPage = ({ title, contentWidth = null, isLoading = false, children }) => (
  <PageContents>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <ContentColumn contentWidth={contentWidth}>{isLoading ? <SectionLoader /> : children}</ContentColumn>
  </PageContents>
)
