import styled from 'styled-components'
import colors from '../../_constants/colors'
import { darken } from 'polished'

export const MenuPanel = styled.div`
  overflow-y: auto;
  background-color: ${(props) => props.customColors.primary || colors.DEFAULT_PRIMARY};
  color: ${(props) => props.customColors.footer_header || colors.DEFAULT_FOOTER_HEADER};
  border: 1px solid ${(props) => props.customColors.line || props.customColors.primary || colors.DEFAULT_PRIMARY};
  border-top: 0;
  font-weight: 300;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: start;
  justify-items: start;
  padding: 20px;
  transition: all 0.5s ease-in-out;
  min-width: 230px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.15) inset;

  .need-help {
    font-size: 0.8em;
    font-weight: 600;
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: start;
    margin-top: 1.5em;
    margin-left: 0;
    white-space: nowrap;
  }
  .phone-a {
    margin-left: 1em;
  }
  .phone-no {
    margin-left: 0.2em;
  }

  a,
  .link,
  .menu-link {
    color: ${(props) => props.customColors.footer_header || colors.TEXT_LIGHT};
    cursor: pointer;
    display: flex;
    align-items: start;
    justify-content: start;
    margin-top: 0.2em;
    &:hover {
      opacity: 0.5;
    }

    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .sign-out-button {
    margin-top: 10px;
    background-color: ${(props) => props.customColors.secondary || colors.DEFAULT_SECONDARY};

    &:hover {
      background-color: ${(props) => darken(0.15, props.customColors.secondary || colors.DEFAULT_SECONDARY_DARK)};
    }
  }

  .signup-link-button {
    margin-top: 10px;
  }
  .system-time-container {
    margin-top: 14px;
    font-size: 14px;
    font-weight: 300;
    color: ${(props) => props?.customColors?.footer_header || colors.TEXT_LIGHT};
  }
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: start;
  .menu-link {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

    &:first-child {
      padding-top: 0;
    }

    &:hover {
      opacity: 0.5;
    }

    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`
