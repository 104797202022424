import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformUnappliedCashResponse } from 'xen/_modules/unapplied-cash/api/transform';
async function getTenantUnappliedCash() {
    try {
        const response = await request({
            url: `/unapplied_cash_entries`,
            method: 'GET',
        });
        return transformUnappliedCashResponse(response);
    }
    catch (error) {
        throw Error('Error getting unapplied cash');
    }
}
export const useGetUnappliedCash = () => {
    return useQuery({
        queryKey: ['unapplied-cash-entries'],
        queryFn: getTenantUnappliedCash,
    });
};
