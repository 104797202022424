export const dealConstants = {
  GET_DEAL_REQUEST: 'GET_DEAL_REQUEST',
  GET_DEAL_SUCCESS: 'GET_DEAL_SUCCESS',
  GET_DEAL_FAILURE: 'GET_DEAL_FAILURE',

  UPDATE_DEAL_REQUEST: 'UPDATE_DEAL_REQUEST',
  UPDATE_DEAL_SUCCESS: 'UPDATE_DEAL_SUCCESS',
  UPDATE_DEAL_FAILURE: 'UPDATE_DEAL_FAILURE',

  UPLOAD_DEAL_DOCUMENT_REQUEST: 'UPLOAD_DEAL_DOCUMENT_REQUEST',
  UPLOAD_DEAL_DOCUMENT_SUCCESS: 'UPLOAD_DEAL_DOCUMENT_SUCCESS',
  UPLOAD_DEAL_DOCUMENT_FAILURE: 'UPLOAD_DEAL_DOCUMENT_FAILURE',

  CREATE_DEAL_REQUEST: 'CREATE_DEAL_REQUEST',
  CREATE_DEAL_SUCCESS: 'CREATE_DEAL_SUCCESS',
  CREATE_DEAL_FAILURE: 'CREATE_DEAL_FAILURE',
}
