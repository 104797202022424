import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { BasicTable, Loader, Text } from 'xen-ui';
import { PageTitle, Redirect, TextLink } from 'xen/components';
import { formatCurrency, formatDate } from 'xen/helpers';
import { useGetUnappliedCash } from '../api';
const sourceMap = {
    check: 'Check',
    ach: 'ACH',
    wire: 'Wire',
};
export const UnappliedCashEntries = () => {
    const { paymentId } = useParams();
    const { data: entries = [], isLoading, isError, isSuccess } = useGetUnappliedCash();
    const noEntries = isSuccess && entries?.length === 0;
    let emptyStateContent = _jsx(Loader, {});
    if (isError)
        emptyStateContent = _jsx(Text, { children: "Error fetching debtor payments with unapplied cash." });
    if (noEntries)
        emptyStateContent = (_jsxs(Text, { children: ["There are no ", _jsx(TextLink, { to: "/invoice-settlements", children: "debtor payments" }), " with unapplied cash."] }));
    if (isLoading || isError || noEntries) {
        return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "Unapplied Cash" }), _jsx("div", { "data-testid": "empty-state", children: emptyStateContent })] }));
    }
    if (paymentId && noEntries)
        return _jsx(Redirect, { to: "/unapplied-cash" });
    return (_jsxs(_Fragment, { children: [!paymentId && _jsx(PageTitle, { title: "Unapplied Cash" }), _jsxs(BasicTable, { highlightOnHover: true, style: { textAlign: 'left' }, children: [_jsx(BasicTable.Thead, { children: _jsxs(BasicTable.Tr, { children: [_jsx(BasicTable.Th, { children: "Payment" }), _jsx(BasicTable.Th, { children: "Invoice Settlement" }), _jsx(BasicTable.Th, { children: "Posted At" }), _jsx(BasicTable.Th, { children: "Unapplied Amount" }), _jsx(BasicTable.Th, { children: "Source" }), _jsx(BasicTable.Th, { children: "Debtor" })] }) }), _jsx(BasicTable.Tbody, { children: entries?.map((entry) => {
                            const isCurrentPayment = paymentId === String(entry.collectionCheckId);
                            return (_jsxs(BasicTable.Tr, { bg: isCurrentPayment ? 'gray.0' : undefined, style: { cursor: 'pointer' }, children: [_jsx(BasicTable.Td, { children: _jsx(TextLink, { to: "/unapplied-cash/:paymentId", urlParams: [entry.collectionCheckId], children: entry.checkNumber }) }), _jsx(BasicTable.Td, { children: _jsx(TextLink, { to: "/invoice-settlements/:settlementId/edit", urlParams: [entry.paymentBatch.id], children: entry.paymentBatch.batchNumber }) }), _jsx(BasicTable.Td, { children: formatDate.localeDateString(entry.datePosted) }), _jsx(BasicTable.Td, { children: formatCurrency(entry.amount) }), _jsx(BasicTable.Td, { children: sourceMap[entry.source.toLowerCase()] }), _jsx(BasicTable.Td, { children: entry.debtorName })] }, entry.id));
                        }) })] })] }));
};
