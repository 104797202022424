import { accountingConstants } from '../_constants'

export const accountingActions = {
  setAccounting,
  setActiveCustomers,
  addActiveCustomer,
  updateActiveCustomer,
}

function setAccounting(connected, platform) {
  return {
    type: accountingConstants.SET_ACCOUNTING,
    connected,
    platform,
  }
}

function setActiveCustomers(customers) {
  return {
    type: accountingConstants.SET_ACTIVE_CUSTOMERS,
    customers,
  }
}

function addActiveCustomer(customer) {
  return {
    type: accountingConstants.ADD_ACTIVE_CUSTOMERS,
    customer,
  }
}

function updateActiveCustomer(customer, index) {
  return {
    type: accountingConstants.UPDATE_ACTIVE_CUSTOMER,
    customer,
    index,
  }
}
