import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { Text } from 'xen-ui';
import { searchParam } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { useCreateApplicationAccess } from '../../../api/use-integration-plaid/use-create-application-access';
export const PlaidModal = ({ linkToken, entityId, entityType = 'tenant', }) => {
    const { isError: isLinkTokenError, mutateAsync: createApplicationAccess } = useCreateApplicationAccess();
    const onSuccess = async (publicToken, metadata) => {
        const { institution } = metadata;
        await createApplicationAccess({
            entityId,
            entityType,
            publicToken,
            institutionName: institution?.name || 'None',
            institutionId: institution?.institution_id || 'None',
        });
        const data = queryClient.getQueryData(['integration-plaid-accounts', { queryParams: { entityType } }]);
        const accountNumbers = typeof data === 'object' && Array.isArray(data) ? data.length : 0;
        searchParam.set('integrationPlaidAccounts', String(accountNumbers));
    };
    const { open, ready,
    // error,
    // exit
     } = usePlaidLink({
        token: linkToken,
        onSuccess,
    });
    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open, linkToken]);
    if (isLinkTokenError) {
        return _jsx(Text, { children: "Error connecting account. Please try again." });
    }
    return null;
};
