import request from './axios_service'
import { UTCDateToSystemString } from '../_helpers'

export const userService = {
  getUser,
  updateUser,
  updateUserBatch,
  setBusinessUser,
  getUserNotificationSettings,
  updateUserNotificationSettings,
  getUserNotifications,
  markNotificationAsRead,
}

function getUser(userSessionToken) {
  return request({
    url: `/login/current_user/${userSessionToken}`,
    method: 'GET',
    uncancelable: true,
  })
}

function updateUser(attr, val, userId) {
  return request({
    url: `/users/${userId}`,
    uncancelable: true,
    method: 'PUT',
    data: {
      [attr]: val,
    },
  })
}

function getUserNotificationSettings(userId) {
  return request({
    url: `/v2/users/${userId}/notification_settings`,
    method: 'GET',
  })
}

function getUserNotifications(userId, page = 1) {
  const urlParams = new URLSearchParams()
  urlParams.append('page', page)
  urlParams.append('per_page', 10)
  return request(
    {
      url: `/v2/users/${userId}/notifications?${urlParams.toString()}`,
      method: 'GET',
    },
    null,
    true
  ).then((response) => {
    const results = response.data.map((notification) => {
      return { ...notification, created_at: UTCDateToSystemString(notification.created_at) }
    })

    return { results: results, total_results: response.headers['total-count'] }
  })
}

function markNotificationAsRead(notificationId) {
  return request({
    url: `/v2/notifications/${notificationId}/read`,
    method: 'POST',
  })
}
function updateUserNotificationSettings(userId, data) {
  return request({
    url: `/v2/users/${userId}/notification_settings`,
    method: 'PUT',
    data: { settings: data },
  })
}

function updateUserBatch(data, userId) {
  return request({
    url: `/users/${userId}`,
    method: 'PUT',
    data: data,
  })
}

function setBusinessUser(data, businessId) {
  return request({
    url: `/users`,
    method: 'POST',
    data: { ...data, business_id: businessId, user_type: 'prospect' },
  })
}
