import React, { lazy } from 'react'
import { Route, Redirect } from 'react-router-dom'

// Components
import { PageNotReady } from '../PageNotReady'

// Constants
import ROUTES, { LENDER_ROUTES, ABL_LENDER_ROUTES } from '../_constants/routes'

// Helpers
import { isLender } from '../_helpers/user-utils'
import { isProductEnabled } from '../_helpers/flags'

export const ABLLenderRoutes = ({ loggedIn, user, featureFlags }) => {
  // Only for asset based lenders
  if (!isLender(loggedIn, user.user_type) || !isProductEnabled('Asset Based Lending', featureFlags)) {
    return null
  } else if (
    isLender(loggedIn, user.user_type) &&
    !isProductEnabled('Operate', featureFlags) &&
    isProductEnabled('Asset Based Lending', featureFlags)
  ) {
    return <Route component={PageNotReady} />
  }
  let loadedModules = {}

  const prospectsOverview = import(`../_views/factoring/lender/NewProspectsOverview/index.js`)

  //dynamically load pages and dependencies for abl routes
  const pageComponents = {
    ABLLenderDashboard: import('../_views/ABLLenderViews/ABLLenderDashboard/index_new.js').then((m) => {
      loadedModules['ABLLenderDashboard'] = m
      return m
    }),
    BBCOverviewDashboard: import('../_views/LenderViews/BBCOverviewDashboard/index').then((m) => {
      loadedModules['BBCOverviewDashboard'] = m
      return m
    }),
    BBCOverview: import('../_views/LenderViews/BBCOverview/index.js').then((m) => {
      loadedModules['BBCOverviewDashboard'] = m
      return m
    }),
    BBSubmission: import('../BorrowingBaseCertificate/BorrowingBaseSubmission/index').then((m) => {
      loadedModules['BBSummission'] = m
      return m
    }),
    BBSummary: import('../BorrowingBaseCertificate/BBCSummary/index.js').then((m) => {
      loadedModules['BBSummary'] = m
      return m
    }),
    BBCTemplatesOverview: import('../_views/LenderViews/BBCTemplatesOverview/index.js').then((m) => {
      loadedModules['BBCTemplatesOverview'] = m
      return m
    }),
    BBCTemplateCreate: import('../_views/LenderViews/BBCTemplateCreate/index.js').then((m) => {
      loadedModules['BBCTemplateCreate'] = m
      return m
    }),
    BBCTemplateDetails: import('../_views/LenderViews/BBCTemplateDetails/index.js').then((m) => {
      loadedModules['BBCTemplateDetails'] = m
      return m
    }),
    FundingRequestOverview: import('../_views/LenderViews/FundingRequestOverview/index.js').then((m) => {
      loadedModules['FundingRequestOverview'] = m
      return m
    }),
    FundingRequestPage: import('../_views/LenderViews/FundingRequestPage/FundingRequestPageABL/index.js').then((m) => {
      loadedModules['FundingRequestPage'] = m
      return m
    }),
    FundingRequestCreate: import('../_views/LenderViews/FundingRequestCreate/index.js').then((m) => {
      loadedModules['FundingRequestCreate'] = m
      return m
    }),
    BBCWorkflow: import('../_views/ABLLenderViews/BBCWorkflow/index.js').then((m) => {
      loadedModules['BBCWorkflow'] = m
      return m
    }),
    ABL_BBCTemplates: import('../_views/ABLLenderViews/BBCTemplates/index.js').then((m) => {
      loadedModules['ABL_BBCTemplates'] = m
      return m
    }),
    ABL_BBCTemplateDetails: import('../_views/ABLLenderViews/BBCTemplateDetails/index.js').then((m) => {
      loadedModules['ABL_BBCTemplateDetails'] = m
      return m
    }),
    BBCInvoices: import('../BorrowingBaseCertificate/BBCInvoices/index.js').then((m) => {
      loadedModules['BBCInvoices'] = m
      return m
    }),
    ABL_ProspectsOverview: prospectsOverview.then((m) => {
      loadedModules['ABL_ProspectsOverview'] = m
      return m
    }),
    BusinessEdit: import('../_views/abl/lender/BusinessEdit/index.js').then((m) => {
      loadedModules['BusinessEdit'] = m
      return m
    }),
    DailyStatements: import('../_views/abl/DailyStatements/index.js').then((m) => {
      loadedModules['DailyStatements'] = m
      return m
    }),
  }

  const ablLenderRoutes = [
    // ABL Lender Dashboard
    {
      path: ROUTES.lender_home,
      Component: lazy(() => pageComponents['ABLLenderDashboard']),
      module: 'ABLLenderDashboard',
    },
    // Borrowing Bases
    {
      path: LENDER_ROUTES.borrowing_base_workflow + '/:clientId',
      Component: lazy(() => pageComponents['BBCWorkflow']),
      module: 'BBCWorkflow',
    },
    {
      path: LENDER_ROUTES.borrowing_base_overview + '/:status',
      Component: lazy(() => pageComponents['BBCOverview']),
      exact: true,
      module: 'BBCOverview',
    },
    {
      path: LENDER_ROUTES.borrowing_base_overview,
      Component: lazy(() => pageComponents['BBCOverviewDashboard']),
      exact: true,
      module: 'BBCOverviewDashboard',
    },
    {
      path: LENDER_ROUTES.borrowing_base_submission + '/:bbcId/invoices',
      exact: true,
      Component: lazy(() => pageComponents['BBCInvoices']),
      module: 'BBCInvoices',
    },
    {
      path: LENDER_ROUTES.borrowing_base_submission + '/:bbcId',
      exact: true,
      Component: lazy(() => pageComponents['BBSubmission']),
      module: 'BBSubmission',
    },
    {
      path: LENDER_ROUTES.borrowing_base_summary + '/:bbcId',
      exact: true,
      Component: lazy(() => pageComponents['BBSummary']),
      module: 'BBSummary',
    },
    {
      path: LENDER_ROUTES.borrowing_base_templates,
      Component: lazy(() => pageComponents['BBCTemplatesOverview']),
      exact: true,
      module: 'BBCTemplatesOverview',
    },
    {
      path: LENDER_ROUTES.borrowing_base_templates_create,
      Component: lazy(() => pageComponents['BBCTemplateCreate']),
      module: 'BBCTemplateCreate',
    },
    {
      path: LENDER_ROUTES.borrowing_base_templates + '/:templateId',
      Component: lazy(() => pageComponents['BBCTemplateDetails']),
      module: 'BBCTemplateDetails',
    },
    // Funding Requests
    {
      path: LENDER_ROUTES.funding_requests,
      Component: () => <Redirect to={LENDER_ROUTES.funding_requests_pending} />,
      exact: true,
    },
    {
      path: LENDER_ROUTES.funding_requests_pending,
      Component: lazy(() => pageComponents['FundingRequestOverview']),
      exact: true,
      module: 'FundingRequestOverview',
    },
    {
      path: LENDER_ROUTES.funding_requests_approved,
      Component: lazy(() => pageComponents['FundingRequestOverview']),
      exact: true,
      module: 'FundingRequestOverview',
    },
    {
      path: LENDER_ROUTES.funding_requests_funded,
      Component: lazy(() => pageComponents['FundingRequestOverview']),
      exact: true,
      module: 'FundingRequestOverview',
    },
    {
      path: LENDER_ROUTES.funding_requests_rejected,
      Component: lazy(() => pageComponents['FundingRequestOverview']),
      exact: true,
      module: 'FundingRequestOverview',
    },
    {
      path: LENDER_ROUTES.funding_requests_create,
      Component: lazy(() => pageComponents['FundingRequestCreate']),
      module: 'FundingRequestCreate',
    },
    {
      path: ABL_LENDER_ROUTES.funding_requests + '/:fundingRequestId',
      Component: lazy(() => pageComponents['FundingRequestPage']),
      module: 'FundingRequestPage',
    },
    // Advanced BBC work
    {
      path: ABL_LENDER_ROUTES.bbc_templates,
      Component: lazy(() => pageComponents['ABL_BBCTemplates']),
      exact: true,
      module: 'ABL_BBCTemplates',
    },
    {
      path: ABL_LENDER_ROUTES.bbc_templates + '/:templateTypeId',
      Component: lazy(() => pageComponents['ABL_BBCTemplates']),
      module: 'ABL_BBCTemplates',
    },
    {
      path: ABL_LENDER_ROUTES.bbc_template_details + '/:templateId',
      Component: lazy(() => pageComponents['ABL_BBCTemplateDetails']),
      module: 'ABL_BBCTemplateDetails',
    },
    {
      path: LENDER_ROUTES.prospects_overview,
      exact: true,
      Component: lazy(() => pageComponents['ABL_ProspectsOverview']),
      module: 'ABL_ProspectsOverview',
    },
    {
      path: ABL_LENDER_ROUTES.business_edit + '/:businessId',
      Component: lazy(() => pageComponents['BusinessEdit']),
      module: 'BusinessEdit',
    },
    {
      path: ROUTES.statements_daily + '/:businessId/:date',
      Component: lazy(() => pageComponents['DailyStatements']),
      module: 'DailyStatements',
    },
  ]

  return ablLenderRoutes.map(({ path, Component, exact = false, module }) => (
    // Butt ugly, but this prevents the suspense fallback component from displaying unnecessicarily
    <Route
      exact={exact}
      key={path}
      path={path}
      render={(props) => {
        if (module && loadedModules[module]) {
          //Set status to resolved and result to default export of downloaded module to prevent briefly loading suspense component. See https://github.com/facebook/react/blob/master/packages/react/src/ReactLazy.js
          Component._status = 1
          Component._result = loadedModules[module].default
        }
        return <Component {...props} />
      }}
    />
  ))
}
