import { reportsUpdateConstants } from '../_constants'

export function reportsUpdate(state = {}, action) {
  switch (action.type) {
    case reportsUpdateConstants.SET_REPORTS_LAST_UPDATE:
      return {
        ...state,
        reportsLastUpdate: action.payload,
      }
    case reportsUpdateConstants.SET_REPORTS_SHOULD_NOTIFY:
      return {
        ...state,
        reportsShouldNotify: action.payload,
      }
    default:
      return state
  }
}
