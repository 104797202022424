import { adminConstants } from '../_constants'

export const adminActions = {
  setAdminTenant,
}

function setAdminTenant(tenant_id) {
  return {
    type: adminConstants.SET_ADMIN_TENANT,
    tenant_id,
  }
}
