import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { z, ZodError } from 'zod';
import { TextInput as XenTextInput } from 'xen-ui';
import { formatDecimal, formatDecimalAsNumber } from 'xen/helpers';
import { ErrorText } from '../../error-text';
const formatCurrencyValue = (val) => {
    if (val === '')
        return '';
    // Remove all commas, conver to number, round to 2 decimal places
    const numVal = formatDecimalAsNumber(val);
    // Convert back to string properly formatted with commas
    return formatDecimal(numVal);
};
export function FormCurrencyInput({ name, control, defaultValue, error, rules, onBlur, onChange, shouldUnregister, ...props }) {
    const { field: { value: fieldValue, onChange: fieldOnChange, ...field }, fieldState, } = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const [value, setValue] = useState(formatCurrencyValue(fieldValue));
    const fieldError = error ?? fieldState.error?.message;
    return (_jsx(XenTextInput, { ...field, ...props, error: fieldError ? _jsx(ErrorText, { component: "span", message: fieldError }) : undefined, inputWrapperOrder: ['label', 'description', 'error', 'input'], leftSection: '$', leftSectionPointerEvents: "none", onBlur: (e) => {
            // Ensure that the value is formatted correctly onBlur (clean up what the user typed)
            // unless there's an error message, in which case we don't want to format the value.
            let val = e.target.value;
            if (!fieldError) {
                val = formatCurrencyValue(val);
            }
            fieldOnChange(val);
            setValue(val);
            onBlur?.(e);
        }, onChange: (e) => {
            // Parse as a string so we allow commas and decimals and show validation
            // about incorrect format like multiple commas/decimals and alphabetic
            // characters
            const val = e.target.value;
            fieldOnChange(val);
            setValue(val);
            onChange?.(e);
        }, styles: { root: { display: 'flex', flexDirection: 'column', gap: '.25rem' }, ...props.styles }, value: value }));
}
const currencyInputSchema = ({ label, name, required = false }) => z.string().refine((val) => {
    if (required && val === '') {
        throw new ZodError([{ path: [name], message: `${label} is required`, code: 'custom' }]);
    }
    if (val.includes(',,') || val.includes('..')) {
        throw new ZodError([
            {
                path: [name],
                message: `${label} must be a valid number`,
                code: 'custom',
            },
        ]);
    }
    const numVal = Number(val.replaceAll(',', ''));
    if (isNaN(numVal)) {
        throw new ZodError([{ path: [name], message: `${label} must be a valid number`, code: 'custom' }]);
    }
    if (numVal <= 0) {
        throw new ZodError([
            {
                path: [name],
                message: `${label} must be greater than 0`,
                code: 'too_small',
                minimum: 0,
                inclusive: false,
                type: 'number',
            },
        ]);
    }
    // Based on MYSQL 'precision: 20, scale: 8' for associated columns
    if (numVal.toFixed(2).length > 15) {
        throw new ZodError([
            {
                path: [name],
                message: `${label} must be less than 10 billion`,
                code: 'too_big',
                maximum: 10_000_000_000,
                inclusive: false,
                type: 'number',
            },
        ]);
    }
    return true;
}, {
    message: `${label} must be valid number greater than 0`,
});
FormCurrencyInput.schema = currencyInputSchema;
FormCurrencyInput.formatValue = formatDecimalAsNumber;
