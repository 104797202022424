import { jsx as _jsx } from "react/jsx-runtime";
import { z } from 'zod';
import { useGetVerificationTypes } from 'xen/api/use-invoice';
import { FormSelect } from '../select';
export const FormInvoiceVerificationSelect = ({ allowDeselect = false, label = 'Invoice Verification Type', nothingFoundMessage = 'No invoice verification type found...', required = false, ...props }) => {
    const { data: invoiceVerificationTypes = [] /* isSuccess: isInvoiceVerificationTypes */ } = useGetVerificationTypes();
    const invoiceVerificationTypesData = invoiceVerificationTypes.map((invoiceVerificationType) => {
        return {
            label: invoiceVerificationType.name,
            value: String(invoiceVerificationType.id),
        };
    });
    return (_jsx(FormSelect, { allowDeselect: allowDeselect ?? !required, clearable: false, data: invoiceVerificationTypesData, label: label, nothingFoundMessage: nothingFoundMessage, required: required, searchable: true, ...props }));
};
const invoiceVerificationTypeSelectSchema = z.string().min(1, { message: 'Verification type is required' });
FormInvoiceVerificationSelect.schema = invoiceVerificationTypeSelectSchema;
