import React, { useState, useEffect } from 'react'
import { CountryRegionData } from 'react-country-region-selector'

// Components
import { SelectInput } from '../../FormInput'

let COUNTRIES = CountryRegionData.map((country) => {
  return {
    display: country[0],
    value: country[0],
  }
})

const updateFromProps = (value, defaultValue) => {
  // Generally these will be uncontrolled
  const isControlled = value !== undefined
  const valueToUse = isControlled ? value : defaultValue
  const newDisplayValue = valueToUse
  return newDisplayValue
}

export const CountryField = (props) => {
  const { id = null, value, defaultValue, onChange = () => {}, country, ...rest } = props
  const [displayValue, setDisplayValue] = useState(updateFromProps(value, defaultValue))

  // Allow us to reset the field when ID changes
  useEffect(() => {
    setDisplayValue(updateFromProps(value, defaultValue))
  }, [id, value])

  if (props.countries) {
    COUNTRIES = COUNTRIES.reduce((nameArr, country) => {
      if (props.countries.includes(country.value)) {
        nameArr.push(country)
        return nameArr
      } else {
        return nameArr
      }
    }, [])
  }

  const countries = [{ display: 'Select Country', value: '' }].concat(COUNTRIES)

  return (
    <SelectInput
      {...rest}
      id={id}
      onChange={(e) => onChange(e.target.value, id)}
      options={countries}
      value={displayValue}
    />
  )
}
