import { jsx as _jsx } from "react/jsx-runtime";
import { Loader } from 'xen-ui';
import { useGetConfiguration } from 'xen/api/use-configuration';
import { ErrorText } from 'xen/components';
import { BrandingConfigurationUpdateForm } from '../components/branding-configuration-update-form';
import { ConfigurationsWrapper } from '../components/configurations-wrapper';
const General = () => {
    const { data: configuration, error: getConfigurationError } = useGetConfiguration();
    if (getConfigurationError) {
        return (_jsx(ConfigurationsWrapper, { activeTab: "root", children: _jsx(ErrorText, { message: getConfigurationError.message }) }));
    }
    if (!configuration) {
        return (_jsx(ConfigurationsWrapper, { activeTab: "root", children: _jsx(Loader, {}) }));
    }
    return (_jsx(ConfigurationsWrapper, { activeTab: "root", children: _jsx(BrandingConfigurationUpdateForm, { configuration: configuration }) }));
};
export default General;
