import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { queryKeyFactory } from './query-keys-factory';
import { transformPaymentResponseToPayment } from './transform';
async function getPayment(id) {
    try {
        const response = await request({
            method: 'get',
            url: `/operate/checks/${id}`,
        });
        const data = transformPaymentResponseToPayment(response);
        return data;
    }
    catch (_error) {
        throw Error('Error fetching payment');
    }
}
export const useGetPayment = (id) => {
    return useQuery({
        queryKey: queryKeyFactory.payment(id),
        queryFn: () => getPayment(id),
        enabled: id !== undefined,
    });
};
