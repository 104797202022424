import { connect } from 'react-redux'
import { alertActions } from '../_actions'
import { tenantColors } from '../_constants/colors'

import Swal from 'sweetalert2'

// Customisable Properties
//
// title: <String>
// text: <String>
// type: <String> [warning, error, success, info, question]
// showConfirmButton: <boolean> (default: true)
// confirmButtonText: <String> (default: `Ok`)
// showCancelButton: <boolean> (default: true)
// cancelButtonText: <String> (default: `Cancel)
// preventClose: <boolean> (default: false)
// onConfirm: function()
// onCancel: function()

const Alert = ({ alert, hideAlert, tenant }) => {
  Swal.fire({
    title: alert.title,
    text: alert.message,
    type: alert.type,
    showConfirmButton: alert.showConfirmButton === false ? false : true,
    confirmButtonText: alert.confirmButtonText || `OK`,
    confirmButtonColor: `${tenantColors[tenant].secondary}`,
    showCancelButton: alert.showCancelButton === false ? false : true,
    cancelButtonText: alert.cancelButtonText || `Cancel`,
    focusConfirm: false,
    showCloseButton: alert.preventClose === true ? false : true,
    padding: `2em`,
    allowOutsideClick: alert.preventClose === true ? false : true,
    allowEscapeKey: alert.preventClose === true ? false : true,
    onAfterClose: () => {
      hideAlert()
    },
  }).then((result) => {
    if (result.value) {
      alert.onConfirm && alert.onConfirm()
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      alert.onCancel && alert.onCancel()
    }
  })
  return true
}

const mapStateToProps = (state) => {
  const { env } = state
  return {
    tenant: env.tenant,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideAlert: () => {
      dispatch(alertActions.hideAlert())
    },
  }
}

const connectedAlert = connect(mapStateToProps, mapDispatchToProps)(Alert)
export { connectedAlert as Alert }
