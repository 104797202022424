import { jsx as _jsx } from "react/jsx-runtime";
import { Loader, Stack } from 'xen-ui';
import { useGetInvoiceScheduleInvoices } from 'xen/api/use-invoice-schedule';
import { ErrorText } from 'xen/components';
import { InvoiceCard } from 'xen/_modules/invoices/components';
export const InvoiceScheduleInvoices = ({ invoiceSchedule }) => {
    const { data: invoices = [], isLoading: isInvoicesLoading, isError: isInvoicesError, } = useGetInvoiceScheduleInvoices({
        queryParams: { pagination: { count: 1000 } },
        urlParams: { scheduleId: invoiceSchedule.id },
    });
    if (isInvoicesLoading)
        return _jsx(Loader, {});
    if (isInvoicesError)
        return _jsx(ErrorText, { message: "Could not fetch invoices" });
    return (_jsx(Stack, { children: invoices.map((invoice) => (_jsx(InvoiceCard, { invoice: invoice }, invoice.id))) }));
};
