import request from './axios_service'
import { checksDatesToSystemTime, formHeader } from '../_helpers'
import { UTCDateToSystemString, absoluteCurrentDateinUTC, dateToUTCString, getDate } from '../_helpers/dates'
import { addBackwardCompatibilityNames, invoiceDatesToSystemTime, invoiceDatesToUTC } from '../_helpers/invoices'
import { notesDatesToSystemTime } from '../_helpers/notes'
import { fundingRequestsToSystemTime } from '../_helpers/funding-requests'
import { convertSystemTimeToDate } from '../_helpers/convertSystemTimeToDate'
import { formatInvoiceSchedules } from '../_helpers/invoice-schedules'
import { transactionDatesToSystemTime } from '../_helpers/transactions'
import { operationDatesToSystemTime } from '../_helpers/operations'

import config from 'config'

// Error Handler
import { paymentBatchErrors } from '../_errorHandlers'

//Type definitions for JSDoc
/**
 *
 * @typedef {Object} Document
 * @property {Number} id
 * @property {Date} created_at
 * @property {String} data_source
 * @property {Boolean} deleted
 * @property {String} document_description
 * @property {Date} document_issue_date
 * @property {String} document_name
 * @property {String} document_number
 * @property {String} document_type
 * @property {Date} updated_at
 *
 */

/** @typedef {Object} Invoice This type definition is incomplete
 * @property {String} advance_amount
 * @property {Boolean} archived
 * @property {Number} business_id
 * @property {String} cash_posted_amount
 * @property {Boolean} closed
 * @property {String} created_at
 * @property {String} debtor_due_date
 * @property {Number} debtor_id
 * @property {Boolean} deleted
 * @property {String} dilution_amount
 * @property {Object} errors
 * @property {String} face_value
 * @property {String} funded_amount
 * @property {String} funding_remaining
 * @property {Number} id
 * @property {Boolean} ineligible
 * @property {String} invoice_date
 * @property {String} invoice_number
 * @property {Number} invoice_schedule_id
 * @property {String} payment_terms
 * @property {Boolean} preclosed
 * @property {String} state
 * @property {Number} tenant_debtor_id
 * @property {Number} tenant_id
 * @property {String} updated_at
 */

/**
 *
 * @typedef {Object} PaymentAssignment
 * @property {String} action_type valid values are not_set, payment, partial_pay, chargeback, over_pay, and credit_memo
 * @property {Number} amount Action ammount
 * @property {String} business Will be a business object, but business is currently not Typed out
 * @property {Number} business_id
 * @property {Number} collections_check_id
 * @property {Date} created_at
 * @property {Number} id
 * @property {Invoice} invoice
 * @property {Number} invoice_id
 * @property {Number} payment_batch_id
 * @property {Number} tenant_id
 * @property {Date} updated_at
 */

/**
 *
 * @typedef {Object} PaymentBatch
 * @property {String} amount_posted
 * @property {Number} batch_number
 * @property {Date} created_at
 * @property {Date} date_created
 * @property {Date} date_posted
 * @property {Number} debtor_count
 * @property {Document[]} documents
 * @property {String} expected_total_value
 * @property {Number} id
 * @property {Number} payment_count
 * @property {String} state
 * @property {String} total_value
 * @property {Date} updated_at
 */

/**
 *
 * @typedef {Object} Term
 * @property {Number} id
 * @property {Number} business_id
 * @property {Date} created_at
 * @property {Number} entity 0 = FLAT, 1 = FACE_VALUE, 2 = ADVANCED, 3 = REMAINING
 * @property {Object} errors
 * @property {Null} event
 * @property {Number} range_begin # of days
 * @property {Number} range_end # of days
 * @property {Number} range_increment # of days
 * @property {Boolean} submitted
 * @property {String} type AdvanceRate, FeeOneTime, FeeTiered, FeeRecurring
 * @property {Date} updated_at
 * @property {Number} value
 */

/**
 * @typedef {object} CashFlow
 * @property {string} month_year - month and year in MM/YYYY format
 * @property {number} cash_in - total cash in for the month
 * @property {number} cash_out - total cash out for the month
 */

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Businesses
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const changeBusiness = (businessId, data) => {
  return request({
    url: `/businesses/${businessId}`,
    method: 'PUT',
    data: data,
  })
}

export const changeBusinessToClient = (businessId) => {
  return request({
    url: `/businesses/${businessId}`,
    method: 'PUT',
    data: {
      is_client: 1,
    },
  })
}

export const setClientToFactoring = (businessId) => {
  return request({
    url: `/businesses/${businessId}`,
    method: 'PUT',
    data: {
      financing_type: 'factoring',
    },
  })
}

export const setClientToABL = (businessId) => {
  return request({
    url: `/businesses/${businessId}`,
    method: 'PUT',
    data: {
      financing_type: 'abl',
    },
  })
}

export const getSalesPeople = () => {
  return request({
    url: `/users/sales_people`,
    method: 'GET',
  })
}

export const getSalesPersonByUserId = (userId) => {
  return request({
    url: `/sales_person/show_by_user/${userId}`,
    method: 'GET',
  })
}

export const updateSalesPerson = (businessId, userId) => {
  return request({
    url: `/businesses/${businessId}`,
    method: 'PUT',
    data: {
      sales_person_id: userId,
    },
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Borrowing Base Certificates
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Returns the total number of bbcs in the system
// TODO: make sure this is getting only the submitted ones
export const getBBCCount = (queryString = '') => {
  return request({
    url: `/operate/borrowing_base_certificates/count?${queryString}`,
    method: 'GET',
  })
}

// Delete invoice schedule
export const deleteInvoiceSchedule = (schedule_id) => {
  return request({
    //remove OR before merging
    url: `/invoice_schedules/${schedule_id}`,
    method: 'DELETE',
  })
}

// Returns a list of all the invoice schedules for the specified tenant
export const getInvoiceSchedules = (searchParams, uncancelable = false) => {
  return request({
    //remove OR before merging
    url: `/invoice_schedules?` + (searchParams || ''),
    method: 'GET',
    uncancelable: uncancelable,
  }).then((invoiceSchedules) => {
    const formattedInvoceSchdules = invoiceSchedules.map(formatInvoiceSchedules)
    return formattedInvoceSchdules
  })
}

export const getInvoiceSchedulesV2 = (searchParams) => {
  return request(
    {
      url: `/invoice_schedules?${searchParams.toString()}`,
      method: 'GET',
      data: null,
    },
    null,
    true
  ).then((response) => {
    return {
      results: response.data.map(formatInvoiceSchedules),
      total_results: response.headers['total-count'],
    }
  })
}

// Returns the number of invoice schedules for the specified tenant
export const getInvoiceSchedulesCount = (searchParams, uncancelable) => {
  return request({
    //remove OR before merging
    url: `/invoice_schedules/count?` + (searchParams || ''),
    method: 'GET',
    uncancelable: uncancelable,
  })
}

// Purchase invoice schedule validate
export const validateSchedulePurchase = (businessId, scheduleId, schedule_purchase_date) => {
  return request({
    url: `/businesses/${businessId}/invoices/purchase`,
    method: 'POST',
    data: {
      invoice_schedule_id: scheduleId,
      action_args: {
        default_fee_schedule: true,
        date: schedule_purchase_date,
      },
    },
  })
}

// Purchase invoice schedule
export const purchaseSchedule = (businessId, scheduleId, schedule_purchase_date, summaries) => {
  return request({
    url: `/businesses/${businessId}/invoices/purchase`,
    method: 'POST',
    data: {
      invoice_schedule_id: scheduleId,
      action_args: {
        default_fee_schedule: true,
        date: schedule_purchase_date,
        summaries: summaries,
      },
    },
  })
}

//purchasing invoice schedule with v2 of transaction modules
export const proposeSchedulePurchase = (scheduleId, schedule_purchase_date) => {
  return request({
    url: `/ledger/invoice_schedules/${scheduleId}/purchase?propose=true`,
    method: 'PUT',
    data: {
      invoice_schedule_id: scheduleId,
      date: schedule_purchase_date,
    },
  })
}

//commit schedule purchase
export const commitSchedulePurchase = (scheduleId, schedule_purchase_date, batch_number, accounts, notify) => {
  let toPut = {
    invoice_schedule_id: scheduleId,
    date: schedule_purchase_date,
    batch_number,
    accounts,
  }
  if (notify) {
    toPut = { ...toPut, notify }
  }
  return request({
    url: `/ledger/invoice_schedules/${scheduleId}/purchase`,
    method: 'PUT',
    data: toPut,
  })
}

// Returns the number of invoice schedules for the specified tenant
export const getInvoiceSchedule = (id) => {
  return request({
    url: `/invoice_schedules/${id}`,
    method: 'GET',
    uncancelable: true,
  }).then((invoice) => {
    invoice = invoiceDatesToSystemTime(invoice)
    invoice = addBackwardCompatibilityNames(invoice)
    invoice.notes = invoice.notes.map(notesDatesToSystemTime)
    return invoice
  })
}

export const updateInvoiceSchedule = (id, data) => {
  return request({
    url: `/invoice_schedules/${id}`,
    method: 'PUT',
    uncancelable: true,
    data,
  })
}

export const approveInvoiceSchedule = (id) => {
  const stringifyError = false
  return request(
    {
      url: `/invoice_schedules/${id}/approve`,
      method: 'PUT',
    },
    null,
    false,
    stringifyError
  )
}

export const unapproveInvoiceSchedule = (id) => {
  return request({
    url: `/invoice_schedules/${id}/unapprove`,
    method: 'DELETE',
  })
}

// Get Invoice Schedule Verification Details
export const getInvoiceScheduleVerificationDetails = (businessId, scheduleId) => {
  return request({
    url: `businesses/${businessId}/invoice_schedules/${scheduleId}/verifications`,
    method: 'GET',
  })
}

// Create a General Note for a schedule
export const createInvoiceScheduleGeneralNote = (id, body) => {
  return request({
    url: `/invoice_schedules/${id}/notes`,
    method: 'POST',
    data: {
      body: body,
    },
  })
}

export const uploadInvoiceScheduleDocument = (scheduleId, file) => {
  const data = new FormData()
  data.append(`filename`, file.name)
  data.append(`filedata`, file)
  return request(
    {
      url: `documents/upload_invoice_schedule_document/${scheduleId}`,
      method: 'POST',
      data,
    },
    null,
    false,
    false
  )
}

export const getScheduleInvoices = (scheduleId, params) => {
  return request({
    url: `/invoices?invoice_schedule_id=${scheduleId}&${params}`,
    method: 'GET',
    uncancelable: true,
  }).then((invoices) => invoices.map(invoiceDatesToSystemTime))
}

//get accrued fees for a specific invoice
export const getInvoiceAccruedFees = (invoice_id) => {
  return request({
    url: `/ledger/invoices/${invoice_id}/fees_accrued`,
    method: 'GET',
  })
}

//get history data for a specific invoice
export const getInvoiceHistory = (invoiceId) => {
  return request({
    url: `/invoices/${invoiceId}/history`,
    method: 'GET',
  }).then((history) => history.map((historyItem) => convertSystemTimeToDate(historyItem, ['date'])))
}

// TODO: confirm this returns in sorted order
export const getBBCCalcDetails = (bbcId) => {
  return request({
    url: `/operate/borrowing_base_certificates/${bbcId}/calc`,
    method: 'GET',
  })
}

// TODO: confirm this returns in sorted order
export const getBBCDetails = (bbcId) => {
  return request({
    url: `/operate/borrowing_base_certificates/${bbcId}`,
    method: 'GET',
  })
}

export const approveBBC = (bbcId, summaries) => {
  return request({
    url: `/operate/borrowing_base_certificates/${bbcId}/approval`,
    method: 'PUT',
    data: {
      approval_code: 'approve',
      comment: 'This borrowing base has been approved.',
      summaries: summaries,
    },
  })
}

export const rejectBBC = (bbcId) => {
  return request({
    url: `/operate/borrowing_base_certificates/${bbcId}/approval`,
    method: 'PUT',
    data: {
      approval_code: 'reject',
      comment: 'This borrowing base has been rejected.',
    },
  })
}

export const proposeBBC = (bbcId) => {
  return request({
    url: `/operate/borrowing_base_certificates/${bbcId}/approval`,
    method: 'PUT',
    data: {
      approval_code: 'propose',
      comment: 'This borrowing base updated with new proposed amounts.',
    },
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Funding Requests
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Returns a (sub)list of all the funding requests for the specified tenant
export const getFundingRequests = (queryString = 'count=100') => {
  return request({
    url: `/operate/funding_requests?${queryString}`,
    method: 'GET',
  }).then((fundingRequests) => {
    const formattedRequests = fundingRequests.map(fundingRequestsToSystemTime)
    return formattedRequests
  })
}

export const getFundingRequestsCount = (queryString) => {
  return request({
    url: `/operate/funding_requests/count?${queryString}`,
    method: 'GET',
  })
}

export const getFundingRequestDetails = (fundingRequestId) => {
  return request({
    url: `/operate/funding_requests/${fundingRequestId}`,
    method: 'GET',
  }).then((response) => {
    const formattedResponse = fundingRequestsToSystemTime(response)
    return formattedResponse
  })
}

export const proposeFundingRequest = (id) => {
  return request({
    url: `/operate/funding_requests/${id}/approval`,
    method: 'PUT',
    data: {
      approval_code: 'propose',
      comment: 'This funding requests updated with new proposed amounts.',
    },
  })
}

export const updateFundingRequest = (id, attr, value) => {
  return request({
    url: `/operate/funding_requests/${id}`,
    method: 'PUT',
    data: {
      [attr]: value,
    },
  })
}

export const updateFundingRequestData = (id, data) => {
  return request({
    url: `/operate/funding_requests/${id}`,
    method: 'PUT',
    data: data,
  })
}

export const unapproveFundingRequest = (fundingRequestId) => {
  return request({
    url: `/operate/funding_requests/${fundingRequestId}/approval`,
    method: 'PUT',
    data: {
      approval_code: 'unapprove',
    },
  })
}

export const approveFundingRequest = (fundingRequestId, summaries) => {
  return request({
    url: `/operate/funding_requests/${fundingRequestId}/approval`,
    method: 'PUT',
    data: {
      approval_code: 'approve',
      comment: 'This funding request has been approved.',
      summaries: summaries,
    },
  })
}

export const rejectFundingRequest = (fundingRequestId) => {
  return request({
    url: `/operate/funding_requests/${fundingRequestId}/approval`,
    method: 'PUT',
    data: {
      approval_code: 'reject',
      comment: 'This funding request has been reject.',
    },
  })
}

// Get business summary for detail in Funding Request Client Detail section
export const getFundingRequestClientDetails = (clientId, uncancelable = false) => {
  return request({
    url: `/ledger/summaries/businesses/${clientId}`,
    method: 'GET',
    uncancelable: uncancelable,
  })
}

// Propose fund approved request
export const proposeFundFundingRequest = (fundingRequestId, data) => {
  return request({
    url: `/ledger/funding_requests/${fundingRequestId}/fund?propose=true`,
    method: 'PUT',
    data: data,
  })
}

// Mark FR as 'funded' and move funds in the backend.
export const fundApprovedFundingRequest = (fundingRequestId, proposeData) => {
  return request({
    url: `/ledger/funding_requests/${fundingRequestId}/fund`,
    method: 'PUT',
    data: proposeData,
  })
}

export const uploadFundingRequestDoc = (funding_request_id, file) => {
  const data = new FormData()
  data.append(`filename`, file.name)
  data.append(`filedata`, file)

  return request({
    url: `/documents/upload_funding_request_document/${funding_request_id}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

export const getFundingRequestDocuments = (fundingRequestId, searchParams) => {
  return request({
    url: `/operate/funding_requests/${fundingRequestId}/get_documents/?${searchParams}`,
    method: 'GET',
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Borrowing Base Templates
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Get all completed template sections for tenant
export const getAllLockedBBCTemplates = (params) => {
  return request({
    url: `/operate/bbc_form_template/sections?${params}`,
    method: 'GET',
  })
}

// Get all incomplete / pending template sections for tenant
export const getAllUnlockedBBCTemplates = () => {
  return request({
    url: `/operate/bbc_form_template/sections?locked=false&count=50`,
    method: 'GET',
  })
}

// Search for specific types of bbc templates
export const searchBBCTemplates = (query) => {
  return request({
    url: `/operate/bbc_form_template/sections${query ? '?' + query : ''}`,
    method: 'GET',
  })
}

// Create a new BBC Template
export const createBBCTemplateSection = (data) => {
  return request({
    url: `/operate/bbc_form_template/sections`,
    method: 'POST',
    data: data,
  })
}

// Get a BBC Template Section
export const getBBCTemplateSection = (sectionId) => {
  return request({
    url: `/operate/bbc_form_template/sections/${sectionId}`,
    method: 'GET',
  })
}

// Update a BBC Template Section
// - Send entire config every time
export const updateBBCTemplateSection = (sectionId, data) => {
  return request({
    url: `/operate/bbc_form_template/sections/${sectionId}`,
    method: 'PUT',
    data: data,
  })
}

// Get all BBC Sections associated with this business, as well as advance rate
export const getBBCConfigForBusiness = (businessId) => {
  return request({
    url: `/operate/businesses/${businessId}/bbc_form`,
    method: 'GET',
  })
}

// Associate the bbc section with a given business, establishing advance rate
// - Can't associate if it's not locked
export const associateBBCSectionWithBusiness = (sectionId, businessId, advanceRate, creditLimit, nickname) => {
  const data = {
    advance_rate: advanceRate,
    section_nickname: nickname,
  }
  if (creditLimit && creditLimit != 0) {
    data.credit_limit = creditLimit
  }
  return request({
    url: `/operate/bbc_form_template/sections/${sectionId}/associate/${businessId}`,
    method: 'PUT',
    data: data,
  })
}

// Disassociate the bbc section with a given business, establishing advance rate
// - Can't associate if it's not locked
export const dissociateBBCSectionAndBusiness = (sectionId, businessId) => {
  return request({
    url: `/operate/bbc_form_template/sections/${sectionId}/dissociate/${businessId}`,
    method: 'PUT',
    data: {},
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Users
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const getUsers = (userId) => {
  return request({
    url: `/users/${userId}`,
    method: 'GET',
  })
}

export const createUser = (userInfo, businessId, financingType = 'factoring') => {
  return request({
    url: `/users`,
    method: 'POST',
    data: {
      user: { ...userInfo, ['business_id']: businessId },
      financing_type: financingType,
    },
  })
}

export const updateUser = (userInfo, businessId, userId) => {
  return request({
    url: `/users/${userId}`,
    method: 'PUT',
    data: {
      user: { ...userInfo, ['business_id']: businessId },
    },
  })
}

export const getUsersForBusiness = (businessId, financingType = 'factoring') => {
  return request({
    url: `/users/search`,
    method: 'POST',
    data: {
      business_id: businessId,
      financing_type: financingType,
      constraints: {
        business_id: businessId,
      },
    },
  })
}

export const getBusinessPII = (businessId) => {
  return request({
    url: `/businesses/get_pii/${businessId}`,
    method: 'GET',
    data: null,
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Overview
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const getOverview = (businessId) => {
  return request({
    url: `/operate/overview`,
    method: 'GET',
  })
}

export const getPortfolio = () => {
  return request({
    url: `/lenders/portfolio`,
    method: 'GET',
  })
}
/**
 * Returns an array of up to twelve items with the month, year, end of month ar and total fees for the month
 * Ordered by year and month
 * @param {Integer} years_ago
 */
export const getFeesAr = (years_ago = 0) => {
  return request({
    url: `/lenders/fees_ar?years_ago=${years_ago}`,
    method: 'GET',
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Get Concentration for Clients and Debtors
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const getConcentration = () => {
  return request({
    url: `/lenders/concentration/`,
    method: 'GET',
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Get ArAging Table Data
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const getArAging = (params) => {
  return request({
    url: `/lenders/ar_aging?${params}`,
    method: 'GET',
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Invoices
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const getAllInvoicesForTenant = (params, cancelToken = {}) => {
  return request({
    url: `/invoices/?${params}`,
    method: 'GET',
    cancelToken: cancelToken.token,
  }).then((invoices) => {
    return invoices.map((invoice) => {
      invoice = invoiceDatesToSystemTime(invoice)
      invoice = addBackwardCompatibilityNames(invoice)
      return invoice
    })
  })
}

export const getAllInvoiceCount = (params = '', cancelToken = {}) => {
  return request({
    url: `/invoices/count?${params}`,
    method: 'GET',
    cancelToken: cancelToken.token,
  })
}

export const uploadInvoices = (file, financing_type) => {
  const data = new FormData()
  data.append(`file`, file, 'invoices.csv')
  data.append(`financing_type`, financing_type)
  return request(
    {
      url: `/invoices/bulk_upload`,
      method: 'POST',
      data,
    },
    null,
    false,
    false
  )
}

export const createEmptyInvoiceSchedule = (business_id) => {
  return request(
    {
      url: `/businesses/${business_id}/invoice_schedules`,
      method: 'POST',
    },
    null,
    false
  )
}

export const submitInvoiceSchedule = (schedule_id) => {
  return request(
    {
      url: `/invoice_schedules/${schedule_id}/submit`,
      method: 'PUT',
      data: null,
    },
    null,
    false,
    false
  )
}

export const addInvoicesToSchedule = (schedule_id, invoice_ids) => {
  return request(
    {
      url: `/invoice_schedules/${schedule_id}/invoices`,
      method: 'POST',
      data: { invoice_ids },
    },
    null,
    false,
    false
  )
}

export const getInvoice = (params) => {
  return request({
    url: `/invoices/${params}`,
    method: `GET`,
  }).then(invoiceDatesToSystemTime)
}

/**
 * NOTE: due to Rails (convention over configuration)
 * this specific param is structure:
 *  {invoce: {
 *    due_date: ...,
 *    ...
 *  }}
 */
export const createInvoiceForSchedule = (data) => {
  data.invoice = invoiceDatesToUTC(data.invoice)
  return request({
    url: `/invoice_schedules/${data.invoice_schedule_id}/invoices`,
    method: `POST`,
    data,
  }).then(invoiceDatesToSystemTime)
}

export const createNFInvoice = (data) => {
  data = invoiceDatesToUTC(data)
  return request({
    url: `/invoices/non_factored`,
    method: `POST`,
    data,
  }).then(invoiceDatesToSystemTime)
}

export const updateInvoice = (id, data) => {
  data = invoiceDatesToUTC(data)
  return request({
    url: `/invoices/${id}`,
    method: `PUT`,
    data,
  }).then(invoiceDatesToSystemTime)
}

export const deleteInvoice = (id) => {
  return request({
    url: `/invoices/${id}`,
    method: `DELETE`,
  })
}

export const performInvoiceChargeback = (invoice, amount, reason = 'Chargeback to client', summaries = null) => {
  const data = new FormData()
  data.append(`chargeback_amount`, amount)
  data.append(`chargeback_reason`, reason)
  if (summaries) {
    data.append(`summaries`, summaries)
  }
  return request({
    url: `operate/chargebacks/invoice/${invoice.id}`,
    method: 'POST',
    data,
  })
}

export const createInvoiceChargeback = (invoiceId, amount, reason = 'Chargeback to client', chargebackDate) => {
  const data = {
    invoice_id: invoiceId,
    chargeback_reason: reason,
    chargeback_amount: amount,
    chargeback_date: chargebackDate,
  }
  return request({
    url: 'operate/invoice_chargebacks',
    method: 'POST',
    data,
  })
}

export const proposeInvoiceChargeback = (invoiceChargebackId, data) => {
  return request({
    url: `ledger/invoice_chargebacks/${invoiceChargebackId}/process?propose=true`,
    method: 'PUT',
    data: data,
  })
}

export const commitInvoiceChargeback = (invoiceChargebackId, proposeData) => {
  return request({
    url: `ledger/invoice_chargebacks/${invoiceChargebackId}/process`,
    method: 'PUT',
    data: proposeData,
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Debtor Payments - Factoring
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const getBatchesChecks = (params = '') => {
  return request(
    {
      url: `/operate/payment_batches/checks?${params}`,
      method: 'GET',
    },
    null,
    true
  ).then((response) => {
    const { data, headers } = response
    const checks = checksDatesToSystemTime(data)
    return { checks, headers }
  })
}

export const getDebtorPayments = (params = '', cancelToken = {}) => {
  return request({
    url: `/operate/checks/?${params}`,
    method: 'GET',
    cancelToken: cancelToken.token,
  }).then((payments) => {
    payments = checksDatesToSystemTime(payments)
    return payments
  })
}

export const getDebtorPaymentsCount = (params = '', cancelToken = {}) => {
  return request({
    url: `/operate/checks/count/?${params}`,
    method: 'GET',
    cancelToken: cancelToken.token,
  })
}

export const getBorrowerDebtorPayments = (params = '', cancelToken = {}) => {
  return request({
    url: `/operate/borrower/checks/?${params}`,
    method: 'GET',
    cancelToken: cancelToken.token,
  }).then((payments) => {
    payments = checksDatesToSystemTime(payments)
    return payments
  })
}

export const getBorrowerDebtorPaymentsCount = (params = '', cancelToken = {}) => {
  return request({
    url: `/operate/borrower/checks/count/?${params}`,
    method: 'GET',
    cancelToken: cancelToken.token,
  })
}

// DEPRECATED
export const getDebtorPayment = (id) => {
  return request({
    url: `/operate/checks/${id}`,
    method: 'GET',
  })
}

export const uploadPaymentsCSV = (file) => {
  const data = new FormData()
  //hard coded filename here because type checking on platform isn't very robust
  data.append(`file`, file, 'paymentcsv.csv')
  return request(
    {
      url: `/operate/checks/bulk_upload`,
      data,
      method: 'POST',
    },
    null,
    false,
    false
  )
}

export const uploadNotesToCheck = ({ checkId, notes_body, notes_category }) => {
  const data = {
    body: notes_body,
    category: notes_category,
  }
  return request({
    url: `/operate/checks/${checkId}/notes`,
    method: 'POST',
    data: data,
  })
}

export const processInvoice = ({
  business_id,
  invoice_id,
  check_id,
  amount,
  date,
  close_invoice,
  summaries = null,
}) => {
  const data = {
    collections_check_id: check_id,
    total_amount: amount,
    close_invoice: close_invoice, // TODO: ask james
    date: date,
  }
  if (summaries !== null) {
    data['summaries'] = summaries
  }
  return request({
    url: `/businesses/${business_id}/invoices/${invoice_id}/process_invoice`,
    method: 'POST',
    data: data,
  })
}

export const processRefund = ({ business_id, invoice_id = null, check_id, amount, date, summaries = null }) => {
  const data = {
    business_id: business_id,
    total_amount: amount,
    date: date,
  }
  if (invoice_id !== null) {
    data['invoice_id'] = invoice_id
  }
  if (summaries !== null) {
    data['summaries'] = summaries
  }
  return request({
    url: `/operate/checks/${check_id}/refund`,
    method: 'POST',
    data: data,
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Debtors
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const getDebtors = (params = 'type=tenant', raw = false) => {
  return request(
    {
      url: `/debtors?${params}`,
      method: 'GET',
    },
    null,
    raw
  )
}

export const getBusinessDebtors = ({ business_id, params }, raw = false) => {
  return request(
    {
      url: `/factoring/businesses/${business_id}/debtors?${params}`,
      method: 'GET',
    },
    null,
    raw
  )
}

export const getChildDebtors = (id, params) => {
  return request({
    url: `/debtors/${id}/child_debtors?${params}`,
    method: 'GET',
  })
}

export const getChildDebtorsCount = (id, params) => {
  return request({
    url: `/debtors/${id}/child_debtors/count?${params}`,
    method: 'GET',
  })
}

export const searchDebtors = (params = 'type=tenant', raw = false) => {
  return request(
    {
      url: `/debtors_search?${params}`,
      method: 'GET',
    },
    null,
    raw
  )
}

export const getDebtorsCount = (params = 'type=tenant') => {
  return request({
    url: `/debtors/count?${params}`,
    method: 'GET',
  })
}

export const createDebtor = (debtorName) => {
  return request({
    url: `/debtors`,
    method: 'POST',
    data: {
      debtor_name: debtorName,
    },
  })
}

export const createTenantDebtor = (debtorId) => {
  return request({
    url: `/tenant_debtors`,
    method: 'POST',
    data: {
      debtor_id: debtorId,
    },
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Notes
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const getDealNotes = (dealId, params = '') => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${dealId}/notes?${params}`,
    method: 'GET',
  })
}

export const createDealNote = (dealId, content, createdBy) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${dealId}/notes`,
    method: 'POST',
    data: {
      created_by: createdBy,
      content: content,
    },
  })
}

export const updateDealNote = (dealId, noteId, payload) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${dealId}/notes/${noteId}`,
    method: 'PUT',
    data: payload,
  })
}

export const removeDealNote = (dealId, noteId) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${dealId}/notes/${noteId}`,
    method: 'DELETE',
  })
}

export const createInvoiceVerification = (invoice_id, verification_type_id, contact_id, body) => {
  return request({
    url: `/invoices/${invoice_id}/verifications`,
    method: 'POST',
    data: {
      body,
      contact_id,
      verification_type_id,
    },
  })
}

export const uploadVerificationDoc = (verification_id, file) => {
  const data = new FormData()
  data.append(`filename`, file.name)
  data.append(`filedata`, file)

  return request({
    url: `/documents/upload_verification_document/${verification_id}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Checks
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const getAllChecks = (businessId) => {
  return request({
    url: `/operate/checks?business_id=${businessId}`,
    method: 'GET',
  })
}

export const createCheck = (businessId, tenantId) => {
  return request({
    url: `/operate/checks`,
    method: 'POST',
    data: {
      collections_check: {
        business_id: businessId,
        amount: 1000,
      },
    },
  })
}

export const getCheck = (checkId) => {
  return request({
    url: `/operate/checks/${checkId}`,
    method: 'GET',
  })
}

export const updateCheck = (id, data) => {
  return request({
    url: `/operate/checks/`,
    method: 'PUT',
    data: {
      id: id,
      collections_check: data,
    },
  })
}

export const proposeCheck = (id, businessId) => {
  return request({
    url: `/operate/checks/${id}/apply`,
    method: 'PUT',
    data: {
      id: id,
      business_id: businessId,
    },
  })
}

export const applyCheck = (id, businessId, summaries) => {
  return request({
    url: `/operate/checks/${id}/apply`,
    method: 'PUT',
    data: {
      id: id,
      business_id: businessId,
      summaries: summaries,
    },
  })
}

export const uploadDocumentToCheck = (file, checkId) => {
  const data = new FormData()
  data.append(`filename`, file.name)
  data.append(`filedata`, file)

  return request({
    url: `/documents/upload_collections_check_document/${checkId}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

export const getDocument = (documentId) => {
  return request({
    url: `/documents/${documentId}`,
    method: 'GET',
  })
}

export const uploadInvoiceDocument = (file, business_id) => {
  const data = new FormData()
  data.append(`filename`, file.name)
  data.append(`filedata`, file)
  data.append('document_type', 'invoice')
  return request({
    url: `/documents/upload_invoice_document/${business_id}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

export const createPaymentBatch = (data = {}) => {
  return request({
    url: `/operate/payment_batches`,
    method: 'POST',
    data,
  })
}

export const getPaymentBatch = (id) => {
  return request({
    url: `/operate/payment_batches/${id}`,
    method: 'GET',
    data: null,
  }).then(paymentBatchErrors)
}

export const addCheckToBatch = (batchId, checkId) => {
  return request({
    url: `/operate/payment_batches/${batchId}/collections_checks/${checkId}`,
    method: 'POST',
    data: null,
  })
}

export const createPayment = (data) => {
  // data = paymentBatchesDatesToUTC(data)
  return request({
    url: `/operate/checks`,
    method: 'POST',
    data: { collections_check: { ...data } },
  })
}

export const updatePayment = (id, data) => {
  // data = paymentBatchesDatesToUTC(data)
  return request({
    url: `/operate/checks`,
    method: 'PUT',
    data: { id, collections_check: { ...data } },
  })
}
/**
 * Get payment batches matching criteria in search params (expected to be URLParams object stringified)
 * @param {String} searchParams URL Search criteria
 * @param {Boolean} raw
 * @return {PaymentBatch[]}
 */
export const getPaymentBatches = (searchParams) => {
  return request(
    {
      url: `/operate/payment_batches?${searchParams}`,
      method: 'GET',
      data: null,
    },
    null,
    true
  )
    .then((response) => {
      const { data: batches } = response
      const formmatedBatches = batches.map((batch) => {
        return {
          ...batch,
          date_created: getDate(UTCDateToSystemString(batch.created_at)),
          created_at: UTCDateToSystemString(batch.created_at),
        }
      })

      const data = formmatedBatches
      const headers = response.headers
      return { data, headers }
    })
    .catch((e) => console.error(e))
}
/**
 * Get number payment batches matching should be same string that's passed to getPaymentBatches. Returns number of payment batches matching filter criteria
 * @param {String} searchParams
 * @return {{count:Number}}
 */
export const getPaymentBatchCount = (searchParams) => {
  return request({
    url: `/operate/payment_batches/count?${searchParams}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Upload document for a payment batch
 * @param {Number} batchId Payment Batch Id
 * @param {File} file file to upload
 * @return {Document}
 */
export const uploadBatchDocument = (batchId, file) => {
  const data = new FormData()
  data.append(`filename`, file.name)
  data.append(`filedata`, file)
  return request({
    url: `/documents/upload_payment_batch_document/${batchId}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

/**
 * Utility function to fetch payment assignments for a given invoice/payment combination, returns array that should only have one element
 * @param {Number} paymentId
 * @param {Number} invoiceId
 * @return {PaymentAssignment[]}
 */
export const getPaymentAssignmentsForInvoice = (paymentId, invoiceId) => {
  return request({
    url: `/operate/payment_assignments?collections_check_id=${paymentId}&invoice_id=${invoiceId}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Utility function to fetch payment assignments for a given payment
 * @param {Number} paymentId
 * @return {PaymentAssignment[]} Array of payment assignments
 */
export const getPaymentAssignmentsForPayment = (paymentId) => {
  return request({
    url: `/operate/payment_assignments?collections_check_id=${paymentId}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Creates payment assignment
 * @param {PaymentAssignment} data
 * @return {PaymentAssignment} Created payment assignment
 */
export const createPaymentAssignment = (data) => {
  return request({
    url: `/operate/payment_assignments`,
    method: 'POST',
    data,
  })
}

/**
 * Updates payment assignment, with PUT HTTP call
 * @param {PaymentAssignment} data
 * @param {Number} payment_assignment_id
 * @return {PaymentAssignment} Updated payment assignment
 */
export const updatePaymentAssignment = (data, payment_assignment_id) => {
  //Correct the OBOB
  if (data.range_begin) {
    data.range_begin = Number(data.range_begin) - 1
  }
  if (data.range_end) {
    data.range_end = Number(data.range_end) - 1
  }
  return request({
    url: `/operate/payment_assignments/${payment_assignment_id}`,
    method: 'PUT',
    data,
  })
}

/**
 * Deletes payment assignment for a given id
 * @param {Number} payment_assignment_id
 */
export const deletePaymentAssignment = (payment_assignment_id) => {
  return request({
    url: `/operate/payment_assignments/${payment_assignment_id}`,
    method: 'DELETE',
  })
}

// Terms

/**
 * Create a term for a business, use this for setting advance rate as well
 * @param {Term} data
 * @param {Number} business_id
 * @returns {Term} Term that was created
 */
export const createTerm = (data, business_id) => {
  if (data.range_begin) {
    data.range_begin = Number(data.range_begin) - 1
  }
  if (data.range_end) {
    data.range_end = Number(data.range_end) - 1
  }
  return request(
    {
      url: `/businesses/${business_id}/terms_entries`,
      method: 'POST',
      data,
    },
    null,
    false,
    false
  )
}

/**
 * Get terms for a business
 * @param {Number} business_id
 * @returns {Term[]}
 */
export const getBusinessTerms = (business_id) => {
  return request(
    {
      url: `/businesses/${business_id}/terms_entries`,
      method: 'GET',
      data: null,
    },
    null,
    false,
    false
  ).then((terms) => {
    return terms.map((t) => {
      if (t.range_begin) {
        t.range_begin = Number(t.range_begin) + 1
      }
      if (t.range_end) {
        t.range_end = Number(t.range_end) + 1
      }
      return t
    })
  })
}

/**
 * Get term entry by ID
 * @param {Number} term_id
 * @returns {Term}
 */
export const getTerm = (term_id) => {
  return request({
    url: `/terms_entries/${term_id}`,
    method: 'GET',
    data: null,
  }).then((t) => {
    if (t.range_begin) {
      t.range_begin = Number(t.range_begin) + 1
    }
    if (t.range_end) {
      t.range_end = Number(t.range_end) + 1
    }
    return t
  })
}

/**
 * Update an existing term entry
 * @param {Number} term_id
 * @param {Term} data
 * @returns {Term}
 */
export const updateTerm = (term_id, data) => {
  if (data.range_begin) {
    data.range_begin = Number(data.range_begin) - 1
  }
  if (data.range_end) {
    data.range_end = Number(data.range_end) - 1
  }
  return request(
    {
      url: `/terms_entries/${term_id}`,
      method: 'PUT',
      data,
    },
    null,
    false,
    false
  )
}

/**
 * Delete term entry
 * @param {Number} term_id
 */
export const deleteTerm = (term_id) => {
  return request({
    url: `/terms_entries/${term_id}`,
    method: 'DELETE',
    data: null,
  })
}

/**
 * Propose approval of payment batch
 * @param {Number} batchId id of batch to approve
 * @return {{batch_number: Number}} batch number
 */
export const proposePaymentBatch = (batchId, data) => {
  return request({
    url: `/ledger/payment_batches/${batchId}/process?propose=true`,
    method: 'PUT',
    data: data,
  })
}

/**
 * Commit approval of payment batch
 * @param {Number} batchId id of batch to approve
 * @param {Number} batch_number
 * @return {Void}
 */
export const commitPaymentBatch = (batchId, batch_number, date = null) => {
  return request({
    url: `/ledger/payment_batches/${batchId}/process`,
    method: 'PUT',
    data: { batch_number, date },
  })
}
/**
 * Update business debtor limit
 * @param {Number} business_id
 * @param {Number} debtor_id Not business_debtor id, just debtor id
 * @param {Object} data Can have
 * @returns {Object} the type  of object this returns is still in flux but should at least include, debtor_id and debtor_name
 */
export const updateBusinessDebtorLimit = (business_id, debtor_id, data) => {
  return request({
    url: `/businesses/${business_id}/debtors/${debtor_id}/limits`,
    method: 'PUT',
    data,
  })
}
/**
 * Delete a Check
 * @param {Number} check_id
 */
export const deleteCheck = (check_id) => {
  return request({
    url: `/operate/checks/${check_id}`,
    method: 'DELETE',
    data: null,
  })
}

/**
 * Delete Payment batch
 * @param {Number} batchId
 */
export const deletePaymentBatch = (batchId) => {
  return request({
    url: `/operate/payment_batches/${batchId}`,
    method: 'DELETE',
    data: null,
  })
}

/**
 * Creates debtor and business debtor relationship. This function should only be used for underwrite client (not operate)
 * @param {Any} data
 * @param {Number} business_id
 */
export const createUnderwriteDebtor = (data, business_id) => {
  return request({
    url: `/businesses/${business_id}/debtors`,
    method: 'POST',
    data,
  })
}

/**
 * Get's debtors available to an underwrite client. Not for use in operate
 * @param {Number} business_id
 */
export const getUnderwriteDebtors = (business_id) => {
  return request({
    url: `/businesses/${business_id}/debtors`,
    method: 'GET',
    data: null,
  })
}

/**
 * Deletes a business debtor from a business. Only for use in underwrite.
 * @param {*} business_id
 * @param {*} business_debtor_id The business debtor id, not the debtor id
 */
export const deleteUnderwriteDebtor = (business_id, business_debtor_id) => {
  return request({
    url: `/businesses/${business_id}/debtors/${business_debtor_id}`,
    method: 'DELETE',
    data: null,
  })
}
/**
 * Unprocesses an invoice schedule and sets it to a pending state
 * @param {Number} schedule_id
 * @param {Number} business_id
 */
export const unprocessSchedule = (schedule_id, business_id) => {
  return request({
    url: `/reset/invoice_schedules`,
    method: 'POST',
    data: {
      business_id: business_id,
      ids: schedule_id,
    },
  })
}

/**
 * Unprocesses funding request
 * @param {Number} fr_id Funding request ID
 * @param {Number} business_id
 */
export const unprocessFundingRequest = (fr_id) => {
  return request({
    url: `/reset/funding_requests`,
    method: 'POST',
    data: {
      ids: fr_id,
    },
  })
}

/**
 * Unprocesses payment batch
 * @param {Number} payment_batch_id Funding request ID
 * @param {Number} business_id
 */
export const unprocessPaymentBatch = (payment_batch_id) => {
  return request({
    url: `/reset/payment_batches`,
    method: 'POST',
    data: {
      ids: payment_batch_id,
    },
  })
}

/**
 * Unprocesses chargeback
 * @param {Number} invoiceChargebackId
 */
export const unprocessChargeback = (invoiceChargebackId) => {
  return request({
    url: `reset/chargeback`,
    method: 'POST',
    data: {
      ids: [invoiceChargebackId],
    },
  })
}

/**
 * Get payment batch client summaries
 * @param {Number} payment_batch_id Funding request ID
 * @param {String} searchParams
 */
export const getPaymentBatchClientSummaries = (payment_batch_id, searchParams) => {
  return request(
    {
      url: `/operate/payment_batches/${payment_batch_id}/client_summaries?${searchParams}`,
      method: 'GET',
    },
    null,
    true
  )
}

/**
 * Get payment batch client summaries
 * @param {String} searchParams
 */
export const getPaymentBatchesClientSummaries = (searchParams) => {
  return request(
    {
      url: `/operate/payment_batches/client_summaries?${searchParams}`,
      method: 'GET',
    },
    null,
    true
  )
}

/**
 * Get payment batch summary
 * @param {Number} payment_batch_id Funding request ID
 */
export const getPaymentBatchSummary = (payment_batch_id) => {
  return request({
    url: `/operate/payment_batches/${payment_batch_id}/summary`,
    method: 'GET',
  })
}

/**
 * Get payments from multiple batches summary
 * @param {String} searchParams
 */
export const getPaymentBatchesSummary = (searchParams) => {
  return request(
    {
      url: `/operate/payment_batches/summary?${searchParams}`,
      method: 'GET',
    },
    null,
    true
  )
}

// Accounts
/**
 * Get ledger entries for an account
 * @param {Number} businessId
 * @param {String} accountType
 * @param {String} searchParams
 */
export const getAccountTransactions = (businessId, accountType, searchParams = '') => {
  return request(
    {
      url: `/ledger/businesses/${businessId}/account/${accountType}/transactions?${searchParams}`,
      method: 'GET',
    },
    null,
    true
  ).then(transactionDatesToSystemTime)
}

/**
 * Get ledger entries for an account
 * @param {Number} businessId
 * @param {String} accountType
 * @param {String} searchParams
 */
export const headAccountTransactions = (businessId, accountType, searchParams = '') => {
  return request(
    {
      url: `/ledger/businesses/${businessId}/account/${accountType}/transactions?${searchParams}`,
      method: 'HEAD',
    },
    null,
    true
  )
}

/**
 * Get ledger entries for an account
 * @param {Number} businessId
 * @param {String} accountType
 */
export const getAccount = (businessId, accountType) => {
  return request({
    url: `/ledger/businesses/${businessId}/account/${accountType}`,
    method: 'GET',
  })
}

// Return Check

/**
 * Return Check.
 * @param {Number} paymentId
 * @param {String} type
 * @param {String} reason
 *
 */

export const returnCheck = (paymentId, type, reason) => {
  const utcCurrentDate = absoluteCurrentDateinUTC(new Date())
  const tenantDate = dateToUTCString(utcCurrentDate)

  return request({
    url: `/operate/checks/${paymentId}/check_return`,
    method: 'POST',
    data: { date: tenantDate, reason, type },
  })
}

/**
 * Get Operations
 */
export const getOperations = (searchParams = '', method = 'GET') => {
  return request(
    {
      url: `/ledger/operations?${searchParams}`,
      method,
    },
    null,
    true
  ).then((response) => {
    if (method == 'GET') {
      return operationDatesToSystemTime(response)
    } else {
      return response
    }
  })
}

/**
 * Get Activities
 */
export const getActivities = (business_id, searchParams = '', method = 'GET') => {
  return request(
    {
      url: `/businesses/${business_id}/activities?${searchParams}`,
      method,
    },
    null,
    true
  ).then((response) => {
    return response
  })
}

/**
 * Get total activities by context
 */
export const getTotalActivitiesByContext = (business_id, searchParams = '', method = 'GET') => {
  return request(
    {
      url: `/businesses/${business_id}/activities/total_by_context?${searchParams}`,
      method,
    },
    null,
    true
  ).then((response) => {
    return response
  })
}

export const getTenantAccountBalance = (account_type) => {
  return request({
    url: `/ledger/account/${account_type}/balance`,
    method: 'GET',
  })
}

/**
 * Get cash flow operations over previous 12 months and current month.
 *
 * @param {clientId} number
 * @returns {Promise<CashFlow[]>}
 * @throws {Error}
 */
export const getTwelveMonthClientCashFlow = (business_id) => {
  return request({
    url: `/ledger/summaries/cash_in_out/${business_id}`,
    method: 'GET',
  })
}

export const getOperableTypeTransactions = (businessId, params) => {
  return request({
    url: `/ledger/summaries/by_entity_type/${businessId}?${params.toString()}`,
    method: 'GET',
  })
}

export const getScheduleNotifiableDebtors = (scheduleId) => {
  return request({
    url: `/invoice_schedules/${scheduleId}/notifiable_debtors`,
    method: 'GET',
  })
}

export const getScheduleDebtors = (scheduleId) => {
  return request({
    url: `/invoice_schedules/${scheduleId}/debtors`,
    method: 'GET',
  })
}

export const notifyScheduleDebtors = (scheduleId, debtors) => {
  return request({
    url: `/emails/notify_schedule_debtors/${scheduleId}`,
    method: 'POST',
    data: { debtor_ids: debtors },
  })
}

export const getScheduleNotificationPreview = (scheduleId, debtorId) => {
  return request({
    url: `/emails/notify_schedule_debtors_preview/${scheduleId}/debtor/${debtorId}`,
    method: 'GET',
  })
}

export const getClientSummaryReport = (business_id, params) => {
  return request({
    url: `/ledger/business_summary/${business_id}?${params}`,
    method: 'GET',
  })
}

/**
 * Get Notification Groups
 */
export const getNotificationGroups = (params) => {
  return request(
    {
      url: `/notification_groups?${params}`,
      method: 'GET',
    },
    null,
    true
  )
}

/**
 * Delete a Notification Group
 */
export const deleteNotificationGroup = (id) => {
  return request(
    {
      url: `/notification_groups/${id}`,
      method: 'DELETE',
    },
    null,
    true
  )
}

/**
 * Update a Notification Group
 */
export const updateNotificationGroup = (id, payload) => {
  return request({
    url: `/notification_groups/${id}`,
    method: 'PUT',
    data: payload,
  })
}

/**
 * Create a Notification Group
 */
export const createNotificationGroup = (payload) => {
  return request({
    url: '/notification_groups',
    method: 'POST',
    data: payload,
  })
}

/**
 * Get Notification Types
 */
export const getNotificationTypes = () => {
  return request(
    {
      url: '/notification_types',
      method: 'GET',
    },
    null,
    true
  )
}
