import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { tableWidthsActions } from '../_actions'

export const tableWidthsHooks = {
  useGetColumnsWidths,
  useSetColumnsWidths,
}

function useGetColumnsWidths() {
  const dispatch = useDispatch()
  return useCallback(
    (columns, uniqueTableId) => dispatch(tableWidthsActions.getColumnsWidths(columns, uniqueTableId)),
    [dispatch]
  )
}

function useSetColumnsWidths() {
  const dispatch = useDispatch()
  return useCallback(
    (state, uniqueTableId) => dispatch(tableWidthsActions.setColumnsWidths(state, uniqueTableId)),
    [dispatch]
  )
}
