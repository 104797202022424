import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'

// Constants
import colors from '../../_constants/colors'
import { radioLabelStyle } from '../Forms/FormField/CustomFields/UploadDocField'

export const S_RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  min-width: 100%;

  .radio-btn {
    flex-grow: 1;
    flex-basis: 0;
    margin: 5px;
    label {
      ${radioLabelStyle}
    }

    input[type='radio'] {
      display: none;

      &:checked {
        & + label {
          border-color: ${colors.MATERIAL_GREEN};
        }
      }
    }
  }
`

const TenantFormRadioGroup = ({ field, formikName, handleChange, handleBlur, invalid }) => (
  <S_RadioGroup className={`radio-group ${invalid ? 'invalid-input' : ''}`}>
    {field.options.map((option, i) => (
      <Field
        key={i}
        name={formikName}
        render={({ field: formikField }) => {
          return (
            <div className={`radio-btn`}>
              <input
                checked={option.value === formikField.value}
                id={`${formikName}-${option.value}`}
                name={formikField.name}
                onChange={(e) => {
                  let fieldValue = e.target.value

                  if (fieldValue === `true`) {
                    fieldValue = true
                  } else if (fieldValue === `false`) {
                    fieldValue = false
                  }

                  handleChange(formikName, fieldValue)
                  handleBlur(formikName, field.name, fieldValue)
                }}
                type={`radio`}
                value={option.value}
              />
              <label htmlFor={`${formikName}-${option.value}`}>{option.label}</label>
            </div>
          )
        }}
        validate={(value) => {
          let errorMessage
          if ((field.required && value === '') || value === null || value === undefined) {
            errorMessage = `Field is required.`
          }
          return errorMessage
        }}
      />
    ))}
  </S_RadioGroup>
)

export default TenantFormRadioGroup
