import { cancelTokenConstants } from '../_constants'

export function cancelToken(state = {}, action) {
  switch (action.type) {
    case cancelTokenConstants.SET_CANCEL_TOKEN_SUCCESS:
      return {
        ...state,
        ...action.source,
      }

    default:
      return state
  }
}
