import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { darken, math } from 'polished'

// CONSTANTS
import colors from '../_constants/colors'

/**
 * Breakpoints
 */
export const cssBreakpoints = {
  sideNav_desktop: '750px',
  mobileSm: '320px',
  mobileMd: '480px',
  mobileLg: '768px',
  desktopSm: '1024px',
  desktopMd: '1300px',
  desktopLg: '1440px',
}

export const textStyles = {
  heading1: css`
    font-size: 32px;
    line-height: 36px;
    font-weight: bold;
  `,
  heading2: css`
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
  `,
  heading3: css`
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
  `,
  heading4: css`
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
  `,
  paragraph: css`
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  `,
}

const styling = css`
  display: inline-block;
  width: max-content;
  height: max-content;
  background-color: ${(props) =>
    props.activeTenantColors ? props.activeTenantColors.secondary : colors.DEFAULT_SECONDARY};
  font-weight: bold;
  border-radius: 100px;
  color: white;
  border: 2px solid
    ${(props) =>
      props.activeTenantColors ? darken(0.1, props.activeTenantColors.secondary) : colors.DEFAULT_SECONDARY_DARK};
  text-align: center;
  padding: 1em 5em;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.activeTenantColors ? darken(0.1, props.activeTenantColors.secondary) : colors.DEFAULT_SECONDARY_DARK};
  }
`

export const FinvoiceBtn = styled(({ activeTenantColors, ...props }) => <Link {...props} />)`
  ${styling}
`

export const FinvoiceButton = styled.button`
  ${styling}
`

/**
 * Standard Input Styling
 * - Used for <input>, <select>, <textarea>
 */
export const textInputStylesConfig = {
  lineHeight: '19px',
  fontSize: '16px',
  fontSizeDesktopLg: '20px',
  paddingV: '9px',
  paddingH: '12px',
  borderWidth: '1px',
  borderRadius: '3px',
}

textInputStylesConfig.minHeight = math(`
    ${textInputStylesConfig.lineHeight} + 
    2 * ${textInputStylesConfig.paddingV} + 
    2 * ${textInputStylesConfig.borderWidth} 
`)

export const textInputStyles = css`
  width: 100%;
  border-radius: ${textInputStylesConfig.borderRadius};
  border: ${textInputStylesConfig.borderWidth} solid
    ${(props) => (props.invalid ? colors.RED_ERROR : colors.OUTLINE_GREY_BOX)};
  padding: ${(props) => (props.paddingV ? props.paddingV : textInputStylesConfig.paddingV)}
    ${(props) => (props.paddingH ? props.paddingH : textInputStylesConfig.paddingH)};
  font-size: ${textInputStylesConfig.fontSize};
  line-height: ${textInputStylesConfig.lineHeight}; /* setting line height to force input height consistency */
  outline: none;
  transition: border 0.2s;
  color: ${colors.TEXT_DARK};

  &::placeholder {
    color: ${colors.OUTLINE_GREY_BOX};
  }

  &:disabled:not(.read-only-styles) {
    background: ${colors.GREY_LIGHT_FILLS};
    color: ${colors.SUBTITLE_GREY};
  }

  &.no-input-styles {
    background: transparent;
    border: none;
    color: ${colors.DEFAULT_TEXT};
  }

  &:hover:not(:disabled) {
    border-color: ${(props) => (props.invalid ? colors.RED_ERROR : colors.SUBTITLE_GREY)};
  }

  &:focus:not(:disabled),
  &.focused:not(:disabled) {
    border-color: ${(props) => (props.invalid ? colors.RED_ERROR : colors.TEXT_LIGHT)};
  }

  &.desktop-lg {
    @media screen and (min-width: ${cssBreakpoints.mobileLg}) {
      height: 60px;
      font-size: ${textInputStylesConfig.fontSizeDesktopLg};
    }
  }
`

export const selectInputStyles = css`
  width: auto; /* TODO: does this style get added to the constant definition */
  max-width: 100%; /* TODO: does this style get added to the constant definition */
  position: relative;
  &:before {
    position: absolute;
    z-index: 0;
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 1px solid ${(props) => props.theme?.colors?.secondary};
    border-bottom: 1px solid ${(props) => props.theme?.colors?.secondary};
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 12px;
    right: 12px;
    transition:
      transform 0.3s ease-in-out,
      border-color 0.3s ease-in-out,
      margin 0.3s ease-in-out;
  }

  select {
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    outline: none;
    display: block;
    font-size: 16px;
    color: ${colors.TEXT_DARK};
    padding: ${textInputStylesConfig.paddingV} ${math(`${textInputStylesConfig.paddingH} * 2`)}
      ${textInputStylesConfig.paddingV} ${textInputStylesConfig.paddingH};
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: ${textInputStylesConfig.borderWidth} solid
      ${(props) => (props.invalid ? colors.RED_ERROR : colors.OUTLINE_GREY_BOX)};
    border-radius: ${textInputStylesConfig.borderRadius};
    appearance: none;
    background-color: #fff;

    &:hover:not(:disabled) {
      border-color: ${(props) => (props.invalid ? colors.RED_ERROR : colors.SUBTITLE_GREY)};
    }

    &:focus:not(:disabled),
    &.focused:not(:disabled) {
      border-color: ${(props) => (props.invalid ? colors.RED_ERROR : colors.TEXT_LIGHT)};
    }

    &.fs14 {
      font-size: 14px;
    }
  }
`

/**
 * Constants defining common css spacing and styles
 */
export const cssConstants = {
  siteHeaderHeight: '62px',
  headerSubNavHeight: '62px',
  headerSubNavHeightMobile: '32px',
  sideNavMinWidth: '250px', // TODO: decide if we want min or max width
  sideNavMaxWidth: '250px',
  pageContentsPadTop: '25px', // Space between bottom of header and top of primary content
  contentColPadH_small: '10px',
  contentWidth: '1440px',
  contentWidthMd: '1170px',
  contentPadding: '12px',
}

/**
 * Table Styles
 * - based on react-table styles and Table.jsx, but with some variants
 * - eg. assumes border between cells in a row
 */
export const tableStylesConfig = {
  borderWidth: '1px',
  cellPaddingV: '10px',
  cellPaddingH: '10px',
  cellPaddingVMobile: '5px',
  cellPaddingHMobile: '5px',
  borderColor: '#e8e8e8', // Taken from react-table border
  headerColor: '#666',
  footerColor: '#666',
  fontSize: '14.4px', // Taken from react-table default of '.9em'
  mobileFontSize: '12px', // Taken from react-table default of '.9em'
  cellMinWidth: '100px',
  cellActionWidth: '50px',
}

tableStylesConfig.border = `${tableStylesConfig.borderWidth} solid ${tableStylesConfig.borderColor}`

// Table Cell
export const tableCellStyles = css`
  border-right: ${tableStylesConfig.border};
  display: flex;
  flex: 100 0 auto; /* Based on react-table */
  width: ${tableStylesConfig.cellMinWidth};
  padding: ${tableStylesConfig.cellPaddingV} ${tableStylesConfig.cellPaddingH};
  font-size: ${tableStylesConfig.fontSize};
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:last-child {
    border-right: none;
  }

  &.row-controls {
    width: ${tableStylesConfig.cellActionWidth};
    flex: 50 0 auto;
    border-right: none;
  }
`

// Table Row
export const tableRowStyles = css`
  display: flex;
  border-bottom: ${tableStylesConfig.border};

  &:last-child {
    border-bottom: none;
  }
`

// Table Header Cell (extends table cell)
export const tableHeaderCellStyles = css`
  ${tableCellStyles}
  overflow: hidden;
  border-right: none;
`

// Table Header (extends table row)
export const tableHeaderStyles = css`
  ${tableRowStyles}

  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${tableStylesConfig.headerColor};
`

// Table
export const tableStyles = css`
  border: ${tableStylesConfig.border};
  border-radius: 4px;
`

// Notes Styles

const notesMargin = `18.2px`
export const Notes = {
  headerStyles: css`
    color: ${colors.TITLE_GREY};
    font-size: 26px;
    font-weight: 400;
    margin-bottom: ${notesMargin};
  `,
  noteStyles: css`
    border-radius: 3px;
    border: ${colors.BORDER_GREY} solid 1px;
    color: ${colors.TEXT_DARK};
    padding: 10px;
  `,
  margin: `${notesMargin}`,
  height: `80px`,
}
