import { businessBankAccountConstants } from '../_constants'

const initialState = {
  name: '',
  address_street: '',
  address_city: '',
  address_state: '',
  address_postal: '',
  account_name: '',
  account_number: '',
  aba_routing_ach: '',
}

export function businessBankAccount(state = initialState, action) {
  switch (action.type) {
    case businessBankAccountConstants.GET_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
          business_id: action.business_id,
        },
      }
    case businessBankAccountConstants.GET_BANK_ACCOUNT_SUCCESS:
      return action.bankAccount
    case businessBankAccountConstants.GET_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        action: {
          error: action.error,
        },
      }
    case businessBankAccountConstants.UPDATE_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
          attr: action.attr,
          val: action.val,
          business_id: action.businessId,
        },
      }
    case businessBankAccountConstants.UPDATE_BANK_ACCOUNT_SUCCESS:
      return action.bankAccount
    case businessBankAccountConstants.UPDATE_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        action: {
          error: action.error,
        },
      }

    case businessBankAccountConstants.CREATE_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
          business_id: action.businessId,
        },
      }
    case businessBankAccountConstants.CREATE_BANK_ACCOUNT_SUCCESS:
      return action.bankAccount
    case businessBankAccountConstants.CREATE_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        action: {
          error: action.error,
        },
      }
    default:
      return state
  }
}
