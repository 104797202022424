import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

// Constants

// Helpers
import { isFeatureEnabled } from '../_helpers/flags'

export const UserSettingsRoutes = ({ loggedIn, user, featureFlags }) => {
  const isSettingsEnabled = isFeatureEnabled('user-notification-settings', featureFlags)
  // only if user is not admin
  if (loggedIn && !user.is_admin && isSettingsEnabled) {
    return []
  }

  const Settings = import('../_views/LenderViews/Settings/index')

  const settingsRoutes = [
    // Generic Lender Dashboard
    {
      path: '/settings',
      Component: lazy(() => Settings),
    },
  ]

  return settingsRoutes.map(({ path, Component, exact = false }) => (
    <Route component={Component} exact={exact} key={path} path={path} />
  ))
}
