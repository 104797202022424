import { locksConstants } from '../_constants'

export function locks(state = {}, action) {
  const record_type = action.record_type
  const record_id = action.record_id
  const s_copy = { ...state }
  switch (action.type) {
    case locksConstants.LOCK:
      if (!Array.isArray(s_copy[record_type])) {
        s_copy[record_type] = [record_id]
      } else if (!s_copy[record_type].includes(record_id)) {
        s_copy[record_type].push(record_id)
      }
      return {
        ...s_copy,
      }
    case locksConstants.UNLOCK:
      if (!Array.isArray(s_copy[record_type])) {
        return { ...s_copy }
      } else if (s_copy[record_type].includes(record_id)) {
        s_copy[record_type] = s_copy[record_type].filter((id) => id != record_id)
      }
      return {
        ...s_copy,
      }

    default:
      return state
  }
}
