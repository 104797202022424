import request from './axios_service'

export const businessBankAccountService = {
  getBusinessBankAccount,
  createBusinessBankAccount,
  updateBusinessBankAccount,
  getBusinessBankAccountPii,
}

function getBusinessBankAccount(businessId) {
  return request({
    url: `/businesses/${businessId}/bank_account`,
    method: 'GET',
  })
}

function getBusinessBankAccountPii(businessId) {
  return request({
    url: `/businesses/${businessId}/bank_account/get_pii`,
    method: 'GET',
  })
}

function createBusinessBankAccount(businessId) {
  return request({
    url: `/businesses/${businessId}/bank_account`,
    method: 'POST',
  })
}

function updateBusinessBankAccount(attr, val, businessId, data = false) {
  let newData = data || { [attr]: val }
  return request({
    url: `/businesses/${businessId}/bank_account`,
    method: 'PUT',
    data: newData,
  })
}
