import { notifications as xenNotifications } from 'xen-ui';
// 5 seconds
const defaultCloseTime = 5 * 1000;
const showSuccess = (message, options = {}) => {
    return xenNotifications.show({
        autoClose: defaultCloseTime,
        color: 'green',
        message,
        title: 'Success',
        ...options,
    });
};
const showError = (message, options = {}) => {
    return xenNotifications.show({
        autoClose: defaultCloseTime,
        color: 'red',
        message,
        title: 'Error',
        ...options,
    });
};
export const notifications = {
    error: showError,
    success: showSuccess,
};
