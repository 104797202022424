import { UTCDateToSystemString } from './dates'

export const debtorToSystemTime = (debtor) => {
  if (debtor.sent_date) {
    debtor.sent_date = UTCDateToSystemString(debtor.sent_date)
  }
  if (debtor.waived_date) {
    debtor.waived_date = UTCDateToSystemString(debtor.waived_date)
  }

  if (debtor.received_date) {
    debtor.waived_date = UTCDateToSystemString(debtor.waived_date)
  }

  if (debtor.stopped_date) {
    debtor.stopped_date = UTCDateToSystemString(debtor.stopped_date)
  }
  return debtor
}

export const debtorSummaryToSystemTime = (summary) => {
  if (summary.last_payment_date) {
    summary.last_payment_date = UTCDateToSystemString(summary.last_payment_date)
  }
  return summary
}

export const formatDebtor = (debtor) => {
  if (debtor.debtor_name) {
    debtor.debtor_name = debtor.debtor_name.trim()
  }
  return debtor
}
