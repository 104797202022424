import React, { useState, useEffect } from 'react'
import { IoCloseCircleSharp } from 'react-icons/io5'
import { FiSliders } from 'react-icons/fi'
import { IconContext } from 'react-icons'

import { Button } from '../../Button'
import { dateToUTCString, getDateFromDatetime } from '../../../_helpers/dates'
import { useModal } from '../../../_reduxHooks/modal.hook'
import { S_Filter } from './filter.styles'
import { H2 } from '../../Typography/Components/Headings/H2'
import { FilterField } from './FilterField'
import { formatCurrency } from '../../../_helpers'
import { useTheme } from 'styled-components'

export const formatFilter = (filter) => {
  let formatter = filter.column && filter.column.formatter
  if (filter.column.type != 'autoComplete') {
    filter.formattedValue = filter.formattedValue || filter.value
    if (filter.column.type === 'date' && formatter) {
      filter.formattedValue = formatter(filter.value)
      filter.value = dateToUTCString(filter.value.includes('T') ? filter.value : `${filter.value}T00:00:00Z`)
    } else if (filter.column.type === 'date' && !formatter) {
      filter.value = dateToUTCString(filter.value.includes('T') ? filter.value : `${filter.value}T00:00:00Z`)
      filter.formattedValue = getDateFromDatetime(filter.value)
    } else if (filter.column.type === 'currency' && !formatter) {
      filter.formattedValue = formatCurrency(filter.value)
    } else if (formatter) {
      filter.formattedValue = formatter(filter.value)
    }
  }
  return filter
}

export const Filter = ({ filterFields, currentFilters = [], onChange }) => {
  const [pendingFilters, setPendingFilters] = useState(currentFilters.map((filter) => formatFilter(filter)))
  const [showing, setShowing] = useState({})
  const { closeModal } = useModal()
  const { colors } = useTheme()

  useEffect(() => {
    let toShow = { ...showing }
    for (let filter of currentFilters) {
      if (typeof filter.value !== 'undefined' && filter.value !== null && filter.value !== '') {
        toShow[filter.column.fieldName] = true
      }
    }
    setShowing(toShow)
  }, [currentFilters])

  const handleChange = (newFilters) => {
    let formattedFilters = newFilters.map((newFilter) => {
      return formatFilter(newFilter)
    })
    onChange(formattedFilters)
    closeModal()
  }

  return (
    <S_Filter>
      <div className={`header-with-close`}>
        <div className={'header-left'}>
          <H2 alignItems={'center'} display={'flex'} fw={'400'}>
            <IconContext.Provider value={{ color: colors.secondary, size: '25px', className: 'sliders' }}>
              <FiSliders />
            </IconContext.Provider>
            Filter Results
          </H2>
        </div>
        <div className={'header-right'}>
          <Button
            className={`compact secondary`}
            onClick={() => {
              handleChange([])
            }}
          >
            Reset
          </Button>
          <IconContext.Provider value={{ color: colors.secondary, size: '35px', className: 'close-btn' }}>
            <IoCloseCircleSharp onClick={closeModal} />
          </IconContext.Provider>
        </div>
      </div>
      <div className="filter-container">
        {filterFields.map((field, i) => {
          const show = showing[field.fieldName]
          return (
            <FilterField
              field={field}
              key={i}
              onLabelClick={() => {
                setShowing({ ...showing, [field.fieldName]: !show })
              }}
              pendingFilters={pendingFilters}
              setPendingFilters={setPendingFilters}
              show={show}
            />
          )
        })}
      </div>
      <div className={'btn-container'}>
        <Button className={`set-filter-btn`} onClick={() => handleChange(pendingFilters)}>
          Set Filters
        </Button>
      </div>
    </S_Filter>
  )
}
