export const transformUnappliedCashResponse = (response) => {
    return response.map((unapplied_cash) => {
        return {
            id: unapplied_cash.id,
            amount: Number(unapplied_cash.amount),
            checkNumber: unapplied_cash.check_number,
            collectionCheckId: unapplied_cash.collection_check_id,
            datePosted: new Date(unapplied_cash.date_posted),
            debtorName: unapplied_cash.debtor_name,
            paymentBatch: {
                batchNumber: unapplied_cash.payment_batch.batch_number,
                id: unapplied_cash.payment_batch.id,
            },
            reversed: unapplied_cash.reversed,
            source: unapplied_cash.source,
        };
    });
};
