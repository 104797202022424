import { locksConstants } from '../_constants'

export const lockRecord = (record_type, record_id) => {
  return {
    type: locksConstants.LOCK,
    record_type,
    record_id,
  }
}

export const unlockRecord = (record_type, record_id) => {
  return {
    type: locksConstants.UNLOCK,
    record_type,
    record_id,
  }
}
