import PropTypes from 'prop-types'

export const viewPropTypes = {
  Component: PropTypes.func.isRequired,
  asideData: PropTypes.instanceOf(Proxy),
  endpointCall: PropTypes.func.isRequired,
  explainer: PropTypes.string.isRequired,
  pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  requestMethod: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export const BaseListProps = {
  fetchReload: PropTypes.func,
  searchParam: PropTypes.string,
}

export const listViewPropsTypes = {
  ...BaseListProps,
  initialTotal: PropTypes.number,
  searchFilters: PropTypes.array,
  searchTerm: PropTypes.string,
  viewConfig: PropTypes.shape(viewPropTypes),
}

export const listViewDefaultProps = {
  fetchReload: () => {},
  initialTotal: 0,
  searchFilters: [],
  searchTerm: '',
  viewConfig: {},
}
