import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

// Components
import { Button } from '../_components/Button'

// Constants
import ROUTES from '../_constants/routes'
import { useFeatureFlags } from 'xen/hooks'
import { Redirect } from 'xen/components'

const PageNotFoundComponent = styled.div`
  min-height: calc(100vh - 70px);
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 100px 50px;
  grid-gap: 20px;

  .text {
    opacity: 0.5;
  }

  .links {
    display: grid;
    justify-content: center;
    grid-gap: 10px;

    @media screen and (min-width: 600px) {
      grid-auto-flow: column;
      grid-auto-columns: max-content;
    }
  }
`

const signInWithRedirectPath = () => `${ROUTES.sign_in}?redirectTo=${window.location.pathname}`

const PageNotFound = ({ loggedIn }) => {
  const { tenantEnrollment } = useFeatureFlags()

  if (tenantEnrollment) return <Redirect to="/" />

  return (
    <PageNotFoundComponent className={`page-not-found`}>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <h1 className={`title`}>Page Not Found.</h1>
      <p className={`text`}>Sorry, this page could not be found.</p>
      <div className={`links`}>
        {loggedIn ? (
          // Links to sign in but will redirect to the appropriate page based on whether
          // they are a client or not
          <Button buttonType={`link`} text={`Home`} to={signInWithRedirectPath()} />
        ) : (
          [
            <Button
              buttonType={`link`}
              key={`PageNotFound SignInButton`}
              text={`Sign In`}
              to={signInWithRedirectPath()}
            />,
            <Button buttonType={`link`} key={`PageNotFound SignUpButton`} text={`Sign Up`} to={ROUTES.sign_up} />,
          ]
        )}
      </div>
    </PageNotFoundComponent>
  )
}

function mapStateToProps(state) {
  const { env, authentication } = state
  return {
    env,
    loggedIn: authentication.loggedIn,
  }
}

const connectedPageNotFound = connect(mapStateToProps)(PageNotFound)
export { connectedPageNotFound as PageNotFound }
