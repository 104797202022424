import config from 'config';
import { LOCAL_STORAGE_PREFIX } from 'xen/constants';
import { formatCase } from 'xen/helpers';
import { useFeaturesContext } from 'xen/providers';
const ENABLE_FEATURE_FLAG_OVERRIDES = config.enableFeatureFlagOverrides ?? false;
/**
 * Wrapper for Flipper feature provider.
 * Update the `FeatureKey` and `FlagMap` types to use new flags here.
 */
export const useFeatureFlags = () => {
    // Get flags from Flipper
    const flags = useFeaturesContext();
    const flagsWithOverrides = Object.entries(flags).reduce((acc, [flag, apiValue]) => {
        // By default, use API value, coerced to boolean (no support yet for string flags)
        let value = !!apiValue;
        // If local overrides are enabled
        if (ENABLE_FEATURE_FLAG_OVERRIDES) {
            // and flag exists in local storage
            const localValue = window.localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${flag}`);
            if (localValue !== null) {
                // override API value with local storage value.
                value = localValue === 'true';
            }
        }
        // Convert flag to camelCase
        const flagName = formatCase.camel(flag);
        // Add key-value pair to flags objet
        acc[flagName] = value;
        return acc;
    }, {});
    return flagsWithOverrides;
};
