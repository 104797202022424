import styled from 'styled-components'
import { cssBreakpoints } from '../../../_constants/styles'
import colors from '../../../_constants/colors'

export const S_FilterIndicator = styled.div`
  background: ${colors.DEFAULT_SECONDARY};
  color: #fff;
  font-size: 14px;
  padding: 2.5px 2px 2.5px 10px;
  border-radius: 20px;
  font-weight: 400;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    padding: 5px;
  }

  .x-btn {
    display: block;
    color: #fff;
    margin-left: 4px;
    cursor: pointer;
    &:hover {
      color: ${colors.GREY_LIGHT_FILLS};
    }
  }
`
