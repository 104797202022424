import { UTCDateToSystemString } from './dates'
import { userDatesToSystemTime } from './user-utils'

export const notesDatesToSystemTime = (note) => {
  if (note.created_at) {
    note.created_at = UTCDateToSystemString(note.created_at)
  }
  if (note.updated_at) {
    note.updated_at = UTCDateToSystemString(note.updated_at)
  }
  if (note.pinned_on) {
    note.pinned_on = UTCDateToSystemString(note.pinned_on)
  }

  if (note.created_by) {
    note.created_by = userDatesToSystemTime(note.created_by)
  }

  if (note.body) {
    note.note = note.body
  }

  return note
}
