import { PlaidDisconnect } from './PlaidDisconnect'
import { PlaidLink } from './PlaidLink'
import React from 'react'
import { useBusiness } from '../../../_reduxHooks/business.hook'

export const PlaidConnector = ({ isConnected, successCb, disconnectCb }) => {
  const business = useBusiness().business
  if (isConnected) {
    return <PlaidDisconnect disconnectCb={disconnectCb} entityId={business.id} entityType="business" />
  } else {
    return <PlaidLink isConnected={isConnected} successCb={successCb} />
  }
}
