import React from 'react'
import { useSelector } from 'react-redux'
import styles from './MarketingColumn.module.css'

export const MarketingColumn = () => {
  const { marketingColumn } = useSelector((state) => ({
    marketingColumn: state.authentication?.marketing,
  }))

  const renderMarketingComponents = (component) => {
    switch (component.type) {
      case 'image':
        return (
          <img
            alt={component.alt ?? 'Un-named Image'}
            className={
              `${styles[`img-${component.size}`]}` +
              `${component.margin_top ? styles[` margin-top-${component.margin_top}`] : ''}`
            }
            src={component.content}
          />
        )
      case 'html':
        return (
          <div
            className={`${`${component.margin_top ? styles[`margin-top-${component.margin_top}`] : ''}`}`}
            dangerouslySetInnerHTML={{ __html: component.content }}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className={styles['marketing-section']}>
      {marketingColumn?.map((component) => (
        <div key={`${component.type}-${component.id}`}>{renderMarketingComponents(component)}</div>
      ))}
    </div>
  )
}
