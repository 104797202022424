import { subscriptionConstants } from '../_constants'

export function subscription(state = {}, action) {
  switch (action.type) {
    case subscriptionConstants.WEBSOCKET_STATE:
      return {
        ...state,
        websocket_state: action.websocket_state,
        websocket: action.websocket,
        connection_token: action.connection_token,
      }

    default:
      return state
  }
}
