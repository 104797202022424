import { subscriptionConstants } from '../_constants'

export const setWebsocketState = (state, websocket, connection_token) => {
  return {
    type: subscriptionConstants.WEBSOCKET_STATE,
    websocket_state: state,
    websocket,
    connection_token,
  }
}
