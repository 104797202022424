export const initialMainGLAccountsConfig = {
  entity: {
    account_name: '',
    account_number: '',
    account_type: '',
  },
  fields: [
    {
      type: 'text',
      id: 'account_name',
      required: false,
      layout: '',
      label: 'Account Name',
    },
    {
      type: 'text',
      id: 'account_number',
      required: false,
      layout: '',
      label: `Account Number`,
    },
  ],
}

export const createGLAccountFormConfig = {
  entity: {
    account_name: '',
    account_number: '',
    parent_account_id: '',
  },
  fields: [
    {
      type: 'text',
      id: 'account_name',
      required: false,
      layout: '',
      label: 'Account Name',
    },
    {
      type: 'text',
      id: 'account_number',
      required: false,
      layout: '',
      label: `Account Number`,
    },
  ],
}
