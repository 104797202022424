import React from 'react'
import colors from '../../../../_constants/colors'
import { S_Text } from './Text.styles'

const variants = {
  p: 'p',
  span: 'span',
  label: 'label',
  div: 'div',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
}

export const Text = ({ variant, children, className, ...props }) => {
  const tag = variants[variant] || 'p'
  return (
    <S_Text className={`${className} typography`} {...props} as={tag}>
      {children}
    </S_Text>
  )
}

Text.defaultProps = {
  className: '',
  fs: '14px',
  fw: '400',
  textColor: colors.TEXT_DARK,
  mt: '0px',
  mb: '0px',
  ml: '0px',
  mr: '0px',
  pt: '0px',
  pb: '0px',
  pl: '0px',
  pr: '0px',
  display: 'block',
  alignItems: false,
  variant: 'p',
  align: 'left',
  wrap: 'normal',
  overflow: undefined,
}
