import { formConstants } from '../_constants'

const initialState = {
  activeSection: 1,
  submitted: false,
  completed: [],
}

export function form(state = initialState, action) {
  switch (action.type) {
    case formConstants.NEXT_SECTION:
      return {
        ...state,
        activeSection: (state.activeSection += 1),
      }
    case formConstants.PREV_SECTION:
      return {
        ...state,
        activeSection: (state.activeSection -= 1),
      }
    case formConstants.SUBMIT_FORM:
      return {
        activeSection: 1,
        submitted: true,
      }
    case formConstants.GO_TO_SECTION:
      return {
        ...state,
        activeSection: action.nextSection,
      }
    case formConstants.COMPLETED_SECTIONS:
      return {
        ...state,
        completed: action.completedSections,
      }
    default:
      return state
  }
}
