export const BBCTypes = {
  ACCOUNTS_RECEIVABLE: { display: 'Accounts Receivable', value: 'accounts_receivable' },
  EQUIPMENT: { display: 'Equipment', value: 'equipment' },
  EXTERNAL: { display: 'External', value: 'external' },
  INVENTORY: { display: 'Inventory', value: 'inventory' },
  PARTICIPANT: { display: 'Participant', value: 'participate' },
}

export const BBCTypesMap = {
  accounts_receivable: BBCTypes.ACCOUNTS_RECEIVABLE,
  equipment: BBCTypes.EQUIPMENT,
  external: BBCTypes.EXTERNAL,
  inventory: BBCTypes.INVENTORY,
  participate: BBCTypes.PARTICIPANT,
}

export const ParticipateAgainst = {
  client_balance: { display: 'Client Balance', value: 'client_balance' },
  gross: { display: 'Gross Collateral', value: 'gross' },
  eligible: { display: 'Eligible Collateral', value: 'eligible' },
  supporting: { display: 'Supporting Collateral', value: 'supporting' },
}
