import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import ROUTES from '../_constants/routes'

const BankLoadingTrackback = ({ component: isClient, ...rest }) => {
  if (window.opener) {
    window.close()
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isClient ? (
          <Redirect to={{ pathname: ROUTES.client_home, state: { from: props.location } }} />
        ) : (
          <Redirect to={{ pathname: ROUTES.upload_invoices, state: { from: props.location } }} />
        )
      }
    />
  )
}

function mapStateToProps(state) {
  const { business } = state
  return {
    isClient: business.is_client,
  }
}

const connectedBankLoadingTrackback = connect(mapStateToProps)(BankLoadingTrackback)
export { connectedBankLoadingTrackback as BankLoadingTrackback }
