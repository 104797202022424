import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Tooltip } from 'xen-ui';
import { useAppShellNavBarContext } from 'xen/providers';
import { NavItem } from '../../link';
export const NavBarButton = ({ ...props }) => {
    const { isMobile } = useAppShellNavBarContext();
    const ButtonComponent = isMobile ? MobileNavBarButton : DesktopNavBarButton;
    return _jsx(ButtonComponent, { ...props });
};
// Private --------------------------------------------------------------------
const DesktopNavBarButton = ({ ...props }) => {
    const { isNavCollapsed, navWidth, toggleNavWidth } = useAppShellNavBarContext();
    // When desktop nav is collapsed, only show icon that re-opens nav on click
    if (isNavCollapsed) {
        return (_jsx(Tooltip, { label: props.label, position: "right", children: _jsx(NavItem.Button, { onClick: toggleNavWidth, ...props, 
                // eslint-disable-next-line react/no-children-prop
                children: undefined, label: "", w: navWidth }) }));
    }
    return _jsx(NavItem.Button, { ...props });
};
const MobileNavBarButton = ({ ...props }) => {
    const { isNavHidden } = useAppShellNavBarContext();
    const [isExpanded, setIsExpanded] = useState(() => false);
    const toggleOpen = () => setIsExpanded((isExpanded) => !isExpanded);
    // On mobile, always default buttons to collapsed unless they are manually expanded
    useEffect(() => {
        isNavHidden && setIsExpanded(false);
    }, [isNavHidden]);
    return (_jsx(NavItem.Button, { onClick: toggleOpen, ...props, defaultOpened: false, opened: isExpanded }));
};
