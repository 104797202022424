import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { FaFileAlt, FaDonate, FaFileInvoiceDollar } from 'react-icons/fa'
import { MdGroup, MdLocalAtm, MdDashboard, MdBook, MdList, MdFlag } from 'react-icons/md'

// Styled Components
import { S_SideNav } from './S_SideNav'

// Actions
import { adminActions } from '../../../_actions/admin.actions'

// Utils

// Services
import { getTenants } from '../../../_services/fvadmin_service'

const iconMap = {
  dashboard: <MdDashboard />,
  book: <MdBook />,
  new_collateral: <FaFileAlt />,
  file: <FaFileAlt />,
  group: <MdGroup />,
  funding: <MdLocalAtm />,
  list: <MdList />,
  money: <FaDonate />,
  invoice: <FaFileInvoiceDollar />,
  flag: <MdFlag />,
}

const getIcon = (iconType = 'dashboard') => {
  return iconMap[iconType]
}

const SecondaryLinks = ({ links }) => {
  if (!links || links.length === 0) {
    return null
  }
  return (
    <div className="secondary-links">
      {links.map(({ label, route, exact = false }, i) => (
        <NavLink activeClassName="active" className="link nav-link secondary-link" exact={exact} key={i} to={route}>
          {label}
        </NavLink>
      ))}
    </div>
  )
}

const SideNav = ({ isOpen, setAdminTenant, admin_tenant, setTenantSetter }) => {
  const href = window.location.href
  const [tenants, setTenants] = useState([])
  const [selectedTenantId, setSelectedTenantId] = useState(1)

  const overallLinks = [
    {
      route: '/fv_admin_panel',
      label: 'Dashboard',
      icon: 'dashboard',
      secondaryLinks: [],
    },
    {
      route: '/default_feature_flags',
      label: 'Feature Defaults',
      icon: 'flag',
      secondaryLinks: [],
    },
    {
      route: '/default_product_flags',
      label: 'Product Defaults',
      icon: 'flag',
      secondaryLinks: [],
    },
    {
      route: '/build',
      label: 'Build Info',
      icon: 'book',
      secondaryLinks: [],
    },
  ]
  const navLinks = [
    {
      route: '/tenant_details',
      label: 'Details',
      icon: 'dashboard',
      secondaryLinks: [],
    },
    {
      route: '/tenant_users',
      label: 'Users',
      icon: 'group',
      secondaryLinks: [],
    },
    {
      route: '/email_field_config',
      label: 'Form Config (Email)',
      icon: 'list',
      secondaryLinks: [],
    },
    {
      route: '/feature_flags',
      label: 'Features',
      icon: 'flag',
      secondaryLinks: [],
    },
    {
      route: '/product_flags',
      label: 'Products',
      icon: 'flag',
      secondaryLinks: [],
    },
    {
      route: '/tenant_config',
      label: 'Config',
      icon: 'flag',
      secondaryLinks: [],
    },
  ]
  useEffect(() => {
    getTenants().then((t) => setTenants(t))
    setAdminTenant(admin_tenant || 1)
    setTenantSetter(setTenants)
  }, [])
  return (
    <S_SideNav className={isOpen ? 'active' : ''}>
      {/* Nav */}
      <nav>
        {overallLinks.map(({ route, label, icon, secondaryLinks }, i) => {
          return (
            <div className={`nav-item ${href.indexOf(route) !== -1 ? 'active' : ''}`} key={i}>
              <NavLink activeClassName="active-nav-link" className="link nav-link" to={route}>
                {icon && getIcon(icon)}
                {label}
              </NavLink>
              <SecondaryLinks links={secondaryLinks} />
            </div>
          )
        })}
        {tenants && (
          <select
            onChange={(e) => {
              setAdminTenant(e.target.value)
            }}
            value={admin_tenant}
          >
            {tenants.map((t) => {
              return (
                <option
                  onClick={() => {
                    setSelectedTenantId(t.id)
                  }}
                  value={t.id}
                >
                  {t.token}
                </option>
              )
            })}
          </select>
        )}
        {navLinks.map(({ route, label, icon, secondaryLinks }, i) => {
          return (
            <div className={`nav-item ${href.indexOf(route) !== -1 ? 'active' : ''}`} key={i}>
              <NavLink activeClassName="active-nav-link" className="link nav-link" to={route}>
                {icon && getIcon(icon)}
                {label}
              </NavLink>
              <SecondaryLinks links={secondaryLinks} />
            </div>
          )
        })}
      </nav>
    </S_SideNav>
  )
}

function mapStateToProps(state) {
  const { admin } = state
  const { isOpen } = state.sideNav
  return { isOpen, admin_tenant: Number(admin.admin_tenant) }
}

const connectedComponent = connect(mapStateToProps, (dispatch) => ({
  setAdminTenant: (tenantId) => dispatch(adminActions.setAdminTenant(tenantId)),
}))(SideNav)
export { connectedComponent as SideNav }
