import { additionalBusinessDataConstants } from '../_constants'
import { additionalBusinessDataService } from '../_services'

export const additionalBusinessDataActions = {
  getAdditionalBusinessData,
  updateAdditionalBusinessData,
}

function getAdditionalBusinessData(businessId) {
  return (dispatch) => {
    dispatch(request())
    return additionalBusinessDataService
      .getAdditionalBusinessData(businessId)
      .then((additionalData) => dispatch(success(additionalData)))
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: additionalBusinessDataConstants.GET_ADDITIONAL_BUSINESS_DATA_REQUEST }
  }
  function success(additionalData) {
    return {
      type: additionalBusinessDataConstants.GET_ADDITIONAL_BUSINESS_DATA_SUCCESS,
      additionalData,
    }
  }
  function failure(error) {
    return { type: additionalBusinessDataConstants.GET_ADDITIONAL_BUSINESS_DATA_FAILURE, error }
  }
}

function updateAdditionalBusinessData(attr, val, businessId) {
  return (dispatch) => {
    dispatch(request(attr, val))
    return additionalBusinessDataService
      .updateAdditionalBusinessData(attr, val, businessId)
      .then((additionalData) => dispatch(success(additionalData)))
      .catch((error) => dispatch(failure(error)))
  }

  function request(attr, val) {
    return {
      type: additionalBusinessDataConstants.UPDATE_ADDITIONAL_BUSINESS_DATA_REQUEST,
      attr,
      val,
    }
  }
  function success(additionalData) {
    return {
      type: additionalBusinessDataConstants.UPDATE_ADDITIONAL_BUSINESS_DATA_SUCCESS,
      additionalData,
    }
  }
  function failure(error) {
    return {
      type: additionalBusinessDataConstants.UPDATE_ADDITIONAL_BUSINESS_DATA_FAILURE,
      error,
    }
  }
}
