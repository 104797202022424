import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import apiKeys from 'apiKeys'
import config from 'config'
import env from 'env'

import { persistor, startMonitoring, store } from 'legacy/_helpers'
import {
  AppShellProvider,
  ConfigurationProvider,
  FeaturesProvider,
  GlobalDataProvider,
  QueryProvider,
  ThemeProvider,
} from 'xen/providers'
import { Notifications } from 'xen-ui'

import { App } from 'legacy/App'
import { ErrorBoundary, LiveChat } from 'xen/components'

const DATADOG_VERSION = config.datadogVersion ?? '1.0.0'
const DATADOG_APP_ID = apiKeys.datadog
const environment = env.name ?? 'development'
const container = document.getElementById('app')
const root = createRoot(container)

;(async () => {
  if (DATADOG_APP_ID) {
    startMonitoring({
      applicationId: DATADOG_APP_ID,
      env: environment,
      version: DATADOG_VERSION,
    })
  }

  root.render(
    <ErrorBoundary>
      <QueryProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <FeaturesProvider>
              <ConfigurationProvider>
                <GlobalDataProvider>
                  <ThemeProvider>
                    <AppShellProvider>
                      <LiveChat />
                      <Notifications />
                      <App />
                    </AppShellProvider>
                  </ThemeProvider>
                </GlobalDataProvider>
              </ConfigurationProvider>
            </FeaturesProvider>
          </PersistGate>
        </Provider>
      </QueryProvider>
    </ErrorBoundary>
  )
})()
