const PAYMENT_ERRORS_MAP = {
  'Number of payments must be greater than 0': 'Please click Add Payment to create a payment',
}

export const paymentBatchErrors = (paymentBatch) => {
  const { errors } = paymentBatch

  if (Object.keys(errors).length > 0) {
    for (const key in errors) {
      const errorValue = errors[key]
      const isTypeOfString = typeof errorValue === 'string'
      let isErrorKeyValid = null

      if (isTypeOfString) {
        isErrorKeyValid = PAYMENT_ERRORS_MAP[errorValue]
      } else {
        /** TODO:
         * Verifies the backend service when implemented.
         */
        isErrorKeyValid = PAYMENT_ERRORS_MAP[errorValue.collections_checks]
      }

      if (isErrorKeyValid) {
        errors[key] = isTypeOfString ? isErrorKeyValid : { collections_checks: isErrorKeyValid }
      }
    }
  }
  paymentBatch.errors = errors
  return paymentBatch
}
