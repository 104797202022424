// Transform function so that each service call doesn't have to map from ui params to api ones.
// Handles GET and POST response types.
export const createSearchParams = ({
  searchTerm = '',
  pageSize = 10,
  pageNum = 1,
  sort = null,
  requestMethod = 'GET',
  searchParam,
  searchFilters = [],
}) => {
  const sortParams =
    sort && sort.length && sort[0].id
      ? {
          // Need to remove the nesting of column names for sorting server-side. For example busines.business_name should become business_name
          sort_by: sort[0].id.includes('.') ? sort[0].id.split('.').pop() : sort[0].id,
          sort_asc: !sort[0].desc,
        }
      : {}

  // This one is used for POST requests
  const filterParams = searchFilters.reduce((acum, filterParam) => {
    const column = filterParam.column
    // Specific rules for custom filters with specific behavior
    // This one sets certain rules if true
    if (column.type === 'customTruthy') {
      column.customTruthy.forEach((param) => {
        if (acum[param.paramName]) {
          acum[param.paramName] = acum[param.paramName] + ',' + param.paramValue
        } else {
          acum[param.paramName] = param.paramValue
        }
      })
      return acum
    }

    // Regular fields
    return { ...acum, [column.fieldName]: filterParam.value }
  }, {})

  if (requestMethod === 'GET') {
    let getParams = new URLSearchParams()
    pageSize && getParams.set('count', pageSize)
    pageNum && getParams.set('start_index', pageSize * (pageNum - 1))
    if (sort && sort.length) {
      getParams.set('sort_by', sortParams.sort_by)
      getParams.set('sort_asc', sortParams.sort_asc)
    }

    if (searchFilters.length) {
      searchFilters.forEach((filter) => {
        const column = filter.column
        let finalValue = filter.value

        // Custom filters
        if (column.type === 'customTruthy') {
          column.customTruthy.forEach((param) => {
            getParams.append(param.paramName, param.paramValue)
          })
        } else {
          // Regular filters
          if (typeof finalValue === 'string' && finalValue.includes(',') && column.type === 'select') {
            finalValue.split(',').forEach((valuePart) => {
              if (!valuePart) return
              getParams.append(`${filter.column.fieldName}[]`, valuePart)
            })
          } else {
            getParams.set(filter.column.fieldName, finalValue)
          }
        }
      })
      if (searchTerm) {
        getParams.set(searchParam, searchTerm)
      }
    } else if (searchTerm) {
      getParams.set(searchParam, searchTerm)
    }
    return getParams
  }

  return {
    search_term: searchTerm,
    ...sortParams,
    ...filterParams,
    pagination: {
      per_page: pageSize,
      page: pageNum,
    },
  }
}
