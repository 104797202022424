import { UTCDateToSystemString } from './dates'

/**
 * @typedef {Object} data
 **/

/**
 * Convert fields for given
 * @param {object} data
 * @param {array} fields
 * @return {data}
 */
export const convertSystemTimeToDate = (data, fields) => {
  fields.map((field) => {
    if (data[field]) {
      if (data[field].includes('T')) {
        data[field] = UTCDateToSystemString(data[field]).split('T')[0]
      }
    }
  })

  return data
}
