import React from 'react'
import { renderToString } from 'react-dom/server'
import { Provider } from 'react-redux'
import { store } from './store'

export const printComponent = (Component, props = {}) => {
  const htmlString = renderToString(
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  )
  printHTMLString(htmlString)
}

export const printChildren = (children, props = {}) => {
  const htmlString = renderToString(<Provider store={store}>{React.cloneElement(children, props)}</Provider>)
  printHTMLString(htmlString)
}

export const printElement = (domEl, title = '') => {
  const htmlString = domEl.outerHTML
  printHTMLString(htmlString, title)
}

export const printHTMLString = (htmlString, title = '') => {
  let tags = ['meta', 'base', 'link']
  let tagsString = ''

  tagsString += `<title>${title}</title>`

  tags.forEach((tag) => {
    let elementsToCopy = document.getElementsByTagName(tag)
    let elementsString = ''
    for (let i = 0; i < elementsToCopy.length; i++) {
      elementsString += elementsToCopy[i].outerHTML
    }
    tagsString += elementsString
  })

  var printPreview = window.open('', 'print_preview')
  var printDocument = printPreview.document
  printDocument.open()
  setTimeout(() => copyStyles(document, printDocument), 0)
  printDocument.write('<!DOCTYPE html><html><head>' + tagsString + '</head><body>' + htmlString + '</body></html>')

  printPreview.onload = (event) => {
    // Using time out because onload event is not completely reliable
    setTimeout(() => {
      printPreview.print()
    }, 500)
  }

  printDocument.close()
}

function copyStyles(source, target) {
  const headFrag = target.createDocumentFragment()

  Array.from(source.styleSheets).forEach((styleSheet) => {
    let rules
    try {
      rules = styleSheet.cssRules
    } catch (err) {
      console.error(err)
    }

    if (rules) {
      const ruleText = []
      Array.from(styleSheet.cssRules).forEach((cssRule) => {
        const { type } = cssRule
        if (type === CSSRule.UNKNOWN_RULE) {
          return
        }

        let returnText = ''

        returnText = cssRule.cssText
        ruleText.push(returnText)
      })

      const newStyleEl = target.createElement('style')
      newStyleEl.textContent = ruleText.join('\n')
      headFrag.appendChild(newStyleEl)
    } else if (styleSheet.href) {
      const newLinkEl = target.createElement('link')

      newLinkEl.rel = 'stylesheet'
      newLinkEl.href = styleSheet.href
      headFrag.appendChild(newLinkEl)
    }
  })

  target.head.appendChild(headFrag)
}
