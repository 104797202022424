import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Group, Heading, Loader } from 'xen-ui';
import { useIsInvoiceScheduleCreating } from 'xen/api/use-invoice-schedule';
import { Page, PageTitle } from 'xen/components';
import { InvoiceScheduleCreateForm } from '../components';
const InvoiceScheduleNew = () => {
    const isCreating = useIsInvoiceScheduleCreating();
    return (_jsxs(Page, { children: [_jsxs(Group, { align: "center", children: [_jsx(PageTitle, { title: "New Invoice Schedule" }), isCreating && _jsx(Loader, { size: "sm" })] }), _jsx(Heading, { level: 2, children: "Overview" }), _jsx(InvoiceScheduleCreateForm, {})] }));
};
export default InvoiceScheduleNew;
