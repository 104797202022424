import React from 'react'
import { S_FilterIndicators } from './FilterIndicators.styles'
import { FilterIndicator } from './FilterIndicator'

export const FilterIndicators = ({ searchFilters, removeFilter }) => {
  return (
    <S_FilterIndicators>
      {searchFilters.map((filter, i) => (
        <FilterIndicator filter={filter} key={i} removeFilter={removeFilter} />
      ))}
    </S_FilterIndicators>
  )
}

FilterIndicators.defaultProps = {
  searchFilters: [],
  removeFilter: () => {},
}
