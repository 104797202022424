import { borrowingBaseCertificateConstants } from '../_constants'
import { borrowingBaseCertificateService } from '../_services/borrowingBaseCertificate.service'

export const borrowingBaseCertificateActions = {
  updateFormData,
  cleanFormData,
  uploadDocument,
  setId,
  cleanUploadedDocument,
}

function updateFormData(data) {
  return (dispatch) => {
    dispatch({ type: borrowingBaseCertificateConstants.UPDATE_FORM_DATA, data })
  }
}

function cleanFormData() {
  return { type: borrowingBaseCertificateConstants.CLEAN_FORM_DATA }
}

function cleanUploadedDocument() {
  return { type: borrowingBaseCertificateConstants.CLEAN_UPLOADED_DOCUMENT }
}

function uploadDocument(file, name, bbcId) {
  return (dispatch) => {
    dispatch(request(file, name))
    return borrowingBaseCertificateService
      .uploadDocument(file, name, bbcId)
      .then((_) => dispatch(success(file, name)))
      .catch((error) => dispatch(failure(error, name)))
  }

  function request(file, name) {
    return { type: borrowingBaseCertificateConstants.UPLOAD_DOCUMENT_REQUEST, file, name }
  }
  function success(file, name) {
    return { type: borrowingBaseCertificateConstants.UPLOAD_DOCUMENT_SUCCESS, file, name }
  }
  function failure(error, name) {
    return { type: borrowingBaseCertificateConstants.UPLOAD_DOCUMENT_FAILURE, error, name }
  }
}

function setId(id) {
  return { type: borrowingBaseCertificateConstants.SET_ID, id }
}
