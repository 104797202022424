import { modalConstants } from '../_constants'

export function modal(state = {}, action) {
  switch (action.type) {
    case modalConstants.OPEN_MODAL:
      return {
        is_open: true,
        content: action.content,
        props: action.props,
        modalProps: action.modalProps,
      }
    case modalConstants.CLOSE_MODAL:
      return {
        is_open: false,
      }
    case modalConstants.SWITCH_MODAL:
      return {
        is_open: true,
        content: action.content,
        props: action.props,
      }
    case modalConstants.SET_PREV_MODAL:
      return {
        ...state,
        prev_modal: action.content,
        prev_modal_props: action.props,
      }
    default:
      return state
  }
}
