export const transformTenantResponse = (response) => {
    const data = {
        addressCity: response.address_city,
        addressCountry: response.address_country,
        addressState: response.address_state,
        addressStreet: response.address_street,
        addressUnit: response.address_unit,
        addressZipCode: response.address_zip_code,
        agingType: response.aging_type,
        aliasOfId: response.alias_of_id,
        appEndpoint: response.app_endpoint,
        attachmentsEmailAddress: response.attachments_email_address,
        bccUserEmail: !!response.bcc_user_email,
        contactEmailAddress: response.contact_email_address,
        contactFirstName: response.contact_first_name,
        contactLastName: response.contact_last_name,
        contactPhone: response.contact_phone,
        contractLengthMonths: response.contract_length_months,
        contractRenewDate: response.contract_renew_date ? new Date(response.contract_renew_date) : null,
        contractSignDate: response.contract_sign_date ? new Date(response.contract_sign_date) : null,
        createdAt: new Date(response.created_at),
        disabled: !!response.disabled,
        fromEmailAddress: response.from_email_address,
        fundingRequestNotificationEmail: response.funding_request_notification_email,
        hellosignEnabled: !!response.hellosign_enabled,
        hideEmailLogo: !!response.hide_email_logo,
        id: response.id,
        newLeadsEmailAddress: response.new_leads_email_address,
        notificationsEmailAddress: response.notifications_email_address,
        numLicenses: response.num_licenses,
        operateEnabled: !!response.operate_enabled,
        operationsAttachmentsEmailAddress: response.operations_attachments_email_address,
        operationsNotificationsEmailAddress: response.operations_notifications_email_address,
        organizationName: response.organization_name,
        organizationType: response.organization_type,
        organizationWebsite: response.organization_website,
        originationType: response.origination_type,
        preheaderEmailColor: response.preheader_email_color,
        qbOauthVersion: response.qb_oauth_version,
        referenceNum: response.reference_num ? Number(response.reference_num) : null,
        returnUserAttachmentsEmailAddress: response.return_user_attachments_email_address,
        returnUserNotificationsEmailAddress: response.return_user_notifications_email_address,
        showCopyright: !!response.show_copyright,
        showTrackingData: !!response.show_tracking_data,
        tenantTimezone: response.tenant_timezone,
        tenantType: response.tenant_type,
        token: response.token,
        updatedAt: new Date(response.updated_at),
    };
    if ('schedule_batch_start' in response)
        data.scheduleBatchStart = Number(response.schedule_batch_start);
    return data;
};
