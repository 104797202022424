import React from 'react'
import { Text } from '../Text/Text'

export const H4 = ({ children, fs, ...props }) => {
  return (
    <Text fs={fs} variant={'h4'} {...props}>
      {children}
    </Text>
  )
}

H4.defaultProps = {
  fs: '16px',
  fw: '700',
}
