import request from './axios_service'
/**
 * @typedef {Object} DefaultValue
 * @property {Number} id
 * @property {String} entity
 * @property {String} field_name
 * @property {String} field_type
 * @property {Number} tenant_id
 * @property {String} value
 */

/**
 * Get list of tenants
 */
export const getTenants = () => {
  return request({
    url: `/tenants`,
    method: 'GET',
  })
}

/**
 * Get tenant by id
 * @param {Number} id
 */
export const getTenant = (id) => {
  return request({
    url: `/tenants/${id}`,
    method: 'GET',
  })
}

/**
 * Update tenant
 * @param {Number} id
 * @param {Object} data
 */
export const updateTenant = (id, data) => {
  return request({
    url: `/tenants/${id}`,
    method: 'PUT',
    data,
  })
}

/**
 * Create tenant
 * @param {Object} data
 */
export const createTenant = (data) => {
  return request({
    url: `/tenants`,
    method: 'POST',
    data,
  })
}

/**
 * Get a tenant's users
 * @param {Number} tenantId
 * @param {Object} data
 */
export const getTenantUsers = (searchParams) => {
  return request({
    url: `/users/search`,
    method: 'POST',
    data: searchParams,
  })
}

/**
 * Get a tenant's user count
 * @param {Number} tenantId
 * @param {Object} data
 */
export const getTenantUsersCount = (searchParams) => {
  return request({
    url: `/users/search/count`,
    method: 'POST',
    data: searchParams,
  })
}

/**
 * Create a user
 * @param {Object} data
 */
export const createUser = (data) => {
  return request({
    url: `/users`,
    method: 'POST',
    data,
  })
}

/**
 * Update a user
 * @param {Object} data
 */
export const updateUser = (data) => {
  return request({
    url: `/users/${data.id}`,
    method: 'PUT',
    data,
  })
}

/**
 * Get user login link
 * @param {Number} user_id
 */
export const getUserLoginLink = (user_id) => {
  return request({
    url: `/users/${user_id}/login_link`,
    method: 'GET',
    data: null,
  })
}

/**
 * Get tenant originate form configuration
 * @param {Number} tenant_id
 */
export const getTenantOriginateFormConfig = (tenant_id) => {
  return request({
    url: `/tenant_originate_form_configurations/${tenant_id}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Get tenant originate form configuration
 * @param {Number} tenant_id
 */
export const updateTenantOriginateFormConfig = (tenant_id, data) => {
  return request({
    url: `/tenant_originate_form_configurations/${tenant_id}`,
    method: 'PUT',
    data,
  })
}

/**
 * Get list of all feature flags
 */
export const defaultFeatureFlags = (flag_type) => {
  return request({
    url: `/flags/features/defaults${flag_type ? `?flag_type=${flag_type}` : ''}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Get list of all product flags
 */
export const defaultProductFlags = () => {
  return request({
    url: `/flags/products/defaults`,
    method: 'GET',
    data: null,
  })
}

/**
 * Update feature flag
 */
export const updateFeatureFlag = (feature_name, data) => {
  return request({
    url: `/flags/features/${feature_name}`,
    method: 'PUT',
    data,
  })
}

/**
 * Update feature flag
 */
export const createFeatureFlag = (data) => {
  return request({
    url: `/flags/features`,
    method: 'POST',
    data,
  })
}

/**
 * Get feature flags for tenant
 */
export const getTenantFeatureFlags = (tenant_id, flag_type) => {
  return request({
    url: `/flags/features/tenant_flags/${tenant_id}${flag_type ? `?flag_type=${flag_type}` : ''}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Get product flags for tenant
 */
export const getTenantProductFlags = (tenant_id) => {
  return request({
    url: `/flags/products/${tenant_id}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Get user creation metrics
 */
export const getUserCreationMetrics = (group_by = 'date') => {
  return request({
    url: `/users/metrics/user_creation?group_by=${group_by}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Get users by type
 */
export const getUserTypeMetrics = () => {
  return request({
    url: `/users/metrics/user_types`,
    method: 'GET',
    data: null,
  })
}

/**
 * Get user session creation metrics
 */
export const getUserSessionMetrics = (group_by = 'date') => {
  return request({
    url: `/users/metrics/sessions?group_by=${group_by}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Get tenant user distribution
 */
export const getUserTenantDistributionMetrics = () => {
  return request({
    url: `/users/metrics/tenant_distribution`,
    method: 'GET',
    data: null,
  })
}
/*
 * Get tenant verification note types
 * Save for use outside of fv admin panel
 */
export const getTenantVerificationTypes = (tenant_id) => {
  return request({
    url: `/verification_types?tenant_id=${tenant_id}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Get tenant verification note types
 */
export const createTenantVerificationType = (tenant_id, sentiment, name) => {
  return request({
    url: `/verification_types`,
    method: 'POST',
    data: { tenant_id, sentiment, name },
  })
}

/*
 * Get tenant main gl accounts
 */
export const getMainGLAccounts = (tenant_id) => {
  return request({
    url: `/tenants/${tenant_id}/general_accounts`,
    method: 'GET',
  })
}

export const getABLGLAccounts = () => {
  return request({
    url: `/abl/accounts`,
    method: 'GET',
  })
}
/*
 * Update tenant abl gl account
 */
export const updateMainABLGLAccount = (tenant_id, account_id, data) => {
  return request({
    url: `/abl/accounts/${account_id}`,
    method: 'PUT',
    data: data,
  })
}
/*
 * Update tenant gl account
 */
export const updateMainGLAccount = (tenant_id, account_id, data) => {
  return request({
    url: `/tenants/${tenant_id}/general_accounts/${account_id}`,
    method: 'PUT',
    data: { general_account: data },
  })
}

/*
 * Create tenant gl account
 */
export const createGLAccount = (tenant_id, data) => {
  return request({
    url: `/tenants/${tenant_id}/general_accounts`,
    method: 'POST',
    data: data,
  })
}

/*
 * Create tenant abl gl account
 */
export const createABLGLAccount = (data) => {
  return request({
    url: `/abl/accounts`,
    method: 'POST',
    data: data,
  })
}

/**
 * Get tenant default values
 * @param {Number} tenant_id
 * @returns {DefaultValue[]}
 */
export const getTenantDefaultValues = (tenant_id) => {
  return request({
    url: `/tenant_default_values?tenant_id=${tenant_id}`,
    method: 'GET',
    data: null,
  })
}

/**
 * Update tenant default value
 * @param {Number} id Tenant Default Value Id
 * @param {DefaultValue} data
 * @returns {DefaultValue}
 */
export const updateTenantDefaultValue = (id, data) => {
  return request({
    url: `/tenant_default_values/${id}`,
    method: 'PUT',
    data: data,
  })
}

/**
 * Create tenant default value
 * @param {DefaultValue}
 * @returns {DefaultValue}
 */
export const createTenantDefaultValue = (data) => {
  return request({
    url: `/tenant_default_values`,
    method: 'POST',
    data: data,
  })
}

/**
 * Update tenant default value
 * @param {Number} id
 */
export const deleteTenantDefaultValue = (id) => {
  return request({
    url: `/tenant_default_values/${id}`,
    method: 'DELETE',
    data: null,
  })
}

/**
 * Get operations where the amount added or subtracted from the client
 * reserve account doesn't match the transactions table.
 */
export const getBadOperations = () => {
  return request({
    url: `/ledger/bad_operations`,
    method: 'GET',
    data: null,
  })
}
