import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

// Components
import { Button } from '../_components/Button'

const S_PageNotReady = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 20px;

  .title,
  .text,
  .links {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .text {
    font-size: 16px;
    opacity: 0.5;
  }
`

const PageNotReady = ({ tenant }) => {
  const underwriteLink = 'https://' + `${tenant}` + '.admin.finvoice.co'
  return (
    <S_PageNotReady className={`page-not-ready`}>
      <Helmet>
        <title>Page Not Ready</title>
      </Helmet>
      <h1 className={`title`}>Welcome to the new Lender Portal</h1>
      <p className={`text`}>
        {`We're building a better experience for your team, and will let you know when it's available for use.`}
      </p>
      <p className={`text`}>In the meantime please continue to use the current underwrite portal.</p>
      <div className={`links`}>
        <Button
          buttonType={`button`}
          onClick={() => (window.location.href = underwriteLink)}
          text={`Underwrite Portal`}
        />
      </div>
      <p className={`text`}>Please contact tech@xenplatforms.com if you have any questions.</p>
    </S_PageNotReady>
  )
}

function mapStateToProps(state) {
  const { env, authentication } = state
  return {
    env,
    loggedIn: authentication.loggedIn,
    tenant: env.tenant,
  }
}

const connectedPageNotFound = connect(mapStateToProps)(PageNotReady)
export { connectedPageNotFound as PageNotReady }
