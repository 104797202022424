import request from './axios_service'
import { businessDatesToSystemTime, prospectsDatesToSystemTime } from '../_helpers/business'

export const getBusinesses = (searchParams) => {
  let toRequest = {
    url: `/businesses/search/`,
    data: { ...searchParams },
    method: 'POST',
  }
  return request(toRequest)
}

// Returns a (sub)list of all the clients for the specified tenant, and the total number of clients
export const getClients = (searchParams) => {
  return request({
    url: `/businesses/search/`,
    data: {
      ...searchParams,
      constraints: {
        is_client: true,
      },
    },
    method: 'POST',
  })
}

export const getAllProspects = (searchParams = {}) => {
  return request({
    url: `/businesses/search/`,
    data: {
      ...searchParams,
      constraints: {
        is_client: false,
      },
    },
    method: 'POST',
  })
}

// Return (sub)lists of all the clients, divided into two categories, for the specified tenant, and the total number of clients on each category
export const getActiveClients = (searchParams) => {
  return request({
    url: `/businesses/search/`,
    data: {
      ...searchParams,
      constraints: {
        is_client: true,
        risk_status: 'active',
      },
    },
    method: 'POST',
  }).then((clients) => {
    clients.results = clients.results.map(businessDatesToSystemTime)
    return clients
  })
}

export const getClientsOverview = (params) => {
  return request(
    {
      url: `/v2/businesses/overview?${params.toString()}`,
      method: 'GET',
      data: null,
    },
    null,
    true
  ).then((response) => {
    return {
      results: response.data,
      total_results: response.headers['total-count'],
    }
  })
}

export const getClientsByRiskStatus = (searchParams, riskStatus = 'active') => {
  return request({
    url: `/businesses/search/`,
    data: {
      ...searchParams,
      backwards_compatible: true,
      sort_by: 'updated_at',
      constraints: {
        is_client: true,
        risk_status: riskStatus,
      },
    },
    method: 'POST',
  })
}

export const getBusinessByRiskStatus = (searchParams, riskStatus = 'active') => {
  const count = searchParams.pagination.per_page
  const start_index = searchParams.pagination.page - 1

  let query_params = `count=${count}&start_index=${start_index}&sort_by=updated_at&is_client=true&risk_status=${riskStatus}`

  if (searchParams.search_term) {
    query_params = query_params + `&search_term=${searchParams.search_term}`
  }

  if (searchParams.business_name) {
    query_params = query_params + `&search_term=${searchParams.business_name}`
  }

  if (searchParams.start_date) {
    query_params = query_params + `&start_date=${searchParams.start_date}`
  }

  if (searchParams.end_date) {
    query_params = query_params + `&end_date=${searchParams.end_date}`
  }

  if (searchParams.sales_person_id) {
    query_params = query_params + `&sales_person_id=${searchParams.sales_person_id}`
  }
  return request({
    url: `/v2/businesses/search?${query_params}`,
    data: null,
    method: 'GET',
  })
}

// Return (sub)lists of all the prospects, divided into three categories, for the specified tenant and the total number of prospects on each category
export const getProspectsPending = (searchParams) => {
  return request({
    url: `/v2/businesses/prospect_search`,
    data: {
      ...searchParams,
      backwards_compatible: true,
      search_type: 'Borrower',
      sort_by: 'updated_at',
      deal_constraints: {
        pending: true,
      },
    },
    method: 'POST',
  })
}

export const getProspectsLeads = (searchParams) => {
  return request({
    url: `/v2/businesses/prospect_search`,
    data: {
      ...searchParams,
      backwards_compatible: true,
      search_type: 'Borrower',
      sort_by: 'updated_at',
      deal_constraints: {
        lead: true,
      },
    },
    method: 'POST',
  })
}

export const getProspectsSubmissions = (searchParams) => {
  return request({
    url: `/v2/businesses/prospect_search`,
    data: {
      ...searchParams,
      backwards_compatible: true,
      search_type: 'Borrower',
      sort_by: 'updated_at',
      deal_constraints: {
        submitted: true,
      },
    },
    method: 'POST',
  }).then((resp) => {
    resp.results = resp.results.map(prospectsDatesToSystemTime)
    return resp
  })
}

export const getProspectsArchived = (searchParams) => {
  return request({
    url: `/v2/businesses/prospect_search`,
    data: {
      ...searchParams,
      backwards_compatible: true,
      search_type: 'Borrower',
      sort_by: 'updated_at',
      deal_constraints: {
        archived: true,
      },
    },
    method: 'POST',
  })
}

export const archiveBusiness = (business_id) => {
  return request({
    url: `/businesses/archive/${business_id}`,
    data: null,
    method: 'PUT',
  })
}

export const unArchiveBusiness = (business_id) => {
  return request({
    url: `/businesses/unarchive/${business_id}`,
    data: null,
    method: 'PUT',
  })
}
