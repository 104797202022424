import { ConfigProxy } from '../_helpers/util'
import React from 'react'
export const footerConfig = ConfigProxy(
  {
    finvoice: [
      [
        {
          label: 'Privacy Policy',
          link: 'https://www.xenplatforms.com/privacy1',
        },
        {
          label: 'Terms of Use',
          link: 'https://www.xenplatforms.com/terms-of-use-1',
        },
      ],
    ],
    demo: [
      [
        {
          label: 'Privacy Policy',
          link: 'https://www.xenplatforms.com/privacy1',
        },
        {
          label: 'Terms of Use',
          link: 'https://www.xenplatforms.com/terms-of-use-1',
        },
      ],
    ],
    capitalnow: [
      [
        {
          type: 'email',
          label: 'INFO@CAPITALNOW.CA 🇨🇦',
          link: 'mailto:info@capitalnow.ca',
        },
        {
          type: 'email',
          label: 'INFO@CAPITALNOWUSA.COM 🇺🇸',
          link: 'mailto:info@capitalnowusa.ca',
        },
      ],
      [
        {
          type: 'phone',
          label: '403-617-2075',
          link: 'tel:4036172075',
        },
        {
          type: 'phone',
          label: '512-858-8806',
          link: 'tel:5128588806',
        },
      ],
    ],
    sevenoaks: [
      [
        {
          label: 'For all your Working Capital & Cashflow solutions, please visit our website at',
          link: 'http://www.sevenoakscapital.com/',
        },
      ],
      [
        {
          label: 'www.sevenoakscapital.com',
          link: 'http://www.sevenoakscapital.com/',
        },
      ],
    ],
    sallyportcf: [
      [
        {
          label: 'About Sallyport',
          link: 'http://www.sallyportcf.com/company/',
        },
      ],
      [
        {
          label: 'FAQs',
          link: 'http://www.sallyportcf.com/faqs/',
        },
      ],
    ],
    portercapital: [
      [
        {
          label: 'About',
          link: 'https://www.portercap.net/about',
        },
      ],
      [
        {
          label: 'Privacy Policy',
          link: 'https://www.portercap.net/privacy-policy',
        },
      ],
      [
        {
          label: 'Website',
          link: 'https://www.portercap.net/',
        },
      ],
      [
        {
          label: 'News',
          link: 'https://www.portercap.net/blog',
        },
      ],
    ],
    newcenturyfinancial: [
      [
        {
          label: `© 2002-${new Date().getFullYear()} New Century Financial`,
          link: 'https://www.newcenturyfinancial.com/',
        },
      ],
      [
        {
          label: 'Privacy Policy',
          link: 'https://www.newcenturyfinancial.com/about-factoring/privacy/',
        },
      ],
    ],
    scfactoringsolution: [
      [
        {
          label: (
            <div style={{ textAlign: 'left', lineHeight: '26px' }}>
              2598 East Sunrise Boulevard Suite 210-A <br />
              Fort Lauderdale, FL 33304
              <br />
              <a
                className={`hover-effect`}
                href="tel:+17543018813"
                style={{ padding: 0, margin: 0, justifyContent: 'start' }}
              >
                Office: +1 (754) 301-8813
              </a>
            </div>
          ),
          nohover: true,
        },
      ],
    ],
    twelvefive: [],
    chartercapitalusa: [
      [
        {
          label: 'About',
          link: 'https://www.chartercapitalusa.com/invoice-factoring-company/',
        },
        {
          label: 'FAQ',
          link: 'https://www.chartercapitalusa.com/invoice-factoring-frequently-asked-questions/',
        },
        {
          label: 'Live Chat',
          link: 'https://www.chartercapitalusa.com/chat/',
        },
      ],
    ],
    capitalplus: [
      [
        {
          label: 'About',
          link: 'https://capitalplus.com/who-we-are/',
        },
        {
          label: 'Contact us',
          link: 'https://capitalplus.com/who-we-are/contact-us/',
        },
      ],
    ],
    capitalplusfinancial: [
      [
        {
          label: 'About',
          link: 'https://capitalplus.com/who-we-are/',
        },
        {
          label: 'Contact us',
          link: 'https://capitalplus.com/who-we-are/contact-us/',
        },
      ],
    ],
    changecapital: [
      [
        {
          label: 'Contact us',
          link: 'https://change.capital/contact-us/',
        },
      ],
    ],
    velocityfi: [
      [
        {
          label: 'About',
          link: 'https://velocityfi.com/about/',
        },
        {
          label: 'FAQ',
          link: 'https://velocityfi.com/factoring/#faqs',
        },
        {
          label: 'Contact us',
          link: 'https://velocityfi.com/contact-us/',
        },
        {
          label: (
            <div style={{ textAlign: 'left', lineHeight: '26px' }}>
              Have Questions? <br />
              <a
                className={`hover-effect`}
                href="tel:+4322471888"
                style={{ padding: 0, margin: 0, justifyContent: 'start' }}
              >
                +1 (432) 247-1888
              </a>
            </div>
          ),
          nohover: true,
        },
      ],
    ],
    marcocapital: [
      [
        {
          label: 'Grow With Marco',
          link: 'https://www.marcocap.com/customer-log-in',
        },
        {
          label: 'About',
          link: 'https://www.marcocap.com/about-feed',
        },
        {
          label: 'Contact',
          link: 'https://www.marcocap.com/contact-feed',
        },
        {
          label: 'Jobs',
          link: 'https://angel.co/company/marco-capital/jobs',
        },
      ],
    ],
    marcofi: [
      [
        {
          label: 'Grow With Marco',
          link: 'https://www.marcocap.com/customer-log-in',
        },
        {
          label: 'About',
          link: 'https://www.marcocap.com/about-feed',
        },
        {
          label: 'Contact',
          link: 'https://www.marcocap.com/contact-feed',
        },
        {
          label: 'Jobs',
          link: 'https://angel.co/company/marco-capital/jobs',
        },
      ],
    ],
    acsfactors: [
      [
        {
          label: 'Home',
          link: 'https://www.acsfactors.com/',
        },
        {
          label: 'About Us',
          link: 'https://www.acsfactors.com/about-us/',
        },
        {
          label: 'Client Resources',
          link: 'https://www.acsfactors.com/client-resources/',
        },
        {
          label: 'Contact Us',
          link: 'https://www.acsfactors.com/contact-us/',
        },
      ],
    ],
    ccbg: [
      [
        {
          label: 'Apply Now',
          link: 'https://ccbgstaging.finvoice.co/signup',
        },
        {
          label: 'Request Information',
          link: 'https://www.ccbg.com/cashflow/',
        },
      ],
    ],
    hedayacapital: [
      [
        {
          label: 'About',
          link: 'https://www.hedayacapital.com/',
        },
        {
          label: 'Privacy Policy',
          link: 'https://hedayacapital.finvoice.co/privacy',
        },
        {
          label: 'End User License Agreement',
          link: 'https://hedayacapital.finvoice.co/eula',
        },
        {
          label: 'Contact Us',
          link: 'tel:5128588806',
        },
      ],
    ],
    republicbc: [
      [
        {
          label: 'About Republic Business Credit',
          link: 'http://www.republicbc.com/about/',
        },
        {
          label: 'Services',
          link: 'http://republicbc.com/products/',
        },
      ],
    ],
    newbridgeglobal: [],
    axosbank: [
      [
        {
          label: 'About',
          link: 'https://www.axosbank.com/en/Business/Commercial-Lending/Factoring',
        },
        {
          label: 'Privacy',
          link: 'https://www.axosbank.com/Legal/Privacy-and-Security',
        },
      ],
    ],
    factoringdemo: [
      [
        {
          label: 'Privacy Policy',
          link: 'https://www.xenplatforms.com/privacy1',
        },
        {
          label: 'Terms of Use',
          link: 'https://www.xenplatforms.com/terms-of-use-1',
        },
      ],
    ],
    abldemo: [
      [
        {
          label: 'Privacy Policy',
          link: 'https://www.xenplatforms.com/privacy1',
        },
        {
          label: 'Terms of Use',
          link: 'https://www.xenplatforms.com/terms-of-use-1',
        },
      ],
    ],
    prodemo: [
      [
        {
          label: 'Privacy Policy',
          link: 'https://www.xenplatforms.com/privacy1',
        },
        {
          label: 'Terms of Use',
          link: 'https://www.xenplatforms.com/terms-of-use-1',
        },
      ],
    ],
    ctrlpay: [
      [
        {
          label: 'About',
          link: '', //TODO: Fill In Links
        },
        {
          label: 'Privacy Policy',
          link: '', //TODO: Fill In Links
        },
        {
          label: 'Service Terms',
          link: '', //TODO: Fill In Links
        },
        {
          label: 'Terms of Use',
          link: '', //TODO: Fill In Links
        },
        {
          label: '1-800-375-5056',
          link: 'tel:18003755056',
        },
      ],
    ],
    breakoutfinance: [
      [
        {
          label: '888-318-3534',
          link: 'tel:8883183534',
        },
      ],
    ],
    fastarfunding: [
      [
        {
          label: '888-833-2286',
          link: 'tel:8888332286',
        },
      ],
    ],
    interportcapital: [
      [
        {
          label: 'About',
          link: 'https://interportcap.com/who-we-are/',
        },
        {
          label: 'Contact',
          link: 'https://interportcap.com/contact-us/',
        },
        {
          label: 'How it Works',
          link: 'https://interportcap.com/how-it-works/',
        },
        {
          label: 'FAQ',
          link: 'https://interportcap.com/faq/',
        },
        {
          label: 'Privacy Policy',
          link: 'https://interportcap.com/privacy-policy/',
        },
      ],
    ],
    xen: [
      [
        { label: 'About Us', link: 'https://xenplatforms.com/about' },
        { label: 'Terms of Use', link: 'https://xenplatforms.com/terms-of-use-1' },
        { label: 'Privacy Policy', link: 'https://xenplatforms.com/privacy1' },
        { label: 'Contact', link: 'https://xenplatforms.com/contact' },
      ],
    ],
    unicorncapital: [
      [
        { label: 'About Us', link: 'https://xenplatforms.com/about' },
        { label: 'Terms of Use', link: 'https://xenplatforms.com/terms-of-use-1' },
        { label: 'Privacy Policy', link: 'https://xenplatforms.com/privacy1' },
        { label: 'Contact', link: 'https://xenplatforms.com/contact' },
      ],
    ],
  },
  []
)
