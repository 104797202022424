import styled from 'styled-components'
import { S_Checkbox } from '../Checkbox/Checkbox.styles'

export const S_CheckboxGroup = styled.div`
  ${S_Checkbox} {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    padding: 5px;
  }
`
