import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformDebtorSearchResponse } from './transform';
async function getDebtorsSearch(params = {}) {
    try {
        const response = await request({
            method: 'get',
            url: '/debtors_search',
            params,
        });
        const data = transformDebtorSearchResponse(response);
        return data;
    }
    catch (_error) {
        throw Error('Error searching for Debtors');
    }
}
export const useGetDebtorsSearch = (params) => {
    return useQuery({
        queryKey: ['debtors', { params }],
        queryFn: () => getDebtorsSearch(params),
    });
};
