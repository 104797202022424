import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react'
import { connect } from 'react-redux'
import { IconContext } from 'react-icons'
import { FaExclamationTriangle } from 'react-icons/fa'
import throttle from 'lodash/throttle'
import { Chip } from './Chip'
import { BeatLoader } from 'react-spinners'

// Components
import { S_AutoCompleteInput } from './AutoCompleteInput.styles'

// Actions
import { modalActions } from '../../_actions'

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false)

  function downHandler({ key }) {
    if (key === targetKey) {
      event.preventDefault()
      setKeyPressed(true)
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  })

  return keyPressed
}

const throttleCallback = throttle((val, callback) => {
  callback(val)
}, 500)
/**
 * Accepts fetchSuggestions (async function), valueIdAttr (attr of selected option to send to onChange handler), valueLabelAttr (pretty value to display when option is selected)
 */
const AutoCompleteInput = (props, ref) => {
  const [suggestions, setSuggestions] = useState([])
  const [selectedText, setSelectedText] = useState('')
  let val = 0
  if (props.defaultValue) {
    val = props.defaultValue
  }
  if (props.value) {
    val = props.value
  }
  const { showWarning = true, limitMobileHeight = false } = props

  const [selectedIdVal, setSelectedIdVal] = useState(val)
  const [warning, setWarning] = useState('')
  const [openSuggestionBox, setOpenSuggestionBox] = useState(false)
  const inputRef = useRef(null)

  const downPress = useKeyPress('ArrowDown')
  const upPress = useKeyPress('ArrowUp')
  const enterPress = useKeyPress('Enter')
  const [cursor, setCursor] = useState(0)
  const [hovered, setHovered] = useState(undefined)
  const [componentLoading, setIsLoading] = useState(false)
  const isLoading = props.isLoading !== undefined ? props.isLoading : componentLoading

  const showChip = !!selectedIdVal && !!selectedText

  const getSuggestions = (e) => {
    let search = `${e.target.value}`
    setSelectedText(e.target.value)
    if (props.checkInputValue === true) {
      props.onInputValueChange({
        target: {
          value: e.target.value,
        },
      })
    }
    throttleCallback(search, async (search) => {
      setIsLoading(true)
      let suggestions = await props.fetchSuggestions(search)
      setIsLoading(false)
      setSuggestions(suggestions)
    })
  }

  const setSelected = (selectedId, newSelectedText) => {
    props.onChange({
      target: {
        value: selectedId,
        text: newSelectedText,
      },
    })
    setSelectedText(newSelectedText)
    setSuggestions([])
    setSelectedIdVal(selectedId)
    if (typeof props.onBlur === 'function') {
      props.onBlur({
        target: {
          value: selectedId,
        },
      })
    }
  }
  useEffect(() => {
    if (props.value && typeof props.fetchRecord === 'function') {
      props
        .fetchRecord(props.value)
        .then((record) => {
          if (!record) {
            setWarning(`Record with ID ${props.value} not found.`)
          } else {
            if (props.expectedLabel && record[props.valueLabelAttr] != props.expectedLabel) {
              setWarning(`Expected record name of ${props.expectedLabel}, but got ${record[props.valueLabelAttr]}`)
            }
            setSelectedText(record[props.valueLabelAttr])
          }
        })
        .catch((err) => {
          setWarning(`Could not fetch record with id ${props.value}`)
        })
    } else {
      setSelectedText(props.value)
    }
  }, [props.value])

  useEffect(() => {
    if (suggestions.length && downPress) {
      setCursor((prevState) => (prevState < suggestions.length - 1 ? prevState + 1 : prevState))
    }
  }, [downPress])
  useEffect(() => {
    if (suggestions.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState))
    }
  }, [upPress])
  useEffect(() => {
    if (suggestions.length && enterPress) {
      setSelected(suggestions[cursor][props.valueIdAttr], suggestions[cursor][props.valueLabelAttr])
      setOpenSuggestionBox(false)
    }
  }, [cursor, enterPress])
  useEffect(() => {
    if (suggestions.length && hovered) {
      setCursor(suggestions.indexOf(hovered))
    }
  }, [hovered])

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus()
    },
    //making this act more like a proper html input element
    tagName: 'INPUT',
    value: selectedIdVal,
  }))

  const renderInputComponent = () => {
    if (props.inputComponent) {
      // Note that instead of ref we use inputRef here as it is assumed
      // that the custom inputComponent will be a function component
      return (
        <props.inputComponent
          autoComplete="off"
          className={'autocomplete-field'}
          disabled={props.disabled || showChip}
          inputRef={inputRef}
          onChange={getSuggestions}
          onFocus={(e) => {
            setSelectedIdVal(null)
            setSelectedText('')
            setOpenSuggestionBox(true)
          }}
          placeholder={props.placeholder || ''}
          type={'text'}
          value={!!selectedIdVal && !!selectedText ? '' : selectedText}
        ></props.inputComponent>
      )
    } else {
      return (
        <input
          autoComplete="off"
          className={'autocomplete-field'}
          disabled={props.disabled || showChip}
          onChange={getSuggestions}
          onFocus={(e) => {
            setSelectedIdVal(null)
            setSelectedText('')
            setOpenSuggestionBox(true)
          }}
          placeholder={props.placeholder || ''}
          ref={inputRef}
          type={'text'}
          value={!!selectedIdVal && !!selectedText ? '' : selectedText}
        />
      )
    }
  }

  return (
    <S_AutoCompleteInput
      className={`${props.className} ${props.showInputStyles && showChip ? 'showInputStyles' : ''} ${
        !showChip ? 'inputMode' : ''
      }`}
      limitMobileHeight={limitMobileHeight}
      multiline={props.multiline === 'function' ? props.multiline : true}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setSuggestions([])
          setOpenSuggestionBox(false)
          if (typeof props.onBlur === 'function' && !selectedText) {
            props.onBlur({
              target: {
                value: null,
              },
            })
          }
          if (suggestions.length !== 0) {
            setSelected(suggestions[0][props.valueIdAttr], suggestions[0][props.valueLabelAttr])
          }
        }
      }}
      onClickCapture={(e) => {
        if (!props.disabled && !showChip) setOpenSuggestionBox(true)
      }}
    >
      {!showChip && renderInputComponent()}
      {showChip && (
        <Chip
          className="chip-position"
          onDelete={() => {
            setSelectedIdVal(null)
            setSelectedText('')
            if (inputRef.current) {
              inputRef.current.focus()
            }
            if (typeof props.onBlur === 'function') {
              props.onBlur({
                target: {
                  value: '',
                },
              })
            }
            props.onChange({
              target: {
                value: '',
                text: '',
              },
            })
          }}
          showDelete={props.showDelete || !props.disabled}
          text={selectedText}
        />
      )}
      <div className={`suggestion-box-wrapper`}>
        <div
          className={openSuggestionBox && suggestions?.length === 0 && selectedText === '' ? 'suggestion-box' : 'close'}
        >
          <div className={'default-suggestion'} tabIndex={-1}>
            {props.defaultSuggestion}
          </div>
        </div>
        {isLoading && selectedText !== '' ? (
          <div className={`suggestion-box loading open`}>
            <BeatLoader size={10} />
          </div>
        ) : (
          <>
            {/* <div className={openSuggestionBox && suggestions.length===0 && selectedText !== '' ? `suggestion-box open` : 'close' }>
                            No Results
                        </div> */}
            <div
              className={
                openSuggestionBox && (suggestions?.length !== 0 || props.addButton) && selectedText !== ''
                  ? `suggestion-box open`
                  : `suggestion-box close`
              }
            >
              {suggestions.map((item, i) => {
                return (
                  <div
                    className={`suggestion item ${i === cursor ? 'active' : ''}`}
                    key={item.id}
                    onClick={() => {
                      setSelected(item[props.valueIdAttr], item[props.valueLabelAttr])
                      setOpenSuggestionBox(false)
                    }}
                    onMouseEnter={() => {
                      setHovered(item)
                    }}
                    onMouseLeave={() => setHovered(undefined)}
                    tabIndex={-1}
                  >
                    {item[props.valueLabelAttr]}
                  </div>
                )
              })}
              {props.addButton && selectedText !== '' ? (
                <div className={openSuggestionBox ? 'add-button' : 'close'}>
                  <div
                    className={`create`}
                    onClick={(e) => {
                      //Preventing default so state can update before selecting a value
                      e.preventDefault()
                      setSuggestions([])
                      setSelectedIdVal(null)
                      setSelectedText('')
                      setOpenSuggestionBox(false)
                      props.openModal(props.buttonModal, { setSelected })
                    }}
                    tabIndex={-1}
                  >
                    {props.modalText}
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
      {warning &&
        showWarning && ( //<ToolTip content={warning}>
          <IconContext.Provider value={{ color: '#ffae42' }}>
            <FaExclamationTriangle className={`warning-icon`} title={warning} />
          </IconContext.Provider>
        )}
    </S_AutoCompleteInput>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (content, props) => {
      dispatch(modalActions.openModal(content, props))
    },
  }
}

const ACI = forwardRef(AutoCompleteInput)

const connectedAutoCompleteInput = connect(null, mapDispatchToProps, null, { forwardRef: true })(ACI)

export { connectedAutoCompleteInput as AutoCompleteInput }
