import { UTCDateToSystemString } from './dates'
import { userDatesToSystemTime } from './user-utils'
import { documentDatesToSystemTime } from './documents'
export const verificationDatesToSystemTime = (verification) => {
  verification.note.created_at = UTCDateToSystemString(verification.note.created_at)
  verification.note.updated_at = UTCDateToSystemString(verification.note.updated_at)
  verification.note.created_by = userDatesToSystemTime(verification.note.created_by)
  verification.note.modified_by = userDatesToSystemTime(verification.note.modified_by)
  verification.note.owner = userDatesToSystemTime(verification.note.owner)
  verification.documents = verification.documents.map(documentDatesToSystemTime)
  return verification
}
