import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { S_Chip } from './Chip.styles'

export const Chip = ({
  icon = null,
  text = '',
  showDelete = true,
  onDelete = () => {},
  className = '',
  style = {},
}) => {
  //TODO: Add icon support
  if (showDelete) {
    style.paddingRight = '7px'
  }
  return (
    <S_Chip className={className} style={style}>
      <div className={`chip-text`}>{text}</div>
      {showDelete && (
        <div className={`x-btn`} onClick={onDelete}>
          <IconContext.Provider value={{ className: 'x-btn-icon' }}>
            <FaTimes />
          </IconContext.Provider>
        </div>
      )}
    </S_Chip>
  )
}
