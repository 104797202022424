import { useIsMutating, useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformNonFactoredInvoiceParams, transformUnserializedInvoice } from './transform';
class NonFactoredInvoiceRequestError extends Error {
    genericError;
    invoiceNumberError;
    constructor({ genericError, invoiceNumberError } = {}) {
        super('Could not create non-factored invoice');
        this.genericError = genericError;
        this.invoiceNumberError = invoiceNumberError;
    }
}
const invoiceResponseHasError = (error) => typeof error === 'string';
async function createNonFactoredInvoice(params) {
    try {
        const payloadData = transformNonFactoredInvoiceParams(params);
        const response = await request({
            method: 'POST',
            url: '/invoices/non_factored',
            data: payloadData,
        });
        const data = transformUnserializedInvoice(response);
        return data;
    }
    catch (error) {
        // If the server returns a 4XX error, request() will re-throw the error
        // object/message as a string. In this case, we want to rethrow the error
        // message so it's returned in the error key of the useMutation hook. We add
        // the error message to the invoiceNumber property so we know it's
        // specifically an invoice number error.
        if (invoiceResponseHasError(error)) {
            // At the moment the server only validates the invoice number field, but
            // in the future we could assign different errors to different keys.
            if (error.toLowerCase().includes('invoice number')) {
                throw new NonFactoredInvoiceRequestError({ invoiceNumberError: error });
            }
            // If the error is not related to the invoice number, assign it to a generic key
            throw new NonFactoredInvoiceRequestError({ genericError: error });
        }
        // If the error is not a string, it's an instance of Error, so rethrow with its message.
        throw new NonFactoredInvoiceRequestError({ genericError: error.message });
    }
}
export const useCreateNonFactoredInvoice = () => {
    return useMutation({
        mutationKey: ['create-non-factored-invoice'],
        mutationFn: (params) => createNonFactoredInvoice(params),
        onError: (error) => {
            notifications.error(error.invoiceNumberError ?? error.message);
        },
        onSuccess: (invoice) => {
            notifications.success(`Created non-factored invoice ${invoice.invoiceNumber}`);
            queryClient.invalidateQueries({ queryKey: ['payment-assignments'] });
            queryClient.invalidateQueries({ queryKey: ['payments'] });
            queryClient.invalidateQueries({ queryKey: ['invoices'] });
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements'] });
        },
    });
};
export const useIsNonFactoredInvoiceCreating = () => {
    return useIsMutating({ mutationKey: ['create-non-factored-invoice'] });
};
