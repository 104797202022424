import { colors } from '../../_constants/colors'

export const labelChartStyles = {
  fontSize: 14,
  color: colors.TEXT_DARK,
  textAlign: 'left',
  padding: '5px 8px',
  borderRadius: 2,
  borderColor: colors.BORDER_GREY,
}

export const axisTickStyles = {
  fontSize: 12,
  fontWeight: 400,
  color: colors.TEXT_LIGHT,
  fill: colors.TEXT_LIGHT,
}
