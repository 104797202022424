import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { modalActions } from '../_actions'
export function useModal() {
  const dispatch = useDispatch()
  return {
    modal: useSelector((state) => {
      return state.modal
    }, shallowEqual),
    openModal: useCallback(
      (content, props, modalProps) => dispatch(modalActions.openModal(content, props, modalProps)),
      [dispatch]
    ),
    closeModal: useCallback(() => dispatch(modalActions.closeModal()), [dispatch]),
    switchModal: useCallback((content, props) => dispatch(modalActions.switchModal(content, props)), [dispatch]),
    setPrevModal: useCallback((content, props) => dispatch(modalActions.setPrevModal(content, props)), [dispatch]),
  }
}
