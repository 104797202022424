import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FaFacebook, FaTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa'

// CONSTANTS
import { socialMediaConfig } from '../_configs'

export const SocialIconRow = styled.div`
  display: flex;
  margin-top: 1em;
  justify-content: flex-start;
  font-size: 25px;
  width: 100%;
  a {
    opacity: 0.5;
    margin-right: 10px;
    &:hover {
      opacity: 1 !important;
      color: white;
    }
  }
`

const lookup = {
  facebook: FaFacebook,
  twitter: FaTwitter,
  linkedin: FaLinkedin,
  youtube: FaYoutube,
}

export const SocialIcons = () => {
  const { env } = useSelector((state) => state)
  const tenant = env.tenant
  const config = socialMediaConfig[tenant]

  return (
    <SocialIconRow>
      {config.map((social, i) => {
        const Component = lookup[social.type]
        return (
          <a href={social.link} key={i} rel="noreferrer" target="_blank">
            <Component className={`icon`} />
          </a>
        )
      })}
    </SocialIconRow>
  )
}
