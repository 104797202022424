import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'formik'
import styled from 'styled-components'
import ReactDropzone from 'react-dropzone'

// Actions
import { dealActions } from '../../_actions'

// Constants
import { radioLabelStyle } from '../Forms/FormField/CustomFields/UploadDocField'

// Components
import { S_RadioGroup } from '../form-fields/RadioGroup'

const RadioLabel = styled.label`
  ${radioLabelStyle}
`

const UploadFileLabel = styled(ReactDropzone)`
  ${radioLabelStyle}
`

const getLatestDocumentName = (documents, fieldName) => {
  const document = documents
    .sort((a, b) => {
      return new Date(b.updated_at) - new Date(a.updated_at)
    })
    .find((doc) => doc.document_type === fieldName)

  if (document) {
    return document.document_name
  } else {
    return `loading...`
  }
}

const UploadDoc = ({
  field,
  formikName,
  uploadDealDocument,
  user,
  deal,
  handleChange,
  handleBlur,
  supporting_documents,
  value,
}) => (
  <S_RadioGroup>
    <Field
      name={formikName}
      render={({ field: formikField }) => {
        return (
          <div className={`radio-btn`}>
            <input
              id={`${formikName}-true`}
              type={`radio`}
              {...formikField}
              checked={formikField.value === `true`}
              value={`true`}
            />
            <UploadFileLabel
              className={`radio-label`}
              htmlFor={`${formikName}-true`}
              multiple={false}
              onDrop={(acceptedFiles, rejectedFiles) => {
                const file = acceptedFiles[0]
                uploadDealDocument({ filedata: file, filename: file.name, document_type: field.name }, user.id, deal.id)

                const fieldValue = `true`
                handleChange(formikName, fieldValue)
                handleBlur(formikName, `have_${field.name}`, fieldValue)
              }}
            >
              {formikField.value === `true`
                ? getLatestDocumentName(supporting_documents, field.name)
                : field.options[0].label}
            </UploadFileLabel>
          </div>
        )
      }}
      validate={(value) => {
        let errorMessage
        if (field.required && value === null) {
          errorMessage = `Field is required.`
        }
        return errorMessage
      }}
    />
    {value !== `true` && (
      <Field
        name={formikName}
        render={({ field: formikField }) => {
          return (
            <div className={`radio-btn`}>
              <input
                id={`${formikName}-later`}
                type={`radio`}
                {...formikField}
                checked={formikField.value === `later`}
                onChange={(e) => {
                  let fieldValue = e.target.value

                  handleChange(formikName, fieldValue)
                  handleBlur(formikName, `have_${field.name}`, fieldValue)
                }}
                value={`later`}
              />
              <RadioLabel className={`radio-label`} htmlFor={`${formikName}-later`}>
                {field.options[1].label}
              </RadioLabel>
            </div>
          )
        }}
        validate={(value) => {
          let errorMessage
          if (field.required && value === null) {
            errorMessage = `Field is required.`
          }
          return errorMessage
        }}
      />
    )}
  </S_RadioGroup>
)

function mapStateToProps(state) {
  const { user, deal } = state
  return {
    user,
    deal: deal.deal,
    supporting_documents: deal.deal_supporting_documents,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadDealDocument: (file, userId, dealId) => {
      dispatch(dealActions.uploadDealDocument(file, userId, dealId))
    },
  }
}

const connectedUploadDoc = connect(mapStateToProps, mapDispatchToProps)(UploadDoc)
export { connectedUploadDoc as UploadDoc }
