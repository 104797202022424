import { formatDecimalAsNumber } from './decimal';
export const formatCurrency = (value, { currency = 'USD' } = {}) => {
    if (value === '')
        return '';
    // Remove all commas, convert to number, round to 2 decimal places
    const numVal = typeof value === 'string' ? formatDecimalAsNumber(value) : value;
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    }).format(numVal);
};
