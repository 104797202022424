import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformUnserializedInvoice } from 'xen/api/use-invoice';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformInvoiceSchedulePayload } from './transform';
async function createInvoiceScheduleInvoice({ invoiceScheduleId, invoice, }) {
    try {
        const response = await request({
            method: 'POST',
            url: `/invoice_schedules/${invoiceScheduleId}/invoices`,
            data: transformInvoiceSchedulePayload({ invoiceScheduleId, invoice }),
        });
        const data = transformUnserializedInvoice(response);
        return data;
    }
    catch (error) {
        throw new Error('Could not create invoice');
    }
}
export const useCreateInvoiceScheduleInvoice = () => {
    return useMutation({
        mutationKey: ['create-invoice-schedule-invoice'],
        mutationFn: ({ invoiceScheduleId, invoice }) => createInvoiceScheduleInvoice({ invoiceScheduleId, invoice }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: () => {
            notifications.success(`Created invoice for invoice schedule`);
            queryClient.invalidateQueries({ queryKey: ['invoice-schedules'] });
        },
    });
};
