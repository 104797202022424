import styled from 'styled-components'

export const S_Chip = styled.div`
  padding: 3px 10px;
  font-size: 16px;
  border-radius: 25px;
  background-color: #f1f1f1;
  margin-left: 4px;
  display: flex;
  max-width: fit-content;
  .x-btn {
    max-height: 16px;
    max-width: 16px;
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }
  .chip-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: ${(props) => (props.showDelete ? '90%' : '100%')};
    overflow: hidden;
  }
  .x-btn-icon {
    color: black;
    cursor: pointer;
  }
  .x-btn-icon:hover {
    color: #999;
  }
`
