import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { Anchor, Checkbox, Group, Loader, Stack, Text } from 'xen-ui';
import { useGetBusinessTerms } from 'xen/api/use-business/use-get-business-terms';
import { useUpdateInvoiceSchedule } from 'xen/api/use-invoice-schedule';
import { ErrorText } from 'xen/components';
import { FormCheckboxGroup, FormClientSelect } from 'xen/components/form';
const invoiceScheduleFormSchema = z.object({
    businessId: FormClientSelect.schema,
    terms: z.string().array(),
});
export const InvoiceScheduleUpdateForm = ({ invoiceSchedule }) => {
    const { businessId, businessName, id: scheduleId, state } = invoiceSchedule;
    const defaultValues = {
        businessId: String(businessId),
        terms: invoiceSchedule.terms.map((term) => String(term.id)),
    };
    const { control, handleSubmit } = useForm({
        resolver: zodResolver(invoiceScheduleFormSchema),
        mode: 'onBlur',
        values: defaultValues,
    });
    const { mutate, isError: isUpdateError } = useUpdateInvoiceSchedule({ urlParams: { scheduleId } });
    const { data: businessTerms = [], isLoading: isBusinessTermsLoading, isError: isBusinessTermsError, } = useGetBusinessTerms(businessId);
    const onSubmit = async (data) => {
        const payload = {
            businessId: parseInt(data.businessId),
            terms: data.terms.map((id) => ({ id: Number(id) })),
        };
        mutate({ payload });
    };
    const handleFormSubmit = () => {
        handleSubmit(onSubmit)();
    };
    if (isBusinessTermsLoading)
        return _jsx(Loader, {});
    if (isBusinessTermsError) {
        return _jsx(ErrorText, { message: "Could not load business terms" });
    }
    const SettingsLink = () => {
        // TODO: Convert <Anchor> to <TextLink> when associated xen path exists */}
        return (_jsx(Anchor, { component: Link, fz: "inherit", to: `/clients_overview/${businessId}/settings`, underline: "always", children: businessName }));
    };
    return (_jsx(Form, { control: control, onSubmit: handleFormSubmit, children: _jsx(Group, { children: _jsxs(Stack, { gap: "lg", children: [isUpdateError && _jsx(ErrorText, { message: "Could not update invoice schedule" }), _jsx(FormClientSelect, { control: control, description: "Select the associated client", disabled: state !== 'incomplete', name: "businessId", onChange: handleFormSubmit, required: true }), businessTerms.length > 0 ? (_jsx(FormCheckboxGroup, { control: control, defaultValue: defaultValues.terms, description: _jsxs(_Fragment, { children: ["Select which ", _jsx(SettingsLink, {}), " terms apply"] }), label: "Terms", name: "terms", onChange: handleFormSubmit, children: _jsx(Stack, { gap: "xs", pt: "0.25rem", children: businessTerms.map(({ id, name, type }) => {
                                return (_jsx(Checkbox, { disabled: !['incomplete', 'pending'].includes(state), label: name || type, value: String(id) }, id));
                            }) }) })) : (_jsxs(Stack, { gap: "sm", children: [_jsx(Text, { fw: "bold", children: "Terms" }), _jsxs(Text, { children: ["No terms have been created for ", _jsx(SettingsLink, {}), "."] })] }))] }) }) }));
};
