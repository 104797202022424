/**
 * All files in this folder should export an object with an array of strings called respondsTo (optional), a message handler called onMessage and a string variable called channel
 *
 */
import { store } from '../../_helpers/store'
import { lockRecord, unlockRecord } from '../../_actions/locks.actions'
const onMessage = (message) => {
  if (message?.message?.message_type === 'lock') {
    store.dispatch(lockRecord(message.message.object_type, message.message.id))
  } else if (message?.message?.message_type === 'unlock') {
    store.dispatch(unlockRecord(message.message.object_type, message.message.id))
  }
}
const respondsTo = ['lock', 'unlock']
const channel = 'LockChannel'

export const lockService = { onMessage, respondsTo, channel }
