import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from 'xen-ui';
import { useUpdateInvoiceSchedule } from 'xen/api/use-invoice-schedule';
export const InvoiceScheduleUnsubmitButton = ({ children = 'Edit Invoice Schedule', invoiceSchedule: { id: scheduleId }, ...props }) => {
    const { mutateAsync: updateSchedule, isPending } = useUpdateInvoiceSchedule({ urlParams: { scheduleId } });
    const handleUnsubmitSchedule = async () => {
        updateSchedule({ payload: { state: 'incomplete' } });
    };
    return (_jsx(Button, { loading: isPending, onClick: handleUnsubmitSchedule, ...props, children: children }));
};
