import ROUTES from '../../../_constants/routes'
import { FINANCIAL_RADIO_OPTIONS } from '../../form.constants'
import { getAllowedDob } from '../../../_helpers'

export const velocityfi_config = {
  crumbs: [
    {
      label: 'Business Details',
      link: ROUTES.confirm_form,
    },
    {
      label: 'Submitted',
      link: ROUTES.submitted,
    },
  ],
  sections: [
    {
      title: 'Business Details',
      type: 'contact_details',
      newProspectTitle: 'Required Prospect Details',
      newProspectSubtitle: 'Please enter the following information to create a new prospect.',
      tooltip: 'Please tell us about your business.',
      fields: [
        {
          type: 'text',
          name: 'first_name',
          table: 'user',
          label: 'First Name',
          placeholder: 'First Name',
          lenderRequired: true,
          required: true,
        },
        {
          type: 'text',
          name: 'last_name',
          table: 'user',
          label: 'Last Name',
          placeholder: 'Last Name',
          lenderRequired: true,
          required: true,
        },
        {
          type: 'email',
          name: 'email_address',
          table: 'user',
          label: 'Email',
          placeholder: 'Email',
          lenderRequired: true,
          required: true,
        },
        {
          type: 'text',
          name: 'title',
          table: 'user',
          label: 'Title',
          placeholder: 'Title',
          lenderRequired: false,
          required: false,
        },
        {
          type: 'tel',
          name: 'phone_number',
          table: 'user',
          label: 'Cell Phone',
          placeholder: 'Cell Phone',
          lenderRequired: false,
          required: false,
          hideForPrefillForm: false,
        },
        {
          type: 'text',
          name: 'business_name',
          table: 'business',
          placeholder: 'Legal Company Name',
          label: 'Legal Company Name',
          required: true,
          lenderRequired: true,
        },
        {
          type: 'text',
          name: 'business_website',
          table: 'business',
          placeholder: 'Website',
          label: 'Website',
          required: false,
        },
        {
          type: 'tel',
          name: 'business_phone_number',
          table: 'business',
          placeholder: 'Phone Number',
          label: 'Phone Number',
          lenderRequired: true,
          required: true,
        },
        [
          {
            type: 'text',
            name: 'address_street',
            table: 'business',
            placeholder: 'Street',
            label: 'Business Address',
            required: true,
          },
          {
            type: 'text',
            name: 'address_city',
            table: 'business',
            placeholder: 'City',
            label: 'City',
            required: true,
          },
          {
            type: 'text',
            name: 'address_state',
            table: 'business',
            placeholder: 'State',
            label: 'State',
            required: true,
          },
          {
            type: 'text',
            name: 'address_zip_code',
            table: 'business',
            placeholder: 'Zip Code',
            label: 'Zip Code',
            required: true,
          },
        ],
        {
          type: 'business_ein',
          name: 'business_ein_last4',
          table: 'business',
          label: 'Business Tax ID',
          placeholder: 'Business Tax ID',
          required: true,
        },
        {
          type: 'text',
          name: 'business_entity',
          table: 'business',
          placeholder: 'Type of Business',
          label: 'Type of Business',
          required: true,
        },
        {
          type: 'currency',
          name: 'monthly_sales_range',
          table: 'business',
          label: 'Monthly Sales Volume',
          required: true,
        },
      ],
    },
    {
      title: 'Officers/Owners',
      type: 'business_officers',
      label: 'Officer/Owner',
      fields: [
        {
          type: 'text',
          name: 'first_name',
          table: 'business_officers',
          label: 'First Name',
          placeholder: 'First Name',
          required: true,
        },
        {
          type: 'text',
          name: 'last_name',
          table: 'business_officers',
          label: 'Last Name',
          placeholder: 'Last Name',
          required: true,
        },
        {
          type: 'email',
          name: 'email_address',
          table: 'business_officers',
          label: 'Email',
          placeholder: 'Email',
          required: true,
        },
        {
          type: 'percentage',
          name: 'pct_ownership',
          table: 'business_officers',
          label: 'Percent Ownership',
          placeholder: 'Percent Ownership',
          required: true,
        },
        {
          type: 'tel',
          name: 'phone_number',
          table: 'business_officers',
          label: 'Phone',
          placeholder: 'Phone',
          required: true,
        },
        {
          type: 'text',
          name: 'title',
          table: 'business_officers',
          label: 'Title',
          placeholder: 'Title',
          required: true,
        },
        {
          type: 'date',
          name: 'dob',
          max: getAllowedDob(18),
          table: 'business_officers',
          label: 'Date of Birth (MM-DD-YYYY)',
          placeholder: 'Date of Birth (MM-DD-YYYY)',
          required: true,
        },
        {
          type: 'text',
          name: 'address',
          table: 'business_officers',
          label: 'Address',
          placeholder: 'Home Address',
          required: true,
        },
        {
          type: 'text',
          name: 'city',
          table: 'business_officers',
          label: 'City',
          placeholder: 'City',
          required: true,
        },
        {
          type: 'text',
          name: 'state',
          table: 'business_officers',
          label: 'State',
          placeholder: 'State',
          required: true,
        },
        {
          type: 'text',
          name: 'zip',
          table: 'business_officers',
          label: 'Zip Code',
          placeholder: 'Zip code',
          required: true,
        },
        {
          type: 'ssn',
          name: 'ssn_last4',
          table: 'business_officers',
          label: 'Social Security Number',
          placeholder: 'Social Security Number',
          required: true,
        },
        {
          type: 'upload_document',
          name: 'personal_id',
          table: 'deal',
          label: `Personal Id`,
          required: true,
          options: [
            {
              label: 'Upload file',
              value: 'upload',
            },
            {
              label: 'I will do it later',
              value: 'later',
            },
          ],
        },
      ],
    },
    {
      title: 'Financial Details',
      label: 'Financial Details',
      fields: [
        {
          type: 'currency',
          name: 'amount_interest_monthly',
          table: 'additional_business_data',
          label: 'Monthly Funding Amount Needed?',
          required: true,
        },
        {
          type: 'tenant_form_radio',
          name: 'currently_being_financed',
          table: 'business',
          label: 'Do you currently have a financing relationship with another lender/factor?',
          options: FINANCIAL_RADIO_OPTIONS,
          required: true,
          following_question: {
            condition: true,
            type: 'textarea',
            name: 'currently_being_financed_explain',
            table: 'additional_business_data',
            label: 'Please provide additional details:',
            required: false,
          },
        },
        {
          type: 'tenant_form_radio',
          name: '941_taxes_paid',
          table: 'additional_business_data',
          label: 'Any past due 941 (payroll) taxes?',
          options: FINANCIAL_RADIO_OPTIONS,
          required: true,
          following_question: {
            condition: true,
            type: 'textarea',
            name: '941_taxes_paid_explain',
            table: 'additional_business_data',
            label: 'Please provide additional details:',
            required: false,
          },
        },
        {
          type: 'tenant_form_radio',
          name: 'tax_liens_filed',
          table: 'additional_business_data',
          placeholder: 'Any tax liens filed?',
          label: 'Any tax liens filed?',
          options: FINANCIAL_RADIO_OPTIONS,
          required: true,
          following_question: {
            condition: true,
            type: 'textarea',
            name: 'tax_liens_filed_explain',
            table: 'additional_business_data',
            label: 'Please provide additional details:',
            required: false,
          },
        },
        {
          type: 'tenant_form_radio',
          name: 'company_gone_different_name',
          table: 'additional_business_data',
          label: 'Has your company ever gone by a different name or currently using an Assumed Name?',
          options: FINANCIAL_RADIO_OPTIONS,
          required: true,
          following_question: {
            condition: true,
            type: 'textarea',
            name: 'company_gone_different_name_explain',
            table: 'additional_business_data',
            label: 'Please List:',
            required: false,
          },
        },
        {
          type: 'tenant_form_radio',
          name: 'lawsuits',
          table: 'additional_business_data',
          options: FINANCIAL_RADIO_OPTIONS,
          label: 'Are there any judgements filed or lawsuits pending against the business?',
          required: true,
          following_question: {
            condition: true,
            type: 'textarea',
            name: 'lawsuits_explain',
            table: 'additional_business_data',
            label: 'Please provide additional details:',
            required: false,
          },
        },
        {
          type: 'text',
          name: 'how_many_invoices',
          table: 'additional_business_data',
          label: 'On average, how many invoices do you send out per month?',
          required: true,
        },
        {
          type: 'text',
          name: 'invoices_size',
          table: 'additional_business_data',
          label: 'What is your average invoice size?',
          required: true,
        },
        {
          type: 'tenant_form_radio',
          name: 'sales_to_government',
          table: 'additional_business_data',
          label: 'Do you have any sales to government agencies?',
          options: FINANCIAL_RADIO_OPTIONS,
          required: true,
          following_question: {
            condition: true,
            type: 'textarea',
            name: 'sales_to_government_explain',
            table: 'additional_business_data',
            label: 'Are they city, state, or federal?',
            required: false,
          },
        },
        {
          type: 'tenant_form_radio',
          name: 'ownership_companies_of_invoices',
          table: 'additional_business_data',
          label: 'Do you have any ownership in the companies you invoice?',
          options: FINANCIAL_RADIO_OPTIONS,
          required: true,
          following_question: {
            condition: true,
            type: 'textarea',
            name: 'ownership_companies_of_invoices_explain',
            table: 'additional_business_data',
            label: 'Please provide additional details:',
            required: false,
          },
        },
        {
          type: 'tenant_form_radio',
          name: 'progress_milestone_billing',
          table: 'additional_business_data',
          label: 'Do have any Progress or Milestone billing?',
          options: FINANCIAL_RADIO_OPTIONS,
          required: true,
          following_question: {
            condition: true,
            type: 'textarea',
            name: 'additional_business_data_explain',
            table: 'additional_business_data',
            label: 'Please provide additional details:',
            required: false,
          },
        },
      ],
    },
    {
      title: 'Top 3 Customers',
      type: 'largest_customers',
      maxLength: 3,
      addButtonInitialLabel: 'Add Top 3 Customers +',
      addButtonLabel: 'Add Next Customer +',
      label: 'Customer',
      fields: [
        {
          type: 'text',
          name: 'debtor_name',
          label: 'Customer Name',
          table: 'top_business_debtors',
        },
        {
          type: 'text',
          name: 'debtor_city',
          label: 'Customer City',
          table: 'top_business_debtors',
        },
        {
          type: 'text',
          name: 'debtor_state',
          label: 'Customer State',
          table: 'top_business_debtors',
        },
        {
          type: 'currency',
          name: 'sales_volume',
          label: 'Annual Sales Volume',
          table: 'top_business_debtors',
        },
        {
          type: 'text',
          name: 'relationship_length',
          table: 'top_business_debtors',
          label: 'Length Of Relationship',
        },
      ],
    },
    {
      type: 'upload_documents',
      title: 'Document Details',
      tooltip:
        'Upload the following documents to expedite your application. Required fields are denoted with an asterisk *',
      fields: [
        [
          {
            type: 'upload_document',
            name: 'aged_receivables',
            table: 'deal',
            label: 'Accounts Receivable Aging (Detail)',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'aged_payables',
            table: 'deal',
            label: 'Accounts Payable Aging (Detail)',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'profit_and_loss',
            table: 'deal',
            label: 'Profit and Loss',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'balance_sheet',
            table: 'deal',
            label: 'Balance Sheet',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'bank_statement',
            table: 'deal',
            label: 'Bank Statement',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'certificate_of_insurance',
            table: 'deal',
            label: 'Certificate of Insurance',
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'w9',
            table: 'deal',
            label: `W-9 `,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'personal_financial_statements',
            table: 'deal',
            label: `Personal Financial Statement`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'operating_agreement',
            table: 'deal',
            label: `Operating Agreement`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'assumed_name_certificate',
            table: 'deal',
            label: `Assumed Name Certificate`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'ein_letter_irs',
            table: 'deal',
            label: `EIN Letter from IRS`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'sample_invoice_docs',
            table: 'deal',
            label: `Sample Invoice with Supporting Docs`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'vendor_agreement',
            table: 'deal',
            label: `MSA/Vendor Agreements`,
            required: false,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
        ],
      ],
    },
    {
      title: 'About Velocity',
      type: 'about',
      label: 'About Velocity Financial',
      fields: [
        {
          type: 'textarea',
          name: 'heard_about',
          label: 'How did you hear about Velocity Financial?',
          table: 'additional_business_data',
          required: false,
        },
      ],
    },
  ],
  disclaimer:
    'By clicking Submit, you are submitting an application for a factoring facility on behalf of the applicant business. You certify there are no misrepresentations in the application or in any documents submitted in connection therewith, that all such information and documentation submitted is true, complete, and accurate and does not omit any material information. You understand submitting application for a factoring facility with Velocity Financial Credit Fund LLC DBA Velocity Financial (“Velocity”) does not guaranty Velocity will factor or provide any financial services whatsoever. You understand that the application and any other credit or financial information received will be retained and replied upon in connection with the application and any potential facility by Velocity, any insurer of credit and any third party to whom Velocity may sell/assign all or part of a factoring facility. A separate authorization for consumer credit and UCC filing will be sent to each Owner listed on the application and consent of that authorization is required to proceed.',
  agree_btn: true,
}
