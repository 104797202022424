import config from 'config'
import { store } from '../_helpers/store'
import { setWebsocketState } from '../_actions'
import { WEBSOCKET_STATES } from '../_constants'
import * as requestedSubscriptions from './subscriptions/index'

const subscribers = Object.values(requestedSubscriptions)

export const subscribe = (session_token) => {
  let reduxState = store.getState()
  const dispatch = store.dispatch
  if (
    reduxState.subscription.websocket_state == WEBSOCKET_STATES.CONNECTING &&
    reduxState.subscription.connection_token == session_token
  ) {
    return
  }
  if (
    reduxState.subscription.websocket_state == WEBSOCKET_STATES.CONNECTED &&
    reduxState.subscription.connection_token == session_token
  ) {
    return
  }

  dispatch(setWebsocketState(WEBSOCKET_STATES.CONNECTING, null, session_token))
  let ws = new WebSocket(config.wsUrl + `?token=${session_token}`)
  ws.onopen = () => {
    for (let subscriber of subscribers) {
      ws.send(
        JSON.stringify({
          command: 'subscribe',
          identifier: JSON.stringify({ channel: subscriber.channel }),
        })
      )
    }
    dispatch(setWebsocketState(WEBSOCKET_STATES.CONNECTED, ws, session_token))
  }
  ws.onclose = () => {
    dispatch(setWebsocketState(WEBSOCKET_STATES.DISCONNECTED, null, session_token))
  }
  ws.onerror = (err) => {
    console.error(err)
  }
  ws.onmessage = (message) => {
    let messageData = JSON.parse(message.data)
    if (messageData.type == 'ping' || !messageData.identifier) {
      return
    }
    for (let subscriber of subscribers) {
      if (
        subscriber.channel == JSON.parse(messageData.identifier).channel &&
        (subscriber.respondsTo.includes(messageData.type) || !!subscriber.respondsTo?.length)
      ) {
        subscriber.onMessage(messageData, ws)
      }
    }
  }
  return ws
}
