import request from './axios_service'
import { formHeader } from '../_helpers'

// post 'documents/upload_operate_invoice_document/:business_id' => 'documents#upload_invoice_document_for_email'
export const getPendingInvoices = (businessId) => {
  return request({
    url: `/invoices/get_all_pending_invoices/${businessId}`,
    method: 'GET',
    uncancelable: true,
  })
}

// Portfolio
export const getInvoiceSummarized = (view = 'ar_aging', params = '') => {
  return request({
    url: `/portfolio/invoices/summarized?view=${view}${params}`,
    method: 'GET',
  })
}

export const getInvoicesPortfolio = (params) => {
  return request(
    {
      url: `/portfolio/invoices?${params}`,
      method: 'GET',
    },
    null,
    true
  )
}

export const getInvoiceSupportingDocuments = (invoiceId, searchParams) => {
  return request({
    url: `invoices/${invoiceId}/get_supporting_documents/?${searchParams}`,
    method: 'GET',
  })
}

export const uploadInvoiceSupportingDocument = (file, invoice_id) => {
  const data = new FormData()
  data.append(`filename`, file.name)
  data.append(`filedata`, file)
  data.append('document_type', 'invoice')
  return request({
    url: `/documents/upload_invoice_supporting_document/${invoice_id}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

export const uploadInvoiceSchedule = (file, scheduleId) => {
  const data = new FormData()
  data.append(`filename`, file.name)
  data.append(`filedata`, file)

  return request({
    url: `/invoice_schedules/${scheduleId}/upload_invoices`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

export const getScheduleInvoices = (params, id) => {
  return request(
    {
      url: `/invoice_schedules/${id}/invoices?${params}`,
      method: 'GET',
    },
    null,
    true
  )
}

export const stopAccruingFeesOnInvoice = (invoiceId, date) => {
  return request({
    url: `/ledger/invoices/${invoiceId}/stop_accrued_fees`,
    method: 'PUT',
    data: { date: date },
  })
}
