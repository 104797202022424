import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformDebtorResponse } from 'xen/api/use-debtor/transform';
async function getInvoiceScheduleNotifiableDebtors({ urlParams: { scheduleId }, }) {
    try {
        const response = await request({
            method: 'GET',
            url: `/invoice_schedules/${scheduleId}/notifiable_debtors`,
        });
        return response.map(transformDebtorResponse);
    }
    catch (_error) {
        throw Error('Could not fetch invoice schedule notifiable debtors');
    }
}
export const useGetInvoiceScheduleNotifiableDebtors = (params) => {
    const { urlParams: { scheduleId }, } = params;
    return useQuery({
        queryKey: ['invoice-schedules', scheduleId, 'notifiable-debtors'],
        queryFn: () => getInvoiceScheduleNotifiableDebtors(params),
        enabled: scheduleId !== undefined,
    });
};
