import React, { useState } from 'react'
import { Field } from 'formik'
import styled from 'styled-components'
import { Button } from '../Button'

const StyledField = styled(({ invalid, ...props }) => <Field {...props} />)`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.invalid ? `#d93025` : `#ccc`)};
  padding: 16px;
  font-size: 16px;
  outline: none;
  transition: border 0.2s;
  margin-bottom: 16px;

  &:focus {
    border: 1px solid ${(props) => (props.invalid ? `#d93025` : `#222`)};
  }
`

const ListField = ({ field, formikName, handleBlur = (formikName, fieldName, newValue) => {}, invalid, value }) => {
  const [count, setCount] = useState(0)
  const [values, setValues] = useState([])
  if (value && count < value.length) {
    setCount(value.length)
    setValues(value)
  }
  let toRender = []
  for (let i = 0; i < count; i++) {
    toRender[i] = (
      <StyledField
        component={'input'}
        invalid={invalid}
        key={formikName + '_' + i}
        name={formikName + '_' + i}
        onBlur={(e) => {
          let vals = [...values]
          vals[i] = e.target.value
          handleBlur(formikName, field.name, vals)
          setValues(vals)
        }}
        validate={(value) => {
          let errorMessage
          if (field.required && !value) {
            errorMessage = `${field.label || `Field`} is required.`
          }
          return errorMessage
        }}
        {...field}
        onChange={(e) => {
          let vals = [...values]
          vals[i] = e.target.value
          setValues(vals)
        }}
        value={values[i]}
      />
    )
  }
  toRender.push(
    <Button
      key={formikName + '_last'}
      onClick={() => setCount(count + 1)}
      text={`Add ${field.label}`}
      type={`button`}
    />
  )
  return <div>{toRender}</div>
}

export default ListField
