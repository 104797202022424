import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from 'xen-ui';
import { useUpdateInvoiceScheduleApproval } from 'xen/api/use-invoice-schedule';
export const InvoiceScheduleApproveButton = ({ children = 'Approve Invoice Schedule', invoiceSchedule: { id: scheduleId }, ...props }) => {
    const { mutateAsync: approveInvoiceSchedule, isPending } = useUpdateInvoiceScheduleApproval({
        urlParams: { scheduleId },
    });
    const handleInvoiceScheduleApprove = async () => {
        approveInvoiceSchedule();
    };
    return (_jsx(Button, { loading: isPending, onClick: handleInvoiceScheduleApprove, ...props, children: children }));
};
