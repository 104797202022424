import { menuOpenConstants } from '../_constants'

export function menuOpen(state = false, action) {
  switch (action.type) {
    case menuOpenConstants.TOGGLE:
      return !state
    default:
      return state
  }
}
