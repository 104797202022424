import styled from 'styled-components'
import { math } from 'polished'

// Constants
import { cssConstants, cssBreakpoints } from '../../../_constants/styles'

const { sideNavMinWidth, pageContentsPadTop } = cssConstants

const { sideNav_desktop } = cssBreakpoints

const sideNavStyles = {
  iconWidth: '20px',
  iconMarginRight: '10px',
  navItemPad_V: '15px',
  navItemPad_H: '13px',
  get secondaryLinkPadLeft() {
    return math(`${this.iconWidth} + ${this.iconMarginRight} + ${this.navItemPad_H}`)
  },
}

export const S_SideNav = styled.aside`
  position: absolute;
  z-index: 1;
  background: #f9f9f9;
  height: 100%;

  nav {
    display: none;
  }

  &.active {
    box-shadow: 5px 0 5px #ccc;
    nav {
      display: block;
      position: sticky;
      top: 0;
      min-width: ${sideNavMinWidth};
      padding: 10px 0;
    }
  }

  .nav-item {
    margin-bottom: 10px;
    &:hover,
    &.active {
      background: #e7e7e7;
    }
    &.active {
      .nav-link {
        &:hover {
          background: #d7d7d7;
        }
      }
      font-weight: bold;
      .secondary-links {
        display: block;
      }
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    padding: 11px 13px;
    font-size: 16px;
    font-size: 18px;

    svg {
      margin-right: ${sideNavStyles.iconMarginRight};
      width: ${sideNavStyles.iconWidth};
      height: ${sideNavStyles.iconWidth};
    }
  }

  .secondary-links {
    display: none;
  }

  .secondary-link {
    font-size: 16px;
    padding-left: ${sideNavStyles.secondaryLinkPadLeft};
    font-weight: normal;
    &.active {
      background: #d7d7d7;
      font-weight: bold;
    }
  }

  @media (min-width: ${sideNav_desktop}) {
    position: sticky;
    top: 0;
    display: block;
    min-height: calc(100vh);
    min-width: ${sideNavMinWidth};
    padding: ${pageContentsPadTop} 0;
    border-right: 1px solid #ddd;

    &.active {
      box-shadow: none;
      nav {
        padding: 0;
      }
    }

    nav {
      display: block;
      position: static;
    }
  }

  @media print {
    display: none;
  }
  select {
    margin-left: 16px;
  }
`
