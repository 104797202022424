import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
async function updateProcessState({ id, date, batchNumber }) {
    try {
        await request({
            method: 'PUT',
            url: `/ledger/payment_batches/${id}/process`,
            data: { date },
        });
        // request returns null so we return id to use to invalidate the cache
        return id;
    }
    catch (_error) {
        throw Error(`Could not process invoice settlement ${batchNumber}`);
    }
}
export const useUpdateProcessState = () => {
    return useMutation({
        mutationFn: ({ id, date, batchNumber }) => updateProcessState({ id, date, batchNumber }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (id, { batchNumber }) => {
            notifications.success(`Processed invoice settlement ${batchNumber}`);
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements', String(id)] });
            queryClient.invalidateQueries({ queryKey: ['payment-assignments'] });
            queryClient.invalidateQueries({ queryKey: ['unapplied-cash-entries'] });
        },
    });
};
