import React, { lazy } from 'react'
import { Route, Redirect } from 'react-router-dom'

// Constants
import ROUTES, { LENDER_ROUTES } from '../_constants/routes'

// Helpers
import { isLender, isSalesPersonPermission } from '../_helpers/user-utils'
import { isProductEnabled, isFeatureEnabled } from '../_helpers/flags'

export const OperateLenderRoutes = ({ loggedIn, user, featureFlags }) => {
  // Only for lenders with operate enabled

  const onlySalesPersonRoutes =
    isLender(loggedIn, user.user_type) && isProductEnabled('Operate', featureFlags) && isSalesPersonPermission(user)

  if (!isLender(loggedIn, user.user_type) || (!isProductEnabled('Operate', featureFlags) && !onlySalesPersonRoutes)) {
    return []
  }

  let loadedModules = {}

  let debtorsOverview = isProductEnabled('Asset Based Lending', featureFlags)
    ? import(`../_views/abl/lender/DebtorsOverview/index.js`)
    : import(`../_views/factoring/lender/DebtorsOverview/index.js`)

  let debtorDetails = isProductEnabled('Asset Based Lending', featureFlags)
    ? import(`../_views/abl/lender/DebtorDetails/index.js`)
    : import(`../_views/factoring/lender/DebtorDetails/index.js`)

  const prospectDetails = import('../_views/LenderViews/NewProspectDetails/index.js')

  const prospectsOverview = import(`../_views/factoring/lender/NewProspectsOverview/index.js`)

  const uploadInvoices = isFeatureEnabled('new_invoice_redesign', featureFlags)
    ? import(`../_views/ClientViews/UploadInvoices/UploadInvoicesRedesign`)
    : import(`../_views/ClientViews/UploadInvoices/UploadInvoices`)

  const createInvoices = isFeatureEnabled('new_invoice_redesign', featureFlags)
    ? import('../_views/factoring/common/CreateInvoicesRedesign')
    : import('../_views/factoring/common/CreateInvoices')

  const pageComponents = {
    LenderHomePage: import('../_views/LenderViews/LenderHome/index.js').then((m) => {
      loadedModules['LenderHomePage'] = m
      return m
    }),
    ClientsOverview: import('../_views/LenderViews/ClientsOverview/index.js').then((m) => {
      loadedModules['ClientsOverview'] = m
      return m
    }),
    InvoiceDigitization: import('../_views/FactoringLender/InvoiceDigitization/index.js').then((m) => {
      loadedModules['InvoiceDigitization'] = m
      return m
    }),
    ClientDetails: import('../_views/LenderViews/ClientDetails/index.js').then((m) => {
      loadedModules['ClientDetails'] = m
      return m
    }),
    ProspectsOverview: prospectsOverview.then((m) => {
      loadedModules['UnderwriteClientsOverview'] = m
      return m
    }),
    ProspectDetails: prospectDetails.then((m) => {
      loadedModules['ProspectDetails'] = m
      return m
    }),
    NewProspect: import('../_views/common/NewProspect/index').then((m) => {
      loadedModules['NewProspect'] = m
      return m
    }),
    EditBusiness: import('../_views/LenderViews/EditBusiness/index.js').then((m) => {
      loadedModules['EditBusiness'] = m
      return m
    }),
    InvoicesOverview: import('../_views/common/lender/InvoicesOverview/index.js').then((m) => {
      loadedModules['InvoicesOverview'] = m
      return m
    }),
    UploadInvoices: createInvoices.then((m) => {
      loadedModules['UploadInvoices'] = m
      return m
    }),
    CreateSchedule: uploadInvoices.then((m) => {
      loadedModules['CreateSchedule'] = m
      return m
    }),
    InvoiceDetailsPage: import('../_views/LenderViews/InvoiceDetails/index.js').then((m) => {
      loadedModules['InvoiceDetailsPage'] = m
      return m
    }),
    CheckDetails: import('../_views/LenderViews/CheckDetails/index.js').then((m) => {
      loadedModules['CheckDetails'] = m
      return m
    }),
    DebtorsList: import('../_views/LenderViews/DebtorsList/index.js').then((m) => {
      loadedModules['DebtorsList'] = m
      return m
    }),
    DebtorsOverview: debtorsOverview.then((m) => {
      loadedModules['DebtorsOverview'] = m
      return m
    }),
    DebtorDetails: debtorDetails.then((m) => {
      loadedModules['DebtorDetails'] = m
      return m
    }),
    CashReceiptsOverview: import('../_views/abl/lender/CashReceiptsOverview/index.js').then((m) => {
      loadedModules['CashReceiptsOverview'] = m
      return m
    }),
  }

  // TODO: move ABL routes to their own file

  const lenderProspectsRoutes = [
    {
      path: LENDER_ROUTES.prospects_overview,
      Component: lazy(() => pageComponents['ProspectsOverview']),
      exact: true,
      module: 'ProspectsOverview',
    },
    {
      path: LENDER_ROUTES.new_prospect,
      Component: lazy(() => pageComponents['NewProspect']),
      module: 'NewProspect',
    },
    {
      path: LENDER_ROUTES.edit_business + '/:businessType/:businessId',
      Component: lazy(() => pageComponents['EditBusiness']),
      module: 'EditBusiness',
    },
    {
      path: LENDER_ROUTES.prospect_details + '/:businessId',
      Component: lazy(() => pageComponents['ProspectDetails']),
      module: 'ProspectDetails',
    },
  ]
  const lenderClientsRoutes = [
    {
      path: LENDER_ROUTES.clients_overview,
      Component: lazy(() => pageComponents['ClientsOverview']),
      exact: true,
      module: 'ClientsOverview',
    },
    {
      path: LENDER_ROUTES.clients_overview + '/:businessId',
      Component: lazy(() => pageComponents['ClientDetails']),
      module: 'ClientDetails',
    },
  ]

  const lenderRoutes = [
    // Generic Lender Dashboard
    {
      path: ROUTES.lender_home,
      Component: lazy(() => pageComponents['LenderHomePage']),
      module: 'LenderHomePage',
    },
    // Debtors
    {
      path: LENDER_ROUTES.debtors_list,
      Component: lazy(() => pageComponents['DebtorsList']),
      module: 'DebtorsList',
    },
    {
      path: LENDER_ROUTES.debtors_overview,
      Component: lazy(() => pageComponents['DebtorsOverview']),
      module: 'DebtorsOverview',
    },
    {
      path: LENDER_ROUTES.debtor_details + '/:debtorId',
      Component: lazy(() => pageComponents['DebtorDetails']),
      module: 'DebtorDetails',
    },
    // Invoices
    {
      path: LENDER_ROUTES.invoices_overview,
      Component: () => <Redirect to={LENDER_ROUTES.invoices_purchased} />,
      exact: true,
    },
    {
      path: LENDER_ROUTES.invoices_all,
      Component: lazy(() => pageComponents['InvoicesOverview']),
      exact: true,
      module: 'InvoicesOverview',
    },
    {
      path: LENDER_ROUTES.invoices_closed,
      Component: lazy(() => pageComponents['InvoicesOverview']),
      exact: true,
      module: 'InvoicesOverview',
    },
    {
      path: LENDER_ROUTES.invoices_ineligible,
      Component: lazy(() => pageComponents['InvoicesOverview']),
      exact: true,
      module: 'InvoicesOverview',
    },
    {
      path: LENDER_ROUTES.invoices_not_funded,
      Component: lazy(() => pageComponents['InvoicesOverview']),
      exact: true,
      module: 'InvoicesOverview',
    },
    {
      path: LENDER_ROUTES.invoices_pending,
      Component: lazy(() => pageComponents['InvoicesOverview']),
      exact: true,
      module: 'InvoicesOverview',
    },
    {
      path: LENDER_ROUTES.invoices_purchased,
      Component: lazy(() => pageComponents['InvoicesOverview']),
      exact: true,
      module: 'InvoicesOverview',
    },
    {
      path: LENDER_ROUTES.invoices_short_paid,
      Component: lazy(() => pageComponents['InvoicesOverview']),
      exact: true,
      module: 'InvoicesOverview',
    },
    {
      path: LENDER_ROUTES.invoices_create,
      Component: lazy(async () => {
        let m = await pageComponents['CreateSchedule']
        return m
      }),
      exact: true,
      module: 'CreateSchedule',
    },
    {
      path: LENDER_ROUTES.invoice + '/:invoiceId',
      Component: lazy(() => pageComponents['InvoiceDetailsPage']),
      module: 'InvoiceDetailsPage',
    },
    // Checks
    {
      path: LENDER_ROUTES.cash_receipts + '/:checkId',
      Component: lazy(() => pageComponents['CheckDetails']),
      module: 'CheckDetails',
    },
    {
      path: LENDER_ROUTES.digitization,
      Component: lazy(() => pageComponents['InvoiceDigitization']),
      module: 'InvoiceDigitization',
    },
    {
      path: LENDER_ROUTES.cash_receipts_overview,
      Component: lazy(() => pageComponents['CashReceiptsOverview']),
      module: 'CashReceiptsOverview',
    },
  ]

  const lenderRoutesToShow = onlySalesPersonRoutes
    ? [...lenderProspectsRoutes, ...lenderClientsRoutes]
    : [...lenderProspectsRoutes, ...lenderClientsRoutes, ...lenderRoutes]
  return lenderRoutesToShow.map(({ path, Component, exact = false, module }) => (
    // Butt ugly, but this prevents the suspense fallback component from displaying unnecessicarily
    <Route
      exact={exact}
      key={path}
      path={path}
      render={(props) => {
        if (module && loadedModules[module]) {
          //Set status to resolved and result to default export of downloaded module to prevent briefly loading suspense component. See https://github.com/facebook/react/blob/master/packages/react/src/ReactLazy.js
          Component._status = 1
          Component._result = loadedModules[module].default
        }
        return <Component {...props} />
      }}
    />
  ))
}
