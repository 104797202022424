import { businessBankAccountConstants } from '../_constants'
import { businessBankAccountService } from '../_services'

export const businessBankAccountActions = {
  getBusinessBankAccount,
  createBusinessBankAccount,
  updateBusinessBankAccount,
}

function getBusinessBankAccount(businessId) {
  return (dispatch) => {
    dispatch(request(businessId))
    return businessBankAccountService
      .getBusinessBankAccount(businessId)
      .then(async (bankAccount) => {
        dispatch(success(bankAccount))

        if (bankAccount.length === 0) {
          await dispatch(createBusinessBankAccount(businessId))
        }
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request(businessId) {
    return { type: businessBankAccountConstants.GET_BANK_ACCOUNT_REQUEST, businessId }
  }
  function success(bankAccount) {
    return { type: businessBankAccountConstants.GET_BANK_ACCOUNT_SUCCESS, bankAccount }
  }
  function failure(error) {
    return { type: businessBankAccountConstants.GET_BANK_ACCOUNT_FAILURE, error }
  }
}

function updateBusinessBankAccount(attr, val, businessId) {
  return (dispatch) => {
    dispatch(request(attr, val, businessId))
    return businessBankAccountService
      .updateBusinessBankAccount(attr, val, businessId)
      .then(async (bankAccount) => {
        dispatch(success(bankAccount))
        if (bankAccount.length === 0) {
          await dispatch(createBusinessBankAccount(businessId))
        }
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request(attr, val, businessId) {
    return {
      type: businessBankAccountConstants.UPDATE_BANK_ACCOUNT_REQUEST,
      attr,
      val,
      businessId,
    }
  }
  function success(bankAccount) {
    return { type: businessBankAccountConstants.UPDATE_BANK_ACCOUNT_SUCCESS, bankAccount }
  }
  function failure(error) {
    return { type: businessBankAccountConstants.UPDATE_BANK_ACCOUNT_FAILURE, error }
  }
}

function createBusinessBankAccount(businessId) {
  return (dispatch) => {
    dispatch(request(businessId))
    return businessBankAccountService
      .createBusinessBankAccount(businessId)
      .then((bankAccount) => dispatch(success(bankAccount)))
      .catch((error) => dispatch(failure(error)))
  }

  function request(businessId) {
    return { type: businessBankAccountConstants.CREATE_BANK_ACCOUNT_REQUEST, businessId }
  }
  function success(bankAccount) {
    return { type: businessBankAccountConstants.CREATE_BANK_ACCOUNT_SUCCESS, bankAccount }
  }
  function failure(error) {
    return { type: businessBankAccountConstants.CREATE_BANK_ACCOUNT_FAILURE, error }
  }
}
