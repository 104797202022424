import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { createPolymorphicComponent, Button as MantineButton, } from '@mantine/core';
import { XenIcon } from '../icons';
const _Button = forwardRef(({ icon, ...props }, ref) => {
    const Icon = icon ? XenIcon[icon] : undefined;
    return _jsx(MantineButton, { autoContrast: true, ...(Icon && { leftSection: _jsx(Icon, { fontSize: "inherit" }) }), ...props, ref: ref });
});
_Button.displayName = 'Button';
export const Button = createPolymorphicComponent(_Button);
