import styled from 'styled-components'
export const S_SensitiveChangeModal = styled.div`
  .explanation {
    max-width: 300px;
    margin-bottom: 30px;
    font-size: 11px;
    color: #aaa;
  }
  .submit-container {
    width: 100%;
    margin-top: 30px;
    .submit-btn {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
`
