import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformInvoiceSchedule } from './transform';
async function getInvoiceSchedule(id) {
    try {
        const response = await request({
            method: 'GET',
            url: `/invoice_schedules/${id}`,
        });
        const data = transformInvoiceSchedule(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not fetch invoice schedule');
    }
}
export const useGetInvoiceSchedule = (id) => {
    return useQuery({
        queryKey: ['invoice-schedules', id],
        queryFn: () => getInvoiceSchedule(id),
    });
};
