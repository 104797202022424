import { css } from 'styled-components'
import { darken, lighten } from 'polished'
import colors from '../../_constants/colors'
import { cssBreakpoints } from '../../_constants/styles'

export const S_Button = css`
  display: inline-block;
  width: max-content;
  height: auto;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-in-out;

  &:not(.icon) {
    border: 1px solid ${(props) => props?.customcolor || props.theme.colors.secondary};
    background-color: ${(props) => props?.customcolor || props.theme.colors.secondary};
    font-size: 16px;
    border-radius: 3px;
    color: white;
    text-align: center;
    padding: 9px 19px;
    min-width: 150px;
    letter-spacing: 0.3px;
    box-sizing: border-box;
    &:hover {
      border-color: ${(props) => darken(0.15, props?.customcolor || props.theme.colors.secondary)};
      background-color: ${(props) => darken(0.15, props?.customcolor || props.theme.colors.secondary)};
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.compact {
    min-width: 0;
    padding: 5px 12px;
    font-size: 14px;
  }

  &.md-compact {
    @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
      min-width: 0;
      padding: 6px 10px;
      font-size: 14px;
    }
  }

  &.light {
    font-weight: normal;
  }

  &.destroy {
    background: ${lighten(0.15, colors.MATERIAL_RED)};
    border-color: ${lighten(0.15, colors.MATERIAL_RED)};
    &:hover {
      background: ${colors.MATERIAL_RED};
      border-color: ${colors.MATERIAL_RED};
    }

    &.minimal {
      color: ${colors.MATERIAL_RED};
      background-color: ${colors.WHITE};
      border: 1px solid ${colors.MATERIAL_RED};

      &:hover {
        background: ${colors.MATERIAL_RED};
        color: ${colors.WHITE};
      }
    }
  }

  &.secondary {
    background: none;
    border: 1px solid ${colors.TEXT_DARK};
    color: ${colors.TEXT_DARK};
    &:hover {
      background: ${colors.TEXT_LIGHT};
      border-color: ${colors.TEXT_LIGHT};
      color: #fff;
    }
  }

  &.secondary-accent {
    background: none;
    border: 1px solid ${(props) => props?.customcolor || props.theme.colors.secondary};
    color: ${(props) => props?.customcolor || props.theme.colors.secondary};
    &:hover {
      background: ${(props) => props?.customcolor || props.theme.colors.secondary};
      border-color: ${(props) => props?.customcolor || props.theme.colors.secondary};
      color: #fff;
    }
  }

  &.secondary-white {
    background: none;
    border: 1px solid ${colors.WHITE};
    color: ${colors.WHITE};
    &:hover {
      background: ${colors.WHITE};
      border-color: ${colors.WHITE};
      color: ${colors.DEFAULT_TEXT};
    }
    &.no-border {
      border-color: transparent;
    }
  }

  &.tertiary {
    background: #8d9fa6;
    border: none;
    color: #fff;
    &:hover {
      background: ${darken(0.15, '#8d9fa6')};
    }
  }

  &.icon-left {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    svg {
      display: inline-block;
      margin-right: 3px;
    }
  }

  &.text-button,
  &.link {
    padding: 0;
    border: none;
    min-width: auto;
    background: none;
  }

  &.text-button {
    color: ${colors.TEXT_LIGHT};
    &:hover {
      background: none;
      border: none;
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  &.link {
    color: ${(props) => props.theme.colors.secondary};
    &.link-black {
      color: ${colors.TEXT_DARK};
    }
    &:hover {
      background: none;
      border: none;
      text-decoration: underline;
    }
    &.compact {
      font-size: 14px;
    }
  }

  &.icon {
    background: none;
    border: none;
    color: ${colors.TEXT_LIGHT};
    font-size: 18px;
    padding: 10.5px 5px;

    &.square {
      padding: 10.5px 10.5px;
    }

    &.icon-rounded-compact {
      padding: 7.5px 7.5px;
      font-size: 14px;
      background: ${(props) => props.theme.colors.secondary};
      color: #fff;
      border-radius: 100%;
      &:hover {
        background-color: ${(props) => darken(0.15, props?.customcolor || props.theme.colors.secondary)};
        color: #fff;
      }
    }

    &.icon-compact {
      font-size: 16px;
      padding: 9.5px 3px;
      &.square {
        padding: 10.5px 10.5px;
      }
    }

    &.icon-edit {
      svg {
        position: relative;
        top: -1px;
        right: -1px;
      }
    }

    &:hover {
      color: ${colors.DEFAULT_SECONDARY};

      &.hover-destroy {
        color: ${colors.MATERIAL_RED};
      }
    }

    svg {
      display: block;
    }
  }

  &.icon-bordered {
    border: 1px solid ${(props) => props?.customcolor || props.theme.colors.secondary};
    border-radius: 3px;

    svg {
      fill: ${(props) => props?.customcolor || props.theme.colors.secondary};
      transition: fill 0.2s ease-in-out;
    }

    &:hover {
      background: ${(props) => props?.customcolor || props.theme.colors.secondary};
      svg {
        fill: ${colors.WHITE};
      }
    }
  }

  &.full-width {
    width: 100%;
  }
`
