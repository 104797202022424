import styled, { css } from 'styled-components'
import colors from '../../_constants/colors'

const subtitleSizes = {
  base: '16px',
  md: '18px',
  sm: '14px',
}

const titleSizes = {
  md: '32px',
  sm: '24px',
}

const expandTitleSyles = css`
  padding-right: 35px;
  cursor: pointer;
  position: relative;
  display: inline-flex;

  &:after {
    top: calc(50% - 7px);
    box-sizing: border-box;
    right: 0;
    position: absolute;
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-right: 1.5px solid ${colors.TEXT_DARK};
    border-top: 1.5px solid ${colors.TEXT_DARK};
    transform: rotate(45deg);
    margin-right: 0.5em;
    font-weight: 300;
    transition: all 0.2s ease-in-out;
  }

  ${(props) =>
    props.expanded &&
    css`
      &:after {
        top: calc(50% - 11px);
        transform: rotate(135deg);
      }
    `}
`

export const S_Subtitle = styled.h2`
  font-size: ${(props) => (props.size ? subtitleSizes[props.size] : '26px')};
  margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}px` : '15px')};
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : 0)};
  color: ${(props) => props.color || colors.TEXT_DARK};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  display: ${(props) => (props.display ? props.display : 'block')};
  letter-spacing: 0.3px;
`

export const S_Title = styled.h2`
  font-size: ${(props) => (props.size ? titleSizes[props.size] : '36px')};
  color: ${colors.TEXT_DARK};
  display: ${(props) => (props.display ? props.display : 'block')};
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : '0px')};
  margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}px` : '15px')};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  letter-spacing: 0.3px;

  ${(props) => props.expandable && expandTitleSyles}
`
