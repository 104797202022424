import { modalConstants } from '../_constants'

export const modalActions = {
  openModal,
  closeModal,
  switchModal,
  setPrevModal,
}

function openModal(content, props = {}, modalProps = {}) {
  return {
    type: modalConstants.OPEN_MODAL,
    content,
    props,
    modalProps,
  }
}

function closeModal() {
  return {
    type: modalConstants.CLOSE_MODAL,
  }
}

function switchModal(content, props) {
  return {
    type: modalConstants.SWITCH_MODAL,
    content,
    props,
  }
}

function setPrevModal(content, props) {
  return {
    type: modalConstants.SET_PREV_MODAL,
    content,
    props,
  }
}
