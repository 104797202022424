export const PRODUCT_FLAGS = {
  OPERATE: 'product_operate',
  FACTORING: 'product_factoring',
  ABL: 'product_abl',
  ORIGINATE: 'product_originate',
  UNDERWRITE: 'product_underwrite',
  E_SIGNING: 'product_esigning',
  RISK_AND_FRAUD: 'product_risk_and_fraud',
}

export const PRODUCT_FLAGS_STR = {
  'Asset Based Lending': PRODUCT_FLAGS.ABL,
  Underwrite: PRODUCT_FLAGS.UNDERWRITE,
  Factoring: PRODUCT_FLAGS.FACTORING,
  'Risk and Fraud': PRODUCT_FLAGS.RISK_AND_FRAUD,
  Originate: PRODUCT_FLAGS.ORIGINATE,
  Operate: PRODUCT_FLAGS.OPERATE,
  'E-Signing': PRODUCT_FLAGS.E_SIGNING,
}

// FACTORING = 11
// ABL = 12
// E_SIGN = 101
// RISK_FRAUD = 102

// const productFlags = [
//   {'enabled':true,'comment':null,'description':'Originate'},
//   {'enabled':true,'comment':null,'description':'Underwrite'},
//   {'enabled':true,'comment':null,'description':'Operate'},
//   {'enabled':true,'comment':null,'description':'Factoring'},
//   {'enabled':false,'comment':null,'description':'Asset Based Lending'},
//   {'enabled':true,'comment':null,'description':'E-Signing'},
//   {'enabled':true,'comment':null,'description':'Risk and Fraud'}
// ]
