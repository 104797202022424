import { scheduleInvoicesConstants } from '../_constants'

export function invoiceSchedules(state = {}, action) {
  switch (action.type) {
    case scheduleInvoicesConstants.SET_PURCHASE_SHOULD_NOTIFY:
      return {
        ...state,
        purchaseInvoicesShouldNotify: action.payload,
      }
    case scheduleInvoicesConstants.SET_UPLOAD_SHOULD_NOTIFY:
      return {
        ...state,
        uploadInvoicesShouldNotify: action.payload,
      }
    default:
      return state
  }
}
