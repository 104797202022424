export const plaidConstants = {
  CONNECT_PLAID_SUCCESS: 'CONNECT_PLAID_SUCCESS',
  CONNECT_PLAID_FAILURE: 'CONNECT_PLAID_FAILURE',
  DISCONNECT_PLAID: 'DISCONNECT_PLAID',

  SET_PLAID_CONNECTION_STATUS: 'SET_PLAID_CONNECTION_STATUS',

  APPLICATION_NAME: 'plaid',

  STATUS_ATTR: 'connect_your_bank',
  STATUS_CONNECT: 'connected',
  STATUS_NOT_CONNECT: 'not_connected',

  CONNECT_TITLE: 'Connect your bank',
  CONNECTED_TITLE: 'Connected',
}
