import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { dragActions } from '../_actions'

export function useDrag() {
  const dispatch = useDispatch()
  return {
    drag: useSelector((state) => {
      return state.drag
    }, shallowEqual),
    setDragOverTarget: useCallback((target) => dispatch(dragActions.setDragOverTarget(target)), [dispatch]),
  }
}
