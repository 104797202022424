import styled from 'styled-components'

export const S_NotificationGroupModal = styled.div`
  max-width: 40vw;
  .submit {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
`
