import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'

// Constants
import colors from '../../_constants/colors'
import { radioLabelStyle } from '../Forms/FormField/CustomFields/UploadDocField'

export const S_Checkbox = styled.div`
  min-width: 100%;

  &:not(.checkbox) {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
  }

  .radio-btn:not(.checkbox) {
    flex-grow: 1;
    flex-basis: 0;
    margin: 5px;
    label {
      ${radioLabelStyle}
    }

    input[type='checkbox'] {
      display: none;

      &:checked {
        & + label {
          border-color: ${colors.MATERIAL_GREEN};
        }
      }
    }
  }

  .checkbox {
    display: block;
    margin-bottom: 7px;

    label {
      position: relative;
      padding: 1px 0 1px 35px;
      font-size: 12px;
      line-height: 1.4;
      display: inline-block;
      color: ${colors.TEXT_DARK};

      &:before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        background: #fff;
        border: 1.5px solid ${colors.OUTLINE_GREY_BOX};
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      &:after {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 4px;
        left: 7px;
        width: 6px;
        height: 9px;
        opacity: 0;
        border: solid ${colors.DEFAULT_SECONDARY};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    input {
      display: none;
      &:checked {
        & + label {
          &:after {
            opacity: 1;
          }
          &:before {
            border-color: ${colors.DEFAULT_SECONDARY};
          }
        }
      }
    }
  }
`

const Checkbox = ({ field, formikName, handleChange, handleBlur, state, values, className = '' }) => {
  let tableName = formikName.split('.')[0]
  return (
    <S_Checkbox className={`radio-group ${className}`}>
      {field.options.map((option, i) => (
        <Field
          key={i}
          name={formikName}
          render={({ field: formikField }) => {
            const OptionLabel = () => {
              if (!option.label) {
                return ''
              }
              return typeof option.label === 'function' ? <option.label /> : option.label
            }
            return (
              <div className={`radio-btn ${className}`}>
                <input
                  checked={state ? state[tableName][option.field_name] : values[option.field_name]}
                  id={`${option.field_name}`}
                  name={option.field_name}
                  onChange={(e) => {
                    let fieldValue = e.target.checked
                    handleChange(option.field_name, fieldValue)
                    handleBlur(tableName + '.' + option.field_name, option.field_name, fieldValue)
                  }}
                  type={`checkbox`}
                />
                <label htmlFor={`${option.field_name}`}>
                  <OptionLabel />
                </label>
              </div>
            )
          }}
          validate={(value) => {
            let errorMessage
            if (field.required && value === null) {
              errorMessage = `Field is required.`
            }
            return errorMessage
          }}
        />
      ))}
    </S_Checkbox>
  )
}

export default Checkbox
